// Frameworks
import React, { useContext } from 'react';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { Platform, Text, View } from 'react-native';
import { Palette } from '../../Theme';
import { CommunityQuestionAnswer, CommunityQuestionAnswerComment } from '../../API';
import { Button, Icon } from 'react-native-elements';
import log from '../../business/logging/logger';
import { useLinkTo } from '@react-navigation/native';

function CommentButton(props: { answer: CommunityQuestionAnswer, comments: CommunityQuestionAnswerComment[] }): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { answer, comments } = props;
  const linkTo = useLinkTo();

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Button
        title={comments && comments.length == 1 ? `1 Comment` : `${comments.length} Comments`}
        titleStyle={{
          color: Palette.plum,
          ...themeFromContext.textVariants.cardBottomButton,
          fontWeight: '700',
          paddingStart: 2,
        }}
        onPress={() => {
          log.info(`/community/${answer.communityID}/questions/answer/comments/${answer.id}`);
          linkTo(`/community/${answer.communityID}/questions/answer/comments/${answer.id}`);
        }}
        buttonStyle={{
          alignSelf: 'center',
          padding: 0,
          paddingRight: 6,
          borderWidth: 1,
          borderRadius: 100,
          paddingLeft: 6,
          borderColor: Palette.lightpurple.concat('66'),
          marginHorizontal: 2,
        }}
        type='clear'
        icon={
          <Icon
            size={themeFromContext.textVariants.header.fontSize}
            name={'chatbox-outline'}
            type='ionicon'
            color={Palette.teal}
          />}
        testID={`comment-button-${answer.id}`}
      />
      {/* {
        comments.length > 0 &&
        <View
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            borderRadius: 100,
            overflow: 'hidden',
            backgroundColor: Palette.lightgrey,
            minWidth: themeFromContext.textVariants.header.fontSize,
            height: themeFromContext.textVariants.header.fontSize,
            padding: Platform.OS !== 'web' ? 0 : 8,
          }}
        >
          <Text
            style={{
              textAlign: 'center',
              textAlignVertical: 'center',
              color: themeFromContext.colors.text,
              ...themeFromContext.textVariants.cardBottomButton,
              fontSize: 16,
            }}
            testID={`comment-button-count-${answer.id}`}
          >
            {comments.length}
          </Text>
        </View>
      } */}
    </View>
  );
}
export default CommentButton;