import React, { useContext } from 'react'
import { View, Image, Platform, ScrollView, KeyboardAvoidingView } from 'react-native'
import { DISPLAY, PHONE_BREAK, TABLET_BREAK } from '../../../business/layout/layout';
import CommonContext from '../../../CommonContext';
import { CommonContextType } from '../../../types/CommonContextType';
import AuthPageFooter from './AuthPageFooter';
import { SafeAreaView } from 'react-native-safe-area-context'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

function AuthPage(props: { children: Element[] | Element }): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { width } = commonContext.dimensions;
  const display = width > TABLET_BREAK ? DISPLAY.WEB : width <= PHONE_BREAK ? DISPLAY.PHONE : DISPLAY.TABLET;


  return (
    <SafeAreaView style={{
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      flex: 1,
      zIndex: 1,
    }}>
      <KeyboardAwareScrollView
        contentContainerStyle={{
          //flexGrow: 1,
          justifyContent: 'center',
          display: 'flex',
          minHeight:'100%',
          //flex: 1,
          alignItems: display == DISPLAY.WEB ? 'flex-start' : 'center',
          zIndex: 1,
        }}
        style={{
          // justifyContent: 'center'
          // flexGrow: 1,
          // minHeight: '100%',
          // minWidth:'100%'
          // alignItems: display == DISPLAY.WEB ? 'flex-start' : 'center',
          // justifyContent: 'center',
        }} >
        {
          display != DISPLAY.PHONE &&
          <Image
            source={require('../../../../assets/surf.jpg')}
            resizeMode={'cover'}
            style={{
              height: '100%',
              width: '100%',
              position: 'absolute',
            }}
          />
        }
        <View style={{
          // flex: 1,
          flexGrow: display == DISPLAY.PHONE ? 1 : 0,
          width: '100%',
          maxWidth: display == DISPLAY.PHONE ? '100%' : 400,
          alignItems: 'center',
          backgroundColor: themeFromContext.colors.background,
          borderRadius: 10,
          paddingHorizontal: 30,
          paddingTop: Platform.OS === 'ios' ? 50 : 20,
          paddingBottom: 30,
          justifyContent: 'center',
          marginLeft: display == DISPLAY.WEB ? (width / 10) : 0,
          zIndex: 1,
        }}>
          <Image
            source={require('../../../../assets/novella-logo-full.png')}
            resizeMode='contain'
            style={{ height: 100, width: '100%', marginBottom: 10, }}
          />
          {props.children}
          <AuthPageFooter />
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  )
}

export default AuthPage