// Frameworks
import React, { useContext } from 'react';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import DropDownPicker, { DropDownPickerProps, RenderListItemPropsInterface, ValueType } from 'react-native-dropdown-picker';
import { StyleProp, Text, TouchableOpacity, ViewStyle } from 'react-native';
import { Palette } from '../../Theme';

function NDropDownPicker<T extends ValueType>(propsTop: DropDownPickerProps<T> & { zIndex?: number, itemStyle?: StyleProp<ViewStyle>, selectedItemStyle?: StyleProp<ViewStyle>, onPressItem: (value: T) => void }) : React.ReactElement {
  
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;

  return (
    <DropDownPicker
      { ...propsTop }
      labelProps={{
        numberOfLines: 1,
      }}
      textStyle={[
        {
          padding: 0,
          margin: 0,
          textAlignVertical: 'center',
          lineHeight: 16,
          fontSize: 16,
          fontWeight: '700',
          color: Palette.plum,
        },
        propsTop.textStyle,
      ]}
      containerStyle={[
        {
          borderWidth: 0,
          flex: 1,
          marginEnd: 6,
          marginTop: 1,
          maxHeight: 38,
          borderRadius: 8,
        },
        propsTop.containerStyle,
      ]}
      //label inside the collapsed dropdown, showing current selection
      labelStyle={[
        {
          backgroundColor: Palette.transparent,
          paddingTop: 2,
        },
        propsTop.labelStyle,
      ]}
      props={{
        // container around the label
        style: [
          {
            minHeight: 34,
            color: themeFromContext.colors.foreground,
            ...themeFromContext.textVariants.body,
            paddingLeft: 9,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            flex: 1,
            backgroundColor: '#B2DCDD',
            borderRadius: 13,
            borderColor: '#B2DCDD',
            borderWidth: 1,
            paddingHorizontal: 8,
            zIndex: propsTop.zIndex ?? 500,
          },
          propsTop.style
        ],
      }}
      itemProps={{
        style: [
          {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap',
          },
        ]
      }}
      // each individual item inside the expanded dropdown
      listItemContainerStyle={{
        backgroundColor: Palette.transparent,
        borderRadius: 0,
      }}
      selectedItemContainerStyle={{
        backgroundColor: Palette.plum,
        borderRadius: 0,
      }}
      selectedItemLabelStyle={{
        color: Palette.white,
      }}
      // style of the container for the expanded dropdown
      dropDownContainerStyle={[
        {
          borderRadius: 13,
          backgroundColor: '#D3EBEB',
          borderWidth: 0,
          paddingVertical: 0,
          zIndex: (propsTop.zIndex ?? 500) - 1,
          marginTop: -10,
          paddingTop: 10,
          overflow: 'hidden',
        },
        propsTop.dropDownContainerStyle,
      ]}
      scrollViewProps={{
        showsVerticalScrollIndicator: true,
      }}
      renderListItem={(propsInner: RenderListItemPropsInterface<T>) => {
        return (
            <TouchableOpacity 
              {...propsInner} 
              style={[
                propsInner.listItemContainerStyle,
                {
                  height: 36,
                  borderRadius:10,
                },
                propsInner.isSelected ? propsInner.selectedItemContainerStyle : {},
              ]} 
              onPress={() => {
                propsTop.onPressItem(propsInner.value);
              }} 
            >
              <Text
                numberOfLines={1}
                ellipsizeMode='tail'
                style={[
                  propsInner.listItemLabelStyle,
                  propsInner.isSelected ? propsInner.selectedItemLabelStyle : {},
                ]}
              >
                  {propsInner.label}
              </Text>
            </TouchableOpacity>
        );
      }}
    />
  );
}
export default NDropDownPicker;