import React, { useContext, useEffect, useState } from 'react';
import { Animated, Easing, Text, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { Provider } from 'react-native-paper';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CommunityTabParamList } from '../../../types/Navigation';
import { Community, CommunityUser, OperationStatus, User, xAssignEmailToPlaceholder } from '../../../API';
import { convertGoogleContactsToRelatedUserData, convertRelatedUserToRelatedUserData, isValidEmail, mergeUserDataByEmailOrNames, RelatedUserData, subscribeToXUpdates } from '../../../business/user/userHelper';
import { CommunityUserStatus } from '../../../API';
import * as s from '../../../graphql/subscriptions';
import log from '../../../business/logging/logger';
import RelatedUserPicker from '../../components/RelatedUserPicker';
import NTextInput from '../../components/NTextInput';
import PromptModal from '../../components/Modals/PromptModal';
import MemberScreenListItem from './MemberScreenListItem';
import ScrollableFlatListScreen from '../../components/Layouts/ScrollableFlatListScreen';
import { useLinkTo, useIsFocused } from '@react-navigation/native';
import SlideUpView from '../../components/Modals/SlideUpView';
import { Palette } from '../../../Theme';
import { normalizeHeight } from '../../../business/layout/responseSize';
import { getNote } from '../../../business/user/communityUserHelper';
import Message from '../../../../assets/svg/illustrations/Message';
import NFAB2 from '../../components/NFAB2';
import EmailPreviewModal2 from '../../components/EmailPreviewModal2';
import NButton from '../../components/NButton';

const PREVIEW = require(`../../../../assets/member-email-sample.jpg`);

export type MembersScreenProps = BottomTabScreenProps<CommunityTabParamList, 'Members'>;

const checked: Set<string> = new Set<string>();

function MembersScreen({ route, navigation }: MembersScreenProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const { userService, communityService, relatedUserService } = commonContext.services;
  const themeFromContext = commonContext.theme;
  //  const [canManageCommunity, setCanManageCommunity] = useState(false);
  const [communityRole, setCommunityRole] = useState(CommunityUserStatus.REMOVED);
  // const [members, dispatch] = useReducer(reducer, []);
  const [members, setMembers] = useState<CommunityUser[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [inviteUsersOverlayVisible, setInviteUsersOverlayVisible] = useState(false);
  const [inviteUsersOverlayErrorMessage, setInviteUsersOverlayErrorMessage] = useState('');
  const [inviteButtonRefreshing, setInviteButtonRefreshing] = useState(false);
  const [usersToInvite, setUsersToInvite] = useState<RelatedUserData[]>([]);
  const [relatedUsers, setRelatedUsers] = useState<RelatedUserData[]>([]);
  const [removeUsersOverlayVisible, setRemoveUsersOverlayVisible] = useState(false);
  const [removeUsersOverlayErrorMessage, setRemoveUsersOverlayErrorMessage] = useState('');
  const [removeButtonRefreshing, setRemoveButtonRefreshing] = useState(false);
  const [speedDialopen, setSpeedDialOpen] = useState(false);
  const [sortAsc, setSortAsc] = useState(true);
  const [refreshing, setRefreshing] = useState(true);
  const [lastCustomNote, setLastCustomNote] = useState('');
  const [customNote, setCustomNote] = useState('');
  const [maxCustomNoteLength, setMaxCustomNoteLength] = useState(communityService.DEFAULT_MAX_INVITATION_MESSAGE_LENGTH);
  //const [loggedInUser, setLoggedInUser] = useState<User>();
  const [currentCommunity, setCurrentCommunity] = useState<Community>();
  const isFocused = useIsFocused();
  //const [showGoogleAuthModal, setShowGoogleAuthModal] = useState(false);
  const [deniedContacts, setDeniedContacts] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [showEmailPreview, setShowEmailPreview] = useState(false);
  const linkTo = useLinkTo();
  const communityId = route.params?.communityId;
  const [userToAddEmail, setUserToAddEmail] = useState<CommunityUser>();
  const [addEmailErrorMessage, setAddEmailErrorMessage] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [addEmailOverlayVisible, setAddEmailOverlayVisible] = useState(false);
  const [addAndInviteButtonRefreshing, setAddAndInviteButtonRefreshing] = useState(false);
  const [opSub, setOpSub] = useState<any>();
  const { width, height } = commonContext.dimensions;
  const [googleWorking, setGoogleWorking] = useState(false);
  const [showGoogleModal, setShowGoogleModal] = useState(false);

  const [customNoteVisible, setCustomNoteVisible] = useState(false);

  useEffect(() => {
    let mounted = true;
    if (communityId) {
      relatedUserService.getKnownUsers([communityId])
        .then((users) => {
          if (mounted) {
            setRelatedUsers(convertRelatedUserToRelatedUserData(userService, users));
          }
        })
        .catch((ex) => {
          log.error(ex);
        });
    }
    return () => { mounted = false; }
  }, [communityId]);

  useEffect(() => {
    if (route && route.params && route.params.authorized == 1) {
      if (showGoogleModal) {
        setGoogleWorking(false);
        setShowGoogleModal(false);
        toggleInviteUsersOverlay();
      }
    }
  }, [route?.params.authorized]);

  useEffect(() => {
    let mounted = isFocused;
    setRefreshing(true);
    if (mounted) {
      init(mounted);
    }
    return () => {
      if (opSub) {
        opSub.unsubscribe();
      }
      mounted = false
    };
  }, [communityId, isFocused]);

  useEffect(() => {
    if (commonContext.membership[communityId]) {
      setMembers(commonContext.membership[communityId]);
      setLastCustomNote(findLatestCustomNote(commonContext.membership[communityId], commonContext.loggedInUser));
      setRefresh(!refresh);
    }
  }, [commonContext.membership]);

  function init(mounted: boolean) {
    if (mounted) {
      commonContext.analytics?.viewPage(route.name, route.params);
      if (commonContext.loggedInUser && commonContext.loggedInUser.communityLimits && commonContext.loggedInUser.communityLimits.maxInvitationLength) {
        setMaxCustomNoteLength(commonContext.loggedInUser.communityLimits.maxInvitationLength);
      }

      checked.clear();

      // handle members
      if (commonContext.membership[communityId]) {
        setMembers(communityService.sort(commonContext.membership[communityId], 'status', false));
        setLastCustomNote(findLatestCustomNote(commonContext.membership[communityId], commonContext.loggedInUser));
      }
      setRefresh(!refresh);
      setRefreshing(false);

      communityService.getSingleCommunity(communityId)
        .then((community) => { if (mounted) setCurrentCommunity(community); });
      communityService.getLoggedInUserCommunityRole(communityId)
        .then((value) => { if (mounted) setCommunityRole(value); });
    }
  }

  const findLatestCustomNote = (members: CommunityUser[], author: User | undefined): string => {
    if (!author) {
      return '';
    }
    const copy = [...members];
    const sorted = communityService.sort(copy, 'createdAt', false);
    let note = '';
    // log.info(`LOOKING FOR LAST CUSTOM NOTE`);
    // log.info(`FROM USER: ${author.firstName}`);
    for (let i = 0; i < sorted.length; i++) {
      const n = getNote(sorted[i])
      if (n != '' &&
        sorted[i].invitedByUser &&
        sorted[i].invitedByUser.id == author.id) {
        note = n;
        log.info(`GOT IT: ${note}`);
        break;
      }
    }
    return note;
  }



  const toggleInviteUsersOverlay = async () => {
    setWaiting(true);
    // here we want to check if they have google contacts authorized, like create community first screen
    if (!deniedContacts) {
      try {
        const contacts = await userService.getMyGoogleContacts(() => {
          //log.info(commonContext.showingModal);
          if (!showGoogleModal) {
            setTimeout(() => setShowGoogleModal(true), 400);
          }
          //setShowGoogleAuthModal(true);
          setWaiting(false);
        });
        if (contacts) {
          const newRelated = mergeUserDataByEmailOrNames<RelatedUserData>(relatedUsers, convertGoogleContactsToRelatedUserData(contacts), (item: RelatedUserData) => item.user);
          setRelatedUsers(newRelated);
          showAnyway();
        }
      } catch (ex) {
        log.info(`Error getting Google contacts, we might need the user to reauthorize?`);
        log.info(ex);
        if (!showGoogleModal) {
          setShowGoogleModal(true);
        }
        //setShowGoogleAuthModal(true);
        setWaiting(false);
      }
    } else {
      showAnyway();
    }
  };

  const showAnyway = () => {
    setUsersToInvite([]);
    const cn = findLatestCustomNote(commonContext.membership[communityId], commonContext.loggedInUser);
    setLastCustomNote(cn);
    setCustomNote(cn);
    setInviteUsersOverlayErrorMessage('');
    setInviteUsersOverlayVisible(!inviteUsersOverlayVisible);
    setWaiting(false);
  }

  const authorizeContacts = async () => {
    if (commonContext.loggedInUser) {
      setGoogleWorking(true);
      await userService.requestGoogleContactsPermissions(`community/${communityId}/members/${communityId}?authorized=1`);
    }
  }

  const denyContacts = () => {
    log.info(`clicked no thanks`);
    setDeniedContacts(true);
    setShowGoogleModal(false);
    //setShowGoogleAuthModal(false);
    setTimeout(() => {
      showAnyway();
    }, 600);
    setRefresh(!refresh);
  }

  const pressInvite = async () => {
    // as MEMBER for now, until we have an "accept" mechanism
    try {
      setInviteButtonRefreshing(true);

      // Can't let someone re-invite an existing member because it could downgrade their status, and that is not the intention here
      const m = new Set<string>(members.map(m => m.user.id));
      const cleanUsersToInvite = usersToInvite.filter(u => !m.has(u.user.id));
      await communityService.inviteAndSendInvitations(cleanUsersToInvite.map(m => m.user), communityId, CommunityUserStatus.MEMBER, customNote);
      setInviteButtonRefreshing(false);
      toggleInviteUsersOverlay();
      // getAllowedMembersByCommunity([communityId]).then((mapping) => {
      //   const m = [ ...mapping[communityId] ];
      //   dispatch({ type: "set", payload: sort(m, "status", false) });
      //   //setMembers(sort(m, "status", false));
      //   setRefresh(!refresh);
      // });
    } catch (ex) {
      setInviteButtonRefreshing(false);
      setInviteUsersOverlayErrorMessage(`Unable to invite new members at this time.`);
    }
  }

  const toggleRemoveUsersOverlay = () => {
    setRemoveUsersOverlayErrorMessage('');
    setRemoveUsersOverlayVisible(!removeUsersOverlayVisible);
  };

  const pressRemove = async () => {
    let success = true;
    setRemoveButtonRefreshing(true);
    await communityService.removeMembers(
      members.filter((value, index) => checked.has(value.id)),
      communityId,
      (d, u) => {
        setRemoveUsersOverlayErrorMessage(u);
        success = false;
        log.info(u);
        log.error(d);
      });
    setRemoveButtonRefreshing(false);
    if (success) {
      toggleRemoveUsersOverlay();
      // getAllowedMembersByCommunity([communityId]).then((mapping) => {
      //   const m = [ ... mapping[communityId] ];
      //   dispatch({ type: "set", payload: sort(m, "status", false) });
      //   //setMembers(sort(m, "status", false));

      //   setRefresh(!refresh);
      // });
      checked.clear();
      setRefresh(!refresh);
    }
  }

  const toggleCheck = (id: string) => {
    if (!checked.has(id)) {
      checked.add(id);
    } else {
      checked.delete(id);
    }
    setRefresh(!refresh);
  }

  const addEmailAndInvite = (communityUser: CommunityUser) => {
    const cn = findLatestCustomNote(commonContext.membership[communityId], commonContext.loggedInUser);
    setLastCustomNote(cn);
    setCustomNote(cn);
    setAddEmailErrorMessage('');
    setNewEmail('');
    setUserToAddEmail(communityUser);
    setAddEmailOverlayVisible(true);
  }

  const pressAddAndInvite = async () => {
    setAddAndInviteButtonRefreshing(true);

    try {
      if (!isValidEmail(newEmail)) {
        setAddEmailErrorMessage(`Please enter a valid email address`);
        setAddAndInviteButtonRefreshing(false);
      } else {
        if (userToAddEmail) {
          log.info(`trying to merge user using email: ${newEmail}`);
          const op = await communityService.mergePlaceholderAndSendInvitation(newEmail, userToAddEmail, customNote);
          log.info(JSON.stringify(op, null, 2));
          const ops = subscribeToXUpdates<xAssignEmailToPlaceholder>(op.id, s.onUpdateXAssignEmailToPlaceholder, 'onUpdateXAssignEmailToPlaceholder', (o) => {
            if (o.status == OperationStatus.FAILED) {
              setAddEmailErrorMessage(`Unable to set email. Please try again later.`);
            } else if (o.status == OperationStatus.NOOP) {
              setAddEmailErrorMessage(`Oops! There's already a member with the same email.`);
            } else if (o.status == OperationStatus.SUCCEEDED) {
              setAddEmailErrorMessage('');
              setAddEmailOverlayVisible(false);
            }
            setAddAndInviteButtonRefreshing(false);
          });
          log.info(JSON.stringify(ops, null, 2));
          if (opSub) {
            opSub.unsubscribe();
          }
          setOpSub(ops);
        } else {
          setAddEmailErrorMessage(`This shouldn't be possible -- you need to pick a user first.`);
        }
      }
    } catch (ex) {
      setAddEmailErrorMessage(`Unable to update email at this time.`);
      setAddAndInviteButtonRefreshing(false);
    }
  }

  const toggleEmailPreview = (show: boolean) => {
    if (show) {
      setInviteUsersOverlayVisible(false);
      setTimeout(() => {
        setShowEmailPreview(true);
      }, 600);
    } else {
      setShowEmailPreview(false);
      setTimeout(() => {
        setInviteUsersOverlayVisible(true);
      }, 600);
    }
  }

  const [anim, setAnim] = useState(new Animated.Value(0));

  useEffect(() => {
    if (customNoteVisible) {
      // open it

      Animated.timing(
        anim,
        {
          toValue: 1,
          duration: 200,
          useNativeDriver: true,
          easing: Easing.ease,
        }
      ).start((result) => {
        //setFullyOpen(true);
      });

    } else {
      // close it

      //setFullyOpen(false);
      Animated.timing(
        anim,
        {
          toValue: 0,
          duration: 200,
          useNativeDriver: true,
          easing: Easing.ease,
        }
      ).start(() => {
        //setShouldOpen(false);
      });

    }

    return () => { return; }
  }, [customNoteVisible]);

  return (
    <Provider>
      {
        commonContext.loggedInUser &&
        <ScrollableFlatListScreen
          navigation={navigation}
          data={members}
          isFocused={isFocused}
          refreshing={refreshing}
          topTitle={`Members`}
          bottomTitle={currentCommunity ? currentCommunity.name : 'Community'}
          //goBack={() => linkTo(`/communities/${commonContext.loggedInUser?.id}/`)}
          goBack={navigation.goBack}
          extraData={refresh}
          renderItem={({ item, index, separators }) => (
            <MemberScreenListItem
              loggedInUser={commonContext.loggedInUser}
              communityRole={communityRole}
              member={item}
              checked={checked.has(item.id)}
              onPress={() => { toggleCheck(item.id); }}
              onPressEmail={(member) => { addEmailAndInvite(member); }}
            />
          )}
          ListEmptyComponent={() => (
            <View style={{
              alignItems: 'center',
              //height: '100%',
              justifyContent: 'center',
              padding: 50
            }}>
              <Text
                style={{
                  fontFamily: themeFromContext.textVariants.header.fontFamily,
                  fontSize: themeFromContext.textVariants.header.fontSize,
                  fontWeight: themeFromContext.textVariants.header.fontWeight,
                  color: Palette.white,
                  textAlign: 'center'
                }}
              >
                {`You're all alone!\n\nInvite someone to this community!`}
              </Text>
            </View>
          )}
          ListFooterComponent={() => (
            <View style={{
              alignItems: 'center',
              marginTop: themeFromContext.spacing.m,
              marginBottom: normalizeHeight(260, height),
            }}>
              <Text style={{
                //paddingHorizontal: 100,
                textAlign: 'center',
                paddingVertical: themeFromContext.spacing.s,
                paddingHorizontal: 24,
                ...themeFromContext.textVariants.listFooter,
                color: Palette.white,
              }}>
                {communityService.isManagementRole(communityRole) ? `Didn't find who you're looking for? Invite them now by hitting the "+" button!` : ''}
              </Text>
            </View>
          )}
          ListFooterComponentStyle={{ display: members?.length > 0 ? 'flex' : 'none' }}
        >
          {
            communityService.isManagementRole(communityRole) &&
            <NFAB2
              isVisible={true}
              hasBottomBar={true}
              left={
                checked.size === 0 ?
                  <Icon
                    name='add'
                    type='ionicon'
                    color={Palette.plum}
                    size={32}
                  /> :
                  <Icon
                    name='trash-outline'
                    type='ionicon'
                    color={Palette.white}
                    size={32}
                  />
              }
              right={
                <Text
                  style={{
                    fontSize: 20,
                    color: checked.size === 0 ? Palette.plum : Palette.white,
                  }}
                >
                  {checked.size === 0 ? `Invite` : `Remove`}
                </Text>
              }
              colors={checked.size === 0 ? undefined : [Palette.red, Palette.red]}
              onPress={() => {
                checked.size === 0 ? toggleInviteUsersOverlay() : toggleRemoveUsersOverlay();
              }}
            />
          }
          <SlideUpView
            onCancel={() => { toggleRemoveUsersOverlay(); }}
            onCommit={pressRemove}
            show={removeUsersOverlayVisible}
            buttonTitle={`Remove`}
            buttonColors={[themeFromContext.colors.danger, themeFromContext.colors.danger]}
            labelColor={Palette.white}
            icon={
              <Icon
                name='trash-outline'
                type='ionicon'
                solid
                size={32}
                color={themeFromContext.colors.background}
                style={{
                  marginRight: 4,
                }}
              />
            }
          >
            <Text
              style={{
                ...themeFromContext.textVariants.askQuestionHeader,
                color: themeFromContext.colors.primary,
                textAlign: 'left',
                paddingLeft: 10,
              }}
            >
              {`Remove these members?`}
            </Text>
            <Text
              style={{
                ...themeFromContext.textVariants.header,
                color: themeFromContext.colors.foreground,
                padding: themeFromContext.spacing.m,
                textAlign: 'center',
                flex: 1,
              }}
            >
              {members.filter((value, index) => checked.has(value.id)).map(m => userService.getFullName(m.user)).join(`\n`)}
            </Text>
            {
              removeUsersOverlayErrorMessage.length > 0 &&
              <Text
                style={{
                  margin: themeFromContext.spacing.l,
                  color: themeFromContext.colors.failure,
                  textAlign: 'center',
                  ...themeFromContext.textVariants.body
                }}
              >
                {removeUsersOverlayErrorMessage}
              </Text>
            }

          </SlideUpView>


          <SlideUpView
            onCancel={toggleInviteUsersOverlay}
            onCommit={pressInvite}
            show={inviteUsersOverlayVisible}
            buttonTitle={`Send invitations`}
            onBack={customNoteVisible ? () => { setCustomNoteVisible(false); } : undefined}
            // title={customNoteVisible ? `Personal message` : `Invite who?`}
            percent={90}
            //stretchVertical={true}
          // icon={
          //   <Icon
          //     name='send'
          //     type='ionicons'
          //     color={Palette.plum}
          //     size={32}
          //     style={{
          //       marginRight: 4,
          //     }}
          //   />
          // }
          >
            {/* <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                zIndex: 200,
                flex: 1,
                justifyContent: 'space-between',
                //width: (width * 2) - (themeFromContext.slideUp.style.padding * 5),
              }}
            > */}
              {/* <View
                style={{
                  flex: 1,
                  display: 'flex',
                  zIndex: 200,
                  //justifyContent: 'flex-start',
                  //height: '100%',
                  //width: width - (themeFromContext.slideUp.style.padding * 2),
                  // transform: [
                  //   {
                  //     translateX: Animated.subtract(0, Animated.multiply(width, anim)),
                  //   }
                  // ]
                }}
              > */}
                <View
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Message />
                </View>
                <Text
                  style={{
                    ...themeFromContext.textVariants.header,
                    marginBottom: normalizeHeight(20, height),
                    textAlign: 'center',
                  }}
                >
                  {`Invite who?`}
                </Text>

                {/* <View> */}
                {
                  inviteUsersOverlayErrorMessage.length > 0 &&
                  <Text
                    style={{
                      //margin: themeFromContext.spacing.l,
                      color: themeFromContext.colors.failure,
                      fontFamily: themeFromContext.textVariants.body.fontFamily,
                      fontSize: themeFromContext.textVariants.body.fontSize
                    }}
                  >
                    {inviteUsersOverlayErrorMessage}
                  </Text>
                }
                {/* </View> */}

                {/* <View
                  style={{
                    display: 'flex',
                    flex: 1,
                    width: '100%',
                  }}
                > */}
                <RelatedUserPicker
                  alreadyPicked={usersToInvite}
                  onSelectionChanged={setUsersToInvite}
                  fullList={relatedUsers}
                />
                {/* </View> */}
                <View
                  style={{
                    borderRadius: 13,
                    padding: 24,
                    backgroundColor: '#6D6D6D20',
                    alignItems: 'flex-start',
                    zIndex: 100,
                    display: 'flex',
                    //flex: 1,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 20,
                      fontWeight: '600',
                      color: Palette.plum
                    }}
                  >
                    {`Want to add a personal note?`}
                  </Text>
                  <Text
                    style={{
                      fontSize: 16,
                      fontWeight: '300',
                      color: Palette.plum,
                      marginTop: 12,
                      lineHeight: 20,
                    }}
                  >
                     {`If you want, we'll include a note from you in the invitation email. Preview the email `}
                      <Text
                        style={{
                          color: Palette.teal,
                          fontWeight: '700',
                        }}
                        onPress={() => toggleEmailPreview(true)}
                        testID={`invite-user-to-community-email-preview-button`}
                      >
                        {`here`}
                      </Text>
                      {`.`}
                  </Text>
                  {/* <View
                    style={{
                      //flex: 1,
                      width: '100%',
                      // display: 'flex',
                      // flexDirection: 'column',
                    }}
                  > */}
                    <NTextInput
                      multiline={true}
                      maxHeight={200}
                      maxLength={maxCustomNoteLength}
                      placeholder='Your note (optional)'
                      value={customNote}
                      onChangeText={setCustomNote}
                      autoCorrect
                      inputStyle={{
                        borderWidth: 1,
                        //borderRadius: 0,
                        borderColor: Palette.lightgrey,
                        padding: 4,
                        textAlignVertical: 'bottom',
                      }}
                      errorStyle={{
                        color: Palette.black,
                      }}
                      containerStyle={{
                        paddingBottom: 0,
                      }}
                      // inputContainerStyle={{
                      //   borderBottomColor: '#DDEAEB',
                      //   borderBottomWidth: 1,
                      // }}
                      // leftIconContainerStyle={{
                      //   justifyContent: 'flex-start',
                      // }}
                      // leftIcon={
                      //   <Icon
                      //     name='pencil-outline'
                      //     type='material-community'
                      //     color={Palette.grey}
                      //   />
                      // }
                      testID={`invite-user-to-community-note-input`}
                    />
                  {/* </View> */}
                </View>
              {/* </View> */}
              {/* <Animated.View
                style={{
                  position: 'absolute',
                  display: 'flex',
                  zIndex: 200,
                  justifyContent: 'space-evenly',
                  height: '100%',
                  width: '100%',// width - (themeFromContext.slideUp.style.padding * 2),
                  transform: [
                    {
                      translateX: Animated.subtract(width, Animated.multiply(width, anim)),
                    }
                  ]
                }}
              >
                <View
                  style={{
                    paddingVertical: 20,
                    width: '100%',
                  }}
                >
                  <Text
                    style={{
                      textAlign: 'center',
                      ...themeFromContext.textVariants.body,
                      color: Palette.grey,
                    }}
                  >
                    {`If you want, we'll include a note from you in the invitation email. Preview the email `}
                    <Text
                      style={{
                        color: Palette.teal,
                        fontWeight: '700',
                      }}
                      onPress={() => toggleEmailPreview(true)}
                      testID={`invite-user-to-community-email-preview-button`}
                    >
                      {`here`}
                    </Text>
                    {`.`}
                  </Text>
                </View>
                <View
                  style={{
                    //flex: 1,
                    width: '100%',
                    // display: 'flex',
                    // flexDirection: 'column',
                  }}
                >
                  <NTextInput
                    multiline={true}
                    maxHeight={200}
                    maxLength={maxCustomNoteLength}
                    placeholder='Your note (optional)'
                    value={customNote}
                    onChangeText={setCustomNote}
                    autoCorrect
                    inputStyle={{
                      borderWidth: 0,
                      borderRadius: 0,
                      padding: 4,
                      textAlignVertical: 'bottom',
                    }}
                    inputContainerStyle={{
                      borderBottomColor: '#DDEAEB',
                      borderBottomWidth: 1,
                    }}
                    leftIconContainerStyle={{
                      justifyContent: 'flex-start',
                    }}
                    leftIcon={
                      <Icon
                        name='pencil-outline'
                        type='material-community'
                        color={Palette.grey}
                      />
                    }
                    testID={`invite-user-to-community-note-input`}
                  />
                </View>
              </Animated.View> */}
            {/* </View> */}
          </SlideUpView>
          {
            commonContext.loggedInUser && currentCommunity &&
            <EmailPreviewModal2 isVisible={showEmailPreview} onClose={() => toggleEmailPreview(false)} image={PREVIEW} />
            // <EmailPreviewModal
            //   title={`Email Preview`}
            //   isVisible={showEmailPreview}
            //   close={() => setShowEmailPreview(false)}
            // >
            //   <CommunityInvitationEmailPreview loggedInUser={commonContext.loggedInUser} communityName={currentCommunity.name} />
            // </EmailPreviewModal>
          }
          <PromptModal
            show={showGoogleModal}
            heading={`Contacts`}
            working={googleWorking}
            confirm={authorizeContacts}
            confirmButtonText={`OK, sounds good!`}
            deny={denyContacts}
            denyButtonText={`No thanks`}
            prompt={`Inviting friends and family is the most exciting part of Novella! To make it easier, let us import contacts from a Google Account.\n\nWe promise not to misuse (or even store) any of this data.\n\nIf you choose "No thanks" then you can just enter the emails by hand.`}
            testID='prompt-modal-google-contacts'
          />
          {/* <NActivityIndicatorModal show={waiting} /> */}
          <SlideUpView
            onCancel={() => setAddEmailOverlayVisible(false)}
            onCommit={pressAddAndInvite}
            buttonTitle={`Send invitation`}
            show={addEmailOverlayVisible}
            working={addAndInviteButtonRefreshing}
            icon={
              <Icon
                name='send'
                type='ionicons'
                color={Palette.plum}
                size={32}
                style={{
                  marginRight: 4,
                }}
              />
            }
          >
            <View>
              <Text
                style={{
                  ...themeFromContext.textVariants.askQuestionHeader,
                  color: themeFromContext.colors.primary,
                  textAlign: 'left',
                  paddingLeft: 10,
                }}
              >
                {`Add email for ${userToAddEmail?.user.firstName}:`}
              </Text>
            </View>
            <View>
              <Text
                style={{
                  color: themeFromContext.colors.failure,
                  fontFamily: themeFromContext.textVariants.body.fontFamily,
                  fontSize: themeFromContext.textVariants.body.fontSize,
                  textAlign: 'center',
                }}
                testID={`add-email-error-message`}
              >
                {addEmailErrorMessage}
              </Text>
            </View>
            <View>
              <NTextInput
                placeholder={`Type email...`}
                value={newEmail}
                onChangeText={setNewEmail}
                maxLength={100}
                testID={`add-email-input`}
              />
            </View>

            <View
              style={{
                paddingVertical: 20,
                width: '100%',
              }}
            >
              <Text
                style={{
                  textAlign: 'center',
                  ...themeFromContext.textVariants.body
                }}
              >
                {`If you want, we'll include a note from you in the invitation email.`}
              </Text>
            </View>

            <View>
              <Text
                style={{
                  ...themeFromContext.textVariants.askQuestionHeader,
                  color: themeFromContext.colors.primary,
                  textAlign: 'left',
                  paddingLeft: 10,
                }}
              >
                {`Your note:`}
              </Text>
            </View>
            <View>
              <NTextInput
                multiline={true}
                maxHeight={200}
                maxLength={maxCustomNoteLength}
                value={customNote}
                onChangeText={setCustomNote}
                placeholder={`Optional`}
                autoCorrect
                testID={`add-email-custom-note`}
              />
            </View>
          </SlideUpView>
        </ScrollableFlatListScreen>
      }
    </Provider>
  );
}

export default MembersScreen;
