import React, { useContext } from 'react';
import { View } from 'react-native';
import CommonContext from '../../CommonContext';
import { MAX_WIDTH, useSafeAreaInsets } from '../../business/layout/layout';
import { normalizeHeight } from '../../business/layout/responseSize';
import { Palette } from '../../Theme';
import { CommonContextType } from '../../types/CommonContextType';
import NText from './NText';

function StepHeader(props: { stepDisplayNumber: number, stepTitles: string[], floating?: boolean }): React.ReactElement {
    const commonContext = useContext<CommonContextType>(CommonContext);
    const themeFromContext = commonContext.theme;
    const { top } = useSafeAreaInsets();
    return (
        <View
            style={{
                display: 'flex',
                width: '100%',
                backgroundColor: props.floating ? themeFromContext.colors.background : Palette.plum,
                alignItems: 'center',
                paddingHorizontal: props.floating ? 0 : 20,
                paddingTop: props.floating ? 0 : top,
                // paddingVertical: 5,
            }}
        >
            <View
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    padding: 6,
                    paddingTop: 8,
                    borderRadius: 12,
                    borderWidth: 0,
                    overflow: 'hidden',
                    backgroundColor: Palette.purple,
                    //marginHorizontal: 20,
                    marginTop: props.floating ? 0 : 10,
                    marginBottom: props.floating ? 10 : 0,
                    width: '100%',
                    maxWidth: MAX_WIDTH,
                }}
            >
                {
                    props.stepTitles.map((title, index) => (
                        <View
                            style={{
                                display: 'flex',
                                flex: 1,
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                width: '100%',
                            }}
                            key={index}
                        >
                            <View
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    //justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginBottom: 4,
                                    width: '100%',
                                }}
                            >
                                <View
                                    style={{
                                        height: 4,
                                        backgroundColor: index == 0 ? Palette.transparent : index < props.stepDisplayNumber + 1 ? Palette.lightteal : themeFromContext.colors.background,
                                        width: '100%',
                                        zIndex: 1,
                                        flex: 1,
                                    }}
                                >
                                </View>
                                <View // DOT
                                    style={{
                                        borderRadius: 100,
                                        borderWidth: 0,
                                        backgroundColor: index <= props.stepDisplayNumber ? Palette.lightteal : themeFromContext.colors.background,
                                        overflow: 'hidden',
                                        padding: normalizeHeight(7, commonContext.dimensions.height),
                                        zIndex: 10,
                                    }}
                                ></View>
                                <View
                                    style={{
                                        height: 4,
                                        backgroundColor: index == props.stepTitles.length - 1 ? Palette.transparent : index < props.stepDisplayNumber ? Palette.lightteal : themeFromContext.colors.background,
                                        width: '100%',
                                        zIndex: 1,
                                        flex: 1,
                                    }}
                                >
                                </View>

                            </View>
                            <NText
                                style={{
                                    ...themeFromContext.textVariants.detail,
                                    fontSize: 10,
                                    fontWeight: 'bold',
                                    color: Palette.white,
                                    textAlign: 'center',
                                }}
                            >
                                {title}
                            </NText>
                        </View>
                    ))
                }
            </View>
        </View>
    );
}

export default StepHeader;