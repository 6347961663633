import React, { ReactElement } from 'react';
import { LayoutChangeEvent, View } from 'react-native';
import PQContent from './PQContent';
import HeadlessBody from './HeadlessBody';
import PQFooter from './PQFooter';

function PQPage(props: { hasSteps?: boolean, navigation?: any, image?: React.ReactElement, title: string, validate?: () => number, back?: () => void, next?: () => Promise<void>, nextType?: "next" | "submit" | "finish", onLayout?: (event: LayoutChangeEvent) => void, infoChildren?: ReactElement | ReactElement[], children?: ReactElement | ReactElement[] }): React.ReactElement {
    return (
        <View
            style={{
                width: '100%',
                //height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                flex: 1,
            }}
        >
            {
                props.navigation &&
                <HeadlessBody hasSteps={props.hasSteps}>
                    <PQContent image={props.image} title={props.title} back={props.back} next={props.next} nextType={props.nextType} validate={props.validate} infoChildren={props.infoChildren} onLayout={props.onLayout}>
                        {props.children}
                    </PQContent>
                    <PQFooter />
                </HeadlessBody>
            }
            {
                !props.navigation &&
                <PQContent image={props.image} title={props.title} back={props.back} next={props.next} nextType={props.nextType} validate={props.validate} infoChildren={props.infoChildren} onLayout={props.onLayout}>
                    {props.children}
                </PQContent>
            }
        </View>
    );
}

export default PQPage;