import { TUSUpload } from "./TUSUpload";

export interface IUploadListener<T> {
  name: string;
  listen(upload: T): Promise<void>;
}


// SINGLETON
export const UploadListenerLibrary = (function() {
  let listeners: IUploadListener<TUSUpload>[] = [];

  function privateMethod () {
    // ...
  }

  return { // public interface
    addListener(listener: IUploadListener<TUSUpload>) : void {
      const idx = listeners.findIndex(l => l.name == listener.name);
      if (idx > -1) {
        listeners[idx] = listener;
      } else {
        listeners = [ ...listeners, listener ];
      }
    },
    getListeners() : IUploadListener<TUSUpload>[] {
      return listeners;
    },
    publicMethod1: function () {
      // All private members are accessible here
    },
    publicMethod2: function () {
      // whatever
    }
  };
})();
