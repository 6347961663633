import { Community } from "../../API";
import { CommonContextType } from "../../types/CommonContextType";
import { ActivityItem, ActivityType } from "../../views/screens/ActivityHistory/types";
import { getPowers } from "../answers/answerPostPowerRules";
import { extractVisibility } from "../answers/videoMetadata";

export async function ActivityItemsFromCommunity(
  community: Community,
  //sortBy: ActivitySort,
  //includeQuestions: boolean,
  //includeAnswers: boolean,
  //includeLikes: boolean,
  //includeComments: boolean,
  //includeJoins: boolean,
  //includeLoggedInUserActivity: boolean,
  commonContext: CommonContextType): Promise<ActivityItem[]|undefined>
{

  if (!community) {
    return;
  }

  const role = await commonContext.services.communityService.getLoggedInUserCommunityRole(community.id);

  //get the "group" that the user is in.  This is so a SUPERVIEWER can view videos that only the organizer/spotlight can see (started with the GH community)
  const communityGroup = commonContext.isAdmin == false ? commonContext.loggedInUser?.communityUsersByStatusDate?.items.find(cu => cu?.communityID == community.id)?.group : "";
  

  let items: ActivityItem[] = [];

  const creation: ActivityItem = {
    key: community.id,
    actor: undefined,
    item: undefined,
    targetUserName: '',
    targetItemType: undefined,
    targetItem: undefined,
    activityType: ActivityType.CommunityCreation,
    excerpt: undefined,
    timestamp: community.createdAt,
    likedByLoggedInUser: false,
    likeCount: 0,
    commentCount: 0,
  }

  let joins: ActivityItem[] = [];
  const members = community.communityUsersByStatusDate?.items;
  //if (includeJoins) {
    members?.forEach(member => {
      if (member == null) return;

      //if (includeLoggedInUserActivity || member.userID != commonContext.loggedInUser?.id) {
        const j: ActivityItem = {
          key: member.id,
          actor: member.user,
          item: undefined,
          targetUserName: commonContext.services.userService.getFullName(member.user),
          targetItemType: undefined,
          targetItem: undefined,
          activityType: ActivityType.CommunityJoin,
          excerpt: undefined,
          timestamp: member.joinedOn,
          likedByLoggedInUser: false,
          likeCount: 0,
          commentCount: 0,
        }
        joins = [...joins, j];
      //}
    });
  //}

  const questions = community.communityQuestionsByStatusDate?.items;
  questions?.forEach(question => {
    if (question == null) return;
    
    // process question, starting with likes and then the question itself

    // question likes
    let questionLikedByLoggedInUser = false;
    let questionLikes: ActivityItem[] = [];

    //if (includeLikes) {
      question.communityQuestionLikesByStatus?.items.forEach(like => {
        if (like == null) return;

        //if (includeLoggedInUserActivity || like.userID != commonContext.loggedInUser?.id) {
          if (commonContext.loggedInUser && like?.userID == commonContext.loggedInUser.id) {
            questionLikedByLoggedInUser = true;
          }

          // let thing = commonContext.services.userService.getFullName(like?.user);
          // thing = thing.trim();
          // if (thing.length == 0) {
          //   log.info(JSON.stringify(like, null, 2));
          // }
          const l: ActivityItem = {
            key: like.id,
            actor: like.user,
            item: undefined,
            targetUserName: commonContext.services.userService.getFullName(question.user),
            targetItemType: "Question",
            targetItem: question,
            activityType: ActivityType.Like,
            excerpt: question.questionText,
            timestamp: like.date,
            likedByLoggedInUser: false,
            likeCount: 0,
            commentCount: 0,
          }
          questionLikes = [...questionLikes, l];
        //}
      });
    //}

    // question
    const q: ActivityItem
    //if (includeQuestions && (includeLoggedInUserActivity || question.userID != commonContext.loggedInUser?.id)) {
      = {
        key: question.id,
        actor: question.user,
        item: question,
        targetUserName: question.community.name,
        activityType: ActivityType.Question,
        excerpt: question.questionText,
        timestamp: question.date,
        targetItemType: undefined,
        targetItem: undefined,
        likedByLoggedInUser: questionLikedByLoggedInUser,
        likeCount: questionLikes.length,
        commentCount: 0,
      };
    //}

    // process answers, starting with the likes and comments, then the answers themselves

    // answer likes and comments
    let answerItems: ActivityItem[] = [];
    question.communityQuestionAnswersByStatusDate?.items.forEach(answer => {
      if (answer == null) return;

      let answerLikes: ActivityItem[] = [];
      let answerComments: ActivityItem[] = [];

      // honor answer visibility
      const loggedInUserRole = commonContext.isAdmin ? "ADMIN" : role;
      const isOriginalAuthor = answer.userID == commonContext.loggedInUser?.id;
      const communityRequiresModeration = community.requiresModeration;
      const currentPostVisibility = extractVisibility(answer.content);
  
      const powers = getPowers(loggedInUserRole, isOriginalAuthor, communityRequiresModeration, currentPostVisibility, communityGroup);

      // log.info(`canSee: ${powers.canSee}`);

      if (powers.canSee) {
        let answerLikedByLoggedInUser = false;
        //if (includeLikes) {
          answer.communityQuestionAnswerLikesByStatus?.items.forEach(answerLike => {
            if (answerLike == null) return;
            //if (includeLoggedInUserActivity || answerLike.userID != commonContext.loggedInUser?.id) {
              if (commonContext.loggedInUser && answerLike.userID == commonContext.loggedInUser.id) {
                answerLikedByLoggedInUser = true;
              }
              const l: ActivityItem = {
                key: answerLike.id,
                actor: answerLike.user,
                item: undefined,
                targetUserName: commonContext.services.userService.getFullName(answer.user),
                activityType: ActivityType.Like,
                excerpt: undefined,
                timestamp: answerLike.date,
                targetItemType: "Answer",
                targetItem: answer,
                likedByLoggedInUser: false,
                likeCount: 0,
                commentCount: 0,
              }
              answerLikes = [...answerLikes, l];
            //}
          });
        //}

        //if (includeComments) {
          answer.communityQuestionAnswerCommentsByStatusDate?.items.forEach(comment => {
            if (comment == null) return;
            //if (includeLoggedInUserActivity || comment.userID != commonContext.loggedInUser?.id) {
              const c: ActivityItem = {
                key: comment.id,
                actor: comment.user,
                targetUserName: commonContext.services.userService.getFullName(answer.user),
                activityType: ActivityType.Comment,
                excerpt: comment.content,
                timestamp: comment.date,
                targetItemType: "Answer",
                targetItem: answer,
                likedByLoggedInUser: false,
                likeCount: 0,
                commentCount: 0,
              };
              answerComments = [...answerComments, c];
            //}
          });
        //}

        // the answer itself
        const a: ActivityItem
        
        //if (includeAnswers && (includeLoggedInUserActivity || answer.userID != commonContext.loggedInUser?.id)) {
          = {
            key: answer.id,
            actor: answer.user,
            item: answer,
            targetUserName: commonContext.services.userService.getFullName(question.user),
            activityType: ActivityType.Answer,
            excerpt: question.questionText,
            timestamp: answer.date,
            targetItemType: "Question",
            targetItem: question,
            likedByLoggedInUser: answerLikedByLoggedInUser,
            likeCount: answerLikes.length,
            commentCount: answerComments.length,
          };
          answerItems = [...answerItems, a, ...answerLikes, ...answerComments];
        //}
      }
    });

    // handle filters for question-owned items
    items = [
      ...items,
      ...answerItems,
      ...(q ? [ q ] : []),
      ...questionLikes,
    ];
  });

  // handle filters for community-owned items
  items = [ ...items, creation ];
  items = [ ...items, ...joins ];

  // handle sort
  // if (sortBy == ActivitySort.Popularity) {
  //   items.sort((a, b) => {
  //     const ascore = calculateScore(a);
  //     const bscore = calculateScore(b);
  //     //community creation always needs to show up first
  //     if (a.activityType == ActivityType.CommunityCreation) {
  //       return 1;
  //     } else if (b.activityType == ActivityType.CommunityCreation) {
  //       return -1;
  //     } else if (ascore < bscore) {
  //       return 1;
  //     } else if (ascore > bscore) {
  //       return -1;
  //     } else {
  //       if (a.timestamp < b.timestamp) { // fallback to timestamp
  //         return 1;
  //       } else if (a.timestamp > b.timestamp) {
  //         return -1;
  //       } else {
  //         return 0;
  //       }
  //     }
  //   });
  // } else {
  //   // newest first
  //   items.sort((a, b) => {
  //     //community creation always needs to show up first
  //     if (a.activityType == ActivityType.CommunityCreation) {
  //       return 1;
  //     } else if (b.activityType == ActivityType.CommunityCreation) {
  //       return -1;
  //     } else if (a.timestamp < b.timestamp) {
  //       return 1;
  //     } else if (a.timestamp > b.timestamp) {
  //       return -1;
  //     } else {
  //       return 0;
  //     }
  //   });
  // }

  return items;
}