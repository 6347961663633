// Frameworks
import React, { useContext, useEffect, useState } from 'react';
import { IButtons, ThemeType } from '../../../types/Theme';
import { DrawerScreenProps } from '@react-navigation/drawer';
import { RootStackParamList } from '../../../types/Navigation';
import { Image, Platform, View } from 'react-native';
import log from '../../../business/logging/logger';
import { useLinkTo } from '@react-navigation/native';
import { TabView } from 'react-native-tab-view';
import { WELCOME_SEQUENCES } from '../../../../assets/welcome/Screens';
import { WelcomeSequence, WelcomeSequencePage, } from '../../../../assets/welcome/WelcomeScreensDefinitions';
import NButton from '../../components/NButton';
import NText from '../../components/NText';
import { normalizeHeight, normalizeWidth } from '../../../business/layout/responseSize';
import NActivityIndicator from '../../components/ActivityIndicators/NActivityIndicator';
import { useSafeAreaInsets } from '../../../business/layout/layout';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { Palette } from '../../../Theme';
import { CommunityType } from '../../../API';
import { setBypass } from '../../../business/redirect/bypass';

type WelcomeProps = DrawerScreenProps<RootStackParamList, 'Welcome'>;

const imageWidth = 1200;
const imageHeight = 700;
const maxImageWidth = 500;

function Dot(props: { active: boolean, activeColor: string }): React.ReactElement {
  const inactiveColor = 'rgba(0,0,0,.2)';
  return (
    <View
      style={{
        backgroundColor: props.active ? props.activeColor : inactiveColor,
        width: 8,
        height: 8,
        borderRadius: 4,
        margin: 3,
      }}
    />
  );
}

function PagingControls(props: { count: number, activeIndex: number, activeColor: string, buttonType: keyof IButtons, onNext: () => void, onPrev: () => void, onFinish: () => void } & { children?: React.ReactNode }): React.ReactElement {
  if (props.activeIndex > props.count - 1) {
    throw `activeIndex is the zero-based index of the active dot. Can't use activeIndex=${props.activeIndex} when count=${props.count}`;
  }
  if (props.activeIndex < 0) {
    throw `activeIndex is the zero-based index of the active dot. Can't use activeIndex=${props.activeIndex}`;
  }

  const dots = [];
  for (let i = 0; i < props.count; i++) {
    dots.push(
      <Dot key={i} active={i == props.activeIndex} activeColor={props.activeColor} ></Dot>
    )
  }

  const showPrev = props.activeIndex > 0;
  const showNext = props.activeIndex < props.count - 1;

  return (
    <View
      style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        {dots}
      </View>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          maxWidth: '100%',
          justifyContent: 'space-around',
          //alignItems: 'flex-end',
          flex: 1,
          height: '100%',
          marginTop: 20
        }}
      >
        <NButton
          style={{
            marginHorizontal: 30,
            height: '100%'
          }}
          hidden={!showPrev}
          t={props.buttonType}
          title={`PREV`}
          onPress={() => {
            log.info("PREV");
            props.onPrev();
          }}
          testID='welcome-previous-button'
        />
        {
          showNext &&
          <NButton
            style={{
              marginHorizontal: 30,
              height: '100%'
            }}
            hidden={!showNext}
            t={props.buttonType}
            title={`NEXT`}
            onPress={() => {
              log.info("NEXT");
              props.onNext();
            }}
            testID='welcome-next-button'
          />
        }
        {
          !showNext &&
          <NButton
            style={{
              marginHorizontal: 30,
              height: '100%'
            }}
            t='primary'
            title={`DONE`}
            onPress={props.onFinish}
            testID='welcome-primary-button'
          />
        }
      </View>
    </View>
  );
}

function SwiperPage(props: { slide: WelcomeSequencePage | undefined, theme: ThemeType | undefined, layout: { width: number, height: number }, index: number, count: number, onSequenceComplete: () => void, testID?: string }): React.ReactElement {

  const imgPd = normalizeWidth(40, props.layout.width);
  const w = Math.min(maxImageWidth, props.layout.width - imgPd);
  const h = imageHeight * (w / imageWidth)

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}
      testID={props.testID}
    >
      {
        props.slide &&
        <Image
          source={props.slide?.imageSource}
          resizeMode='contain'
          style={{
            width: w,
            height: h,
            marginTop: normalizeHeight(30, props.layout.height),
            marginBottom: normalizeHeight(30, props.layout.height),
          }}
        />
      }

      <NText
        style={{
          color: props.theme?.colors.foreground,
          paddingTop: normalizeHeight(20, props.layout.height),
          paddingHorizontal: normalizeWidth(50, props.layout.width),
          textAlign: 'center',
          ...props.theme?.textVariants.header,
          fontWeight: 'normal',
          marginBottom: normalizeHeight(20, props.layout.height),
        }}
        testID='welcome-heading'
      >
        {props.slide?.heading}
      </NText>
      <NText
        style={{
          color: props.theme?.colors.foreground,
          textAlign: 'center',
          paddingHorizontal: normalizeWidth(30, props.layout.width),
          ...props.theme?.textVariants.body,
          fontSize: 18,
          fontWeight: '300',
          marginBottom: normalizeHeight(10, props.layout.height),
        }}
        testID='welcome-body'
      >
        {props.slide?.body}
      </NText>
    </View>
  );
}


function WelcomeScreen({ route, navigation }: WelcomeProps): React.ReactElement {
  const requested = route.params?.requested;
  const commonContext = useContext<CommonContextType>(CommonContext);
  const { userService } = commonContext.services;
  const themeFromContext = commonContext.theme;
  const linkTo = useLinkTo();
  //const [loggedInUser, setLoggedInUser] = useState<User>();
  const { width, height } = commonContext.dimensions;
  const [slides, setSlides] = useState<WelcomeSequencePage[]>();
  const [sequenceName, setSequenceName] = useState('');
  const [remainingWelcomeSequences, setRemainingWelcomeSequences] = useState<WelcomeSequence[]>([]);
  const [communityType, setCommunityType] = useState(CommunityType.DEFAULT);
  const { top } = useSafeAreaInsets();
  const [index, setIndex] = useState(0);
  //const [sceneMap, setSceneMap] = useState({});
  const [routes, setRoutes] = useState<any[]>([]);

  useEffect(() => {
    if (!commonContext.loggedInUser) {
      setTimeout(() => {
        linkTo(`/signin`);
      }, 500);
    }

    // get the communityID of the first community the person was invited to
    const cid = commonContext.loggedInUser?.communityUsersByStatusDate?.items.filter(x => x != null && x != undefined).sort((a, b) => Date.parse(a?.invitedOn) - Date.parse(b?.invitedOn))[0]?.communityID;
    log.info(cid);
    if (cid) {
      const ct = commonContext.communities[cid].type ?? CommunityType.DEFAULT;
      setCommunityType(ct);
    } else {
      setCommunityType(CommunityType.DEFAULT);
    }
  }, []);

  useEffect(() => {
    if (!commonContext.loggedInUser) {
      setTimeout(() => {
        linkTo(`/signin`);
      }, 500);
    }

    // get all the welcomes the logged-in user has already seen
    const seen = commonContext.loggedInUser?.welcomesSeen ? commonContext.loggedInUser.welcomesSeen.map(s => s.toUpperCase()) : [];

    // if the user navigated here manually (requested == true) then we don't care if he's already seen the welcomes -- we'll show them anyway
    // but, if the user did NOT request to come here, then we'll only show welcomes he has NOT seen yet
    const notSeen = requested ? WELCOME_SEQUENCES.concat([]) : WELCOME_SEQUENCES.filter(s => !seen.includes(s.name.toUpperCase()));
    handleWelcomeSequences(notSeen, communityType);
  }, [requested, communityType]);

  const handleWelcomeSequences = (notSeen: WelcomeSequence[], communityType: CommunityType) => {
    const current = notSeen.shift(); // like "dequeue"

    if (!current) {
      // no more welcome sequences, so reset and go to the home page
      setIndex(0);
      //linkTo(`/communities/${userId}/`);
      setBypass(true); // don't show welcomes on this device anymore
      navigation.navigate('Home');
    } else {
      log.info(`remaining:`);
      log.info(notSeen);

      // save any remaining welcome sequences for the next cycle
      setRemainingWelcomeSequences(notSeen);

      // save slides for current welcome sequence
      setSlides(current.welcomesByCommunityType[communityType]);
      setSequenceName(current.name);

      // Build routes for current welcome sequence
      let routes: any[] = [];
      for (let i = 0; i < current.welcomesByCommunityType[communityType].length; i++) {
        routes = [
          ...routes,
          { key: i, title: i }
        ];
      }
      setRoutes(routes);
    }
  }

  // This is implemented this way based on the recommendations here: https://github.com/satya164/react-native-tab-view
  const renderScene = ({ route }: { route: any }) => {
    return <SwiperPage
      slide={slides ? slides[route.key] : undefined}
      theme={themeFromContext}
      layout={{
        width: width,
        height: height
      }}
      index={route.key}
      count={slides ? slides.length : 0}
      onSequenceComplete={() => {

        // done with sequence
        // don't need to await?
        if (commonContext.loggedInUser && sequenceName.length > 0) {
          userService.flagWelcomeAsSeen(sequenceName, commonContext.loggedInUser);
        }

        handleWelcomeSequences(remainingWelcomeSequences, communityType);
      }}
      testID={`welcome-slide-${route.key}`}
    />
  }

  const goToNext = () => {
    if (slides) {
      if (index < slides.length - 1) {
        setIndex(index + 1);
      }
    }
  }

  const goToPrev = () => {
    if (slides) {
      if (index > 0) {
        setIndex(index - 1);
      }
    }
  }

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: top,
        // padding: normalizeHeight(20, height),
      }}
    >
      {
        !slides &&
        <NActivityIndicator />
      }
      {
        slides &&
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            flex: 1,
          }}
        >
          <View
            style={{
              flex: 5,
              height: '100%',
              width: '100%',
            }}
          >

            <TabView
              navigationState={{ index, routes }}
              renderScene={renderScene}
              onIndexChange={setIndex}
              renderTabBar={() => null}
              swipeEnabled={Platform.OS == 'ios' || Platform.OS == 'android'}
            //initialLayout={{width: width}}
            />
          </View>

          <View
            style={{
              marginTop: normalizeHeight(40, height),
              width: '100%',
              flex: 1,
            }}
          >
            <PagingControls
              count={slides ? slides.length : 1}
              activeColor={themeFromContext.colors.primary ?? Palette.lightteal}
              activeIndex={index}
              buttonType={'primary'}
              onNext={goToNext}
              onPrev={goToPrev}
              onFinish={() => {

                // done with sequence
                // don't need to await?
                if (commonContext.loggedInUser && sequenceName.length > 0) {
                  userService.flagWelcomeAsSeen(sequenceName, commonContext.loggedInUser);
                }

                handleWelcomeSequences(remainingWelcomeSequences, communityType);
              }}
            />
          </View>
        </View>
      }

    </View>
  );
}
export default WelcomeScreen;
