// Frameworks
import React, { useContext } from 'react';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import NText from '../NText';
import { Palette } from '../../../Theme';
import { normalizeHeight } from '../../../business/layout/responseSize';
import { LayoutChangeEvent } from 'react-native';

function PQTitle(props: { text: string, onLayout?: (event: LayoutChangeEvent) => void }) : React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;

  return (
    <NText
      style={{
        textAlign: 'center',
        ...themeFromContext.textVariants.wizardSubHeader,
        color: Palette.purple,// themeFromContext.colors.text,
        marginTop: normalizeHeight(14, commonContext.dimensions.height),
        marginBottom: 8,
        paddingHorizontal: 20,
        //lineHeight: '1.1em',
        //flexShrink: 1,
      }}
      onLayout={props.onLayout}
    >
      {props.text}
    </NText>
  );
}
export default PQTitle;