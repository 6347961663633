import log from '../logging/logger';
import { API } from 'aws-amplify';
import awsConfig from '../../aws-exports';
import { CommunityQuestion } from '../../API';
API.configure(awsConfig);

const API_NAME = `novellaREST`;

export async function getQuestionUnauthenticated(communityQuestionId: string): Promise<CommunityQuestion | undefined>
{
  const apiName = API_NAME;
  const path = `/unauthenticatedQuestion`;
  const init = {
    queryStringParameters: { communityQuestionId: communityQuestionId},
    body: null // this is soooo important for a GET request...
  }

  try {
    const question: CommunityQuestion = await API.get(apiName, path, init);

    // log.info(`GOT UNAUTHENTICATED QUESTION RESPONSE:`);
    // log.info(question);
    return question;
  } catch (ex) {
    //log.error(`ERROR TRYING TO GET UNAUTHENTICATED QUESTION:`)
    log.error(ex);
  }
}