import { CommunityQuestion, CommunityQuestionAnswer, User } from "../../../API";

export type ActivityItem = {
  key: string,
  actor: User|undefined,
  item: CommunityQuestion | CommunityQuestionAnswer | undefined,
  targetUserName: string,
  targetItemType: "Answer" | "Question" | undefined,
  targetItem: CommunityQuestion | CommunityQuestionAnswer | undefined,
  activityType: ActivityType,
  excerpt: string | undefined,
  timestamp: string,
  likedByLoggedInUser: boolean,
  likeCount: number,
  commentCount: number,
}

export enum ActivityType {
  Like,
  Comment,
  Answer,
  Question,
  CommunityJoin,
  CommunityCreation,
}

export enum ActivitySort {
  Popularity = "Most Popular",
  Time = "Most Recent"
}