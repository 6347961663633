import React, { useContext, useEffect, useRef, useState } from 'react'
import { Image, Platform, Pressable, SafeAreaView, TextInput, View } from 'react-native'
import { Auth } from 'aws-amplify'
import { RootStackParamList } from '../../../../types/Navigation';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import AuthPageInput from '../AuthPageInput';
import { isValidEmail } from '../../../../business/user/userHelper';
import { Button, Icon, Text } from 'react-native-elements';
import { CommonContextType } from '../../../../types/CommonContextType';
import CommonContext from '../../../../CommonContext';
import NButton from '../../../components/NButton';
import { Palette } from '../../../../Theme';
import log from '../../../../business/logging/logger';
import { onPressEnterAsync } from '../../../../business/input/keyPress';
import { DrawerScreenProps } from '@react-navigation/drawer';
import { setAutoJoin } from '../../../../business/public/autoJoin';
import { getCurrentEnv } from '../../../../business/env/currentEnv';
import { getMostRecentUser } from '../../../../business/user/userMemory';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { DISPLAY, PHONE_BREAK, TABLET_BREAK, useSafeAreaInsets } from '../../../../business/layout/layout';
import PQFooter from '../../../components/OnePersonOneQuestion/PQFooter';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import SlideUpView2 from '../../../components/Modals/SlideUpView2';
import VimeoPlayer from '../../../components/VimeoPlayer/VimeoPlayer';

const fallbackVideo = require('../../../../../assets/introducing_novella.mp4');

export type SignIn2InnerProps = DrawerScreenProps<RootStackParamList, 'Sign In'>;

function SignIn2Inner(props: SignIn2InnerProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const [email, setEmail] = useState('');
  const [confirmed, setConfirmed] = useState(false);
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [signInButtonRefreshing, setSignInButtonRefreshing] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  // const [facebookLoading, setFacebookLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const pwdRef = useRef<TextInput>(null);
  const linkTo = useLinkTo();
  const [env, setEnv] = useState(getCurrentEnv());
  const { width, height } = commonContext.dimensions;
  const display = width > TABLET_BREAK ? DISPLAY.WEB : width <= PHONE_BREAK ? DISPLAY.PHONE : DISPLAY.TABLET;
  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const { top, bottom } = useSafeAreaInsets();
  
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      // analytics
      commonContext.analytics?.viewPage('Sign In', {});
    }
  }, [isFocused]);

  useEffect(() => {
    setSignInButtonRefreshing(false);

    if (props.route.params?.confirmed) {
      setConfirmed(props.route.params.confirmed);
    } else {
      setConfirmed(false);
    }
    if (props.route.params?.c) {
      setAutoJoin(props.route.params.c);
    }

    let e = '';
    if (props.route.params?.email) {
      e = props.route.params.email;
    }
    if (e.length == 0) {
      const mostRecent = getMostRecentUser();
      if (mostRecent) {
        e = mostRecent;
      }
    }
    setEmail(e);
  }, [props])

  const clearErrors = () => {
    setEmailError('');
    setPasswordError('');
  }

  const signIn = async () => {
    clearErrors();
    let errored = false;
    if (!isValidEmail(email)) {
      setEmailError(`Please enter a valid email address`);
      errored = true;
    }

    if (password.trim().length == 0) {
      setPasswordError(`Please enter a password`);
      errored = true;
    }

    if (errored) {
      return;
    }

    try {
      setSignInButtonRefreshing(true);
      await Auth.signIn(email, password);
    } catch (err: any) {
      if (err.name == 'UserNotFoundException' || err.name == 'UserNotConfirmedException' || err.name == 'NotAuthorizedException' || err.name == 'InvalidParameterException') {
        setPasswordError(`Invalid email / password combination`);
      } else if (err.name == 'PasswordResetRequiredException') {
        clearErrors();
        linkTo(`/forgotPassword?email=${email}&required=true`);
      } else {
        //log.info(`Unknown sign-in error: ${err.name}`);
        setPasswordError(`Unknown error '${err.name}: ${err.message}! Please try again...`);
        log.info(JSON.stringify(err, null, 2));
      }
      setSignInButtonRefreshing(false);
    }
  }

  function goTo1p1q(): void {
    linkTo(`/fpq`);
  }

  return (
    <SafeAreaView style={{
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      flex: 1,
      zIndex: 1,
    }}>
      <Pressable
        style={{
          position: 'absolute',
          top: top + 30,
          left: 20,
          zIndex: 2,
          borderRadius: 50,
          overflow: 'hidden',
          backgroundColor: Palette.white.concat('33'),
          height: 50,
          width: 50,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onPress={() => {
          console.log('go back');
          props.navigation.goBack();
        }}
      >
        <Icon
          name='arrow-back'
          type='ionicon'
          color={Palette.white}
          size={30}
        />
      </Pressable>
      <KeyboardAwareScrollView
        style={{
          //alignSelf: 'center',
          flex: 1,
          overflow: 'visible',
        }}
        contentContainerStyle={{
          //flexGrow: 1,
          //justifyContent: 'space-around',
          display: 'flex',
          overflow: 'visible',
          // minHeight: '100%',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1,
        }}
      >
        <View
          style={{
            // flex: 1,
            flexGrow: display == DISPLAY.PHONE ? 1 : 0,
            width: '100%',
            maxWidth: display == DISPLAY.PHONE ? '100%' : 400,
            alignItems: 'center',
            //backgroundColor: themeFromContext.colors.background,
            borderRadius: 10,
            paddingHorizontal: 30,
            // paddingTop: top,
            // paddingBottom: bottom,
            justifyContent: 'center',
            marginLeft: 0,
            zIndex: 1,
            overflow: 'visible',
          }}
        >
          <Image
            source={require('../../../../../assets/logo-white-teal-alpha.png')}
            resizeMode='contain'
            style={{ height: height / 10, width: '100%', }}
          />

          {/* <View
            style={{
              display: 'flex',
            }}
          >
            <NButton
              t={'action'}
              title={`Try Novella for Free`}
              iconPosition={'left'}
              icon={
                <Icon
                  name='add'
                  type='ionicon'
                  color={Palette.plum}
                  size={height / 28}
                />
              }
              onPress={goTo1p1q}
            />
            <NButton
              t={'primary2'}
              title={`How it Works`}
              style={{
                width: '100%',
              }}
              onPress={() => setShowHowItWorks(true)}
            />
          </View> */}

          {confirmed &&
            <Text
              style={{
                color: Palette.lightyellow,
                ...themeFromContext.textVariants.body,
                fontWeight: '700',
                marginTop: 20,
              }}
            >
              {`Check your email for a confirmation link, then come back here to sign in.`}
            </Text>
          }

          <View
            style={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
            }}
          >


            <Text
              style={{
                ...themeFromContext.textVariants.askQuestionHeader,
                color: Palette.white,
                width: '100%',
                textAlign: 'left',
                marginBottom: 10,
                marginTop: 25,
                //paddingVertical: 20,
              }}
            >
              {`Sign in to Novella`}
            </Text>

            <AuthPageInput
              //label='Email *'
              testID='sign-in-email-input'
              value={email}
              onChangeText={setEmail}
              placeholder='Enter email'
              autoCompleteType='email'
              keyboardType='email-address'
              textContentType='emailAddress'
              autoCapitalize='none'
              autoCorrect={false}
              errorMessage={emailError}
              inputStyle={{
                borderRadius: 10,
                borderColor: Palette.white,
                borderWidth: 2,
                paddingHorizontal: 16,
                paddingVertical: 8,
                ...themeFromContext.textVariants.body,
                color: Palette.white,
                backgroundColor: Palette.white.concat('33'),
              }}
              placeholderTextColor={Palette.white.concat('aa')}
            />
            <AuthPageInput
              testID='sign-in-password-input'
              ref={pwdRef}
              onChangeText={setPassword}
              placeholder='Enter password'
              autoCompleteType='password'
              //keyboardType='visible-password'
              textContentType='password'
              autoCapitalize='none'
              autoCorrect={false}
              secureTextEntry
              errorMessage={passwordError}
              onKeyPress={async (e) => onPressEnterAsync(e, signIn)}
              inputStyle={{
                borderRadius: 10,
                borderColor: Palette.white,
                borderWidth: 2,
                paddingHorizontal: 16,
                paddingVertical: 8,
                ...themeFromContext.textVariants.body,
                color: Palette.white,
                backgroundColor: Palette.white.concat('33'),
              }}
              placeholderTextColor={Palette.white.concat('aa')}
            />
            <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
              <Button
                testID='sign-in-forgot-password-button'
                buttonStyle={{
                  backgroundColor: Palette.transparent,
                  marginTop: 0,
                  marginBottom: 20,
                  marginRight: 10,
                  padding: 0,
                  borderWidth: 0
                }}
                titleStyle={{
                  //marginVertical: 8,
                  paddingVertical: 0,
                  color: Palette.white,
                  alignSelf: 'flex-start',
                  ...themeFromContext.textVariants.inputLabel,
                  fontWeight: 'bold',
                  textDecorationLine: 'underline',
                  textDecorationStyle: 'solid',
                }}
                onPress={() => {
                  clearErrors();
                  linkTo(`/forgotPassword${email ? '?email=' + email : ''}`);
                }}
                title='Forgot password?'
              />
            </View>
            {/* <AuthPageTerms textColor={Palette.white} /> */}
            <NButton
              testID='sign-in-sign-in-button'
              //disabled={!isValidEmail(email) || password.length == 0}
              //containerStyle={{ padding: 10, width: '100%' }}
              buttonStyle={{ marginBottom: 8 }}
              t='primary2'
              onPress={signIn}
              loading={signInButtonRefreshing}
              loadingProps={{
                color: Palette.plum,
              }}
              title={`Sign in`}
            />
            <Text
              style={{
                ...themeFromContext.textVariants.detail,
                color: themeFromContext.colors.secondary,
                marginBottom: 5,
              }}
            >
              {`OR`}
            </Text>
            <NButton
              testID='sign-in-google-button'
              //containerStyle={{ paddingHorizontal: 10, paddingVertical: 5,  }}
              loading={googleLoading}
              loadingProps={{
                animating: true,
                color: Palette.white,
                size: 'small',
              }}
              //buttonStyle={{paddingVertical: 8}}
              buttonStyle={{
                borderColor: Palette.white,
              }}
              t={"secondary2"}
              title={
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignContent: 'center',
                    //width: '100%',
                    alignItems: 'center',
                  }}
                >

                  <Image
                    source={require('../../../../../assets/social/g_25.png')}
                    style={{
                      height: 25,
                      width: 25,
                      marginRight: 6
                    }}
                  />
                  <Text
                    style={{
                      textAlign: 'center',
                      ...themeFromContext.textVariants.authSecondaryButton,
                      //fontWeight: themeFromContext.textVariants.detail.fontWeight,
                      color: Palette.white,
                      //color: themeFromContext.colors.foreground,
                    }}
                  >
                    {`Sign in with Google`}
                  </Text>
                </View>
              }
              onPress={() => {
                setGoogleLoading(true);
                Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
              }}
            />

            <NButton
              testID='sign-in-apple-button'
              //containerStyle={{ paddingHorizontal: 10, paddingVertical: 5, width: '100%' }}
              loading={appleLoading}
              loadingProps={{
                animating: true,
                color: Palette.white,
                size: 'small',
              }}
              buttonStyle={{
                borderColor: Palette.white,
              }}
              //buttonStyle={{paddingVertical: 16}}
              t={"secondary2"}
              title={
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignContent: 'center',
                    //width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    source={require('../../../../../assets/social/a25.png')}
                    style={{
                      height: 25,
                      width: 25,
                      marginRight: 6,
                      marginBottom: 3,
                      tintColor: Palette.white,
                    }}
                  />
                  <Text
                    style={{
                      textAlign: 'center',
                      ...themeFromContext.textVariants.authSecondaryButton,
                      //fontWeight: themeFromContext.textVariants.detail.fontWeight,
                      color: Palette.white,
                      //color: themeFromContext.colors.foreground,
                    }}
                  >
                    {`Sign in with Apple`}
                  </Text>
                </View>
              }
              onPress={() => {
                setAppleLoading(true);
                Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple });
              }}
            />

            <View
              style={{
                width: '80%',
                borderTopWidth: 1,
                borderTopColor: Palette.lightgrey,
                marginTop: 15,
              }}
            >
            </View>

            {
              //((env != 'production' && env != 'prod') || Platform.OS == 'ios') &&
              <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                  <Text
                    style={{
                      //marginEnd: 8,
                      marginVertical: 8,
                      color: Palette.white,
                      ...themeFromContext.textVariants.inputLabel
                    }}>
                    {`Don't have an account? `}
                  </Text>
                  <Button
                    testID='sign-in-create-account-button'
                    buttonStyle={{
                      backgroundColor: Palette.transparent,
                      marginVertical: 0,
                      padding: 0,
                      borderWidth: 0
                    }}
                    titleStyle={{
                      marginVertical: 8,
                      paddingVertical: 0,
                      color: Palette.lightteal,
                      alignSelf: 'flex-start',
                      ...themeFromContext.textVariants.inputLabel
                    }}
                    onPress={() => {
                      clearErrors();
                      linkTo(`/signUp${email ? '?email=' + email : ''}`)
                    }}
                    title='Create one now'
                  />
                </View>
              </View>
            }
          </View>
          <View
            style={{
              marginTop: 10,
            }}
          >
            <PQFooter />
          </View>
        </View>
      </KeyboardAwareScrollView>
      <SlideUpView2
        show={showHowItWorks}
        onDone={async () => setShowHowItWorks(false)}
        doneButtonIcon={
          <Icon
            name='times-circle'
            type='font-awesome'
            color={'#313067'}
            size={30}
          />
        }
      >
        <Text style={[commonContext.theme.textVariants.header, { textAlign: 'center', fontSize: 18, marginTop: 10, marginBottom: 20, }]}>{`How It Works`}</Text>
        <VimeoPlayer
          embedUrl={`https://player.vimeo.com/video/778647119?h=cf51a0b614&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
          asset={fallbackVideo}
          height={232}
          width={131}
          transcodingStatus={'complete'}
          heightConstrained={false}
        />
      </SlideUpView2>
    </SafeAreaView>
  )
}

export default SignIn2Inner;
