import React, { useContext } from 'react'
import { View, StyleSheet, Linking } from 'react-native'
import { Text } from 'react-native-elements';
import CommonContext from '../../../CommonContext';
import { CommonContextType } from '../../../types/CommonContextType';

function AuthPageTerms(props: { textColor?: string }): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { textColor } = props;

  const styles = StyleSheet.create({
    body: {
      ...themeFromContext.textVariants.detail,
      color: textColor ?? themeFromContext.colors.secondary,
      textAlign: 'center'
      //flexWrap: 'wrap'
    },
    link: {
      ...themeFromContext.textVariants.detail,
      fontWeight: 'bold',
      color: textColor ?? themeFromContext.colors.secondary,
      textDecorationLine: 'underline',
    }
  });

  return (
    <View
      style={{
        marginTop: 10,
        marginHorizontal: 10,
        alignItems: 'center',
      }}
    >
      <Text style={styles.body}>
        {`By continuing, you agree to Novella's `}
        <Text
          style={styles.link}
          onPress={() => Linking.openURL("https://www.meetnovella.com/terms-of-service/")}
        >
          {`Terms of Service`}
        </Text>
        {` and `}
        <Text
          style={styles.link}
          onPress={() => Linking.openURL("https://www.meetnovella.com/cookies-policy/")}
        >
          {`Cookie Policy`}
        </Text>
        {`, and confirm that you have read Novella’s `}
        <Text
          style={styles.link}
          onPress={() => Linking.openURL("https://www.meetnovella.com/privacy-policy/")}
        >
          {`Privacy Policy`}
        </Text>
        {`.`}
      </Text>
    </View>
  )
}

export default AuthPageTerms
