/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/*

THIS IS COPIED MUST BE MANUALLY COPIED TO: amplify/backend/function/CommunityQuestionAnswerCommandStreamListener/src/index.js

*/
export function getPowers(loggedInUserRole, isOriginalAuthor, communityRequiresModeration, currentPostVisibility, communityGroup, allowPublicPages) {
  
  const DEFAULT_POWERS = {
    canSee: currentPostVisibility == "COMMUNITY" && allowPublicPages,
    canEditText: false,
    canDelete: false,
    visibilityTargets: [],
  };

  let powers = DEFAULT_POWERS;

  const FULL_POWER = {
    canSee: true,
    canDelete: true,
    canEditText: true,
    visibilityTargets: [ "SELF", "ORGANIZER", "COMMUNITY" ],
  }

  switch (loggedInUserRole) {
    case "ADMIN":
      powers = FULL_POWER;
      break;
    case "ORGANIZER":
    case "SUBJECT":
      if (isOriginalAuthor) {
        powers = FULL_POWER;
      } else {
        switch (currentPostVisibility) {
          case "ORGANIZER":
          case "COMMUNITY":
            powers = {
              canSee: true,
              canEditText: false,
              canDelete: true,
              visibilityTargets: [ "SELF", "ORGANIZER", "COMMUNITY" ],
            }
            break;
          case "SELF":
          default:
            // default powers
            break
        }
      }
      break;
    case "MEMBER":
      if (isOriginalAuthor) {
        if (communityRequiresModeration) {
          powers = {
            canSee: true,
            canEditText: true,
            canDelete: true,
            visibilityTargets: []
          };
          switch (currentPostVisibility) {
            case "SELF":
            case "ORGANIZER":
            case "COMMUNITY":
              powers.visibilityTargets = [ "SELF", "ORGANIZER" ];
              break;
            default:
              break;
          }
        } else {
          powers = FULL_POWER;
        }
      //created the concept of a "superviewer" for the GH community 
      //very hacky, and needs to change, but this was a way to allow a small group
      //of people to see what the organizer sees without being organizers themselves,
      //and without adding a new visibility value, which could be more work
      } else if (communityGroup === "SUPERVIEWER") {
        switch (currentPostVisibility) {
          case "ORGANIZER":
          case "COMMUNITY":
            powers = {
              canSee: true,
              canEditText: false,
              canDelete: false,
              visibilityTargets: []
            };
            break;
        }
      } else {
        switch (currentPostVisibility) {
          case "COMMUNITY":
            powers = {
              canSee: true,
              canEditText: false,
              canDelete: false,
              visibilityTargets: []
            };
            break;
          case "SELF":
          case "ORGANIZER":
          default:
            // default powers
            break;
        }
      }
      break;
    default:
      // default powers
      break;
  }

  return powers;
}
