import React from "react";
import RootNavigatorCommon from "./RootNavigatorCommon";


function RootNavigator(): React.ReactElement {
  return (
    <RootNavigatorCommon />
  );
}

export default RootNavigator;