import React, { useContext } from 'react'
import { Text } from 'react-native-elements';
import CommonContext from '../../../CommonContext';
import { CommonContextType } from '../../../types/CommonContextType';

function AuthPageHeader(props: { text: string }): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  return (
    <Text
      style={{
        marginVertical: 10,
        marginHorizontal: 10,
        alignSelf: 'center',
        textAlign: 'center',
        color: themeFromContext.colors.foreground,
        ...themeFromContext.textVariants.header
      }}
    >
      {props.text}
    </Text>
  )
}

export default AuthPageHeader