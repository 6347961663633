import 'react-native-get-random-values';
import 'react-native-gesture-handler';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { SafeAreaProvider } from 'react-native-safe-area-context';
import RootNavigator from './src/views/navigators/RootNavigator/RootNavigator';
import { AppState, Platform, Text, TextInput, useColorScheme, useWindowDimensions, View } from 'react-native';
import { LIGHT_THEME } from './src/Theme';
import initializeAuthListener from './src/business/user/authEvents';
import configureAmplify from './src/business/config/amplifyConfig';
import * as Localization from 'expo-localization';
import i18n from 'i18n-js';
import CommonContext from './src/CommonContext';
import AnalyticsWrapper from './src/business/analytics/analyticsWrapper';
import log from './src/business/logging/logger';
import { Community, CommunityUser, CommunityUserStatus, User } from './src/API';
import { refreshAuthToken, subscribeToAll, subscribeToCommunityMembershipByUserId, subscribeToUserUpdates } from './src/business/user/userHelper';
import Splash from './src/views/screens/Loading/Splash';
import { Dictionary } from './src/types/data/Dictionary';
import * as s from './src/graphql/subscriptions';
import { ServicesContextFactory } from './src/ServicesContext';
import { getCurrentEnv } from './src/business/env/currentEnv';
import { DeviceMotion } from 'expo-sensors';
import { initializeBackgroundTasks } from './src/business/background/backgroundTaskHelper';
import { AnswerUploadManager } from './src/business/upload/AnswerUploadManager';
import { AnswerModelManager } from './src/business/upload/AnswerModelManager';
import SplashScreen from 'react-native-splash-screen';
import { useKeepAwake } from 'expo-keep-awake';
import { Orientation } from './src/business/layout/orientation';
import branch from 'react-native-branch';
import { membershipReducer } from './src/business/reducers/membershipReducer';
import { myCommuntiesReducer } from './src/business/reducers/myCommunitiesReducer';

// VERY VERY BAD!!
// Purposefully break accessibility because iOS dynamic text sizes break out layouts :( :( :( :( :(
Text.defaultProps = Text.defaultProps || {};
Text.defaultProps.allowFontScaling = false;
TextInput.defaultProps = TextInput.defaultProps || {};
TextInput.defaultProps.allowFontScaling = false;

// Set the key-value pairs for the different languages you want to support.
const translations = require(`./assets/i18n/translations.json`);
i18n.translations = translations;
// Set the locale once at the beginning of your app.
i18n.locale = Localization.locale;
// When a value is missing from a language it'll fallback to another language with the key present.
i18n.fallbacks = true;

function AppCommon(): React.ReactElement {
  //const [currentView, setCurrentView] = useState('initializing');
  //log.info('currentView: ', currentView)
  const [globalServices, setglobalServices] = useState(new ServicesContextFactory(getCurrentEnv()).buildServicesContext());
  const [initializing, setInitializing] = useState(true);
  const scheme = useColorScheme();
  const { width, height } = useWindowDimensions();
  const deviceOrientation = useRef<Orientation>('portrait');
  const [orientationState, setOrientationState] = useState<Orientation>('portrait');
  const [dimensions, setDimensions] = useState<{ width: number, height: number, orientation: Orientation }>({ width: width, height: height, orientation: 'portrait' });
  const [analytics, setAnalytics] = useState(new AnalyticsWrapper());
  const [loggedInUser, setLoggedInUser] = useState<User>();
  const [loggedInUserIsAdmin, setLoggedInUserIsAdmin] = useState(false);
  const [communityUsers, dispatchMembershipUpdate] = useReducer(membershipReducer, {});
  const [myCommunities, dispatchMyCommunitiesUpdate] = useReducer(myCommuntiesReducer, {});
  const [userSubscriptions, setUserSubscriptions] = useState<any[]>([]);
  const [communityIdSubscriptions, setCommunityIdSubscriptions] = useState<any[]>([]);
  const [userIdSubscriptions, setUserIdSubscriptions] = useState<any[]>([]);
  const [showingModal, setShowingModal] = useState<string | undefined>();
  const [authState, setAuthState] = useState('');
  const [answerModelManager] = useState(new AnswerModelManager(analytics, globalServices.communityService, globalServices.userService));

  const appState = useRef(AppState.currentState);
  //const [appStateVisible, setAppStateVisible] = useState(appState.current);

  useKeepAwake();

  useEffect(() => {

    const subscription = AppState.addEventListener('change', nextAppState => {
      if (
        appState.current != 'active' &&
        nextAppState === 'active'
      ) {
        console.log('App has come to the foreground!');
        //console.error('wakeUpInProgressUploads in main App');
        AnswerUploadManager.wakeUpInProgressUploads([answerModelManager]).then(() => {
          console.log('finish initializing upload manager in main App');
          // this is handled inside wakeUpInProgressUploads
          //AnswerUploadManager.startNext();
        });
        // (LocalForageWrapper.sync() as unknown as Promise<string>).then((result) => {
        //   UploadListenerLibrary.addListener(answerModelManager);
        //   // AnswerUploadManager.wakeUpInProgressUploads()
        //   //   .then(() => {
        //       console.error('finish initializing upload manager in main App');
        //     // });
        // }).catch((err) => {
        //   console.error(`Unable to sync AsyncStorage to  in-memory cache`);
        // });
      }

      //console.log('OLD AppState', appState.current);

      appState.current = nextAppState;
      //setAppStateVisible(appState.current);
      //console.log('AppState', appState.current);
    });

    return () => {
      subscription.remove();
    };
  }, []);

  useEffect(() => {
    //console.log(`width: ${width}, height: ${height}, orientation: ${orientationState}`);
    setDimensions({ width: width, height: height, orientation: orientationState });
  }, [width, height, orientationState]);

  useEffect(() => {
    const s = initializeDeviceInfoListener();
    initializeAuthListener(globalServices.userService, globalServices.communityService, analytics, setAuthState);
    configureAmplify();
    buildLoggedInUserState().then(() => {
      if (SplashScreen) {
        console.log(`hiding splash screen...`);
        SplashScreen.hide();
      }
    });
    initializeBackgroundProcessing();

    return () => {
      if (s != undefined) s.remove();
      unsubscribeAll();
    }
  }, []);

  useEffect(() => {
    log.info(`AUTH STATE CHANGED - USE EFFECT FIRED: ${authState}`);
    buildLoggedInUserState();

    return () => {
      unsubscribeAll();
    }
  }, [authState]);

  const initializeBackgroundProcessing = async () => {
    if (Platform.OS == 'ios' || Platform.OS == 'android') {
      await initializeBackgroundTasks();
    }
  }

  const initializeDeviceInfoListener = () => {
    let s: any;
    DeviceMotion.isAvailableAsync().then((isAvailable: boolean) => {
      if (isAvailable) {
        s = DeviceMotion.addListener(({ orientation }: { orientation: number }) => {
          const o = orientation == 0 ? 'portrait' : orientation == 180 ? 'portraitUpsideDown' : orientation == 90 ? 'landscapeLeft' : 'landscapeRight';
          if (o != deviceOrientation.current) {
            deviceOrientation.current = o;
            setOrientationState(o);
            console.log(`changing orientation to: ${o}`);
          }
        });
      }
    });
    return s;
  }

  const buildLoggedInUserState = async (): Promise<void> => {
    try {
      setInitializing(true);
      const results = await Promise.all([
        globalServices.userService.getLoggedInUser(),
        globalServices.userService.isLoggedInUserAdmin(),
        globalServices.communityService.getCommunityDataForLoggedInUser(),
      ]);

      // handle logged-in user
      const lu = results[0];
      if (lu) {
        setLoggedInUser(lu);

        // subscribe to changes
        subscribeToUser(lu.id);
        subscribeToCommunityUsersByUserId(lu.id);

        console.log(`authenticated!`);
      } else {
        noLoggedInUser();
      }
      // handle logged-in user is admin
      const luIsAdmin = results[1];
      if (luIsAdmin) {
        setLoggedInUserIsAdmin(true);
      } else {
        setLoggedInUserIsAdmin(false);
      }
      // handle user's communities
      const communities = results[2];

      const communityDictionary: Dictionary<Community> = {};
      if (communities) { // getting communities even though no logged in user???
        communities.forEach(c => {
          if (!communityDictionary[c.id]) {
            communityDictionary[c.id] = c;
          }
        });

        // subscribe to changes
        subscribeToCommunityUsers();
      } else {
        // unsubscribe to any user changes
        if (communityIdSubscriptions) {
          communityIdSubscriptions.forEach(s => s.unsubscribe());
        }
      }

      dispatchMyCommunitiesUpdate({ type: "set", payload: communityDictionary });

      const secondaryResults = await Promise.all([
        globalServices.communityService.getAllowedMembersByCommunity(Object.keys(communityDictionary)),
      ]);

      // handle membership
      const membership = secondaryResults[0];
      dispatchMembershipUpdate({ type: "set", payload: membership });

      //setCurrentView('mainNav')

    } catch (err) {
      //setCurrentView('auth')
      log.info(`what happened during app initialization?`);
      log.info(err);
      noLoggedInUser();
    }
    setInitializing(false);
  }

  const noLoggedInUser = () => {
    setLoggedInUser(undefined);

    // unsubscribe to any user changes
    if (userSubscriptions) {
      userSubscriptions.forEach(s => s.unsubscribe());
      setUserSubscriptions([]);
    }
    if (userIdSubscriptions) {
      userIdSubscriptions.forEach(s => s.unsubscribe());
      setUserIdSubscriptions([]);
    }

    console.log(`not authenticated!`);
  }

  const subscribeToUser = (id: string) => {
    if (userSubscriptions) {
      userSubscriptions.forEach(s => s.unsubscribe());
    }
    const s = subscribeToUserUpdates(id, (user) => {
      log.info(`loggedInUser subscription received update -- putting in common context`)
      log.info(JSON.stringify(user, null, 2));
      setLoggedInUser(user);
    });
    setUserSubscriptions([s]);
  }

  const subscribeToCommunityUsers = () => {
    if (communityIdSubscriptions) {
      communityIdSubscriptions.forEach(s => s.unsubscribe());
    }
    const membershipSubscriptions: any[] = [
      subscribeToAll<CommunityUser>(s.onCreateCommunityUser, "onCreateCommunityUser", addMember),
      subscribeToAll<CommunityUser>(s.onUpdateCommunityUser, "onUpdateCommunityUser", updateMember),
    ];
    setCommunityIdSubscriptions(membershipSubscriptions);
  }

  const subscribeToCommunityUsersByUserId = (id: string) => {
    if (userIdSubscriptions) {
      userIdSubscriptions.forEach(s => s.unsubscribe());
    }

    const selfMembershipSubscriptions = [
      subscribeToCommunityMembershipByUserId(
        id,
        s.onCreateCommunityUserByUserId,
        "onCreateCommunityUserByUserId",
        (communityUser: CommunityUser) => {
          log.info(`GOT TOP LEVEL COMMUNITY ADDED!`);
          log.info(communityUser.community);
          dispatchMyCommunitiesUpdate({ type: "add", payload: communityUser.community });

          // refrsh tokens
          refreshAuthToken()
            .then(() => {
              console.log(`refreshed tokens`);
            });
        }
      ),
      subscribeToCommunityMembershipByUserId(
        id,
        s.onUpdateCommunityUserByUserId,
        "onUpdateCommunityUserByUserId",
        (communityUser: CommunityUser) => {
          log.info(`GOT TOP LEVEL COMMUNITY UPDATED!`);
          log.info(communityUser.community);
          if (communityUser.status == CommunityUserStatus.BANNED || communityUser.status == CommunityUserStatus.REMOVED) {
            log.info(`GOT TOP LEVEL COMMUNITY REMOVED 2!`);
            dispatchMyCommunitiesUpdate({ type: "remove", payload: communityUser.community });
          }
        }
      ),
      // subscribeToCommunityMembershipByUserId(
      //   id,
      //   s.onDeleteCommunityUserByUserId,
      //   "onDeleteCommunityUserByUserId",
      //   (communityUser: CommunityUser) => {
      //     log.info(`GOT TOP LEVEL COMMUNITY REMOVED!`);
      //     log.info(communityUser.community);
      //     dispatchMyCommunitiesUpdate({ type: "remove", payload: communityUser.community });
      //   }
      // )
    ];

    setUserIdSubscriptions(selfMembershipSubscriptions);
  }

  const unsubscribeAll = () => {
    if (userSubscriptions) {
      userSubscriptions.forEach(s => s.unsubscribe());
      setUserSubscriptions([]);
    }

    if (communityIdSubscriptions) {
      communityIdSubscriptions.forEach(s => s.unsubscribe());
      setCommunityIdSubscriptions([]);
    }

    if (userIdSubscriptions) {
      userIdSubscriptions.forEach(s => s.unsubscribe());
      setUserIdSubscriptions([]);
    }
  }

  // const onLayout = (event: LayoutChangeEvent) => {
  //   setDimensions({ width: event.nativeEvent.layout.width, height: event.nativeEvent.layout.height });
  // }

  const addMember = (communityUser: CommunityUser) => {
    log.info(`GOT TOP LEVEL MEMBER CREATED!`);
    log.info(communityUser);
    if (communityUser.status != CommunityUserStatus.BANNED && communityUser.status != CommunityUserStatus.REMOVED) {
      dispatchMembershipUpdate({ type: "add", payload: { communityID: communityUser.community.id, communityUser: communityUser } });
    }
  }

  const updateMember = (communityUser: CommunityUser) => {
    log.info(`GOT TOP LEVEL MEMBER UPDATED!`);
    log.info(communityUser);
    if (communityUser.status != CommunityUserStatus.BANNED && communityUser.status != CommunityUserStatus.REMOVED) {
      dispatchMembershipUpdate({ type: "update", payload: { communityID: communityUser.community.id, communityUser: communityUser } });
    } else {
      removeMember(communityUser);
    }
  }

  const removeMember = (communityUser: CommunityUser) => {
    log.info(`GOT TOP LEVEL MEMBER REMOVED!`);
    log.info(communityUser);
    dispatchMembershipUpdate({ type: "remove", payload: { communityID: communityUser.community.id, communityUser: communityUser } });
  }

  return (
    <SafeAreaProvider>
      <CommonContext.Provider value={{
        dimensions: dimensions,
        isAdmin: loggedInUserIsAdmin,
        analytics: analytics,
        loggedInUser: loggedInUser,
        communities: myCommunities,
        membership: communityUsers,
        showingModal: showingModal,
        setShowingModal: setShowingModal,
        theme: scheme === 'dark' ? LIGHT_THEME : LIGHT_THEME,
        services: globalServices,
      }} >
        <View
          // behavior='padding'
          style={{
            width: '100%',
            height: '100%',
            display: 'flex',
            // flex:1
          }}
        >
          {
            initializing && <Splash />
          }
          {
            !initializing && <RootNavigator />
          }
        </View>
      </CommonContext.Provider>
    </SafeAreaProvider>
  )
}

export default AppCommon;