/* eslint-disable no-case-declarations */
import BackgroundFetch from "react-native-background-fetch";
import { ServicesContextFactory } from "../../ServicesContext";
import AnalyticsWrapper from "../analytics/analyticsWrapper";
import { getCurrentEnv } from "../env/currentEnv";
import { AnswerModelManager } from "../upload/AnswerModelManager";
import { AnswerUploadManager } from "../upload/AnswerUploadManager";

// if we change or add new ones, they also need to be added to the Info.plist file for iOS.
// see setup guides here: https://www.npmjs.com/package/react-native-background-fetch
const SECONDARY_SCHEDULED_TASK_ID = "com.transistorsoft.scheduledTask";

 // Initialize BackgroundFetch ONLY ONCE when component mounts.
export const initializeBackgroundTasks = async () : Promise<void> => {
  // BackgroundFetch event handler.
  const onEvent = async (taskId: string) => {
    // Do your background work...
    await performBackgroundTask(taskId);

    setTimeout(() => {
      // IMPORTANT:  You must signal to the OS that your task is complete.
      BackgroundFetch.finish(taskId);
    }, 300000);
  }

  // Timeout callback is executed when your Task has exceeded its allowed running-time.
  // You must stop what you're doing immediately BackgroundFetch.finish(taskId)
  const onTimeout = async (taskId: string) => {
    console.warn('[BackgroundFetch] TIMEOUT task: ', taskId);
    BackgroundFetch.finish(taskId);
  }

  // Initialize BackgroundFetch only once when component mounts.
  // Supposedly, iOS will NEVER invoke you more frequently than every 15 mins, and maybe even slower...
  const status = await BackgroundFetch.configure({minimumFetchInterval: 15}, onEvent, onTimeout);
  console.log('[BackgroundFetch] configure status: ', status);

  // BackgroundFetch.scheduleTask({
  //   taskId: SECONDARY_SCHEDULED_TASK_ID,
  //   delay: 1 * 60 * 1000,
  //   periodic: true,
  //   requiresNetworkConnectivity: true,
  // })
}

const performBackgroundTask = async (taskId: string) => {
  // This is the fetch-event callback.
  console.log("[BackgroundFetch] taskId: ", taskId);

  // Use a switch statement to route task-handling.
  switch (taskId) {
    case SECONDARY_SCHEDULED_TASK_ID:
      //console.log("Received custom task");
      break;
    default:
      //console.log("Default fetch task");
      //AnswerUploadManager.hydrate();
      const globalServices = new ServicesContextFactory(getCurrentEnv()).buildServicesContext()
      const analytics = new AnalyticsWrapper();
      const amm = new AnswerModelManager(analytics, globalServices.communityService, globalServices.userService);
      await AnswerUploadManager.wakeUpInProgressUploads([amm]);
      console.log('finish initializing upload manager in background task');
  }
}