import { Auth, Storage } from "aws-amplify";
import { IUserService } from "../../contracts/IUserService";
import { invalidateItem, ReadThroughCacheInput, readThroughCache } from "../cache/cacheHelper";


// storage key looks like this:
// NEW IMPL: "identityId::::filename"
// OLD IMPL: "filename"

// cache key looks like this:
// NEW IMPL: "identityId::::filename.signedS3Url"
// OLD IMPL: "userId.filename.signedS3Url"


// Amazon's default S3 signed URL ttl is 900 seconds (I think), so we should only cache the signed URL for 840 seconds?
const s3ttl = 840000;
const DELIMITER = '::::';

function createCacheKey(storageKey: string) : string {
  return `${storageKey}.signedS3Url`;
}

function createStorageKey(identityId: string, filename: string) : string {
  return `${identityId}${DELIMITER}${filename}`;
}

function parseStorageKey(storageKey: string) : { identityId?: string | null, filename: string } {
  const a = storageKey.split(DELIMITER);
  if (a.length === 2) {
    return { identityId: a[0], filename: a[1] };
  } else if (a.length === 1) {
    return { filename: a[0] };
  } else {
    throw `Failed to parse s3 key: ${storageKey}`;
  }
}

export async function getUserFile(userService: IUserService, storageKey: string, userId?: string) : Promise<any> {
  const input: ReadThroughCacheInput = {
    key: createCacheKey(storageKey),
    ttl: s3ttl,
    ifNotFound: async () => {
      // the identityId is required if level == protected and getting an item from another user
      // first we try to parse it out of the storage key iteself (newer implementation)
      // if that fails, we try to get the identity from the related user record
      // if that fails, nothing will come back from S3 (unless the logged-in user happens to be the same as the target user)
      const unpack = parseStorageKey(storageKey);
      let identityId = unpack.identityId;
      const filename = unpack.filename;

      if (!identityId && userId) {
        const u = await userService.getUser(userId);
        identityId = u.identityID;
      }
      if (identityId != null) {
        return await Storage.get(filename, { level: 'protected', identityId: identityId });
      }
    }
  };
  return await readThroughCache(input);
}

export async function putUserFile(userService: IUserService, filename: string, item: any) : Promise<string> {
  const loggedInUser = await userService.getLoggedInUser();
  let identityId = loggedInUser.identityID;
  if (!identityId) {
    identityId = (await Auth.currentUserCredentials()).identityId;
  }
  await Storage.put(filename, item, { level: 'protected' });

  // This is the key we store in Dynamo, which has all the info we need to lookup the s3 file
  const storageKey = createStorageKey(identityId, filename);

  // This is the key we use for caching the s3 signed url to the file
  const cacheKey = createCacheKey(storageKey);
  invalidateItem(cacheKey);

  return storageKey;
}