/* eslint-disable no-case-declarations */

export function arrayReducer(state: any[], action: { type: "set" | "add" | "update" | "remove" | "insert", payload: any}): any[] {
  switch (action.type) {
    case "set":
      return action.payload;
    case "add":
      let newState = [ ...state ];
      if (!newState.find(x => x.id == action.payload.id)) {
        newState = [ ...newState, action.payload ];
      }
      return newState;
    case "insert":
      let newState4 = [ ...state ];
      if (!newState4.find(x => x.id == action.payload.id)) {
        newState4 = [ action.payload, ...newState4 ];
      }
      return newState4;
    case "remove":
      const newState2 = state.filter(x => x.id != action.payload.id);
      return newState2;
    case "update":
      const newState3 = [ ...state ];
      const idx = newState3.findIndex(x => x.id == action.payload.id);
      if (idx >= 0) {
        newState3[idx] = action.payload;
      }      
      return newState3;
    default:
      return [ ...state ];
  }
}