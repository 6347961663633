import React, { useContext, useEffect, useState } from 'react';
import { Text } from 'react-native-elements';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { Pressable, View } from 'react-native';
import { VideoVisibility } from '../../contracts/ICommunityService';
import { Palette } from '../../Theme';
import NButton from './NButton';
import { useSafeAreaInsets } from '../../business/layout/layout';
import NDropDownPicker from './NDropDownPicker';
import { ItemType } from 'react-native-dropdown-picker';
import i18n from 'i18n-js';

type VisibilityPicker4Props = {
  visibility: VideoVisibility,
  allowed: VideoVisibility[],
  authorId: string,
  dropDownDirection: "TOP" | "BOTTOM",
  onValueChanged?: (v: VideoVisibility | null) => void,
  saveVisibility?: (v: VideoVisibility) => Promise<void>
}

function VisibilityPicker4(props: VisibilityPicker4Props): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { visibility, allowed, authorId, saveVisibility, onValueChanged, dropDownDirection } = props;
  const [visibilityText, setVisibilityText] = useState('');
  const [visibilityLinkEnabled, setVisibilityLinkEnabled] = useState(false);
  const [showVisibilityControl, setShowVisibilityControl] = useState(false);
  const [proposedVisibility, setProposedVisibility] = useState(allowed[0]);
  const [savingVisibility, setSavingVisibility] = useState(false);
  const [open, setOpen] = useState(false);

  const [menuAnchor, setMenuAnchor] = useState({ x: 0, y: 0 });
  const { top } = useSafeAreaInsets();
  const [visibilityEdited, setVisibilityEdited] = useState(false);
  const [items, setItems] = useState<ItemType<VideoVisibility>[]>([]);

  useEffect(() => {
    setItems(allowed.map(x => { return { label: getVisibilityText(x, authorId, commonContext.loggedInUser?.id), value: x }; }));
  }, [allowed, authorId, commonContext.loggedInUser]);

  useEffect(() => {
    setShowVisibilityControl(false);
    setSavingVisibility(false);
    if (allowed.includes(visibility)) {
      setProposedVisibility(visibility);
    } else {
      setProposedVisibility(allowed[0]);
    }
    setVisibilityLinkEnabled(allowed.filter(v => v != visibility).length > 0);
  }, [visibility, allowed]);

  useEffect(() => {
    const text = getVisibilityText(visibility, authorId, commonContext.loggedInUser?.id);
    setVisibilityText(text);
  }, [visibility, authorId, commonContext.loggedInUser]);

  const getVisibilityText = (v: VideoVisibility, authorId: string, loggedInUserId?: string): string => {
    let text = '';
    let self = false;
    if (authorId == loggedInUserId) {
      self = true;
    }
/*
    "Visibility_Self_OnlyYou": "Only you",
    "Visibility_Self_SpotOrgAndYou": "Spot./Org. and you",
    "Visibility_Self_Everyone": "Everyone",
    "Visibility_Other_OnlyYou": "The original poster",
    "Visibility_Other_SpotOrgAndYou": "Spot./Org. and original poster",
    "Visibility_Other_Everyone": "Everyone"
*/

    switch (v) {
      case VideoVisibility.SELF:
        if (self) {
          text = i18n.t('Visibility_Self_OnlyYou');
        } else {
          text = i18n.t('Visibility_Other_OnlyYou');
        }
        break;
      case VideoVisibility.COMMUNITY:
        text = i18n.t('Visibility_Everyone');
        break;
      case VideoVisibility.ORGANIZER:
        if (self) {
          text = i18n.t('Visibility_Self_SpotOrgAndYou');
        } else {
          text = i18n.t('Visibility_Other_SpotOrgAndYou');
        }
        break;
      default:
        break;
    }

    return text;
  }

  const pressSave = async (v: VideoVisibility) => {
    setSavingVisibility(true);
    if (saveVisibility) {
      await saveVisibility(v);
    }
    setTimeout(() => {
      setVisibilityEdited(false);
      setSavingVisibility(false);
    }, 20000);
  }

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignContent: 'center',
        alignItems: 'center',
        width: '100%',
        minHeight: 40,
        zIndex: 1,
      }}
    >
      <Text
        style={{
          ...themeFromContext.textVariants.inputLabel,
          color: themeFromContext.colors.text,
          marginEnd: themeFromContext.spacing.s,
          whiteSpace: 'nowrap',
          //width: 'min-content',
          //flex: 1,
        }}

      >
        {`Visible to:`}
      </Text>
      {
        !showVisibilityControl && visibility &&
        <Pressable
          style={{
            flex: 1,
          }}
          disabled={!visibilityLinkEnabled}
          onPress={() => {
            setShowVisibilityControl(true);
            setOpen(true);
          }}
        >
          <Text
            style={{
              margin: 3,
              //marginEnd: 0,
              ...themeFromContext.textVariants.memberCardEmail,
              color: visibilityLinkEnabled && (!visibilityEdited || !saveVisibility) ? Palette.teal : themeFromContext.colors.secondary
            }}
            numberOfLines={1}
            testID={`answer-card-visibility`}
          >
            {visibilityText}
          </Text>
        </Pressable>
      }
      {
        showVisibilityControl &&
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          //width: '100%',
          flex: 1,
          //paddingHorizontal: 20,
          //paddingTop: 30,
          zIndex: 1,
        }}
      >        
        <NDropDownPicker
          dropDownDirection={dropDownDirection}
          open={open}
          setOpen={setOpen}
          value={proposedVisibility}
          setValue={setProposedVisibility}
          onChangeValue={onValueChanged}
          items={items}
          setItems={setItems}
          onPressItem={(value) => {
            setProposedVisibility(value);
            setOpen(false);
          }}
        />
      {
        saveVisibility &&
        <NButton
          t='brightLink'
          titleStyle={{
            fontSize: 14,
          }}
          title={`Save`}
          loading={savingVisibility}
          loadingProps={{
            color: Palette.teal,
          }}
          buttonStyle={{
            width: 50,
          }}
          loadingStyle={{
            backgroundColor: Palette.transparent,
          }}
          disabled={savingVisibility}
          disabledStyle={{
            backgroundColor: Palette.transparent,
          }}
          onPress={() => pressSave(proposedVisibility)}
        >
        </NButton>
      }
      </View>
      }
    </View>
  );
}

export default VisibilityPicker4;