import { API } from 'aws-amplify';
//import { ICache } from '@aws-amplify/cache/lib-esm/types';
import * as m from '../graphql/mutations';
import * as q from '../graphql/queries';
import { IFullListQuerier } from '../business/graphql/FullListQuerier';
import log from '../business/logging/logger';
import { Category, CommunityQuestion, CommunityQuestionRecommendation } from '../API';
import { Dictionary } from '../types/data/Dictionary';
import { ICache } from '../business/storage/ICache';
import { ICategoryService } from '../contracts/ICategoryService';

const CACHE_KEY = 'allCategories';

type catid = {
  categoryID: { eq: string }
}

export class CategoryService implements ICategoryService {
  flq: IFullListQuerier;
  cache: ICache;
  constructor(flq: IFullListQuerier, cache: ICache) {
    this.flq = flq;
    this.cache = cache;
  }
  compareCategories(a: string, b: string, asc: boolean): number {
    return asc ?
      a.toLowerCase().localeCompare(b.toLowerCase()) :
      -1 * a.toLowerCase().localeCompare(b.toLowerCase());
  }
  handleDuplicateCategories(sorted: Category[]): void {
    const duplicates: Dictionary<Category[]> = {};
    for (let i = 0; i < sorted.length - 1; i++) {
      if ((sorted[i].name.toLowerCase() == sorted[i + 1].name.toLowerCase()) ||
        (i > 0 && sorted[i].name.toLowerCase() == sorted[i - 1].name.toLowerCase())) {
        const name = sorted[i].name;
        if (!duplicates[name]) {
          duplicates[name] = [];
        }
        duplicates[name].push(sorted[i]);
      }
    }
    if (Object.entries(duplicates).length > 0) {
      // PROBLEM!
      log.error(`found duplicate catgories and consolidating:`);
      log.info(sorted);
      log.info(duplicates);

      Object.entries(duplicates).forEach(async (dupe) => {
        const name = dupe[0];
        const canonicalCat = dupe[1][0];
        const duplicateCats = dupe[1].slice(1);

        // get community questions and recommendations with duplicate categories
        const categoryIdConditions: catid[] = duplicateCats.map(d => { return { categoryID: { eq: d.id } }; });
        const variables = {
          filter: {
            or: categoryIdConditions
          }
        };
        let results: any[] = [];

        try {
          results = await Promise.all([
            this.flq.queryFullList('listCommunityQuestions', q.listCommunityQuestions, variables),
            this.flq.queryFullList('listCommunityQuestionRecommendations', q.listCommunityQuestionRecommendations, variables)
          ]);
        } catch (ex) {
          log.error(ex);
        }

        if (results.length != 2) {
          throw `Unable to retrieve prerequisite data.`;
        }

        const questionsToUpdate: CommunityQuestion[] = results[0];
        const recommendationsToUpdate: CommunityQuestionRecommendation[] = results[1];

        // update to use the canonical category
        let results2: any[] = [];

        try {
          results2 = await Promise.all([
            ...questionsToUpdate.map(q => {
              API.graphql({
                query: m.updateCommunityQuestion,
                variables: {
                  input: {
                    id: q.id,
                    categoryID: canonicalCat.id,
                  }
                }
              });
            }),
            ...recommendationsToUpdate.map(r => {
              API.graphql({
                query: m.updateCommunityQuestionRecommendation,
                variables: {
                  input: {
                    id: r.id,
                    categoryID: canonicalCat.id,
                  }
                }
              });
            }),
          ]);
        } catch (ex) {
          log.error(ex);
          throw ex;
        }

        // delete duplicate categories
        let results3: any[] = [];

        try {
          results3 = await Promise.all(
            duplicateCats.map(d => {
              API.graphql({
                query: m.deleteCategory,
                variables: {
                  input: {
                    id: d.id
                  }
                }
              });
            })
          );
        } catch (ex) {
          log.error(ex);
          throw ex;
        }
      });
    }
  }
  async getAllCategories(): Promise<Category[]> {
    const all = this.cache.getItem(CACHE_KEY);
    if (!all) {
      const list: Category[] = await this.flq.queryFullList('listCategories', listCategoriesLite);
      const sorted: Category[] = list.sort((a, b) => this.compareCategories(a.name, b.name, true));

      // handle duplicates (this happened because of a race condition once, and we needed to clean up dupes and references in questions)
      this.handleDuplicateCategories(sorted);

      this.cache.setItem(CACHE_KEY, sorted)
    }
    return this.cache.getItem(CACHE_KEY);
  }
  async createNewCategory(name: string): Promise<Category> {
    const catLower = name.toLowerCase();
    const list = await this.getAllCategories();
    const categories = list.filter(c => c.name.toLowerCase() == catLower);
    if (categories.length == 0) {
      const createResult: any = await API.graphql({
        query: m.createCategory,
        variables: {
          input: {
            name: name
          }
        }
      });
      this.cache.removeItem(CACHE_KEY);
      return createResult.data.createCategory;
    } else {
      return categories[0];
    }
  }
  async getValidCategory(categoryName: string, failure?: (message: string) => void): Promise<Category | undefined> {
    const catLower = categoryName.toLowerCase();
    const list = await this.getAllCategories();
    const categories = list.filter(c => c.name.toLowerCase() == catLower);
    if (categories.length === 0) {
      // ERROR
      const error = `unknown category: ${categoryName}!`;
      log.log(error);
      if (failure != undefined) {
        failure(error);
      }
      return;
    }
    return categories[0];
  }
}

export const listCategoriesLite = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;