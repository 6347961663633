// Frameworks
import React, { useContext, useEffect, useState } from 'react';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { TooltipProps } from 'rn-tourguide'
import { View } from 'react-native';
import NText from '../../components/NText';
import NButton from '../../components/NButton';
import { Icon, Text } from 'react-native-elements';

export const DELIMITER = '::::';

function HelpTooltip(props: TooltipProps): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const {
    isFirstStep,
    isLastStep,
    handleNext,
    handlePrev,
    handleStop,
    currentStep,
    labels,
  } = props;
  const [header, setHeader] = useState('');
  const [body, setBody] = useState('');

  useEffect(() => {
    if (currentStep) {
      const pieces = currentStep.text.split(DELIMITER);
      let h = '';
      let b = pieces[0];
      if (pieces.length == 2) {
        h = pieces[0];
        b = pieces[1];
      }
      // this is because the content of the modal was changing to the next step before it disappeared and re-appeared, giving an ugly experience
      setTimeout(() => {
        setHeader(h);
        setBody(b);
      }, 100);
    }
  }, [currentStep]);

  return (
    <View
      style={{
        borderRadius: 16,
        paddingTop: 16,
        alignItems: 'center',
        justifyContent: 'center',
        paddingBottom: 16,
        width: '100%',
        backgroundColor: themeFromContext.colors.background,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 400,
      }}
      testID={`tutorial-tooltip-${currentStep.order}`}
    >
      <View
        style={{
          width: '100%',
          alignItems: 'flex-end',
          paddingHorizontal: 16,
        }}
      >
        <Icon
          type='material-community'
          name='close-circle-outline'
          color={themeFromContext.colors.tertiary}
          onPress={handleStop}
          testID={`tutorial-tooltip-stop-button-${currentStep.order}`}
        />
      </View>
      <View
        style={{
          width: '100%',
          alignItems: 'center',
        }}
      >
        <NText
          testID={`tutorial-tooltip-header-${currentStep.order}`}
          style={{
            ...themeFromContext.textVariants.header,
            margin: 10,
            marginBottom: 20,
            //paddingBottom: 24,
          }}
        >
          {currentStep && header}
        </NText>
        <Text
          testID={`tutorial-tooltip-body-${currentStep.order}`}
          style={{
            ...themeFromContext.textVariants.body,
            textAlign: 'left',
            marginHorizontal: 30,
            marginBottom: 30,
          }}
        >
          {currentStep && body}
        </Text>
      </View>
      <View
        style={{
          width: '100%',
          alignItems: 'center',
          justifyContent: 'space-between',
          display: 'flex',
          flexDirection: 'row',
          paddingHorizontal: 16,
          paddingBottom: 6,
        }}
      >
        <NButton
          t={'mild'}
          title={labels?.previous || `PREV`}
          disabled={isFirstStep}
          hidden={isFirstStep}
          onPress={handlePrev}
          testID={`tutorial-tooltip-prev-button-${currentStep.order}`}
        />
        {!isLastStep ? (
          <NButton
            t={'mild'}
            title={labels?.next || `NEXT`}
            onPress={handleNext}
            testID={`tutorial-tooltip-next-button-${currentStep.order}`}
          />
        ) : (
          <NButton
            t={'primary'}
            title={labels?.finish || `DONE`}
            onPress={handleStop}
            testID={`tutorial-tooltip-done-button-${currentStep.order}`}
          />
        )}
      </View>
    </View>
  );
}
export default HelpTooltip;