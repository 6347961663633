//import { getFirebaseAnalytics, logEvent, setUserProperties, setUserId } from './firebase';
import { initAmplitudeAnalytics, logAmplitudeEvent, setUserId, setUserProperties } from './amplitude';
import { getCurrentEnv } from '../env/currentEnv';
//import { Analytics } from 'firebase/analytics';
import log from '../logging/logger';

const env = getCurrentEnv();

export type ItemType = {
  item_id: string,
  item_name: string,
  affiliation?: string,
  index?: number,
  item_category?: string,
  item_category2?: string,
  item_category3?: string,
  item_category4?: string,
  item_category5?: string,
  item_list_id?: string,
  item_list_name?: string,
}

export default class AnalyticsWrapper {
  //firebase: Analytics;
  amplitude: any;

  constructor (username?: string) {
    //this.firebase = getFirebaseAnalytics();
    //this.amplitude = getAmplitudeAnalytics();
    initAmplitudeAnalytics(username);
  }

  setUserData(username: string, data?: any) : void {
    //log.info(`analytics: username: ${username}`);
    //log.info(`analytics: user properties: ${JSON.stringify(data, null, 2)}`);

    // hand amplitude
    setUserId(username);
    setUserProperties(data);

    // handle firebase
    //setUserId(this.firebase, username);
    //setUserProperties(this.firebase, data);
  }

  logEvent(eventName: string, data?: any) : void {

    const eventProperties = {
      "environment": env,
      ...data,
    }

    console.log(`ANALYTICS: ${eventName}`);
    console.log(`ANALYTICS: ${JSON.stringify(data, null, 2)}`);

    // handle amplitude
    logAmplitudeEvent(eventName, eventProperties);

    // handle firebase
    //logEvent(this.firebase, eventName, eventProperties);
  }

  logItemTypes(eventName: string, data: ItemType[]) : void {

    const commonEventProperties = {
      "environment": env,
    }

    // handle amplitude
    data.forEach(i => {
      const amplitudeEventProperties = {
        ...commonEventProperties,
        ...i,
      }
      logAmplitudeEvent(eventName, amplitudeEventProperties);

      //log.info(`analytics: event name: ${eventName}`);
      //log.info(`analytics: event properties: ${JSON.stringify(amplitudeEventProperties, null, 2)}`);
    })

    // handle firebase
    //logEvent(this.firebase, eventName, { items: data });
  }

  joinCommunity(group_id: string) : void {
    this.logEvent('join_group', { group_id: group_id });
  }

  viewPage(page_name: string, params: any) : void {
    this.logEvent('view_page', { page_name: page_name, ...params });
  }

  submitFormData(page_name: string, data: any) : void {
    this.logEvent('submit_form_data', { page_name: page_name, ...data });
  }

  uploadVideo(videoMetadata: any) : void {
    this.logEvent('upload_video', videoMetadata);
  }

  selectItems(items: ItemType[]) : void {
    this.logItemTypes('select_item', items);
  }

  viewItems(items: ItemType[]) : void {
    this.logItemTypes('view_item', items);
  }
}