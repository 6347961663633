import React, { useEffect, useState } from 'react'
import { Animated, Easing, StyleSheet, View } from 'react-native';
import LogoAnimation from '../../components/ActivityIndicators/LogoAnimation';
import { Palette } from '../../../Theme';

function Splash(): React.ReactElement {
  const [anim] = useState(new Animated.Value(0.75));

  useEffect(() => {
    // anim.addListener((value) => {
    //log.info(`value: ${Math.floor(value.value)}`);
    // setV1(Math.floor(value.value) % 10 == 0);
    // });

    Animated.loop(
      Animated.timing(
        anim,
        {
          toValue: 2,
          duration: 2500,
          useNativeDriver: false,
          easing: Easing.linear,
        }
      )
    ).start();
  }, []);

  return (
    <View style={styles.container}>
      <LogoAnimation color={Palette.grey} />
      {/* <Animated.Image
        style={[styles.logo, { transform: [{ scale: anim }] }]}
        resizeMode='contain'
        // height={165}
        // width={432}
        source={require("../../../assets/novella-logo-full.png")}
      /> */}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  logo: {
    height: 165,
    width: 432,
  }
})

export default Splash;