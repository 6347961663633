import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';
import { DrawerContentScrollView, DrawerContentComponentProps, DrawerItem } from '@react-navigation/drawer';
import { Text, Icon } from 'react-native-elements';
import { Auth } from 'aws-amplify';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { useLinkTo } from '@react-navigation/native';
import log from '../../../business/logging/logger';
import MemberAvatar from '../../components/MemberAvatar';
import { RootStackParamList } from '../../../types/Navigation';
import { Palette } from '../../../Theme';
import { TOP_HEADER_BASE_HEIGHT } from '../../../constants/header';
import NDrawerItem from './NDrawerItem';
import * as Application from 'expo-application';
import { getCurrentEnv } from '../../../business/env/currentEnv';

function CustomContentComponent(props: DrawerContentComponentProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const [fullName, setFullName] = useState('');
  const [version, setVersion] = useState<string|null>(null);
  const [build, setBuild] = useState<string|null>(null);
  const year = React.useMemo(() => new Date().getFullYear(), []);

  useEffect(() => {
    setVersion(Application.nativeApplicationVersion);
    setBuild(Application.nativeBuildVersion);
  }, []);

  useEffect(() => {
    setFullName(commonContext.services?.userService.getFullName(commonContext.loggedInUser));
  }, [commonContext.loggedInUser]);

  const itemStyle = {
    drawerActiveTintColor: Palette.plum,
    drawerActiveBackgroundColor: Palette.lightteal,
    drawerInactiveTintColor: Palette.white,
    drawerLabelStyle: themeFromContext.textVariants.body,
    drawerInactiveBackgroundColor: Palette.plum,
  };

  const signOut = async function () {
    try {
      await Auth.signOut()
      //log.info('signed out');
    } catch (err) {
      log.error('error signing out...', err);
    }
  }

  const linkTo = useLinkTo();

  return (
    // <Text>{`DRAWER\n\nDRAWER`}</Text>
    <View style={{
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      backgroundColor: Palette.plum,
      flex: 1,
    }}>
      <View
        style={{
          width: '100%',
          height: TOP_HEADER_BASE_HEIGHT,
          paddingHorizontal: 20,
          alignItems: 'flex-end',
          justifyContent: 'center',
        }}
      >
        <Icon
          name='close-thick'
          type='material-community'
          color={Palette.white}
          onPress={() => props.navigation.closeDrawer()}
          testID={`drawer-close-button`}
        />
      </View>

      <View
        style={{
          flex: 1,
          display: 'flex',
          height: '100%',
          width: '100%',
          justifyContent: 'space-between'
        }}
      >

        <View
          style={{
            alignItems: 'center',
            width: '100%',
          }}
        >
          {/* avatar and name */}
          <MemberAvatar
            size="xlarge"
            userData={commonContext.loggedInUser}
            ignoreUpdate={true}
            containerStyle={{
              backgroundColor: Palette.pink,
            }}
          />
          <Text
            style={{
              color: Palette.white,
              margin: 12,
              ...themeFromContext.textVariants.header
            }}
          >
            {fullName}
          </Text>
        </View>

        <View>
          {/* menu items */}
          <NDrawerItem
            label={`Profile`}
            focused={props.state.routes[props.state.index].name === 'Profile' && (props.state.routes[props.state.index].params as RootStackParamList["Profile"])?.userId == commonContext.loggedInUser?.id}
            onPress={() => linkTo(`/profile/${commonContext.loggedInUser?.id}/`)}
            iconName={`account`}
            //iconType={}
          />
          {/* <DrawerItem
            style={{
              borderRadius: 0,
              margin: 0,
              paddingHorizontal: 10,
              paddingVertical: 5,
            }}
            activeBackgroundColor={itemStyle.drawerActiveBackgroundColor}
            activeTintColor={itemStyle.drawerActiveTintColor}
            inactiveTintColor={itemStyle.drawerInactiveTintColor}
            inactiveBackgroundColor={itemStyle.drawerInactiveBackgroundColor}
            labelStyle={{...itemStyle.drawerLabelStyle, fontWeight: 'bold'}}
            label="Rewards"
            focused={props.state.routes[props.state.index].name === 'Rewards' && (props.state.routes[props.state.index].params as RootStackParamList["Rewards"])?.userId == commonContext.loggedInUser?.id}
            onPress={() => linkTo(`/rewards/${commonContext.loggedInUser?.id}/`)}
            icon={(props) => (
              <Icon
                name={'gift-outline'}
                type='material-community'
                color={props.focused ? Palette.plum : Palette.white}
              />
            )}
          /> */}
          <NDrawerItem
            label={`My Communities`}
            focused={props.state.routes[props.state.index].name === 'Home'}
            onPress={() => linkTo(`/communities/${commonContext.loggedInUser?.id}/`)}
            iconName={`account-group`}
            //iconType={}
          />
          <NDrawerItem
            label={`Uploads`}
            focused={props.state.routes[props.state.index].name === 'Uploads'}
            onPress={() => linkTo(`/uploads/`)}
            iconName={`cloud-upload`}
            //iconType={}
          />
          <NDrawerItem
            label={`App Intro`}
            focused={props.state.routes[props.state.index].name === 'Welcome'}
            onPress={() => linkTo(`/welcome/${true}`)}
            iconName={`human-greeting`}
            //iconType={}
          />
          <NDrawerItem
            label={`Help`}
            focused={props.state.routes[props.state.index].name === 'Help'}
            onPress={() => linkTo(`/help`)}
            iconName={`lifebuoy`}
            //iconType={}
          />
          {
            getCurrentEnv() != 'production' && getCurrentEnv() != 'stage' &&
            <NDrawerItem
              label={`1P1Q (Dev Only)`}
              focused={props.state.routes[props.state.index].name === 'Ask Now' && (props.state.routes[props.state.index].params as RootStackParamList["Ask Now"])?.userId == commonContext.loggedInUser?.id}
              onPress={() => linkTo(`/onequestion`)}
              iconName={`chat-question-outline`}
              testID={`drawer-menu-sign-out-button`}
              //iconType={}
            />
          }
        </View>

        <View>
          {/* sign out and copyright */}
          <NDrawerItem
            label={`Sign Out`}
            focused={false}
            onPress={signOut}
            iconName={`exit-to-app`}
            //iconType={}
          />
          <Text
            style={{
              ...themeFromContext.textVariants.detail,
              color: themeFromContext.colors.detail,
              marginBottom: 10,
              marginTop: 40,
              textAlign: 'center',
            }}
          >
            {`© ${year} NOVELLA, INC.`}
          </Text>
          {
            version != null && build != null &&
            <Text
              style={{
                ...themeFromContext.textVariants.detail,
                color: themeFromContext.colors.detail,
                //marginBottom: 30,
                //marginTop: 40,
                textAlign: 'center',
              }}
            >
              {`${version} (${build})`}
            </Text>
          }
          <View
            style={{marginBottom: 30}}
          ></View>
        </View>

      </View>
    </View>
  );
}

function CustomDrawerContent(props: DrawerContentComponentProps): React.ReactElement {
  return (
    <DrawerContentScrollView
      {...props}
      contentContainerStyle={{
        flex: 1,
        display: 'flex',
        margin: 0,
        height: '100%',
        justifyContent: 'flex-start',
        alignContent: 'flex-start',
      }}
    >
      <CustomContentComponent {...props} />
    </DrawerContentScrollView>
  );
}

export default CustomDrawerContent;
