import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function Question(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      viewBox="0 0 140 140"
      {...props}
    >
      <G data-name="Question Illustration">
        <Path
          data-name="Path 70958"
          d="M140 70a69.914 69.914 0 01-65.914 69.882Q72.059 140 70 140a70.112 70.112 0 01-11.431-.931q-2.469-.407-4.878-.983a69.783 69.783 0 01-28.266-14.112A70 70 0 11140 70zm0 0"
          fill="#cdebec"
        />
        <G data-name="Group 3611" fill="#7fcecf">
          <Path
            data-name="Path 70959"
            d="M392.73 438.261a70.063 70.063 0 01-11.914 8.692 7.454 7.454 0 016.114-11.567 7.305 7.305 0 015.8 2.875zm0 0"
            transform="translate(62.109 63.903) translate(-337.896 -380.239)"
          />
          <Path
            data-name="Path 70960"
            d="M363.071 233.7c-10.269 0-17.426 5.878-17.426 11.153a5.775 5.775 0 005.761 5.761c2.249 0 3.521-1.406 4.542-2.535 1.31-1.447 2.664-2.945 6.858-2.945 3 0 8.025.881 8.025 6.788 0 5.015-3.587 8.01-6.752 10.653-.716.6-1.392 1.162-2.019 1.741-2.7 2.5-5.457 6.361-5.457 16.062 0 4.115 1.879 6.2 5.584 6.2 4.317 0 5.849-2.629 5.849-4.878 0-5.618.123-8.01 3.187-10.789l.1-.1c.417-.429.976-.94 1.626-1.531 3.717-3.386 9.937-9.052 9.937-18.252 0-10.37-7.962-17.339-19.814-17.339zm0 0"
            transform="translate(62.109 63.903) translate(-313.241 -233.703)"
          />
          <Path
            data-name="Path 70961"
            d="M264.38 390c0 8.911-5.84 14.511-9.583 17.928a69.793 69.793 0 01-15.68 2.714 14.919 14.919 0 014.44-7.366 51.27 51.27 0 012.02-1.74c3.163-2.643 6.752-5.64 6.752-10.653 0-5.907-5.029-6.789-8.026-6.789-4.194 0-5.549 1.5-6.859 2.946-1.02 1.128-2.292 2.534-4.541 2.534a5.775 5.775 0 01-5.762-5.762c0-5.274 7.156-11.151 17.428-11.151 11.849.003 19.811 6.971 19.811 17.339zm0 0"
            transform="translate(62.109 63.903) translate(-227.141 -334.667)"
          />
        </G>
        <Path
          data-name="Path 70962"
          d="M120.6 380.461v8a69.432 69.432 0 01-22.943 8.123l-.116.017a70.076 70.076 0 01-43.9-26.186 26.071 26.071 0 0117.26-15.08q.609-.163 1.229-.3a25.716 25.716 0 013.385-.5q1.054-.087 2.129-.087h16.94a26 26 0 015.517.586c.1.02.206.043.305.065.3.069.6.143.9.223a.171.171 0 00.022.005 26.041 26.041 0 0119.272 25.134zm0 0"
          transform="translate(-38.976 -257.53)"
          fill="#f78c45"
        />
        <Path
          data-name="Path 70963"
          d="M149.187 380.461v16.106a70.14 70.14 0 01-56.2-15.064v-1.042a26.019 26.019 0 0119.272-25.128c.406-.109.814-.208 1.228-.3a25.727 25.727 0 013.386-.5c.7-.058 1.411-.087 2.129-.087h4.171a26 26 0 015.517.586c.1.02.206.043.305.065.3.069.6.143.9.223a.171.171 0 00.022.005 26.041 26.041 0 0119.277 25.132zm0 0"
          transform="translate(-67.558 -257.53)"
          fill="#ffe16b"
        />
        <Path
          data-name="Path 70964"
          d="M147.087 356.594l-.063.294-8.66 40.286a69.07 69.07 0 01-14.191-5.066l-7.573-35.217-.062-.3zm0 0"
          transform="translate(-84.673 -259.088)"
          fill="#f2efea"
        />
        <Path
          data-name="Path 70965"
          d="M131.81 355.27l-11.71 6.509-3.562-16.572 7.277-4.285"
          transform="translate(-84.67 -247.702)"
          fill="#d8d4ce"
        />
        <Path
          data-name="Path 70966"
          d="M172.4 355.27l11.711 6.509 3.563-16.572-7.277-4.285"
          transform="translate(-125.256 -247.702)"
          fill="#d8d4ce"
        />
        <Path
          data-name="Path 70967"
          d="M44.516 134.348V95.116a34.97 34.97 0 1169.939 0v39.232"
          transform="translate(-32.343 -43.699)"
          fill="#273647"
        />
        <Path
          data-name="Path 70968"
          d="M58.9 96.656c0 13.877 8.125 29.932 12.588 37.7H44.512V95.124a34.949 34.949 0 0134.715-34.968c-10.752.268-20.327 17.517-20.327 36.5zm0 0"
          transform="translate(-32.341 -43.707)"
          fill="#151a1c"
        />
        <Path
          data-name="Path 70969"
          d="M157.024 317.8v11.389a9.281 9.281 0 01-18.563 0V317.8zm0 0"
          transform="translate(-100.601 -230.905)"
          fill="#ea947c"
        />
        <Path
          data-name="Path 70970"
          d="M171.814 356.82v.721a9.28 9.28 0 01-6.537 8.865 9.276 9.276 0 01-6.46-7.656q.929.056 1.869.057a32.068 32.068 0 0011.128-1.987zm0 0"
          transform="translate(-115.39 -259.252)"
          fill="#f6c2b4"
        />
        <Path
          data-name="Path 70971"
          d="M33.427 226.6a8.628 8.628 0 11-7.248-8.52 8.632 8.632 0 017.248 8.52zm0 0"
          transform="translate(-11.744 -158.368)"
          fill="#f6c2b4"
        />
        <Path
          data-name="Path 70972"
          d="M56.992 226.9a8.632 8.632 0 01-7.248 8.52 32.245 32.245 0 010-17.041 8.632 8.632 0 017.248 8.521zm0 0"
          transform="translate(-35.309 -158.663)"
          fill="#ea947c"
        />
        <Path
          data-name="Path 70973"
          d="M42.619 241.338a3.085 3.085 0 11-3.085-3.085 3.085 3.085 0 013.085 3.085zm0 0"
          transform="translate(-26.482 -173.106)"
          fill="#ea947c"
        />
        <Path
          data-name="Path 70974"
          d="M109.239 157.173v29.668A31.99 31.99 0 0192.23 195.3l-.157.026a31.672 31.672 0 01-5 .388 32.112 32.112 0 01-32.112-32.111v-12.225a32.185 32.185 0 01.329-4.611 20.348 20.348 0 0025.574-1.646 18.193 18.193 0 004.826-7.54 21.188 21.188 0 006.629 13.229 23.7 23.7 0 0016.917 6.363zm0 0"
          transform="translate(-39.935 -99.961)"
          fill="#ea947c"
        />
        <Path
          data-name="Path 70975"
          d="M135.81 157.173v29.668a31.99 31.99 0 01-17.01 8.459l-.157.026a32.117 32.117 0 01-27.109-31.723v-12.225c0-.326.006-.648.017-.971a20.124 20.124 0 0015.886-5.286 18.193 18.193 0 004.826-7.54 21.188 21.188 0 006.629 13.229 23.7 23.7 0 0016.917 6.363zm0 0"
          transform="translate(-66.506 -99.961)"
          fill="#f6c2b4"
        />
        <Path
          data-name="Path 70976"
          d="M207.131 208.673a5.333 5.333 0 10-10.666 0"
          transform="translate(-142.744 -147.739)"
          fill="#ea947c"
        />
        <Path
          data-name="Path 70977"
          d="M120.013 208.673a5.333 5.333 0 10-10.666 0"
          transform="translate(-79.447 -147.739)"
          fill="#ea947c"
        />
        <Path
          data-name="Path 70978"
          d="M122.814 221.467a3.643 3.643 0 11-3.643-3.643 3.643 3.643 0 013.643 3.643zm0 0"
          transform="translate(-83.938 -158.263)"
          fill="#273647"
        />
        <Path
          data-name="Path 70979"
          d="M209.934 221.467a3.643 3.643 0 11-3.643-3.643 3.643 3.643 0 013.643 3.643zm0 0"
          transform="translate(-147.237 -158.263)"
          fill="#273647"
        />
        <Path
          data-name="Path 70980"
          d="M162.2 256.341h-7.287a1.571 1.571 0 110-3.142h7.287a1.571 1.571 0 010 3.142zm0 0"
          transform="translate(-111.411 -183.965)"
          fill="#ea947c"
        />
        <Path
          data-name="Path 70981"
          d="M149.64 288.558a16.239 16.239 0 01-7.829-2.021 1.429 1.429 0 011.377-2.5 13.332 13.332 0 0012.9 0 1.429 1.429 0 111.377 2.5 16.236 16.236 0 01-7.825 2.021zm0 0"
          transform="translate(-102.497 -206.24)"
          fill="#ea947c"
        />
        <Path
          data-name="Path 70982"
          d="M131.161 224.648a1.561 1.561 0 11-1.561-1.562 1.56 1.56 0 011.561 1.562zm0 0"
          transform="translate(-93.028 -162.086)"
          fill="#fff"
        />
        <Path
          data-name="Path 70983"
          d="M218.934 224.648a1.561 1.561 0 11-1.562-1.562 1.561 1.561 0 011.562 1.562zm0 0"
          transform="translate(-156.801 -162.086)"
          fill="#fff"
        />
        <Path
          data-name="Path 70984"
          d="M231.082 252.969a4 4 0 11-4-4 4 4 0 014 4zm0 0"
          transform="translate(-162.083 -180.892)"
          fill="#ea947c"
        />
        <Path
          data-name="Path 70985"
          d="M100.472 252.969a4 4 0 11-4-4 4 4 0 014 4zm0 0"
          transform="translate(-67.187 -180.892)"
          fill="#ea947c"
        />
        <Path
          data-name="Path 70986"
          d="M320.63 45.977a9.27 9.27 0 01-1.349 3.331 9.38 9.38 0 01-6.7 4.166 211.514 211.514 0 01-29.154 1.566l-17.109 10.01a2.064 2.064 0 01-3.105-1.781v-9.522c-.789-.089-1.578-.175-2.364-.272a9.37 9.37 0 01-8.065-7.537 97.471 97.471 0 01.009-36.849 9.347 9.347 0 018.06-7.5q2.052-.253 4.1-.466a210.836 210.836 0 0147.614.466 9.345 9.345 0 018.06 7.5 97.569 97.569 0 010 36.888zm0 0"
          transform="translate(-182.39)"
          fill="#caf1ff"
        />
        <Path
          data-name="Path 70987"
          d="M319.285 52.3a9.38 9.38 0 01-6.7 4.166 211.515 211.515 0 01-29.154 1.566l-17.109 10.009a2.063 2.063 0 01-3.105-1.781v-9.522c-.789-.089-1.578-.175-2.364-.272a9.37 9.37 0 01-8.065-7.537 97.613 97.613 0 01.012-36.849 9.346 9.346 0 018.06-7.5q2.052-.253 4.1-.466A70.1 70.1 0 01319.285 52.3zm0 0"
          transform="translate(-182.394 -2.992)"
          fill="#82e2ff"
        />
        <Path
          data-name="Path 70988"
          d="M362.873 149.9a4.347 4.347 0 104.295 4.347 4.326 4.326 0 00-4.295-4.347zm0 0"
          transform="translate(-260.529 -108.908)"
          fill="#36507d"
        />
        <Path
          data-name="Path 70989"
          d="M348.931 32.07c-6 0-10.181 3.434-10.181 6.515a3.374 3.374 0 003.366 3.366 3.481 3.481 0 002.654-1.48 4.5 4.5 0 014.007-1.721c1.751 0 4.688.515 4.688 3.966 0 2.93-2.1 4.679-3.945 6.224-.418.349-.813.679-1.179 1.017-1.577 1.46-3.188 3.716-3.188 9.384 0 2.4 1.1 3.624 3.263 3.624 2.522 0 3.417-1.536 3.417-2.85 0-3.282.072-4.679 1.863-6.3l.057-.054c.244-.251.57-.549.95-.9 2.171-1.978 5.805-5.288 5.805-10.663 0-6.058-4.652-10.129-11.575-10.129zm0 0"
          transform="translate(-246.123 -23.301)"
          fill="#36507d"
        />
        <Path
          data-name="Path 70990"
          d="M264.505 114.9v13.764a2.051 2.051 0 01-1.288-1.912v-9.522c-.789-.089-1.578-.175-2.364-.272a9.37 9.37 0 01-8.065-7.537 97.7 97.7 0 01-1.682-22.046 34.943 34.943 0 0113.4 27.525zm0 0"
          transform="translate(-182.394 -63.484)"
          fill="#3fb7d4"
        />
      </G>
    </Svg>
  )
}

export default Question
