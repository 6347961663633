// Taken from the solution referenced here: https://github.com/GoogleChromeLabs/browser-fs-access/issues/88

function debounce<T extends any[]>(
  fn: (...args: T) => void,
  timeout: number,
) {
  let handle = 0;
  let lastArgs: T | null = null;
  const ret = (...args: T) => {
    lastArgs = args;
    clearTimeout(handle);
    handle = window.setTimeout(() => {
      lastArgs = null;
      fn(...args);
    }, timeout);
  };
  ret.flush = () => {
    clearTimeout(handle);
    if (lastArgs) {
      const _lastArgs = lastArgs;
      lastArgs = null;
      fn(..._lastArgs);
    }
  };
  ret.cancel = () => {
    lastArgs = null;
    clearTimeout(handle);
  };
  return ret;
}

enum EVENT {
  KEYUP = "keyup",
  MOUSE_MOVE = "mousemove",
  FOCUS = "focus",
  POINTER_UP = "pointerup",
}

const INPUT_CHANGE_INTERVAL_MS = 500;

export function legacySetup(resolve, reject, input) {
  const scheduleRejection = debounce(reject, INPUT_CHANGE_INTERVAL_MS);
  const focusHandler = () => {
    checkForFile();
    document.addEventListener(EVENT.KEYUP, scheduleRejection);
    document.addEventListener(EVENT.POINTER_UP, scheduleRejection);
    scheduleRejection();
  };
  const checkForFile = () => {
    // this hack might not work when expecting multiple files
    if (input.files?.length) {
      const ret = input.files[0];
      resolve(ret);
    }
  };
  requestAnimationFrame(() => {
    window.addEventListener(EVENT.FOCUS, focusHandler);
  });
  const interval = window.setInterval(() => {
    checkForFile();
  }, INPUT_CHANGE_INTERVAL_MS);
  return (rejectPromise) => {
    clearInterval(interval);
    scheduleRejection.cancel();
    window.removeEventListener(EVENT.FOCUS, focusHandler);
    document.removeEventListener(EVENT.KEYUP, scheduleRejection);
    document.removeEventListener(EVENT.POINTER_UP, scheduleRejection);
    if (rejectPromise) {
      // so that something is shown in console if we need to debug this
      console.warn("Opening the file was canceled (legacy-fs).");
      rejectPromise(() => { throw `Request Aborted` });
    }
  };
}