import * as FileSystem from 'expo-file-system';

export const UPLOAD_SUBDIRECTORY = `uploads/`;
export const DOWNLOAD_CACHE_SUBDIRECTORY = `download-cache/`;

export const UPLOAD_STORAGE_DIRECTORY = `${FileSystem.documentDirectory}${UPLOAD_SUBDIRECTORY}`;
export const DOWNLOAD_CACHE_STORAGE_DIRECTORY = `${FileSystem.documentDirectory}${DOWNLOAD_CACHE_SUBDIRECTORY}`;

export async function getUploadDirectory() : Promise<string> {
  console.log(`application sandbox:`);
  console.log(UPLOAD_STORAGE_DIRECTORY);
  const info = await FileSystem.getInfoAsync(UPLOAD_STORAGE_DIRECTORY);
  if (!info.exists) {
    await FileSystem.makeDirectoryAsync(UPLOAD_STORAGE_DIRECTORY);
  }
  return UPLOAD_STORAGE_DIRECTORY;
}

export async function getDownloadCacheDirectory() : Promise<string> {
  console.log(`application sandbox:`);
  console.log(DOWNLOAD_CACHE_STORAGE_DIRECTORY);
  const info = await FileSystem.getInfoAsync(DOWNLOAD_CACHE_STORAGE_DIRECTORY);
  if (!info.exists) {
    await FileSystem.makeDirectoryAsync(DOWNLOAD_CACHE_STORAGE_DIRECTORY);
  }
  return DOWNLOAD_CACHE_STORAGE_DIRECTORY;
}