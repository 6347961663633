/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type ModelCommunityQuestionFilterInput = {
  id?: ModelIDInput | null,
  communityID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  categoryID?: ModelIDInput | null,
  communityCategory?: ModelStringInput | null,
  date?: ModelStringInput | null,
  status?: ModelCommunityQuestionStatusInput | null,
  source?: ModelCommunityQuestionSourceInput | null,
  questionText?: ModelStringInput | null,
  imageKeys?: ModelStringInput | null,
  clonedFromCommunityQuestionRecommendationId?: ModelIDInput | null,
  and?: Array< ModelCommunityQuestionFilterInput | null > | null,
  or?: Array< ModelCommunityQuestionFilterInput | null > | null,
  not?: ModelCommunityQuestionFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelCommunityQuestionStatusInput = {
  eq?: CommunityQuestionStatus | null,
  ne?: CommunityQuestionStatus | null,
};

export enum CommunityQuestionStatus {
  ADDED = "ADDED",
  DELETED = "DELETED",
  FLAGGED = "FLAGGED",
}


export type ModelCommunityQuestionSourceInput = {
  eq?: CommunityQuestionSource | null,
  ne?: CommunityQuestionSource | null,
};

export enum CommunityQuestionSource {
  USER = "USER",
  RECOMMENDATION = "RECOMMENDATION",
}


export type ModelCommunityQuestionConnection = {
  __typename: "ModelCommunityQuestionConnection",
  items:  Array<CommunityQuestion | null >,
  nextToken?: string | null,
};

export type CommunityQuestion = {
  __typename: "CommunityQuestion",
  id: string,
  communityID: string,
  userID: string,
  categoryID?: string | null,
  communityCategory?: string | null,
  date: string,
  status: CommunityQuestionStatus,
  source: CommunityQuestionSource,
  questionText: string,
  imageKeys?: Array< string > | null,
  clonedFromCommunityQuestionRecommendationId?: string | null,
  createdAt: string,
  updatedAt: string,
  category?: Category | null,
  community: Community,
  owner?: string | null,
  communityQuestionAnswersByStatusDate?: ModelCommunityQuestionAnswerConnection | null,
  communityQuestionLikesByStatus?: ModelCommunityQuestionLikeConnection | null,
  user: User,
};

export type Category = {
  __typename: "Category",
  id: string,
  name: string,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type Community = {
  __typename: "Community",
  id: string,
  name: string,
  imageKey?: string | null,
  type?: CommunityType | null,
  allowPublicPages?: boolean | null,
  alias?: string | null,
  publicPreviewHeaderURL?: string | null,
  publicPreviewDescription?: string | null,
  requiresModeration?: boolean | null,
  allowUnauthenticatedAnswers?: boolean | null,
  limits?: CommunityLimits | null,
  vimeoVideos?: Array< string > | null,
  communityCategories?: Array< string > | null,
  interviewTab?: InterviewTab | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
  communityQuestionsByStatusDate?: ModelCommunityQuestionConnection | null,
  communityQuestionRecommendations?: ModelCommunityQuestionRecommendationConnection | null,
  communityUsersByStatusDate?: ModelCommunityUserConnection | null,
};

export enum CommunityType {
  DEFAULT = "DEFAULT",
  PASSED = "PASSED",
}


export type CommunityLimits = {
  __typename: "CommunityLimits",
  maxSubjects?: number | null,
  maxMembers?: number | null,
  maxInvitationLength?: number | null,
  maxVideoDurationSeconds?: number | null,
};

export type InterviewTab = {
  __typename: "InterviewTab",
  header: string,
  bodyLine1: string,
  bodyLine2: string,
};

export type ModelCommunityQuestionRecommendationConnection = {
  __typename: "ModelCommunityQuestionRecommendationConnection",
  items:  Array<CommunityQuestionRecommendation | null >,
  nextToken?: string | null,
};

export type CommunityQuestionRecommendation = {
  __typename: "CommunityQuestionRecommendation",
  id: string,
  communityID: string,
  categoryID: string,
  questionText: string,
  score: number,
  status?: CommunityQuestionRecommendationStatus | null,
  createdAt: string,
  updatedAt: string,
  category: Category,
  community: Community,
  owner?: string | null,
};

export enum CommunityQuestionRecommendationStatus {
  DEFAULT = "DEFAULT",
  DELETED = "DELETED",
}


export type ModelCommunityUserConnection = {
  __typename: "ModelCommunityUserConnection",
  items:  Array<CommunityUser | null >,
  nextToken?: string | null,
};

export type CommunityUser = {
  __typename: "CommunityUser",
  id: string,
  communityID: string,
  userID: string,
  joinedOn: string,
  invitedByUserID: string,
  invitedOn: string,
  invitation?: CommunityUserInvitation | null,
  status: CommunityUserStatus,
  relationshipToSpotlight?: string | null,
  owner?: string | null,
  group?: string | null,
  createdAt: string,
  updatedAt: string,
  community: Community,
  user: User,
  invitedByUser: User,
};

export type CommunityUserInvitation = {
  __typename: "CommunityUserInvitation",
  invitationMessage: string,
};

export enum CommunityUserStatus {
  INVITED = "INVITED",
  MEMBER = "MEMBER",
  ORGANIZER = "ORGANIZER",
  SUBJECT = "SUBJECT",
  REMOVED = "REMOVED",
  BANNED = "BANNED",
  ADMIN = "ADMIN",
}


export type User = {
  __typename: "User",
  id: string,
  firstName: string,
  lastName: string,
  email?: string | null,
  status?: UserStatus | null,
  profileImageKey?: string | null,
  communityLimits?: CommunityLimits | null,
  welcomesSeen?: Array< string > | null,
  helpsSeen?: Array< string > | null,
  nextSteps?:  Array<CommunityNextStep > | null,
  referralCode?: string | null,
  referredByUserID?: string | null,
  username?: string | null,
  identityID?: string | null,
  createdAt: string,
  updatedAt: string,
  communityQuestionsByStatusDate?: ModelCommunityQuestionConnection | null,
  communityQuestionAnswersByUserStatusDate?: ModelCommunityQuestionAnswerConnection | null,
  communityQuestionlikesByStatusDate?: ModelCommunityQuestionLikeConnection | null,
  communityUsersByStatusDate?: ModelCommunityUserConnection | null,
};

export enum UserStatus {
  INVITED = "INVITED",
  REFERRED = "REFERRED",
  JOINED = "JOINED",
  PLACEHOLDER = "PLACEHOLDER",
  DELETED = "DELETED",
}


export type CommunityNextStep = {
  __typename: "CommunityNextStep",
  communityID: string,
  nextStepsByRole?: NextStepsByRole | null,
};

export type NextStepsByRole = {
  __typename: "NextStepsByRole",
  INVITED?: Array< NextStepType > | null,
  MEMBER?: Array< NextStepType > | null,
  ORGANIZER?: Array< NextStepType > | null,
  SUBJECT?: Array< NextStepType > | null,
  REMOVED?: Array< NextStepType > | null,
  BANNED?: Array< NextStepType > | null,
  ADMIN?: Array< NextStepType > | null,
};

export enum NextStepType {
  INVITE_ORGANIZERS = "INVITE_ORGANIZERS",
  INVITE_MEMBERS = "INVITE_MEMBERS",
  ASK_QUESTIONS = "ASK_QUESTIONS",
  LIKE_QUESTIONS = "LIKE_QUESTIONS",
  REQUEST_INTERVIEW = "REQUEST_INTERVIEW",
}


export type ModelCommunityQuestionAnswerConnection = {
  __typename: "ModelCommunityQuestionAnswerConnection",
  items:  Array<CommunityQuestionAnswer | null >,
  nextToken?: string | null,
};

export type CommunityQuestionAnswer = {
  __typename: "CommunityQuestionAnswer",
  id: string,
  communityQuestionID: string,
  userID: string,
  communityID: string,
  status: CommunityQuestionAnswerStatus,
  date: string,
  content: string,
  createdAt: string,
  updatedAt: string,
  communityQuestion: CommunityQuestion,
  owner?: string | null,
  communityQuestionAnswerCommentsByStatusDate?: ModelCommunityQuestionAnswerCommentConnection | null,
  communityQuestionAnswerLikesByStatus?: ModelCommunityQuestionAnswerLikeConnection | null,
  user: User,
};

export enum CommunityQuestionAnswerStatus {
  ADDED = "ADDED",
  DELETED = "DELETED",
  FLAGGED = "FLAGGED",
}


export type ModelCommunityQuestionAnswerCommentConnection = {
  __typename: "ModelCommunityQuestionAnswerCommentConnection",
  items:  Array<CommunityQuestionAnswerComment | null >,
  nextToken?: string | null,
};

export type CommunityQuestionAnswerComment = {
  __typename: "CommunityQuestionAnswerComment",
  id: string,
  userID: string,
  communityID: string,
  communityQuestionAnswerID: string,
  content: string,
  status: CommunityQuestionAnswerCommentStatus,
  date: string,
  createdAt: string,
  updatedAt: string,
  communityQuestionAnswer: CommunityQuestionAnswer,
  owner?: string | null,
  user: User,
};

export enum CommunityQuestionAnswerCommentStatus {
  ADDED = "ADDED",
  DELETED = "DELETED",
}


export type ModelCommunityQuestionAnswerLikeConnection = {
  __typename: "ModelCommunityQuestionAnswerLikeConnection",
  items:  Array<CommunityQuestionAnswerLike | null >,
  nextToken?: string | null,
};

export type CommunityQuestionAnswerLike = {
  __typename: "CommunityQuestionAnswerLike",
  id: string,
  userID: string,
  communityID: string,
  communityQuestionAnswerID: string,
  status: CommunityQuestionAnswerLikeStatus,
  date: string,
  createdAt: string,
  updatedAt: string,
  communityQuestionAnswer: CommunityQuestionAnswer,
  owner?: string | null,
  user: User,
};

export enum CommunityQuestionAnswerLikeStatus {
  ADDED = "ADDED",
  DELETED = "DELETED",
}


export type ModelCommunityQuestionLikeConnection = {
  __typename: "ModelCommunityQuestionLikeConnection",
  items:  Array<CommunityQuestionLike | null >,
  nextToken?: string | null,
};

export type CommunityQuestionLike = {
  __typename: "CommunityQuestionLike",
  id: string,
  userID: string,
  communityQuestionID: string,
  status: CommunityQuestionLikeStatus,
  date: string,
  createdAt: string,
  updatedAt: string,
  communityQuestion: CommunityQuestion,
  user: User,
};

export enum CommunityQuestionLikeStatus {
  ADDED = "ADDED",
  DELETED = "DELETED",
}


export type ModelCommunityQuestionAnswerByCommunityQuestionByStatusByDateCompositeKeyConditionInput = {
  eq?: ModelCommunityQuestionAnswerByCommunityQuestionByStatusByDateCompositeKeyInput | null,
  le?: ModelCommunityQuestionAnswerByCommunityQuestionByStatusByDateCompositeKeyInput | null,
  lt?: ModelCommunityQuestionAnswerByCommunityQuestionByStatusByDateCompositeKeyInput | null,
  ge?: ModelCommunityQuestionAnswerByCommunityQuestionByStatusByDateCompositeKeyInput | null,
  gt?: ModelCommunityQuestionAnswerByCommunityQuestionByStatusByDateCompositeKeyInput | null,
  between?: Array< ModelCommunityQuestionAnswerByCommunityQuestionByStatusByDateCompositeKeyInput | null > | null,
  beginsWith?: ModelCommunityQuestionAnswerByCommunityQuestionByStatusByDateCompositeKeyInput | null,
};

export type ModelCommunityQuestionAnswerByCommunityQuestionByStatusByDateCompositeKeyInput = {
  status?: CommunityQuestionAnswerStatus | null,
  date?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type ModelCommunityQuestionAnswerFilterInput = {
  id?: ModelIDInput | null,
  communityQuestionID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  communityID?: ModelIDInput | null,
  status?: ModelCommunityQuestionAnswerStatusInput | null,
  date?: ModelStringInput | null,
  content?: ModelStringInput | null,
  and?: Array< ModelCommunityQuestionAnswerFilterInput | null > | null,
  or?: Array< ModelCommunityQuestionAnswerFilterInput | null > | null,
  not?: ModelCommunityQuestionAnswerFilterInput | null,
};

export type ModelCommunityQuestionAnswerStatusInput = {
  eq?: CommunityQuestionAnswerStatus | null,
  ne?: CommunityQuestionAnswerStatus | null,
};

export type CreatePacemakerLastRunInput = {
  id?: string | null,
  entries: Array< PacemakerRunEntryInput >,
};

export type PacemakerRunEntryInput = {
  job: string,
  timestamp: string,
};

export type ModelPacemakerLastRunConditionInput = {
  and?: Array< ModelPacemakerLastRunConditionInput | null > | null,
  or?: Array< ModelPacemakerLastRunConditionInput | null > | null,
  not?: ModelPacemakerLastRunConditionInput | null,
};

export type PacemakerLastRun = {
  __typename: "PacemakerLastRun",
  id: string,
  entries:  Array<PacemakerRunEntry >,
  createdAt: string,
  updatedAt: string,
};

export type PacemakerRunEntry = {
  __typename: "PacemakerRunEntry",
  job: string,
  timestamp: string,
};

export type UpdatePacemakerLastRunInput = {
  entries?: Array< PacemakerRunEntryInput > | null,
};

export type DeletePacemakerLastRunInput = {
  id: string,
};

export type CreateZAnswerCreatedNotifySigInput = {
  id?: string | null,
  createdAt?: string | null,
};

export type ModelzAnswerCreatedNotifySigConditionInput = {
  and?: Array< ModelzAnswerCreatedNotifySigConditionInput | null > | null,
  or?: Array< ModelzAnswerCreatedNotifySigConditionInput | null > | null,
  not?: ModelzAnswerCreatedNotifySigConditionInput | null,
};

export type zAnswerCreatedNotifySig = {
  __typename: "zAnswerCreatedNotifySig",
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateZAnswerCreatedNotifySigInput = {
  id: string,
  createdAt: string,
};

export type DeleteZAnswerCreatedNotifySigInput = {
  id: string,
  createdAt: string,
};

export type CreateZAnswerCreatedNotifyBacklogInput = {
  communityID: string,
  communityQuestionAnswerID: string,
  communityQuestionAnswerCreatedAt: string,
  userID: string,
  createdAt?: string | null,
};

export type ModelzAnswerCreatedNotifyBacklogConditionInput = {
  communityID?: ModelStringInput | null,
  communityQuestionAnswerCreatedAt?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzAnswerCreatedNotifyBacklogConditionInput | null > | null,
  or?: Array< ModelzAnswerCreatedNotifyBacklogConditionInput | null > | null,
  not?: ModelzAnswerCreatedNotifyBacklogConditionInput | null,
};

export type zAnswerCreatedNotifyBacklog = {
  __typename: "zAnswerCreatedNotifyBacklog",
  communityID: string,
  communityQuestionAnswerID: string,
  communityQuestionAnswerCreatedAt: string,
  userID: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateZAnswerCreatedNotifyBacklogInput = {
  communityID?: string | null,
  communityQuestionAnswerID: string,
  communityQuestionAnswerCreatedAt?: string | null,
  userID?: string | null,
  createdAt?: string | null,
};

export type DeleteZAnswerCreatedNotifyBacklogInput = {
  communityQuestionAnswerID: string,
};

export type CreateZAnswerCreatedNotifyCompleteInput = {
  communityID: string,
  communityQuestionAnswerID: string,
  userID: string,
  createdAt?: string | null,
  completedAt: string,
};

export type ModelzAnswerCreatedNotifyCompleteConditionInput = {
  communityID?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  and?: Array< ModelzAnswerCreatedNotifyCompleteConditionInput | null > | null,
  or?: Array< ModelzAnswerCreatedNotifyCompleteConditionInput | null > | null,
  not?: ModelzAnswerCreatedNotifyCompleteConditionInput | null,
};

export type zAnswerCreatedNotifyComplete = {
  __typename: "zAnswerCreatedNotifyComplete",
  communityID: string,
  communityQuestionAnswerID: string,
  userID: string,
  createdAt: string,
  completedAt: string,
  updatedAt: string,
};

export type UpdateZAnswerCreatedNotifyCompleteInput = {
  communityID?: string | null,
  communityQuestionAnswerID: string,
  userID?: string | null,
  createdAt?: string | null,
  completedAt?: string | null,
};

export type DeleteZAnswerCreatedNotifyCompleteInput = {
  communityQuestionAnswerID: string,
};

export type CreateZCommentMadeNotifySigInput = {
  id?: string | null,
  createdAt?: string | null,
};

export type ModelzCommentMadeNotifySigConditionInput = {
  and?: Array< ModelzCommentMadeNotifySigConditionInput | null > | null,
  or?: Array< ModelzCommentMadeNotifySigConditionInput | null > | null,
  not?: ModelzCommentMadeNotifySigConditionInput | null,
};

export type zCommentMadeNotifySig = {
  __typename: "zCommentMadeNotifySig",
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateZCommentMadeNotifySigInput = {
  id: string,
  createdAt: string,
};

export type DeleteZCommentMadeNotifySigInput = {
  id: string,
  createdAt: string,
};

export type CreateZCommentMadeNotifyBacklogInput = {
  communityID: string,
  communityQuestionAnswerID: string,
  communityQuestionAnswerCommentCreatedAt: string,
  userID: string,
  createdAt?: string | null,
};

export type ModelzCommentMadeNotifyBacklogConditionInput = {
  communityQuestionAnswerID?: ModelStringInput | null,
  communityQuestionAnswerCommentCreatedAt?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzCommentMadeNotifyBacklogConditionInput | null > | null,
  or?: Array< ModelzCommentMadeNotifyBacklogConditionInput | null > | null,
  not?: ModelzCommentMadeNotifyBacklogConditionInput | null,
};

export type zCommentMadeNotifyBacklog = {
  __typename: "zCommentMadeNotifyBacklog",
  communityID: string,
  communityQuestionAnswerID: string,
  communityQuestionAnswerCommentCreatedAt: string,
  userID: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateZCommentMadeNotifyBacklogInput = {
  communityID: string,
  communityQuestionAnswerID?: string | null,
  communityQuestionAnswerCommentCreatedAt?: string | null,
  userID?: string | null,
  createdAt?: string | null,
};

export type DeleteZCommentMadeNotifyBacklogInput = {
  communityID: string,
};

export type CreateZCommentMadeNotifyCompleteInput = {
  communityID: string,
  communityQuestionAnswerID: string,
  userID: string,
  createdAt?: string | null,
  completedAt: string,
};

export type ModelzCommentMadeNotifyCompleteConditionInput = {
  communityQuestionAnswerID?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  and?: Array< ModelzCommentMadeNotifyCompleteConditionInput | null > | null,
  or?: Array< ModelzCommentMadeNotifyCompleteConditionInput | null > | null,
  not?: ModelzCommentMadeNotifyCompleteConditionInput | null,
};

export type zCommentMadeNotifyComplete = {
  __typename: "zCommentMadeNotifyComplete",
  communityID: string,
  communityQuestionAnswerID: string,
  userID: string,
  createdAt: string,
  completedAt: string,
  updatedAt: string,
};

export type UpdateZCommentMadeNotifyCompleteInput = {
  communityID: string,
  communityQuestionAnswerID?: string | null,
  userID?: string | null,
  createdAt?: string | null,
  completedAt?: string | null,
};

export type DeleteZCommentMadeNotifyCompleteInput = {
  communityID: string,
};

export type CreateZCommunityInvitationReminderNotifySigInput = {
  id?: string | null,
  source: string,
  createdAt?: string | null,
  signal: JobSignalInput,
};

export type JobSignalInput = {
  payload: string,
};

export type ModelzCommunityInvitationReminderNotifySigConditionInput = {
  source?: ModelStringInput | null,
  and?: Array< ModelzCommunityInvitationReminderNotifySigConditionInput | null > | null,
  or?: Array< ModelzCommunityInvitationReminderNotifySigConditionInput | null > | null,
  not?: ModelzCommunityInvitationReminderNotifySigConditionInput | null,
};

export type zCommunityInvitationReminderNotifySig = {
  __typename: "zCommunityInvitationReminderNotifySig",
  id: string,
  source: string,
  createdAt: string,
  signal: JobSignal,
  updatedAt: string,
};

export type JobSignal = {
  __typename: "JobSignal",
  payload: string,
};

export type UpdateZCommunityInvitationReminderNotifySigInput = {
  id: string,
  source?: string | null,
  createdAt: string,
  signal?: JobSignalInput | null,
};

export type DeleteZCommunityInvitationReminderNotifySigInput = {
  id: string,
  createdAt: string,
};

export type CreateZCommunityInvitationReminderNotifyBacklogInput = {
  communityUserID: string,
  userID: string,
  communityID: string,
  status: CommunityUserStatus,
  invitedByUserID: string,
  invitedOn: string,
  createdAt?: string | null,
};

export type ModelzCommunityInvitationReminderNotifyBacklogConditionInput = {
  userID?: ModelStringInput | null,
  communityID?: ModelStringInput | null,
  status?: ModelCommunityUserStatusInput | null,
  invitedByUserID?: ModelStringInput | null,
  invitedOn?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzCommunityInvitationReminderNotifyBacklogConditionInput | null > | null,
  or?: Array< ModelzCommunityInvitationReminderNotifyBacklogConditionInput | null > | null,
  not?: ModelzCommunityInvitationReminderNotifyBacklogConditionInput | null,
};

export type ModelCommunityUserStatusInput = {
  eq?: CommunityUserStatus | null,
  ne?: CommunityUserStatus | null,
};

export type zCommunityInvitationReminderNotifyBacklog = {
  __typename: "zCommunityInvitationReminderNotifyBacklog",
  communityUserID: string,
  userID: string,
  communityID: string,
  status: CommunityUserStatus,
  invitedByUserID: string,
  invitedOn: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateZCommunityInvitationReminderNotifyBacklogInput = {
  communityUserID: string,
  userID?: string | null,
  communityID?: string | null,
  status?: CommunityUserStatus | null,
  invitedByUserID?: string | null,
  invitedOn?: string | null,
  createdAt?: string | null,
};

export type DeleteZCommunityInvitationReminderNotifyBacklogInput = {
  communityUserID: string,
};

export type CreateZCommunityInvitationReminderNotifyCompleteInput = {
  communityUserID: string,
  userID: string,
  communityID: string,
  status: CommunityUserStatus,
  invitedByUserID: string,
  invitedOn: string,
  createdAt?: string | null,
  completedAt: string,
};

export type ModelzCommunityInvitationReminderNotifyCompleteConditionInput = {
  userID?: ModelStringInput | null,
  communityID?: ModelStringInput | null,
  status?: ModelCommunityUserStatusInput | null,
  invitedByUserID?: ModelStringInput | null,
  invitedOn?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  and?: Array< ModelzCommunityInvitationReminderNotifyCompleteConditionInput | null > | null,
  or?: Array< ModelzCommunityInvitationReminderNotifyCompleteConditionInput | null > | null,
  not?: ModelzCommunityInvitationReminderNotifyCompleteConditionInput | null,
};

export type zCommunityInvitationReminderNotifyComplete = {
  __typename: "zCommunityInvitationReminderNotifyComplete",
  communityUserID: string,
  userID: string,
  communityID: string,
  status: CommunityUserStatus,
  invitedByUserID: string,
  invitedOn: string,
  createdAt: string,
  completedAt: string,
  updatedAt: string,
};

export type UpdateZCommunityInvitationReminderNotifyCompleteInput = {
  communityUserID: string,
  userID?: string | null,
  communityID?: string | null,
  status?: CommunityUserStatus | null,
  invitedByUserID?: string | null,
  invitedOn?: string | null,
  createdAt?: string | null,
  completedAt?: string | null,
};

export type DeleteZCommunityInvitationReminderNotifyCompleteInput = {
  communityUserID: string,
};

export type CreateZCommunityNextStepCalculateSigInput = {
  id?: string | null,
  source: string,
  createdAt?: string | null,
  signal: JobSignalInput,
};

export type ModelzCommunityNextStepCalculateSigConditionInput = {
  source?: ModelStringInput | null,
  and?: Array< ModelzCommunityNextStepCalculateSigConditionInput | null > | null,
  or?: Array< ModelzCommunityNextStepCalculateSigConditionInput | null > | null,
  not?: ModelzCommunityNextStepCalculateSigConditionInput | null,
};

export type zCommunityNextStepCalculateSig = {
  __typename: "zCommunityNextStepCalculateSig",
  id: string,
  source: string,
  createdAt: string,
  signal: JobSignal,
  updatedAt: string,
};

export type UpdateZCommunityNextStepCalculateSigInput = {
  id: string,
  source?: string | null,
  createdAt: string,
  signal?: JobSignalInput | null,
};

export type DeleteZCommunityNextStepCalculateSigInput = {
  id: string,
  createdAt: string,
};

export type CreateZCommunityNextStepCalculateBacklogInput = {
  id?: string | null,
  communityID: string,
  nextStepType: NextStepType,
  createdAt?: string | null,
};

export type ModelzCommunityNextStepCalculateBacklogConditionInput = {
  communityID?: ModelStringInput | null,
  nextStepType?: ModelNextStepTypeInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzCommunityNextStepCalculateBacklogConditionInput | null > | null,
  or?: Array< ModelzCommunityNextStepCalculateBacklogConditionInput | null > | null,
  not?: ModelzCommunityNextStepCalculateBacklogConditionInput | null,
};

export type ModelNextStepTypeInput = {
  eq?: NextStepType | null,
  ne?: NextStepType | null,
};

export type zCommunityNextStepCalculateBacklog = {
  __typename: "zCommunityNextStepCalculateBacklog",
  id: string,
  communityID: string,
  nextStepType: NextStepType,
  createdAt: string,
  updatedAt: string,
};

export type UpdateZCommunityNextStepCalculateBacklogInput = {
  id: string,
  communityID?: string | null,
  nextStepType?: NextStepType | null,
  createdAt?: string | null,
};

export type DeleteZCommunityNextStepCalculateBacklogInput = {
  id: string,
};

export type CreateZCommunityNextStepCalculateCompleteInput = {
  id?: string | null,
  communityID: string,
  nextStepType: NextStepType,
  createdAt?: string | null,
  completedAt: string,
};

export type ModelzCommunityNextStepCalculateCompleteConditionInput = {
  communityID?: ModelStringInput | null,
  nextStepType?: ModelNextStepTypeInput | null,
  createdAt?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  and?: Array< ModelzCommunityNextStepCalculateCompleteConditionInput | null > | null,
  or?: Array< ModelzCommunityNextStepCalculateCompleteConditionInput | null > | null,
  not?: ModelzCommunityNextStepCalculateCompleteConditionInput | null,
};

export type zCommunityNextStepCalculateComplete = {
  __typename: "zCommunityNextStepCalculateComplete",
  id: string,
  communityID: string,
  nextStepType: NextStepType,
  createdAt: string,
  completedAt: string,
  updatedAt: string,
};

export type UpdateZCommunityNextStepCalculateCompleteInput = {
  id: string,
  communityID?: string | null,
  nextStepType?: NextStepType | null,
  createdAt?: string | null,
  completedAt?: string | null,
};

export type DeleteZCommunityNextStepCalculateCompleteInput = {
  id: string,
};

export type CreateZFirstCommunityQuestionNotifySigInput = {
  id?: string | null,
  source: string,
  createdAt?: string | null,
  signal: JobSignalInput,
};

export type ModelzFirstCommunityQuestionNotifySigConditionInput = {
  source?: ModelStringInput | null,
  and?: Array< ModelzFirstCommunityQuestionNotifySigConditionInput | null > | null,
  or?: Array< ModelzFirstCommunityQuestionNotifySigConditionInput | null > | null,
  not?: ModelzFirstCommunityQuestionNotifySigConditionInput | null,
};

export type zFirstCommunityQuestionNotifySig = {
  __typename: "zFirstCommunityQuestionNotifySig",
  id: string,
  source: string,
  createdAt: string,
  signal: JobSignal,
  updatedAt: string,
};

export type UpdateZFirstCommunityQuestionNotifySigInput = {
  id: string,
  source?: string | null,
  createdAt: string,
  signal?: JobSignalInput | null,
};

export type DeleteZFirstCommunityQuestionNotifySigInput = {
  id: string,
  createdAt: string,
};

export type CreateZFirstCommunityQuestionNotifyBacklogInput = {
  communityID: string,
  communityCreatedAt: string,
  communityQuestionID: string,
  userID: string,
  createdAt?: string | null,
};

export type ModelzFirstCommunityQuestionNotifyBacklogConditionInput = {
  communityCreatedAt?: ModelStringInput | null,
  communityQuestionID?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzFirstCommunityQuestionNotifyBacklogConditionInput | null > | null,
  or?: Array< ModelzFirstCommunityQuestionNotifyBacklogConditionInput | null > | null,
  not?: ModelzFirstCommunityQuestionNotifyBacklogConditionInput | null,
};

export type zFirstCommunityQuestionNotifyBacklog = {
  __typename: "zFirstCommunityQuestionNotifyBacklog",
  communityID: string,
  communityCreatedAt: string,
  communityQuestionID: string,
  userID: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateZFirstCommunityQuestionNotifyBacklogInput = {
  communityID: string,
  communityCreatedAt?: string | null,
  communityQuestionID?: string | null,
  userID?: string | null,
  createdAt?: string | null,
};

export type DeleteZFirstCommunityQuestionNotifyBacklogInput = {
  communityID: string,
};

export type CreateZFirstCommunityQuestionNotifyCompleteInput = {
  communityID: string,
  communityCreatedAt: string,
  communityQuestionID: string,
  userID: string,
  createdAt?: string | null,
  completedAt: string,
};

export type ModelzFirstCommunityQuestionNotifyCompleteConditionInput = {
  communityCreatedAt?: ModelStringInput | null,
  communityQuestionID?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  and?: Array< ModelzFirstCommunityQuestionNotifyCompleteConditionInput | null > | null,
  or?: Array< ModelzFirstCommunityQuestionNotifyCompleteConditionInput | null > | null,
  not?: ModelzFirstCommunityQuestionNotifyCompleteConditionInput | null,
};

export type zFirstCommunityQuestionNotifyComplete = {
  __typename: "zFirstCommunityQuestionNotifyComplete",
  communityID: string,
  communityCreatedAt: string,
  communityQuestionID: string,
  userID: string,
  createdAt: string,
  completedAt: string,
  updatedAt: string,
};

export type UpdateZFirstCommunityQuestionNotifyCompleteInput = {
  communityID: string,
  communityCreatedAt?: string | null,
  communityQuestionID?: string | null,
  userID?: string | null,
  createdAt?: string | null,
  completedAt?: string | null,
};

export type DeleteZFirstCommunityQuestionNotifyCompleteInput = {
  communityID: string,
};

export type CreateZQuestionAskedNotifySigInput = {
  id?: string | null,
  createdAt?: string | null,
};

export type ModelzQuestionAskedNotifySigConditionInput = {
  and?: Array< ModelzQuestionAskedNotifySigConditionInput | null > | null,
  or?: Array< ModelzQuestionAskedNotifySigConditionInput | null > | null,
  not?: ModelzQuestionAskedNotifySigConditionInput | null,
};

export type zQuestionAskedNotifySig = {
  __typename: "zQuestionAskedNotifySig",
  id: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateZQuestionAskedNotifySigInput = {
  id: string,
  createdAt: string,
};

export type DeleteZQuestionAskedNotifySigInput = {
  id: string,
  createdAt: string,
};

export type CreateZQuestionAskedNotifyBacklogInput = {
  communityID: string,
  communityQuestionID: string,
  communityQuestionCreatedAt: string,
  userID: string,
  createdAt?: string | null,
};

export type ModelzQuestionAskedNotifyBacklogConditionInput = {
  communityQuestionID?: ModelStringInput | null,
  communityQuestionCreatedAt?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzQuestionAskedNotifyBacklogConditionInput | null > | null,
  or?: Array< ModelzQuestionAskedNotifyBacklogConditionInput | null > | null,
  not?: ModelzQuestionAskedNotifyBacklogConditionInput | null,
};

export type zQuestionAskedNotifyBacklog = {
  __typename: "zQuestionAskedNotifyBacklog",
  communityID: string,
  communityQuestionID: string,
  communityQuestionCreatedAt: string,
  userID: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateZQuestionAskedNotifyBacklogInput = {
  communityID: string,
  communityQuestionID?: string | null,
  communityQuestionCreatedAt?: string | null,
  userID?: string | null,
  createdAt?: string | null,
};

export type DeleteZQuestionAskedNotifyBacklogInput = {
  communityID: string,
};

export type CreateZQuestionAskedNotifyCompleteInput = {
  communityID: string,
  communityQuestionID: string,
  userID: string,
  createdAt?: string | null,
  completedAt: string,
};

export type ModelzQuestionAskedNotifyCompleteConditionInput = {
  communityQuestionID?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  and?: Array< ModelzQuestionAskedNotifyCompleteConditionInput | null > | null,
  or?: Array< ModelzQuestionAskedNotifyCompleteConditionInput | null > | null,
  not?: ModelzQuestionAskedNotifyCompleteConditionInput | null,
};

export type zQuestionAskedNotifyComplete = {
  __typename: "zQuestionAskedNotifyComplete",
  communityID: string,
  communityQuestionID: string,
  userID: string,
  createdAt: string,
  completedAt: string,
  updatedAt: string,
};

export type UpdateZQuestionAskedNotifyCompleteInput = {
  communityID: string,
  communityQuestionID?: string | null,
  userID?: string | null,
  createdAt?: string | null,
  completedAt?: string | null,
};

export type DeleteZQuestionAskedNotifyCompleteInput = {
  communityID: string,
};

export type CreateCategoryInput = {
  id?: string | null,
  name: string,
};

export type ModelCategoryConditionInput = {
  name?: ModelStringInput | null,
  and?: Array< ModelCategoryConditionInput | null > | null,
  or?: Array< ModelCategoryConditionInput | null > | null,
  not?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryInput = {
  id: string,
  name?: string | null,
};

export type DeleteCategoryInput = {
  id: string,
};

export type CreateCommunityCommandInput = {
  id?: string | null,
  command: CommandInput,
  communityID?: string | null,
  name?: string | null,
  imageKey?: string | null,
  type?: CommunityType | null,
  allowPublicPages?: boolean | null,
  limits?: CommunityLimitsInput | null,
  vimeoVideos?: Array< string > | null,
  communityCategories?: Array< string > | null,
};

export type CommandInput = {
  operation: CommandOperation,
};

export enum CommandOperation {
  CREATE = "CREATE",
  UPDATE_WHOLE = "UPDATE_WHOLE",
  UPDATE_PARTIAL = "UPDATE_PARTIAL",
  DELETE = "DELETE",
}


export type CommunityLimitsInput = {
  maxSubjects?: number | null,
  maxMembers?: number | null,
  maxInvitationLength?: number | null,
  maxVideoDurationSeconds?: number | null,
};

export type ModelCommunityCommandConditionInput = {
  communityID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  imageKey?: ModelStringInput | null,
  type?: ModelCommunityTypeInput | null,
  allowPublicPages?: ModelBooleanInput | null,
  vimeoVideos?: ModelStringInput | null,
  communityCategories?: ModelStringInput | null,
  and?: Array< ModelCommunityCommandConditionInput | null > | null,
  or?: Array< ModelCommunityCommandConditionInput | null > | null,
  not?: ModelCommunityCommandConditionInput | null,
};

export type ModelCommunityTypeInput = {
  eq?: CommunityType | null,
  ne?: CommunityType | null,
};

export type ModelBooleanInput = {
  ne?: boolean | null,
  eq?: boolean | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type CommunityCommand = {
  __typename: "CommunityCommand",
  id: string,
  command: Command,
  communityID?: string | null,
  name?: string | null,
  imageKey?: string | null,
  type?: CommunityType | null,
  allowPublicPages?: boolean | null,
  limits?: CommunityLimits | null,
  vimeoVideos?: Array< string > | null,
  communityCategories?: Array< string > | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type Command = {
  __typename: "Command",
  operation: CommandOperation,
};

export type UpdateCommunityCommandInput = {
  id: string,
  command?: CommandInput | null,
  communityID?: string | null,
  name?: string | null,
  imageKey?: string | null,
  type?: CommunityType | null,
  allowPublicPages?: boolean | null,
  limits?: CommunityLimitsInput | null,
  vimeoVideos?: Array< string > | null,
  communityCategories?: Array< string > | null,
};

export type DeleteCommunityCommandInput = {
  id: string,
};

export type CreateCommunityInput = {
  id?: string | null,
  name: string,
  imageKey?: string | null,
  type?: CommunityType | null,
  allowPublicPages?: boolean | null,
  alias?: string | null,
  publicPreviewHeaderURL?: string | null,
  publicPreviewDescription?: string | null,
  requiresModeration?: boolean | null,
  allowUnauthenticatedAnswers?: boolean | null,
  limits?: CommunityLimitsInput | null,
  vimeoVideos?: Array< string > | null,
  communityCategories?: Array< string > | null,
  interviewTab?: InterviewTabInput | null,
};

export type InterviewTabInput = {
  header: string,
  bodyLine1: string,
  bodyLine2: string,
};

export type ModelCommunityConditionInput = {
  name?: ModelStringInput | null,
  imageKey?: ModelStringInput | null,
  type?: ModelCommunityTypeInput | null,
  allowPublicPages?: ModelBooleanInput | null,
  alias?: ModelStringInput | null,
  publicPreviewHeaderURL?: ModelStringInput | null,
  publicPreviewDescription?: ModelStringInput | null,
  requiresModeration?: ModelBooleanInput | null,
  allowUnauthenticatedAnswers?: ModelBooleanInput | null,
  vimeoVideos?: ModelStringInput | null,
  communityCategories?: ModelStringInput | null,
  and?: Array< ModelCommunityConditionInput | null > | null,
  or?: Array< ModelCommunityConditionInput | null > | null,
  not?: ModelCommunityConditionInput | null,
};

export type UpdateCommunityInput = {
  id: string,
  name?: string | null,
  imageKey?: string | null,
  type?: CommunityType | null,
  allowPublicPages?: boolean | null,
  alias?: string | null,
  publicPreviewHeaderURL?: string | null,
  publicPreviewDescription?: string | null,
  requiresModeration?: boolean | null,
  allowUnauthenticatedAnswers?: boolean | null,
  limits?: CommunityLimitsInput | null,
  vimeoVideos?: Array< string > | null,
  communityCategories?: Array< string > | null,
  interviewTab?: InterviewTabInput | null,
};

export type DeleteCommunityInput = {
  id: string,
};

export type CreateCommunityInterviewRequestCommandInput = {
  id?: string | null,
  command: CommandInput,
  communityID: string,
  userID: string,
  date?: string | null,
  status: CommunityInterviewStatus,
  message: CommunityInterviewMessageInput,
};

export enum CommunityInterviewStatus {
  REQUESTED = "REQUESTED",
  CANCELLED = "CANCELLED",
}


export type CommunityInterviewMessageInput = {
  subject: string,
  body: string,
};

export type ModelCommunityInterviewRequestCommandConditionInput = {
  communityID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  date?: ModelStringInput | null,
  status?: ModelCommunityInterviewStatusInput | null,
  and?: Array< ModelCommunityInterviewRequestCommandConditionInput | null > | null,
  or?: Array< ModelCommunityInterviewRequestCommandConditionInput | null > | null,
  not?: ModelCommunityInterviewRequestCommandConditionInput | null,
};

export type ModelCommunityInterviewStatusInput = {
  eq?: CommunityInterviewStatus | null,
  ne?: CommunityInterviewStatus | null,
};

export type CommunityInterviewRequestCommand = {
  __typename: "CommunityInterviewRequestCommand",
  id: string,
  command: Command,
  communityID: string,
  userID: string,
  date?: string | null,
  status: CommunityInterviewStatus,
  message: CommunityInterviewMessage,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type CommunityInterviewMessage = {
  __typename: "CommunityInterviewMessage",
  subject: string,
  body: string,
};

export type UpdateCommunityInterviewRequestCommandInput = {
  id: string,
  command?: CommandInput | null,
  communityID?: string | null,
  userID?: string | null,
  date?: string | null,
  status?: CommunityInterviewStatus | null,
  message?: CommunityInterviewMessageInput | null,
};

export type DeleteCommunityInterviewRequestCommandInput = {
  id: string,
};

export type CreateCommunityInterviewRequestInput = {
  id?: string | null,
  communityID: string,
  userID: string,
  date?: string | null,
  status: CommunityInterviewStatus,
  message: CommunityInterviewMessageInput,
  owner: string,
};

export type ModelCommunityInterviewRequestConditionInput = {
  userID?: ModelIDInput | null,
  date?: ModelStringInput | null,
  status?: ModelCommunityInterviewStatusInput | null,
  and?: Array< ModelCommunityInterviewRequestConditionInput | null > | null,
  or?: Array< ModelCommunityInterviewRequestConditionInput | null > | null,
  not?: ModelCommunityInterviewRequestConditionInput | null,
};

export type CommunityInterviewRequest = {
  __typename: "CommunityInterviewRequest",
  id: string,
  communityID: string,
  userID: string,
  date?: string | null,
  status: CommunityInterviewStatus,
  message: CommunityInterviewMessage,
  owner: string,
  createdAt: string,
  updatedAt: string,
  community: Community,
  user: User,
};

export type UpdateCommunityInterviewRequestInput = {
  id: string,
  communityID?: string | null,
  userID?: string | null,
  date?: string | null,
  status?: CommunityInterviewStatus | null,
  message?: CommunityInterviewMessageInput | null,
  owner?: string | null,
};

export type DeleteCommunityInterviewRequestInput = {
  id: string,
};

export type CreateCommunityQuestionInput = {
  id?: string | null,
  communityID: string,
  userID: string,
  categoryID?: string | null,
  communityCategory?: string | null,
  date: string,
  status: CommunityQuestionStatus,
  source: CommunityQuestionSource,
  questionText: string,
  imageKeys?: Array< string > | null,
  clonedFromCommunityQuestionRecommendationId?: string | null,
};

export type ModelCommunityQuestionConditionInput = {
  userID?: ModelIDInput | null,
  categoryID?: ModelIDInput | null,
  communityCategory?: ModelStringInput | null,
  date?: ModelStringInput | null,
  status?: ModelCommunityQuestionStatusInput | null,
  source?: ModelCommunityQuestionSourceInput | null,
  questionText?: ModelStringInput | null,
  imageKeys?: ModelStringInput | null,
  clonedFromCommunityQuestionRecommendationId?: ModelIDInput | null,
  and?: Array< ModelCommunityQuestionConditionInput | null > | null,
  or?: Array< ModelCommunityQuestionConditionInput | null > | null,
  not?: ModelCommunityQuestionConditionInput | null,
};

export type UpdateCommunityQuestionInput = {
  id: string,
  communityID?: string | null,
  userID?: string | null,
  categoryID?: string | null,
  communityCategory?: string | null,
  date?: string | null,
  status?: CommunityQuestionStatus | null,
  source?: CommunityQuestionSource | null,
  questionText?: string | null,
  imageKeys?: Array< string > | null,
  clonedFromCommunityQuestionRecommendationId?: string | null,
};

export type DeleteCommunityQuestionInput = {
  id: string,
};

export type CreateCommunityQuestionAnswerCommandInput = {
  id?: string | null,
  command: CommandInput,
  communityQuestionAnswerID?: string | null,
  communityQuestionID?: string | null,
  communityID?: string | null,
  userID?: string | null,
  status?: CommunityQuestionAnswerStatus | null,
  date?: string | null,
  content?: string | null,
};

export type ModelCommunityQuestionAnswerCommandConditionInput = {
  communityQuestionAnswerID?: ModelIDInput | null,
  communityQuestionID?: ModelIDInput | null,
  communityID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  status?: ModelCommunityQuestionAnswerStatusInput | null,
  date?: ModelStringInput | null,
  content?: ModelStringInput | null,
  and?: Array< ModelCommunityQuestionAnswerCommandConditionInput | null > | null,
  or?: Array< ModelCommunityQuestionAnswerCommandConditionInput | null > | null,
  not?: ModelCommunityQuestionAnswerCommandConditionInput | null,
};

export type CommunityQuestionAnswerCommand = {
  __typename: "CommunityQuestionAnswerCommand",
  id: string,
  command: Command,
  communityQuestionAnswerID?: string | null,
  communityQuestionID?: string | null,
  communityID?: string | null,
  userID?: string | null,
  status?: CommunityQuestionAnswerStatus | null,
  date?: string | null,
  content?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateCommunityQuestionAnswerCommandInput = {
  id: string,
  command?: CommandInput | null,
  communityQuestionAnswerID?: string | null,
  communityQuestionID?: string | null,
  communityID?: string | null,
  userID?: string | null,
  status?: CommunityQuestionAnswerStatus | null,
  date?: string | null,
  content?: string | null,
};

export type DeleteCommunityQuestionAnswerCommandInput = {
  id: string,
};

export type CreateCommunityQuestionAnswerInput = {
  id?: string | null,
  communityQuestionID: string,
  userID: string,
  communityID: string,
  status: CommunityQuestionAnswerStatus,
  date: string,
  content: string,
};

export type ModelCommunityQuestionAnswerConditionInput = {
  communityQuestionID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  status?: ModelCommunityQuestionAnswerStatusInput | null,
  date?: ModelStringInput | null,
  content?: ModelStringInput | null,
  and?: Array< ModelCommunityQuestionAnswerConditionInput | null > | null,
  or?: Array< ModelCommunityQuestionAnswerConditionInput | null > | null,
  not?: ModelCommunityQuestionAnswerConditionInput | null,
};

export type UpdateCommunityQuestionAnswerInput = {
  id: string,
  communityQuestionID?: string | null,
  userID?: string | null,
  communityID?: string | null,
  status?: CommunityQuestionAnswerStatus | null,
  date?: string | null,
  content?: string | null,
};

export type DeleteCommunityQuestionAnswerInput = {
  id: string,
};

export type CreateCommunityQuestionAnswerCommentCommandInput = {
  id?: string | null,
  command: CommandInput,
  communityQuestionAnswerCommentID?: string | null,
  userID?: string | null,
  communityID?: string | null,
  communityQuestionAnswerID?: string | null,
  content?: string | null,
  status?: CommunityQuestionAnswerCommentStatus | null,
  date?: string | null,
};

export type ModelCommunityQuestionAnswerCommentCommandConditionInput = {
  communityQuestionAnswerCommentID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  communityID?: ModelIDInput | null,
  communityQuestionAnswerID?: ModelIDInput | null,
  content?: ModelStringInput | null,
  status?: ModelCommunityQuestionAnswerCommentStatusInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelCommunityQuestionAnswerCommentCommandConditionInput | null > | null,
  or?: Array< ModelCommunityQuestionAnswerCommentCommandConditionInput | null > | null,
  not?: ModelCommunityQuestionAnswerCommentCommandConditionInput | null,
};

export type ModelCommunityQuestionAnswerCommentStatusInput = {
  eq?: CommunityQuestionAnswerCommentStatus | null,
  ne?: CommunityQuestionAnswerCommentStatus | null,
};

export type CommunityQuestionAnswerCommentCommand = {
  __typename: "CommunityQuestionAnswerCommentCommand",
  id: string,
  command: Command,
  communityQuestionAnswerCommentID?: string | null,
  userID?: string | null,
  communityID?: string | null,
  communityQuestionAnswerID?: string | null,
  content?: string | null,
  status?: CommunityQuestionAnswerCommentStatus | null,
  date?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateCommunityQuestionAnswerCommentCommandInput = {
  id: string,
  command?: CommandInput | null,
  communityQuestionAnswerCommentID?: string | null,
  userID?: string | null,
  communityID?: string | null,
  communityQuestionAnswerID?: string | null,
  content?: string | null,
  status?: CommunityQuestionAnswerCommentStatus | null,
  date?: string | null,
};

export type DeleteCommunityQuestionAnswerCommentCommandInput = {
  id: string,
};

export type CreateCommunityQuestionAnswerCommentInput = {
  id?: string | null,
  userID: string,
  communityID: string,
  communityQuestionAnswerID: string,
  content: string,
  status: CommunityQuestionAnswerCommentStatus,
  date: string,
};

export type ModelCommunityQuestionAnswerCommentConditionInput = {
  userID?: ModelIDInput | null,
  communityQuestionAnswerID?: ModelIDInput | null,
  content?: ModelStringInput | null,
  status?: ModelCommunityQuestionAnswerCommentStatusInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelCommunityQuestionAnswerCommentConditionInput | null > | null,
  or?: Array< ModelCommunityQuestionAnswerCommentConditionInput | null > | null,
  not?: ModelCommunityQuestionAnswerCommentConditionInput | null,
};

export type UpdateCommunityQuestionAnswerCommentInput = {
  id: string,
  userID?: string | null,
  communityID?: string | null,
  communityQuestionAnswerID?: string | null,
  content?: string | null,
  status?: CommunityQuestionAnswerCommentStatus | null,
  date?: string | null,
};

export type DeleteCommunityQuestionAnswerCommentInput = {
  id: string,
};

export type CreateCommunityQuestionAnswerLikeInput = {
  id?: string | null,
  userID: string,
  communityID: string,
  communityQuestionAnswerID: string,
  status: CommunityQuestionAnswerLikeStatus,
  date: string,
};

export type ModelCommunityQuestionAnswerLikeConditionInput = {
  userID?: ModelIDInput | null,
  communityQuestionAnswerID?: ModelIDInput | null,
  status?: ModelCommunityQuestionAnswerLikeStatusInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelCommunityQuestionAnswerLikeConditionInput | null > | null,
  or?: Array< ModelCommunityQuestionAnswerLikeConditionInput | null > | null,
  not?: ModelCommunityQuestionAnswerLikeConditionInput | null,
};

export type ModelCommunityQuestionAnswerLikeStatusInput = {
  eq?: CommunityQuestionAnswerLikeStatus | null,
  ne?: CommunityQuestionAnswerLikeStatus | null,
};

export type UpdateCommunityQuestionAnswerLikeInput = {
  id: string,
  userID?: string | null,
  communityID?: string | null,
  communityQuestionAnswerID?: string | null,
  status?: CommunityQuestionAnswerLikeStatus | null,
  date?: string | null,
};

export type DeleteCommunityQuestionAnswerLikeInput = {
  id: string,
};

export type CreateCommunityQuestionLikeInput = {
  id?: string | null,
  userID: string,
  communityQuestionID: string,
  status: CommunityQuestionLikeStatus,
  date: string,
};

export type ModelCommunityQuestionLikeConditionInput = {
  userID?: ModelIDInput | null,
  communityQuestionID?: ModelIDInput | null,
  status?: ModelCommunityQuestionLikeStatusInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelCommunityQuestionLikeConditionInput | null > | null,
  or?: Array< ModelCommunityQuestionLikeConditionInput | null > | null,
  not?: ModelCommunityQuestionLikeConditionInput | null,
};

export type ModelCommunityQuestionLikeStatusInput = {
  eq?: CommunityQuestionLikeStatus | null,
  ne?: CommunityQuestionLikeStatus | null,
};

export type UpdateCommunityQuestionLikeInput = {
  id: string,
  userID?: string | null,
  communityQuestionID?: string | null,
  status?: CommunityQuestionLikeStatus | null,
  date?: string | null,
};

export type DeleteCommunityQuestionLikeInput = {
  id: string,
};

export type CreateCommunityQuestionRecommendationInput = {
  id?: string | null,
  communityID: string,
  categoryID: string,
  questionText: string,
  score: number,
  status?: CommunityQuestionRecommendationStatus | null,
};

export type ModelCommunityQuestionRecommendationConditionInput = {
  categoryID?: ModelIDInput | null,
  questionText?: ModelStringInput | null,
  score?: ModelIntInput | null,
  status?: ModelCommunityQuestionRecommendationStatusInput | null,
  and?: Array< ModelCommunityQuestionRecommendationConditionInput | null > | null,
  or?: Array< ModelCommunityQuestionRecommendationConditionInput | null > | null,
  not?: ModelCommunityQuestionRecommendationConditionInput | null,
};

export type ModelIntInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
};

export type ModelCommunityQuestionRecommendationStatusInput = {
  eq?: CommunityQuestionRecommendationStatus | null,
  ne?: CommunityQuestionRecommendationStatus | null,
};

export type UpdateCommunityQuestionRecommendationInput = {
  id: string,
  communityID?: string | null,
  categoryID?: string | null,
  questionText?: string | null,
  score?: number | null,
  status?: CommunityQuestionRecommendationStatus | null,
};

export type DeleteCommunityQuestionRecommendationInput = {
  id: string,
};

export type CreateCommunityUserCommandInput = {
  id?: string | null,
  command: CommandInput,
  communityUserId?: string | null,
  communityID?: string | null,
  userID?: string | null,
  joinedOn?: string | null,
  invitedByUserID?: string | null,
  invitedOn?: string | null,
  invitation?: CommunityUserInvitationInput | null,
  status?: CommunityUserStatus | null,
  relationshipToSpotlight?: string | null,
};

export type CommunityUserInvitationInput = {
  invitationMessage: string,
};

export type ModelCommunityUserCommandConditionInput = {
  communityUserId?: ModelIDInput | null,
  communityID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  joinedOn?: ModelStringInput | null,
  invitedByUserID?: ModelIDInput | null,
  invitedOn?: ModelStringInput | null,
  status?: ModelCommunityUserStatusInput | null,
  relationshipToSpotlight?: ModelStringInput | null,
  and?: Array< ModelCommunityUserCommandConditionInput | null > | null,
  or?: Array< ModelCommunityUserCommandConditionInput | null > | null,
  not?: ModelCommunityUserCommandConditionInput | null,
};

export type CommunityUserCommand = {
  __typename: "CommunityUserCommand",
  id: string,
  command: Command,
  communityUserId?: string | null,
  communityID?: string | null,
  userID?: string | null,
  joinedOn?: string | null,
  invitedByUserID?: string | null,
  invitedOn?: string | null,
  invitation?: CommunityUserInvitation | null,
  status?: CommunityUserStatus | null,
  relationshipToSpotlight?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateCommunityUserCommandInput = {
  id: string,
  command?: CommandInput | null,
  communityUserId?: string | null,
  communityID?: string | null,
  userID?: string | null,
  joinedOn?: string | null,
  invitedByUserID?: string | null,
  invitedOn?: string | null,
  invitation?: CommunityUserInvitationInput | null,
  status?: CommunityUserStatus | null,
  relationshipToSpotlight?: string | null,
};

export type DeleteCommunityUserCommandInput = {
  id: string,
};

export type CreateCommunityUserInput = {
  id?: string | null,
  communityID: string,
  userID: string,
  joinedOn: string,
  invitedByUserID: string,
  invitedOn: string,
  invitation?: CommunityUserInvitationInput | null,
  status: CommunityUserStatus,
  relationshipToSpotlight?: string | null,
  owner?: string | null,
  group?: string | null,
};

export type ModelCommunityUserConditionInput = {
  userID?: ModelIDInput | null,
  joinedOn?: ModelStringInput | null,
  invitedByUserID?: ModelIDInput | null,
  invitedOn?: ModelStringInput | null,
  status?: ModelCommunityUserStatusInput | null,
  relationshipToSpotlight?: ModelStringInput | null,
  group?: ModelStringInput | null,
  and?: Array< ModelCommunityUserConditionInput | null > | null,
  or?: Array< ModelCommunityUserConditionInput | null > | null,
  not?: ModelCommunityUserConditionInput | null,
};

export type UpdateCommunityUserInput = {
  id: string,
  communityID?: string | null,
  userID?: string | null,
  joinedOn?: string | null,
  invitedByUserID?: string | null,
  invitedOn?: string | null,
  invitation?: CommunityUserInvitationInput | null,
  status?: CommunityUserStatus | null,
  relationshipToSpotlight?: string | null,
  owner?: string | null,
  group?: string | null,
};

export type DeleteCommunityUserInput = {
  id: string,
};

export type CreateInvitationInput = {
  id?: string | null,
  email: string,
  toNotify: boolean,
  status: InvitationStatus,
};

export enum InvitationStatus {
  INITIATED = "INITIATED",
  DELIVERED = "DELIVERED",
  BOUNCED = "BOUNCED",
  MARKED_AS_SPAM = "MARKED_AS_SPAM",
  UNSUBSCRIBED = "UNSUBSCRIBED",
  ACCEPTED = "ACCEPTED",
}


export type ModelInvitationConditionInput = {
  email?: ModelStringInput | null,
  toNotify?: ModelBooleanInput | null,
  status?: ModelInvitationStatusInput | null,
  and?: Array< ModelInvitationConditionInput | null > | null,
  or?: Array< ModelInvitationConditionInput | null > | null,
  not?: ModelInvitationConditionInput | null,
};

export type ModelInvitationStatusInput = {
  eq?: InvitationStatus | null,
  ne?: InvitationStatus | null,
};

export type Invitation = {
  __typename: "Invitation",
  id: string,
  email: string,
  toNotify: boolean,
  status: InvitationStatus,
  createdAt: string,
  updatedAt: string,
};

export type UpdateInvitationInput = {
  id: string,
  email?: string | null,
  toNotify?: boolean | null,
  status?: InvitationStatus | null,
};

export type DeleteInvitationInput = {
  id: string,
};

export type CreateUsernameToUserIdInput = {
  username: string,
  userID: string,
};

export type ModelUsernameToUserIdConditionInput = {
  userID?: ModelIDInput | null,
  and?: Array< ModelUsernameToUserIdConditionInput | null > | null,
  or?: Array< ModelUsernameToUserIdConditionInput | null > | null,
  not?: ModelUsernameToUserIdConditionInput | null,
};

export type UsernameToUserId = {
  __typename: "UsernameToUserId",
  username: string,
  userID: string,
};

export type UpdateUsernameToUserIdInput = {
  username: string,
  userID?: string | null,
};

export type DeleteUsernameToUserIdInput = {
  username: string,
};

export type CreateUserIdToIdentityIdInput = {
  userID: string,
  identityID: string,
};

export type ModelUserIdToIdentityIdConditionInput = {
  identityID?: ModelStringInput | null,
  and?: Array< ModelUserIdToIdentityIdConditionInput | null > | null,
  or?: Array< ModelUserIdToIdentityIdConditionInput | null > | null,
  not?: ModelUserIdToIdentityIdConditionInput | null,
};

export type UserIdToIdentityId = {
  __typename: "UserIdToIdentityId",
  userID: string,
  identityID: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserIdToIdentityIdInput = {
  userID: string,
  identityID?: string | null,
};

export type DeleteUserIdToIdentityIdInput = {
  userID: string,
};

export type CreateUserCommandInput = {
  id?: string | null,
  command: CommandInput,
  userID?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  status?: UserStatus | null,
  profileImageKey?: string | null,
  communityLimits?: CommunityLimitsInput | null,
  welcomesSeen?: Array< string | null > | null,
  helpsSeen?: Array< string | null > | null,
  username?: string | null,
  identityID?: string | null,
  referralCode?: string | null,
};

export type ModelUserCommandConditionInput = {
  userID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  status?: ModelUserStatusInput | null,
  profileImageKey?: ModelStringInput | null,
  welcomesSeen?: ModelStringInput | null,
  helpsSeen?: ModelStringInput | null,
  username?: ModelStringInput | null,
  identityID?: ModelStringInput | null,
  referralCode?: ModelStringInput | null,
  and?: Array< ModelUserCommandConditionInput | null > | null,
  or?: Array< ModelUserCommandConditionInput | null > | null,
  not?: ModelUserCommandConditionInput | null,
};

export type ModelUserStatusInput = {
  eq?: UserStatus | null,
  ne?: UserStatus | null,
};

export type UserCommand = {
  __typename: "UserCommand",
  id: string,
  command: Command,
  userID?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  status?: UserStatus | null,
  profileImageKey?: string | null,
  communityLimits?: CommunityLimits | null,
  welcomesSeen?: Array< string | null > | null,
  helpsSeen?: Array< string | null > | null,
  username?: string | null,
  identityID?: string | null,
  referralCode?: string | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateUserCommandInput = {
  id: string,
  command?: CommandInput | null,
  userID?: string | null,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  status?: UserStatus | null,
  profileImageKey?: string | null,
  communityLimits?: CommunityLimitsInput | null,
  welcomesSeen?: Array< string | null > | null,
  helpsSeen?: Array< string | null > | null,
  username?: string | null,
  identityID?: string | null,
  referralCode?: string | null,
};

export type DeleteUserCommandInput = {
  id: string,
};

export type CreateUserInput = {
  id?: string | null,
  firstName: string,
  lastName: string,
  email?: string | null,
  status?: UserStatus | null,
  profileImageKey?: string | null,
  communityLimits?: CommunityLimitsInput | null,
  welcomesSeen?: Array< string > | null,
  helpsSeen?: Array< string > | null,
  nextSteps?: Array< CommunityNextStepInput > | null,
  referralCode?: string | null,
  referredByUserID?: string | null,
  username?: string | null,
  identityID?: string | null,
};

export type CommunityNextStepInput = {
  communityID: string,
  nextStepsByRole?: NextStepsByRoleInput | null,
};

export type NextStepsByRoleInput = {
  INVITED?: Array< NextStepType > | null,
  MEMBER?: Array< NextStepType > | null,
  ORGANIZER?: Array< NextStepType > | null,
  SUBJECT?: Array< NextStepType > | null,
  REMOVED?: Array< NextStepType > | null,
  BANNED?: Array< NextStepType > | null,
  ADMIN?: Array< NextStepType > | null,
};

export type ModelUserConditionInput = {
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  status?: ModelUserStatusInput | null,
  profileImageKey?: ModelStringInput | null,
  welcomesSeen?: ModelStringInput | null,
  helpsSeen?: ModelStringInput | null,
  referralCode?: ModelStringInput | null,
  referredByUserID?: ModelStringInput | null,
  username?: ModelStringInput | null,
  identityID?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type UpdateUserInput = {
  id: string,
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  status?: UserStatus | null,
  profileImageKey?: string | null,
  communityLimits?: CommunityLimitsInput | null,
  welcomesSeen?: Array< string > | null,
  helpsSeen?: Array< string > | null,
  nextSteps?: Array< CommunityNextStepInput > | null,
  referralCode?: string | null,
  referredByUserID?: string | null,
  username?: string | null,
  identityID?: string | null,
};

export type DeleteUserInput = {
  id: string,
};

export type CreateUserGoogleTokensInput = {
  owner: string,
  accessToken: string,
  refreshToken: string,
  scopes?: Array< string > | null,
  expiresOn: string,
};

export type ModelUserGoogleTokensConditionInput = {
  accessToken?: ModelStringInput | null,
  refreshToken?: ModelStringInput | null,
  scopes?: ModelStringInput | null,
  expiresOn?: ModelStringInput | null,
  and?: Array< ModelUserGoogleTokensConditionInput | null > | null,
  or?: Array< ModelUserGoogleTokensConditionInput | null > | null,
  not?: ModelUserGoogleTokensConditionInput | null,
};

export type UserGoogleTokens = {
  __typename: "UserGoogleTokens",
  owner: string,
  accessToken: string,
  refreshToken: string,
  scopes?: Array< string > | null,
  expiresOn: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserGoogleTokensInput = {
  owner: string,
  accessToken?: string | null,
  refreshToken?: string | null,
  scopes?: Array< string > | null,
  expiresOn?: string | null,
};

export type DeleteUserGoogleTokensInput = {
  owner: string,
};

export type CreateUserReferralCodeInput = {
  referralCode: string,
  firstName: string,
};

export type ModelUserReferralCodeConditionInput = {
  firstName?: ModelStringInput | null,
  and?: Array< ModelUserReferralCodeConditionInput | null > | null,
  or?: Array< ModelUserReferralCodeConditionInput | null > | null,
  not?: ModelUserReferralCodeConditionInput | null,
};

export type UserReferralCode = {
  __typename: "UserReferralCode",
  referralCode: string,
  firstName: string,
  createdAt: string,
  updatedAt: string,
};

export type UpdateUserReferralCodeInput = {
  referralCode: string,
  firstName?: string | null,
};

export type DeleteUserReferralCodeInput = {
  referralCode: string,
};

export type CreateXAssignEmailToPlaceholderInput = {
  id?: string | null,
  status: OperationStatus,
  reason?: string | null,
  communityUserID: string,
  userID: string,
  communityID: string,
  email: string,
  invitation?: CommunityUserInvitationInput | null,
};

export enum OperationStatus {
  REQUESTED = "REQUESTED",
  SUCCEEDED = "SUCCEEDED",
  NOOP = "NOOP",
  FAILED = "FAILED",
}


export type ModelxAssignEmailToPlaceholderConditionInput = {
  status?: ModelOperationStatusInput | null,
  reason?: ModelStringInput | null,
  communityUserID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  communityID?: ModelIDInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelxAssignEmailToPlaceholderConditionInput | null > | null,
  or?: Array< ModelxAssignEmailToPlaceholderConditionInput | null > | null,
  not?: ModelxAssignEmailToPlaceholderConditionInput | null,
};

export type ModelOperationStatusInput = {
  eq?: OperationStatus | null,
  ne?: OperationStatus | null,
};

export type xAssignEmailToPlaceholder = {
  __typename: "xAssignEmailToPlaceholder",
  id: string,
  status: OperationStatus,
  reason?: string | null,
  communityUserID: string,
  userID: string,
  communityID: string,
  email: string,
  invitation?: CommunityUserInvitation | null,
  createdAt: string,
  updatedAt: string,
  owner?: string | null,
};

export type UpdateXAssignEmailToPlaceholderInput = {
  id: string,
  status?: OperationStatus | null,
  reason?: string | null,
  communityUserID?: string | null,
  userID?: string | null,
  communityID?: string | null,
  email?: string | null,
  invitation?: CommunityUserInvitationInput | null,
};

export type DeleteXAssignEmailToPlaceholderInput = {
  id: string,
};

export type ModelPacemakerLastRunFilterInput = {
  and?: Array< ModelPacemakerLastRunFilterInput | null > | null,
  or?: Array< ModelPacemakerLastRunFilterInput | null > | null,
  not?: ModelPacemakerLastRunFilterInput | null,
};

export type ModelPacemakerLastRunConnection = {
  __typename: "ModelPacemakerLastRunConnection",
  items:  Array<PacemakerLastRun | null >,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export type ModelzAnswerCreatedNotifySigFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzAnswerCreatedNotifySigFilterInput | null > | null,
  or?: Array< ModelzAnswerCreatedNotifySigFilterInput | null > | null,
  not?: ModelzAnswerCreatedNotifySigFilterInput | null,
};

export type ModelzAnswerCreatedNotifySigConnection = {
  __typename: "ModelzAnswerCreatedNotifySigConnection",
  items:  Array<zAnswerCreatedNotifySig | null >,
  nextToken?: string | null,
};

export type ModelzAnswerCreatedNotifyBacklogFilterInput = {
  communityID?: ModelStringInput | null,
  communityQuestionAnswerID?: ModelStringInput | null,
  communityQuestionAnswerCreatedAt?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzAnswerCreatedNotifyBacklogFilterInput | null > | null,
  or?: Array< ModelzAnswerCreatedNotifyBacklogFilterInput | null > | null,
  not?: ModelzAnswerCreatedNotifyBacklogFilterInput | null,
};

export type ModelzAnswerCreatedNotifyBacklogConnection = {
  __typename: "ModelzAnswerCreatedNotifyBacklogConnection",
  items:  Array<zAnswerCreatedNotifyBacklog | null >,
  nextToken?: string | null,
};

export type ModelzAnswerCreatedNotifyCompleteFilterInput = {
  communityID?: ModelStringInput | null,
  communityQuestionAnswerID?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  and?: Array< ModelzAnswerCreatedNotifyCompleteFilterInput | null > | null,
  or?: Array< ModelzAnswerCreatedNotifyCompleteFilterInput | null > | null,
  not?: ModelzAnswerCreatedNotifyCompleteFilterInput | null,
};

export type ModelzAnswerCreatedNotifyCompleteConnection = {
  __typename: "ModelzAnswerCreatedNotifyCompleteConnection",
  items:  Array<zAnswerCreatedNotifyComplete | null >,
  nextToken?: string | null,
};

export type ModelzCommentMadeNotifySigFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzCommentMadeNotifySigFilterInput | null > | null,
  or?: Array< ModelzCommentMadeNotifySigFilterInput | null > | null,
  not?: ModelzCommentMadeNotifySigFilterInput | null,
};

export type ModelzCommentMadeNotifySigConnection = {
  __typename: "ModelzCommentMadeNotifySigConnection",
  items:  Array<zCommentMadeNotifySig | null >,
  nextToken?: string | null,
};

export type ModelzCommentMadeNotifyBacklogFilterInput = {
  communityID?: ModelStringInput | null,
  communityQuestionAnswerID?: ModelStringInput | null,
  communityQuestionAnswerCommentCreatedAt?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzCommentMadeNotifyBacklogFilterInput | null > | null,
  or?: Array< ModelzCommentMadeNotifyBacklogFilterInput | null > | null,
  not?: ModelzCommentMadeNotifyBacklogFilterInput | null,
};

export type ModelzCommentMadeNotifyBacklogConnection = {
  __typename: "ModelzCommentMadeNotifyBacklogConnection",
  items:  Array<zCommentMadeNotifyBacklog | null >,
  nextToken?: string | null,
};

export type ModelzCommentMadeNotifyCompleteFilterInput = {
  communityID?: ModelStringInput | null,
  communityQuestionAnswerID?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  and?: Array< ModelzCommentMadeNotifyCompleteFilterInput | null > | null,
  or?: Array< ModelzCommentMadeNotifyCompleteFilterInput | null > | null,
  not?: ModelzCommentMadeNotifyCompleteFilterInput | null,
};

export type ModelzCommentMadeNotifyCompleteConnection = {
  __typename: "ModelzCommentMadeNotifyCompleteConnection",
  items:  Array<zCommentMadeNotifyComplete | null >,
  nextToken?: string | null,
};

export type ModelzCommunityInvitationReminderNotifySigFilterInput = {
  id?: ModelIDInput | null,
  source?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzCommunityInvitationReminderNotifySigFilterInput | null > | null,
  or?: Array< ModelzCommunityInvitationReminderNotifySigFilterInput | null > | null,
  not?: ModelzCommunityInvitationReminderNotifySigFilterInput | null,
};

export type ModelzCommunityInvitationReminderNotifySigConnection = {
  __typename: "ModelzCommunityInvitationReminderNotifySigConnection",
  items:  Array<zCommunityInvitationReminderNotifySig | null >,
  nextToken?: string | null,
};

export type ModelzCommunityInvitationReminderNotifyBacklogFilterInput = {
  communityUserID?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  communityID?: ModelStringInput | null,
  status?: ModelCommunityUserStatusInput | null,
  invitedByUserID?: ModelStringInput | null,
  invitedOn?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzCommunityInvitationReminderNotifyBacklogFilterInput | null > | null,
  or?: Array< ModelzCommunityInvitationReminderNotifyBacklogFilterInput | null > | null,
  not?: ModelzCommunityInvitationReminderNotifyBacklogFilterInput | null,
};

export type ModelzCommunityInvitationReminderNotifyBacklogConnection = {
  __typename: "ModelzCommunityInvitationReminderNotifyBacklogConnection",
  items:  Array<zCommunityInvitationReminderNotifyBacklog | null >,
  nextToken?: string | null,
};

export type ModelzCommunityInvitationReminderNotifyCompleteFilterInput = {
  communityUserID?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  communityID?: ModelStringInput | null,
  status?: ModelCommunityUserStatusInput | null,
  invitedByUserID?: ModelStringInput | null,
  invitedOn?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  and?: Array< ModelzCommunityInvitationReminderNotifyCompleteFilterInput | null > | null,
  or?: Array< ModelzCommunityInvitationReminderNotifyCompleteFilterInput | null > | null,
  not?: ModelzCommunityInvitationReminderNotifyCompleteFilterInput | null,
};

export type ModelzCommunityInvitationReminderNotifyCompleteConnection = {
  __typename: "ModelzCommunityInvitationReminderNotifyCompleteConnection",
  items:  Array<zCommunityInvitationReminderNotifyComplete | null >,
  nextToken?: string | null,
};

export type ModelzCommunityNextStepCalculateSigFilterInput = {
  id?: ModelIDInput | null,
  source?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzCommunityNextStepCalculateSigFilterInput | null > | null,
  or?: Array< ModelzCommunityNextStepCalculateSigFilterInput | null > | null,
  not?: ModelzCommunityNextStepCalculateSigFilterInput | null,
};

export type ModelzCommunityNextStepCalculateSigConnection = {
  __typename: "ModelzCommunityNextStepCalculateSigConnection",
  items:  Array<zCommunityNextStepCalculateSig | null >,
  nextToken?: string | null,
};

export type ModelzCommunityNextStepCalculateBacklogFilterInput = {
  id?: ModelIDInput | null,
  communityID?: ModelStringInput | null,
  nextStepType?: ModelNextStepTypeInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzCommunityNextStepCalculateBacklogFilterInput | null > | null,
  or?: Array< ModelzCommunityNextStepCalculateBacklogFilterInput | null > | null,
  not?: ModelzCommunityNextStepCalculateBacklogFilterInput | null,
};

export type ModelzCommunityNextStepCalculateBacklogConnection = {
  __typename: "ModelzCommunityNextStepCalculateBacklogConnection",
  items:  Array<zCommunityNextStepCalculateBacklog | null >,
  nextToken?: string | null,
};

export type ModelzCommunityNextStepCalculateCompleteFilterInput = {
  id?: ModelIDInput | null,
  communityID?: ModelStringInput | null,
  nextStepType?: ModelNextStepTypeInput | null,
  createdAt?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  and?: Array< ModelzCommunityNextStepCalculateCompleteFilterInput | null > | null,
  or?: Array< ModelzCommunityNextStepCalculateCompleteFilterInput | null > | null,
  not?: ModelzCommunityNextStepCalculateCompleteFilterInput | null,
};

export type ModelzCommunityNextStepCalculateCompleteConnection = {
  __typename: "ModelzCommunityNextStepCalculateCompleteConnection",
  items:  Array<zCommunityNextStepCalculateComplete | null >,
  nextToken?: string | null,
};

export type ModelzFirstCommunityQuestionNotifySigFilterInput = {
  id?: ModelIDInput | null,
  source?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzFirstCommunityQuestionNotifySigFilterInput | null > | null,
  or?: Array< ModelzFirstCommunityQuestionNotifySigFilterInput | null > | null,
  not?: ModelzFirstCommunityQuestionNotifySigFilterInput | null,
};

export type ModelzFirstCommunityQuestionNotifySigConnection = {
  __typename: "ModelzFirstCommunityQuestionNotifySigConnection",
  items:  Array<zFirstCommunityQuestionNotifySig | null >,
  nextToken?: string | null,
};

export type ModelzFirstCommunityQuestionNotifyBacklogFilterInput = {
  communityID?: ModelStringInput | null,
  communityCreatedAt?: ModelStringInput | null,
  communityQuestionID?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzFirstCommunityQuestionNotifyBacklogFilterInput | null > | null,
  or?: Array< ModelzFirstCommunityQuestionNotifyBacklogFilterInput | null > | null,
  not?: ModelzFirstCommunityQuestionNotifyBacklogFilterInput | null,
};

export type ModelzFirstCommunityQuestionNotifyBacklogConnection = {
  __typename: "ModelzFirstCommunityQuestionNotifyBacklogConnection",
  items:  Array<zFirstCommunityQuestionNotifyBacklog | null >,
  nextToken?: string | null,
};

export type ModelzFirstCommunityQuestionNotifyCompleteFilterInput = {
  communityID?: ModelStringInput | null,
  communityCreatedAt?: ModelStringInput | null,
  communityQuestionID?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  and?: Array< ModelzFirstCommunityQuestionNotifyCompleteFilterInput | null > | null,
  or?: Array< ModelzFirstCommunityQuestionNotifyCompleteFilterInput | null > | null,
  not?: ModelzFirstCommunityQuestionNotifyCompleteFilterInput | null,
};

export type ModelzFirstCommunityQuestionNotifyCompleteConnection = {
  __typename: "ModelzFirstCommunityQuestionNotifyCompleteConnection",
  items:  Array<zFirstCommunityQuestionNotifyComplete | null >,
  nextToken?: string | null,
};

export type ModelzQuestionAskedNotifySigFilterInput = {
  id?: ModelIDInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzQuestionAskedNotifySigFilterInput | null > | null,
  or?: Array< ModelzQuestionAskedNotifySigFilterInput | null > | null,
  not?: ModelzQuestionAskedNotifySigFilterInput | null,
};

export type ModelzQuestionAskedNotifySigConnection = {
  __typename: "ModelzQuestionAskedNotifySigConnection",
  items:  Array<zQuestionAskedNotifySig | null >,
  nextToken?: string | null,
};

export type ModelzQuestionAskedNotifyBacklogFilterInput = {
  communityID?: ModelStringInput | null,
  communityQuestionID?: ModelStringInput | null,
  communityQuestionCreatedAt?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  and?: Array< ModelzQuestionAskedNotifyBacklogFilterInput | null > | null,
  or?: Array< ModelzQuestionAskedNotifyBacklogFilterInput | null > | null,
  not?: ModelzQuestionAskedNotifyBacklogFilterInput | null,
};

export type ModelzQuestionAskedNotifyBacklogConnection = {
  __typename: "ModelzQuestionAskedNotifyBacklogConnection",
  items:  Array<zQuestionAskedNotifyBacklog | null >,
  nextToken?: string | null,
};

export type ModelzQuestionAskedNotifyCompleteFilterInput = {
  communityID?: ModelStringInput | null,
  communityQuestionID?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  completedAt?: ModelStringInput | null,
  and?: Array< ModelzQuestionAskedNotifyCompleteFilterInput | null > | null,
  or?: Array< ModelzQuestionAskedNotifyCompleteFilterInput | null > | null,
  not?: ModelzQuestionAskedNotifyCompleteFilterInput | null,
};

export type ModelzQuestionAskedNotifyCompleteConnection = {
  __typename: "ModelzQuestionAskedNotifyCompleteConnection",
  items:  Array<zQuestionAskedNotifyComplete | null >,
  nextToken?: string | null,
};

export type ModelCategoryFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  and?: Array< ModelCategoryFilterInput | null > | null,
  or?: Array< ModelCategoryFilterInput | null > | null,
  not?: ModelCategoryFilterInput | null,
};

export type ModelCategoryConnection = {
  __typename: "ModelCategoryConnection",
  items:  Array<Category | null >,
  nextToken?: string | null,
};

export type ModelCommunityCommandFilterInput = {
  id?: ModelIDInput | null,
  communityID?: ModelIDInput | null,
  name?: ModelStringInput | null,
  imageKey?: ModelStringInput | null,
  type?: ModelCommunityTypeInput | null,
  allowPublicPages?: ModelBooleanInput | null,
  vimeoVideos?: ModelStringInput | null,
  communityCategories?: ModelStringInput | null,
  and?: Array< ModelCommunityCommandFilterInput | null > | null,
  or?: Array< ModelCommunityCommandFilterInput | null > | null,
  not?: ModelCommunityCommandFilterInput | null,
};

export type ModelCommunityCommandConnection = {
  __typename: "ModelCommunityCommandConnection",
  items:  Array<CommunityCommand | null >,
  nextToken?: string | null,
};

export type ModelCommunityFilterInput = {
  id?: ModelIDInput | null,
  name?: ModelStringInput | null,
  imageKey?: ModelStringInput | null,
  type?: ModelCommunityTypeInput | null,
  allowPublicPages?: ModelBooleanInput | null,
  alias?: ModelStringInput | null,
  publicPreviewHeaderURL?: ModelStringInput | null,
  publicPreviewDescription?: ModelStringInput | null,
  requiresModeration?: ModelBooleanInput | null,
  allowUnauthenticatedAnswers?: ModelBooleanInput | null,
  vimeoVideos?: ModelStringInput | null,
  communityCategories?: ModelStringInput | null,
  and?: Array< ModelCommunityFilterInput | null > | null,
  or?: Array< ModelCommunityFilterInput | null > | null,
  not?: ModelCommunityFilterInput | null,
};

export type ModelCommunityConnection = {
  __typename: "ModelCommunityConnection",
  items:  Array<Community | null >,
  nextToken?: string | null,
};

export type ModelCommunityInterviewRequestCommandFilterInput = {
  id?: ModelIDInput | null,
  communityID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  date?: ModelStringInput | null,
  status?: ModelCommunityInterviewStatusInput | null,
  and?: Array< ModelCommunityInterviewRequestCommandFilterInput | null > | null,
  or?: Array< ModelCommunityInterviewRequestCommandFilterInput | null > | null,
  not?: ModelCommunityInterviewRequestCommandFilterInput | null,
};

export type ModelCommunityInterviewRequestCommandConnection = {
  __typename: "ModelCommunityInterviewRequestCommandConnection",
  items:  Array<CommunityInterviewRequestCommand | null >,
  nextToken?: string | null,
};

export type ModelCommunityInterviewRequestFilterInput = {
  id?: ModelIDInput | null,
  communityID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  date?: ModelStringInput | null,
  status?: ModelCommunityInterviewStatusInput | null,
  owner?: ModelStringInput | null,
  and?: Array< ModelCommunityInterviewRequestFilterInput | null > | null,
  or?: Array< ModelCommunityInterviewRequestFilterInput | null > | null,
  not?: ModelCommunityInterviewRequestFilterInput | null,
};

export type ModelCommunityInterviewRequestConnection = {
  __typename: "ModelCommunityInterviewRequestConnection",
  items:  Array<CommunityInterviewRequest | null >,
  nextToken?: string | null,
};

export type ModelCommunityQuestionByUserByStatusByDateCompositeKeyConditionInput = {
  eq?: ModelCommunityQuestionByUserByStatusByDateCompositeKeyInput | null,
  le?: ModelCommunityQuestionByUserByStatusByDateCompositeKeyInput | null,
  lt?: ModelCommunityQuestionByUserByStatusByDateCompositeKeyInput | null,
  ge?: ModelCommunityQuestionByUserByStatusByDateCompositeKeyInput | null,
  gt?: ModelCommunityQuestionByUserByStatusByDateCompositeKeyInput | null,
  between?: Array< ModelCommunityQuestionByUserByStatusByDateCompositeKeyInput | null > | null,
  beginsWith?: ModelCommunityQuestionByUserByStatusByDateCompositeKeyInput | null,
};

export type ModelCommunityQuestionByUserByStatusByDateCompositeKeyInput = {
  status?: CommunityQuestionStatus | null,
  date?: string | null,
};

export type ModelCommunityQuestionByCommunityByStatusByDateCompositeKeyConditionInput = {
  eq?: ModelCommunityQuestionByCommunityByStatusByDateCompositeKeyInput | null,
  le?: ModelCommunityQuestionByCommunityByStatusByDateCompositeKeyInput | null,
  lt?: ModelCommunityQuestionByCommunityByStatusByDateCompositeKeyInput | null,
  ge?: ModelCommunityQuestionByCommunityByStatusByDateCompositeKeyInput | null,
  gt?: ModelCommunityQuestionByCommunityByStatusByDateCompositeKeyInput | null,
  between?: Array< ModelCommunityQuestionByCommunityByStatusByDateCompositeKeyInput | null > | null,
  beginsWith?: ModelCommunityQuestionByCommunityByStatusByDateCompositeKeyInput | null,
};

export type ModelCommunityQuestionByCommunityByStatusByDateCompositeKeyInput = {
  status?: CommunityQuestionStatus | null,
  date?: string | null,
};

export type ModelCommunityQuestionAnswerCommandFilterInput = {
  id?: ModelIDInput | null,
  communityQuestionAnswerID?: ModelIDInput | null,
  communityQuestionID?: ModelIDInput | null,
  communityID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  status?: ModelCommunityQuestionAnswerStatusInput | null,
  date?: ModelStringInput | null,
  content?: ModelStringInput | null,
  and?: Array< ModelCommunityQuestionAnswerCommandFilterInput | null > | null,
  or?: Array< ModelCommunityQuestionAnswerCommandFilterInput | null > | null,
  not?: ModelCommunityQuestionAnswerCommandFilterInput | null,
};

export type ModelCommunityQuestionAnswerCommandConnection = {
  __typename: "ModelCommunityQuestionAnswerCommandConnection",
  items:  Array<CommunityQuestionAnswerCommand | null >,
  nextToken?: string | null,
};

export type ModelCommunityQuestionAnswerByUserByStatusByDateCompositeKeyConditionInput = {
  eq?: ModelCommunityQuestionAnswerByUserByStatusByDateCompositeKeyInput | null,
  le?: ModelCommunityQuestionAnswerByUserByStatusByDateCompositeKeyInput | null,
  lt?: ModelCommunityQuestionAnswerByUserByStatusByDateCompositeKeyInput | null,
  ge?: ModelCommunityQuestionAnswerByUserByStatusByDateCompositeKeyInput | null,
  gt?: ModelCommunityQuestionAnswerByUserByStatusByDateCompositeKeyInput | null,
  between?: Array< ModelCommunityQuestionAnswerByUserByStatusByDateCompositeKeyInput | null > | null,
  beginsWith?: ModelCommunityQuestionAnswerByUserByStatusByDateCompositeKeyInput | null,
};

export type ModelCommunityQuestionAnswerByUserByStatusByDateCompositeKeyInput = {
  status?: CommunityQuestionAnswerStatus | null,
  date?: string | null,
};

export type ModelCommunityQuestionAnswerByCommunityByStatusByDateCompositeKeyConditionInput = {
  eq?: ModelCommunityQuestionAnswerByCommunityByStatusByDateCompositeKeyInput | null,
  le?: ModelCommunityQuestionAnswerByCommunityByStatusByDateCompositeKeyInput | null,
  lt?: ModelCommunityQuestionAnswerByCommunityByStatusByDateCompositeKeyInput | null,
  ge?: ModelCommunityQuestionAnswerByCommunityByStatusByDateCompositeKeyInput | null,
  gt?: ModelCommunityQuestionAnswerByCommunityByStatusByDateCompositeKeyInput | null,
  between?: Array< ModelCommunityQuestionAnswerByCommunityByStatusByDateCompositeKeyInput | null > | null,
  beginsWith?: ModelCommunityQuestionAnswerByCommunityByStatusByDateCompositeKeyInput | null,
};

export type ModelCommunityQuestionAnswerByCommunityByStatusByDateCompositeKeyInput = {
  status?: CommunityQuestionAnswerStatus | null,
  date?: string | null,
};

export type ModelCommunityQuestionAnswerCommentCommandFilterInput = {
  id?: ModelIDInput | null,
  communityQuestionAnswerCommentID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  communityID?: ModelIDInput | null,
  communityQuestionAnswerID?: ModelIDInput | null,
  content?: ModelStringInput | null,
  status?: ModelCommunityQuestionAnswerCommentStatusInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelCommunityQuestionAnswerCommentCommandFilterInput | null > | null,
  or?: Array< ModelCommunityQuestionAnswerCommentCommandFilterInput | null > | null,
  not?: ModelCommunityQuestionAnswerCommentCommandFilterInput | null,
};

export type ModelCommunityQuestionAnswerCommentCommandConnection = {
  __typename: "ModelCommunityQuestionAnswerCommentCommandConnection",
  items:  Array<CommunityQuestionAnswerCommentCommand | null >,
  nextToken?: string | null,
};

export type ModelCommunityQuestionAnswerCommentFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  communityID?: ModelIDInput | null,
  communityQuestionAnswerID?: ModelIDInput | null,
  content?: ModelStringInput | null,
  status?: ModelCommunityQuestionAnswerCommentStatusInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelCommunityQuestionAnswerCommentFilterInput | null > | null,
  or?: Array< ModelCommunityQuestionAnswerCommentFilterInput | null > | null,
  not?: ModelCommunityQuestionAnswerCommentFilterInput | null,
};

export type ModelCommunityQuestionAnswerCommentByCommunityQuestionAnswerByStatusCompositeKeyConditionInput = {
  eq?: ModelCommunityQuestionAnswerCommentByCommunityQuestionAnswerByStatusCompositeKeyInput | null,
  le?: ModelCommunityQuestionAnswerCommentByCommunityQuestionAnswerByStatusCompositeKeyInput | null,
  lt?: ModelCommunityQuestionAnswerCommentByCommunityQuestionAnswerByStatusCompositeKeyInput | null,
  ge?: ModelCommunityQuestionAnswerCommentByCommunityQuestionAnswerByStatusCompositeKeyInput | null,
  gt?: ModelCommunityQuestionAnswerCommentByCommunityQuestionAnswerByStatusCompositeKeyInput | null,
  between?: Array< ModelCommunityQuestionAnswerCommentByCommunityQuestionAnswerByStatusCompositeKeyInput | null > | null,
  beginsWith?: ModelCommunityQuestionAnswerCommentByCommunityQuestionAnswerByStatusCompositeKeyInput | null,
};

export type ModelCommunityQuestionAnswerCommentByCommunityQuestionAnswerByStatusCompositeKeyInput = {
  status?: CommunityQuestionAnswerCommentStatus | null,
  id?: string | null,
};

export type ModelCommunityQuestionAnswerCommentByUserByStatusByDateCompositeKeyConditionInput = {
  eq?: ModelCommunityQuestionAnswerCommentByUserByStatusByDateCompositeKeyInput | null,
  le?: ModelCommunityQuestionAnswerCommentByUserByStatusByDateCompositeKeyInput | null,
  lt?: ModelCommunityQuestionAnswerCommentByUserByStatusByDateCompositeKeyInput | null,
  ge?: ModelCommunityQuestionAnswerCommentByUserByStatusByDateCompositeKeyInput | null,
  gt?: ModelCommunityQuestionAnswerCommentByUserByStatusByDateCompositeKeyInput | null,
  between?: Array< ModelCommunityQuestionAnswerCommentByUserByStatusByDateCompositeKeyInput | null > | null,
  beginsWith?: ModelCommunityQuestionAnswerCommentByUserByStatusByDateCompositeKeyInput | null,
};

export type ModelCommunityQuestionAnswerCommentByUserByStatusByDateCompositeKeyInput = {
  status?: CommunityQuestionAnswerCommentStatus | null,
  date?: string | null,
};

export type ModelCommunityQuestionAnswerCommentByCommunityByStatusByDateCompositeKeyConditionInput = {
  eq?: ModelCommunityQuestionAnswerCommentByCommunityByStatusByDateCompositeKeyInput | null,
  le?: ModelCommunityQuestionAnswerCommentByCommunityByStatusByDateCompositeKeyInput | null,
  lt?: ModelCommunityQuestionAnswerCommentByCommunityByStatusByDateCompositeKeyInput | null,
  ge?: ModelCommunityQuestionAnswerCommentByCommunityByStatusByDateCompositeKeyInput | null,
  gt?: ModelCommunityQuestionAnswerCommentByCommunityByStatusByDateCompositeKeyInput | null,
  between?: Array< ModelCommunityQuestionAnswerCommentByCommunityByStatusByDateCompositeKeyInput | null > | null,
  beginsWith?: ModelCommunityQuestionAnswerCommentByCommunityByStatusByDateCompositeKeyInput | null,
};

export type ModelCommunityQuestionAnswerCommentByCommunityByStatusByDateCompositeKeyInput = {
  status?: CommunityQuestionAnswerCommentStatus | null,
  date?: string | null,
};

export type ModelCommunityQuestionAnswerLikeFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  communityID?: ModelIDInput | null,
  communityQuestionAnswerID?: ModelIDInput | null,
  status?: ModelCommunityQuestionAnswerLikeStatusInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelCommunityQuestionAnswerLikeFilterInput | null > | null,
  or?: Array< ModelCommunityQuestionAnswerLikeFilterInput | null > | null,
  not?: ModelCommunityQuestionAnswerLikeFilterInput | null,
};

export type ModelCommunityQuestionAnswerLikeByCommunityQuestionAnswerByStatusCompositeKeyConditionInput = {
  eq?: ModelCommunityQuestionAnswerLikeByCommunityQuestionAnswerByStatusCompositeKeyInput | null,
  le?: ModelCommunityQuestionAnswerLikeByCommunityQuestionAnswerByStatusCompositeKeyInput | null,
  lt?: ModelCommunityQuestionAnswerLikeByCommunityQuestionAnswerByStatusCompositeKeyInput | null,
  ge?: ModelCommunityQuestionAnswerLikeByCommunityQuestionAnswerByStatusCompositeKeyInput | null,
  gt?: ModelCommunityQuestionAnswerLikeByCommunityQuestionAnswerByStatusCompositeKeyInput | null,
  between?: Array< ModelCommunityQuestionAnswerLikeByCommunityQuestionAnswerByStatusCompositeKeyInput | null > | null,
  beginsWith?: ModelCommunityQuestionAnswerLikeByCommunityQuestionAnswerByStatusCompositeKeyInput | null,
};

export type ModelCommunityQuestionAnswerLikeByCommunityQuestionAnswerByStatusCompositeKeyInput = {
  status?: CommunityQuestionAnswerLikeStatus | null,
  id?: string | null,
};

export type ModelCommunityQuestionLikeFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  communityQuestionID?: ModelIDInput | null,
  status?: ModelCommunityQuestionLikeStatusInput | null,
  date?: ModelStringInput | null,
  and?: Array< ModelCommunityQuestionLikeFilterInput | null > | null,
  or?: Array< ModelCommunityQuestionLikeFilterInput | null > | null,
  not?: ModelCommunityQuestionLikeFilterInput | null,
};

export type ModelCommunityQuestionLikeByCommunityQuestionByStatusCompositeKeyConditionInput = {
  eq?: ModelCommunityQuestionLikeByCommunityQuestionByStatusCompositeKeyInput | null,
  le?: ModelCommunityQuestionLikeByCommunityQuestionByStatusCompositeKeyInput | null,
  lt?: ModelCommunityQuestionLikeByCommunityQuestionByStatusCompositeKeyInput | null,
  ge?: ModelCommunityQuestionLikeByCommunityQuestionByStatusCompositeKeyInput | null,
  gt?: ModelCommunityQuestionLikeByCommunityQuestionByStatusCompositeKeyInput | null,
  between?: Array< ModelCommunityQuestionLikeByCommunityQuestionByStatusCompositeKeyInput | null > | null,
  beginsWith?: ModelCommunityQuestionLikeByCommunityQuestionByStatusCompositeKeyInput | null,
};

export type ModelCommunityQuestionLikeByCommunityQuestionByStatusCompositeKeyInput = {
  status?: CommunityQuestionLikeStatus | null,
  id?: string | null,
};

export type ModelCommunityQuestionAnswerLikeByUserByStatusByDateCompositeKeyConditionInput = {
  eq?: ModelCommunityQuestionAnswerLikeByUserByStatusByDateCompositeKeyInput | null,
  le?: ModelCommunityQuestionAnswerLikeByUserByStatusByDateCompositeKeyInput | null,
  lt?: ModelCommunityQuestionAnswerLikeByUserByStatusByDateCompositeKeyInput | null,
  ge?: ModelCommunityQuestionAnswerLikeByUserByStatusByDateCompositeKeyInput | null,
  gt?: ModelCommunityQuestionAnswerLikeByUserByStatusByDateCompositeKeyInput | null,
  between?: Array< ModelCommunityQuestionAnswerLikeByUserByStatusByDateCompositeKeyInput | null > | null,
  beginsWith?: ModelCommunityQuestionAnswerLikeByUserByStatusByDateCompositeKeyInput | null,
};

export type ModelCommunityQuestionAnswerLikeByUserByStatusByDateCompositeKeyInput = {
  status?: CommunityQuestionAnswerLikeStatus | null,
  date?: string | null,
};

export type ModelCommunityQuestionRecommendationFilterInput = {
  id?: ModelIDInput | null,
  communityID?: ModelIDInput | null,
  categoryID?: ModelIDInput | null,
  questionText?: ModelStringInput | null,
  score?: ModelIntInput | null,
  status?: ModelCommunityQuestionRecommendationStatusInput | null,
  and?: Array< ModelCommunityQuestionRecommendationFilterInput | null > | null,
  or?: Array< ModelCommunityQuestionRecommendationFilterInput | null > | null,
  not?: ModelCommunityQuestionRecommendationFilterInput | null,
};

export type ModelCommunityUserCommandFilterInput = {
  id?: ModelIDInput | null,
  communityUserId?: ModelIDInput | null,
  communityID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  joinedOn?: ModelStringInput | null,
  invitedByUserID?: ModelIDInput | null,
  invitedOn?: ModelStringInput | null,
  status?: ModelCommunityUserStatusInput | null,
  relationshipToSpotlight?: ModelStringInput | null,
  and?: Array< ModelCommunityUserCommandFilterInput | null > | null,
  or?: Array< ModelCommunityUserCommandFilterInput | null > | null,
  not?: ModelCommunityUserCommandFilterInput | null,
};

export type ModelCommunityUserCommandConnection = {
  __typename: "ModelCommunityUserCommandConnection",
  items:  Array<CommunityUserCommand | null >,
  nextToken?: string | null,
};

export type ModelCommunityUserFilterInput = {
  id?: ModelIDInput | null,
  communityID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  joinedOn?: ModelStringInput | null,
  invitedByUserID?: ModelIDInput | null,
  invitedOn?: ModelStringInput | null,
  status?: ModelCommunityUserStatusInput | null,
  relationshipToSpotlight?: ModelStringInput | null,
  owner?: ModelStringInput | null,
  group?: ModelStringInput | null,
  and?: Array< ModelCommunityUserFilterInput | null > | null,
  or?: Array< ModelCommunityUserFilterInput | null > | null,
  not?: ModelCommunityUserFilterInput | null,
};

export type ModelCommunityUserByUserByStatusByJoinDateCompositeKeyConditionInput = {
  eq?: ModelCommunityUserByUserByStatusByJoinDateCompositeKeyInput | null,
  le?: ModelCommunityUserByUserByStatusByJoinDateCompositeKeyInput | null,
  lt?: ModelCommunityUserByUserByStatusByJoinDateCompositeKeyInput | null,
  ge?: ModelCommunityUserByUserByStatusByJoinDateCompositeKeyInput | null,
  gt?: ModelCommunityUserByUserByStatusByJoinDateCompositeKeyInput | null,
  between?: Array< ModelCommunityUserByUserByStatusByJoinDateCompositeKeyInput | null > | null,
  beginsWith?: ModelCommunityUserByUserByStatusByJoinDateCompositeKeyInput | null,
};

export type ModelCommunityUserByUserByStatusByJoinDateCompositeKeyInput = {
  status?: CommunityUserStatus | null,
  joinedOn?: string | null,
};

export type ModelCommunityUserByCommunityByStatusByJoinDateCompositeKeyConditionInput = {
  eq?: ModelCommunityUserByCommunityByStatusByJoinDateCompositeKeyInput | null,
  le?: ModelCommunityUserByCommunityByStatusByJoinDateCompositeKeyInput | null,
  lt?: ModelCommunityUserByCommunityByStatusByJoinDateCompositeKeyInput | null,
  ge?: ModelCommunityUserByCommunityByStatusByJoinDateCompositeKeyInput | null,
  gt?: ModelCommunityUserByCommunityByStatusByJoinDateCompositeKeyInput | null,
  between?: Array< ModelCommunityUserByCommunityByStatusByJoinDateCompositeKeyInput | null > | null,
  beginsWith?: ModelCommunityUserByCommunityByStatusByJoinDateCompositeKeyInput | null,
};

export type ModelCommunityUserByCommunityByStatusByJoinDateCompositeKeyInput = {
  status?: CommunityUserStatus | null,
  joinedOn?: string | null,
};

export type ModelInvitationFilterInput = {
  id?: ModelIDInput | null,
  email?: ModelStringInput | null,
  toNotify?: ModelBooleanInput | null,
  status?: ModelInvitationStatusInput | null,
  and?: Array< ModelInvitationFilterInput | null > | null,
  or?: Array< ModelInvitationFilterInput | null > | null,
  not?: ModelInvitationFilterInput | null,
};

export type ModelInvitationConnection = {
  __typename: "ModelInvitationConnection",
  items:  Array<Invitation | null >,
  nextToken?: string | null,
};

export type ModelUsernameToUserIdFilterInput = {
  username?: ModelStringInput | null,
  userID?: ModelIDInput | null,
  and?: Array< ModelUsernameToUserIdFilterInput | null > | null,
  or?: Array< ModelUsernameToUserIdFilterInput | null > | null,
  not?: ModelUsernameToUserIdFilterInput | null,
};

export type ModelUsernameToUserIdConnection = {
  __typename: "ModelUsernameToUserIdConnection",
  items:  Array<UsernameToUserId | null >,
  nextToken?: string | null,
};

export type ModelUserIdToIdentityIdFilterInput = {
  userID?: ModelIDInput | null,
  identityID?: ModelStringInput | null,
  and?: Array< ModelUserIdToIdentityIdFilterInput | null > | null,
  or?: Array< ModelUserIdToIdentityIdFilterInput | null > | null,
  not?: ModelUserIdToIdentityIdFilterInput | null,
};

export type ModelUserIdToIdentityIdConnection = {
  __typename: "ModelUserIdToIdentityIdConnection",
  items:  Array<UserIdToIdentityId | null >,
  nextToken?: string | null,
};

export type ModelUserCommandFilterInput = {
  id?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  status?: ModelUserStatusInput | null,
  profileImageKey?: ModelStringInput | null,
  welcomesSeen?: ModelStringInput | null,
  helpsSeen?: ModelStringInput | null,
  username?: ModelStringInput | null,
  identityID?: ModelStringInput | null,
  referralCode?: ModelStringInput | null,
  and?: Array< ModelUserCommandFilterInput | null > | null,
  or?: Array< ModelUserCommandFilterInput | null > | null,
  not?: ModelUserCommandFilterInput | null,
};

export type ModelUserCommandConnection = {
  __typename: "ModelUserCommandConnection",
  items:  Array<UserCommand | null >,
  nextToken?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  firstName?: ModelStringInput | null,
  lastName?: ModelStringInput | null,
  email?: ModelStringInput | null,
  status?: ModelUserStatusInput | null,
  profileImageKey?: ModelStringInput | null,
  welcomesSeen?: ModelStringInput | null,
  helpsSeen?: ModelStringInput | null,
  referralCode?: ModelStringInput | null,
  referredByUserID?: ModelStringInput | null,
  username?: ModelStringInput | null,
  identityID?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items:  Array<User | null >,
  nextToken?: string | null,
};

export type ModelUserGoogleTokensFilterInput = {
  owner?: ModelIDInput | null,
  accessToken?: ModelStringInput | null,
  refreshToken?: ModelStringInput | null,
  scopes?: ModelStringInput | null,
  expiresOn?: ModelStringInput | null,
  and?: Array< ModelUserGoogleTokensFilterInput | null > | null,
  or?: Array< ModelUserGoogleTokensFilterInput | null > | null,
  not?: ModelUserGoogleTokensFilterInput | null,
};

export type ModelUserGoogleTokensConnection = {
  __typename: "ModelUserGoogleTokensConnection",
  items:  Array<UserGoogleTokens | null >,
  nextToken?: string | null,
};

export type ModelUserReferralCodeFilterInput = {
  referralCode?: ModelStringInput | null,
  firstName?: ModelStringInput | null,
  and?: Array< ModelUserReferralCodeFilterInput | null > | null,
  or?: Array< ModelUserReferralCodeFilterInput | null > | null,
  not?: ModelUserReferralCodeFilterInput | null,
};

export type ModelUserReferralCodeConnection = {
  __typename: "ModelUserReferralCodeConnection",
  items:  Array<UserReferralCode | null >,
  nextToken?: string | null,
};

export type ModelxAssignEmailToPlaceholderFilterInput = {
  id?: ModelIDInput | null,
  status?: ModelOperationStatusInput | null,
  reason?: ModelStringInput | null,
  communityUserID?: ModelIDInput | null,
  userID?: ModelIDInput | null,
  communityID?: ModelIDInput | null,
  email?: ModelStringInput | null,
  and?: Array< ModelxAssignEmailToPlaceholderFilterInput | null > | null,
  or?: Array< ModelxAssignEmailToPlaceholderFilterInput | null > | null,
  not?: ModelxAssignEmailToPlaceholderFilterInput | null,
};

export type ModelxAssignEmailToPlaceholderConnection = {
  __typename: "ModelxAssignEmailToPlaceholderConnection",
  items:  Array<xAssignEmailToPlaceholder | null >,
  nextToken?: string | null,
};

export type ListCommunityQuestions2QueryVariables = {
  filter?: ModelCommunityQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommunityQuestions2Query = {
  listCommunityQuestions?:  {
    __typename: "ModelCommunityQuestionConnection",
    items:  Array< {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          user:  {
            __typename: "User",
            firstName: string,
            lastName: string,
            email?: string | null,
            status?: UserStatus | null,
          },
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AnswersByQuestion2QueryVariables = {
  communityQuestionID?: string | null,
  statusDate?: ModelCommunityQuestionAnswerByCommunityQuestionByStatusByDateCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommunityQuestionAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AnswersByQuestion2Query = {
  answersByQuestion?:  {
    __typename: "ModelCommunityQuestionAnswerConnection",
    items:  Array< {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      communityID: string,
      userID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      owner?: string | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommunityForActivityHistoryQueryVariables = {
  id: string,
};

export type GetCommunityForActivityHistoryQuery = {
  getCommunity?:  {
    __typename: "Community",
    id: string,
    name: string,
    imageKey?: string | null,
    type?: CommunityType | null,
    allowPublicPages?: boolean | null,
    alias?: string | null,
    publicPreviewHeaderURL?: string | null,
    publicPreviewDescription?: string | null,
    requiresModeration?: boolean | null,
    allowUnauthenticatedAnswers?: boolean | null,
    vimeoVideos?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    communityQuestionsByStatusDate?:  {
      __typename: "ModelCommunityQuestionConnection",
      items:  Array< {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        date: string,
        status: CommunityQuestionStatus,
        questionText: string,
        imageKeys?: Array< string > | null,
        createdAt: string,
        updatedAt: string,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          items:  Array< {
            __typename: "CommunityQuestionAnswer",
            id: string,
            communityQuestionID: string,
            userID: string,
            communityID: string,
            status: CommunityQuestionAnswerStatus,
            date: string,
            content: string,
            createdAt: string,
            updatedAt: string,
            owner?: string | null,
            communityQuestionAnswerCommentsByStatusDate?:  {
              __typename: "ModelCommunityQuestionAnswerCommentConnection",
              items:  Array< {
                __typename: "CommunityQuestionAnswerComment",
                id: string,
                userID: string,
                communityID: string,
                communityQuestionAnswerID: string,
                content: string,
                status: CommunityQuestionAnswerCommentStatus,
                date: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                user:  {
                  __typename: "User",
                  id: string,
                  firstName: string,
                  lastName: string,
                  status?: UserStatus | null,
                  profileImageKey?: string | null,
                  username?: string | null,
                  identityID?: string | null,
                  createdAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            communityQuestionAnswerLikesByStatus?:  {
              __typename: "ModelCommunityQuestionAnswerLikeConnection",
              items:  Array< {
                __typename: "CommunityQuestionAnswerLike",
                id: string,
                userID: string,
                communityID: string,
                communityQuestionAnswerID: string,
                status: CommunityQuestionAnswerLikeStatus,
                date: string,
                createdAt: string,
                updatedAt: string,
                owner?: string | null,
                user:  {
                  __typename: "User",
                  id: string,
                  firstName: string,
                  lastName: string,
                  status?: UserStatus | null,
                  profileImageKey?: string | null,
                  username?: string | null,
                  identityID?: string | null,
                  createdAt: string,
                },
              } | null >,
              nextToken?: string | null,
            } | null,
            user:  {
              __typename: "User",
              id: string,
              firstName: string,
              lastName: string,
              status?: UserStatus | null,
              profileImageKey?: string | null,
              username?: string | null,
              identityID?: string | null,
              createdAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          items:  Array< {
            __typename: "CommunityQuestionLike",
            id: string,
            userID: string,
            communityQuestionID: string,
            status: CommunityQuestionLikeStatus,
            date: string,
            createdAt: string,
            updatedAt: string,
            user:  {
              __typename: "User",
              id: string,
              firstName: string,
              lastName: string,
              status?: UserStatus | null,
              profileImageKey?: string | null,
              username?: string | null,
              identityID?: string | null,
              createdAt: string,
              updatedAt: string,
            },
          } | null >,
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityUsersByStatusDate?:  {
      __typename: "ModelCommunityUserConnection",
      items:  Array< {
        __typename: "CommunityUser",
        id: string,
        communityID: string,
        userID: string,
        joinedOn: string,
        invitedByUserID: string,
        invitedOn: string,
        invitation?:  {
          __typename: "CommunityUserInvitation",
          invitationMessage: string,
        } | null,
        status: CommunityUserStatus,
        relationshipToSpotlight?: string | null,
        owner?: string | null,
        createdAt: string,
        updatedAt: string,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        invitedByUser:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreatePacemakerLastRunMutationVariables = {
  input: CreatePacemakerLastRunInput,
  condition?: ModelPacemakerLastRunConditionInput | null,
};

export type CreatePacemakerLastRunMutation = {
  createPacemakerLastRun?:  {
    __typename: "PacemakerLastRun",
    id: string,
    entries:  Array< {
      __typename: "PacemakerRunEntry",
      job: string,
      timestamp: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdatePacemakerLastRunMutationVariables = {
  input: UpdatePacemakerLastRunInput,
  condition?: ModelPacemakerLastRunConditionInput | null,
};

export type UpdatePacemakerLastRunMutation = {
  updatePacemakerLastRun?:  {
    __typename: "PacemakerLastRun",
    id: string,
    entries:  Array< {
      __typename: "PacemakerRunEntry",
      job: string,
      timestamp: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeletePacemakerLastRunMutationVariables = {
  input: DeletePacemakerLastRunInput,
  condition?: ModelPacemakerLastRunConditionInput | null,
};

export type DeletePacemakerLastRunMutation = {
  deletePacemakerLastRun?:  {
    __typename: "PacemakerLastRun",
    id: string,
    entries:  Array< {
      __typename: "PacemakerRunEntry",
      job: string,
      timestamp: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateZAnswerCreatedNotifySigMutationVariables = {
  input: CreateZAnswerCreatedNotifySigInput,
  condition?: ModelzAnswerCreatedNotifySigConditionInput | null,
};

export type CreateZAnswerCreatedNotifySigMutation = {
  createZAnswerCreatedNotifySig?:  {
    __typename: "zAnswerCreatedNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateZAnswerCreatedNotifySigMutationVariables = {
  input: UpdateZAnswerCreatedNotifySigInput,
  condition?: ModelzAnswerCreatedNotifySigConditionInput | null,
};

export type UpdateZAnswerCreatedNotifySigMutation = {
  updateZAnswerCreatedNotifySig?:  {
    __typename: "zAnswerCreatedNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteZAnswerCreatedNotifySigMutationVariables = {
  input: DeleteZAnswerCreatedNotifySigInput,
  condition?: ModelzAnswerCreatedNotifySigConditionInput | null,
};

export type DeleteZAnswerCreatedNotifySigMutation = {
  deleteZAnswerCreatedNotifySig?:  {
    __typename: "zAnswerCreatedNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateZAnswerCreatedNotifyBacklogMutationVariables = {
  input: CreateZAnswerCreatedNotifyBacklogInput,
  condition?: ModelzAnswerCreatedNotifyBacklogConditionInput | null,
};

export type CreateZAnswerCreatedNotifyBacklogMutation = {
  createZAnswerCreatedNotifyBacklog?:  {
    __typename: "zAnswerCreatedNotifyBacklog",
    communityID: string,
    communityQuestionAnswerID: string,
    communityQuestionAnswerCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateZAnswerCreatedNotifyBacklogMutationVariables = {
  input: UpdateZAnswerCreatedNotifyBacklogInput,
  condition?: ModelzAnswerCreatedNotifyBacklogConditionInput | null,
};

export type UpdateZAnswerCreatedNotifyBacklogMutation = {
  updateZAnswerCreatedNotifyBacklog?:  {
    __typename: "zAnswerCreatedNotifyBacklog",
    communityID: string,
    communityQuestionAnswerID: string,
    communityQuestionAnswerCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteZAnswerCreatedNotifyBacklogMutationVariables = {
  input: DeleteZAnswerCreatedNotifyBacklogInput,
  condition?: ModelzAnswerCreatedNotifyBacklogConditionInput | null,
};

export type DeleteZAnswerCreatedNotifyBacklogMutation = {
  deleteZAnswerCreatedNotifyBacklog?:  {
    __typename: "zAnswerCreatedNotifyBacklog",
    communityID: string,
    communityQuestionAnswerID: string,
    communityQuestionAnswerCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateZAnswerCreatedNotifyCompleteMutationVariables = {
  input: CreateZAnswerCreatedNotifyCompleteInput,
  condition?: ModelzAnswerCreatedNotifyCompleteConditionInput | null,
};

export type CreateZAnswerCreatedNotifyCompleteMutation = {
  createZAnswerCreatedNotifyComplete?:  {
    __typename: "zAnswerCreatedNotifyComplete",
    communityID: string,
    communityQuestionAnswerID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateZAnswerCreatedNotifyCompleteMutationVariables = {
  input: UpdateZAnswerCreatedNotifyCompleteInput,
  condition?: ModelzAnswerCreatedNotifyCompleteConditionInput | null,
};

export type UpdateZAnswerCreatedNotifyCompleteMutation = {
  updateZAnswerCreatedNotifyComplete?:  {
    __typename: "zAnswerCreatedNotifyComplete",
    communityID: string,
    communityQuestionAnswerID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteZAnswerCreatedNotifyCompleteMutationVariables = {
  input: DeleteZAnswerCreatedNotifyCompleteInput,
  condition?: ModelzAnswerCreatedNotifyCompleteConditionInput | null,
};

export type DeleteZAnswerCreatedNotifyCompleteMutation = {
  deleteZAnswerCreatedNotifyComplete?:  {
    __typename: "zAnswerCreatedNotifyComplete",
    communityID: string,
    communityQuestionAnswerID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type CreateZCommentMadeNotifySigMutationVariables = {
  input: CreateZCommentMadeNotifySigInput,
  condition?: ModelzCommentMadeNotifySigConditionInput | null,
};

export type CreateZCommentMadeNotifySigMutation = {
  createZCommentMadeNotifySig?:  {
    __typename: "zCommentMadeNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateZCommentMadeNotifySigMutationVariables = {
  input: UpdateZCommentMadeNotifySigInput,
  condition?: ModelzCommentMadeNotifySigConditionInput | null,
};

export type UpdateZCommentMadeNotifySigMutation = {
  updateZCommentMadeNotifySig?:  {
    __typename: "zCommentMadeNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteZCommentMadeNotifySigMutationVariables = {
  input: DeleteZCommentMadeNotifySigInput,
  condition?: ModelzCommentMadeNotifySigConditionInput | null,
};

export type DeleteZCommentMadeNotifySigMutation = {
  deleteZCommentMadeNotifySig?:  {
    __typename: "zCommentMadeNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateZCommentMadeNotifyBacklogMutationVariables = {
  input: CreateZCommentMadeNotifyBacklogInput,
  condition?: ModelzCommentMadeNotifyBacklogConditionInput | null,
};

export type CreateZCommentMadeNotifyBacklogMutation = {
  createZCommentMadeNotifyBacklog?:  {
    __typename: "zCommentMadeNotifyBacklog",
    communityID: string,
    communityQuestionAnswerID: string,
    communityQuestionAnswerCommentCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateZCommentMadeNotifyBacklogMutationVariables = {
  input: UpdateZCommentMadeNotifyBacklogInput,
  condition?: ModelzCommentMadeNotifyBacklogConditionInput | null,
};

export type UpdateZCommentMadeNotifyBacklogMutation = {
  updateZCommentMadeNotifyBacklog?:  {
    __typename: "zCommentMadeNotifyBacklog",
    communityID: string,
    communityQuestionAnswerID: string,
    communityQuestionAnswerCommentCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteZCommentMadeNotifyBacklogMutationVariables = {
  input: DeleteZCommentMadeNotifyBacklogInput,
  condition?: ModelzCommentMadeNotifyBacklogConditionInput | null,
};

export type DeleteZCommentMadeNotifyBacklogMutation = {
  deleteZCommentMadeNotifyBacklog?:  {
    __typename: "zCommentMadeNotifyBacklog",
    communityID: string,
    communityQuestionAnswerID: string,
    communityQuestionAnswerCommentCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateZCommentMadeNotifyCompleteMutationVariables = {
  input: CreateZCommentMadeNotifyCompleteInput,
  condition?: ModelzCommentMadeNotifyCompleteConditionInput | null,
};

export type CreateZCommentMadeNotifyCompleteMutation = {
  createZCommentMadeNotifyComplete?:  {
    __typename: "zCommentMadeNotifyComplete",
    communityID: string,
    communityQuestionAnswerID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateZCommentMadeNotifyCompleteMutationVariables = {
  input: UpdateZCommentMadeNotifyCompleteInput,
  condition?: ModelzCommentMadeNotifyCompleteConditionInput | null,
};

export type UpdateZCommentMadeNotifyCompleteMutation = {
  updateZCommentMadeNotifyComplete?:  {
    __typename: "zCommentMadeNotifyComplete",
    communityID: string,
    communityQuestionAnswerID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteZCommentMadeNotifyCompleteMutationVariables = {
  input: DeleteZCommentMadeNotifyCompleteInput,
  condition?: ModelzCommentMadeNotifyCompleteConditionInput | null,
};

export type DeleteZCommentMadeNotifyCompleteMutation = {
  deleteZCommentMadeNotifyComplete?:  {
    __typename: "zCommentMadeNotifyComplete",
    communityID: string,
    communityQuestionAnswerID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type CreateZCommunityInvitationReminderNotifySigMutationVariables = {
  input: CreateZCommunityInvitationReminderNotifySigInput,
  condition?: ModelzCommunityInvitationReminderNotifySigConditionInput | null,
};

export type CreateZCommunityInvitationReminderNotifySigMutation = {
  createZCommunityInvitationReminderNotifySig?:  {
    __typename: "zCommunityInvitationReminderNotifySig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type UpdateZCommunityInvitationReminderNotifySigMutationVariables = {
  input: UpdateZCommunityInvitationReminderNotifySigInput,
  condition?: ModelzCommunityInvitationReminderNotifySigConditionInput | null,
};

export type UpdateZCommunityInvitationReminderNotifySigMutation = {
  updateZCommunityInvitationReminderNotifySig?:  {
    __typename: "zCommunityInvitationReminderNotifySig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type DeleteZCommunityInvitationReminderNotifySigMutationVariables = {
  input: DeleteZCommunityInvitationReminderNotifySigInput,
  condition?: ModelzCommunityInvitationReminderNotifySigConditionInput | null,
};

export type DeleteZCommunityInvitationReminderNotifySigMutation = {
  deleteZCommunityInvitationReminderNotifySig?:  {
    __typename: "zCommunityInvitationReminderNotifySig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type CreateZCommunityInvitationReminderNotifyBacklogMutationVariables = {
  input: CreateZCommunityInvitationReminderNotifyBacklogInput,
  condition?: ModelzCommunityInvitationReminderNotifyBacklogConditionInput | null,
};

export type CreateZCommunityInvitationReminderNotifyBacklogMutation = {
  createZCommunityInvitationReminderNotifyBacklog?:  {
    __typename: "zCommunityInvitationReminderNotifyBacklog",
    communityUserID: string,
    userID: string,
    communityID: string,
    status: CommunityUserStatus,
    invitedByUserID: string,
    invitedOn: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateZCommunityInvitationReminderNotifyBacklogMutationVariables = {
  input: UpdateZCommunityInvitationReminderNotifyBacklogInput,
  condition?: ModelzCommunityInvitationReminderNotifyBacklogConditionInput | null,
};

export type UpdateZCommunityInvitationReminderNotifyBacklogMutation = {
  updateZCommunityInvitationReminderNotifyBacklog?:  {
    __typename: "zCommunityInvitationReminderNotifyBacklog",
    communityUserID: string,
    userID: string,
    communityID: string,
    status: CommunityUserStatus,
    invitedByUserID: string,
    invitedOn: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteZCommunityInvitationReminderNotifyBacklogMutationVariables = {
  input: DeleteZCommunityInvitationReminderNotifyBacklogInput,
  condition?: ModelzCommunityInvitationReminderNotifyBacklogConditionInput | null,
};

export type DeleteZCommunityInvitationReminderNotifyBacklogMutation = {
  deleteZCommunityInvitationReminderNotifyBacklog?:  {
    __typename: "zCommunityInvitationReminderNotifyBacklog",
    communityUserID: string,
    userID: string,
    communityID: string,
    status: CommunityUserStatus,
    invitedByUserID: string,
    invitedOn: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateZCommunityInvitationReminderNotifyCompleteMutationVariables = {
  input: CreateZCommunityInvitationReminderNotifyCompleteInput,
  condition?: ModelzCommunityInvitationReminderNotifyCompleteConditionInput | null,
};

export type CreateZCommunityInvitationReminderNotifyCompleteMutation = {
  createZCommunityInvitationReminderNotifyComplete?:  {
    __typename: "zCommunityInvitationReminderNotifyComplete",
    communityUserID: string,
    userID: string,
    communityID: string,
    status: CommunityUserStatus,
    invitedByUserID: string,
    invitedOn: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateZCommunityInvitationReminderNotifyCompleteMutationVariables = {
  input: UpdateZCommunityInvitationReminderNotifyCompleteInput,
  condition?: ModelzCommunityInvitationReminderNotifyCompleteConditionInput | null,
};

export type UpdateZCommunityInvitationReminderNotifyCompleteMutation = {
  updateZCommunityInvitationReminderNotifyComplete?:  {
    __typename: "zCommunityInvitationReminderNotifyComplete",
    communityUserID: string,
    userID: string,
    communityID: string,
    status: CommunityUserStatus,
    invitedByUserID: string,
    invitedOn: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteZCommunityInvitationReminderNotifyCompleteMutationVariables = {
  input: DeleteZCommunityInvitationReminderNotifyCompleteInput,
  condition?: ModelzCommunityInvitationReminderNotifyCompleteConditionInput | null,
};

export type DeleteZCommunityInvitationReminderNotifyCompleteMutation = {
  deleteZCommunityInvitationReminderNotifyComplete?:  {
    __typename: "zCommunityInvitationReminderNotifyComplete",
    communityUserID: string,
    userID: string,
    communityID: string,
    status: CommunityUserStatus,
    invitedByUserID: string,
    invitedOn: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type CreateZCommunityNextStepCalculateSigMutationVariables = {
  input: CreateZCommunityNextStepCalculateSigInput,
  condition?: ModelzCommunityNextStepCalculateSigConditionInput | null,
};

export type CreateZCommunityNextStepCalculateSigMutation = {
  createZCommunityNextStepCalculateSig?:  {
    __typename: "zCommunityNextStepCalculateSig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type UpdateZCommunityNextStepCalculateSigMutationVariables = {
  input: UpdateZCommunityNextStepCalculateSigInput,
  condition?: ModelzCommunityNextStepCalculateSigConditionInput | null,
};

export type UpdateZCommunityNextStepCalculateSigMutation = {
  updateZCommunityNextStepCalculateSig?:  {
    __typename: "zCommunityNextStepCalculateSig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type DeleteZCommunityNextStepCalculateSigMutationVariables = {
  input: DeleteZCommunityNextStepCalculateSigInput,
  condition?: ModelzCommunityNextStepCalculateSigConditionInput | null,
};

export type DeleteZCommunityNextStepCalculateSigMutation = {
  deleteZCommunityNextStepCalculateSig?:  {
    __typename: "zCommunityNextStepCalculateSig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type CreateZCommunityNextStepCalculateBacklogMutationVariables = {
  input: CreateZCommunityNextStepCalculateBacklogInput,
  condition?: ModelzCommunityNextStepCalculateBacklogConditionInput | null,
};

export type CreateZCommunityNextStepCalculateBacklogMutation = {
  createZCommunityNextStepCalculateBacklog?:  {
    __typename: "zCommunityNextStepCalculateBacklog",
    id: string,
    communityID: string,
    nextStepType: NextStepType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateZCommunityNextStepCalculateBacklogMutationVariables = {
  input: UpdateZCommunityNextStepCalculateBacklogInput,
  condition?: ModelzCommunityNextStepCalculateBacklogConditionInput | null,
};

export type UpdateZCommunityNextStepCalculateBacklogMutation = {
  updateZCommunityNextStepCalculateBacklog?:  {
    __typename: "zCommunityNextStepCalculateBacklog",
    id: string,
    communityID: string,
    nextStepType: NextStepType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteZCommunityNextStepCalculateBacklogMutationVariables = {
  input: DeleteZCommunityNextStepCalculateBacklogInput,
  condition?: ModelzCommunityNextStepCalculateBacklogConditionInput | null,
};

export type DeleteZCommunityNextStepCalculateBacklogMutation = {
  deleteZCommunityNextStepCalculateBacklog?:  {
    __typename: "zCommunityNextStepCalculateBacklog",
    id: string,
    communityID: string,
    nextStepType: NextStepType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateZCommunityNextStepCalculateCompleteMutationVariables = {
  input: CreateZCommunityNextStepCalculateCompleteInput,
  condition?: ModelzCommunityNextStepCalculateCompleteConditionInput | null,
};

export type CreateZCommunityNextStepCalculateCompleteMutation = {
  createZCommunityNextStepCalculateComplete?:  {
    __typename: "zCommunityNextStepCalculateComplete",
    id: string,
    communityID: string,
    nextStepType: NextStepType,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateZCommunityNextStepCalculateCompleteMutationVariables = {
  input: UpdateZCommunityNextStepCalculateCompleteInput,
  condition?: ModelzCommunityNextStepCalculateCompleteConditionInput | null,
};

export type UpdateZCommunityNextStepCalculateCompleteMutation = {
  updateZCommunityNextStepCalculateComplete?:  {
    __typename: "zCommunityNextStepCalculateComplete",
    id: string,
    communityID: string,
    nextStepType: NextStepType,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteZCommunityNextStepCalculateCompleteMutationVariables = {
  input: DeleteZCommunityNextStepCalculateCompleteInput,
  condition?: ModelzCommunityNextStepCalculateCompleteConditionInput | null,
};

export type DeleteZCommunityNextStepCalculateCompleteMutation = {
  deleteZCommunityNextStepCalculateComplete?:  {
    __typename: "zCommunityNextStepCalculateComplete",
    id: string,
    communityID: string,
    nextStepType: NextStepType,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type CreateZFirstCommunityQuestionNotifySigMutationVariables = {
  input: CreateZFirstCommunityQuestionNotifySigInput,
  condition?: ModelzFirstCommunityQuestionNotifySigConditionInput | null,
};

export type CreateZFirstCommunityQuestionNotifySigMutation = {
  createZFirstCommunityQuestionNotifySig?:  {
    __typename: "zFirstCommunityQuestionNotifySig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type UpdateZFirstCommunityQuestionNotifySigMutationVariables = {
  input: UpdateZFirstCommunityQuestionNotifySigInput,
  condition?: ModelzFirstCommunityQuestionNotifySigConditionInput | null,
};

export type UpdateZFirstCommunityQuestionNotifySigMutation = {
  updateZFirstCommunityQuestionNotifySig?:  {
    __typename: "zFirstCommunityQuestionNotifySig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type DeleteZFirstCommunityQuestionNotifySigMutationVariables = {
  input: DeleteZFirstCommunityQuestionNotifySigInput,
  condition?: ModelzFirstCommunityQuestionNotifySigConditionInput | null,
};

export type DeleteZFirstCommunityQuestionNotifySigMutation = {
  deleteZFirstCommunityQuestionNotifySig?:  {
    __typename: "zFirstCommunityQuestionNotifySig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type CreateZFirstCommunityQuestionNotifyBacklogMutationVariables = {
  input: CreateZFirstCommunityQuestionNotifyBacklogInput,
  condition?: ModelzFirstCommunityQuestionNotifyBacklogConditionInput | null,
};

export type CreateZFirstCommunityQuestionNotifyBacklogMutation = {
  createZFirstCommunityQuestionNotifyBacklog?:  {
    __typename: "zFirstCommunityQuestionNotifyBacklog",
    communityID: string,
    communityCreatedAt: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateZFirstCommunityQuestionNotifyBacklogMutationVariables = {
  input: UpdateZFirstCommunityQuestionNotifyBacklogInput,
  condition?: ModelzFirstCommunityQuestionNotifyBacklogConditionInput | null,
};

export type UpdateZFirstCommunityQuestionNotifyBacklogMutation = {
  updateZFirstCommunityQuestionNotifyBacklog?:  {
    __typename: "zFirstCommunityQuestionNotifyBacklog",
    communityID: string,
    communityCreatedAt: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteZFirstCommunityQuestionNotifyBacklogMutationVariables = {
  input: DeleteZFirstCommunityQuestionNotifyBacklogInput,
  condition?: ModelzFirstCommunityQuestionNotifyBacklogConditionInput | null,
};

export type DeleteZFirstCommunityQuestionNotifyBacklogMutation = {
  deleteZFirstCommunityQuestionNotifyBacklog?:  {
    __typename: "zFirstCommunityQuestionNotifyBacklog",
    communityID: string,
    communityCreatedAt: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateZFirstCommunityQuestionNotifyCompleteMutationVariables = {
  input: CreateZFirstCommunityQuestionNotifyCompleteInput,
  condition?: ModelzFirstCommunityQuestionNotifyCompleteConditionInput | null,
};

export type CreateZFirstCommunityQuestionNotifyCompleteMutation = {
  createZFirstCommunityQuestionNotifyComplete?:  {
    __typename: "zFirstCommunityQuestionNotifyComplete",
    communityID: string,
    communityCreatedAt: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateZFirstCommunityQuestionNotifyCompleteMutationVariables = {
  input: UpdateZFirstCommunityQuestionNotifyCompleteInput,
  condition?: ModelzFirstCommunityQuestionNotifyCompleteConditionInput | null,
};

export type UpdateZFirstCommunityQuestionNotifyCompleteMutation = {
  updateZFirstCommunityQuestionNotifyComplete?:  {
    __typename: "zFirstCommunityQuestionNotifyComplete",
    communityID: string,
    communityCreatedAt: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteZFirstCommunityQuestionNotifyCompleteMutationVariables = {
  input: DeleteZFirstCommunityQuestionNotifyCompleteInput,
  condition?: ModelzFirstCommunityQuestionNotifyCompleteConditionInput | null,
};

export type DeleteZFirstCommunityQuestionNotifyCompleteMutation = {
  deleteZFirstCommunityQuestionNotifyComplete?:  {
    __typename: "zFirstCommunityQuestionNotifyComplete",
    communityID: string,
    communityCreatedAt: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type CreateZQuestionAskedNotifySigMutationVariables = {
  input: CreateZQuestionAskedNotifySigInput,
  condition?: ModelzQuestionAskedNotifySigConditionInput | null,
};

export type CreateZQuestionAskedNotifySigMutation = {
  createZQuestionAskedNotifySig?:  {
    __typename: "zQuestionAskedNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateZQuestionAskedNotifySigMutationVariables = {
  input: UpdateZQuestionAskedNotifySigInput,
  condition?: ModelzQuestionAskedNotifySigConditionInput | null,
};

export type UpdateZQuestionAskedNotifySigMutation = {
  updateZQuestionAskedNotifySig?:  {
    __typename: "zQuestionAskedNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteZQuestionAskedNotifySigMutationVariables = {
  input: DeleteZQuestionAskedNotifySigInput,
  condition?: ModelzQuestionAskedNotifySigConditionInput | null,
};

export type DeleteZQuestionAskedNotifySigMutation = {
  deleteZQuestionAskedNotifySig?:  {
    __typename: "zQuestionAskedNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateZQuestionAskedNotifyBacklogMutationVariables = {
  input: CreateZQuestionAskedNotifyBacklogInput,
  condition?: ModelzQuestionAskedNotifyBacklogConditionInput | null,
};

export type CreateZQuestionAskedNotifyBacklogMutation = {
  createZQuestionAskedNotifyBacklog?:  {
    __typename: "zQuestionAskedNotifyBacklog",
    communityID: string,
    communityQuestionID: string,
    communityQuestionCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateZQuestionAskedNotifyBacklogMutationVariables = {
  input: UpdateZQuestionAskedNotifyBacklogInput,
  condition?: ModelzQuestionAskedNotifyBacklogConditionInput | null,
};

export type UpdateZQuestionAskedNotifyBacklogMutation = {
  updateZQuestionAskedNotifyBacklog?:  {
    __typename: "zQuestionAskedNotifyBacklog",
    communityID: string,
    communityQuestionID: string,
    communityQuestionCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteZQuestionAskedNotifyBacklogMutationVariables = {
  input: DeleteZQuestionAskedNotifyBacklogInput,
  condition?: ModelzQuestionAskedNotifyBacklogConditionInput | null,
};

export type DeleteZQuestionAskedNotifyBacklogMutation = {
  deleteZQuestionAskedNotifyBacklog?:  {
    __typename: "zQuestionAskedNotifyBacklog",
    communityID: string,
    communityQuestionID: string,
    communityQuestionCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateZQuestionAskedNotifyCompleteMutationVariables = {
  input: CreateZQuestionAskedNotifyCompleteInput,
  condition?: ModelzQuestionAskedNotifyCompleteConditionInput | null,
};

export type CreateZQuestionAskedNotifyCompleteMutation = {
  createZQuestionAskedNotifyComplete?:  {
    __typename: "zQuestionAskedNotifyComplete",
    communityID: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateZQuestionAskedNotifyCompleteMutationVariables = {
  input: UpdateZQuestionAskedNotifyCompleteInput,
  condition?: ModelzQuestionAskedNotifyCompleteConditionInput | null,
};

export type UpdateZQuestionAskedNotifyCompleteMutation = {
  updateZQuestionAskedNotifyComplete?:  {
    __typename: "zQuestionAskedNotifyComplete",
    communityID: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteZQuestionAskedNotifyCompleteMutationVariables = {
  input: DeleteZQuestionAskedNotifyCompleteInput,
  condition?: ModelzQuestionAskedNotifyCompleteConditionInput | null,
};

export type DeleteZQuestionAskedNotifyCompleteMutation = {
  deleteZQuestionAskedNotifyComplete?:  {
    __typename: "zQuestionAskedNotifyComplete",
    communityID: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type CreateCategoryMutationVariables = {
  input: CreateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type CreateCategoryMutation = {
  createCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCategoryMutationVariables = {
  input: UpdateCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type UpdateCategoryMutation = {
  updateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCategoryMutationVariables = {
  input: DeleteCategoryInput,
  condition?: ModelCategoryConditionInput | null,
};

export type DeleteCategoryMutation = {
  deleteCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCommunityCommandMutationVariables = {
  input: CreateCommunityCommandInput,
  condition?: ModelCommunityCommandConditionInput | null,
};

export type CreateCommunityCommandMutation = {
  createCommunityCommand?:  {
    __typename: "CommunityCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityID?: string | null,
    name?: string | null,
    imageKey?: string | null,
    type?: CommunityType | null,
    allowPublicPages?: boolean | null,
    limits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    vimeoVideos?: Array< string > | null,
    communityCategories?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCommunityCommandMutationVariables = {
  input: UpdateCommunityCommandInput,
  condition?: ModelCommunityCommandConditionInput | null,
};

export type UpdateCommunityCommandMutation = {
  updateCommunityCommand?:  {
    __typename: "CommunityCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityID?: string | null,
    name?: string | null,
    imageKey?: string | null,
    type?: CommunityType | null,
    allowPublicPages?: boolean | null,
    limits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    vimeoVideos?: Array< string > | null,
    communityCategories?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCommunityCommandMutationVariables = {
  input: DeleteCommunityCommandInput,
  condition?: ModelCommunityCommandConditionInput | null,
};

export type DeleteCommunityCommandMutation = {
  deleteCommunityCommand?:  {
    __typename: "CommunityCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityID?: string | null,
    name?: string | null,
    imageKey?: string | null,
    type?: CommunityType | null,
    allowPublicPages?: boolean | null,
    limits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    vimeoVideos?: Array< string > | null,
    communityCategories?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCommunityMutationVariables = {
  input: CreateCommunityInput,
  condition?: ModelCommunityConditionInput | null,
};

export type CreateCommunityMutation = {
  createCommunity?:  {
    __typename: "Community",
    id: string,
    name: string,
    imageKey?: string | null,
    type?: CommunityType | null,
    allowPublicPages?: boolean | null,
    alias?: string | null,
    publicPreviewHeaderURL?: string | null,
    publicPreviewDescription?: string | null,
    requiresModeration?: boolean | null,
    allowUnauthenticatedAnswers?: boolean | null,
    limits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    vimeoVideos?: Array< string > | null,
    communityCategories?: Array< string > | null,
    interviewTab?:  {
      __typename: "InterviewTab",
      header: string,
      bodyLine1: string,
      bodyLine2: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    communityQuestionsByStatusDate?:  {
      __typename: "ModelCommunityQuestionConnection",
      items:  Array< {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionRecommendations?:  {
      __typename: "ModelCommunityQuestionRecommendationConnection",
      items:  Array< {
        __typename: "CommunityQuestionRecommendation",
        id: string,
        communityID: string,
        categoryID: string,
        questionText: string,
        score: number,
        status?: CommunityQuestionRecommendationStatus | null,
        createdAt: string,
        updatedAt: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    communityUsersByStatusDate?:  {
      __typename: "ModelCommunityUserConnection",
      items:  Array< {
        __typename: "CommunityUser",
        id: string,
        communityID: string,
        userID: string,
        joinedOn: string,
        invitedByUserID: string,
        invitedOn: string,
        invitation?:  {
          __typename: "CommunityUserInvitation",
          invitationMessage: string,
        } | null,
        status: CommunityUserStatus,
        relationshipToSpotlight?: string | null,
        owner?: string | null,
        group?: string | null,
        createdAt: string,
        updatedAt: string,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        invitedByUser:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateCommunityMutationVariables = {
  input: UpdateCommunityInput,
  condition?: ModelCommunityConditionInput | null,
};

export type UpdateCommunityMutation = {
  updateCommunity?:  {
    __typename: "Community",
    id: string,
    name: string,
    imageKey?: string | null,
    type?: CommunityType | null,
    allowPublicPages?: boolean | null,
    alias?: string | null,
    publicPreviewHeaderURL?: string | null,
    publicPreviewDescription?: string | null,
    requiresModeration?: boolean | null,
    allowUnauthenticatedAnswers?: boolean | null,
    limits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    vimeoVideos?: Array< string > | null,
    communityCategories?: Array< string > | null,
    interviewTab?:  {
      __typename: "InterviewTab",
      header: string,
      bodyLine1: string,
      bodyLine2: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    communityQuestionsByStatusDate?:  {
      __typename: "ModelCommunityQuestionConnection",
      items:  Array< {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionRecommendations?:  {
      __typename: "ModelCommunityQuestionRecommendationConnection",
      items:  Array< {
        __typename: "CommunityQuestionRecommendation",
        id: string,
        communityID: string,
        categoryID: string,
        questionText: string,
        score: number,
        status?: CommunityQuestionRecommendationStatus | null,
        createdAt: string,
        updatedAt: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    communityUsersByStatusDate?:  {
      __typename: "ModelCommunityUserConnection",
      items:  Array< {
        __typename: "CommunityUser",
        id: string,
        communityID: string,
        userID: string,
        joinedOn: string,
        invitedByUserID: string,
        invitedOn: string,
        invitation?:  {
          __typename: "CommunityUserInvitation",
          invitationMessage: string,
        } | null,
        status: CommunityUserStatus,
        relationshipToSpotlight?: string | null,
        owner?: string | null,
        group?: string | null,
        createdAt: string,
        updatedAt: string,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        invitedByUser:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteCommunityMutationVariables = {
  input: DeleteCommunityInput,
  condition?: ModelCommunityConditionInput | null,
};

export type DeleteCommunityMutation = {
  deleteCommunity?:  {
    __typename: "Community",
    id: string,
    name: string,
    imageKey?: string | null,
    type?: CommunityType | null,
    allowPublicPages?: boolean | null,
    alias?: string | null,
    publicPreviewHeaderURL?: string | null,
    publicPreviewDescription?: string | null,
    requiresModeration?: boolean | null,
    allowUnauthenticatedAnswers?: boolean | null,
    limits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    vimeoVideos?: Array< string > | null,
    communityCategories?: Array< string > | null,
    interviewTab?:  {
      __typename: "InterviewTab",
      header: string,
      bodyLine1: string,
      bodyLine2: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    communityQuestionsByStatusDate?:  {
      __typename: "ModelCommunityQuestionConnection",
      items:  Array< {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionRecommendations?:  {
      __typename: "ModelCommunityQuestionRecommendationConnection",
      items:  Array< {
        __typename: "CommunityQuestionRecommendation",
        id: string,
        communityID: string,
        categoryID: string,
        questionText: string,
        score: number,
        status?: CommunityQuestionRecommendationStatus | null,
        createdAt: string,
        updatedAt: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    communityUsersByStatusDate?:  {
      __typename: "ModelCommunityUserConnection",
      items:  Array< {
        __typename: "CommunityUser",
        id: string,
        communityID: string,
        userID: string,
        joinedOn: string,
        invitedByUserID: string,
        invitedOn: string,
        invitation?:  {
          __typename: "CommunityUserInvitation",
          invitationMessage: string,
        } | null,
        status: CommunityUserStatus,
        relationshipToSpotlight?: string | null,
        owner?: string | null,
        group?: string | null,
        createdAt: string,
        updatedAt: string,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        invitedByUser:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateCommunityInterviewRequestCommandMutationVariables = {
  input: CreateCommunityInterviewRequestCommandInput,
  condition?: ModelCommunityInterviewRequestCommandConditionInput | null,
};

export type CreateCommunityInterviewRequestCommandMutation = {
  createCommunityInterviewRequestCommand?:  {
    __typename: "CommunityInterviewRequestCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityID: string,
    userID: string,
    date?: string | null,
    status: CommunityInterviewStatus,
    message:  {
      __typename: "CommunityInterviewMessage",
      subject: string,
      body: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCommunityInterviewRequestCommandMutationVariables = {
  input: UpdateCommunityInterviewRequestCommandInput,
  condition?: ModelCommunityInterviewRequestCommandConditionInput | null,
};

export type UpdateCommunityInterviewRequestCommandMutation = {
  updateCommunityInterviewRequestCommand?:  {
    __typename: "CommunityInterviewRequestCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityID: string,
    userID: string,
    date?: string | null,
    status: CommunityInterviewStatus,
    message:  {
      __typename: "CommunityInterviewMessage",
      subject: string,
      body: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCommunityInterviewRequestCommandMutationVariables = {
  input: DeleteCommunityInterviewRequestCommandInput,
  condition?: ModelCommunityInterviewRequestCommandConditionInput | null,
};

export type DeleteCommunityInterviewRequestCommandMutation = {
  deleteCommunityInterviewRequestCommand?:  {
    __typename: "CommunityInterviewRequestCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityID: string,
    userID: string,
    date?: string | null,
    status: CommunityInterviewStatus,
    message:  {
      __typename: "CommunityInterviewMessage",
      subject: string,
      body: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCommunityInterviewRequestMutationVariables = {
  input: CreateCommunityInterviewRequestInput,
  condition?: ModelCommunityInterviewRequestConditionInput | null,
};

export type CreateCommunityInterviewRequestMutation = {
  createCommunityInterviewRequest?:  {
    __typename: "CommunityInterviewRequest",
    id: string,
    communityID: string,
    userID: string,
    date?: string | null,
    status: CommunityInterviewStatus,
    message:  {
      __typename: "CommunityInterviewMessage",
      subject: string,
      body: string,
    },
    owner: string,
    createdAt: string,
    updatedAt: string,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateCommunityInterviewRequestMutationVariables = {
  input: UpdateCommunityInterviewRequestInput,
  condition?: ModelCommunityInterviewRequestConditionInput | null,
};

export type UpdateCommunityInterviewRequestMutation = {
  updateCommunityInterviewRequest?:  {
    __typename: "CommunityInterviewRequest",
    id: string,
    communityID: string,
    userID: string,
    date?: string | null,
    status: CommunityInterviewStatus,
    message:  {
      __typename: "CommunityInterviewMessage",
      subject: string,
      body: string,
    },
    owner: string,
    createdAt: string,
    updatedAt: string,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteCommunityInterviewRequestMutationVariables = {
  input: DeleteCommunityInterviewRequestInput,
  condition?: ModelCommunityInterviewRequestConditionInput | null,
};

export type DeleteCommunityInterviewRequestMutation = {
  deleteCommunityInterviewRequest?:  {
    __typename: "CommunityInterviewRequest",
    id: string,
    communityID: string,
    userID: string,
    date?: string | null,
    status: CommunityInterviewStatus,
    message:  {
      __typename: "CommunityInterviewMessage",
      subject: string,
      body: string,
    },
    owner: string,
    createdAt: string,
    updatedAt: string,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateCommunityQuestionMutationVariables = {
  input: CreateCommunityQuestionInput,
  condition?: ModelCommunityQuestionConditionInput | null,
};

export type CreateCommunityQuestionMutation = {
  createCommunityQuestion?:  {
    __typename: "CommunityQuestion",
    id: string,
    communityID: string,
    userID: string,
    categoryID?: string | null,
    communityCategory?: string | null,
    date: string,
    status: CommunityQuestionStatus,
    source: CommunityQuestionSource,
    questionText: string,
    imageKeys?: Array< string > | null,
    clonedFromCommunityQuestionRecommendationId?: string | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
    communityQuestionAnswersByStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionLikesByStatus?:  {
      __typename: "ModelCommunityQuestionLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionLike",
        id: string,
        userID: string,
        communityQuestionID: string,
        status: CommunityQuestionLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateCommunityQuestionMutationVariables = {
  input: UpdateCommunityQuestionInput,
  condition?: ModelCommunityQuestionConditionInput | null,
};

export type UpdateCommunityQuestionMutation = {
  updateCommunityQuestion?:  {
    __typename: "CommunityQuestion",
    id: string,
    communityID: string,
    userID: string,
    categoryID?: string | null,
    communityCategory?: string | null,
    date: string,
    status: CommunityQuestionStatus,
    source: CommunityQuestionSource,
    questionText: string,
    imageKeys?: Array< string > | null,
    clonedFromCommunityQuestionRecommendationId?: string | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
    communityQuestionAnswersByStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionLikesByStatus?:  {
      __typename: "ModelCommunityQuestionLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionLike",
        id: string,
        userID: string,
        communityQuestionID: string,
        status: CommunityQuestionLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteCommunityQuestionMutationVariables = {
  input: DeleteCommunityQuestionInput,
  condition?: ModelCommunityQuestionConditionInput | null,
};

export type DeleteCommunityQuestionMutation = {
  deleteCommunityQuestion?:  {
    __typename: "CommunityQuestion",
    id: string,
    communityID: string,
    userID: string,
    categoryID?: string | null,
    communityCategory?: string | null,
    date: string,
    status: CommunityQuestionStatus,
    source: CommunityQuestionSource,
    questionText: string,
    imageKeys?: Array< string > | null,
    clonedFromCommunityQuestionRecommendationId?: string | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
    communityQuestionAnswersByStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionLikesByStatus?:  {
      __typename: "ModelCommunityQuestionLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionLike",
        id: string,
        userID: string,
        communityQuestionID: string,
        status: CommunityQuestionLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateCommunityQuestionAnswerCommandMutationVariables = {
  input: CreateCommunityQuestionAnswerCommandInput,
  condition?: ModelCommunityQuestionAnswerCommandConditionInput | null,
};

export type CreateCommunityQuestionAnswerCommandMutation = {
  createCommunityQuestionAnswerCommand?:  {
    __typename: "CommunityQuestionAnswerCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityQuestionAnswerID?: string | null,
    communityQuestionID?: string | null,
    communityID?: string | null,
    userID?: string | null,
    status?: CommunityQuestionAnswerStatus | null,
    date?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCommunityQuestionAnswerCommandMutationVariables = {
  input: UpdateCommunityQuestionAnswerCommandInput,
  condition?: ModelCommunityQuestionAnswerCommandConditionInput | null,
};

export type UpdateCommunityQuestionAnswerCommandMutation = {
  updateCommunityQuestionAnswerCommand?:  {
    __typename: "CommunityQuestionAnswerCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityQuestionAnswerID?: string | null,
    communityQuestionID?: string | null,
    communityID?: string | null,
    userID?: string | null,
    status?: CommunityQuestionAnswerStatus | null,
    date?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCommunityQuestionAnswerCommandMutationVariables = {
  input: DeleteCommunityQuestionAnswerCommandInput,
  condition?: ModelCommunityQuestionAnswerCommandConditionInput | null,
};

export type DeleteCommunityQuestionAnswerCommandMutation = {
  deleteCommunityQuestionAnswerCommand?:  {
    __typename: "CommunityQuestionAnswerCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityQuestionAnswerID?: string | null,
    communityQuestionID?: string | null,
    communityID?: string | null,
    userID?: string | null,
    status?: CommunityQuestionAnswerStatus | null,
    date?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCommunityQuestionAnswerMutationVariables = {
  input: CreateCommunityQuestionAnswerInput,
  condition?: ModelCommunityQuestionAnswerConditionInput | null,
};

export type CreateCommunityQuestionAnswerMutation = {
  createCommunityQuestionAnswer?:  {
    __typename: "CommunityQuestionAnswer",
    id: string,
    communityQuestionID: string,
    userID: string,
    communityID: string,
    status: CommunityQuestionAnswerStatus,
    date: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    communityQuestion:  {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    communityQuestionAnswerCommentsByStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerCommentConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerComment",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        content: string,
        status: CommunityQuestionAnswerCommentStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionAnswerLikesByStatus?:  {
      __typename: "ModelCommunityQuestionAnswerLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerLike",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        status: CommunityQuestionAnswerLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateCommunityQuestionAnswerMutationVariables = {
  input: UpdateCommunityQuestionAnswerInput,
  condition?: ModelCommunityQuestionAnswerConditionInput | null,
};

export type UpdateCommunityQuestionAnswerMutation = {
  updateCommunityQuestionAnswer?:  {
    __typename: "CommunityQuestionAnswer",
    id: string,
    communityQuestionID: string,
    userID: string,
    communityID: string,
    status: CommunityQuestionAnswerStatus,
    date: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    communityQuestion:  {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    communityQuestionAnswerCommentsByStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerCommentConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerComment",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        content: string,
        status: CommunityQuestionAnswerCommentStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionAnswerLikesByStatus?:  {
      __typename: "ModelCommunityQuestionAnswerLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerLike",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        status: CommunityQuestionAnswerLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteCommunityQuestionAnswerMutationVariables = {
  input: DeleteCommunityQuestionAnswerInput,
  condition?: ModelCommunityQuestionAnswerConditionInput | null,
};

export type DeleteCommunityQuestionAnswerMutation = {
  deleteCommunityQuestionAnswer?:  {
    __typename: "CommunityQuestionAnswer",
    id: string,
    communityQuestionID: string,
    userID: string,
    communityID: string,
    status: CommunityQuestionAnswerStatus,
    date: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    communityQuestion:  {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    communityQuestionAnswerCommentsByStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerCommentConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerComment",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        content: string,
        status: CommunityQuestionAnswerCommentStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionAnswerLikesByStatus?:  {
      __typename: "ModelCommunityQuestionAnswerLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerLike",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        status: CommunityQuestionAnswerLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateCommunityQuestionAnswerCommentCommandMutationVariables = {
  input: CreateCommunityQuestionAnswerCommentCommandInput,
  condition?: ModelCommunityQuestionAnswerCommentCommandConditionInput | null,
};

export type CreateCommunityQuestionAnswerCommentCommandMutation = {
  createCommunityQuestionAnswerCommentCommand?:  {
    __typename: "CommunityQuestionAnswerCommentCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityQuestionAnswerCommentID?: string | null,
    userID?: string | null,
    communityID?: string | null,
    communityQuestionAnswerID?: string | null,
    content?: string | null,
    status?: CommunityQuestionAnswerCommentStatus | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCommunityQuestionAnswerCommentCommandMutationVariables = {
  input: UpdateCommunityQuestionAnswerCommentCommandInput,
  condition?: ModelCommunityQuestionAnswerCommentCommandConditionInput | null,
};

export type UpdateCommunityQuestionAnswerCommentCommandMutation = {
  updateCommunityQuestionAnswerCommentCommand?:  {
    __typename: "CommunityQuestionAnswerCommentCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityQuestionAnswerCommentID?: string | null,
    userID?: string | null,
    communityID?: string | null,
    communityQuestionAnswerID?: string | null,
    content?: string | null,
    status?: CommunityQuestionAnswerCommentStatus | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCommunityQuestionAnswerCommentCommandMutationVariables = {
  input: DeleteCommunityQuestionAnswerCommentCommandInput,
  condition?: ModelCommunityQuestionAnswerCommentCommandConditionInput | null,
};

export type DeleteCommunityQuestionAnswerCommentCommandMutation = {
  deleteCommunityQuestionAnswerCommentCommand?:  {
    __typename: "CommunityQuestionAnswerCommentCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityQuestionAnswerCommentID?: string | null,
    userID?: string | null,
    communityID?: string | null,
    communityQuestionAnswerID?: string | null,
    content?: string | null,
    status?: CommunityQuestionAnswerCommentStatus | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCommunityQuestionAnswerCommentMutationVariables = {
  input: CreateCommunityQuestionAnswerCommentInput,
  condition?: ModelCommunityQuestionAnswerCommentConditionInput | null,
};

export type CreateCommunityQuestionAnswerCommentMutation = {
  createCommunityQuestionAnswerComment?:  {
    __typename: "CommunityQuestionAnswerComment",
    id: string,
    userID: string,
    communityID: string,
    communityQuestionAnswerID: string,
    content: string,
    status: CommunityQuestionAnswerCommentStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestionAnswer:  {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateCommunityQuestionAnswerCommentMutationVariables = {
  input: UpdateCommunityQuestionAnswerCommentInput,
  condition?: ModelCommunityQuestionAnswerCommentConditionInput | null,
};

export type UpdateCommunityQuestionAnswerCommentMutation = {
  updateCommunityQuestionAnswerComment?:  {
    __typename: "CommunityQuestionAnswerComment",
    id: string,
    userID: string,
    communityID: string,
    communityQuestionAnswerID: string,
    content: string,
    status: CommunityQuestionAnswerCommentStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestionAnswer:  {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteCommunityQuestionAnswerCommentMutationVariables = {
  input: DeleteCommunityQuestionAnswerCommentInput,
  condition?: ModelCommunityQuestionAnswerCommentConditionInput | null,
};

export type DeleteCommunityQuestionAnswerCommentMutation = {
  deleteCommunityQuestionAnswerComment?:  {
    __typename: "CommunityQuestionAnswerComment",
    id: string,
    userID: string,
    communityID: string,
    communityQuestionAnswerID: string,
    content: string,
    status: CommunityQuestionAnswerCommentStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestionAnswer:  {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateCommunityQuestionAnswerLikeMutationVariables = {
  input: CreateCommunityQuestionAnswerLikeInput,
  condition?: ModelCommunityQuestionAnswerLikeConditionInput | null,
};

export type CreateCommunityQuestionAnswerLikeMutation = {
  createCommunityQuestionAnswerLike?:  {
    __typename: "CommunityQuestionAnswerLike",
    id: string,
    userID: string,
    communityID: string,
    communityQuestionAnswerID: string,
    status: CommunityQuestionAnswerLikeStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestionAnswer:  {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateCommunityQuestionAnswerLikeMutationVariables = {
  input: UpdateCommunityQuestionAnswerLikeInput,
  condition?: ModelCommunityQuestionAnswerLikeConditionInput | null,
};

export type UpdateCommunityQuestionAnswerLikeMutation = {
  updateCommunityQuestionAnswerLike?:  {
    __typename: "CommunityQuestionAnswerLike",
    id: string,
    userID: string,
    communityID: string,
    communityQuestionAnswerID: string,
    status: CommunityQuestionAnswerLikeStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestionAnswer:  {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteCommunityQuestionAnswerLikeMutationVariables = {
  input: DeleteCommunityQuestionAnswerLikeInput,
  condition?: ModelCommunityQuestionAnswerLikeConditionInput | null,
};

export type DeleteCommunityQuestionAnswerLikeMutation = {
  deleteCommunityQuestionAnswerLike?:  {
    __typename: "CommunityQuestionAnswerLike",
    id: string,
    userID: string,
    communityID: string,
    communityQuestionAnswerID: string,
    status: CommunityQuestionAnswerLikeStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestionAnswer:  {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateCommunityQuestionLikeMutationVariables = {
  input: CreateCommunityQuestionLikeInput,
  condition?: ModelCommunityQuestionLikeConditionInput | null,
};

export type CreateCommunityQuestionLikeMutation = {
  createCommunityQuestionLike?:  {
    __typename: "CommunityQuestionLike",
    id: string,
    userID: string,
    communityQuestionID: string,
    status: CommunityQuestionLikeStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestion:  {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateCommunityQuestionLikeMutationVariables = {
  input: UpdateCommunityQuestionLikeInput,
  condition?: ModelCommunityQuestionLikeConditionInput | null,
};

export type UpdateCommunityQuestionLikeMutation = {
  updateCommunityQuestionLike?:  {
    __typename: "CommunityQuestionLike",
    id: string,
    userID: string,
    communityQuestionID: string,
    status: CommunityQuestionLikeStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestion:  {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteCommunityQuestionLikeMutationVariables = {
  input: DeleteCommunityQuestionLikeInput,
  condition?: ModelCommunityQuestionLikeConditionInput | null,
};

export type DeleteCommunityQuestionLikeMutation = {
  deleteCommunityQuestionLike?:  {
    __typename: "CommunityQuestionLike",
    id: string,
    userID: string,
    communityQuestionID: string,
    status: CommunityQuestionLikeStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestion:  {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateCommunityQuestionRecommendationMutationVariables = {
  input: CreateCommunityQuestionRecommendationInput,
  condition?: ModelCommunityQuestionRecommendationConditionInput | null,
};

export type CreateCommunityQuestionRecommendationMutation = {
  createCommunityQuestionRecommendation?:  {
    __typename: "CommunityQuestionRecommendation",
    id: string,
    communityID: string,
    categoryID: string,
    questionText: string,
    score: number,
    status?: CommunityQuestionRecommendationStatus | null,
    createdAt: string,
    updatedAt: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
  } | null,
};

export type UpdateCommunityQuestionRecommendationMutationVariables = {
  input: UpdateCommunityQuestionRecommendationInput,
  condition?: ModelCommunityQuestionRecommendationConditionInput | null,
};

export type UpdateCommunityQuestionRecommendationMutation = {
  updateCommunityQuestionRecommendation?:  {
    __typename: "CommunityQuestionRecommendation",
    id: string,
    communityID: string,
    categoryID: string,
    questionText: string,
    score: number,
    status?: CommunityQuestionRecommendationStatus | null,
    createdAt: string,
    updatedAt: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
  } | null,
};

export type DeleteCommunityQuestionRecommendationMutationVariables = {
  input: DeleteCommunityQuestionRecommendationInput,
  condition?: ModelCommunityQuestionRecommendationConditionInput | null,
};

export type DeleteCommunityQuestionRecommendationMutation = {
  deleteCommunityQuestionRecommendation?:  {
    __typename: "CommunityQuestionRecommendation",
    id: string,
    communityID: string,
    categoryID: string,
    questionText: string,
    score: number,
    status?: CommunityQuestionRecommendationStatus | null,
    createdAt: string,
    updatedAt: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
  } | null,
};

export type CreateCommunityUserCommandMutationVariables = {
  input: CreateCommunityUserCommandInput,
  condition?: ModelCommunityUserCommandConditionInput | null,
};

export type CreateCommunityUserCommandMutation = {
  createCommunityUserCommand?:  {
    __typename: "CommunityUserCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityUserId?: string | null,
    communityID?: string | null,
    userID?: string | null,
    joinedOn?: string | null,
    invitedByUserID?: string | null,
    invitedOn?: string | null,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    status?: CommunityUserStatus | null,
    relationshipToSpotlight?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateCommunityUserCommandMutationVariables = {
  input: UpdateCommunityUserCommandInput,
  condition?: ModelCommunityUserCommandConditionInput | null,
};

export type UpdateCommunityUserCommandMutation = {
  updateCommunityUserCommand?:  {
    __typename: "CommunityUserCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityUserId?: string | null,
    communityID?: string | null,
    userID?: string | null,
    joinedOn?: string | null,
    invitedByUserID?: string | null,
    invitedOn?: string | null,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    status?: CommunityUserStatus | null,
    relationshipToSpotlight?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteCommunityUserCommandMutationVariables = {
  input: DeleteCommunityUserCommandInput,
  condition?: ModelCommunityUserCommandConditionInput | null,
};

export type DeleteCommunityUserCommandMutation = {
  deleteCommunityUserCommand?:  {
    __typename: "CommunityUserCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityUserId?: string | null,
    communityID?: string | null,
    userID?: string | null,
    joinedOn?: string | null,
    invitedByUserID?: string | null,
    invitedOn?: string | null,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    status?: CommunityUserStatus | null,
    relationshipToSpotlight?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateCommunityUserMutationVariables = {
  input: CreateCommunityUserInput,
  condition?: ModelCommunityUserConditionInput | null,
};

export type CreateCommunityUserMutation = {
  createCommunityUser?:  {
    __typename: "CommunityUser",
    id: string,
    communityID: string,
    userID: string,
    joinedOn: string,
    invitedByUserID: string,
    invitedOn: string,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    status: CommunityUserStatus,
    relationshipToSpotlight?: string | null,
    owner?: string | null,
    group?: string | null,
    createdAt: string,
    updatedAt: string,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    invitedByUser:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type UpdateCommunityUserMutationVariables = {
  input: UpdateCommunityUserInput,
  condition?: ModelCommunityUserConditionInput | null,
};

export type UpdateCommunityUserMutation = {
  updateCommunityUser?:  {
    __typename: "CommunityUser",
    id: string,
    communityID: string,
    userID: string,
    joinedOn: string,
    invitedByUserID: string,
    invitedOn: string,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    status: CommunityUserStatus,
    relationshipToSpotlight?: string | null,
    owner?: string | null,
    group?: string | null,
    createdAt: string,
    updatedAt: string,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    invitedByUser:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type DeleteCommunityUserMutationVariables = {
  input: DeleteCommunityUserInput,
  condition?: ModelCommunityUserConditionInput | null,
};

export type DeleteCommunityUserMutation = {
  deleteCommunityUser?:  {
    __typename: "CommunityUser",
    id: string,
    communityID: string,
    userID: string,
    joinedOn: string,
    invitedByUserID: string,
    invitedOn: string,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    status: CommunityUserStatus,
    relationshipToSpotlight?: string | null,
    owner?: string | null,
    group?: string | null,
    createdAt: string,
    updatedAt: string,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    invitedByUser:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type CreateInvitationMutationVariables = {
  input: CreateInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type CreateInvitationMutation = {
  createInvitation?:  {
    __typename: "Invitation",
    id: string,
    email: string,
    toNotify: boolean,
    status: InvitationStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateInvitationMutationVariables = {
  input: UpdateInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type UpdateInvitationMutation = {
  updateInvitation?:  {
    __typename: "Invitation",
    id: string,
    email: string,
    toNotify: boolean,
    status: InvitationStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteInvitationMutationVariables = {
  input: DeleteInvitationInput,
  condition?: ModelInvitationConditionInput | null,
};

export type DeleteInvitationMutation = {
  deleteInvitation?:  {
    __typename: "Invitation",
    id: string,
    email: string,
    toNotify: boolean,
    status: InvitationStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUsernameToUserIdMutationVariables = {
  input: CreateUsernameToUserIdInput,
  condition?: ModelUsernameToUserIdConditionInput | null,
};

export type CreateUsernameToUserIdMutation = {
  createUsernameToUserId?:  {
    __typename: "UsernameToUserId",
    username: string,
    userID: string,
  } | null,
};

export type UpdateUsernameToUserIdMutationVariables = {
  input: UpdateUsernameToUserIdInput,
  condition?: ModelUsernameToUserIdConditionInput | null,
};

export type UpdateUsernameToUserIdMutation = {
  updateUsernameToUserId?:  {
    __typename: "UsernameToUserId",
    username: string,
    userID: string,
  } | null,
};

export type DeleteUsernameToUserIdMutationVariables = {
  input: DeleteUsernameToUserIdInput,
  condition?: ModelUsernameToUserIdConditionInput | null,
};

export type DeleteUsernameToUserIdMutation = {
  deleteUsernameToUserId?:  {
    __typename: "UsernameToUserId",
    username: string,
    userID: string,
  } | null,
};

export type CreateUserIdToIdentityIdMutationVariables = {
  input: CreateUserIdToIdentityIdInput,
  condition?: ModelUserIdToIdentityIdConditionInput | null,
};

export type CreateUserIdToIdentityIdMutation = {
  createUserIdToIdentityId?:  {
    __typename: "UserIdToIdentityId",
    userID: string,
    identityID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserIdToIdentityIdMutationVariables = {
  input: UpdateUserIdToIdentityIdInput,
  condition?: ModelUserIdToIdentityIdConditionInput | null,
};

export type UpdateUserIdToIdentityIdMutation = {
  updateUserIdToIdentityId?:  {
    __typename: "UserIdToIdentityId",
    userID: string,
    identityID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserIdToIdentityIdMutationVariables = {
  input: DeleteUserIdToIdentityIdInput,
  condition?: ModelUserIdToIdentityIdConditionInput | null,
};

export type DeleteUserIdToIdentityIdMutation = {
  deleteUserIdToIdentityId?:  {
    __typename: "UserIdToIdentityId",
    userID: string,
    identityID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserCommandMutationVariables = {
  input: CreateUserCommandInput,
  condition?: ModelUserCommandConditionInput | null,
};

export type CreateUserCommandMutation = {
  createUserCommand?:  {
    __typename: "UserCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    userID?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: UserStatus | null,
    profileImageKey?: string | null,
    communityLimits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    welcomesSeen?: Array< string | null > | null,
    helpsSeen?: Array< string | null > | null,
    username?: string | null,
    identityID?: string | null,
    referralCode?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateUserCommandMutationVariables = {
  input: UpdateUserCommandInput,
  condition?: ModelUserCommandConditionInput | null,
};

export type UpdateUserCommandMutation = {
  updateUserCommand?:  {
    __typename: "UserCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    userID?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: UserStatus | null,
    profileImageKey?: string | null,
    communityLimits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    welcomesSeen?: Array< string | null > | null,
    helpsSeen?: Array< string | null > | null,
    username?: string | null,
    identityID?: string | null,
    referralCode?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteUserCommandMutationVariables = {
  input: DeleteUserCommandInput,
  condition?: ModelUserCommandConditionInput | null,
};

export type DeleteUserCommandMutation = {
  deleteUserCommand?:  {
    __typename: "UserCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    userID?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: UserStatus | null,
    profileImageKey?: string | null,
    communityLimits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    welcomesSeen?: Array< string | null > | null,
    helpsSeen?: Array< string | null > | null,
    username?: string | null,
    identityID?: string | null,
    referralCode?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type CreateUserMutationVariables = {
  input: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    email?: string | null,
    status?: UserStatus | null,
    profileImageKey?: string | null,
    communityLimits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    welcomesSeen?: Array< string > | null,
    helpsSeen?: Array< string > | null,
    nextSteps?:  Array< {
      __typename: "CommunityNextStep",
      communityID: string,
      nextStepsByRole?:  {
        __typename: "NextStepsByRole",
        INVITED?: Array< NextStepType > | null,
        MEMBER?: Array< NextStepType > | null,
        ORGANIZER?: Array< NextStepType > | null,
        SUBJECT?: Array< NextStepType > | null,
        REMOVED?: Array< NextStepType > | null,
        BANNED?: Array< NextStepType > | null,
        ADMIN?: Array< NextStepType > | null,
      } | null,
    } > | null,
    referralCode?: string | null,
    referredByUserID?: string | null,
    username?: string | null,
    identityID?: string | null,
    createdAt: string,
    updatedAt: string,
    communityQuestionsByStatusDate?:  {
      __typename: "ModelCommunityQuestionConnection",
      items:  Array< {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionAnswersByUserStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionlikesByStatusDate?:  {
      __typename: "ModelCommunityQuestionLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionLike",
        id: string,
        userID: string,
        communityQuestionID: string,
        status: CommunityQuestionLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityUsersByStatusDate?:  {
      __typename: "ModelCommunityUserConnection",
      items:  Array< {
        __typename: "CommunityUser",
        id: string,
        communityID: string,
        userID: string,
        joinedOn: string,
        invitedByUserID: string,
        invitedOn: string,
        invitation?:  {
          __typename: "CommunityUserInvitation",
          invitationMessage: string,
        } | null,
        status: CommunityUserStatus,
        relationshipToSpotlight?: string | null,
        owner?: string | null,
        group?: string | null,
        createdAt: string,
        updatedAt: string,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        invitedByUser:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    email?: string | null,
    status?: UserStatus | null,
    profileImageKey?: string | null,
    communityLimits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    welcomesSeen?: Array< string > | null,
    helpsSeen?: Array< string > | null,
    nextSteps?:  Array< {
      __typename: "CommunityNextStep",
      communityID: string,
      nextStepsByRole?:  {
        __typename: "NextStepsByRole",
        INVITED?: Array< NextStepType > | null,
        MEMBER?: Array< NextStepType > | null,
        ORGANIZER?: Array< NextStepType > | null,
        SUBJECT?: Array< NextStepType > | null,
        REMOVED?: Array< NextStepType > | null,
        BANNED?: Array< NextStepType > | null,
        ADMIN?: Array< NextStepType > | null,
      } | null,
    } > | null,
    referralCode?: string | null,
    referredByUserID?: string | null,
    username?: string | null,
    identityID?: string | null,
    createdAt: string,
    updatedAt: string,
    communityQuestionsByStatusDate?:  {
      __typename: "ModelCommunityQuestionConnection",
      items:  Array< {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionAnswersByUserStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionlikesByStatusDate?:  {
      __typename: "ModelCommunityQuestionLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionLike",
        id: string,
        userID: string,
        communityQuestionID: string,
        status: CommunityQuestionLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityUsersByStatusDate?:  {
      __typename: "ModelCommunityUserConnection",
      items:  Array< {
        __typename: "CommunityUser",
        id: string,
        communityID: string,
        userID: string,
        joinedOn: string,
        invitedByUserID: string,
        invitedOn: string,
        invitation?:  {
          __typename: "CommunityUserInvitation",
          invitationMessage: string,
        } | null,
        status: CommunityUserStatus,
        relationshipToSpotlight?: string | null,
        owner?: string | null,
        group?: string | null,
        createdAt: string,
        updatedAt: string,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        invitedByUser:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    email?: string | null,
    status?: UserStatus | null,
    profileImageKey?: string | null,
    communityLimits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    welcomesSeen?: Array< string > | null,
    helpsSeen?: Array< string > | null,
    nextSteps?:  Array< {
      __typename: "CommunityNextStep",
      communityID: string,
      nextStepsByRole?:  {
        __typename: "NextStepsByRole",
        INVITED?: Array< NextStepType > | null,
        MEMBER?: Array< NextStepType > | null,
        ORGANIZER?: Array< NextStepType > | null,
        SUBJECT?: Array< NextStepType > | null,
        REMOVED?: Array< NextStepType > | null,
        BANNED?: Array< NextStepType > | null,
        ADMIN?: Array< NextStepType > | null,
      } | null,
    } > | null,
    referralCode?: string | null,
    referredByUserID?: string | null,
    username?: string | null,
    identityID?: string | null,
    createdAt: string,
    updatedAt: string,
    communityQuestionsByStatusDate?:  {
      __typename: "ModelCommunityQuestionConnection",
      items:  Array< {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionAnswersByUserStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionlikesByStatusDate?:  {
      __typename: "ModelCommunityQuestionLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionLike",
        id: string,
        userID: string,
        communityQuestionID: string,
        status: CommunityQuestionLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityUsersByStatusDate?:  {
      __typename: "ModelCommunityUserConnection",
      items:  Array< {
        __typename: "CommunityUser",
        id: string,
        communityID: string,
        userID: string,
        joinedOn: string,
        invitedByUserID: string,
        invitedOn: string,
        invitation?:  {
          __typename: "CommunityUserInvitation",
          invitationMessage: string,
        } | null,
        status: CommunityUserStatus,
        relationshipToSpotlight?: string | null,
        owner?: string | null,
        group?: string | null,
        createdAt: string,
        updatedAt: string,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        invitedByUser:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateUserGoogleTokensMutationVariables = {
  input: CreateUserGoogleTokensInput,
  condition?: ModelUserGoogleTokensConditionInput | null,
};

export type CreateUserGoogleTokensMutation = {
  createUserGoogleTokens?:  {
    __typename: "UserGoogleTokens",
    owner: string,
    accessToken: string,
    refreshToken: string,
    scopes?: Array< string > | null,
    expiresOn: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserGoogleTokensMutationVariables = {
  input: UpdateUserGoogleTokensInput,
  condition?: ModelUserGoogleTokensConditionInput | null,
};

export type UpdateUserGoogleTokensMutation = {
  updateUserGoogleTokens?:  {
    __typename: "UserGoogleTokens",
    owner: string,
    accessToken: string,
    refreshToken: string,
    scopes?: Array< string > | null,
    expiresOn: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserGoogleTokensMutationVariables = {
  input: DeleteUserGoogleTokensInput,
  condition?: ModelUserGoogleTokensConditionInput | null,
};

export type DeleteUserGoogleTokensMutation = {
  deleteUserGoogleTokens?:  {
    __typename: "UserGoogleTokens",
    owner: string,
    accessToken: string,
    refreshToken: string,
    scopes?: Array< string > | null,
    expiresOn: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateUserReferralCodeMutationVariables = {
  input: CreateUserReferralCodeInput,
  condition?: ModelUserReferralCodeConditionInput | null,
};

export type CreateUserReferralCodeMutation = {
  createUserReferralCode?:  {
    __typename: "UserReferralCode",
    referralCode: string,
    firstName: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type UpdateUserReferralCodeMutationVariables = {
  input: UpdateUserReferralCodeInput,
  condition?: ModelUserReferralCodeConditionInput | null,
};

export type UpdateUserReferralCodeMutation = {
  updateUserReferralCode?:  {
    __typename: "UserReferralCode",
    referralCode: string,
    firstName: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteUserReferralCodeMutationVariables = {
  input: DeleteUserReferralCodeInput,
  condition?: ModelUserReferralCodeConditionInput | null,
};

export type DeleteUserReferralCodeMutation = {
  deleteUserReferralCode?:  {
    __typename: "UserReferralCode",
    referralCode: string,
    firstName: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateXAssignEmailToPlaceholderMutationVariables = {
  input: CreateXAssignEmailToPlaceholderInput,
  condition?: ModelxAssignEmailToPlaceholderConditionInput | null,
};

export type CreateXAssignEmailToPlaceholderMutation = {
  createXAssignEmailToPlaceholder?:  {
    __typename: "xAssignEmailToPlaceholder",
    id: string,
    status: OperationStatus,
    reason?: string | null,
    communityUserID: string,
    userID: string,
    communityID: string,
    email: string,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type UpdateXAssignEmailToPlaceholderMutationVariables = {
  input: UpdateXAssignEmailToPlaceholderInput,
  condition?: ModelxAssignEmailToPlaceholderConditionInput | null,
};

export type UpdateXAssignEmailToPlaceholderMutation = {
  updateXAssignEmailToPlaceholder?:  {
    __typename: "xAssignEmailToPlaceholder",
    id: string,
    status: OperationStatus,
    reason?: string | null,
    communityUserID: string,
    userID: string,
    communityID: string,
    email: string,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type DeleteXAssignEmailToPlaceholderMutationVariables = {
  input: DeleteXAssignEmailToPlaceholderInput,
  condition?: ModelxAssignEmailToPlaceholderConditionInput | null,
};

export type DeleteXAssignEmailToPlaceholderMutation = {
  deleteXAssignEmailToPlaceholder?:  {
    __typename: "xAssignEmailToPlaceholder",
    id: string,
    status: OperationStatus,
    reason?: string | null,
    communityUserID: string,
    userID: string,
    communityID: string,
    email: string,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type GetPacemakerLastRunQueryVariables = {
  id: string,
};

export type GetPacemakerLastRunQuery = {
  getPacemakerLastRun?:  {
    __typename: "PacemakerLastRun",
    id: string,
    entries:  Array< {
      __typename: "PacemakerRunEntry",
      job: string,
      timestamp: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListPacemakerLastRunsQueryVariables = {
  filter?: ModelPacemakerLastRunFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPacemakerLastRunsQuery = {
  listPacemakerLastRuns?:  {
    __typename: "ModelPacemakerLastRunConnection",
    items:  Array< {
      __typename: "PacemakerLastRun",
      id: string,
      entries:  Array< {
        __typename: "PacemakerRunEntry",
        job: string,
        timestamp: string,
      } >,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZAnswerCreatedNotifySigQueryVariables = {
  id: string,
  createdAt: string,
};

export type GetZAnswerCreatedNotifySigQuery = {
  getZAnswerCreatedNotifySig?:  {
    __typename: "zAnswerCreatedNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListZAnswerCreatedNotifySigsQueryVariables = {
  id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelzAnswerCreatedNotifySigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListZAnswerCreatedNotifySigsQuery = {
  listZAnswerCreatedNotifySigs?:  {
    __typename: "ModelzAnswerCreatedNotifySigConnection",
    items:  Array< {
      __typename: "zAnswerCreatedNotifySig",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZAnswerCreatedNotifyBacklogQueryVariables = {
  communityQuestionAnswerID: string,
};

export type GetZAnswerCreatedNotifyBacklogQuery = {
  getZAnswerCreatedNotifyBacklog?:  {
    __typename: "zAnswerCreatedNotifyBacklog",
    communityID: string,
    communityQuestionAnswerID: string,
    communityQuestionAnswerCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListZAnswerCreatedNotifyBacklogsQueryVariables = {
  communityQuestionAnswerID?: string | null,
  filter?: ModelzAnswerCreatedNotifyBacklogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListZAnswerCreatedNotifyBacklogsQuery = {
  listZAnswerCreatedNotifyBacklogs?:  {
    __typename: "ModelzAnswerCreatedNotifyBacklogConnection",
    items:  Array< {
      __typename: "zAnswerCreatedNotifyBacklog",
      communityID: string,
      communityQuestionAnswerID: string,
      communityQuestionAnswerCreatedAt: string,
      userID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZAnswerCreatedNotifyCompleteQueryVariables = {
  communityQuestionAnswerID: string,
};

export type GetZAnswerCreatedNotifyCompleteQuery = {
  getZAnswerCreatedNotifyComplete?:  {
    __typename: "zAnswerCreatedNotifyComplete",
    communityID: string,
    communityQuestionAnswerID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type ListZAnswerCreatedNotifyCompletesQueryVariables = {
  communityQuestionAnswerID?: string | null,
  filter?: ModelzAnswerCreatedNotifyCompleteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListZAnswerCreatedNotifyCompletesQuery = {
  listZAnswerCreatedNotifyCompletes?:  {
    __typename: "ModelzAnswerCreatedNotifyCompleteConnection",
    items:  Array< {
      __typename: "zAnswerCreatedNotifyComplete",
      communityID: string,
      communityQuestionAnswerID: string,
      userID: string,
      createdAt: string,
      completedAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZCommentMadeNotifySigQueryVariables = {
  id: string,
  createdAt: string,
};

export type GetZCommentMadeNotifySigQuery = {
  getZCommentMadeNotifySig?:  {
    __typename: "zCommentMadeNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListZCommentMadeNotifySigsQueryVariables = {
  id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelzCommentMadeNotifySigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListZCommentMadeNotifySigsQuery = {
  listZCommentMadeNotifySigs?:  {
    __typename: "ModelzCommentMadeNotifySigConnection",
    items:  Array< {
      __typename: "zCommentMadeNotifySig",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZCommentMadeNotifyBacklogQueryVariables = {
  communityID: string,
};

export type GetZCommentMadeNotifyBacklogQuery = {
  getZCommentMadeNotifyBacklog?:  {
    __typename: "zCommentMadeNotifyBacklog",
    communityID: string,
    communityQuestionAnswerID: string,
    communityQuestionAnswerCommentCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListZCommentMadeNotifyBacklogsQueryVariables = {
  communityID?: string | null,
  filter?: ModelzCommentMadeNotifyBacklogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListZCommentMadeNotifyBacklogsQuery = {
  listZCommentMadeNotifyBacklogs?:  {
    __typename: "ModelzCommentMadeNotifyBacklogConnection",
    items:  Array< {
      __typename: "zCommentMadeNotifyBacklog",
      communityID: string,
      communityQuestionAnswerID: string,
      communityQuestionAnswerCommentCreatedAt: string,
      userID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZCommentMadeNotifyCompleteQueryVariables = {
  communityID: string,
};

export type GetZCommentMadeNotifyCompleteQuery = {
  getZCommentMadeNotifyComplete?:  {
    __typename: "zCommentMadeNotifyComplete",
    communityID: string,
    communityQuestionAnswerID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type ListZCommentMadeNotifyCompletesQueryVariables = {
  communityID?: string | null,
  filter?: ModelzCommentMadeNotifyCompleteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListZCommentMadeNotifyCompletesQuery = {
  listZCommentMadeNotifyCompletes?:  {
    __typename: "ModelzCommentMadeNotifyCompleteConnection",
    items:  Array< {
      __typename: "zCommentMadeNotifyComplete",
      communityID: string,
      communityQuestionAnswerID: string,
      userID: string,
      createdAt: string,
      completedAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZCommunityInvitationReminderNotifySigQueryVariables = {
  id: string,
  createdAt: string,
};

export type GetZCommunityInvitationReminderNotifySigQuery = {
  getZCommunityInvitationReminderNotifySig?:  {
    __typename: "zCommunityInvitationReminderNotifySig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type ListZCommunityInvitationReminderNotifySigsQueryVariables = {
  id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelzCommunityInvitationReminderNotifySigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListZCommunityInvitationReminderNotifySigsQuery = {
  listZCommunityInvitationReminderNotifySigs?:  {
    __typename: "ModelzCommunityInvitationReminderNotifySigConnection",
    items:  Array< {
      __typename: "zCommunityInvitationReminderNotifySig",
      id: string,
      source: string,
      createdAt: string,
      signal:  {
        __typename: "JobSignal",
        payload: string,
      },
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZCommunityInvitationReminderNotifyBacklogQueryVariables = {
  communityUserID: string,
};

export type GetZCommunityInvitationReminderNotifyBacklogQuery = {
  getZCommunityInvitationReminderNotifyBacklog?:  {
    __typename: "zCommunityInvitationReminderNotifyBacklog",
    communityUserID: string,
    userID: string,
    communityID: string,
    status: CommunityUserStatus,
    invitedByUserID: string,
    invitedOn: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListZCommunityInvitationReminderNotifyBacklogsQueryVariables = {
  communityUserID?: string | null,
  filter?: ModelzCommunityInvitationReminderNotifyBacklogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListZCommunityInvitationReminderNotifyBacklogsQuery = {
  listZCommunityInvitationReminderNotifyBacklogs?:  {
    __typename: "ModelzCommunityInvitationReminderNotifyBacklogConnection",
    items:  Array< {
      __typename: "zCommunityInvitationReminderNotifyBacklog",
      communityUserID: string,
      userID: string,
      communityID: string,
      status: CommunityUserStatus,
      invitedByUserID: string,
      invitedOn: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZCommunityInvitationReminderNotifyCompleteQueryVariables = {
  communityUserID: string,
};

export type GetZCommunityInvitationReminderNotifyCompleteQuery = {
  getZCommunityInvitationReminderNotifyComplete?:  {
    __typename: "zCommunityInvitationReminderNotifyComplete",
    communityUserID: string,
    userID: string,
    communityID: string,
    status: CommunityUserStatus,
    invitedByUserID: string,
    invitedOn: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type ListZCommunityInvitationReminderNotifyCompletesQueryVariables = {
  communityUserID?: string | null,
  filter?: ModelzCommunityInvitationReminderNotifyCompleteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListZCommunityInvitationReminderNotifyCompletesQuery = {
  listZCommunityInvitationReminderNotifyCompletes?:  {
    __typename: "ModelzCommunityInvitationReminderNotifyCompleteConnection",
    items:  Array< {
      __typename: "zCommunityInvitationReminderNotifyComplete",
      communityUserID: string,
      userID: string,
      communityID: string,
      status: CommunityUserStatus,
      invitedByUserID: string,
      invitedOn: string,
      createdAt: string,
      completedAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZCommunityNextStepCalculateSigQueryVariables = {
  id: string,
  createdAt: string,
};

export type GetZCommunityNextStepCalculateSigQuery = {
  getZCommunityNextStepCalculateSig?:  {
    __typename: "zCommunityNextStepCalculateSig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type ListZCommunityNextStepCalculateSigsQueryVariables = {
  id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelzCommunityNextStepCalculateSigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListZCommunityNextStepCalculateSigsQuery = {
  listZCommunityNextStepCalculateSigs?:  {
    __typename: "ModelzCommunityNextStepCalculateSigConnection",
    items:  Array< {
      __typename: "zCommunityNextStepCalculateSig",
      id: string,
      source: string,
      createdAt: string,
      signal:  {
        __typename: "JobSignal",
        payload: string,
      },
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZCommunityNextStepCalculateBacklogQueryVariables = {
  id: string,
};

export type GetZCommunityNextStepCalculateBacklogQuery = {
  getZCommunityNextStepCalculateBacklog?:  {
    __typename: "zCommunityNextStepCalculateBacklog",
    id: string,
    communityID: string,
    nextStepType: NextStepType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListZCommunityNextStepCalculateBacklogsQueryVariables = {
  filter?: ModelzCommunityNextStepCalculateBacklogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListZCommunityNextStepCalculateBacklogsQuery = {
  listZCommunityNextStepCalculateBacklogs?:  {
    __typename: "ModelzCommunityNextStepCalculateBacklogConnection",
    items:  Array< {
      __typename: "zCommunityNextStepCalculateBacklog",
      id: string,
      communityID: string,
      nextStepType: NextStepType,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZCommunityNextStepCalculateCompleteQueryVariables = {
  id: string,
};

export type GetZCommunityNextStepCalculateCompleteQuery = {
  getZCommunityNextStepCalculateComplete?:  {
    __typename: "zCommunityNextStepCalculateComplete",
    id: string,
    communityID: string,
    nextStepType: NextStepType,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type ListZCommunityNextStepCalculateCompletesQueryVariables = {
  filter?: ModelzCommunityNextStepCalculateCompleteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListZCommunityNextStepCalculateCompletesQuery = {
  listZCommunityNextStepCalculateCompletes?:  {
    __typename: "ModelzCommunityNextStepCalculateCompleteConnection",
    items:  Array< {
      __typename: "zCommunityNextStepCalculateComplete",
      id: string,
      communityID: string,
      nextStepType: NextStepType,
      createdAt: string,
      completedAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZFirstCommunityQuestionNotifySigQueryVariables = {
  id: string,
  createdAt: string,
};

export type GetZFirstCommunityQuestionNotifySigQuery = {
  getZFirstCommunityQuestionNotifySig?:  {
    __typename: "zFirstCommunityQuestionNotifySig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type ListZFirstCommunityQuestionNotifySigsQueryVariables = {
  id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelzFirstCommunityQuestionNotifySigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListZFirstCommunityQuestionNotifySigsQuery = {
  listZFirstCommunityQuestionNotifySigs?:  {
    __typename: "ModelzFirstCommunityQuestionNotifySigConnection",
    items:  Array< {
      __typename: "zFirstCommunityQuestionNotifySig",
      id: string,
      source: string,
      createdAt: string,
      signal:  {
        __typename: "JobSignal",
        payload: string,
      },
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZFirstCommunityQuestionNotifyBacklogQueryVariables = {
  communityID: string,
};

export type GetZFirstCommunityQuestionNotifyBacklogQuery = {
  getZFirstCommunityQuestionNotifyBacklog?:  {
    __typename: "zFirstCommunityQuestionNotifyBacklog",
    communityID: string,
    communityCreatedAt: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListZFirstCommunityQuestionNotifyBacklogsQueryVariables = {
  communityID?: string | null,
  filter?: ModelzFirstCommunityQuestionNotifyBacklogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListZFirstCommunityQuestionNotifyBacklogsQuery = {
  listZFirstCommunityQuestionNotifyBacklogs?:  {
    __typename: "ModelzFirstCommunityQuestionNotifyBacklogConnection",
    items:  Array< {
      __typename: "zFirstCommunityQuestionNotifyBacklog",
      communityID: string,
      communityCreatedAt: string,
      communityQuestionID: string,
      userID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZFirstCommunityQuestionNotifyCompleteQueryVariables = {
  communityID: string,
};

export type GetZFirstCommunityQuestionNotifyCompleteQuery = {
  getZFirstCommunityQuestionNotifyComplete?:  {
    __typename: "zFirstCommunityQuestionNotifyComplete",
    communityID: string,
    communityCreatedAt: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type ListZFirstCommunityQuestionNotifyCompletesQueryVariables = {
  communityID?: string | null,
  filter?: ModelzFirstCommunityQuestionNotifyCompleteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListZFirstCommunityQuestionNotifyCompletesQuery = {
  listZFirstCommunityQuestionNotifyCompletes?:  {
    __typename: "ModelzFirstCommunityQuestionNotifyCompleteConnection",
    items:  Array< {
      __typename: "zFirstCommunityQuestionNotifyComplete",
      communityID: string,
      communityCreatedAt: string,
      communityQuestionID: string,
      userID: string,
      createdAt: string,
      completedAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZQuestionAskedNotifySigQueryVariables = {
  id: string,
  createdAt: string,
};

export type GetZQuestionAskedNotifySigQuery = {
  getZQuestionAskedNotifySig?:  {
    __typename: "zQuestionAskedNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListZQuestionAskedNotifySigsQueryVariables = {
  id?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  filter?: ModelzQuestionAskedNotifySigFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListZQuestionAskedNotifySigsQuery = {
  listZQuestionAskedNotifySigs?:  {
    __typename: "ModelzQuestionAskedNotifySigConnection",
    items:  Array< {
      __typename: "zQuestionAskedNotifySig",
      id: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZQuestionAskedNotifyBacklogQueryVariables = {
  communityID: string,
};

export type GetZQuestionAskedNotifyBacklogQuery = {
  getZQuestionAskedNotifyBacklog?:  {
    __typename: "zQuestionAskedNotifyBacklog",
    communityID: string,
    communityQuestionID: string,
    communityQuestionCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListZQuestionAskedNotifyBacklogsQueryVariables = {
  communityID?: string | null,
  filter?: ModelzQuestionAskedNotifyBacklogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListZQuestionAskedNotifyBacklogsQuery = {
  listZQuestionAskedNotifyBacklogs?:  {
    __typename: "ModelzQuestionAskedNotifyBacklogConnection",
    items:  Array< {
      __typename: "zQuestionAskedNotifyBacklog",
      communityID: string,
      communityQuestionID: string,
      communityQuestionCreatedAt: string,
      userID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetZQuestionAskedNotifyCompleteQueryVariables = {
  communityID: string,
};

export type GetZQuestionAskedNotifyCompleteQuery = {
  getZQuestionAskedNotifyComplete?:  {
    __typename: "zQuestionAskedNotifyComplete",
    communityID: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type ListZQuestionAskedNotifyCompletesQueryVariables = {
  communityID?: string | null,
  filter?: ModelzQuestionAskedNotifyCompleteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListZQuestionAskedNotifyCompletesQuery = {
  listZQuestionAskedNotifyCompletes?:  {
    __typename: "ModelzQuestionAskedNotifyCompleteConnection",
    items:  Array< {
      __typename: "zQuestionAskedNotifyComplete",
      communityID: string,
      communityQuestionID: string,
      userID: string,
      createdAt: string,
      completedAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NextStepsBacklogByCommunityQueryVariables = {
  communityID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelzCommunityNextStepCalculateBacklogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NextStepsBacklogByCommunityQuery = {
  nextStepsBacklogByCommunity?:  {
    __typename: "ModelzCommunityNextStepCalculateBacklogConnection",
    items:  Array< {
      __typename: "zCommunityNextStepCalculateBacklog",
      id: string,
      communityID: string,
      nextStepType: NextStepType,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NextStepsBacklogByCommunityAndNextStepTypeQueryVariables = {
  communityID?: string | null,
  nextStepType?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelzCommunityNextStepCalculateBacklogFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NextStepsBacklogByCommunityAndNextStepTypeQuery = {
  nextStepsBacklogByCommunityAndNextStepType?:  {
    __typename: "ModelzCommunityNextStepCalculateBacklogConnection",
    items:  Array< {
      __typename: "zCommunityNextStepCalculateBacklog",
      id: string,
      communityID: string,
      nextStepType: NextStepType,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NextStepsCompletedByCommunityQueryVariables = {
  communityID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelzCommunityNextStepCalculateCompleteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NextStepsCompletedByCommunityQuery = {
  nextStepsCompletedByCommunity?:  {
    __typename: "ModelzCommunityNextStepCalculateCompleteConnection",
    items:  Array< {
      __typename: "zCommunityNextStepCalculateComplete",
      id: string,
      communityID: string,
      nextStepType: NextStepType,
      createdAt: string,
      completedAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type NextStepsCompleteByCommunityAndNextStepTypeQueryVariables = {
  communityID?: string | null,
  nextStepType?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelzCommunityNextStepCalculateCompleteFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type NextStepsCompleteByCommunityAndNextStepTypeQuery = {
  nextStepsCompleteByCommunityAndNextStepType?:  {
    __typename: "ModelzCommunityNextStepCalculateCompleteConnection",
    items:  Array< {
      __typename: "zCommunityNextStepCalculateComplete",
      id: string,
      communityID: string,
      nextStepType: NextStepType,
      createdAt: string,
      completedAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCategoryQueryVariables = {
  id: string,
};

export type GetCategoryQuery = {
  getCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCategoriesQueryVariables = {
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCategoriesQuery = {
  listCategories?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CategoriesByNameQueryVariables = {
  name?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCategoryFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CategoriesByNameQuery = {
  categoriesByName?:  {
    __typename: "ModelCategoryConnection",
    items:  Array< {
      __typename: "Category",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommunityCommandQueryVariables = {
  id: string,
};

export type GetCommunityCommandQuery = {
  getCommunityCommand?:  {
    __typename: "CommunityCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityID?: string | null,
    name?: string | null,
    imageKey?: string | null,
    type?: CommunityType | null,
    allowPublicPages?: boolean | null,
    limits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    vimeoVideos?: Array< string > | null,
    communityCategories?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCommunityCommandsQueryVariables = {
  filter?: ModelCommunityCommandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommunityCommandsQuery = {
  listCommunityCommands?:  {
    __typename: "ModelCommunityCommandConnection",
    items:  Array< {
      __typename: "CommunityCommand",
      id: string,
      command:  {
        __typename: "Command",
        operation: CommandOperation,
      },
      communityID?: string | null,
      name?: string | null,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommunityQueryVariables = {
  id: string,
};

export type GetCommunityQuery = {
  getCommunity?:  {
    __typename: "Community",
    id: string,
    name: string,
    imageKey?: string | null,
    type?: CommunityType | null,
    allowPublicPages?: boolean | null,
    alias?: string | null,
    publicPreviewHeaderURL?: string | null,
    publicPreviewDescription?: string | null,
    requiresModeration?: boolean | null,
    allowUnauthenticatedAnswers?: boolean | null,
    limits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    vimeoVideos?: Array< string > | null,
    communityCategories?: Array< string > | null,
    interviewTab?:  {
      __typename: "InterviewTab",
      header: string,
      bodyLine1: string,
      bodyLine2: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    communityQuestionsByStatusDate?:  {
      __typename: "ModelCommunityQuestionConnection",
      items:  Array< {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionRecommendations?:  {
      __typename: "ModelCommunityQuestionRecommendationConnection",
      items:  Array< {
        __typename: "CommunityQuestionRecommendation",
        id: string,
        communityID: string,
        categoryID: string,
        questionText: string,
        score: number,
        status?: CommunityQuestionRecommendationStatus | null,
        createdAt: string,
        updatedAt: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    communityUsersByStatusDate?:  {
      __typename: "ModelCommunityUserConnection",
      items:  Array< {
        __typename: "CommunityUser",
        id: string,
        communityID: string,
        userID: string,
        joinedOn: string,
        invitedByUserID: string,
        invitedOn: string,
        invitation?:  {
          __typename: "CommunityUserInvitation",
          invitationMessage: string,
        } | null,
        status: CommunityUserStatus,
        relationshipToSpotlight?: string | null,
        owner?: string | null,
        group?: string | null,
        createdAt: string,
        updatedAt: string,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        invitedByUser:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListCommunitiesQueryVariables = {
  filter?: ModelCommunityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommunitiesQuery = {
  listCommunities?:  {
    __typename: "ModelCommunityConnection",
    items:  Array< {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommunityByAliasQueryVariables = {
  alias?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommunityFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommunityByAliasQuery = {
  communityByAlias?:  {
    __typename: "ModelCommunityConnection",
    items:  Array< {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommunityInterviewRequestCommandQueryVariables = {
  id: string,
};

export type GetCommunityInterviewRequestCommandQuery = {
  getCommunityInterviewRequestCommand?:  {
    __typename: "CommunityInterviewRequestCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityID: string,
    userID: string,
    date?: string | null,
    status: CommunityInterviewStatus,
    message:  {
      __typename: "CommunityInterviewMessage",
      subject: string,
      body: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCommunityInterviewRequestCommandsQueryVariables = {
  filter?: ModelCommunityInterviewRequestCommandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommunityInterviewRequestCommandsQuery = {
  listCommunityInterviewRequestCommands?:  {
    __typename: "ModelCommunityInterviewRequestCommandConnection",
    items:  Array< {
      __typename: "CommunityInterviewRequestCommand",
      id: string,
      command:  {
        __typename: "Command",
        operation: CommandOperation,
      },
      communityID: string,
      userID: string,
      date?: string | null,
      status: CommunityInterviewStatus,
      message:  {
        __typename: "CommunityInterviewMessage",
        subject: string,
        body: string,
      },
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommunityInterviewRequestQueryVariables = {
  id: string,
};

export type GetCommunityInterviewRequestQuery = {
  getCommunityInterviewRequest?:  {
    __typename: "CommunityInterviewRequest",
    id: string,
    communityID: string,
    userID: string,
    date?: string | null,
    status: CommunityInterviewStatus,
    message:  {
      __typename: "CommunityInterviewMessage",
      subject: string,
      body: string,
    },
    owner: string,
    createdAt: string,
    updatedAt: string,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListCommunityInterviewRequestsQueryVariables = {
  filter?: ModelCommunityInterviewRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommunityInterviewRequestsQuery = {
  listCommunityInterviewRequests?:  {
    __typename: "ModelCommunityInterviewRequestConnection",
    items:  Array< {
      __typename: "CommunityInterviewRequest",
      id: string,
      communityID: string,
      userID: string,
      date?: string | null,
      status: CommunityInterviewStatus,
      message:  {
        __typename: "CommunityInterviewMessage",
        subject: string,
        body: string,
      },
      owner: string,
      createdAt: string,
      updatedAt: string,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommunityInterviewRequestsByCommunityQueryVariables = {
  communityID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommunityInterviewRequestFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommunityInterviewRequestsByCommunityQuery = {
  communityInterviewRequestsByCommunity?:  {
    __typename: "ModelCommunityInterviewRequestConnection",
    items:  Array< {
      __typename: "CommunityInterviewRequest",
      id: string,
      communityID: string,
      userID: string,
      date?: string | null,
      status: CommunityInterviewStatus,
      message:  {
        __typename: "CommunityInterviewMessage",
        subject: string,
        body: string,
      },
      owner: string,
      createdAt: string,
      updatedAt: string,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommunityQuestionQueryVariables = {
  id: string,
};

export type GetCommunityQuestionQuery = {
  getCommunityQuestion?:  {
    __typename: "CommunityQuestion",
    id: string,
    communityID: string,
    userID: string,
    categoryID?: string | null,
    communityCategory?: string | null,
    date: string,
    status: CommunityQuestionStatus,
    source: CommunityQuestionSource,
    questionText: string,
    imageKeys?: Array< string > | null,
    clonedFromCommunityQuestionRecommendationId?: string | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
    communityQuestionAnswersByStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionLikesByStatus?:  {
      __typename: "ModelCommunityQuestionLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionLike",
        id: string,
        userID: string,
        communityQuestionID: string,
        status: CommunityQuestionLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListCommunityQuestionsQueryVariables = {
  filter?: ModelCommunityQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommunityQuestionsQuery = {
  listCommunityQuestions?:  {
    __typename: "ModelCommunityQuestionConnection",
    items:  Array< {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionsByUserQueryVariables = {
  userID?: string | null,
  statusDate?: ModelCommunityQuestionByUserByStatusByDateCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommunityQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionsByUserQuery = {
  questionsByUser?:  {
    __typename: "ModelCommunityQuestionConnection",
    items:  Array< {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type QuestionsByCommunityQueryVariables = {
  communityID?: string | null,
  statusDate?: ModelCommunityQuestionByCommunityByStatusByDateCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommunityQuestionFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type QuestionsByCommunityQuery = {
  questionsByCommunity?:  {
    __typename: "ModelCommunityQuestionConnection",
    items:  Array< {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommunityQuestionAnswerCommandQueryVariables = {
  id: string,
};

export type GetCommunityQuestionAnswerCommandQuery = {
  getCommunityQuestionAnswerCommand?:  {
    __typename: "CommunityQuestionAnswerCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityQuestionAnswerID?: string | null,
    communityQuestionID?: string | null,
    communityID?: string | null,
    userID?: string | null,
    status?: CommunityQuestionAnswerStatus | null,
    date?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCommunityQuestionAnswerCommandsQueryVariables = {
  filter?: ModelCommunityQuestionAnswerCommandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommunityQuestionAnswerCommandsQuery = {
  listCommunityQuestionAnswerCommands?:  {
    __typename: "ModelCommunityQuestionAnswerCommandConnection",
    items:  Array< {
      __typename: "CommunityQuestionAnswerCommand",
      id: string,
      command:  {
        __typename: "Command",
        operation: CommandOperation,
      },
      communityQuestionAnswerID?: string | null,
      communityQuestionID?: string | null,
      communityID?: string | null,
      userID?: string | null,
      status?: CommunityQuestionAnswerStatus | null,
      date?: string | null,
      content?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommunityQuestionAnswerQueryVariables = {
  id: string,
};

export type GetCommunityQuestionAnswerQuery = {
  getCommunityQuestionAnswer?:  {
    __typename: "CommunityQuestionAnswer",
    id: string,
    communityQuestionID: string,
    userID: string,
    communityID: string,
    status: CommunityQuestionAnswerStatus,
    date: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    communityQuestion:  {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    communityQuestionAnswerCommentsByStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerCommentConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerComment",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        content: string,
        status: CommunityQuestionAnswerCommentStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionAnswerLikesByStatus?:  {
      __typename: "ModelCommunityQuestionAnswerLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerLike",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        status: CommunityQuestionAnswerLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListCommunityQuestionAnswersQueryVariables = {
  filter?: ModelCommunityQuestionAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommunityQuestionAnswersQuery = {
  listCommunityQuestionAnswers?:  {
    __typename: "ModelCommunityQuestionAnswerConnection",
    items:  Array< {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AnswersByQuestionQueryVariables = {
  communityQuestionID?: string | null,
  statusDate?: ModelCommunityQuestionAnswerByCommunityQuestionByStatusByDateCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommunityQuestionAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AnswersByQuestionQuery = {
  answersByQuestion?:  {
    __typename: "ModelCommunityQuestionAnswerConnection",
    items:  Array< {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AnswersByUserQueryVariables = {
  userID?: string | null,
  statusDate?: ModelCommunityQuestionAnswerByUserByStatusByDateCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommunityQuestionAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AnswersByUserQuery = {
  answersByUser?:  {
    __typename: "ModelCommunityQuestionAnswerConnection",
    items:  Array< {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type AnswersByCommunityQueryVariables = {
  communityID?: string | null,
  statusDate?: ModelCommunityQuestionAnswerByCommunityByStatusByDateCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommunityQuestionAnswerFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type AnswersByCommunityQuery = {
  answersByCommunity?:  {
    __typename: "ModelCommunityQuestionAnswerConnection",
    items:  Array< {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommunityQuestionAnswerCommentCommandQueryVariables = {
  id: string,
};

export type GetCommunityQuestionAnswerCommentCommandQuery = {
  getCommunityQuestionAnswerCommentCommand?:  {
    __typename: "CommunityQuestionAnswerCommentCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityQuestionAnswerCommentID?: string | null,
    userID?: string | null,
    communityID?: string | null,
    communityQuestionAnswerID?: string | null,
    content?: string | null,
    status?: CommunityQuestionAnswerCommentStatus | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCommunityQuestionAnswerCommentCommandsQueryVariables = {
  filter?: ModelCommunityQuestionAnswerCommentCommandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommunityQuestionAnswerCommentCommandsQuery = {
  listCommunityQuestionAnswerCommentCommands?:  {
    __typename: "ModelCommunityQuestionAnswerCommentCommandConnection",
    items:  Array< {
      __typename: "CommunityQuestionAnswerCommentCommand",
      id: string,
      command:  {
        __typename: "Command",
        operation: CommandOperation,
      },
      communityQuestionAnswerCommentID?: string | null,
      userID?: string | null,
      communityID?: string | null,
      communityQuestionAnswerID?: string | null,
      content?: string | null,
      status?: CommunityQuestionAnswerCommentStatus | null,
      date?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommunityQuestionAnswerCommentQueryVariables = {
  id: string,
};

export type GetCommunityQuestionAnswerCommentQuery = {
  getCommunityQuestionAnswerComment?:  {
    __typename: "CommunityQuestionAnswerComment",
    id: string,
    userID: string,
    communityID: string,
    communityQuestionAnswerID: string,
    content: string,
    status: CommunityQuestionAnswerCommentStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestionAnswer:  {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListCommunityQuestionAnswerCommentsQueryVariables = {
  filter?: ModelCommunityQuestionAnswerCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommunityQuestionAnswerCommentsQuery = {
  listCommunityQuestionAnswerComments?:  {
    __typename: "ModelCommunityQuestionAnswerCommentConnection",
    items:  Array< {
      __typename: "CommunityQuestionAnswerComment",
      id: string,
      userID: string,
      communityID: string,
      communityQuestionAnswerID: string,
      content: string,
      status: CommunityQuestionAnswerCommentStatus,
      date: string,
      createdAt: string,
      updatedAt: string,
      communityQuestionAnswer:  {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommentsByAnswerQueryVariables = {
  communityQuestionAnswerID?: string | null,
  statusId?: ModelCommunityQuestionAnswerCommentByCommunityQuestionAnswerByStatusCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommunityQuestionAnswerCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByAnswerQuery = {
  commentsByAnswer?:  {
    __typename: "ModelCommunityQuestionAnswerCommentConnection",
    items:  Array< {
      __typename: "CommunityQuestionAnswerComment",
      id: string,
      userID: string,
      communityID: string,
      communityQuestionAnswerID: string,
      content: string,
      status: CommunityQuestionAnswerCommentStatus,
      date: string,
      createdAt: string,
      updatedAt: string,
      communityQuestionAnswer:  {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommentsByUserQueryVariables = {
  userID?: string | null,
  statusDate?: ModelCommunityQuestionAnswerCommentByUserByStatusByDateCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommunityQuestionAnswerCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByUserQuery = {
  commentsByUser?:  {
    __typename: "ModelCommunityQuestionAnswerCommentConnection",
    items:  Array< {
      __typename: "CommunityQuestionAnswerComment",
      id: string,
      userID: string,
      communityID: string,
      communityQuestionAnswerID: string,
      content: string,
      status: CommunityQuestionAnswerCommentStatus,
      date: string,
      createdAt: string,
      updatedAt: string,
      communityQuestionAnswer:  {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommentsByCommunityQueryVariables = {
  communityID?: string | null,
  statusDate?: ModelCommunityQuestionAnswerCommentByCommunityByStatusByDateCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommunityQuestionAnswerCommentFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommentsByCommunityQuery = {
  commentsByCommunity?:  {
    __typename: "ModelCommunityQuestionAnswerCommentConnection",
    items:  Array< {
      __typename: "CommunityQuestionAnswerComment",
      id: string,
      userID: string,
      communityID: string,
      communityQuestionAnswerID: string,
      content: string,
      status: CommunityQuestionAnswerCommentStatus,
      date: string,
      createdAt: string,
      updatedAt: string,
      communityQuestionAnswer:  {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommunityQuestionAnswerLikeQueryVariables = {
  id: string,
};

export type GetCommunityQuestionAnswerLikeQuery = {
  getCommunityQuestionAnswerLike?:  {
    __typename: "CommunityQuestionAnswerLike",
    id: string,
    userID: string,
    communityID: string,
    communityQuestionAnswerID: string,
    status: CommunityQuestionAnswerLikeStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestionAnswer:  {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListCommunityQuestionAnswerLikesQueryVariables = {
  filter?: ModelCommunityQuestionAnswerLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommunityQuestionAnswerLikesQuery = {
  listCommunityQuestionAnswerLikes?:  {
    __typename: "ModelCommunityQuestionAnswerLikeConnection",
    items:  Array< {
      __typename: "CommunityQuestionAnswerLike",
      id: string,
      userID: string,
      communityID: string,
      communityQuestionAnswerID: string,
      status: CommunityQuestionAnswerLikeStatus,
      date: string,
      createdAt: string,
      updatedAt: string,
      communityQuestionAnswer:  {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LikesByAnswerQueryVariables = {
  communityQuestionAnswerID?: string | null,
  statusId?: ModelCommunityQuestionAnswerLikeByCommunityQuestionAnswerByStatusCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommunityQuestionAnswerLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LikesByAnswerQuery = {
  likesByAnswer?:  {
    __typename: "ModelCommunityQuestionAnswerLikeConnection",
    items:  Array< {
      __typename: "CommunityQuestionAnswerLike",
      id: string,
      userID: string,
      communityID: string,
      communityQuestionAnswerID: string,
      status: CommunityQuestionAnswerLikeStatus,
      date: string,
      createdAt: string,
      updatedAt: string,
      communityQuestionAnswer:  {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommunityQuestionLikeQueryVariables = {
  id: string,
};

export type GetCommunityQuestionLikeQuery = {
  getCommunityQuestionLike?:  {
    __typename: "CommunityQuestionLike",
    id: string,
    userID: string,
    communityQuestionID: string,
    status: CommunityQuestionLikeStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestion:  {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListCommunityQuestionLikesQueryVariables = {
  filter?: ModelCommunityQuestionLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommunityQuestionLikesQuery = {
  listCommunityQuestionLikes?:  {
    __typename: "ModelCommunityQuestionLikeConnection",
    items:  Array< {
      __typename: "CommunityQuestionLike",
      id: string,
      userID: string,
      communityQuestionID: string,
      status: CommunityQuestionLikeStatus,
      date: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LikesByQuestionQueryVariables = {
  communityQuestionID?: string | null,
  statusId?: ModelCommunityQuestionLikeByCommunityQuestionByStatusCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommunityQuestionLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LikesByQuestionQuery = {
  likesByQuestion?:  {
    __typename: "ModelCommunityQuestionLikeConnection",
    items:  Array< {
      __typename: "CommunityQuestionLike",
      id: string,
      userID: string,
      communityQuestionID: string,
      status: CommunityQuestionLikeStatus,
      date: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type LikesByUserQueryVariables = {
  userID?: string | null,
  statusDate?: ModelCommunityQuestionAnswerLikeByUserByStatusByDateCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommunityQuestionAnswerLikeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type LikesByUserQuery = {
  likesByUser?:  {
    __typename: "ModelCommunityQuestionAnswerLikeConnection",
    items:  Array< {
      __typename: "CommunityQuestionAnswerLike",
      id: string,
      userID: string,
      communityID: string,
      communityQuestionAnswerID: string,
      status: CommunityQuestionAnswerLikeStatus,
      date: string,
      createdAt: string,
      updatedAt: string,
      communityQuestionAnswer:  {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommunityQuestionRecommendationQueryVariables = {
  id: string,
};

export type GetCommunityQuestionRecommendationQuery = {
  getCommunityQuestionRecommendation?:  {
    __typename: "CommunityQuestionRecommendation",
    id: string,
    communityID: string,
    categoryID: string,
    questionText: string,
    score: number,
    status?: CommunityQuestionRecommendationStatus | null,
    createdAt: string,
    updatedAt: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
  } | null,
};

export type ListCommunityQuestionRecommendationsQueryVariables = {
  filter?: ModelCommunityQuestionRecommendationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommunityQuestionRecommendationsQuery = {
  listCommunityQuestionRecommendations?:  {
    __typename: "ModelCommunityQuestionRecommendationConnection",
    items:  Array< {
      __typename: "CommunityQuestionRecommendation",
      id: string,
      communityID: string,
      categoryID: string,
      questionText: string,
      score: number,
      status?: CommunityQuestionRecommendationStatus | null,
      createdAt: string,
      updatedAt: string,
      category:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type RecommendationsByCommunityQueryVariables = {
  communityID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommunityQuestionRecommendationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type RecommendationsByCommunityQuery = {
  recommendationsByCommunity?:  {
    __typename: "ModelCommunityQuestionRecommendationConnection",
    items:  Array< {
      __typename: "CommunityQuestionRecommendation",
      id: string,
      communityID: string,
      categoryID: string,
      questionText: string,
      score: number,
      status?: CommunityQuestionRecommendationStatus | null,
      createdAt: string,
      updatedAt: string,
      category:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      },
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommunityUserCommandQueryVariables = {
  id: string,
};

export type GetCommunityUserCommandQuery = {
  getCommunityUserCommand?:  {
    __typename: "CommunityUserCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityUserId?: string | null,
    communityID?: string | null,
    userID?: string | null,
    joinedOn?: string | null,
    invitedByUserID?: string | null,
    invitedOn?: string | null,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    status?: CommunityUserStatus | null,
    relationshipToSpotlight?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListCommunityUserCommandsQueryVariables = {
  filter?: ModelCommunityUserCommandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommunityUserCommandsQuery = {
  listCommunityUserCommands?:  {
    __typename: "ModelCommunityUserCommandConnection",
    items:  Array< {
      __typename: "CommunityUserCommand",
      id: string,
      command:  {
        __typename: "Command",
        operation: CommandOperation,
      },
      communityUserId?: string | null,
      communityID?: string | null,
      userID?: string | null,
      joinedOn?: string | null,
      invitedByUserID?: string | null,
      invitedOn?: string | null,
      invitation?:  {
        __typename: "CommunityUserInvitation",
        invitationMessage: string,
      } | null,
      status?: CommunityUserStatus | null,
      relationshipToSpotlight?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetCommunityUserQueryVariables = {
  id: string,
};

export type GetCommunityUserQuery = {
  getCommunityUser?:  {
    __typename: "CommunityUser",
    id: string,
    communityID: string,
    userID: string,
    joinedOn: string,
    invitedByUserID: string,
    invitedOn: string,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    status: CommunityUserStatus,
    relationshipToSpotlight?: string | null,
    owner?: string | null,
    group?: string | null,
    createdAt: string,
    updatedAt: string,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    invitedByUser:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type ListCommunityUsersQueryVariables = {
  filter?: ModelCommunityUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommunityUsersQuery = {
  listCommunityUsers?:  {
    __typename: "ModelCommunityUserConnection",
    items:  Array< {
      __typename: "CommunityUser",
      id: string,
      communityID: string,
      userID: string,
      joinedOn: string,
      invitedByUserID: string,
      invitedOn: string,
      invitation?:  {
        __typename: "CommunityUserInvitation",
        invitationMessage: string,
      } | null,
      status: CommunityUserStatus,
      relationshipToSpotlight?: string | null,
      owner?: string | null,
      group?: string | null,
      createdAt: string,
      updatedAt: string,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      invitedByUser:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type CommunitiesByUserQueryVariables = {
  userID?: string | null,
  statusJoinedOn?: ModelCommunityUserByUserByStatusByJoinDateCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommunityUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type CommunitiesByUserQuery = {
  communitiesByUser?:  {
    __typename: "ModelCommunityUserConnection",
    items:  Array< {
      __typename: "CommunityUser",
      id: string,
      communityID: string,
      userID: string,
      joinedOn: string,
      invitedByUserID: string,
      invitedOn: string,
      invitation?:  {
        __typename: "CommunityUserInvitation",
        invitationMessage: string,
      } | null,
      status: CommunityUserStatus,
      relationshipToSpotlight?: string | null,
      owner?: string | null,
      group?: string | null,
      createdAt: string,
      updatedAt: string,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      invitedByUser:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByCommunityQueryVariables = {
  communityID?: string | null,
  statusJoinedOn?: ModelCommunityUserByCommunityByStatusByJoinDateCompositeKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelCommunityUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByCommunityQuery = {
  usersByCommunity?:  {
    __typename: "ModelCommunityUserConnection",
    items:  Array< {
      __typename: "CommunityUser",
      id: string,
      communityID: string,
      userID: string,
      joinedOn: string,
      invitedByUserID: string,
      invitedOn: string,
      invitation?:  {
        __typename: "CommunityUserInvitation",
        invitationMessage: string,
      } | null,
      status: CommunityUserStatus,
      relationshipToSpotlight?: string | null,
      owner?: string | null,
      group?: string | null,
      createdAt: string,
      updatedAt: string,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      invitedByUser:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetInvitationQueryVariables = {
  id: string,
};

export type GetInvitationQuery = {
  getInvitation?:  {
    __typename: "Invitation",
    id: string,
    email: string,
    toNotify: boolean,
    status: InvitationStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListInvitationsQueryVariables = {
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListInvitationsQuery = {
  listInvitations?:  {
    __typename: "ModelInvitationConnection",
    items:  Array< {
      __typename: "Invitation",
      id: string,
      email: string,
      toNotify: boolean,
      status: InvitationStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type InvitationsByEmailByStatusQueryVariables = {
  email?: string | null,
  status?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelInvitationFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type InvitationsByEmailByStatusQuery = {
  invitationsByEmailByStatus?:  {
    __typename: "ModelInvitationConnection",
    items:  Array< {
      __typename: "Invitation",
      id: string,
      email: string,
      toNotify: boolean,
      status: InvitationStatus,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUsernameToUserIdQueryVariables = {
  username: string,
};

export type GetUsernameToUserIdQuery = {
  getUsernameToUserId?:  {
    __typename: "UsernameToUserId",
    username: string,
    userID: string,
  } | null,
};

export type ListUsernameToUserIdsQueryVariables = {
  username?: string | null,
  filter?: ModelUsernameToUserIdFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUsernameToUserIdsQuery = {
  listUsernameToUserIds?:  {
    __typename: "ModelUsernameToUserIdConnection",
    items:  Array< {
      __typename: "UsernameToUserId",
      username: string,
      userID: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUsernameByUserIdQueryVariables = {
  userID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUsernameToUserIdFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetUsernameByUserIdQuery = {
  getUsernameByUserId?:  {
    __typename: "ModelUsernameToUserIdConnection",
    items:  Array< {
      __typename: "UsernameToUserId",
      username: string,
      userID: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserIdToIdentityIdQueryVariables = {
  userID: string,
};

export type GetUserIdToIdentityIdQuery = {
  getUserIdToIdentityId?:  {
    __typename: "UserIdToIdentityId",
    userID: string,
    identityID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserIdToIdentityIdsQueryVariables = {
  userID?: string | null,
  filter?: ModelUserIdToIdentityIdFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserIdToIdentityIdsQuery = {
  listUserIdToIdentityIds?:  {
    __typename: "ModelUserIdToIdentityIdConnection",
    items:  Array< {
      __typename: "UserIdToIdentityId",
      userID: string,
      identityID: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserCommandQueryVariables = {
  id: string,
};

export type GetUserCommandQuery = {
  getUserCommand?:  {
    __typename: "UserCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    userID?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: UserStatus | null,
    profileImageKey?: string | null,
    communityLimits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    welcomesSeen?: Array< string | null > | null,
    helpsSeen?: Array< string | null > | null,
    username?: string | null,
    identityID?: string | null,
    referralCode?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListUserCommandsQueryVariables = {
  filter?: ModelUserCommandFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUserCommandsQuery = {
  listUserCommands?:  {
    __typename: "ModelUserCommandConnection",
    items:  Array< {
      __typename: "UserCommand",
      id: string,
      command:  {
        __typename: "Command",
        operation: CommandOperation,
      },
      userID?: string | null,
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string | null > | null,
      helpsSeen?: Array< string | null > | null,
      username?: string | null,
      identityID?: string | null,
      referralCode?: string | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    email?: string | null,
    status?: UserStatus | null,
    profileImageKey?: string | null,
    communityLimits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    welcomesSeen?: Array< string > | null,
    helpsSeen?: Array< string > | null,
    nextSteps?:  Array< {
      __typename: "CommunityNextStep",
      communityID: string,
      nextStepsByRole?:  {
        __typename: "NextStepsByRole",
        INVITED?: Array< NextStepType > | null,
        MEMBER?: Array< NextStepType > | null,
        ORGANIZER?: Array< NextStepType > | null,
        SUBJECT?: Array< NextStepType > | null,
        REMOVED?: Array< NextStepType > | null,
        BANNED?: Array< NextStepType > | null,
        ADMIN?: Array< NextStepType > | null,
      } | null,
    } > | null,
    referralCode?: string | null,
    referredByUserID?: string | null,
    username?: string | null,
    identityID?: string | null,
    createdAt: string,
    updatedAt: string,
    communityQuestionsByStatusDate?:  {
      __typename: "ModelCommunityQuestionConnection",
      items:  Array< {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionAnswersByUserStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionlikesByStatusDate?:  {
      __typename: "ModelCommunityQuestionLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionLike",
        id: string,
        userID: string,
        communityQuestionID: string,
        status: CommunityQuestionLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityUsersByStatusDate?:  {
      __typename: "ModelCommunityUserConnection",
      items:  Array< {
        __typename: "CommunityUser",
        id: string,
        communityID: string,
        userID: string,
        joinedOn: string,
        invitedByUserID: string,
        invitedOn: string,
        invitation?:  {
          __typename: "CommunityUserInvitation",
          invitationMessage: string,
        } | null,
        status: CommunityUserStatus,
        relationshipToSpotlight?: string | null,
        owner?: string | null,
        group?: string | null,
        createdAt: string,
        updatedAt: string,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        invitedByUser:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByEmailQueryVariables = {
  email?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByEmailQuery = {
  usersByEmail?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByUsernameQueryVariables = {
  username?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByUsernameQuery = {
  usersByUsername?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByIdentityIdQueryVariables = {
  identityID?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByIdentityIdQuery = {
  usersByIdentityId?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type UsersByReferralCodeQueryVariables = {
  referralCode?: string | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type UsersByReferralCodeQuery = {
  usersByReferralCode?:  {
    __typename: "ModelUserConnection",
    items:  Array< {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserGoogleTokensQueryVariables = {
  owner: string,
};

export type GetUserGoogleTokensQuery = {
  getUserGoogleTokens?:  {
    __typename: "UserGoogleTokens",
    owner: string,
    accessToken: string,
    refreshToken: string,
    scopes?: Array< string > | null,
    expiresOn: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserGoogleTokensQueryVariables = {
  owner?: string | null,
  filter?: ModelUserGoogleTokensFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserGoogleTokensQuery = {
  listUserGoogleTokens?:  {
    __typename: "ModelUserGoogleTokensConnection",
    items:  Array< {
      __typename: "UserGoogleTokens",
      owner: string,
      accessToken: string,
      refreshToken: string,
      scopes?: Array< string > | null,
      expiresOn: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetUserReferralCodeQueryVariables = {
  referralCode: string,
};

export type GetUserReferralCodeQuery = {
  getUserReferralCode?:  {
    __typename: "UserReferralCode",
    referralCode: string,
    firstName: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type ListUserReferralCodesQueryVariables = {
  referralCode?: string | null,
  filter?: ModelUserReferralCodeFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
  sortDirection?: ModelSortDirection | null,
};

export type ListUserReferralCodesQuery = {
  listUserReferralCodes?:  {
    __typename: "ModelUserReferralCodeConnection",
    items:  Array< {
      __typename: "UserReferralCode",
      referralCode: string,
      firstName: string,
      createdAt: string,
      updatedAt: string,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type GetXAssignEmailToPlaceholderQueryVariables = {
  id: string,
};

export type GetXAssignEmailToPlaceholderQuery = {
  getXAssignEmailToPlaceholder?:  {
    __typename: "xAssignEmailToPlaceholder",
    id: string,
    status: OperationStatus,
    reason?: string | null,
    communityUserID: string,
    userID: string,
    communityID: string,
    email: string,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type ListXAssignEmailToPlaceholdersQueryVariables = {
  filter?: ModelxAssignEmailToPlaceholderFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListXAssignEmailToPlaceholdersQuery = {
  listXAssignEmailToPlaceholders?:  {
    __typename: "ModelxAssignEmailToPlaceholderConnection",
    items:  Array< {
      __typename: "xAssignEmailToPlaceholder",
      id: string,
      status: OperationStatus,
      reason?: string | null,
      communityUserID: string,
      userID: string,
      communityID: string,
      email: string,
      invitation?:  {
        __typename: "CommunityUserInvitation",
        invitationMessage: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null >,
    nextToken?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  email?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    email?: string | null,
    status?: UserStatus | null,
    profileImageKey?: string | null,
    communityLimits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    welcomesSeen?: Array< string > | null,
    helpsSeen?: Array< string > | null,
    nextSteps?:  Array< {
      __typename: "CommunityNextStep",
      communityID: string,
      nextStepsByRole?:  {
        __typename: "NextStepsByRole",
        INVITED?: Array< NextStepType > | null,
        MEMBER?: Array< NextStepType > | null,
        ORGANIZER?: Array< NextStepType > | null,
        SUBJECT?: Array< NextStepType > | null,
        REMOVED?: Array< NextStepType > | null,
        BANNED?: Array< NextStepType > | null,
        ADMIN?: Array< NextStepType > | null,
      } | null,
    } > | null,
    referralCode?: string | null,
    referredByUserID?: string | null,
    username?: string | null,
    identityID?: string | null,
    createdAt: string,
    updatedAt: string,
    communityQuestionsByStatusDate?:  {
      __typename: "ModelCommunityQuestionConnection",
      items:  Array< {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionAnswersByUserStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionlikesByStatusDate?:  {
      __typename: "ModelCommunityQuestionLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionLike",
        id: string,
        userID: string,
        communityQuestionID: string,
        status: CommunityQuestionLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityUsersByStatusDate?:  {
      __typename: "ModelCommunityUserConnection",
      items:  Array< {
        __typename: "CommunityUser",
        id: string,
        communityID: string,
        userID: string,
        joinedOn: string,
        invitedByUserID: string,
        invitedOn: string,
        invitation?:  {
          __typename: "CommunityUserInvitation",
          invitationMessage: string,
        } | null,
        status: CommunityUserStatus,
        relationshipToSpotlight?: string | null,
        owner?: string | null,
        group?: string | null,
        createdAt: string,
        updatedAt: string,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        invitedByUser:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    email?: string | null,
    status?: UserStatus | null,
    profileImageKey?: string | null,
    communityLimits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    welcomesSeen?: Array< string > | null,
    helpsSeen?: Array< string > | null,
    nextSteps?:  Array< {
      __typename: "CommunityNextStep",
      communityID: string,
      nextStepsByRole?:  {
        __typename: "NextStepsByRole",
        INVITED?: Array< NextStepType > | null,
        MEMBER?: Array< NextStepType > | null,
        ORGANIZER?: Array< NextStepType > | null,
        SUBJECT?: Array< NextStepType > | null,
        REMOVED?: Array< NextStepType > | null,
        BANNED?: Array< NextStepType > | null,
        ADMIN?: Array< NextStepType > | null,
      } | null,
    } > | null,
    referralCode?: string | null,
    referredByUserID?: string | null,
    username?: string | null,
    identityID?: string | null,
    createdAt: string,
    updatedAt: string,
    communityQuestionsByStatusDate?:  {
      __typename: "ModelCommunityQuestionConnection",
      items:  Array< {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionAnswersByUserStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionlikesByStatusDate?:  {
      __typename: "ModelCommunityQuestionLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionLike",
        id: string,
        userID: string,
        communityQuestionID: string,
        status: CommunityQuestionLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityUsersByStatusDate?:  {
      __typename: "ModelCommunityUserConnection",
      items:  Array< {
        __typename: "CommunityUser",
        id: string,
        communityID: string,
        userID: string,
        joinedOn: string,
        invitedByUserID: string,
        invitedOn: string,
        invitation?:  {
          __typename: "CommunityUserInvitation",
          invitationMessage: string,
        } | null,
        status: CommunityUserStatus,
        relationshipToSpotlight?: string | null,
        owner?: string | null,
        group?: string | null,
        createdAt: string,
        updatedAt: string,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        invitedByUser:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  id?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    firstName: string,
    lastName: string,
    email?: string | null,
    status?: UserStatus | null,
    profileImageKey?: string | null,
    communityLimits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    welcomesSeen?: Array< string > | null,
    helpsSeen?: Array< string > | null,
    nextSteps?:  Array< {
      __typename: "CommunityNextStep",
      communityID: string,
      nextStepsByRole?:  {
        __typename: "NextStepsByRole",
        INVITED?: Array< NextStepType > | null,
        MEMBER?: Array< NextStepType > | null,
        ORGANIZER?: Array< NextStepType > | null,
        SUBJECT?: Array< NextStepType > | null,
        REMOVED?: Array< NextStepType > | null,
        BANNED?: Array< NextStepType > | null,
        ADMIN?: Array< NextStepType > | null,
      } | null,
    } > | null,
    referralCode?: string | null,
    referredByUserID?: string | null,
    username?: string | null,
    identityID?: string | null,
    createdAt: string,
    updatedAt: string,
    communityQuestionsByStatusDate?:  {
      __typename: "ModelCommunityQuestionConnection",
      items:  Array< {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionAnswersByUserStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionlikesByStatusDate?:  {
      __typename: "ModelCommunityQuestionLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionLike",
        id: string,
        userID: string,
        communityQuestionID: string,
        status: CommunityQuestionLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityUsersByStatusDate?:  {
      __typename: "ModelCommunityUserConnection",
      items:  Array< {
        __typename: "CommunityUser",
        id: string,
        communityID: string,
        userID: string,
        joinedOn: string,
        invitedByUserID: string,
        invitedOn: string,
        invitation?:  {
          __typename: "CommunityUserInvitation",
          invitationMessage: string,
        } | null,
        status: CommunityUserStatus,
        relationshipToSpotlight?: string | null,
        owner?: string | null,
        group?: string | null,
        createdAt: string,
        updatedAt: string,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        invitedByUser:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateCommunitySubscriptionVariables = {
  id?: string | null,
};

export type OnCreateCommunitySubscription = {
  onCreateCommunity?:  {
    __typename: "Community",
    id: string,
    name: string,
    imageKey?: string | null,
    type?: CommunityType | null,
    allowPublicPages?: boolean | null,
    alias?: string | null,
    publicPreviewHeaderURL?: string | null,
    publicPreviewDescription?: string | null,
    requiresModeration?: boolean | null,
    allowUnauthenticatedAnswers?: boolean | null,
    limits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    vimeoVideos?: Array< string > | null,
    communityCategories?: Array< string > | null,
    interviewTab?:  {
      __typename: "InterviewTab",
      header: string,
      bodyLine1: string,
      bodyLine2: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    communityQuestionsByStatusDate?:  {
      __typename: "ModelCommunityQuestionConnection",
      items:  Array< {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionRecommendations?:  {
      __typename: "ModelCommunityQuestionRecommendationConnection",
      items:  Array< {
        __typename: "CommunityQuestionRecommendation",
        id: string,
        communityID: string,
        categoryID: string,
        questionText: string,
        score: number,
        status?: CommunityQuestionRecommendationStatus | null,
        createdAt: string,
        updatedAt: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    communityUsersByStatusDate?:  {
      __typename: "ModelCommunityUserConnection",
      items:  Array< {
        __typename: "CommunityUser",
        id: string,
        communityID: string,
        userID: string,
        joinedOn: string,
        invitedByUserID: string,
        invitedOn: string,
        invitation?:  {
          __typename: "CommunityUserInvitation",
          invitationMessage: string,
        } | null,
        status: CommunityUserStatus,
        relationshipToSpotlight?: string | null,
        owner?: string | null,
        group?: string | null,
        createdAt: string,
        updatedAt: string,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        invitedByUser:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateCommunitySubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateCommunitySubscription = {
  onUpdateCommunity?:  {
    __typename: "Community",
    id: string,
    name: string,
    imageKey?: string | null,
    type?: CommunityType | null,
    allowPublicPages?: boolean | null,
    alias?: string | null,
    publicPreviewHeaderURL?: string | null,
    publicPreviewDescription?: string | null,
    requiresModeration?: boolean | null,
    allowUnauthenticatedAnswers?: boolean | null,
    limits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    vimeoVideos?: Array< string > | null,
    communityCategories?: Array< string > | null,
    interviewTab?:  {
      __typename: "InterviewTab",
      header: string,
      bodyLine1: string,
      bodyLine2: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    communityQuestionsByStatusDate?:  {
      __typename: "ModelCommunityQuestionConnection",
      items:  Array< {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionRecommendations?:  {
      __typename: "ModelCommunityQuestionRecommendationConnection",
      items:  Array< {
        __typename: "CommunityQuestionRecommendation",
        id: string,
        communityID: string,
        categoryID: string,
        questionText: string,
        score: number,
        status?: CommunityQuestionRecommendationStatus | null,
        createdAt: string,
        updatedAt: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    communityUsersByStatusDate?:  {
      __typename: "ModelCommunityUserConnection",
      items:  Array< {
        __typename: "CommunityUser",
        id: string,
        communityID: string,
        userID: string,
        joinedOn: string,
        invitedByUserID: string,
        invitedOn: string,
        invitation?:  {
          __typename: "CommunityUserInvitation",
          invitationMessage: string,
        } | null,
        status: CommunityUserStatus,
        relationshipToSpotlight?: string | null,
        owner?: string | null,
        group?: string | null,
        createdAt: string,
        updatedAt: string,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        invitedByUser:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnDeleteCommunitySubscriptionVariables = {
  id?: string | null,
};

export type OnDeleteCommunitySubscription = {
  onDeleteCommunity?:  {
    __typename: "Community",
    id: string,
    name: string,
    imageKey?: string | null,
    type?: CommunityType | null,
    allowPublicPages?: boolean | null,
    alias?: string | null,
    publicPreviewHeaderURL?: string | null,
    publicPreviewDescription?: string | null,
    requiresModeration?: boolean | null,
    allowUnauthenticatedAnswers?: boolean | null,
    limits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    vimeoVideos?: Array< string > | null,
    communityCategories?: Array< string > | null,
    interviewTab?:  {
      __typename: "InterviewTab",
      header: string,
      bodyLine1: string,
      bodyLine2: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
    communityQuestionsByStatusDate?:  {
      __typename: "ModelCommunityQuestionConnection",
      items:  Array< {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionRecommendations?:  {
      __typename: "ModelCommunityQuestionRecommendationConnection",
      items:  Array< {
        __typename: "CommunityQuestionRecommendation",
        id: string,
        communityID: string,
        categoryID: string,
        questionText: string,
        score: number,
        status?: CommunityQuestionRecommendationStatus | null,
        createdAt: string,
        updatedAt: string,
        category:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
      } | null >,
      nextToken?: string | null,
    } | null,
    communityUsersByStatusDate?:  {
      __typename: "ModelCommunityUserConnection",
      items:  Array< {
        __typename: "CommunityUser",
        id: string,
        communityID: string,
        userID: string,
        joinedOn: string,
        invitedByUserID: string,
        invitedOn: string,
        invitation?:  {
          __typename: "CommunityUserInvitation",
          invitationMessage: string,
        } | null,
        status: CommunityUserStatus,
        relationshipToSpotlight?: string | null,
        owner?: string | null,
        group?: string | null,
        createdAt: string,
        updatedAt: string,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
        invitedByUser:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateCommunityUserSubscription = {
  onCreateCommunityUser?:  {
    __typename: "CommunityUser",
    id: string,
    communityID: string,
    userID: string,
    joinedOn: string,
    invitedByUserID: string,
    invitedOn: string,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    status: CommunityUserStatus,
    relationshipToSpotlight?: string | null,
    owner?: string | null,
    group?: string | null,
    createdAt: string,
    updatedAt: string,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    invitedByUser:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateCommunityUserSubscription = {
  onUpdateCommunityUser?:  {
    __typename: "CommunityUser",
    id: string,
    communityID: string,
    userID: string,
    joinedOn: string,
    invitedByUserID: string,
    invitedOn: string,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    status: CommunityUserStatus,
    relationshipToSpotlight?: string | null,
    owner?: string | null,
    group?: string | null,
    createdAt: string,
    updatedAt: string,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    invitedByUser:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteCommunityUserSubscription = {
  onDeleteCommunityUser?:  {
    __typename: "CommunityUser",
    id: string,
    communityID: string,
    userID: string,
    joinedOn: string,
    invitedByUserID: string,
    invitedOn: string,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    status: CommunityUserStatus,
    relationshipToSpotlight?: string | null,
    owner?: string | null,
    group?: string | null,
    createdAt: string,
    updatedAt: string,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    invitedByUser:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateCommunityUserByUserIdSubscriptionVariables = {
  userID?: string | null,
};

export type OnCreateCommunityUserByUserIdSubscription = {
  onCreateCommunityUserByUserId?:  {
    __typename: "CommunityUser",
    id: string,
    communityID: string,
    userID: string,
    joinedOn: string,
    invitedByUserID: string,
    invitedOn: string,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    status: CommunityUserStatus,
    relationshipToSpotlight?: string | null,
    owner?: string | null,
    group?: string | null,
    createdAt: string,
    updatedAt: string,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    invitedByUser:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateCommunityUserByUserIdSubscriptionVariables = {
  userID?: string | null,
};

export type OnUpdateCommunityUserByUserIdSubscription = {
  onUpdateCommunityUserByUserId?:  {
    __typename: "CommunityUser",
    id: string,
    communityID: string,
    userID: string,
    joinedOn: string,
    invitedByUserID: string,
    invitedOn: string,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    status: CommunityUserStatus,
    relationshipToSpotlight?: string | null,
    owner?: string | null,
    group?: string | null,
    createdAt: string,
    updatedAt: string,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    invitedByUser:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteCommunityUserByUserIdSubscriptionVariables = {
  userID?: string | null,
};

export type OnDeleteCommunityUserByUserIdSubscription = {
  onDeleteCommunityUserByUserId?:  {
    __typename: "CommunityUser",
    id: string,
    communityID: string,
    userID: string,
    joinedOn: string,
    invitedByUserID: string,
    invitedOn: string,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    status: CommunityUserStatus,
    relationshipToSpotlight?: string | null,
    owner?: string | null,
    group?: string | null,
    createdAt: string,
    updatedAt: string,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    invitedByUser:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateCommunityInterviewRequestSubscriptionVariables = {
  communityID?: string | null,
};

export type OnCreateCommunityInterviewRequestSubscription = {
  onCreateCommunityInterviewRequest?:  {
    __typename: "CommunityInterviewRequest",
    id: string,
    communityID: string,
    userID: string,
    date?: string | null,
    status: CommunityInterviewStatus,
    message:  {
      __typename: "CommunityInterviewMessage",
      subject: string,
      body: string,
    },
    owner: string,
    createdAt: string,
    updatedAt: string,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateCommunityInterviewRequestSubscriptionVariables = {
  id?: string | null,
  communityID?: string | null,
};

export type OnUpdateCommunityInterviewRequestSubscription = {
  onUpdateCommunityInterviewRequest?:  {
    __typename: "CommunityInterviewRequest",
    id: string,
    communityID: string,
    userID: string,
    date?: string | null,
    status: CommunityInterviewStatus,
    message:  {
      __typename: "CommunityInterviewMessage",
      subject: string,
      body: string,
    },
    owner: string,
    createdAt: string,
    updatedAt: string,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteCommunityInterviewRequestSubscriptionVariables = {
  id?: string | null,
  communityID?: string | null,
};

export type OnDeleteCommunityInterviewRequestSubscription = {
  onDeleteCommunityInterviewRequest?:  {
    __typename: "CommunityInterviewRequest",
    id: string,
    communityID: string,
    userID: string,
    date?: string | null,
    status: CommunityInterviewStatus,
    message:  {
      __typename: "CommunityInterviewMessage",
      subject: string,
      body: string,
    },
    owner: string,
    createdAt: string,
    updatedAt: string,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateXAssignEmailToPlaceholderSubscriptionVariables = {
  communityUserID?: string | null,
};

export type OnCreateXAssignEmailToPlaceholderSubscription = {
  onCreateXAssignEmailToPlaceholder?:  {
    __typename: "xAssignEmailToPlaceholder",
    id: string,
    status: OperationStatus,
    reason?: string | null,
    communityUserID: string,
    userID: string,
    communityID: string,
    email: string,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateXAssignEmailToPlaceholderSubscriptionVariables = {
  id?: string | null,
};

export type OnUpdateXAssignEmailToPlaceholderSubscription = {
  onUpdateXAssignEmailToPlaceholder?:  {
    __typename: "xAssignEmailToPlaceholder",
    id: string,
    status: OperationStatus,
    reason?: string | null,
    communityUserID: string,
    userID: string,
    communityID: string,
    email: string,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteXAssignEmailToPlaceholderSubscriptionVariables = {
  id?: string | null,
};

export type OnDeleteXAssignEmailToPlaceholderSubscription = {
  onDeleteXAssignEmailToPlaceholder?:  {
    __typename: "xAssignEmailToPlaceholder",
    id: string,
    status: OperationStatus,
    reason?: string | null,
    communityUserID: string,
    userID: string,
    communityID: string,
    email: string,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCommunityQuestionAnswerSubscription = {
  onCreateCommunityQuestionAnswer?:  {
    __typename: "CommunityQuestionAnswer",
    id: string,
    communityQuestionID: string,
    userID: string,
    communityID: string,
    status: CommunityQuestionAnswerStatus,
    date: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    communityQuestion:  {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    communityQuestionAnswerCommentsByStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerCommentConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerComment",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        content: string,
        status: CommunityQuestionAnswerCommentStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionAnswerLikesByStatus?:  {
      __typename: "ModelCommunityQuestionAnswerLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerLike",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        status: CommunityQuestionAnswerLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateCommunityQuestionAnswerSubscription = {
  onUpdateCommunityQuestionAnswer?:  {
    __typename: "CommunityQuestionAnswer",
    id: string,
    communityQuestionID: string,
    userID: string,
    communityID: string,
    status: CommunityQuestionAnswerStatus,
    date: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    communityQuestion:  {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    communityQuestionAnswerCommentsByStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerCommentConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerComment",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        content: string,
        status: CommunityQuestionAnswerCommentStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionAnswerLikesByStatus?:  {
      __typename: "ModelCommunityQuestionAnswerLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerLike",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        status: CommunityQuestionAnswerLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteCommunityQuestionAnswerSubscription = {
  onDeleteCommunityQuestionAnswer?:  {
    __typename: "CommunityQuestionAnswer",
    id: string,
    communityQuestionID: string,
    userID: string,
    communityID: string,
    status: CommunityQuestionAnswerStatus,
    date: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    communityQuestion:  {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    communityQuestionAnswerCommentsByStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerCommentConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerComment",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        content: string,
        status: CommunityQuestionAnswerCommentStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionAnswerLikesByStatus?:  {
      __typename: "ModelCommunityQuestionAnswerLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerLike",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        status: CommunityQuestionAnswerLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateCommunityQuestionAnswerByQuestionIdSubscriptionVariables = {
  communityQuestionID?: string | null,
};

export type OnCreateCommunityQuestionAnswerByQuestionIdSubscription = {
  onCreateCommunityQuestionAnswerByQuestionId?:  {
    __typename: "CommunityQuestionAnswer",
    id: string,
    communityQuestionID: string,
    userID: string,
    communityID: string,
    status: CommunityQuestionAnswerStatus,
    date: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    communityQuestion:  {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    communityQuestionAnswerCommentsByStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerCommentConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerComment",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        content: string,
        status: CommunityQuestionAnswerCommentStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionAnswerLikesByStatus?:  {
      __typename: "ModelCommunityQuestionAnswerLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerLike",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        status: CommunityQuestionAnswerLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateCommunityQuestionAnswerByQuestionIdSubscriptionVariables = {
  communityQuestionID?: string | null,
};

export type OnUpdateCommunityQuestionAnswerByQuestionIdSubscription = {
  onUpdateCommunityQuestionAnswerByQuestionId?:  {
    __typename: "CommunityQuestionAnswer",
    id: string,
    communityQuestionID: string,
    userID: string,
    communityID: string,
    status: CommunityQuestionAnswerStatus,
    date: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    communityQuestion:  {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    communityQuestionAnswerCommentsByStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerCommentConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerComment",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        content: string,
        status: CommunityQuestionAnswerCommentStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionAnswerLikesByStatus?:  {
      __typename: "ModelCommunityQuestionAnswerLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerLike",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        status: CommunityQuestionAnswerLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteCommunityQuestionAnswerByQuestionIdSubscriptionVariables = {
  communityQuestionID?: string | null,
};

export type OnDeleteCommunityQuestionAnswerByQuestionIdSubscription = {
  onDeleteCommunityQuestionAnswerByQuestionId?:  {
    __typename: "CommunityQuestionAnswer",
    id: string,
    communityQuestionID: string,
    userID: string,
    communityID: string,
    status: CommunityQuestionAnswerStatus,
    date: string,
    content: string,
    createdAt: string,
    updatedAt: string,
    communityQuestion:  {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    communityQuestionAnswerCommentsByStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerCommentConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerComment",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        content: string,
        status: CommunityQuestionAnswerCommentStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionAnswerLikesByStatus?:  {
      __typename: "ModelCommunityQuestionAnswerLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswerLike",
        id: string,
        userID: string,
        communityID: string,
        communityQuestionAnswerID: string,
        status: CommunityQuestionAnswerLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestionAnswer:  {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateCommunityQuestionAnswerCommentSubscription = {
  onCreateCommunityQuestionAnswerComment?:  {
    __typename: "CommunityQuestionAnswerComment",
    id: string,
    userID: string,
    communityID: string,
    communityQuestionAnswerID: string,
    content: string,
    status: CommunityQuestionAnswerCommentStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestionAnswer:  {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateCommunityQuestionAnswerCommentSubscription = {
  onUpdateCommunityQuestionAnswerComment?:  {
    __typename: "CommunityQuestionAnswerComment",
    id: string,
    userID: string,
    communityID: string,
    communityQuestionAnswerID: string,
    content: string,
    status: CommunityQuestionAnswerCommentStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestionAnswer:  {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteCommunityQuestionAnswerCommentSubscription = {
  onDeleteCommunityQuestionAnswerComment?:  {
    __typename: "CommunityQuestionAnswerComment",
    id: string,
    userID: string,
    communityID: string,
    communityQuestionAnswerID: string,
    content: string,
    status: CommunityQuestionAnswerCommentStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestionAnswer:  {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateCommunityQuestionAnswerCommentByAnswerIdSubscriptionVariables = {
  communityQuestionAnswerID?: string | null,
};

export type OnCreateCommunityQuestionAnswerCommentByAnswerIdSubscription = {
  onCreateCommunityQuestionAnswerCommentByAnswerId?:  {
    __typename: "CommunityQuestionAnswerComment",
    id: string,
    userID: string,
    communityID: string,
    communityQuestionAnswerID: string,
    content: string,
    status: CommunityQuestionAnswerCommentStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestionAnswer:  {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateCommunityQuestionAnswerCommentByAnswerIdSubscriptionVariables = {
  communityQuestionAnswerID?: string | null,
};

export type OnUpdateCommunityQuestionAnswerCommentByAnswerIdSubscription = {
  onUpdateCommunityQuestionAnswerCommentByAnswerId?:  {
    __typename: "CommunityQuestionAnswerComment",
    id: string,
    userID: string,
    communityID: string,
    communityQuestionAnswerID: string,
    content: string,
    status: CommunityQuestionAnswerCommentStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestionAnswer:  {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteCommunityQuestionAnswerCommentByAnswerIdSubscriptionVariables = {
  communityQuestionAnswerID?: string | null,
};

export type OnDeleteCommunityQuestionAnswerCommentByAnswerIdSubscription = {
  onDeleteCommunityQuestionAnswerCommentByAnswerId?:  {
    __typename: "CommunityQuestionAnswerComment",
    id: string,
    userID: string,
    communityID: string,
    communityQuestionAnswerID: string,
    content: string,
    status: CommunityQuestionAnswerCommentStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestionAnswer:  {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreatePacemakerLastRunSubscription = {
  onCreatePacemakerLastRun?:  {
    __typename: "PacemakerLastRun",
    id: string,
    entries:  Array< {
      __typename: "PacemakerRunEntry",
      job: string,
      timestamp: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdatePacemakerLastRunSubscription = {
  onUpdatePacemakerLastRun?:  {
    __typename: "PacemakerLastRun",
    id: string,
    entries:  Array< {
      __typename: "PacemakerRunEntry",
      job: string,
      timestamp: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeletePacemakerLastRunSubscription = {
  onDeletePacemakerLastRun?:  {
    __typename: "PacemakerLastRun",
    id: string,
    entries:  Array< {
      __typename: "PacemakerRunEntry",
      job: string,
      timestamp: string,
    } >,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateZAnswerCreatedNotifySigSubscription = {
  onCreateZAnswerCreatedNotifySig?:  {
    __typename: "zAnswerCreatedNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateZAnswerCreatedNotifySigSubscription = {
  onUpdateZAnswerCreatedNotifySig?:  {
    __typename: "zAnswerCreatedNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteZAnswerCreatedNotifySigSubscription = {
  onDeleteZAnswerCreatedNotifySig?:  {
    __typename: "zAnswerCreatedNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateZAnswerCreatedNotifyBacklogSubscription = {
  onCreateZAnswerCreatedNotifyBacklog?:  {
    __typename: "zAnswerCreatedNotifyBacklog",
    communityID: string,
    communityQuestionAnswerID: string,
    communityQuestionAnswerCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateZAnswerCreatedNotifyBacklogSubscription = {
  onUpdateZAnswerCreatedNotifyBacklog?:  {
    __typename: "zAnswerCreatedNotifyBacklog",
    communityID: string,
    communityQuestionAnswerID: string,
    communityQuestionAnswerCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteZAnswerCreatedNotifyBacklogSubscription = {
  onDeleteZAnswerCreatedNotifyBacklog?:  {
    __typename: "zAnswerCreatedNotifyBacklog",
    communityID: string,
    communityQuestionAnswerID: string,
    communityQuestionAnswerCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateZAnswerCreatedNotifyCompleteSubscription = {
  onCreateZAnswerCreatedNotifyComplete?:  {
    __typename: "zAnswerCreatedNotifyComplete",
    communityID: string,
    communityQuestionAnswerID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateZAnswerCreatedNotifyCompleteSubscription = {
  onUpdateZAnswerCreatedNotifyComplete?:  {
    __typename: "zAnswerCreatedNotifyComplete",
    communityID: string,
    communityQuestionAnswerID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteZAnswerCreatedNotifyCompleteSubscription = {
  onDeleteZAnswerCreatedNotifyComplete?:  {
    __typename: "zAnswerCreatedNotifyComplete",
    communityID: string,
    communityQuestionAnswerID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateZCommentMadeNotifySigSubscription = {
  onCreateZCommentMadeNotifySig?:  {
    __typename: "zCommentMadeNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateZCommentMadeNotifySigSubscription = {
  onUpdateZCommentMadeNotifySig?:  {
    __typename: "zCommentMadeNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteZCommentMadeNotifySigSubscription = {
  onDeleteZCommentMadeNotifySig?:  {
    __typename: "zCommentMadeNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateZCommentMadeNotifyBacklogSubscription = {
  onCreateZCommentMadeNotifyBacklog?:  {
    __typename: "zCommentMadeNotifyBacklog",
    communityID: string,
    communityQuestionAnswerID: string,
    communityQuestionAnswerCommentCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateZCommentMadeNotifyBacklogSubscription = {
  onUpdateZCommentMadeNotifyBacklog?:  {
    __typename: "zCommentMadeNotifyBacklog",
    communityID: string,
    communityQuestionAnswerID: string,
    communityQuestionAnswerCommentCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteZCommentMadeNotifyBacklogSubscription = {
  onDeleteZCommentMadeNotifyBacklog?:  {
    __typename: "zCommentMadeNotifyBacklog",
    communityID: string,
    communityQuestionAnswerID: string,
    communityQuestionAnswerCommentCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateZCommentMadeNotifyCompleteSubscription = {
  onCreateZCommentMadeNotifyComplete?:  {
    __typename: "zCommentMadeNotifyComplete",
    communityID: string,
    communityQuestionAnswerID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateZCommentMadeNotifyCompleteSubscription = {
  onUpdateZCommentMadeNotifyComplete?:  {
    __typename: "zCommentMadeNotifyComplete",
    communityID: string,
    communityQuestionAnswerID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteZCommentMadeNotifyCompleteSubscription = {
  onDeleteZCommentMadeNotifyComplete?:  {
    __typename: "zCommentMadeNotifyComplete",
    communityID: string,
    communityQuestionAnswerID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateZCommunityInvitationReminderNotifySigSubscription = {
  onCreateZCommunityInvitationReminderNotifySig?:  {
    __typename: "zCommunityInvitationReminderNotifySig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type OnUpdateZCommunityInvitationReminderNotifySigSubscription = {
  onUpdateZCommunityInvitationReminderNotifySig?:  {
    __typename: "zCommunityInvitationReminderNotifySig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type OnDeleteZCommunityInvitationReminderNotifySigSubscription = {
  onDeleteZCommunityInvitationReminderNotifySig?:  {
    __typename: "zCommunityInvitationReminderNotifySig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type OnCreateZCommunityInvitationReminderNotifyBacklogSubscription = {
  onCreateZCommunityInvitationReminderNotifyBacklog?:  {
    __typename: "zCommunityInvitationReminderNotifyBacklog",
    communityUserID: string,
    userID: string,
    communityID: string,
    status: CommunityUserStatus,
    invitedByUserID: string,
    invitedOn: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateZCommunityInvitationReminderNotifyBacklogSubscription = {
  onUpdateZCommunityInvitationReminderNotifyBacklog?:  {
    __typename: "zCommunityInvitationReminderNotifyBacklog",
    communityUserID: string,
    userID: string,
    communityID: string,
    status: CommunityUserStatus,
    invitedByUserID: string,
    invitedOn: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteZCommunityInvitationReminderNotifyBacklogSubscription = {
  onDeleteZCommunityInvitationReminderNotifyBacklog?:  {
    __typename: "zCommunityInvitationReminderNotifyBacklog",
    communityUserID: string,
    userID: string,
    communityID: string,
    status: CommunityUserStatus,
    invitedByUserID: string,
    invitedOn: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateZCommunityInvitationReminderNotifyCompleteSubscription = {
  onCreateZCommunityInvitationReminderNotifyComplete?:  {
    __typename: "zCommunityInvitationReminderNotifyComplete",
    communityUserID: string,
    userID: string,
    communityID: string,
    status: CommunityUserStatus,
    invitedByUserID: string,
    invitedOn: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateZCommunityInvitationReminderNotifyCompleteSubscription = {
  onUpdateZCommunityInvitationReminderNotifyComplete?:  {
    __typename: "zCommunityInvitationReminderNotifyComplete",
    communityUserID: string,
    userID: string,
    communityID: string,
    status: CommunityUserStatus,
    invitedByUserID: string,
    invitedOn: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteZCommunityInvitationReminderNotifyCompleteSubscription = {
  onDeleteZCommunityInvitationReminderNotifyComplete?:  {
    __typename: "zCommunityInvitationReminderNotifyComplete",
    communityUserID: string,
    userID: string,
    communityID: string,
    status: CommunityUserStatus,
    invitedByUserID: string,
    invitedOn: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateZCommunityNextStepCalculateSigSubscription = {
  onCreateZCommunityNextStepCalculateSig?:  {
    __typename: "zCommunityNextStepCalculateSig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type OnUpdateZCommunityNextStepCalculateSigSubscription = {
  onUpdateZCommunityNextStepCalculateSig?:  {
    __typename: "zCommunityNextStepCalculateSig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type OnDeleteZCommunityNextStepCalculateSigSubscription = {
  onDeleteZCommunityNextStepCalculateSig?:  {
    __typename: "zCommunityNextStepCalculateSig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type OnCreateZCommunityNextStepCalculateBacklogSubscription = {
  onCreateZCommunityNextStepCalculateBacklog?:  {
    __typename: "zCommunityNextStepCalculateBacklog",
    id: string,
    communityID: string,
    nextStepType: NextStepType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateZCommunityNextStepCalculateBacklogSubscription = {
  onUpdateZCommunityNextStepCalculateBacklog?:  {
    __typename: "zCommunityNextStepCalculateBacklog",
    id: string,
    communityID: string,
    nextStepType: NextStepType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteZCommunityNextStepCalculateBacklogSubscription = {
  onDeleteZCommunityNextStepCalculateBacklog?:  {
    __typename: "zCommunityNextStepCalculateBacklog",
    id: string,
    communityID: string,
    nextStepType: NextStepType,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateZCommunityNextStepCalculateCompleteSubscription = {
  onCreateZCommunityNextStepCalculateComplete?:  {
    __typename: "zCommunityNextStepCalculateComplete",
    id: string,
    communityID: string,
    nextStepType: NextStepType,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateZCommunityNextStepCalculateCompleteSubscription = {
  onUpdateZCommunityNextStepCalculateComplete?:  {
    __typename: "zCommunityNextStepCalculateComplete",
    id: string,
    communityID: string,
    nextStepType: NextStepType,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteZCommunityNextStepCalculateCompleteSubscription = {
  onDeleteZCommunityNextStepCalculateComplete?:  {
    __typename: "zCommunityNextStepCalculateComplete",
    id: string,
    communityID: string,
    nextStepType: NextStepType,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateZFirstCommunityQuestionNotifySigSubscription = {
  onCreateZFirstCommunityQuestionNotifySig?:  {
    __typename: "zFirstCommunityQuestionNotifySig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type OnUpdateZFirstCommunityQuestionNotifySigSubscription = {
  onUpdateZFirstCommunityQuestionNotifySig?:  {
    __typename: "zFirstCommunityQuestionNotifySig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type OnDeleteZFirstCommunityQuestionNotifySigSubscription = {
  onDeleteZFirstCommunityQuestionNotifySig?:  {
    __typename: "zFirstCommunityQuestionNotifySig",
    id: string,
    source: string,
    createdAt: string,
    signal:  {
      __typename: "JobSignal",
      payload: string,
    },
    updatedAt: string,
  } | null,
};

export type OnCreateZFirstCommunityQuestionNotifyBacklogSubscription = {
  onCreateZFirstCommunityQuestionNotifyBacklog?:  {
    __typename: "zFirstCommunityQuestionNotifyBacklog",
    communityID: string,
    communityCreatedAt: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateZFirstCommunityQuestionNotifyBacklogSubscription = {
  onUpdateZFirstCommunityQuestionNotifyBacklog?:  {
    __typename: "zFirstCommunityQuestionNotifyBacklog",
    communityID: string,
    communityCreatedAt: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteZFirstCommunityQuestionNotifyBacklogSubscription = {
  onDeleteZFirstCommunityQuestionNotifyBacklog?:  {
    __typename: "zFirstCommunityQuestionNotifyBacklog",
    communityID: string,
    communityCreatedAt: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateZFirstCommunityQuestionNotifyCompleteSubscription = {
  onCreateZFirstCommunityQuestionNotifyComplete?:  {
    __typename: "zFirstCommunityQuestionNotifyComplete",
    communityID: string,
    communityCreatedAt: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateZFirstCommunityQuestionNotifyCompleteSubscription = {
  onUpdateZFirstCommunityQuestionNotifyComplete?:  {
    __typename: "zFirstCommunityQuestionNotifyComplete",
    communityID: string,
    communityCreatedAt: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteZFirstCommunityQuestionNotifyCompleteSubscription = {
  onDeleteZFirstCommunityQuestionNotifyComplete?:  {
    __typename: "zFirstCommunityQuestionNotifyComplete",
    communityID: string,
    communityCreatedAt: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateZQuestionAskedNotifySigSubscription = {
  onCreateZQuestionAskedNotifySig?:  {
    __typename: "zQuestionAskedNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateZQuestionAskedNotifySigSubscription = {
  onUpdateZQuestionAskedNotifySig?:  {
    __typename: "zQuestionAskedNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteZQuestionAskedNotifySigSubscription = {
  onDeleteZQuestionAskedNotifySig?:  {
    __typename: "zQuestionAskedNotifySig",
    id: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateZQuestionAskedNotifyBacklogSubscription = {
  onCreateZQuestionAskedNotifyBacklog?:  {
    __typename: "zQuestionAskedNotifyBacklog",
    communityID: string,
    communityQuestionID: string,
    communityQuestionCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateZQuestionAskedNotifyBacklogSubscription = {
  onUpdateZQuestionAskedNotifyBacklog?:  {
    __typename: "zQuestionAskedNotifyBacklog",
    communityID: string,
    communityQuestionID: string,
    communityQuestionCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteZQuestionAskedNotifyBacklogSubscription = {
  onDeleteZQuestionAskedNotifyBacklog?:  {
    __typename: "zQuestionAskedNotifyBacklog",
    communityID: string,
    communityQuestionID: string,
    communityQuestionCreatedAt: string,
    userID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateZQuestionAskedNotifyCompleteSubscription = {
  onCreateZQuestionAskedNotifyComplete?:  {
    __typename: "zQuestionAskedNotifyComplete",
    communityID: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateZQuestionAskedNotifyCompleteSubscription = {
  onUpdateZQuestionAskedNotifyComplete?:  {
    __typename: "zQuestionAskedNotifyComplete",
    communityID: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteZQuestionAskedNotifyCompleteSubscription = {
  onDeleteZQuestionAskedNotifyComplete?:  {
    __typename: "zQuestionAskedNotifyComplete",
    communityID: string,
    communityQuestionID: string,
    userID: string,
    createdAt: string,
    completedAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateCategorySubscription = {
  onCreateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCategorySubscription = {
  onUpdateCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCategorySubscription = {
  onDeleteCategory?:  {
    __typename: "Category",
    id: string,
    name: string,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCommunityCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCommunityCommandSubscription = {
  onCreateCommunityCommand?:  {
    __typename: "CommunityCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityID?: string | null,
    name?: string | null,
    imageKey?: string | null,
    type?: CommunityType | null,
    allowPublicPages?: boolean | null,
    limits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    vimeoVideos?: Array< string > | null,
    communityCategories?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCommunityCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCommunityCommandSubscription = {
  onUpdateCommunityCommand?:  {
    __typename: "CommunityCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityID?: string | null,
    name?: string | null,
    imageKey?: string | null,
    type?: CommunityType | null,
    allowPublicPages?: boolean | null,
    limits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    vimeoVideos?: Array< string > | null,
    communityCategories?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCommunityCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCommunityCommandSubscription = {
  onDeleteCommunityCommand?:  {
    __typename: "CommunityCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityID?: string | null,
    name?: string | null,
    imageKey?: string | null,
    type?: CommunityType | null,
    allowPublicPages?: boolean | null,
    limits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    vimeoVideos?: Array< string > | null,
    communityCategories?: Array< string > | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCommunityInterviewRequestCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCommunityInterviewRequestCommandSubscription = {
  onCreateCommunityInterviewRequestCommand?:  {
    __typename: "CommunityInterviewRequestCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityID: string,
    userID: string,
    date?: string | null,
    status: CommunityInterviewStatus,
    message:  {
      __typename: "CommunityInterviewMessage",
      subject: string,
      body: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCommunityInterviewRequestCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCommunityInterviewRequestCommandSubscription = {
  onUpdateCommunityInterviewRequestCommand?:  {
    __typename: "CommunityInterviewRequestCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityID: string,
    userID: string,
    date?: string | null,
    status: CommunityInterviewStatus,
    message:  {
      __typename: "CommunityInterviewMessage",
      subject: string,
      body: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCommunityInterviewRequestCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCommunityInterviewRequestCommandSubscription = {
  onDeleteCommunityInterviewRequestCommand?:  {
    __typename: "CommunityInterviewRequestCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityID: string,
    userID: string,
    date?: string | null,
    status: CommunityInterviewStatus,
    message:  {
      __typename: "CommunityInterviewMessage",
      subject: string,
      body: string,
    },
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCommunityQuestionSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCommunityQuestionSubscription = {
  onCreateCommunityQuestion?:  {
    __typename: "CommunityQuestion",
    id: string,
    communityID: string,
    userID: string,
    categoryID?: string | null,
    communityCategory?: string | null,
    date: string,
    status: CommunityQuestionStatus,
    source: CommunityQuestionSource,
    questionText: string,
    imageKeys?: Array< string > | null,
    clonedFromCommunityQuestionRecommendationId?: string | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
    communityQuestionAnswersByStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionLikesByStatus?:  {
      __typename: "ModelCommunityQuestionLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionLike",
        id: string,
        userID: string,
        communityQuestionID: string,
        status: CommunityQuestionLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateCommunityQuestionSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCommunityQuestionSubscription = {
  onUpdateCommunityQuestion?:  {
    __typename: "CommunityQuestion",
    id: string,
    communityID: string,
    userID: string,
    categoryID?: string | null,
    communityCategory?: string | null,
    date: string,
    status: CommunityQuestionStatus,
    source: CommunityQuestionSource,
    questionText: string,
    imageKeys?: Array< string > | null,
    clonedFromCommunityQuestionRecommendationId?: string | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
    communityQuestionAnswersByStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionLikesByStatus?:  {
      __typename: "ModelCommunityQuestionLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionLike",
        id: string,
        userID: string,
        communityQuestionID: string,
        status: CommunityQuestionLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteCommunityQuestionSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCommunityQuestionSubscription = {
  onDeleteCommunityQuestion?:  {
    __typename: "CommunityQuestion",
    id: string,
    communityID: string,
    userID: string,
    categoryID?: string | null,
    communityCategory?: string | null,
    date: string,
    status: CommunityQuestionStatus,
    source: CommunityQuestionSource,
    questionText: string,
    imageKeys?: Array< string > | null,
    clonedFromCommunityQuestionRecommendationId?: string | null,
    createdAt: string,
    updatedAt: string,
    category?:  {
      __typename: "Category",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    } | null,
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
    communityQuestionAnswersByStatusDate?:  {
      __typename: "ModelCommunityQuestionAnswerConnection",
      items:  Array< {
        __typename: "CommunityQuestionAnswer",
        id: string,
        communityQuestionID: string,
        userID: string,
        communityID: string,
        status: CommunityQuestionAnswerStatus,
        date: string,
        content: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswerCommentsByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerCommentConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswerLikesByStatus?:  {
          __typename: "ModelCommunityQuestionAnswerLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    communityQuestionLikesByStatus?:  {
      __typename: "ModelCommunityQuestionLikeConnection",
      items:  Array< {
        __typename: "CommunityQuestionLike",
        id: string,
        userID: string,
        communityQuestionID: string,
        status: CommunityQuestionLikeStatus,
        date: string,
        createdAt: string,
        updatedAt: string,
        communityQuestion:  {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      } | null >,
      nextToken?: string | null,
    } | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateCommunityQuestionAnswerCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCommunityQuestionAnswerCommandSubscription = {
  onCreateCommunityQuestionAnswerCommand?:  {
    __typename: "CommunityQuestionAnswerCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityQuestionAnswerID?: string | null,
    communityQuestionID?: string | null,
    communityID?: string | null,
    userID?: string | null,
    status?: CommunityQuestionAnswerStatus | null,
    date?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCommunityQuestionAnswerCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCommunityQuestionAnswerCommandSubscription = {
  onUpdateCommunityQuestionAnswerCommand?:  {
    __typename: "CommunityQuestionAnswerCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityQuestionAnswerID?: string | null,
    communityQuestionID?: string | null,
    communityID?: string | null,
    userID?: string | null,
    status?: CommunityQuestionAnswerStatus | null,
    date?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCommunityQuestionAnswerCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCommunityQuestionAnswerCommandSubscription = {
  onDeleteCommunityQuestionAnswerCommand?:  {
    __typename: "CommunityQuestionAnswerCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityQuestionAnswerID?: string | null,
    communityQuestionID?: string | null,
    communityID?: string | null,
    userID?: string | null,
    status?: CommunityQuestionAnswerStatus | null,
    date?: string | null,
    content?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCommunityQuestionAnswerCommentCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCommunityQuestionAnswerCommentCommandSubscription = {
  onCreateCommunityQuestionAnswerCommentCommand?:  {
    __typename: "CommunityQuestionAnswerCommentCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityQuestionAnswerCommentID?: string | null,
    userID?: string | null,
    communityID?: string | null,
    communityQuestionAnswerID?: string | null,
    content?: string | null,
    status?: CommunityQuestionAnswerCommentStatus | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCommunityQuestionAnswerCommentCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCommunityQuestionAnswerCommentCommandSubscription = {
  onUpdateCommunityQuestionAnswerCommentCommand?:  {
    __typename: "CommunityQuestionAnswerCommentCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityQuestionAnswerCommentID?: string | null,
    userID?: string | null,
    communityID?: string | null,
    communityQuestionAnswerID?: string | null,
    content?: string | null,
    status?: CommunityQuestionAnswerCommentStatus | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCommunityQuestionAnswerCommentCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCommunityQuestionAnswerCommentCommandSubscription = {
  onDeleteCommunityQuestionAnswerCommentCommand?:  {
    __typename: "CommunityQuestionAnswerCommentCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityQuestionAnswerCommentID?: string | null,
    userID?: string | null,
    communityID?: string | null,
    communityQuestionAnswerID?: string | null,
    content?: string | null,
    status?: CommunityQuestionAnswerCommentStatus | null,
    date?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateCommunityQuestionAnswerLikeSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCommunityQuestionAnswerLikeSubscription = {
  onCreateCommunityQuestionAnswerLike?:  {
    __typename: "CommunityQuestionAnswerLike",
    id: string,
    userID: string,
    communityID: string,
    communityQuestionAnswerID: string,
    status: CommunityQuestionAnswerLikeStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestionAnswer:  {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateCommunityQuestionAnswerLikeSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCommunityQuestionAnswerLikeSubscription = {
  onUpdateCommunityQuestionAnswerLike?:  {
    __typename: "CommunityQuestionAnswerLike",
    id: string,
    userID: string,
    communityID: string,
    communityQuestionAnswerID: string,
    status: CommunityQuestionAnswerLikeStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestionAnswer:  {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteCommunityQuestionAnswerLikeSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCommunityQuestionAnswerLikeSubscription = {
  onDeleteCommunityQuestionAnswerLike?:  {
    __typename: "CommunityQuestionAnswerLike",
    id: string,
    userID: string,
    communityID: string,
    communityQuestionAnswerID: string,
    status: CommunityQuestionAnswerLikeStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestionAnswer:  {
      __typename: "CommunityQuestionAnswer",
      id: string,
      communityQuestionID: string,
      userID: string,
      communityID: string,
      status: CommunityQuestionAnswerStatus,
      date: string,
      content: string,
      createdAt: string,
      updatedAt: string,
      communityQuestion:  {
        __typename: "CommunityQuestion",
        id: string,
        communityID: string,
        userID: string,
        categoryID?: string | null,
        communityCategory?: string | null,
        date: string,
        status: CommunityQuestionStatus,
        source: CommunityQuestionSource,
        questionText: string,
        imageKeys?: Array< string > | null,
        clonedFromCommunityQuestionRecommendationId?: string | null,
        createdAt: string,
        updatedAt: string,
        category?:  {
          __typename: "Category",
          id: string,
          name: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null,
        community:  {
          __typename: "Community",
          id: string,
          name: string,
          imageKey?: string | null,
          type?: CommunityType | null,
          allowPublicPages?: boolean | null,
          alias?: string | null,
          publicPreviewHeaderURL?: string | null,
          publicPreviewDescription?: string | null,
          requiresModeration?: boolean | null,
          allowUnauthenticatedAnswers?: boolean | null,
          vimeoVideos?: Array< string > | null,
          communityCategories?: Array< string > | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        },
        owner?: string | null,
        communityQuestionAnswersByStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionLikesByStatus?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        user:  {
          __typename: "User",
          id: string,
          firstName: string,
          lastName: string,
          email?: string | null,
          status?: UserStatus | null,
          profileImageKey?: string | null,
          welcomesSeen?: Array< string > | null,
          helpsSeen?: Array< string > | null,
          referralCode?: string | null,
          referredByUserID?: string | null,
          username?: string | null,
          identityID?: string | null,
          createdAt: string,
          updatedAt: string,
        },
      },
      owner?: string | null,
      communityQuestionAnswerCommentsByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerCommentConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerComment",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          content: string,
          status: CommunityQuestionAnswerCommentStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswerLikesByStatus?:  {
        __typename: "ModelCommunityQuestionAnswerLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswerLike",
          id: string,
          userID: string,
          communityID: string,
          communityQuestionAnswerID: string,
          status: CommunityQuestionAnswerLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    owner?: string | null,
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateCommunityQuestionLikeSubscription = {
  onCreateCommunityQuestionLike?:  {
    __typename: "CommunityQuestionLike",
    id: string,
    userID: string,
    communityQuestionID: string,
    status: CommunityQuestionLikeStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestion:  {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnUpdateCommunityQuestionLikeSubscription = {
  onUpdateCommunityQuestionLike?:  {
    __typename: "CommunityQuestionLike",
    id: string,
    userID: string,
    communityQuestionID: string,
    status: CommunityQuestionLikeStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestion:  {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnDeleteCommunityQuestionLikeSubscription = {
  onDeleteCommunityQuestionLike?:  {
    __typename: "CommunityQuestionLike",
    id: string,
    userID: string,
    communityQuestionID: string,
    status: CommunityQuestionLikeStatus,
    date: string,
    createdAt: string,
    updatedAt: string,
    communityQuestion:  {
      __typename: "CommunityQuestion",
      id: string,
      communityID: string,
      userID: string,
      categoryID?: string | null,
      communityCategory?: string | null,
      date: string,
      status: CommunityQuestionStatus,
      source: CommunityQuestionSource,
      questionText: string,
      imageKeys?: Array< string > | null,
      clonedFromCommunityQuestionRecommendationId?: string | null,
      createdAt: string,
      updatedAt: string,
      category?:  {
        __typename: "Category",
        id: string,
        name: string,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
      } | null,
      community:  {
        __typename: "Community",
        id: string,
        name: string,
        imageKey?: string | null,
        type?: CommunityType | null,
        allowPublicPages?: boolean | null,
        alias?: string | null,
        publicPreviewHeaderURL?: string | null,
        publicPreviewDescription?: string | null,
        requiresModeration?: boolean | null,
        allowUnauthenticatedAnswers?: boolean | null,
        limits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        vimeoVideos?: Array< string > | null,
        communityCategories?: Array< string > | null,
        interviewTab?:  {
          __typename: "InterviewTab",
          header: string,
          bodyLine1: string,
          bodyLine2: string,
        } | null,
        createdAt: string,
        updatedAt: string,
        owner?: string | null,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionRecommendations?:  {
          __typename: "ModelCommunityQuestionRecommendationConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
      owner?: string | null,
      communityQuestionAnswersByStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionLikesByStatus?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      user:  {
        __typename: "User",
        id: string,
        firstName: string,
        lastName: string,
        email?: string | null,
        status?: UserStatus | null,
        profileImageKey?: string | null,
        communityLimits?:  {
          __typename: "CommunityLimits",
          maxSubjects?: number | null,
          maxMembers?: number | null,
          maxInvitationLength?: number | null,
          maxVideoDurationSeconds?: number | null,
        } | null,
        welcomesSeen?: Array< string > | null,
        helpsSeen?: Array< string > | null,
        nextSteps?:  Array< {
          __typename: "CommunityNextStep",
          communityID: string,
        } > | null,
        referralCode?: string | null,
        referredByUserID?: string | null,
        username?: string | null,
        identityID?: string | null,
        createdAt: string,
        updatedAt: string,
        communityQuestionsByStatusDate?:  {
          __typename: "ModelCommunityQuestionConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionAnswersByUserStatusDate?:  {
          __typename: "ModelCommunityQuestionAnswerConnection",
          nextToken?: string | null,
        } | null,
        communityQuestionlikesByStatusDate?:  {
          __typename: "ModelCommunityQuestionLikeConnection",
          nextToken?: string | null,
        } | null,
        communityUsersByStatusDate?:  {
          __typename: "ModelCommunityUserConnection",
          nextToken?: string | null,
        } | null,
      },
    },
    user:  {
      __typename: "User",
      id: string,
      firstName: string,
      lastName: string,
      email?: string | null,
      status?: UserStatus | null,
      profileImageKey?: string | null,
      communityLimits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      welcomesSeen?: Array< string > | null,
      helpsSeen?: Array< string > | null,
      nextSteps?:  Array< {
        __typename: "CommunityNextStep",
        communityID: string,
        nextStepsByRole?:  {
          __typename: "NextStepsByRole",
          INVITED?: Array< NextStepType > | null,
          MEMBER?: Array< NextStepType > | null,
          ORGANIZER?: Array< NextStepType > | null,
          SUBJECT?: Array< NextStepType > | null,
          REMOVED?: Array< NextStepType > | null,
          BANNED?: Array< NextStepType > | null,
          ADMIN?: Array< NextStepType > | null,
        } | null,
      } > | null,
      referralCode?: string | null,
      referredByUserID?: string | null,
      username?: string | null,
      identityID?: string | null,
      createdAt: string,
      updatedAt: string,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionAnswersByUserStatusDate?:  {
        __typename: "ModelCommunityQuestionAnswerConnection",
        items:  Array< {
          __typename: "CommunityQuestionAnswer",
          id: string,
          communityQuestionID: string,
          userID: string,
          communityID: string,
          status: CommunityQuestionAnswerStatus,
          date: string,
          content: string,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionlikesByStatusDate?:  {
        __typename: "ModelCommunityQuestionLikeConnection",
        items:  Array< {
          __typename: "CommunityQuestionLike",
          id: string,
          userID: string,
          communityQuestionID: string,
          status: CommunityQuestionLikeStatus,
          date: string,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
  } | null,
};

export type OnCreateCommunityQuestionRecommendationSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCommunityQuestionRecommendationSubscription = {
  onCreateCommunityQuestionRecommendation?:  {
    __typename: "CommunityQuestionRecommendation",
    id: string,
    communityID: string,
    categoryID: string,
    questionText: string,
    score: number,
    status?: CommunityQuestionRecommendationStatus | null,
    createdAt: string,
    updatedAt: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
  } | null,
};

export type OnUpdateCommunityQuestionRecommendationSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCommunityQuestionRecommendationSubscription = {
  onUpdateCommunityQuestionRecommendation?:  {
    __typename: "CommunityQuestionRecommendation",
    id: string,
    communityID: string,
    categoryID: string,
    questionText: string,
    score: number,
    status?: CommunityQuestionRecommendationStatus | null,
    createdAt: string,
    updatedAt: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
  } | null,
};

export type OnDeleteCommunityQuestionRecommendationSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCommunityQuestionRecommendationSubscription = {
  onDeleteCommunityQuestionRecommendation?:  {
    __typename: "CommunityQuestionRecommendation",
    id: string,
    communityID: string,
    categoryID: string,
    questionText: string,
    score: number,
    status?: CommunityQuestionRecommendationStatus | null,
    createdAt: string,
    updatedAt: string,
    category:  {
      __typename: "Category",
      id: string,
      name: string,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
    },
    community:  {
      __typename: "Community",
      id: string,
      name: string,
      imageKey?: string | null,
      type?: CommunityType | null,
      allowPublicPages?: boolean | null,
      alias?: string | null,
      publicPreviewHeaderURL?: string | null,
      publicPreviewDescription?: string | null,
      requiresModeration?: boolean | null,
      allowUnauthenticatedAnswers?: boolean | null,
      limits?:  {
        __typename: "CommunityLimits",
        maxSubjects?: number | null,
        maxMembers?: number | null,
        maxInvitationLength?: number | null,
        maxVideoDurationSeconds?: number | null,
      } | null,
      vimeoVideos?: Array< string > | null,
      communityCategories?: Array< string > | null,
      interviewTab?:  {
        __typename: "InterviewTab",
        header: string,
        bodyLine1: string,
        bodyLine2: string,
      } | null,
      createdAt: string,
      updatedAt: string,
      owner?: string | null,
      communityQuestionsByStatusDate?:  {
        __typename: "ModelCommunityQuestionConnection",
        items:  Array< {
          __typename: "CommunityQuestion",
          id: string,
          communityID: string,
          userID: string,
          categoryID?: string | null,
          communityCategory?: string | null,
          date: string,
          status: CommunityQuestionStatus,
          source: CommunityQuestionSource,
          questionText: string,
          imageKeys?: Array< string > | null,
          clonedFromCommunityQuestionRecommendationId?: string | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityQuestionRecommendations?:  {
        __typename: "ModelCommunityQuestionRecommendationConnection",
        items:  Array< {
          __typename: "CommunityQuestionRecommendation",
          id: string,
          communityID: string,
          categoryID: string,
          questionText: string,
          score: number,
          status?: CommunityQuestionRecommendationStatus | null,
          createdAt: string,
          updatedAt: string,
          owner?: string | null,
        } | null >,
        nextToken?: string | null,
      } | null,
      communityUsersByStatusDate?:  {
        __typename: "ModelCommunityUserConnection",
        items:  Array< {
          __typename: "CommunityUser",
          id: string,
          communityID: string,
          userID: string,
          joinedOn: string,
          invitedByUserID: string,
          invitedOn: string,
          status: CommunityUserStatus,
          relationshipToSpotlight?: string | null,
          owner?: string | null,
          group?: string | null,
          createdAt: string,
          updatedAt: string,
        } | null >,
        nextToken?: string | null,
      } | null,
    },
    owner?: string | null,
  } | null,
};

export type OnCreateCommunityUserCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateCommunityUserCommandSubscription = {
  onCreateCommunityUserCommand?:  {
    __typename: "CommunityUserCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityUserId?: string | null,
    communityID?: string | null,
    userID?: string | null,
    joinedOn?: string | null,
    invitedByUserID?: string | null,
    invitedOn?: string | null,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    status?: CommunityUserStatus | null,
    relationshipToSpotlight?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateCommunityUserCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateCommunityUserCommandSubscription = {
  onUpdateCommunityUserCommand?:  {
    __typename: "CommunityUserCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityUserId?: string | null,
    communityID?: string | null,
    userID?: string | null,
    joinedOn?: string | null,
    invitedByUserID?: string | null,
    invitedOn?: string | null,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    status?: CommunityUserStatus | null,
    relationshipToSpotlight?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteCommunityUserCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteCommunityUserCommandSubscription = {
  onDeleteCommunityUserCommand?:  {
    __typename: "CommunityUserCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    communityUserId?: string | null,
    communityID?: string | null,
    userID?: string | null,
    joinedOn?: string | null,
    invitedByUserID?: string | null,
    invitedOn?: string | null,
    invitation?:  {
      __typename: "CommunityUserInvitation",
      invitationMessage: string,
    } | null,
    status?: CommunityUserStatus | null,
    relationshipToSpotlight?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateInvitationSubscription = {
  onCreateInvitation?:  {
    __typename: "Invitation",
    id: string,
    email: string,
    toNotify: boolean,
    status: InvitationStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateInvitationSubscription = {
  onUpdateInvitation?:  {
    __typename: "Invitation",
    id: string,
    email: string,
    toNotify: boolean,
    status: InvitationStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteInvitationSubscription = {
  onDeleteInvitation?:  {
    __typename: "Invitation",
    id: string,
    email: string,
    toNotify: boolean,
    status: InvitationStatus,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserIdToIdentityIdSubscription = {
  onCreateUserIdToIdentityId?:  {
    __typename: "UserIdToIdentityId",
    userID: string,
    identityID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserIdToIdentityIdSubscription = {
  onUpdateUserIdToIdentityId?:  {
    __typename: "UserIdToIdentityId",
    userID: string,
    identityID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserIdToIdentityIdSubscription = {
  onDeleteUserIdToIdentityId?:  {
    __typename: "UserIdToIdentityId",
    userID: string,
    identityID: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateUserCommandSubscription = {
  onCreateUserCommand?:  {
    __typename: "UserCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    userID?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: UserStatus | null,
    profileImageKey?: string | null,
    communityLimits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    welcomesSeen?: Array< string | null > | null,
    helpsSeen?: Array< string | null > | null,
    username?: string | null,
    identityID?: string | null,
    referralCode?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateUserCommandSubscription = {
  onUpdateUserCommand?:  {
    __typename: "UserCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    userID?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: UserStatus | null,
    profileImageKey?: string | null,
    communityLimits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    welcomesSeen?: Array< string | null > | null,
    helpsSeen?: Array< string | null > | null,
    username?: string | null,
    identityID?: string | null,
    referralCode?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserCommandSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteUserCommandSubscription = {
  onDeleteUserCommand?:  {
    __typename: "UserCommand",
    id: string,
    command:  {
      __typename: "Command",
      operation: CommandOperation,
    },
    userID?: string | null,
    firstName?: string | null,
    lastName?: string | null,
    email?: string | null,
    status?: UserStatus | null,
    profileImageKey?: string | null,
    communityLimits?:  {
      __typename: "CommunityLimits",
      maxSubjects?: number | null,
      maxMembers?: number | null,
      maxInvitationLength?: number | null,
      maxVideoDurationSeconds?: number | null,
    } | null,
    welcomesSeen?: Array< string | null > | null,
    helpsSeen?: Array< string | null > | null,
    username?: string | null,
    identityID?: string | null,
    referralCode?: string | null,
    createdAt: string,
    updatedAt: string,
    owner?: string | null,
  } | null,
};

export type OnCreateUserGoogleTokensSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateUserGoogleTokensSubscription = {
  onCreateUserGoogleTokens?:  {
    __typename: "UserGoogleTokens",
    owner: string,
    accessToken: string,
    refreshToken: string,
    scopes?: Array< string > | null,
    expiresOn: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserGoogleTokensSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateUserGoogleTokensSubscription = {
  onUpdateUserGoogleTokens?:  {
    __typename: "UserGoogleTokens",
    owner: string,
    accessToken: string,
    refreshToken: string,
    scopes?: Array< string > | null,
    expiresOn: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserGoogleTokensSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteUserGoogleTokensSubscription = {
  onDeleteUserGoogleTokens?:  {
    __typename: "UserGoogleTokens",
    owner: string,
    accessToken: string,
    refreshToken: string,
    scopes?: Array< string > | null,
    expiresOn: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnCreateUserReferralCodeSubscription = {
  onCreateUserReferralCode?:  {
    __typename: "UserReferralCode",
    referralCode: string,
    firstName: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnUpdateUserReferralCodeSubscription = {
  onUpdateUserReferralCode?:  {
    __typename: "UserReferralCode",
    referralCode: string,
    firstName: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type OnDeleteUserReferralCodeSubscription = {
  onDeleteUserReferralCode?:  {
    __typename: "UserReferralCode",
    referralCode: string,
    firstName: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};
