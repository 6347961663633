import { Logger } from 'aws-amplify'
import { getCurrentEnv } from '../env/currentEnv';
const log = new Logger('Novella-Logger', getLogLevel());
export default log;

function getLogLevel() : string {
  try {
    const currentEnv = getCurrentEnv();
    if (currentEnv == 'research' || currentEnv == 'ui' || currentEnv == 'dev' || currentEnv == 'devint' || currentEnv == 'email' || currentEnv == 'vv') {
      return 'VERBOSE';
    }
  } catch (ex) {
    console.error(ex);
  }
  return 'ERROR';
}