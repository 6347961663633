import * as React from "react"
import Svg, { Path } from "react-native-svg"

function QuestionMark(props) {
  return (
    <Svg
      width={100}
      height={100}
      viewBox="0 0 90 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M45 90c24.853 0 45-20.147 45-45S69.853 0 45 0 0 20.147 0 45s20.147 45 45 45z"
        fill="#7FCECF"
      />
      <Path
        d="M51.43 13.252H38.57c-13.18 0-23.962 10.782-23.962 23.961v9.355c0 6.094 2.306 11.674 6.088 15.914l-2.95 9.272 10.482-3.586A23.741 23.741 0 0038.57 70.53h12.862c13.178 0 23.961-10.782 23.961-23.961v-9.355c0-13.179-10.782-23.961-23.961-23.961z"
        fill="#fff"
      />
      <Path
        d="M75.393 37.213v9.355c0 13.178-10.783 23.96-23.963 23.96H45V13.253h6.43c13.18 0 23.963 10.783 23.963 23.96z"
        fill="#FFF5CA"
      />
      <Path
        d="M40.761 48.364c0-1.852.302-3.515.907-4.99.604-1.474 1.852-2.758 3.742-3.855.567-.34 1.266-.727 2.098-1.162a17.057 17.057 0 002.438-1.56 8.938 8.938 0 002.013-2.125c.547-.813.822-1.748.822-2.807 0-.794-.152-1.493-.454-2.098a4.8 4.8 0 00-1.19-1.53 4.952 4.952 0 00-1.73-.936 6.693 6.693 0 00-2.012-.312c-.832 0-1.588.142-2.268.425a7.734 7.734 0 00-1.843 1.078 9.06 9.06 0 00-1.474 1.474 16.207 16.207 0 00-1.105 1.559l-4.933-3.402a11.002 11.002 0 011.956-3.118 11.98 11.98 0 012.778-2.296 13.007 13.007 0 013.374-1.418 14.53 14.53 0 013.741-.482c1.436 0 2.854.208 4.253.624a10.795 10.795 0 013.77 1.984c1.114.907 2.022 2.07 2.721 3.487.7 1.417 1.05 3.11 1.05 5.074 0 1.21-.142 2.278-.426 3.203a8.914 8.914 0 01-1.219 2.524 10.63 10.63 0 01-1.87 2.04c-.72.606-1.532 1.191-2.439 1.758-.756.454-1.512.889-2.268 1.304-.756.416-1.446.879-2.07 1.39a6.756 6.756 0 00-1.53 1.757c-.397.661-.595 1.465-.595 2.41H40.76zm.113 13.21V53.75h6.294v7.824h-6.294z"
        fill="#6082BC"
      />
      <Path
        d="M45 53.75v7.824h2.167V53.75H45zm13.365-26.845c-.7-1.419-1.607-2.58-2.721-3.487a10.786 10.786 0 00-3.771-1.985 14.873 14.873 0 00-4.252-.624c-.891 0-1.767.077-2.621.234v6.426c.042-.019.084-.037.127-.054.68-.283 1.436-.426 2.267-.426.68 0 1.352.104 2.013.312.66.209 1.237.52 1.73.937.49.414.887.926 1.19 1.529.302.606.453 1.304.453 2.099 0 1.058-.274 1.993-.82 2.805a8.96 8.96 0 01-2.014 2.127 17.195 17.195 0 01-2.438 1.56A42.693 42.693 0 0045 39.766v8.597h1.997c0-.945.199-1.749.596-2.41a6.748 6.748 0 011.53-1.757c.625-.51 1.314-.972 2.07-1.389a71.43 71.43 0 002.267-1.304 21.36 21.36 0 002.438-1.758 10.664 10.664 0 001.872-2.04 8.898 8.898 0 001.219-2.523c.283-.927.425-1.994.425-3.205 0-1.965-.35-3.656-1.05-5.073z"
        fill="#36507D"
      />
    </Svg>
  )
}

export default QuestionMark
