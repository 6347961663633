import React, { useContext } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import CommonContext from '../../../CommonContext';
import { CommonContextType } from '../../../types/CommonContextType';


function StepError(props: { message: string }): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'row',
      justifyContent: 'center',
      width: '100%',
      paddingBottom: 20,
    }
  });

  return (
    <>
      {
        props.message.length > 0 &&
        <View style={styles.container}>
          <Text
            style={{
              alignSelf: 'center',
              textAlign: 'center',
              color: themeFromContext.colors.failure,
              ...themeFromContext.textVariants.body
            }}
          >{props.message}</Text>
        </View>
      }
    </>
  );
}

export default StepError;

