import React, { useContext, useEffect, useRef, useState } from 'react';
import { LayoutChangeEvent, Linking, Platform, Pressable, Text, TextInput, View } from 'react-native';
import CommonContext from '../../../CommonContext';
import { CommonContextType } from '../../../types/CommonContextType';
import { RootStackParamList } from '../../../types/Navigation';
import { DrawerScreenProps } from '@react-navigation/drawer';
import i18n from 'i18n-js';

import NButton from '../../components/NButton';
import PQPage from '../../components/OnePersonOneQuestion/PQPage';
import log from '../../../business/logging/logger';
import LocalForageWrapper from '../../../business/storage/localForageWrapper';
import { Relationship } from '../../../constants/Relationships';
import { Palette } from '../../../Theme';
import { normalizeHeight, normalizeWidth } from '../../../business/layout/responseSize';
import NText from '../../components/NText';
import NTextInput from '../../components/NTextInput';
import { RecommendationFromFile } from '../../../contracts/IQuestionRecommendationService';
import { isValidEmail, refreshAuthToken } from '../../../business/user/userHelper';
import { CommunityServiceError } from '../../../contracts/ICommunityService';
import { createCommunityUnauthenticated, CreateCommunityUnauthenticatedResponse } from '../../../business/public/createCommunityUnauthenticated';
import StepHeader from '../../components/StepHeader';
import Family from '../../../../assets/svg/illustrations/Family';
import Message from '../../../../assets/svg/illustrations/Message';
import People from '../../../../assets/svg/illustrations/People';
import Question from '../../../../assets/svg/illustrations/Question';
import Envelope from '../../../../assets/svg/illustrations/Envelope';
import EmailPreviewModal2 from '../../components/EmailPreviewModal2';
import NDropDownPicker from '../../components/NDropDownPicker';
import VimeoPlayer from '../../components/VimeoPlayer/VimeoPlayer';
import ScrollableViewScreen from '../../components/Layouts/ScrollableViewScreen';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import { useSafeAreaInsets } from '../../../business/layout/layout';

type PQState = {
    // spotlight first name
    sfn: string,
    // spotlight last name
    sln: string,
    // second spotlight first name
    sfn2: string,
    // second spotlight last name
    sln2: string,
    // spotlight relationship
    sr: Relationship,
    // question text
    q: string,
    //category:
    c: string,
    // custom question
    cq: string,
    // use custom question?
    ucq: boolean
    // note
    n: string,
    // note confirmed?
    nc: boolean
    // spotlight's email
    se: string,
    // second spotlight's email
    se2: string,
    // user's first name
    fn: string,
    // user's last name
    ln: string,
    // user's email
    e: string,
    // time
    t: string,
}

const newState = (): PQState => {
    return {
        sfn: '',
        sln: '',
        sfn2: '',
        sln2: '',
        sr: "NONE",
        q: '',
        c: '',
        cq: '',
        ucq: false,
        n: i18n.t('OnePersonOneQuestion_Fourth_Page_Note'),
        nc: false,
        se: '',
        se2: '',
        fn: '',
        ln: '',
        e: '',
        t: new Date(Date.now()).toISOString(),
    };
}

const PREVIEW = require('../../../../assets/spotlight-email-sample.jpg');
const FALLBACK_VIDEO = require('../../../../assets/introducing_novella.mp4');

const CACHE_KEY = '1p1qs';
const QUESTIONS_PER_PAGE = 3;
const LOCAL_STATE_LIFETIME_MS = 2 * 24 * 60 * 60 * 1000; // 2 days
// for testing only const LOCAL_STATE_LIFETIME_MS = 10 * 1000; // 10 seconds

export type MyProps = DrawerScreenProps<RootStackParamList, 'Ask Now'>;

function OnePersonOneQuestion2(props: MyProps): React.ReactElement {
    const commonContext = useContext<CommonContextType>(CommonContext);
    // very special logic: if we're logged in, we don't want to use the navigation prop
    const [navigation] = useState(commonContext.loggedInUser ? null : props.navigation);
    const { theme, dimensions, services } = commonContext;
    const { width, height } = dimensions;
    const [step, setStep] = useState(0);
    const [isSaving, setIsSaving] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [expressedInterest, setExpressedInterest] = useState(false);
    const [showSecondSpotlight, setShowSecondSpotlight] = useState(false);
    const [newCommunityId, setNewCommunityId] = useState<string | undefined>();
    const isFocused = useIsFocused();
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState<{ label: string, value: string }[]>([]);
    const { top, bottom } = useSafeAreaInsets();
    const [reset, setReset] = useState(false);
    const linkTo = useLinkTo();

    // question recommendation state
    const [totalQuestionOptions, setTotalQuestionOptions] = useState<RecommendationFromFile[]>([]);
    const [nextQuestionOptions, setNextQuestionOptions] = useState<RecommendationFromFile[]>([]);
    const [lastOptionIndex, setLastOptionIndex] = useState(QUESTIONS_PER_PAGE - 1);

    // pq state params
    const [sfn, setSfn] = useState('');
    const sfnRef = useRef<TextInput>(null);
    const [sfnt, setSfnt] = useState(0);
    const [sfnError, setSfnError] = useState('');
    const [sln, setSln] = useState('');
    const slnRef = useRef<TextInput>(null);
    const [slnt, setSlnt] = useState(0);
    const [slnError, setSlnError] = useState('');
    const [sfn2, setSfn2] = useState('');
    const sfn2Ref = useRef<TextInput>(null);
    const [sfn2t, setSfn2t] = useState(0);
    const [sfn2Error, setSfn2Error] = useState('');
    const [sln2, setSln2] = useState('');
    const sln2Ref = useRef<TextInput>(null);
    const [sln2t, setSln2t] = useState(0);
    const [sln2Error, setSln2Error] = useState('');
    const [sr, setSr] = useState("NONE");
    const srRef = useRef<View>(null);
    const [srt, setSrt] = useState(0);
    const [srError, setSrError] = useState('');
    const [q, setQ] = useState('');
    const qRef = useRef<TextInput>(null);
    const [qt, setQt] = useState(0);
    const [qError, setQError] = useState('');
    const [c, setC] = useState('');
    const cRef = useRef<TextInput>(null);
    const [ct, setCt] = useState(0);
    const [cq, setCq] = useState('');
    const cqRef = useRef<TextInput>(null);
    const [cqt, setCqt] = useState(0);
    const [cqError, setCqError] = useState('');
    const [ucq, setUcq] = useState(false);
    const [n, setN] = useState(i18n.t('OnePersonOneQuestion_Fourth_Page_Note'));
    const nRef = useRef<TextInput>(null);
    const [nt, setNt] = useState(0);
    const [nError, setNError] = useState('');
    const [nc, setNc] = useState(false);
    const [ncError, setNcError] = useState('');
    const [se, setSe] = useState('');
    const seRef = useRef<TextInput>(null);
    const [set, setSet] = useState(0);
    const [seError, setSeError] = useState('');
    const [se2, setSe2] = useState('');
    const se2Ref = useRef<TextInput>(null);
    const [se2t, setSe2t] = useState(0);
    const [se2Error, setSe2Error] = useState('');
    const [fn, setFn] = useState(commonContext.loggedInUser?.firstName ?? '');
    const fnRef = useRef<TextInput>(null);
    const [fnt, setFnt] = useState(0);
    const [fnError, setFnError] = useState('');
    const [ln, setLn] = useState(commonContext.loggedInUser?.lastName ?? '');
    const lnRef = useRef<TextInput>(null);
    const [lnt, setLnt] = useState(0);
    const [lnError, setLnError] = useState('');
    const [e, setE] = useState(commonContext.loggedInUser?.email ?? '');
    const eRef = useRef<TextInput>(null);
    const [et, setEt] = useState(0);
    const [eError, setEError] = useState('');
    const [submitError, setSubmitError] = useState('');
    const [rfn, setRfn] = useState('');
    const [rfnError, setRfnError] = useState('');
    const [rln, setRln] = useState('');
    const [rlnError, setRlnError] = useState('');
    const [re, setRe] = useState('');
    const [reError, setReError] = useState('');
    const [videoHeight, setVideoHeight] = useState(0);

    useEffect(() => {
        const dditems = Object.entries(Relationship).map(x => { return { label: i18n.t(x[1]), value: x[0] }; });
        setItems(dditems);
        let recs = services.questionRecommendationService.initialRecommendations.filter(r => r.includeIn1p1q);
        shuffleRecs(recs);

        // load from local forage state?
        let pqs: PQState = LocalForageWrapper.getItem(CACHE_KEY);
        // if the state is more than 2 day sold, throw it away
        if (pqs && pqs.t < new Date(Date.now() - LOCAL_STATE_LIFETIME_MS).toISOString()) {
            LocalForageWrapper.setItem(CACHE_KEY, newState());
            pqs = LocalForageWrapper.getItem(CACHE_KEY);
        }

        let goToStep = 0;
        if (pqs) {
            setSfn(pqs.sfn.trim());
            setSln(pqs.sln.trim());
            setSfn2(pqs.sfn2 ? pqs.sfn2.trim() : '');
            setSln2(pqs.sln2 ? pqs.sln2.trim() : '');
            setSr(pqs.sr);
            setQ(pqs.q.trim());
            setC(pqs.c);
            setCq(pqs.cq.trim());
            setUcq(pqs.ucq);
            setN(pqs.n.trim());
            setNc(pqs.nc);
            setSe(pqs.se);
            setSe2(pqs.se2);
            setFn(commonContext.loggedInUser?.firstName ?? pqs.fn.trim());
            setLn(commonContext.loggedInUser?.lastName ?? pqs.ln.trim());
            setE(commonContext.loggedInUser?.email ?? pqs.e);

            if ((pqs.sfn2 != undefined && pqs.sfn2.length > 0) || (pqs.sln2 != undefined && pqs.sln2.length > 0)) {
                setShowSecondSpotlight(true);
            }

            // step 1: sfn, sln, sr
            // step 2: q
            // step 3: cq, ucq
            // step 4: n, nc
            // step 5: se, fn, ln, e

            if (pqs.sfn.length == 0 && pqs.sln.length == 0 && pqs.sr == "NONE" && pqs.sfn2.length == 0 && pqs.sln2.length == 0) {
                log.info('go to step 0');
                goToStep = 0;
            } else if (pqs.sfn.length == 0 || pqs.sln.length == 0 || pqs.sr == "NONE" || (pqs.sfn2.length > 0 && pqs.sln2.length == 0) || (pqs.sfn2.length == 0 && pqs.sln2.length > 0)) {
                log.info('go to step 1');
                goToStep = 1;
            } else if (!pqs.ucq && pqs.q.length == 0) {
                log.info('go to step 2');
                goToStep = 2;
            } else if (pqs.ucq && pqs.cq.length == 0) {
                log.info('go to step 3');
                goToStep = 3;
            } else if (!pqs.nc || pqs.n.length == 0) {
                log.info('go to step 4');
                goToStep = 4;
            } else if (pqs.se.length == 0 || pqs.fn.length == 0 || pqs.ln.length == 0 || pqs.e.length == 0 || !isValidEmail(pqs.se) || !isValidEmail(pqs.e) || (pqs.sfn2.length > 0 && (pqs.se2 == undefined || pqs.se2.length == 0)) || (pqs.se2 != undefined && pqs.se2.length > 0 && !isValidEmail(pqs.se2)) || (pqs.se2 != undefined && pqs.se2.toLowerCase() == pqs.se.toLowerCase())) {
                log.info('go to step 5');
                goToStep = 5;
            } else {
                log.info('go to step 6');
                goToStep = 6;
            }

            const selected = recs.findIndex(r => r.text == pqs.q);
            if (selected >= 0) {
                const thing = recs.splice(selected, 1);
                recs = [thing[0], ...recs];
            }
        } else {
            setSfn('');
            setSln('');
            setSfn2('');
            setSln2('');
            setSr('NONE');
            setQ('');
            setC('');
            setCq('');
            setUcq(false);
            setN(i18n.t('OnePersonOneQuestion_Fourth_Page_Note'));
            setNc(false);
            setSe('');
            setSe2('');
            setFn(commonContext.loggedInUser?.firstName ?? '');
            setLn(commonContext.loggedInUser?.lastName ?? '');
            setE(commonContext.loggedInUser?.email ?? '');
            setShowSecondSpotlight(false);
            goToStep = 0;
        }

        setStep(goToStep);
        setTotalQuestionOptions(recs);
        getOptions(recs, 0, QUESTIONS_PER_PAGE);
        setLastOptionIndex(QUESTIONS_PER_PAGE);
    }, [reset]);

    useEffect(() => {
        // analytics
        const pageName = getPageName(step);
        const readableState = getReadableState();
        commonContext.analytics?.viewPage(pageName, readableState);
    }, [step]);

    const getReadableState = () => {
        const s = {
            SpotlightFirstName: sfn,
            SpotlightLastName: sln,
            RelationshipToSpotlight: sr,
            SelectedQuestionText: q,
            SelectedQuestionCategory: c,
            CustomQuestionText: cq,
            UsingCustomQuestion: ucq,
            CustomNoteText: n,
            CustomNoteConfirmed: nc,
            SpotlightEmail: se,
            UserFirstName: fn,
            UserLastName: ln,
            UserEmail: e,
        };

        // if sfn2 and sln2 are set, add them to the state
        if (sfn2 != undefined && sfn2.length > 0) {
            s['SpotlightFirstName2'] = sfn2;
        }
        if (sln2 != undefined && sln2.length > 0) {
            s['SpotlightLastName2'] = sln2;
        }

        return s;
    }

    const getPageName = (step: number): string => {
        switch (step) {
            case 0:
                return 'Intro';
            case 1:
                return 'Choose Spotlight';
            case 2:
                return 'Select A Question';
            case 3:
                return 'Write Your Own Question';
            case 4:
                return 'Write A Custom Note';
            case 5:
                return 'Contact Info';
            case 6:
                return 'Send Your Question';
            case 7:
                return 'Question Sent';
            default:
                return 'Unknown';
        }
    }

    const validate1 = (): number => {
        let errorY = -1;
        setSfnError('');
        setSlnError('');
        setSrError('');
        setSfn2Error('');
        setSln2Error('');
        if (sfn == undefined || sfn.trim().length == 0) {// first name
            setSfnError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : sfnt;
        }
        if (sln == undefined || sln.trim().length == 0) {// last name
            setSlnError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : slnt;
        }

        // sfn2 and sln2 are optional, but if you include one then you must include the other
        if (sfn2 != undefined && sfn2.trim().length > 0 && (sln2 == undefined || sln2.trim().length == 0)) {
            setSln2Error(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : sln2t;
        } else if (sln2 != undefined && sln2.trim().length > 0 && (sfn2 == undefined || sfn2.trim().length == 0)) {
            setSfn2Error(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : sfn2t;
        }

        log.info(sr);
        if (sr == undefined || sr == "NONE") {// relationship
            log.info('sr is none');
            setSrError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : srt;
        }
        log.info(errorY);

        // analytics
        if (errorY == -1) {
            const pageName = getPageName(1);
            const formData = {
                SpotlightFirstName: sfn.trim(),
                SpotlightLastName: sln.trim(),
                RelationshipToSpotlight: sr,
            }
            commonContext.analytics?.submitFormData(pageName, formData);
        }

        return errorY;
    }

    const validate2 = (): number => {
        let errorY = -1;
        setUcq(false);
        setQError('');
        if (q.trim().length == 0) {
            setQError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : qt;
        }

        // analytics
        if (errorY == -1) {
            const pageName = getPageName(2);
            const formData = {
                UsingCustomQuestion: false,
                QuestionText: q.trim(),
                QuestionCategory: c,
            }
            commonContext.analytics?.submitFormData(pageName, formData);
        }

        return errorY;
    }

    const validate3 = (): number => {
        let errorY = -1;
        setCqError('');
        setUcq(true);
        if (cq.trim().length == 0) {
            setCqError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : cqt;
        }

        // analytics
        if (errorY == -1) {
            const pageName = getPageName(3);
            const formData = {
                UsingCustomQuestion: true,
                CustomQuestionText: cq.trim(),
            }
            commonContext.analytics?.submitFormData(pageName, formData);
        }

        return errorY;
    }

    const validate4 = (): number => {
        let errorY = -1;
        setNError('');
        if (n.trim().length == 0) {
            setNError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : nt;
        }

        // analytics
        if (errorY == -1) {
            const pageName = getPageName(4);
            const formData = {
                CustomNote: n.trim(),
            }
            commonContext.analytics?.submitFormData(pageName, formData);
        }

        return errorY;
    }

    const validate5 = (): number => {
        let errorY = -1;
        setSeError('');
        setSe2Error('');
        setFnError('');
        setLnError('');
        setEError('');
        if (se == undefined || se.length == 0) {// spotlight's email
            setSeError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : set;
        } else if (!isValidEmail(se)) {
            setSeError(i18n.t('OnePersonOneQuestion_InvalidEmail_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : set;
        }

        if (sfn2 != undefined && sfn2.length > 0) {
            if (se2 == undefined || se.length == 0) {// spotlight2's email
                setSe2Error(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
                errorY = errorY >= 0 ? errorY : se2t;
            } else if (!isValidEmail(se2)) {
                setSe2Error(i18n.t('OnePersonOneQuestion_InvalidEmail_ErrorMessage'));
                errorY = errorY >= 0 ? errorY : se2t;
            }
        }

        if (fn == undefined || fn.trim().length == 0) {// first name
            setFnError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : fnt;
        }
        if (ln == undefined || ln.trim().length == 0) {// last name
            setLnError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : lnt;
        }
        if (e == undefined || e.length == 0) {// email
            setEError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : et;
        } else if (!isValidEmail(e)) {
            setEError(i18n.t('OnePersonOneQuestion_InvalidEmail_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : et;
        }

        // if se2 is the same as se, then error
        if (se2 != undefined && se2.length > 0 && se2.toLowerCase() == se.toLowerCase()) {
            setSeError(i18n.t('OnePersonOneQuestion_SameEmail_ErrorMessage'));
            setSe2Error(i18n.t('OnePersonOneQuestion_SameEmail_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : se2t;
        }

        // analytics
        if (errorY == -1) {
            const pageName = getPageName(5);
            const formData = {
                SpotlightEmail: se,
                UserFirstName: fn.trim(),
                UserLastName: ln.trim(),
                UserEmail: e,
            }
            if (se2 != undefined && se2.length > 0) {
                formData['Spotlight2Email'] = se2;
            }
            commonContext.analytics?.submitFormData(pageName, formData);
        }

        return errorY;
    }

    // const validate6 = (): number => {
    //     return -1;
    // }

    // const validate7 = (): number => {
    //     let errorY = -1;
    //     setReError('');
    //     setRfnError('');
    //     setRlnError('');
    //     setReError('');
    //     if (re == undefined || re.length == 0) {// spotlight's email
    //         setReError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
    //         errorY = errorY >= 0 ? errorY : 0;
    //     } else if (!isValidEmail(re)) {
    //         setReError(i18n.t('OnePersonOneQuestion_InvalidEmail_ErrorMessage'));
    //         errorY = errorY >= 0 ? errorY : 0;
    //     }
    //     if (rfn == undefined || rfn.length == 0) {// first name
    //         setRfnError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
    //         errorY = errorY >= 0 ? errorY : 0;
    //     }
    //     if (rln == undefined || rln.length == 0) {// last name
    //         setRlnError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
    //         errorY = errorY >= 0 ? errorY : 0;
    //     }
    //     return errorY;
    // }

    function getOptions(options: RecommendationFromFile[], lastShownIndex: number, numberToAdd: number) {
        let newEnd = lastShownIndex + numberToAdd;
        if (newEnd > options.length) {
            newEnd = options.length;
        }

        const result = options.slice(0, newEnd);

        setNextQuestionOptions(result);
    }

    function getNextOptions() {
        getOptions(totalQuestionOptions, lastOptionIndex, QUESTIONS_PER_PAGE);
        setLastOptionIndex(lastOptionIndex + QUESTIONS_PER_PAGE);
        //setQ('');
    }

    const getFullState = () => {
        const fullState: PQState = {
            sfn: sfn.trim(),
            sln: sln.trim(),
            sfn2: sfn2.trim(),
            sln2: sln2.trim(),
            sr: sr,
            q: q.trim(),
            c: c,
            cq: cq.trim(),
            ucq: ucq,
            n: n,
            nc: nc,
            se: se,
            se2: se2,
            fn: fn.trim(),
            ln: ln.trim(),
            e: e,
            t: new Date(Date.now()).toISOString(),
        };
        return fullState;
    }

    useEffect(() => {
        // update local forage state
        const fullState = getFullState();
        LocalForageWrapper.setItem(CACHE_KEY, fullState);
    }, [sfn, sln, sfn2, sln2, sr, q, c, cq, ucq, n, nc, se, se2, fn, ln, e]);

    function shuffleRecs(r: any[]) {
        for (let i = r.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [r[i], r[j]] = [r[j], r[i]];
        }
    }

    const createCommunityAndStuff = async () => {
        log.info(`CREATE 1P1Q COMMUNITY WITH:`);
        log.info(JSON.stringify(getFullState(), null, 2));

        // analytics
        const pageName = getPageName(6);
        const formData = getReadableState();
        commonContext.analytics?.submitFormData(pageName, formData);

        // start async stuff
        setIsSaving(true);

        try {
            const response: CreateCommunityUnauthenticatedResponse | undefined = await createCommunityUnauthenticated(getFullState());
            log.info(JSON.stringify(response, null, 2));
            setNewCommunityId(response?.community.id);

            // we need to refresh the logged-in user's auth tokens to reflect new community membership
            if (commonContext.loggedInUser) {
                await refreshAuthToken();
            }

            // go to next step?
            setIsSaving(false);
            LocalForageWrapper.removeItem(CACHE_KEY);
            setStep(7);
        } catch (ex) {
            if (ex instanceof CommunityServiceError) {
                setSubmitError(ex.userMessage);
            }
            log.error(ex);
            setIsSaving(false);
            //setIsLoading(false);
        }
    }

    const createAccount = () => {
        log.info('go to create account');
        props.navigation.navigate('Sign Up');
        setReset(!reset);
        // if (Platform.OS == 'web') {
        //     window.open('https://meetnovella.com', "_self");
        // } else {
        //     Linking.openURL('https://meetnovella.com');
        // }
    }

    const done = () => {
        log.info('done');
        linkTo(`/communities/${commonContext.loggedInUser?.id}`);
        setReset(!reset);

        // if (Platform.OS == 'web') {
        //     window.open('https://meetnovella.com', "_self");
        // } else {
        //     Linking.openURL('https://meetnovella.com');
        // }
    }

    const styles = {
        body: {
            //fontFamily: 'Arial',
            //display: 'flex',
            fontSize: 16,
            lineHeight: 22,
            fontWeight: '400',
            textAlign: 'left',
            color: 'black',
            textDecorationLine: 'none',
            width: '100%',
        },
        prompt: {
            //fontFamily: 'Arial',
            fontSize: 16,
            fontWeight: '400',
            textAlign: 'right',
            whiteSpace: 'nowrap',
            padding: 6,
            paddingLeft: 2,
        },
        link: {
            //fontFamily: 'Arial',
            fontSize: 16,
            lineHeight: 22,
            fontWeight: '700',
            textAlign: 'left',
            marginTop: 10,
            textDecorationLine: 'underline',
            whiteSpace: 'nowrap',
            color: theme.colors.primary,
            width: '100%',
        },
        important: {
            //fontFamily: 'Arial',
            fontSize: 14,
            fontWeight: '400',
            textAlign: 'center',
            fontStyle: 'italic',
            marginTop: 10,
        },
        subHeading: {
            //fontFamily: 'Arial',
            fontSize: 16,
            fontWeight: '700',
            color: Palette.purple,
            //lineHeight: 16,
            textAlign: 'center',
            marginTop: 20,
        },
        inputHeading: {
            //fontFamily: 'Arial',
            fontSize: 16,
            fontWeight: '700',
            textAlign: 'left',
            width: '100%',
            marginTop: 20,
            color: Palette.purple,
        },
        question: {
            //fontFamily: 'Arial',
            fontSize: 16,
            fontWeight: '300',
            width: '100%',
            textAlign: 'center',
            color: theme.colors.text,
        },
        errorMessage: {
            //fontFamily: 'Arial',
            fontSize: 12,
            fontWeight: '400',
            textAlign: 'left',
            width: '100%',
            margin: 5,
            color: theme.colors.danger,
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
            //maxWidth: 600,
            paddingVertical: 12,
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            //maxWidth: 600,
            padding: 12,
        },
        columnShort: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            //height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            //maxWidth: 600,
            padding: 12,
        },
        unselectedButton: {
            flex: 1,
            borderColor: Palette.transparent,
            borderWidth: 2,
            borderRadius: 12,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 10,
        },
        selectedButton: {
            borderColor: theme.colors.primary,
        },
        buttonText: {
            ...theme.textVariants.askQuestionHeader,
            color: theme.colors.text,
            marginTop: 6,
            textAlign: 'center',
        },
        label: {
            //...theme.textVariants.inputLabel,
            //fontFamily: 'Arial',
            fontSize: normalizeWidth(14, dimensions.width),
            fontWeight: '700',
            color: theme.colors.text,
        },
        unselectedQuestion: {
            //flex: 1,
            borderColor: Palette.lightgrey,
            borderWidth: 2,
            borderRadius: 12,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 12,
            marginVertical: 4,
            //overflow: 'scroll',
            minHeight: 60,
            width: '100%',
        },
        selectedQuestion: {
            borderColor: Palette.teal,
            borderWidth: 2,
            backgroundColor: Palette.lightteal.concat('55'),
            fontWeight: 'bold',
        },
        infoText: {
            ...theme.textVariants.body,
            paddingHorizontal: 14,
        },
        infoHeader: {
            ...theme.textVariants.headerSecondTitle,
            textAlign: 'center',
        },
        infoRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 14,
            marginBottom: normalizeHeight(14, height),
            minHeight: normalizeHeight(60, height),
        }
    }

    function onLayoutInput(e: LayoutChangeEvent, getTop: (y: number) => void, control?: React.RefObject<React.Component>): void {
        if (control && control.current != null && control.current.measure) {
            control.current.measure((vx, vy, width, height, px, py) => {
                log.info(`${vx} : ${vy} : ${width} : ${height} : ${px} : ${py}`);
                const top = py;
                getTop(top - 50);
            });
        }
    }

    const fireAnalyticsEvent = (email: string) => {
        setExpressedInterest(true);
        if (commonContext.analytics) {
            commonContext.analytics.logEvent(
                'expressed_interest',
                {
                    interest: 'recording_videos_on_computer',
                    email: email,
                }
            )
        }
    }

    const headerSteps = [
        i18n.t(`OnePersonOneQuestion_Header_Step_Intro`),
        i18n.t(`OnePersonOneQuestion_Header_Step_Spotlight`),
        i18n.t(`OnePersonOneQuestion_Header_Step_Question`),
        i18n.t(`OnePersonOneQuestion_Header_Step_CustomNote`),
        i18n.t(`OnePersonOneQuestion_Header_Step_ContactInfo`),
        i18n.t(`OnePersonOneQuestion_Header_Step_Send`)
    ];

    const onLayout = (e: LayoutChangeEvent) => {
        const { height } = e.nativeEvent.layout;
        setVideoHeight(height - 100);
    }

    function renderWalkthrough(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        return (
            <View style={{ display: 'flex', flex: 1/* test */ }}>
            {/* <> */}
                {step == 0 &&
                    <View style={{ display: 'flex', flex: 1/* test */ }}>
                        <StepHeader floating={navigation == null} stepDisplayNumber={0} stepTitles={headerSteps} />
                        <PQPage
                            hasSteps={true}
                            navigation={navigation}
                            title={i18n.t('OnePersonOneQuestion_Intro_Page_Title')}
                            next={async () => setStep(1)}
                            back={props.navigation.goBack}
                            onLayout={onLayout}
                        >
                            <View style={styles.column}>
                                <NText style={[styles.body, { marginBottom: Platform.OS == 'web' ? 20 : 12 }]}>
                                    {i18n.t('OnePersonOneQuestion_Intro_Page_Body')}
                                </NText>
                                {/* <View style={{ ...styles.column, flex: 1 }}> */}
                                <VimeoPlayer
                                    embedUrl={`https://player.vimeo.com/video/778647119?h=cf51a0b614&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                                    asset={FALLBACK_VIDEO}
                                    height={videoHeight}
                                    width={videoHeight * 0.5647}
                                    transcodingStatus={'complete'}
                                    //heightConstrained={Platform.OS === 'web'}
                                />
                                {/* </View> */}

                            </View>
                        </PQPage>
                    </View>}
                {step == 1 &&
                    <View style={{ display: 'flex', flex: 1/* test */ }}>
                        <StepHeader floating={navigation == null} stepDisplayNumber={1} stepTitles={headerSteps} />
                        <PQPage
                            hasSteps={true}
                            navigation={navigation}
                            title={i18n.t('OnePersonOneQuestion_First_Page_Title')}
                            image={<Family width={60} height={60} />}
                            //imageSource={require(`../../assets/svg/illustrations/Family.svg`)}
                            validate={validate1}
                            next={async () => setStep(2)}
                            back={() => setStep(0)}
                            infoChildren={<>
                                <NText style={[styles.infoHeader, { marginBottom: 14, }]}>
                                    {i18n.t('OnePersonOneQuestion_First_Second_Third_Page_InfoHeader')}
                                </NText>
                                <NText style={[styles.infoText, { marginBottom: 14, }]}>
                                    {i18n.t('OnePersonOneQuestion_First_Second_Third_Page_InfoText', { 0: sfn2 != undefined && sfn2.length > 0 ? i18n.t('OnePersonOneQuestion_SpotlightPlural') : i18n.t('OnePersonOneQuestion_SpotlightSingular') })}
                                </NText>
                            </>}
                        >
                            <View style={styles.column}>
                                <NText style={styles.body}>
                                    {i18n.t('OnePersonOneQuestion_First_Page_Body')}
                                </NText>
                                <View style={{ ...styles.column, padding: 0, flex: 1, alignItems: 'flex-start' }}>
                                    {/* <NText style={styles.subHeading}>
                                        {i18n.t('OnePersonOneQuestion_First_Page_Subheading')}
                                    </NText> */}
                                    <NText style={styles.inputHeading}>
                                        {i18n.t('OnePersonOneQuestion_First_Page_InputHeading')}
                                    </NText>
                                    <View
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                            padding: 10,
                                            borderRadius: 10,
                                            borderWidth: 1,
                                            borderColor: '#DDEAEB',
                                            marginVertical: 6,
                                        }}
                                    >
                                        <NTextInput
                                            ref={sfnRef}
                                            onLayout={(e) => onLayoutInput(e, setSfnt, sfnRef)}
                                            value={sfn}
                                            onChangeText={setSfn}
                                            maxLength={128}
                                            hideMaxLength
                                            label={i18n.t('OnePersonOneQuestion_First_Page_FirstName')}
                                            labelStyle={styles.label}
                                            errorMessage={sfnError} />
                                        <NTextInput
                                            ref={slnRef}
                                            onLayout={(e) => onLayoutInput(e, setSlnt, slnRef)}
                                            value={sln}
                                            onChangeText={setSln}
                                            maxLength={128}
                                            hideMaxLength
                                            label={i18n.t('OnePersonOneQuestion_First_Page_LastName')}
                                            labelStyle={styles.label}
                                            errorMessage={slnError} />
                                    </View>
                                    {/* {!showSecondSpotlight &&
                                        <NButton
                                            t={'secondary'}
                                            title={`Add spotlight member`}
                                            titleStyle={{
                                                ...theme.textVariants.detail,
                                                fontSize: 14,
                                            }}
                                            icon={<Icon
                                                iconStyle={{
                                                    marginEnd: 4,
                                                }}
                                                size={20}
                                                name={'add'}
                                                type='ionicon'
                                                color={sfn.length == 0 ? Palette.grey : (Platform.OS != 'web' ? Palette.plum : theme.colors.primary)} />}
                                            disabled={sfn.length == 0}
                                            onPress={() => setShowSecondSpotlight(true)} />}
                                    {showSecondSpotlight &&
                                        <View
                                            style={{
                                                display: 'flex',
                                                width: '100%',
                                                padding: 10,
                                                borderRadius: 10,
                                                borderWidth: 1,
                                                borderColor: '#DDEAEB',
                                                marginVertical: 6,
                                            }}
                                        >
                                            <NTextInput
                                                ref={sfn2Ref}
                                                onLayout={(e) => onLayoutInput(e, setSfn2t, sfn2Ref)}
                                                placeholder={sln2 != undefined && sln2.length > 0 ? `` : i18n.t(`Optional_Field_Placeholder`)}
                                                value={sfn2}
                                                onChangeText={setSfn2}
                                                maxLength={128}
                                                hideMaxLength
                                                label={i18n.t('OnePersonOneQuestion_First_Page_FirstName')}
                                                labelStyle={styles.label}
                                                errorMessage={sfn2Error} />
                                            <NTextInput
                                                ref={sln2Ref}
                                                onLayout={(e) => onLayoutInput(e, setSln2t, sln2Ref)}
                                                placeholder={sfn2 != undefined && sfn2.length > 0 ? `` : i18n.t(`Optional_Field_Placeholder`)}
                                                value={sln2}
                                                onChangeText={setSln2}
                                                maxLength={128}
                                                hideMaxLength
                                                label={i18n.t('OnePersonOneQuestion_First_Page_LastName')}
                                                labelStyle={styles.label}
                                                errorMessage={sln2Error} />
                                        </View>} */}
                                    <View
                                        style={{
                                            width: '100%',
                                            paddingVertical: 14,
                                        }}
                                    >
                                        {/* This is just a "Text" component because the label style already has a normalized font */}
                                        <Text style={styles.label}>
                                            {i18n.t('OnePersonOneQuestion_First_Page_Relationship', { 0: sfn.length > 0 ? sfn : i18n.t('OnePersonOneQuestion_First_Page_RelationshipPrompt_Default') })}
                                        </Text>
                                        <View
                                            ref={srRef}
                                            style={{
                                                display: 'flex',
                                                //flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                justifyContent: 'flex-start',
                                                alignItems: 'center',
                                                width: '100%',
                                                paddingTop: 6,
                                                paddingBottom: 20,
                                            }}
                                            onLayout={(e) => onLayoutInput(e, setSrt, srRef)}
                                        >
                                            <Text style={styles.prompt}>
                                                {sfn2.length == 0
                                                    ? i18n.t('OnePersonOneQuestion_First_Page_RelationshipPrompt', { 0: sfn.length > 0 ? sfn : i18n.t('OnePersonOneQuestion_First_Page_RelationshipPrompt_Default') })
                                                    : i18n.t('OnePersonOneQuestion_First_Page_RelationshipPrompt2', { 0: sfn, 1: sfn2 })}
                                            </Text>
                                            {srError.length > 0 &&
                                                <Text style={[styles.errorMessage]}>
                                                    {srError}
                                                </Text>}
                                            <NDropDownPicker
                                                dropDownDirection={'TOP'}
                                                open={open}
                                                setOpen={setOpen}
                                                value={sr}
                                                setValue={setSr}
                                                onChangeValue={(s) => {
                                                    if (s)
                                                        setSr(s);
                                                }}
                                                items={items}
                                                setItems={setItems}
                                                onPressItem={(s) => {
                                                    setSr(s);
                                                    setOpen(false);
                                                }}
                                                textStyle={{
                                                    fontSize: 16,
                                                    fontWeight: '400',
                                                }} />

                                        </View>
                                    </View>
                                </View>
                            </View>
                        </PQPage>
                    </View>}
                {step == 2 &&
                    <View style={{ display: 'flex', flex: 1/* test */ }}>
                        <StepHeader floating={navigation == null} stepDisplayNumber={2} stepTitles={headerSteps} />
                        <PQPage
                            hasSteps={true}
                            navigation={navigation}
                            title={i18n.t('OnePersonOneQuestion_Second_Page_Title')}
                            image={<Message width={60} height={60} />}
                            //imageSource={require(`../../assets/svg/illustrations/Message.svg`)}
                            validate={validate2}
                            next={async () => {
                                setUcq(false);
                                setStep(4);
                            }}
                            back={() => setStep(1)}
                            infoChildren={<>
                                <NText style={[styles.infoHeader, { marginBottom: 14, }]}>
                                    {i18n.t('OnePersonOneQuestion_First_Second_Third_Page_InfoHeader')}
                                </NText>
                                <NText style={[styles.infoText, { marginBottom: 14, }]}>
                                    {i18n.t('OnePersonOneQuestion_First_Second_Third_Page_InfoText', { 0: sfn2 != undefined && sfn2.length > 0 ? i18n.t('OnePersonOneQuestion_SpotlightPlural') : i18n.t('OnePersonOneQuestion_SpotlightSingular') })}
                                </NText>
                            </>}
                        >
                            <View style={styles.column}>
                                <NText style={styles.body}>
                                    {i18n.t('OnePersonOneQuestion_Second_Page_Body1')}
                                    <NText
                                        style={styles.link}
                                        onPress={() => {
                                            setQ('');
                                            setC('');
                                            setStep(3);
                                        }}
                                    >
                                        {i18n.t('OnePersonOneQuestion_Second_Page_Body2')}
                                    </NText>
                                </NText>
                                <NText style={styles.subHeading}>
                                    {sfn2.length == 0
                                        ? i18n.t('OnePersonOneQuestion_Second_Page_Subheading', { 0: sfn.length > 0 ? sfn : i18n.t('OnePersonOneQuestion_First_Page_RelationshipPrompt_Default') })
                                        : i18n.t('OnePersonOneQuestion_Second_Page_Subheading2', { 0: sfn, 1: sfn2 })}
                                </NText>
                                {qError.length > 0 &&
                                    <Text style={styles.errorMessage}>
                                        {qError}
                                    </Text>}
                                <View style={[
                                    styles.columnShort,
                                    qError.length > 0 ? { borderColor: theme.colors.danger, borderWidth: 1, borderRadius: 12 } : {}
                                ]}>
                                    {nextQuestionOptions.map((question, i) => (
                                        <Pressable
                                            key={i}
                                            onPress={() => {
                                                setQ(question.text);
                                                setC(question.category);
                                            }}
                                            style={[
                                                styles.unselectedQuestion,
                                                q != question.text ? {} : styles.selectedQuestion,
                                            ]}
                                        >
                                            <NText style={styles.question}>
                                                {question.text}
                                            </NText>
                                        </Pressable>
                                    ))}
                                </View>
                                {nextQuestionOptions.length < totalQuestionOptions.length &&
                                    <NButton
                                        t={'mild2'}
                                        style={{
                                            marginVertical: 6,
                                        }}
                                        title={i18n.t(`OnePersonOneQuestion_More`)}
                                        onPress={getNextOptions} />}
                                <NText
                                    style={[
                                        styles.link,
                                        {
                                            textAlign: 'center',
                                        }
                                    ]}
                                    onPress={() => {
                                        setQ('');
                                        setC('');
                                        setStep(3);
                                    }}
                                >
                                    {i18n.t(`OnePersonOneQuestion_Own_Question`)}
                                </NText>
                            </View>
                        </PQPage>
                    </View>}
                {step == 3 &&
                    <View style={{ display: 'flex', flex: 1/* test */ }}>
                        <StepHeader floating={navigation == null} stepDisplayNumber={2} stepTitles={headerSteps} />
                        <PQPage
                            hasSteps={true}
                            navigation={navigation}
                            title={i18n.t('OnePersonOneQuestion_Third_Page_Title')}
                            image={<Message width={60} height={60} />}
                            //imageSource={require(`../../assets/svg/illustrations/Message.svg`)}
                            validate={validate3}
                            next={async () => {
                                setStep(4);
                            }}
                            back={() => setStep(2)}
                            infoChildren={<>
                                <NText style={[styles.infoHeader, { marginBottom: 14, }]}>
                                    {i18n.t('OnePersonOneQuestion_First_Second_Third_Page_InfoHeader')}
                                </NText>
                                <NText style={[styles.infoText, { marginBottom: 14, }]}>
                                    {i18n.t('OnePersonOneQuestion_First_Second_Third_Page_InfoText', { 0: sfn2 != undefined && sfn2.length > 0 ? i18n.t('OnePersonOneQuestion_SpotlightPlural') : i18n.t('OnePersonOneQuestion_SpotlightSingular') })}
                                </NText>
                            </>}
                        >
                            <View style={styles.column}>
                                <NText style={styles.body}>
                                    {sfn2.length == 0
                                        ? i18n.t('OnePersonOneQuestion_Third_Page_Body', { 0: sfn.length > 0 ? sfn : i18n.t('OnePersonOneQuestion_First_Page_RelationshipPrompt_Default') })
                                        : i18n.t('OnePersonOneQuestion_Third_Page_Body2', { 0: sfn, 1: sfn2 })}
                                </NText>
                                <NText style={styles.inputHeading}>
                                    {i18n.t('OnePersonOneQuestion_Third_Page_InputHeading')}
                                </NText>
                                <NTextInput
                                    ref={cqRef}
                                    onLayout={(e) => onLayoutInput(e, setCqt, cqRef)}
                                    multiline={true}
                                    //numberOfLines={4}
                                    maxLength={1000}
                                    placeholder="Type your question here..."
                                    value={cq}
                                    onChangeText={setCq}
                                    placeholderTextColor={theme.colors.text}
                                    errorMessage={cqError}
                                    scrollEnabled={false} />
                            </View>
                        </PQPage>
                    </View>}
                {step == 4 &&
                    <View style={{ display: 'flex', flex: 1/* test */ }}>
                        <StepHeader floating={navigation == null} stepDisplayNumber={3} stepTitles={headerSteps} />
                        <PQPage
                            hasSteps={true}
                            navigation={navigation}
                            title={i18n.t('OnePersonOneQuestion_Fourth_Page_Title')}
                            image={<People width={60} height={60} />}
                            //imageSource={require(`../../assets/svg/illustrations/People.svg`)}
                            validate={validate4}
                            next={async () => {
                                setNc(true);
                                setStep(5);
                            }}
                            back={() => {
                                if (ucq) {
                                    setStep(3);
                                } else {
                                    setStep(2);
                                }
                            }}
                        >
                            <View style={[styles.column]}>
                                <NText style={styles.body}>
                                    {i18n.t('OnePersonOneQuestion_Fourth_Page_Body1')}
                                    <NText
                                        style={styles.link}
                                        onPress={() => { setShowPreview(true); }}
                                    >
                                        {i18n.t('OnePersonOneQuestion_Fourth_Page_Body2')}
                                    </NText>
                                    <NText style={styles.body}>
                                        {sfn2.length == 0
                                            ? i18n.t('OnePersonOneQuestion_Fourth_Page_Body3', { 0: sfn.length > 0 ? sfn : i18n.t('OnePersonOneQuestion_First_Page_RelationshipPrompt_Default') })
                                            : i18n.t('OnePersonOneQuestion_Fourth_Page_Body32', { 0: sfn, 1: sfn2 })}
                                    </NText>
                                </NText>
                                {/* <NText style={[styles.body, { marginTop: 14 }]}>
                                    {i18n.t('OnePersonOneQuestion_Fourth_Page_Body4')}
                                </NText> */}
                                <NText style={styles.inputHeading}>
                                    {i18n.t('OnePersonOneQuestion_Fourth_Page_InputHeading')}
                                </NText>
                                <NTextInput
                                    ref={nRef}
                                    onLayout={(e) => onLayoutInput(e, setNt, nRef)}
                                    multiline={true}
                                    // style={{
                                    //     height: normalizeHeight(150, commonContext.dimensions.height),
                                    // }}
                                    maxLength={1000}
                                    placeholder="Type your note to the spotlight here..."
                                    value={n}
                                    onChangeText={setN}
                                    placeholderTextColor={theme.colors.text}
                                    errorMessage={nError}
                                    scrollEnabled={false}    
                                />
                                <NText style={styles.body}>
                                    {i18n.t('OnePersonOneQuestion_Fourth_Page_Body5')}
                                </NText>
                                <NText
                                    style={styles.link}
                                    onPress={() => { setShowPreview(true); }}
                                >
                                    {i18n.t('OnePersonOneQuestion_SampleEmailLink_Text')}
                                </NText>
                            </View>
                        </PQPage>
                    </View>}
                {step == 5 &&
                    <View style={{ display: 'flex', flex: 1/* test */ }}>
                        <StepHeader floating={navigation == null} stepDisplayNumber={4} stepTitles={headerSteps} />
                        <PQPage
                            hasSteps={true}
                            navigation={navigation}
                            title={i18n.t('OnePersonOneQuestion_Fifth_Page_Title')}
                            image={<Question width={60} height={60} />}
                            //imageSource={require(`../../assets/svg/illustrations/Question.svg`)}
                            validate={validate5}
                            next={async () => setStep(6)}
                            back={() => setStep(4)}
                            infoChildren={<View
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    paddingBottom: 50,
                                }}
                            >
                                <NText style={[styles.infoHeader, { marginBottom: 14, }]}>
                                    {i18n.t('OnePersonOneQuestion_Fifth_Page_InfoText1')}
                                </NText>
                                <NText style={[styles.infoText, { marginBottom: 14, }]}>
                                    {i18n.t('OnePersonOneQuestion_Fifth_Page_InfoText2')}
                                </NText>
                                {!expressedInterest &&
                                    <View style={styles.infoRow}>
                                        <NTextInput
                                            style={{
                                                flex: 1,
                                                marginEnd: 6,
                                            }}
                                            containerStyle={{
                                                flexShrink: 1,
                                            }}
                                            onLayout={(e) => onLayoutInput(e, setEt)}
                                            value={e}
                                            onChangeText={setE}
                                            maxLength={128}
                                            hideMaxLength
                                            errorMessage={eError}
                                            disabled={commonContext.loggedInUser != undefined}
                                            focusable={commonContext.loggedInUser == undefined} />
                                        <NButton
                                            t='primary'
                                            title={i18n.t('OnePersonOneQuestion_Submit')}
                                            onPress={() => fireAnalyticsEvent(e)} />
                                    </View>}
                                {expressedInterest &&
                                    <View
                                        style={[
                                            styles.infoRow,
                                            { backgroundColor: Palette.lightteal.concat('55') }
                                        ]}
                                    >
                                        <NText style={[styles.infoText, { textAlign: 'center', }]}>
                                            {i18n.t('OnePersonOneQuestion_Fifth_Page_InfoConfirm')}
                                        </NText>
                                    </View>}
                                <NText style={styles.infoText}>
                                    {i18n.t('OnePersonOneQuestion_Fifth_Page_InfoText3')}
                                </NText>
                            </View>}
                        >
                            <View style={styles.column}>
                                <NText style={styles.body}>
                                    {sfn2.length == 0
                                        ? i18n.t('OnePersonOneQuestion_Fifth_Page_Body', { 0: sfn.length > 0 ? sfn : i18n.t('OnePersonOneQuestion_First_Page_RelationshipPrompt_Default') })
                                        : i18n.t('OnePersonOneQuestion_Fifth_Page_Body2', { 0: sfn, 1: sfn2 })}
                                </NText>
                                <NText style={styles.inputHeading}>
                                    {i18n.t('OnePersonOneQuestion_Fifth_Page_InputHeading1', { 0: sfn.length > 0 ? sfn : i18n.t('OnePersonOneQuestion_First_Page_RelationshipPrompt_Default') })}
                                </NText>
                                <NTextInput
                                    ref={seRef}
                                    onLayout={(e) => onLayoutInput(e, setSet, seRef)}
                                    value={se}
                                    onChangeText={setSe}
                                    maxLength={128}
                                    hideMaxLength
                                    labelStyle={styles.label}
                                    errorMessage={seError} />
                                {sfn2.length > 0 &&
                                    <View
                                        style={{
                                            display: 'flex',
                                            width: '100%',
                                        }}
                                    >
                                        <NText style={styles.inputHeading}>
                                            {i18n.t('OnePersonOneQuestion_Fifth_Page_InputHeading1', { 0: sfn2 })}
                                        </NText>
                                        <NTextInput
                                            ref={seRef}
                                            onLayout={(e) => onLayoutInput(e, setSe2t, se2Ref)}
                                            value={se2}
                                            onChangeText={setSe2}
                                            maxLength={128}
                                            hideMaxLength
                                            labelStyle={styles.label}
                                            errorMessage={se2Error} />
                                    </View>}
                                <NText style={styles.inputHeading}>
                                    {i18n.t('OnePersonOneQuestion_Fifth_Page_InputHeading2')}
                                </NText>
                                <NTextInput
                                    ref={fnRef}
                                    onLayout={(e) => onLayoutInput(e, setFnt, fnRef)}
                                    value={fn}
                                    onChangeText={setFn}
                                    maxLength={128}
                                    hideMaxLength
                                    label={i18n.t('OnePersonOneQuestion_Fifth_Page_FirstName')}
                                    labelStyle={styles.label}
                                    errorMessage={fnError}
                                    disabled={commonContext.loggedInUser != undefined}
                                    focusable={commonContext.loggedInUser == undefined} />
                                <NTextInput
                                    ref={lnRef}
                                    onLayout={(e) => onLayoutInput(e, setLnt, lnRef)}
                                    value={ln}
                                    onChangeText={setLn}
                                    maxLength={128}
                                    hideMaxLength
                                    label={i18n.t('OnePersonOneQuestion_Fifth_Page_LastName')}
                                    labelStyle={styles.label}
                                    errorMessage={lnError}
                                    disabled={commonContext.loggedInUser != undefined}
                                    focusable={commonContext.loggedInUser == undefined} />
                                <NTextInput
                                    ref={eRef}
                                    onLayout={(e) => onLayoutInput(e, setEt, eRef)}
                                    value={e}
                                    onChangeText={setE}
                                    maxLength={128}
                                    hideMaxLength
                                    label={i18n.t('OnePersonOneQuestion_Fifth_Page_Email')}
                                    labelStyle={styles.label}
                                    errorMessage={eError}
                                    disabled={commonContext.loggedInUser != undefined}
                                    focusable={commonContext.loggedInUser == undefined} />
                                <NText style={styles.important}>
                                    {i18n.t('OnePersonOneQuestion_Privacy')}
                                </NText>
                            </View>
                        </PQPage>
                    </View>}
                {step == 6 &&
                    <View style={{ display: 'flex', flex: 1/* test */ }}>
                        <StepHeader floating={navigation == null} stepDisplayNumber={5} stepTitles={headerSteps} />
                        <PQPage
                            hasSteps={true}
                            navigation={navigation}
                            title={i18n.t('OnePersonOneQuestion_Sixth_Page_Title')}
                            image={<Envelope width={60} height={60} />}
                            //imageSource={require(`../../assets/svg/illustrations/Envelope.svg`)}
                            back={() => setStep(5)}
                            next={createCommunityAndStuff}
                            nextType={'submit'}
                        >
                            <View style={styles.column}>
                                <NText style={styles.body}>
                                    {i18n.t('OnePersonOneQuestion_Sixth_Page_Body1a')}
                                    <NText
                                        style={styles.link}
                                        onPress={() => { setShowPreview(true); }}
                                    >
                                        {i18n.t('OnePersonOneQuestion_Sixth_Page_Body1b')}
                                    </NText>
                                    {sfn2.length == 0
                                        ? i18n.t('OnePersonOneQuestion_Sixth_Page_Body1c', { 0: sfn.length > 0 ? sfn : i18n.t('OnePersonOneQuestion_First_Page_RelationshipPrompt_Default') })
                                        : i18n.t('OnePersonOneQuestion_Sixth_Page_Body1c2', { 0: sfn, 1: sfn2 })}
                                    <NText
                                        style={styles.body}
                                    >
                                        {i18n.t('OnePersonOneQuestion_Sixth_Page_Body1d')}
                                    </NText>
                                    {i18n.t('OnePersonOneQuestion_Sixth_Page_Body1e')}
                                </NText>
                                <NText style={[styles.body, { marginTop: 12, }]}>
                                    {i18n.t('OnePersonOneQuestion_Sixth_Page_Body1f')}
                                </NText>
                                <NText style={[styles.body, { marginTop: 12, }]}>
                                    {i18n.t('OnePersonOneQuestion_Sixth_Page_Body1g')}
                                </NText>

                                <NText style={styles.label}>
                                    {submitError}
                                </NText>
                                {/* <NButton
                                    t={'primary'}
                                    title={i18n.t('OnePersonOneQuestion_Submit')}
                                    style={{
                                        marginTop: 20,
                                    }}
                                    onPress={createCommunityAndStuff}
                                    loading={isSaving}
                                    disabled={isSaving}
                                /> */}
                            </View>
                        </PQPage>
                    </View>}
                {step == 7 &&
                    <View style={{ display: 'flex', flex: 1/* test */ }}>
                        <StepHeader floating={navigation == null} stepDisplayNumber={5} stepTitles={headerSteps} />
                        <PQPage
                            hasSteps={true}
                            navigation={navigation}
                            title={i18n.t('OnePersonOneQuestion_Seventh_Page_Title')}
                        >
                            <View style={styles.column}>
                                <NText style={[styles.body]}>
                                    {i18n.t('OnePersonOneQuestion_Seventh_Page_Body1')} &#127881; {/* party popper emoji */}
                                </NText>
                                {
                                    !commonContext.loggedInUser &&
                                    <>
                                        <NText style={[styles.body, { marginTop: 14, }]}>
                                            {i18n.t('OnePersonOneQuestion_Seventh_Page_Body2')}
                                        </NText>
                                        <NText style={[styles.body, { marginTop: 14, }]}>
                                            {i18n.t('OnePersonOneQuestion_Seventh_Page_Body3')}
                                        </NText>
                                    </>
                                }
                                {
                                    commonContext.loggedInUser &&
                                    <>
                                        <NText style={[styles.body, { marginTop: 14, }]}>
                                            {i18n.t('OnePersonOneQuestion_Seventh_Page_Body2_Auth')}
                                        </NText>
                                        <NText style={[styles.body, { marginTop: 14, }]}>
                                            {i18n.t('OnePersonOneQuestion_Seventh_Page_Body3_Auth')}
                                        </NText>
                                    </>
                                }
                                <NText style={[styles.body, { marginTop: 14, }]}>
                                    <NText
                                        style={[styles.link]}
                                        onPress={() => Linking.openURL("https://www.meetnovella.com/faqs")}
                                    >
                                        {i18n.t('OnePersonOneQuestion_Seventh_Page_Body5a')}
                                    </NText>
                                    {i18n.t('OnePersonOneQuestion_Seventh_Page_Body5b')}
                                </NText>
                                {
                                    !commonContext.loggedInUser &&
                                    <NButton
                                        t={'secondary'}
                                        title={i18n.t('OnePersonOneQuestion_CreateAccount')}
                                        style={{
                                            marginTop: 20,
                                        }}
                                        onPress={createAccount} />
                                }
                                {
                                    commonContext.loggedInUser &&
                                    <NButton
                                        t={'secondary'}
                                        title={i18n.t('OnePersonOneQuestion_MyCommunities')}
                                        style={{
                                            marginTop: 20,
                                        }}
                                        onPress={done} />
                                }
                                <NText style={[styles.body, { marginTop: 20, textAlign: 'center' }]}>
                                    {i18n.t('OnePersonOneQuestion_Seventh_Page_Body4')}
                                    <NText
                                        style={styles.link}
                                        onPress={() => Linking.openURL(`mailto:${i18n.t('OnePersonOneQuestion_TeamEmailAddress')}`)}
                                    >
                                        {i18n.t('OnePersonOneQuestion_TeamEmailAddress')}
                                    </NText>
                                </NText>
                                {/*   <NText style={styles.subHeading}>
                {i18n.t('OnePersonOneQuestion_Seventh_Page_Subtitle')}
            </NText>
              <NText style={{
                ...styles.body,
                marginTop: 12,
            }}>
                {i18n.t('OnePersonOneQuestion_Seventh_Page_Body2')}
            </NText>
              <NText style={styles.inputHeading}>
                {i18n.t('OnePersonOneQuestion_Seventh_Page_InputHeading')}
            </NText>
            <NTextInput
                value={rfn}
                onChangeText={setRfn}
                maxLength={128}
                hideMaxLength
                label={i18n.t('OnePersonOneQuestion_Seventh_Page_FirstName')}
                labelStyle={styles.label}
                errorMessage={rfnError}
            />
            <NTextInput
                value={rln}
                onChangeText={setRln}
                maxLength={128}
                hideMaxLength
                label={i18n.t('OnePersonOneQuestion_Seventh_Page_LastName')}
                labelStyle={styles.label}
                errorMessage={rlnError}
            />
            <NTextInput
                value={re}
                onChangeText={setRe}
                maxLength={128}
                hideMaxLength
                label={i18n.t('OnePersonOneQuestion_Seventh_Page_Email')}
                labelStyle={styles.label}
                errorMessage={reError}
            />
              <NText style={styles.important}>
                {i18n.t('OnePersonOneQuestion_Privacy')}
            </NText>
            <NButton
                t={'primary'}
                title={i18n.t('OnePersonOneQuestion_Submit')}
                style={{
                    marginTop: 20,
                }}
                onPress={() => {
                    alert(`${rfn} ${rln} at ${re}`);
                }}
            />
            <NButton
                t={'secondary'}
                title={i18n.t('OnePersonOneQuestion_NoThanks')}
                style={{
                    marginTop: 8,
                }}
                onPress={goHome}
            /> */}
                            </View>
                        </PQPage>
                    </View>}
                {<EmailPreviewModal2 isVisible={showPreview} onClose={() => setShowPreview(false)} image={PREVIEW} />
                    // showPreview &&
                    // <View
                    //     style={{
                    //         position: 'absolute',
                    //         top: 0,
                    //         bottom: 0,
                    //         right: 0,
                    //         left: 0,
                    //         backgroundColor: 'black',
                    //         display: 'flex',
                    //         alignItems: 'center',
                    //         justifyContent: 'center',
                    //         zIndex: 9999,
                    //     }}
                    // >
                    //     <ImageZoom
                    //         cropWidth={width}
                    //         cropHeight={height}
                    //         imageWidth={width * 0.75}
                    //         imageHeight={height * 0.75}
                    //     >
                    //         <View
                    //             style={{
                    //                 display: 'flex',
                    //                 alignItems: 'center',
                    //                 justifyContent: 'center',
                    //                 height: '100%',
                    //                 width: '100%',
                    //                 padding: 4,
                    //             }}
                    //         >
                    //         <Button
                    //             title={'Close'}
                    //             titleStyle={{
                    //                 fontWeight: '700',
                    //                 color: 'white',
                    //             }}
                    //             containerStyle={{
                    //                 borderWidth:0,
                    //                 borderRadius: 0,
                    //             }}
                    //             style={{
                    //                 marginBottom: 10,
                    //             }}
                    //             buttonStyle={{
                    //                 backgroundColor: theme.colors.transparent,
                    //                 borderWidth: 4,
                    //                 borderRadius: 14,
                    //                 borderColor: 'white',
                    //             }}
                    //             icon={
                    //                 <Icon
                    //                   iconStyle={{
                    //                     marginEnd: 2
                    //                   }}
                    //                   size={24}
                    //                   name={'close-circle-outline'}
                    //                   type='ionicon'
                    //                   color={theme.colors.background}
                    //                 />
                    //             }
                    //             iconPosition={'left'}
                    //             onPress={() => { setShowPreview(false); }}
                    //         />
                    //         {
                    //             !isMobileWeb() &&
                    //             <NText
                    //                 style={[
                    //                     styles.infoText,
                    //                     {
                    //                         color: 'white',
                    //                         marginBottom: 10,
                    //                     }
                    //                 ]}
                    //             >
                    //                 {i18n.t('OnePersonOneQuestion_EmailPreview_ZoomMessage')}
                    //             </NText>
                    //         }
                    //         <Image
                    //             source={require('../../assets/spotlight-email-sample.jpg')}
                    //             defaultSource={}
                    //             resizeMethod='scale'
                    //             resizeMode='contain'
                    //             style={{
                    //                 width: '100%',//normalizeHeight(343, height),
                    //                 height: '100%',
                    //                 //marginTop: normalizeHeight(20, height),
                    //                 //marginVertical: normalizeHeight(30, height),
                    //             }}
                    //             testID={`interview-screen-no-video-request-image`}
                    //         />
                    //         </View>
                    //     </ImageZoom>
                    // </View>
                }
                {/* </> */}
            </View>
        );
    }

    return (
        <>
            {
                !commonContext.loggedInUser &&
                renderWalkthrough()
            }
            {
                commonContext.loggedInUser &&
                <ScrollableViewScreen
                    navigation={props.navigation}
                    isFocused={isFocused}
                    refreshing={false}
                    //topTitle={`Create New`}
                    bottomTitle={`Create New`}
                    goBack={props.navigation.goBack}
                    // style={{
                    //     display: 'flex',
                    //     flexDirection: 'column',
                    //     flex: 1,
                    //   }}
                    // contentContainerStyle={{
                    //     alignItems: 'center',
                    //     justifyContent: 'center',
                    //     flex: 1,
                    // }}
                    testID={`create-new-community-screen`}
                    scrollEnabled={false}
                    containsScrollView={true}
                >
                    {
                        renderWalkthrough()
                    }
                </ScrollableViewScreen>
            }
        </>
    );
}

export default OnePersonOneQuestion2;