export enum Relationship {
	NONE = "Nothing",
	SELF = "Related_to_Self",
	CHILD = "Related_to_Child",
	SPOUSE = "Related_to_Spouse",
	GRANDCHILD = "Related_to_Grandchild",
  GREATGRANDCHILD = "Related_to_Greatgrandchild",
	SIBLING = "Related_to_Sibling",
  COUSIN = "Related_to_Cousin",
  DAUGHTERSONINLAW = "Related_to_DaughterSonInLaw",
  STEPCHILD= "Related_to_Stepchild",
	PARENT = "Related_to_Parent",
  GRANDPARENT = "Related_to_Grandparent",
  NEPHEWNIECE = "Related_to_NephewNiece",
  AUNTUNCLE = "Related_to_AuntUncle",
  PARTNER = "Related_to_Partner",
  FRIEND = "Related_to_Friend",
  COLLEAGUE = "Related_to_Colleague",
  STUDENT = "Related_to_Student",
  OTHER = "Related_to_Other",
}