// Frameworks
import React, { useContext, useState } from 'react';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { LayoutChangeEvent, Text, View } from 'react-native';
import { Palette } from '../../Theme';
import { getRelativeTimeLabel } from '../../business/date/dateFormatter';
import { CommunityQuestion } from '../../API';
import ConstrainedUserImage from './ConstrainedUserImage';

export function QuestionHeaderCardInner(props: { question: CommunityQuestion }): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { question } = props;
  const [cardWidth, setCardWidth] = useState(0);

  function onCardLayout(event: LayoutChangeEvent) {
    const { width, height } = event.nativeEvent.layout;

    const newWidth = Math.min(width, 400);
    if (newWidth != cardWidth) {
      setCardWidth(Math.min(width, 400));
    }
  }

  return (
    <>
      <View
        style={{
          //flex: 1,
          display: 'flex',
          //flexDirection: 'row',
          width: '100%',
          marginVertical: 8,
          borderColor: Palette.lightgrey,
          borderWidth: 1,
          borderRadius: 20,
          backgroundColor: Palette.white,
          padding: 18,
          paddingBottom: 20,
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            //flex: 2,
            paddingBottom: 10,
            // paddingHorizontal: 14,
          }}
          onLayout={onCardLayout}
        >
          <Text
            style={{
              ...themeFromContext.textVariants.body,
              color: Palette.teal,
              textAlign: 'left',
              //padding: 30,
            }}
          >
            {`Question:`}
          </Text>
          <Text
            style={{
              ...themeFromContext.textVariants.body,
              //color: themeFromContext.colors.primary,
              textAlign: 'left',
              //padding: 30,
            }}
          >
            {`${question?.questionText}`}
          </Text>
          {
            question.imageKeys && question.imageKeys.length > 0 &&
            <View
              style={{
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                marginTop: 4,
              }}
            >
            <ConstrainedUserImage imageKey={question?.imageKeys[0]} maxWidth={cardWidth} />
            </View>
          }
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            //flex: 1,
            paddingTop: 10,
            // paddingHorizontal: 14,
            borderTopWidth: 1,
            borderTopColor: Palette.lightgrey
          }}
        >
          <Text
            style={{
              ...themeFromContext.textVariants.body,
              color: Palette.teal,
              textAlign: 'left',
              //padding: 30,
            }}
          >
            {`Asked by:`}
          </Text>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
          <Text
            style={{
              ...themeFromContext.textVariants.body,
              //color: themeFromContext.colors.primary,
              textAlign: 'left',
              marginRight: 6,
              //padding: 30,
            }}
            numberOfLines={1}
          >
            {`${question?.user.firstName} ${question?.user.lastName}`}
          </Text>
          <Text
            style={{
              ...themeFromContext.textVariants.body,
              color: themeFromContext.colors.secondary,
              textAlign: 'left',
              //padding: 30,
            }}
          >
            {`${getRelativeTimeLabel(question.date)}`}
          </Text>
          </View>
        </View>
      </View>
      <View
        style={{
          width: '80%',
          alignSelf: 'center',
          height: 0,
          borderBottomWidth: 1,
          borderBottomColor: Palette.white,
          marginBottom: 8
        }}
      >
      </View>
    </>
  );
}

function propsAreEqual(prevProps: { question: CommunityQuestion }, nextProps: { question: CommunityQuestion }): boolean {

  if (!prevProps.question.imageKeys && nextProps.question.imageKeys) {
    return false;
  }
  if (prevProps.question.imageKeys && !nextProps.question.imageKeys) {
    return false;
  }

  let equal = prevProps.question.id === nextProps.question.id
    && prevProps.question.questionText === nextProps.question.questionText
    && prevProps.question.user.id === nextProps.question.user.id
    && prevProps.question.date === nextProps.question.date;
  
  if (!equal) {
    return false;
  }
  
  if (prevProps.question.imageKeys && nextProps.question.imageKeys) {
    equal = equal && prevProps.question.imageKeys.length === nextProps.question.imageKeys.length;
    if (equal) {
      for (let i = 0; i < prevProps.question.imageKeys.length; i++) {
        equal = equal && prevProps.question.imageKeys[i] === nextProps.question.imageKeys[i];
      }
    }
  }

  return equal;
}


export const QuestionHeaderCard = React.memo(QuestionHeaderCardInner, propsAreEqual);