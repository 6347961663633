import React from 'react';
import IframeRenderer, { iframeModel } from '@native-html/iframe-plugin';
import RenderHTML from 'react-native-render-html';
import { WebView as WebWebView } from 'react-native-web-webview';
import { VimeoThumbnail } from '../../../business/video/vimeo';

const renderers = {
  iframe: IframeRenderer
};

const customHTMLElementModels = {
  iframe: iframeModel
};

const renderersProps = {
  iframe: {
    scalesPageToFit: true,
    webViewProps: {
      /* Any prop you want to pass to iframe WebViews */
    }
  }
};

function InlinePlayer(props: { iframeHtml?: string, m3u8?: string, asset?: any, thumbnail?: VimeoThumbnail, playerContentWidth: number }): React.ReactElement {
  const { iframeHtml, m3u8, asset, playerContentWidth } = props;

  return (
    <>
    {
      iframeHtml != undefined && iframeHtml.length > 0 &&
      <RenderHTML
        contentWidth={playerContentWidth}
        renderers={renderers}
        WebView={WebWebView}
        source={{ html: iframeHtml }}
        customHTMLElementModels={customHTMLElementModels}
        defaultWebViewProps={
          {
            /* Any prop you want to pass to all WebViews */
          }
        }
        renderersProps={renderersProps}
      />
    }
    </>
  );
}
export default InlinePlayer;