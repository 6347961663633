// Frameworks
import React, { useContext } from 'react';
import NModal from '../Modals/NModal';
import NActivityIndicator from './NActivityIndicator';
import { Palette } from '../../../Theme';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';

function NActivityIndicatorModal(props: { show: boolean }): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const { height, width } = commonContext.dimensions;
  return (
    <NModal
      isVisible={props.show}
      //maxWidth={400}
      overlayStyle={{
        backgroundColor: "#00000000",
        borderColor: "#00000000",
        shadowColor: "#00000000",
        height: height,
        display: 'flex',
        justifyContent: 'center',
        bottom: 0,
        transform: [],
      }}
      name={'Waiting'}
      //type={'island'}
    >
      <NActivityIndicator
      containerStyle={{
        //bottom: 0,
        position: 'absolute',
        height: height,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
      }}
      color={Palette.white} />
    </NModal>
  );
}
export default NActivityIndicatorModal;