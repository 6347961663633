export const obfuscateEmail = (email?: string|null): string => {
  if (!email || email == null) {
    return '';
  }
  
  const emailParts = email.split('@');
  if (emailParts[0].length <= 2) {
    return `****@${emailParts[1]}`;
  }
  return `${emailParts[0].substring(0, 2)}****@${emailParts[1]}`;
}