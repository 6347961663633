/* eslint-disable no-case-declarations */
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react'
import { Text, View } from 'react-native';
import { Palette } from '../../../Theme';
import { QuestionsStackParamList } from '../../../types/Navigation';
import log from '../../../business/logging/logger';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import { Community, CommunityQuestionAnswer, CommunityQuestionAnswerComment, CommunityQuestionAnswerCommentStatus, CommunityUserStatus } from '../../../API';
import { normalizeHeight } from '../../../business/layout/responseSize';
import { Divider } from 'react-native-elements';
import { subscribeTo } from '../../../business/user/userHelper';
import * as s from '../../../graphql/subscriptions';
import AnswerItemCard from '../Answers/AnswerItemCard';
import { LikeData, LikeMap } from '../../../contracts/ICommunityService';
import { Provider } from 'react-native-paper';
import { arrayReducer } from '../../../business/answers/arrayReducer';
import ScrollableFlatListScreen from '../../components/Layouts/ScrollableFlatListScreen';
import { extractVideoMetadata, extractVisibility } from '../../../business/answers/videoMetadata';
import { getPowers } from '../../../business/answers/answerPostPowerRules';
import CommentEditor from './CommentEditor';
import CommentNew from './CommentNew';


export type AnswersCommentsProps = BottomTabScreenProps<QuestionsStackParamList, 'Answer Comments'>;

const FAKE_COMMENT_ID = `x0000000-0000-0000-0000-00000000000x`;

function AnswerComments({ route, navigation }: AnswersCommentsProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { height } = commonContext.dimensions;
  const linkTo = useLinkTo();
  const communityQuestionAnswerId = route.params.communityQuestionAnswerId;
  //const communityId = route.params.communityId;
  const isFocused = useIsFocused();
  const [refreshing, setRefreshing] = useState(false);
  const [community, setCommunity] = useState<Community>();
  const [answer, setAnswer] = useState<CommunityQuestionAnswer>();
  //const [videoMetadata, setVideoMetadata] = useState<VimeoDisplayMetadata>();
  //const [answers, setAnswers] = useState<CommunityQuestionAnswer[]>([]);
  const [comments, dispatchCommentsUpdate] = useReducer(arrayReducer, []);
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [refresh, setRefresh] = useState(false);
  //const [members, setMembers] = useState<CommunityUser[]>([]);
  const [communityRole, setCommunityRole] = useState<CommunityUserStatus>();
  const [moderated, setModerated] = useState(true);
  const [likeMap, setLikeMap] = useState<LikeMap>({});
  const [canSeeAnswer, setCanSeeAnswer] = useState(false);
  const [sub, setSub] = useState();

  // likes
  //const [likeMap, setLikeMap] = useState<LikeMap>({});

  //log.info(`GOT HERE`);
  useEffect(() => {
    setAnswer(undefined);
    dispatchCommentsUpdate({ type: "set", payload: [] });
    Promise.all([
      commonContext.services.communityService.getAnswer(communityQuestionAnswerId),
    ])
      .then(results => {
        if (results[0]) {
          const a = results[0];
          setAnswer(a);
          handleAnswer(a);
        }
      })
      .catch(ex => {
        log.error(`Error getting prerequisites:`);
        log.error(ex);
      });

    if (sub) {
      sub.unsubscribe();
    }
    const x = subscribeTo<CommunityQuestionAnswer>(
      communityQuestionAnswerId,
      "id",
      s.onUpdateCommunityQuestionAnswer,
      "onUpdateCommunityQuestionAnswer",
      (item) => {
        log.info(`got update via subscription on AnswerComments page`);
        log.info(item.content);
        setAnswer(item);
        handleAnswer(item);
      });
    setSub(x);

    let subCreate: any = null;
    let subUpdate: any = null;
    let subDelete: any = null;
    // subscribe to CommunityQuestion updates
    //log.info(`UNSUBSCRIBING FROM COMMUNITYQUESTION UPDATES`);
    subscriptions.forEach(s => s.unsubscribe());
    subCreate = subscribeTo<CommunityQuestionAnswerComment>(communityQuestionAnswerId, "communityQuestionAnswerID", s.onCreateCommunityQuestionAnswerCommentByAnswerId, "onCreateCommunityQuestionAnswerCommentByAnswerId", (a) => {
      log.info(JSON.stringify(a, null, 2));
      if (a != null) {
        dispatchCommentsUpdate({ type: "insert", payload: a });
        dispatchCommentsUpdate({ type: "remove", payload: { id: FAKE_COMMENT_ID } });
      }
    });
    subUpdate = subscribeTo<CommunityQuestionAnswerComment>(communityQuestionAnswerId, "communityQuestionAnswerID", s.onUpdateCommunityQuestionAnswerCommentByAnswerId, "onUpdateCommunityQuestionAnswerCommentByAnswerId", (a) => {
      if (a != null) {
        if (a.status == CommunityQuestionAnswerCommentStatus.DELETED) {
          log.warn(`soft deleted:`);
          log.info(a);
          dispatchCommentsUpdate({ type: "remove", payload: a });
        } else {
          dispatchCommentsUpdate({ type: "update", payload: a });
        }
      }
    });
    subDelete = subscribeTo<CommunityQuestionAnswerComment>(communityQuestionAnswerId, "communityQuestionAnswerID", s.onDeleteCommunityQuestionAnswerCommentByAnswerId, "onDeleteCommunityQuestionAnswerCommentByAnswerId", (a) => {
      if (a != null) {
        log.warn(`deleted:`);
        log.info(a);
        dispatchCommentsUpdate({ type: "remove", payload: a });
      }
    });
    setSubscriptions([subCreate, subUpdate, subDelete]);

    return () => {
      log.info(`UNSUBSCRIBING FROM COMMUNITYQUESTIONANSWERCOMMENT UPDATES UNMOUNT`);
      subscriptions.forEach(s => s.unsubscribe());
    };

  }, [communityQuestionAnswerId]);

  useEffect(() => {
    if (answer) {
      const loggedInUserRole = commonContext.isAdmin ? "ADMIN" : communityRole;
      const isOriginalAuthor = answer.userID == commonContext.loggedInUser?.id;
      const currentPostVisibility = extractVisibility(answer.content);

      try {
        const communityGroup = loggedInUserRole != "ADMIN" ? commonContext.loggedInUser?.communityUsersByStatusDate?.items.find(cu => cu?.communityID == answer.communityID)?.group : "";

        //get the "group" that the user is in.  This is so a SUPERVIEWER can view videos that only the organizer/spotlight can see (started with the GH community)
        const powers = getPowers(loggedInUserRole, isOriginalAuthor, moderated, currentPostVisibility, communityGroup);
        setCanSeeAnswer(powers.canSee);
        //log.info(`communityGroup: ${communityGroup}`);
      } catch (ex) {
        log.error(ex);
        log.warn("error - seems like could not get group - missing answer or communityuser");
      }
    }
  }, [answer, communityRole, moderated, commonContext.loggedInUser]);

  useEffect(() => {
    if (isFocused) {

      // whenever we come back to the page, check to see if likes need to be updated...
      if (answer) {
        commonContext.services.communityService.getLikeMapForAnswers([answer])
          .then(map => {
            setLikeMap(map);
          });
     
        // analytics
        // this represents the first page load after a new answer is selected
        if (community && communityRole) {    
          const pageName = route.name;
          const vm = extractVideoMetadata(answer.content);
          const data = {
            ...route.params,
            CommunityId: community?.id,
            CommunityName: community?.name,
            CommunityRole: communityRole,
            QuestionId: answer.communityQuestion.id,
            QuestionText: answer.communityQuestion.questionText,
            QuestionCategoryId: answer.communityQuestion.categoryID,
            QuestionCategoryName: answer.communityQuestion.category?.name,
            QuestionCustomCommunityCategory: answer.communityQuestion.communityCategory,
            QuestionAskedByUserId: answer.communityQuestion.userID,
            QuestionAskedByUserFullName: commonContext.services.userService.getFullName(answer.communityQuestion.user),
            QuestionAskedByUserEmail: answer.communityQuestion.user.email,
            AnswerId: answer.id,
            AnswerContent: answer.content,
            AnsweredByUserId: answer.userID,
            AnsweredByUserFullName: commonContext.services.userService.getFullName(answer.user),
            AnsweredByUserEmail: answer.user.email,
            VideoId: vm?.id,
            VideoTitle: vm?.title,
            VideoDescription: vm?.description,
            VideoVisibility: vm?.visibility,
          }
          commonContext.analytics?.viewPage(pageName, data);
        }
      }
    }
    
  }, [isFocused]);

  // useEffect(() => {
  //   if (commonContext.loggedInUser) {
  //     const u = members.find(m => m.userID == commonContext.loggedInUser?.id);
  //     const r = u?.status;
  //     setCommunityRole(r);
  //   }
  // }, [members]);

  const handleAnswer = async (a: CommunityQuestionAnswer) => {
    const results = await Promise.all([
      commonContext.services.communityService.getComments(a.id),
      commonContext.services.communityService.getSingleCommunity(a.communityID),
      commonContext.services.communityService.getLoggedInUserCommunityRole(a.communityID),
      commonContext.services.communityService.getLikeMapForAnswers([a]),
    ]);
    let c: CommunityQuestionAnswerComment[] = [];
    if (results[0]) {
      c = results[0];
      c.sort((a, b) => { return a.createdAt > b.createdAt ? -1 : 1; });
      dispatchCommentsUpdate({ type: "set", payload: c });
      // WHEN WE GET TO COMMENT LIKES
      // const map = await commonContext.services.communityService.getLikeMapForComments(c);
      // setLikeMap(map);
    } else {
      log.error(`Error getting prerequisites`);
    }
    if (results[1]) {
      const c = results[1];
      setCommunity(c);
      if (c && c.requiresModeration) {
        setModerated(true);
      } else {
        setModerated(false);
      }
    }
    if (results[2]) {
      const r = results[2];
      setCommunityRole(r);
    }
    if (results[3]) {
      setLikeMap(results[3]);
    }

    // analytics
    // this represents the first page load after a new answer is selected
    if (a) {
      const pageName = route.name;
      const vm = extractVideoMetadata(a.content);
      const data = {
        ...route.params,
        CommunityId: c?.id,
        CommunityName: c?.name,
        CommunityRole: communityRole,
        QuestionId: a.communityQuestion.id,
        QuestionText: a.communityQuestion.questionText,
        QuestionCategoryId: a.communityQuestion.categoryID,
        QuestionCategoryName: a.communityQuestion.category?.name,
        QuestionCustomCommunityCategory: a.communityQuestion.communityCategory,
        QuestionAskedByUserId: a.communityQuestion.userID,
        QuestionAskedByUserFullName: commonContext.services.userService.getFullName(a.communityQuestion.user),
        QuestionAskedByUserEmail: a.communityQuestion.user.email,
        AnswerId: a.id,
        AnswerContent: a.content,
        AnsweredByUserId: a.userID,
        AnsweredByUserFullName: commonContext.services.userService.getFullName(a.user),
        AnsweredByUserEmail: a.user.email,
        VideoId: vm?.id,
        VideoTitle: vm?.title,
        VideoDescription: vm?.description,
        VideoVisibility: vm?.visibility,
      }
      commonContext.analytics?.viewPage(pageName, data);
    }

    setRefreshing(false);
  }

  const toggleLike = async (answer: CommunityQuestionAnswer): Promise<void> => {
    try {
      if (!likeMap[answer.id]) {
        likeMap[answer.id] = new LikeData(true, 1);
      } else {
        likeMap[answer.id].like(!likeMap[answer.id].getLiked());
      }
      setRefresh(!refresh);
      await commonContext.services.communityService.toggleCommunityQuestionAnswerLike(answer);
      const map = await commonContext.services.communityService.getLikeMapForAnswers([answer]);
      setLikeMap(map);
    } catch (ex) {
      log.error(ex);
    }
  }

  const fakeNewComment = (commentText: string) => {
    const now = new Date(Date.now()).toISOString();
    const fake = {
      id: FAKE_COMMENT_ID,
      communityID: community?.id,
      userID: commonContext.loggedInUser?.id,
      content: commentText,
      status: CommunityQuestionAnswerCommentStatus.ADDED,
      user: commonContext.loggedInUser,
      createdAt: now,
      updatedAt: now,
    }
    dispatchCommentsUpdate({ type: "insert", payload: fake });
  }

  const renderCard = useMemo(() => {
    return (
      <View
        style={{
          width: '100%',
          backgroundColor: Palette.plum,
        }}
      >
        {
          answer && canSeeAnswer &&
          <>
            <View
              style={{
                //marginVertical: 8,
              }}
            >
              <AnswerItemCard
                answer={answer}
                comments={comments}
                moderated={community?.requiresModeration ?? false}
                role={communityRole ?? CommunityUserStatus.REMOVED}
                index={1}
                likes={likeMap[answer.id]}
                hideMenuButton
                toggleDelete={() => { return; }}
                toggleEditText={() => { return; }}
                toggleLike={toggleLike}
                cardStyle={{
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 0,
                  marginBottom: 0,
                  borderWidth: 0,
                  paddingBottom: 9,
                }}
              />
            </View>
            <Divider orientation='horizontal' width={1} color={Palette.faintgrey} />
            <View
              style={[
                {
                  //marginVertical: 8,
                  backgroundColor: themeFromContext.colors.background,
                  width: '100%',
                  padding: 6,
                  
                },
                comments.length == 0 ? { borderBottomLeftRadius: 20, borderBottomRightRadius: 20 } : {}
              ]}
            >
              <CommentNew answer={answer} onSave={fakeNewComment} />
            </View>
          </>
        }
      </View>
    );
  }
  , [answer?.id, answer?.content, canSeeAnswer, Object.values(likeMap).length, comments.length]);

  return (
    <Provider>
      <ScrollableFlatListScreen
        navigation={navigation}
        //goBack={() => linkTo(`/community/${answer?.communityID}/questions/answers/${answer?.communityQuestionID}`)}
        goBack={navigation.goBack}
        topTitle={`Comments`}
        isFocused={isFocused}
        refreshing={refreshing}
        bottomTitle={community?.name ?? '...'}
        data={canSeeAnswer ? comments : []}
        extraData={refresh}
        renderItem={({ item, index, }) => (
            <CommentEditor
              comment={item}
            />
        )}
        ListFooterComponent={() => (
          <>
          {
            comments.length > 0 &&
            <View
              style={{
                backgroundColor: themeFromContext.colors.background,
                height: 40,
                borderBottomLeftRadius: 20,
                borderBottomRightRadius: 20,
              }}
            >

            </View>
            }
          <View style={{
            minHeight: normalizeHeight(200, height),
            paddingVertical: themeFromContext.spacing.m
          }} />
          </>
        )}
        ListHeaderComponent={renderCard}
        ListEmptyComponent={() => (
          <View
            style={{
              alignItems: 'center',
              flex: 1,
              //justifyContent: 'center',
              paddingTop: 30,
              paddingBottom: 130,
            }}
            testID={'question-answer-list-empty-view'}
          >
            <Text
              style={{
                ...themeFromContext.textVariants.body,
                color: Palette.white,
                textAlign: 'center'
              }}
            >
              {`No comments yet!`}
            </Text>
          </View>
        )}
      >
      </ScrollableFlatListScreen>
    </Provider>
  );
}

export default AnswerComments;