//import { Analytics } from 'aws-amplify';
import log from '../logging/logger';
import AnalyticsWrapper from './analyticsWrapper';
import { ICommunityService } from '../../contracts/ICommunityService';

export async function recordAuthEvent(communityService: ICommunityService, analytics: AnalyticsWrapper, data: any) : Promise<any> {

  try {
    let firstName = '';
    let lastName = '';
    let email = '';
    let username = '';
    //let communities: string[] = [];
    
    if (data.payload.data.signInUserSession) {
      //communities = data.payload.data.signInUserSession.idToken.payload["cognito:groups"];

      if (data.payload.data.attributes) {
        firstName = data.payload.data.attributes?.given_name;
        lastName = data.payload.data.attributes?.family_name;
        email = data.payload.data.attributes?.email?.toLowerCase();
        username = data.payload.data.attributes?.sub;
      } else {
        firstName = data.payload.data.signInUserSession.idToken.payload.given_name;
        lastName = data.payload.data.signInUserSession.idToken.payload.family_name;
        email = data.payload.data.signInUserSession.idToken.payload.email?.toLowerCase();
        username = data.payload.data.signInUserSession.idToken.payload.sub;
      }
    } else {
      email = data.payload.data.user.username;
      username = data.payload.data.userSub; //5ccd8e75-5208-42c0-ab8e-ae06920575dd
    }

    const eventName = data.payload.event;
    const commonData = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      cognitoUsername: username,
    };


    analytics.setUserData(username, commonData);
    analytics.logEvent(eventName, commonData);
    if (eventName == 'signIn') {
      const cs = await communityService.getCommunityDataForLoggedInUser();
      cs.forEach(c => {
        analytics.logEvent('signIn_community', { community_id: c.id, community_name: c.name });
      })
    }
    if (eventName == 'signOut') {
      analytics.setUserData('', {});
    }
  } catch (ex) {
    log.info(`Failed to record analytics event for auth event:`);
    log.info(JSON.stringify(data, null, 2));
    log.info(ex);
  }
}