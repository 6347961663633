import React, { useContext, useEffect, useRef, useState } from 'react';
import { Animated, Easing, NativeScrollEvent, NativeSyntheticEvent, Platform, Pressable, ScrollView, Text, TextInput, View } from 'react-native';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { QuestionsStackParamList } from '../../../types/Navigation';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { Provider } from 'react-native-paper';
import { LikeData, LikeMap } from '../../../contracts/ICommunityService';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import { Community, CommunityQuestion, CommunityQuestionRecommendation, CommunityQuestionSource } from '../../../API';
import log from '../../../business/logging/logger';
import { recordQuestionEvent } from '../../../business/analytics/questionEventRecorder';
import { parse } from 'json2csv';
import { CSVLink } from "react-csv";
import AskedQuestionCard from '../../components/AskedQuestionCard';
import { normalizeHeight } from '../../../business/layout/responseSize';
import { Palette } from '../../../Theme';
import SlideUpView from '../../components/Modals/SlideUpView';
import { Button, Icon, Input } from 'react-native-elements';
import NTextInput from '../../components/NTextInput';
import Question from '../../../../assets/svg/illustrations/Question';
import NFAB2 from '../../components/NFAB2';
import { FABScrollHelper } from '../../../business/scroll/fabScrollHelper';
import Filter from '../../components/Filter';
import ScrollableFlatListScreen from '../../components/Layouts/ScrollableFlatListScreen';
import NDropDownPicker from '../../components/NDropDownPicker';
import { ItemType } from 'react-native-dropdown-picker';
import NButton from '../../components/NButton';
import NText from '../../components/NText';
import ImageUploader from './ImageUploader';

export type AskedQuestionsProps = BottomTabScreenProps<QuestionsStackParamList, 'Asked'>;

const expanded: Set<string> = new Set<string>();

function AskedQuestions2(props: AskedQuestionsProps): React.ReactElement {
  const { route, navigation } = props;
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const [questions, setQuestions] = useState<CommunityQuestion[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  //const [communityCategories, setCommunityCategories] = useState<string[]>([]);
  const [open, setOpen] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [refreshing, setRefreshing] = useState(true);
  //const [sortAsc, setSortAsc] = useState(false);
  const [sortBy, setSortBy] = useState('date');
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [questionEditOverlayVisible, setQuestionEditOverlayVisible] = useState(false);

  const [ideaBankVisible, setIdeaBankVisible] = useState(false);

  const [editOverlayTitle, setEditOverlayTitle] = useState('');
  const [editOverlayQuestionText, setEditOverlayQuestionText] = useState('');
  const [editOverlayCategoryName, setEditOverlayCategoryName] = useState('');
  const [editOverlayImageKey, setEditOverlayImageKey] = useState('');
  const [communityQuestionId, setCommunityQuestionId] = useState('');
  const [saveButtonRefreshing, setSaveButtonRefreshing] = useState(false);
  const [editOverlayErrorMessage, setEditOverlayErrorMessage] = useState('');
  const [deleteOverlayQuestionText, setDeleteOverlayQuestionText] = useState('');
  const [deleteOverlayErrorMessage, setDeleteOverlayErrorMessage] = useState('');
  const [deleteButtonRefreshing, setDeleteButtonRefreshing] = useState(false);
  const [newCategoryText, setNewCategoryText] = useState('');
  const [newCategoryButtonRefreshing, setNewCategoryButtonRefreshing] = useState(false);
  const [refreshCategories, setRefreshCategories] = useState(false);
  const [likeMap, setLikeMap] = useState<LikeMap>({});
  const [csvData, setCsvData] = useState('');
  const [csvFileName, setCsvFileName] = useState('');
  const questionTextRef = useRef<TextInput>(null);
  const isFocused = useIsFocused();
  const communityId = route.params?.communityId;
  const operation = route.params?.operation;
  const [community, setCommunity] = useState<Community>();
  const { width, height } = commonContext.dimensions;
  const { userService, communityService, categoryService, questionRecommendationService } = commonContext.services;
  const linkTo = useLinkTo();
  const [allSectionsExpanded, setAllSectionsExpanded] = useState(true);

  const [filterOptions, setFilterOptions] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<Set<string>>(new Set<string>());

  const [recommendations, setRecommendations] = useState<CommunityQuestionRecommendation[]>([]);


  useEffect(() => {
    setAllSectionsExpanded(expanded.size == questions.length);
  }, [expanded.size, questions.length]);

  useEffect(() => {
    let mounted = isFocused;
    init(mounted);
    return () => { mounted = false; };
  }, [isFocused, communityId, operation]);


  function sortQuestions(qs: CommunityQuestion[], map: LikeMap, sort: string): void {
    let asc = false;
    if (sort == 'category') {
      asc = true;
    }
    qs.sort((a, b) => commonContext.services.communityService.compareCommunityQuestions(a, map[a.id] ? map[a.id].totalLikes : 0, b, map[b.id] ? map[b.id].totalLikes : 0, sort, asc));
  }

  function init(mounted: boolean) {
    setQuestions([]);
    setCsvData('');
    setRefreshing(true);
    Promise.all([
      // getLoggedInUser(),
      communityService.getQuestions(communityId),
      communityService.getSingleCommunity(communityId),
      //questionRecommendationService.getFullRecommendationList(communityId),

    ]).then((results) => {
      if (mounted) {
        commonContext.analytics?.viewPage(route.name, route.params);
        //const user = results[0];
        const qs = results[0];
        const communityResult = results[1];
        //const recs = results[2];

        // handle logged-in user


        if (communityResult) {
          // handle question map
          communityService.getLikeMapForQuestions(qs)
            .then((map) => {
              if (mounted) {
                setLikeMap(map);
                console.log(map);
                if (communityResult) {
                  const unique = new Set<string>(qs.map(q => q.communityCategory ?? q.category?.name ?? 'Unknown'));
                  unique.delete('');
                  setFilterOptions(([...unique]).sort());
                  setSelectedFilters(new Set([...unique]));
                  sortQuestions(qs, map, sortBy);
                  setQuestions(qs);
                }
                setRefresh(!refresh);
                setCsvData('');
                setRefreshing(false);
              }
            });

          // handle community
          setCommunity(communityResult);
          if (communityResult.communityCategories) {
            setCategories(communityResult.communityCategories);
            setRefreshCategories(!refreshCategories);
          } else {
            categoryService.getAllCategories()
              .then((values) => {
                if (mounted) {
                  setCategories(values.map(c => c.name));
                  setRefreshCategories(!refreshCategories);
                }
              })
              .catch((ex) => log.error(ex));
          }
        }

        questionRecommendationService.getFullRecommendationList(communityId).then(recs => {
          setRecommendations(recs);
        })

        if (operation == "new") {
          toggleQuestionEditOverlay(`Ask:`, ``);
        }
      }
    }).catch((ex) => {
      log.error(ex);
    });
  }

  useEffect(() => {
    sortQuestions(questions, likeMap, sortBy);
    setRefresh(!refresh);
  }, [questions, likeMap, sortBy]);

  const toggleDelete = (question: CommunityQuestion): void => {
    toggleDeleteConfirmationOverlay(
      question.questionText,
      question.id);
  }

  const toggleDeleteConfirmationOverlay = (questionText: string, communityQuestionId?: string) => {
    setDeleteOverlayErrorMessage('');
    setCommunityQuestionId(communityQuestionId ? communityQuestionId : '');
    setDeleteOverlayQuestionText(questionText);
    setDeleteConfirmationVisible(!deleteConfirmationVisible);
  };

  const pressDelete = async () => {
    setDeleteButtonRefreshing(true);
    const isNew = communityQuestionId === '';
    if (!isNew) {
      await communityService.deleteExistingQuestion(
        communityQuestionId,
        (d, u) => {
          setDeleteButtonRefreshing(false);
          setDeleteOverlayErrorMessage(u);
        }
      );
      setDeleteButtonRefreshing(false);
      toggleDeleteConfirmationOverlay('');
      communityService.getQuestions(communityId).then((value) => {
        sortQuestions(value, likeMap, sortBy);
        setQuestions(value);
        setCsvData('');
        //setRefresh(!refresh);
      });
    }
  }

  const pressSave = async () => {
    setSaveButtonRefreshing(true);
    let errorMessage = '';
    if (editOverlayQuestionText.length == 0) {
      errorMessage += `Please ask a question.`;
    }
    if (editOverlayCategoryName == undefined || editOverlayCategoryName.length == 0) {
      if (errorMessage.length > 0) {
        errorMessage += `\n`;
      }
      errorMessage += `Please select a category.`;
    }

    if (errorMessage.length > 0) {
      setSaveButtonRefreshing(false);
      setEditOverlayErrorMessage(errorMessage);
      return;
    }

    const isNew = communityQuestionId === '';
    const source = CommunityQuestionSource.USER;
    const imageKeys = editOverlayImageKey && editOverlayImageKey.length > 0 ? [editOverlayImageKey] : [];
    if (isNew) {
      try {
        await Promise.all([
          communityService.saveNewQuestion(editOverlayQuestionText, editOverlayCategoryName, communityId, source, imageKeys),
          recordQuestionEvent({
            eventName: 'questionAsked',
            category: editOverlayCategoryName,
            community: communityId,
            source: CommunityQuestionSource.USER,
            email: commonContext.loggedInUser?.email,
            firstName: commonContext.loggedInUser?.firstName,
            lastName: commonContext.loggedInUser?.lastName,
            question: editOverlayQuestionText,
            username: commonContext.loggedInUser?.username,
            imageKey: editOverlayImageKey,
          })
        ]);
        if (!filterOptions.includes(editOverlayCategoryName)) {
          filterOptions.push(editOverlayCategoryName);
        }
        selectedFilters.add(editOverlayCategoryName);
        setSaveButtonRefreshing(false);
        toggleQuestionEditOverlay('', '');
        communityService.getQuestions(communityId).then((value) => {
          sortQuestions(value, likeMap, sortBy);
          setQuestions(value);
          setCsvData('');
          //setRefresh(!refresh);
        });
      } catch (reason: any) {
        setSaveButtonRefreshing(false);
        toggleQuestionEditOverlay('', '');
        setEditOverlayErrorMessage(reason.userMessage);
      }
    } else {
      try {
        await communityService.saveExistingQuestion(
          editOverlayQuestionText,
          editOverlayCategoryName,
          communityQuestionId,
          imageKeys);
        setSaveButtonRefreshing(false);
        toggleQuestionEditOverlay('', '');
        communityService.getQuestions(communityId).then((value) => {
          sortQuestions(value, likeMap, sortBy);
          setQuestions(value);
          setCsvData('');
          //setRefresh(!refresh);
        });
      } catch (reason: any) {
        setSaveButtonRefreshing(false);
        toggleQuestionEditOverlay('', '');
        setEditOverlayErrorMessage(reason.userMessage ?? '');
      }
    }
  }

  const pressNewCategory = () => {
    setNewCategoryButtonRefreshing(true);
    categoryService.createNewCategory(newCategoryText)
      .then((c) => {
        categoryService.getAllCategories()
          .then((values) => {
            setCategories(values.map(v => v.name));
            setNewCategoryText('');
            setNewCategoryButtonRefreshing(false);
          })
          .catch((ex) => log.error(ex));
      })
      .catch((ex) => {
        setEditOverlayErrorMessage(ex);
      });
  }

  const toggleEdit = (question: CommunityQuestion): void => {
    toggleQuestionEditOverlay(
      `Edit your question...`,
      question.questionText,
      question.communityCategory ?? question.category?.name,
      question.id,
      question.imageKeys ? question.imageKeys[0] : undefined);
  }

  const toggleQuestionEditOverlay = (title: string, questionText: string, categoryName?: string, communityQuestionId?: string, imageKey?: string) => {
    //setRefreshCategories(!refreshCategories);
    setIdeaBankVisible(false);  

    const makeVisible = !questionEditOverlayVisible;
    if (makeVisible) {
      //log.info(`making question input focus: ${makeVisible}`);
      //log.info(questionTextRef.current);
      // setTimeout(() => { // this is a bizarre hack I found here:  https://stackoverflow.com/questions/35522220/react-ref-with-focus-doesnt-work-without-settimeout-my-example
      //   questionTextRef.current?.focus();
      // }, 1100);
      //log.info(questionTextRef.current?.isFocused());
    }
    setQuestionEditOverlayVisible(makeVisible);
    setEditOverlayErrorMessage('');
    if (title != '') setEditOverlayTitle(title);
    setEditOverlayQuestionText(questionText);
    setNewCategoryText('');
    const cat = (categoryName ? categoryName : (categories ? (categories[0] ?? '') : ''));
    setEditOverlayCategoryName(cat);
    setCommunityQuestionId(communityQuestionId ? communityQuestionId : '');
    setEditOverlayImageKey(imageKey ?? '');
  };

  const setImage = async (storageKey: string): Promise<void> => {
    setEditOverlayImageKey(storageKey);
    setRefresh(!refresh);
  }

  const questionEditable = (question: CommunityQuestion): boolean => {
    return (commonContext.loggedInUser?.id === question.user.id && commonContext.loggedInUser?.username == question.owner) || commonContext.isAdmin;
  }

  const toggleLike = async (question: CommunityQuestion): Promise<void> => {
    try {
      if (!likeMap[question.id]) {
        likeMap[question.id] = new LikeData(true, 1);
      } else {
        likeMap[question.id].like(!likeMap[question.id].getLiked());
      }
      setRefresh(!refresh);
      await communityService.toggleCommunityQuestionLike(question);
      const map = await communityService.getLikeMapForQuestions(questions);
      setLikeMap(map);
      setCsvData('');
    } catch (ex) {
      log.error(ex);
    }
  }

  type QuestionExport = {
    Name: string
    Date: string
    Category: string
    Question: string
    Likes: number
    LikedBy: string | undefined
  }

  const downloadQuestionsAsCSV = async () => {
    let fileName = '';
    let csv = '';
    for (let index = 0; index < questions.length; index++) {
      const q = questions[index];
      let likedByUsers = q.communityQuestionLikesByStatus?.items.map(like => userService.getFullName(like?.user)) ?? [];
      likedByUsers = ['', ...likedByUsers];

      for (let index2 = 0; index2 < likedByUsers.length; index2++) {
        const l = likedByUsers[index2];
        const payload: QuestionExport = {
          Name: userService.getFullName(q.user),
          Date: (new Date(q.date)).toLocaleDateString(),
          Category: (q.communityCategory ?? q.category?.name ?? ''),
          Question: q.questionText,
          Likes: likeMap[q.id] ? likeMap[q.id].totalLikes : 0,
          LikedBy: l,
        };

        if (index === 0 && index2 === 0) {
          const columnHeaders = Object.keys(payload).join(',');
          csv = csv.concat(columnHeaders, '\n');
          fileName = encodeURIComponent(q.community.name.replace(' ', '-'));
        }

        const rowValues = parse(payload, { header: false });
        //const rowValues = `"${payload.Name}","${payload.Date}","${payload.Category}","${payload.Question}","${payload.Likes}","${payload.LikedBy}"`;
        csv = csv.concat(rowValues, '\n');
      }
    }
    //log.info(fileName);
    log.info(csv);

    setCsvData(csv);
    setCsvFileName(`${fileName}.csv`);
  }

  const toggleExpanded = (sectionTitle: string) => {
    if (!expanded.has(sectionTitle)) {
      expanded.add(sectionTitle);
    } else {
      expanded.delete(sectionTitle);
    }
    setRefresh(!refresh);
  }

  // const toggleExpandCollapseAll = () => {
  //   if (allSectionsExpanded) {
  //     // collapse all
  //     log.info(`trying to collapse all`);
  //     expanded.clear();
  //     setRefresh(!refresh);
  //   } else {
  //     // expand all
  //     log.info(`trying to expand all`);
  //     questions.forEach(s => expanded.add(s.title));
  //     setRefresh(!refresh);
  //   }
  // }

  const clickAnswerLink = async (question: CommunityQuestion): Promise<void> => {
    log.info(`/community/${question.community.id}/questions/answers/${question.id}`);
    linkTo(`/community/${question.community.id}/questions/answers/${question.id}`);
  }

  const clickRecordLink = async (question: CommunityQuestion): Promise<void> => {
    log.info(`/answer/${question.id}/`);
    linkTo(`/answer/${question.id}/`);
  }

  const [showButton, setShowButton] = useState(true);
  const [fabScroller] = useState(new FABScrollHelper(setShowButton));
  function handleScroll(event: NativeSyntheticEvent<NativeScrollEvent>): void {
    fabScroller.handleScroll(event);
  }

  const [items, setItems] = useState<ItemType<string>[]>([]);

  useEffect(() => {
    setItems(categories.map(x => { return { label: x, value: x }; }));
  }, [categories]);

  const [anim, setAnim] = useState(new Animated.Value(0));

  useEffect(() => {
    if (ideaBankVisible) {
      // open it

      Animated.timing(
        anim,
        {
          toValue: 1,
          duration: 200,
          useNativeDriver: true,
          easing: Easing.ease,
        }
      ).start((result) => {
        //setFullyOpen(true);
      });

    } else {
      // close it

      //setFullyOpen(false);
      Animated.timing(
        anim,
        {
          toValue: 0,
          duration: 200,
          useNativeDriver: true,
          easing: Easing.ease,
        }
      ).start(() => {
        //setShouldOpen(false);
      });

    }

    return () => { return; }
  }, [ideaBankVisible]);

  return (
    <Provider>
      <ScrollableFlatListScreen
        navigation={props.navigation}
        goBack={() => linkTo(`/communities/${commonContext.loggedInUser?.id}/`)}
        topTitle={`Questions`}
        isFocused={isFocused}
        refreshing={refreshing}
        bottomTitle={community?.name ?? '...'}
        //sections={questionSections}
        data={questions}
        extraData={refresh}
        onScroll={handleScroll}
        scrollEventThrottle={16}
        renderItem={({ item, index }) => (
          <>
            {
              (selectedFilters?.has(item.category?.name) || selectedFilters?.has(item.communityCategory)) &&
              <AskedQuestionCard question={item} index={index} editable={questionEditable(item)} likes={likeMap[item.id]} toggleLike={toggleLike} toggleEdit={toggleEdit} toggleDelete={toggleDelete} clickAnswerLink={clickAnswerLink} clickRecordLink={clickRecordLink} />
            }
          </>
        )}
        ListHeaderComponent={() => (
          <View
            style={{
              width: '100%',
            }}
          >
            {
              commonContext.isAdmin &&
              <View style={{ flex: 1, width: '100%', alignItems: 'center', paddingBottom: 10 }}>
                <Button
                  buttonStyle={{
                    backgroundColor: themeFromContext.colors.background,
                  }}
                  title={`Generate CSV`}
                  titleStyle={{
                    color: themeFromContext.colors.primary,
                    ...themeFromContext.textVariants.authSecondaryButton
                  }}
                  onPress={downloadQuestionsAsCSV}
                />
                {
                  csvData != '' && csvFileName != '' &&
                  <CSVLink
                    style={{
                      padding: 10,
                      backgroundColor: themeFromContext.colors.background,
                      borderWidth: 1,
                      borderRadius: 25,
                      borderColor: themeFromContext.colors.primary,
                      borderStyle: 'solid',
                      color: themeFromContext.colors.primary,
                      fontFamily: themeFromContext.textVariants.authSecondaryButton.fontFamily,
                      fontSize: themeFromContext.textVariants.authSecondaryButton.fontSize,
                      fontWeight: 'normal',
                      textDecoration: 'none'
                    }}
                    data={csvData}
                    filename={csvFileName}
                  >
                    {`Download`}
                  </CSVLink>
                }
              </View>
            }
          </View>
        )}
        ListFooterComponent={() => (
          <View style={{
            minHeight: questions.length > 0 ? normalizeHeight(260, height) : 0,
            paddingVertical: themeFromContext.spacing.m
          }} />)
        }
        ListEmptyComponent={() => (
          <View
            style={{
              alignItems: 'center',
              flex: 1,
              //justifyContent: 'center',
              paddingTop: 30,
              paddingBottom: 130,
            }}
            testID={'asked-questions-list-empty-view'}
          >
            <Question />
            <Text
              style={{
                ...themeFromContext.textVariants.header,
                color: Palette.white,
                textAlign: 'center',
                marginVertical: normalizeHeight(20, height),
              }}
            >
              {`What would you like to know?`}
            </Text>
            <Text
              style={{
                ...themeFromContext.textVariants.body,
                color: Palette.white,
                textAlign: 'center'
              }}
            >
              {`Please ask a new question by taping on the "+" below, or vote for a question someone else asked!`}
            </Text>
          </View>
        )}
        filter={
          <Filter
            filterOptions={filterOptions}
            selectedFilters={selectedFilters}
            sortOptions={['category', 'date', 'likes']}
            selectedSort={sortBy}
            onFilterSelectionChanged={(selection: Set<string>) => {
              setSelectedFilters(selection);
              setRefresh(!refresh);
            }}
            onSortSelectionChanged={(selection: string) => {
              setSortBy(selection);
              setRefresh(!refresh);
            }}
          />
        }
      >
        <NFAB2
          isVisible={showButton}
          hasBottomBar={true}
          left={
            <Icon
              name='add'
              type='ionicon'
              color={Palette.plum}
              size={32}
            />
          }
          right={
            <Text
              style={{
                fontSize: 20,
                color: Palette.plum,
              }}
            >
              {`Ask`}
            </Text>
          }
          onPress={() => {
            toggleQuestionEditOverlay(`Ask:`, ``);
          }}
        />
        <SlideUpView
          onCancel={() => { toggleDeleteConfirmationOverlay(''); }}
          onCommit={pressDelete}
          show={deleteConfirmationVisible}
          buttonColors={[themeFromContext.colors.danger, themeFromContext.colors.danger]}
          working={deleteButtonRefreshing}
          icon={
            <Icon
              name='trash-alt'
              type='font-awesome-5'
              solid
              color={themeFromContext.colors.background}
            />
          }
          stretchVertical={true}
        >
          <Text
            style={{
              ...themeFromContext.textVariants.askQuestionHeader,
              color: themeFromContext.colors.primary,
              textAlign: 'left',
              paddingLeft: 10,
            }}
          >
            {`Delete this question?`}
          </Text>
          <Text
            style={{
              ...themeFromContext.textVariants.header,
              color: themeFromContext.colors.foreground,
              padding: themeFromContext.spacing.m,
              textAlign: 'center',
              flex: 1,
            }
            }>
            {`"${deleteOverlayQuestionText}"`}
          </Text>
          <Text
            style={{
              margin: themeFromContext.spacing.s,
              color: themeFromContext.colors.failure,
              fontFamily: themeFromContext.textVariants.body.fontFamily,
              fontSize: themeFromContext.textVariants.body.fontSize,
              textAlign: 'center'
            }}
          >
            {deleteOverlayErrorMessage}
          </Text>
        </SlideUpView>
        <SlideUpView
          show={questionEditOverlayVisible}
          onCancel={() => {
            toggleQuestionEditOverlay('', '');
            setTimeout(() => {
              setIdeaBankVisible(false);
            }, 500);
          }}
          working={saveButtonRefreshing}
          onBack={ideaBankVisible ? () => { setIdeaBankVisible(false); } : undefined}
          onCommit={async () => {
            await pressSave();
            setTimeout(() => {
              setIdeaBankVisible(false);
            }, 500);
          }}
          title={ideaBankVisible ? `Question Idea Bank` : `Ask Questions`}
          buttonTitle={`Submit`}
          percent={96}
          stretchVertical={true}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              zIndex: 200,
              flex: 1,
              justifyContent: 'space-between',
              //width: (width * 2) - (themeFromContext.slideUp.style.padding * 5),
            }}
          >
            <Animated.ScrollView
              style={{
                flex: 1,
                display: 'flex',
                zIndex: 200,
                //justifyContent: 'space-evenly',
                height: '100%',
                width: width - (themeFromContext.slideUp.style.padding * 2),
                transform: [
                  {
                    translateX: Animated.subtract(0, Animated.multiply(width, anim)),
                  }
                ],
                overflow: 'visible',
              }}
            >
              {/* <View
                style={{
                  display: 'flex',
                  flex: 1,
                  zIndex: 200,
                }}
              > */}
                <NTextInput
                  ref={questionTextRef}
                  multiline={true}
                  maxHeight={60}
                  maxLength={1000}
                  autoFocus={false}
                  placeholder='Type your question here...'
                  value={editOverlayQuestionText}
                  onChangeText={setEditOverlayQuestionText}
                  inputStyle={{
                    borderWidth: 0,
                    //borderRadius: 0,
                    //padding: 2,
                    ...themeFromContext.textVariants.header,
                    fontSize: 16,
                  }}
                  placeholderTextColor={'#9CA8C9'}
                  testID={`add-question-modal-question-text-input`}
                />


                {
                  editOverlayErrorMessage.length > 0 &&
                  <View>
                    <Text
                      style={{
                        margin: themeFromContext.spacing.l,
                        color: themeFromContext.colors.failure,
                        ...themeFromContext.textVariants.body,
                        textAlign: 'center'
                      }}
                    >
                      {editOverlayErrorMessage}
                    </Text>
                  </View>
                }

                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginVertical: 10,
                    zIndex: 201,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: '700',
                      color: Palette.plum,
                      textAlign: 'left',
                      marginRight: 6,
                    }}
                  >
                    {`Category:`}
                  </Text>
                  <NDropDownPicker
                    dropDownDirection={'BOTTOM'}
                    open={open}
                    setOpen={setOpen}
                    value={editOverlayCategoryName}
                    setValue={setEditOverlayCategoryName}
                    //onChangeValue={onValueChanged}
                    items={items}
                    setItems={setItems}
                    onPressItem={(value) => {
                      setEditOverlayCategoryName(value);
                      setOpen(false);
                    }}
                  />
                </View>
              {/* </View> */}
              <View
                style={{
                  marginBottom: 10
                }}
              >
              <Text
                style={{
                  fontSize: 14,
                  fontWeight: '700',
                  color: Palette.plum,
                  textAlign: 'left',
                  marginBottom: 6,
                }}
              >
                {`Image:`}
              </Text>
              <ImageUploader imageKey={editOverlayImageKey} onSave={setImage} />
              </View>
              <View
                style={{
                  borderRadius: 13,
                  paddingVertical: 16,
                  paddingHorizontal: 24,
                  backgroundColor: '#6D6D6D20',
                  alignItems: 'flex-start',
                  zIndex: 100,
                  marginBottom: 14,
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: '600',
                    color: Palette.plum
                  }}
                >
                  {`Or, try our Question Idea Bank!`}
                </Text>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: '300',
                    color: Palette.plum,
                    marginTop: 24,
                    lineHeight: 20,
                  }}
                >
                  {`Explore categories and select suggested questions you want to ask.`}
                </Text>
                <NButton
                  t={`primary`}
                  title={`View Ideas`}
                  titleStyle={{
                    fontSize: 16,
                  }}
                  style={{
                    marginTop: 24,
                  }}
                  icon={
                    <Icon
                      name='arrow-forward'
                      type='ionicons'
                      color={Palette.plum}
                      size={28}
                      style={{
                        marginLeft: 4,
                      }}
                    />
                  }
                  iconPosition={`right`}
                  onPress={() => {
                    setIdeaBankVisible(true);
                  }}
                />
              </View>
              {
                commonContext.isAdmin &&
                <View style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  <Input
                    placeholder='New category name'
                    style={{ flexGrow: 1, marginTop: themeFromContext.spacing.m }}
                    inputStyle={{ textAlignVertical: 'top', maxHeight: 300, borderRadius: 25, borderWidth: 1, borderColor: themeFromContext.colors.overlay, padding: themeFromContext.spacing.m }}
                    value={newCategoryText}
                    onChangeText={value => setNewCategoryText(value)}
                  />
                  <Button
                    loading={newCategoryButtonRefreshing}
                    title='Create new category'
                    buttonStyle={{
                      backgroundColor: themeFromContext.colors.primary,
                      margin: themeFromContext.spacing.s
                    }}
                    titleStyle={{
                      color: themeFromContext.colors.foreground
                    }}
                    disabled={newCategoryText.length === 0}
                    onPress={pressNewCategory}
                  />
                </View>
              }
            </Animated.ScrollView>
            <Animated.View
              style={{
                position: 'absolute',
                display: 'flex',
                zIndex: 200,
                justifyContent: 'space-evenly',
                height: '100%',
                width: '100%',// width - (themeFromContext.slideUp.style.padding * 2),
                transform: [
                  {
                    translateX: Animated.subtract(width, Animated.multiply(width, anim)),
                  }
                ]
              }}
            >
              <View
                style={{
                  display: 'flex',
                  zIndex: 498,
                  flex: 1,
                }}
              >

                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: '300',
                    color: Palette.plum,
                    marginTop: 24,
                    lineHeight: 20,
                  }}
                >
                  {`Explore the categories below and select any questions you want to ask, then click the “Submit” button. `}
                </Text>


                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginTop: 40,
                    marginBottom: 20,
                    zIndex: 201,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 14,
                      fontWeight: '700',
                      color: Palette.plum,
                      textAlign: 'left',
                      marginRight: 6,
                    }}
                  >
                    {`Category:`}
                  </Text>
                  <NDropDownPicker
                    dropDownDirection={'BOTTOM'}
                    open={open}
                    setOpen={setOpen}
                    value={editOverlayCategoryName}
                    setValue={setEditOverlayCategoryName}
                    //onChangeValue={onValueChanged}
                    items={items}
                    setItems={setItems}
                    onPressItem={(value) => {
                      setEditOverlayCategoryName(value);
                      setOpen(false);
                    }}
                  />
                </View>

                <Text
                    style={{
                      fontSize: 14,
                      fontWeight: '700',
                      color: Palette.plum,
                      textAlign: 'left',
                      marginRight: 6,
                      marginTop: 14,
                      marginBottom: 8,
                    }}
                  >
                    {`Suggested Questions:`}
                  </Text>
                  <ScrollView
                    style={[
                      {
                        display: 'flex',
                        flex: 1,
                      }
                    ]}
                  >
                      {
                          recommendations.filter(x => x.category.name == editOverlayCategoryName).map((question, i) => (
                              <Pressable
                                  key={i}
                                  onPress={() => {
                                    setEditOverlayQuestionText(question.questionText);
                                  }}
                                  style={[
                                    {
                                      //flex: 1,
                                      borderColor: Palette.lightgrey,
                                      borderWidth: 2,
                                      borderRadius: 12,
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      padding: 12,
                                      marginVertical: 4,
                                      //overflow: 'scroll',
                                      minHeight: 60,
                                      width: '100%',
                                    },
                                    editOverlayQuestionText == question.questionText ? {
                                      borderColor: Palette.teal,
                                      borderWidth: 2,
                                      backgroundColor: Palette.lightteal.concat('55'),
                                      //fontWeight: 'bold',
                                    } : {}
                                  ]}
                              >
                                  <NText
                                    style={{
                                      fontFamily: 'Arial',
                                      fontSize: 16,
                                      fontWeight: '100',
                                      width: '100%',
                                      textAlign: 'center',
                                      color: themeFromContext.colors.text,
                                    }}
                                  >
                                      {question.questionText}
                                  </NText>
                              </Pressable>
                          ))
                      }
                  </ScrollView>

              </View>


              {/* <View
                style={{
                  borderRadius: 13,
                  padding: 24,
                  backgroundColor: '#6D6D6D20',
                  alignItems: 'flex-start',
                  zIndex: 0,
                }}
              >
                <Text
                  style={{
                    fontSize: 20,
                    fontWeight: '600',
                    color: Palette.plum
                  }}
                >
                  {`Or, write your own!`}
                </Text>
                <Text
                  style={{
                    fontSize: 16,
                    fontWeight: '300',
                    color: Palette.plum,
                    marginTop: 24,
                    lineHeight: 20,
                  }}
                >
                  {`Not finding a question you like? You can always write your own!`}
                </Text>
                <NButton
                  t={`primary`}
                  title={`New Question`}
                  titleStyle={{
                    fontSize: 16,
                  }}
                  style={{
                    marginTop: 24,
                  }}
                  icon={
                    <Icon
                      name='arrow-back'
                      type='ionicons'
                      color={Palette.plum}
                      size={28}
                      style={{
                        marginRight: 4,
                      }}
                    />
                  }
                  iconPosition={`left`}
                  onPress={() => {
                    setIdeaBankVisible(false);
                  }}
                />
              </View> */}

            </Animated.View>
          </View>
        </SlideUpView>
      </ScrollableFlatListScreen>
    </Provider>
  );

  // function toggleSort(by: string) {
  //   let asc = sortAsc;
  //   asc = !asc;
  //   setSortAsc(asc);
  //   setSortBy(by);
  //   const s = extractQuestionSections(communityService, questions.map(x => x.data).flat(), likeMap, by, asc, community);
  //   setQuestions(s);
  //   //log.info(`sort by ${by}, asc: ${asc}`);
  // }
}

export default AskedQuestions2;