import React, { useContext, useEffect, useState } from 'react';
import { LayoutChangeEvent, StyleSheet, Text, View } from 'react-native';
import MemberAvatar from '../../components/MemberAvatar';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { CommunityUser } from '../../../API';
import { Palette } from '../../../Theme';
import { ICommunityService } from '../../../services/CommunityService';

const sort = (communityService: ICommunityService, members: CommunityUser[]) => {
  return members.sort((a, b) => {
    return communityService.compareCommunityUsersPriority(a, b);
  });
}

function MemberList(props: {
  show: number,
  size: number,
  color: string,
  textColor: string,
  backgroundColor: string,
  borderColor: string,
  extraColor: string,
  extraTextColor: string,
  members: CommunityUser[],
}): React.ReactElement {

  const { show, members, size } = props;
  const commonContext = useContext<CommonContextType>(CommonContext);
  const { communityService } = commonContext.services;
  const themeFromContext = commonContext.theme;
  const [displayParameters, setDisplayParameters] = useState({ width: 0, visible: false });
  const [memberList, setMemberList] = useState<CommunityUser[]>(sort(communityService, members));

  const [mounted, setMounted] = useState(true);

  const paddingEffect = props.borderColor == props.backgroundColor;
  const borderWidth = paddingEffect ? size / 16 : 2;
  const marginLeft = (size / 4) * -1;
  const avatarWidth = size + borderWidth + marginLeft;

  useEffect(() => {
    setMounted(true);
    const c: CommunityUser[] = sort(communityService, members);
    setMemberList(c);
    return () => { setMounted(false); }
  }, [props.members]);

  function onLayout(e: LayoutChangeEvent): void {
    if (mounted) {
      setDisplayParameters({ width: e.nativeEvent.layout.width, visible: true });
    }
  }

  // Calculate the number of avatars that will fit in the view, assuming each avatar is 36 pixels wide and the text at the end is 50 pixels
  //const avatarWidth = homeStyle.avatarSize;
  //const moreTextWidth = 60;
  let numberOfAvatarsThatFit = (displayParameters.width / avatarWidth);
  numberOfAvatarsThatFit = Math.floor(numberOfAvatarsThatFit);
  numberOfAvatarsThatFit = Math.min(show, numberOfAvatarsThatFit);
  numberOfAvatarsThatFit = Math.max(1, numberOfAvatarsThatFit);

  // at this point numberOfAvatarsThatFit will be the number of full bubbles we can fit in view, with at least 1

  const fullLength = memberList ? memberList.length : 0;
  let numberOfAvatarsToShow = fullLength;
  if (numberOfAvatarsToShow > numberOfAvatarsThatFit) {
    numberOfAvatarsToShow = numberOfAvatarsThatFit - 1;
  }
  const numberAvatarsToHide = fullLength - numberOfAvatarsToShow;
  const membersToShow = [];


  for (let i = 0; i < numberOfAvatarsToShow; i++) {
    const item = memberList[i];
    if (item) {
      membersToShow.push(item);
    }
  }

  return (
    <View onLayout={onLayout}>
      {
        displayParameters.visible &&
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            overflow: 'hidden',
            justifyContent: 'flex-start',
            //padding: 4,
            marginLeft: 0 - borderWidth,
            width: '100%',
            backgroundColor: Palette.transparent// props.backgroundColor
          }}
        >
          {
            membersToShow.map((l, i) => (
              <MemberAvatar
                key={i}
                rounded
                size={size}
                userData={l.user}
                ignoreUpdate={true}
                containerStyle={{
                  backgroundColor: props.color,
                  borderColor: props.borderColor,
                  borderWidth: borderWidth,
                  marginLeft: i == 0 ? 0 : marginLeft,
                }}
                titleStyle={{
                  color: props.textColor,
                  ...themeFromContext.textVariants.avatarText,
                }}
                // backgroundColor={themeFromContext.colors.background}
                // textColor={themeFromContext.colors.foreground}
              />
            ))
          }
          {
            numberAvatarsToHide > 0 &&
            <MemberAvatar
              key={`placeholder-key-for-avatar-list`}
              rounded
              size={size}
              title={numberAvatarsToHide > 0 ? "+" + numberAvatarsToHide : ""}
              containerStyle={{
                backgroundColor: props.extraColor,
                borderColor: props.borderColor,
                borderWidth: borderWidth,
                marginLeft: marginLeft,
              }}
              titleStyle={{
                color: props.extraTextColor,
                ...themeFromContext.textVariants.avatarText
              }}
            />
          }
        </View>
      }
    </View>
  );
}

export default MemberList;