import { CommunityUser } from "../../models";

export const getNote = (member: CommunityUser): string => {
  if (member &&
    member.invitation &&
    member.invitation?.invitationMessage &&
    member.invitation?.invitationMessage?.length > 0) {
    return member.invitation?.invitationMessage;
  } else {
    return '';
  }
}