import log from '../logging/logger';
import { API } from 'aws-amplify';
import awsConfig from '../../aws-exports';
API.configure(awsConfig);

const API_NAME = `novellaREST`;

type UserInfo  = {
  firstName: string,
  lastName: string,
  email: string,
  type?: 'spotlight'|'member',
  message?: string,
}

type GettingStartedHelpRequestBody = {
eventType: string,
payload: UserInfo,
}

export async function requestHelpGettingStarted(firstName: string, lastName: string, email: string, type?: 'spotlight'|'member', message?: string): Promise<any> {
  const apiName = API_NAME;
  const path = `/supportRequest`;

  const init: { body: GettingStartedHelpRequestBody } = {
    body: {
      eventType: 'request_help_getting_started',
      payload: {
        firstName: firstName,
        lastName: lastName,
        email: email,
        type: type,
        message: message,
      }
    }
  }

  try {
    console.log(`REQUESTING GETTING STARTED HELP for ${email} (${type})`);
    const r = await API.post(apiName, path, init);

    log.info(`GOT RESPONSE:`);
    log.info(r);
    return r;
  } catch (ex) {
    log.error(`ERROR TRYING TO GET HELP FOR: ${email} (${type})`);
    log.error(ex);
  }
}