import React, { useContext, useEffect, useState } from 'react';
import { CommunityTabParamList } from '../../../types/Navigation';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { View } from 'react-native';
import NActivityIndicator from '../../components/ActivityIndicators/NActivityIndicator';
import log from '../../../business/logging/logger';
import { useIsFocused } from '@react-navigation/native';
import VimeoScreen from './VimeoScreen';
import RequestInterviewScreen from './RequestInterviewScreen';
import { CommunityInterviewStatus } from '../../../API';
import { CommunityInterviewRequest } from '../../../API';
import { API, graphqlOperation } from 'aws-amplify';
import * as s from '../../../graphql/subscriptions';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { IInterviewRequestService } from '../../../services/InterviewRequestService';


export type InterviewScreenProps = BottomTabScreenProps<CommunityTabParamList, 'Interview'>;

const sort = (irs: IInterviewRequestService, requests: CommunityInterviewRequest[], asc: boolean) => {
  return requests.sort((a, b) => {
    return irs.compareCommunityInterviewRequests(a, b, asc);
  });
}

function InterviewScreen({ route, navigation }: InterviewScreenProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const { communityService, interviewRequestService } = commonContext.services;
  const themeFromContext = commonContext.theme;
  const communityId = route.params.communityId;
  const isFocused = useIsFocused();
  const [loading, setLoading] = useState(true);
  const [hasVideo, setHasVideo] = useState(false);
  //const [isAdmin, setIsAdmin] = useState(false);
  const [request, setRequest] = useState<CommunityInterviewRequest>();

  useEffect(() => {
    let mounted = true;
    setLoading(true);
    let subscription: any = null;
    if (isFocused) {
      Promise.all([
        communityService.getSingleCommunity(communityId),
        interviewRequestService.getInterviewRequests(communityId),
        //isLoggedInUserAdmin(),
      ]).then(results => {
        if (mounted) {
          const community = results[0];
          const requests = results[1];
          //const admin = results[2];

          if (community && community.vimeoVideos && community.vimeoVideos.length > 0 && community.vimeoVideos[0].length > 0) {
            setHasVideo(true);
          } else {
            setHasVideo(false);
          }

          const found = sort(interviewRequestService, requests, false).find(r => r.status == CommunityInterviewStatus.REQUESTED);
          setRequest(found);

          //setIsAdmin(admin);

          setLoading(false);
        }
      }).catch(ex => {
        log.error(`failed to get prerequisites for Request Interview Screen`);
        log.error(ex);
      });
      subscription = (API.graphql(
        graphqlOperation(s.onCreateCommunityInterviewRequest, { communityID: communityId })
      ) as any).subscribe({
        next: (thing: any) => {
          log.info(thing);
          const r: CommunityInterviewRequest = thing.value.data.onCreateCommunityInterviewRequest;
          log.info(r);
          setRequest(r);
        },
        error: (error: any) => log.error(error)
      });
    }
    return () => {
      mounted = false;
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [communityId, isFocused]);


  return (
    <>
     {
        loading &&
        <View style={{
          //display: (refreshing && isFocused) ? 'flex' : 'none',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <NActivityIndicator />
        </View>
      }
      {
        !loading && (request || hasVideo || commonContext.isAdmin) &&
        <VimeoScreen navigation={navigation} route={route} />
      }
      {
        !loading && !request && !hasVideo && !commonContext.isAdmin &&
        <RequestInterviewScreen navigation={navigation} route={route} />
      }
    </>
  );
}

export default InterviewScreen;