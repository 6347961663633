import React, { useContext } from 'react';
import { View } from 'react-native';
import { MAX_WIDTH } from '../../../business/layout/layout';
import { useSafeAreaInsets } from '../../../business/layout/layout';
import { Palette } from '../../../Theme';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { normalizeWidth } from '../../../business/layout/responseSize';

function HeadlessBody(props: {hasSteps?: boolean, maxWidth?: number, children?: Element[] | Element }): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { top, bottom } = useSafeAreaInsets();
  const { height } = commonContext.dimensions;

  return (
    <View
      style={{
        //height: '100%', PUTTING HEIGHT AT 100% WAS A MISTAKE BECAUSE IT STREACHES THIS VIEW DOWN BEYOND THE BOTTOM
        flex: 1,
        display: 'flex',
        backgroundColor: Palette.plum,
        paddingHorizontal: 20,
        paddingTop: 10 + (props.hasSteps ? 0 : top),
        width: '100%',
      }}
    >
      <View
        style={[
          {
            display: 'flex',
            flex: 1,
            //position: 'absolute',
            bottom: 0,
            maxWidth: props.maxWidth ?? MAX_WIDTH,
            width: '100%',
            height: '100%',
            alignSelf: 'center',
            backgroundColor: themeFromContext.colors.background,
            borderTopLeftRadius: themeFromContext.slideUp.style.borderTopLeftRadius,
            borderTopRightRadius: themeFromContext.slideUp.style.borderTopRightRadius,
            paddingHorizontal: normalizeWidth(14, commonContext.dimensions.width),
            paddingTop: 10,
            paddingBottom: bottom,
          }
        ]}
      >
        {props.children}
      </View>
    </View>
  );
}

export default HeadlessBody;