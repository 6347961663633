/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-west-2",
    "aws_appsync_graphqlEndpoint": "https://ch7hx6ete5gbtfbk5zz6qkdc5i.appsync-api.us-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-icbszkp44zaq5pin2mfad3m6ti",
    "aws_cloud_logic_custom": [
        {
            "name": "novellaREST",
            "endpoint": "https://exmf2p5c1l.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        },
        {
            "name": "novellaAI",
            "endpoint": "https://e09hu159tc.execute-api.us-west-2.amazonaws.com/dev",
            "region": "us-west-2"
        }
    ],
    "aws_cognito_identity_pool_id": "us-west-2:af32d6f6-0cba-498b-92d0-04139895b2d7",
    "aws_cognito_region": "us-west-2",
    "aws_user_pools_id": "us-west-2_ISwFkS2Tg",
    "aws_user_pools_web_client_id": "5gk9knhpmd09971245ggqh6qu1",
    "oauth": {
        "domain": "novellaquestions8f34efee-8f34efee-dev.auth.us-west-2.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://app.novella.live/redirect/,https://stage.getnovella.com/redirect/,https://dev.getnovella.com/redirect/,com.novella.ios://redirect/,http://localhost:19006/redirect/",
        "redirectSignOut": "https://app.novella.live/,https://stage.getnovella.com/,https://dev.getnovella.com/,com.novella.ios://,http://localhost:19006/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_AND_IDENTITY_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [
        "FACEBOOK",
        "GOOGLE",
        "APPLE"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "7",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "user-content104235-dev",
    "aws_user_files_s3_bucket_region": "us-west-2"
};


export default awsmobile;
