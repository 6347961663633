// Frameworks
import React, { useContext } from 'react';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { View } from 'react-native';
import { Palette } from '../../Theme';
import { CommunityQuestion } from '../../API';
import { Button, Icon } from 'react-native-elements';
import NButton from './NButton';

function RecordAnswerButton(props: { question: CommunityQuestion, clickRecordLink: (question: CommunityQuestion) => Promise<void> }): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { question, clickRecordLink } = props;

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Button
        title={`Record`}
        titleStyle={{
          ...themeFromContext.textVariants.cardBottomButton,
          fontWeight: '700',
          padding: 0,
          color: Palette.plum,
          paddingStart: 2
        }}
        buttonStyle={{
          minWidth: 0,
          marginVertical: 0,
          paddingVertical: 0,
          paddingRight: 10,
          paddingLeft: 6,
          backgroundColor: Palette.yellow,
          borderRadius: 100,
        }}
        // containerStyle={{
        //   padding: 0,
        // }}
        // style={{
        //   padding: 0,
        // }}
        onPress={async () => {
          await clickRecordLink(question);
        }}
        //type='clear'
        iconContainerStyle={{
          padding: 0,
          margin: 0,
        }}
        icon={
          <Icon
            size={themeFromContext.textVariants.header.fontSize}
            name={'add'}
            type='ionicon'
            color={Palette.plum}
          />}
        testID={`record-button-${question.id}`}
      />
    </View>
  );
}
export default RecordAnswerButton;