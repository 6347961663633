import Amplify, { Cache, Storage, API } from 'aws-amplify'
import log from '../logging/logger';
import memoryStorage from '../storage/memoryStorage';
//import { getCookieStorage } from '../env/currentEnv';
import awsConfig from '../../aws-exports';
import { Platform } from 'react-native';
import LocalForageWrapper from '../storage/localForageWrapper';
import { getCurrentEnv } from '../env/currentEnv';

export function getRedirects() {

  const [
    productionRedirectSignIn,
    stageRedirectSignIn,
    devRedirectSignIn,
    RNRedirectSignIn,
    localRedirectSignIn,
  ] = awsConfig.oauth.redirectSignIn.split(",");
  
  const [
    productionRedirectSignOut,
    stageRedirectSignOut,
    devRedirectSignOut,
    RNRedirectSignOut,
    localRedirectSignOut,
  ] = awsConfig.oauth.redirectSignOut.split(",");

  if (Platform.OS == 'web') {
    const isLocalhost = Boolean(
      window.location.hostname === "localhost" ||
        // [::1] is the IPv6 localhost address.
        window.location.hostname === "[::1]" ||
        // 127.0.0.1/8 is considered localhost for IPv4.
        window.location.hostname.match(
          /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
        )
    );
    const isDev = Boolean(window.location.hostname.includes("dev.getnovella.com"));
    const isStage = Boolean(window.location.hostname.includes("stage.getnovella.com"));
    const isProduction = Boolean(window.location.hostname.includes("app.novella.live"));

    let auth;
    let signIn;
    let signOut;
    let callback;
    if (isLocalhost) {
      //auth = `devauth.novella.live`;
      signIn = localRedirectSignIn;
      signOut = localRedirectSignOut;
      callback = localRedirectSignOut;
    } else if (isDev) {
      auth = `devauth.novella.live`;
      signIn = devRedirectSignIn;
      signOut = devRedirectSignOut;
      callback = devRedirectSignOut;
    } else if (isStage) {
      auth = `stageauth.novella.live`;
      signIn = stageRedirectSignIn;
      signOut = stageRedirectSignOut;
      callback = stageRedirectSignOut;
    } else if (isProduction) {
      auth = `auth.novella.live`;
      signIn = productionRedirectSignIn;
      signOut = productionRedirectSignOut;
      callback = productionRedirectSignOut;
    }

    return { auth, signIn, signOut, callback };
  } else {
    let auth;
    let signIn = RNRedirectSignIn;
    let signOut = RNRedirectSignOut;
    let callback = devRedirectSignOut;
    const env = getCurrentEnv();
    if (env == 'production') {
      signIn = RNRedirectSignIn;
      signOut = RNRedirectSignOut;
      callback = productionRedirectSignOut;
    } else if (env == 'stage') {
      signIn = RNRedirectSignIn;
      signOut = RNRedirectSignOut;
      callback = stageRedirectSignOut;
    }
    return { auth, signIn, signOut, callback };
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function configureAmplify() {
  const { auth, signIn, signOut } = getRedirects();

  const updatedAwsConfig = {
    ...awsConfig,
    oauth: auth ? {
      ...awsConfig.oauth,
      domain: auth, // override auth domain if we know what it should be
      redirectSignIn: signIn,
      redirectSignOut: signOut,
    } : {
      ...awsConfig.oauth,
      redirectSignIn: signIn,
      redirectSignOut: signOut,
    }
  }

  log.info(updatedAwsConfig);

  //const cookieStorage = getCookieStorage();
  Amplify.configure({ ...updatedAwsConfig, storage: LocalForageWrapper });
  //Amplify.configure({ ...updatedAwsConfig, storage: LocalForageWrapper });
  //Amplify.configure({ ...awsConfig, cookieStorage});
  API.configure(updatedAwsConfig);
  Cache.configure({ storage: memoryStorage });
  Storage.configure({ level: 'protected' });
  // Analytics.autoTrack('session', {
  //   enable: true
  // });
}