import React, { useContext } from 'react';
import ProfileScreen from '../../screens/Profile/ProfileScreen';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { getStateFromPath, NavigationContainer } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { RootStackParamList } from '../../../types/Navigation';
import CustomDrawerContent from './DrawerContent';
import CreateCommunity from '../../screens/CreateCommunity/CreateCommunity';
import WelcomeScreen from '../../screens/Welcome/WelcomeScreen';
import RedirectScreen from '../../screens/Redirect/RedirectScreen';
import SignUp from '../../screens/Auth/SignUp/SignUp';
import ForgotPassword from '../../screens/Auth/ForgotPassword/ForgotPassword';
import ConfirmCode from '../../screens/Auth/ConfirmCode/ConfirmCode';
import LoginRedirect from '../../screens/Auth/Redirect/LoginRedirect';
import Referral from '../../screens/Auth/Referral/Referral';
import Start from '../../screens/Auth/Start/Start';
import { TourGuideProvider } from 'rn-tourguide'
import HelpTooltip from './HelpTooltip';
import HomeStack from '../HomeNavigator/HomeStack';
import { Palette } from '../../../Theme';
import CommunityPreview from '../../screens/Auth/CommunityPreview/CommunityPreview';
import GregHowell from '../../screens/GregHowell/GregHowell';
import NewAnswer from '../../screens/Answers/NewAnswer';
import FastAnswer from '../../screens/Answers/FastAnswer';
import OnePersonOneQuestion2 from '../../screens/1P1Q/OnePersonOneQuestion2';
import GregHowel from '../../screens/GregHowell/GregHowel';
import UploadsScreen from '../../screens/Uploads/UploadsScreen';
import SignIn2 from '../../screens/Auth/SignIn/SignIn2';
import QuickStart from '../../screens/Auth/QuickStart/QuickStart';
import FastOnePersonOneQuestion from '../../screens/1P1Q/FastOnePersonOneQuestion';
import HelpScreen from '../../screens/Help/HelpScreen';
import GettingStarted from '../../screens/Auth/GetStarted/GettingStarted';
import Other from '../../screens/Auth/GetStarted/Other';
import OtherOptions from '../../screens/Auth/GetStarted/OtherOptions';
import Invited from '../../screens/Auth/GetStarted/Invited';
import ScanCode from '../../screens/Auth/ScanCode/ScanCode';

const Drawer = createDrawerNavigator<RootStackParamList>();

function RootNavigatorCommon(): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  //log.warn(`RootNavigator, loggedInUserId: ${commonContext.loggedInUser?.id}`);
  const themeFromContext = commonContext.theme;

  const styles = {
    headerStyle: {
      backgroundColor: themeFromContext.colors?.background,
    },
    headerTitleStyle: {
      color: themeFromContext.colors?.foreground,
      fontFamily: themeFromContext.textVariants.header.fontFamily,
    },
    headerTintColor: themeFromContext.colors.foreground,
  };

  const linking = {
    prefixes: [
      /* your linking prefixes */
      'https://main.d235mgzomgv22u.amplifyapp.com',
      'https://dev.d235mgzomgv22u.amplifyapp.com',
      'https://stage.d235mgzomgv22u.amplifyapp.com',
      'https://*.d235mgzomgv22u.amplifyapp.com',
      'http://localhost:19006',
      'https://*.novella.live',
      'https://novella.live',
      'https://*.getnovella.com',
      'live.novella.app://',
      'com.novella.ios://',
    ],
    config: {
      /* configuration for matching screens with paths */
      screens: {
        Redirect: 'redirect',
        Home: {
          path: '',
          initialRouteName: 'My Communities',
          screens: {
            'My Communities': 'communities/:userId',
            Community: {
              path: 'community/:communityId',
              initialRouteName: 'Asked',
              screens: {
                Questions: {
                  path: 'questions',
                  initialRouteName: 'Asked',
                  screens: {
                    Asked: '/asked/:communityId/:operation?',
                    Answers: '/answers/:communityQuestionId',
                    'Answer Comments': '/answer/comments/:communityQuestionAnswerId',
                  }
                },
                Recommended: 'recommended/:communityId',
                Members: 'members/:communityId',
                Interview: 'interview/:communityId',
                Activity: 'activity/:communityId',
                Watch: 'watch/:communityId',
                Test: 'test/:communityId'
              }
            },
          }
        },
        Welcome: 'welcome/:requested',
        Profile: 'profile/:userId',
        Rewards: 'rewards/:userId',
        Administration: 'admin',
        Create: 'create/:userId',
        "Sign In": 'signin',
        "Quick Start": 'quickstart',
        "Getting Started": 'getting-started',
        "Other": 'other',
        "Other Options": 'other-options',
        "Invited": 'invited/:type?',
        "Sign Up": 'signup',
        "Forgot Password": 'forgotpassword',
        "Confirm Code": 'confirmcode',
        "Login": 'login',
        "Referral": 'join',
        "Start": 'start',
        "Community Preview": 'preview/:communityId',
        "New Answer": '/answer/:communityQuestionId',
        "Greg Howell": 'greghowell',
        "Greg Howel": 'greghowel',
        "Ask Now": 'onequestion',
        "Go Fast": 'fpq/:type?',
        "Fast Answer": '/fa/:communityQuestionId',
        Uploads: 'uploads',
        Help: 'help',
        'Scan Code': 'scan',
      }
    },
    getStateFromPath: (path: any, options: any) => {
      if (path != undefined && path != null) {
        const lowerPath = path.toLowerCase();

        console.log(`path: ${path}`);
        // redirect from /create to /onequestion
        const newPath = lowerPath.startsWith('/create') ? '/onequestion' : lowerPath;
        return getStateFromPath(newPath, options);
      }
    },
  };

  return (
    <NavigationContainer linking={linking} theme={themeFromContext} documentTitle={{ formatter: (options, route) => `Novella - ${route?.name}`, }} >
      <TourGuideProvider {...{ tooltipComponent: HelpTooltip, backdropColor: 'rgba(0,0,0,0.7)' }}>
        <Drawer.Navigator
          backBehavior='history'
          //initialRouteName="Scan Code"
          drawerContent={CustomDrawerContent}
          // useLegacyImplementation={true}
          screenOptions={({ navigation }) => ({
            ...styles,
            drawerStyle: {
              display: 'flex',
              height: '100%',
              flex: 1,
              backgroundColor: Palette.plum,
            },
            drawerPosition: 'right',
            gestureEnabled: false,
            headerShown: false,
            // sceneContainerStyle: {
            //   // maxWidth: MAX_WIDTH,
            //   // alignSelf: 'center',
            //   // width: '100%',
            //   //paddingHorizontal: 500,
            // },
          })}
        >
          <Drawer.Screen name="Home" component={HomeStack} />
          <Drawer.Screen name="Profile" component={ProfileScreen} />
          {/* <Drawer.Screen name="Rewards" component={RewardsScreen} /> */}
          <Drawer.Screen name="Create" component={CreateCommunity} />
          <Drawer.Screen name="Redirect" component={RedirectScreen} />
          <Drawer.Screen name="Ask Now" component={OnePersonOneQuestion2} />
          <Drawer.Screen name="Welcome" component={WelcomeScreen} />
          <Drawer.Screen name="Sign In" component={SignIn2} />
          <Drawer.Screen name="Quick Start" component={QuickStart} />
          <Drawer.Screen name="Go Fast" component={FastOnePersonOneQuestion} />
          <Drawer.Screen name="Sign Up" component={SignUp} />
          <Drawer.Screen name="Forgot Password" component={ForgotPassword} />
          <Drawer.Screen name="Confirm Code" component={ConfirmCode} />
          <Drawer.Screen name="Login" component={LoginRedirect} />
          <Drawer.Screen name="Referral" component={Referral} />
          <Drawer.Screen name="Start" component={Start} />
          <Drawer.Screen name="Community Preview" component={CommunityPreview} />
          <Drawer.Screen name='New Answer' component={NewAnswer} />
          <Drawer.Screen name='Fast Answer' component={FastAnswer} />
          <Drawer.Screen name="Greg Howell" component={GregHowell} />
          <Drawer.Screen name="Uploads" component={UploadsScreen} />
          <Drawer.Screen name="Greg Howel" component={GregHowel} />
          <Drawer.Screen name="Help" component={HelpScreen} />
          <Drawer.Screen name="Getting Started" component={GettingStarted} />
          <Drawer.Screen name="Other" component={Other} />
          <Drawer.Screen name="Other Options" component={OtherOptions} />
          <Drawer.Screen name="Invited" component={Invited} />
          <Drawer.Screen name="Scan Code" component={ScanCode} />
        </Drawer.Navigator>
      </TourGuideProvider>
      {/* <NovellaCookieConsent /> */}
    </NavigationContainer>
  );
}

export default RootNavigatorCommon;