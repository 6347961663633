import * as React from 'react';
import Webcam from "react-webcam";
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { Alert, StyleSheet, Text, View } from 'react-native';
import { CAPTURE_BUTTON_SIZE, CONTENT_SPACING, SAFE_AREA_PADDING } from './Constants';
import Reanimated, { Extrapolate, interpolate, useAnimatedGestureHandler, useAnimatedProps, useAnimatedStyle, useSharedValue, withRepeat, withSpring } from 'react-native-reanimated';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Palette } from '../../../Theme';
import { convertNumberSecondsToTimestamp } from '../../../business/date/dateFormatter';
import i18n from 'i18n-js';
import { PressableOpacity } from 'react-native-pressable-opacity';
import IonIcon from 'react-native-vector-icons/Ionicons';
import PromptModal from '../../components/Modals/PromptModal';
import { useIsFocused } from '@react-navigation/native';

const BUTTON_SIZE = 60;
const BORDER_WIDTH = CAPTURE_BUTTON_SIZE * 0.1;

export function VisionCamera(props: { active: boolean, prompt?: string, maxDuration: number, onAccept: (uri: string) => Promise<void>, onAcceptBlob: (blob: Blob) => void, onCancel: () => void }): React.ReactElement {
  const { active, prompt, maxDuration, onAcceptBlob, onCancel } = props;
  const commonContext = React.useContext<CommonContextType>(CommonContext);

  const webcamRef = React.useRef<Webcam & HTMLVideoElement>(null);
  const mediaRecorderRef = React.useRef(null);
  const [recordedChunks, setRecordedChunks] = React.useState([]);

  const timer = useRef<NodeJS.Timer>();
  const startTime = useRef<Date>();
  const [isRecording, setIsRecording] = useState(false);
  const [durationMs, setDurationMs] = useState(0);
  const [remainingMs, setRemainingMs] = useState(0);
  const [countingDown, setCountingDown] = useState(false);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoBlob, setVideoBlob] = useState<Blob | undefined>();

  const [showRerecordWarning, setShowRerecordWarning] = useState(false);
  const [showBrowserWarning, setShowBrowserWarning] = useState(false);
  
  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      // analytics
      commonContext.analytics?.viewPage('Camera', {});
    }
  }, [isFocused]);

  const handleStartCaptureClick = React.useCallback(() => {
    setIsRecording(true);

    console.log(`STARTING RECORDING!`);
    console.log(`webcam:`);
    console.log(webcamRef.current);
    console.log(`stream:`);
    console.log(webcamRef.current.stream);

    if (webcamRef.current && !webcamRef.current.stream) {
      setShowBrowserWarning(true);
    } else {
      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
        mimeType: "video/webm"
      });
      mediaRecorderRef.current.ondataavailable = handleDataAvailable2;
      mediaRecorderRef.current.start();
    }
  }, [webcamRef, setIsRecording, mediaRecorderRef]);

  function handleDataAvailable2(event: any) {
    console.log("data-available");
    if (event.data.size > 0) {
      recordedChunks.push(event.data);
      console.log(recordedChunks);
      console.log(`blah blah 1`);

      const blob = new Blob(recordedChunks, {
        type: "video/webm"
      });
      const url = URL.createObjectURL(blob);
      console.log(`set video blob`);
      setVideoBlob(blob);
      setVideoUrl(url);
      console.log(`videoUrl: ${url}`);
    } else {
      // …
    }
  }

  const handleDataAvailable = React.useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStopCaptureClick = React.useCallback(() => {
    mediaRecorderRef.current.stop();
    setIsRecording(false);
  }, [mediaRecorderRef, webcamRef, setIsRecording, recordedChunks]);

  // const handleDownload = React.useCallback(() => {
  //   if (recordedChunks.length) {
  //     const blob = new Blob(recordedChunks, {
  //       type: "video/webm"
  //     });
  //     const url = URL.createObjectURL(blob);

  //     // window.URL.revokeObjectURL(url);
  //     // setRecordedChunks([]);
  //   }
  // }, [recordedChunks]);


  useEffect(() => {
    setCountingDown(false);
    if (mediaRecorderRef.current) {
      if (isRecording) { // means "isRecording"
        //console.log(`STARTED RECORDING!`);
        const dt = new Date();
        startTime.current = dt;
        setDurationMs(0);
        timer.current = setInterval(() => {
          if (startTime.current) {
            const max = maxDuration * 1000;
            const duration = Date.now() - startTime.current?.getTime();
            const remaining = max - duration;

            console.log(` `);
            console.log(`duration: ${duration}`);
            console.log(`remaining: ${remaining}`);
            console.log(`maxDuration: ${max}`);
            console.log(`countingDown: ${countingDown}`);

            setDurationMs(duration);
            setRemainingMs(remaining);

            if (remaining <= 60000) {
              setCountingDown(true);
            }
            // else {
            //   setCountingDown(false);
            // }

            if (duration >= max) {
              // auto-stop if we hit the max duration
              mediaRecorderRef.current?.stop();
              setIsRecording(false);
            }

          }
        }, 1000);
      } else {
        //console.log(`STOPPED RECORDING!`);
        setRemainingMs(0);
        if (timer.current) {
          clearInterval(timer.current);
        }
      }
    }

    return () => { if (timer.current) clearInterval(timer.current) };
  }, [isRecording, maxDuration]);

  const shadowStyle = useAnimatedStyle(
    () => {
      //console.log(`new value for isRecording: ${isRecording.value}`);
      const thing = {
        transform: [
          {
            scale: withSpring(isRecording ? 0.4 : 0.8, {
              mass: 1,
              damping: 35,
              stiffness: 300,
            }),
          },
        ],
        borderRadius: withSpring(isRecording ? 2 : CAPTURE_BUTTON_SIZE / 2, {
          mass: 1,
          damping: 35,
          stiffness: 300,
        }),
      };
      //console.log(JSON.stringify(thing, null, 2));
      return thing;
    },
    [isRecording],
  );

  const timerStyle = useAnimatedStyle(() => {
    let scale: number;
    if (countingDown) {
      if (isRecording) {
        scale = withRepeat(
          withSpring(1.2, {
            stiffness: 100,
            damping: 1000,
          }),
          -1,
          true,
        );
      } else {
        scale = 1;
        // withSpring(0.9, {
        //   stiffness: 500,
        //   damping: 300,
        // });
      }
    } else {
      scale = 1;
      // withSpring(0.6, {
      //   stiffness: 500,
      //   damping: 300,
      // });
    }

    return {
      opacity: 1,
      transform: [
        {
          scale: scale,
        },
      ],
    };
  }, [countingDown, isRecording]);

  const onSavePressed = useCallback(async () => {
    try {
      //setSavingState('saving');

      // await CameraRoll.save(`file://${path}`, {
      //   type: type,
      // });
      if (videoBlob) {
        onAcceptBlob(videoBlob);
      }

      //setSavingState('saved');
    } catch (e) {
      const message = e instanceof Error ? e.message : JSON.stringify(e);
      //setSavingState('none');
      Alert.alert('Failed to save!', `An unexpected error occured while trying to save. ${message}`);
    }
  }, [videoBlob]);

  return (
    <View
      style={{
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
        // bottom: 0,
        // right: 0,
        width: commonContext.dimensions.width,
        height: commonContext.dimensions.height,
        backgroundColor: 'black',
      }}
    >
      <PromptModal
        show={showBrowserWarning}
        heading={`Unsupported browser!`}
        prompt={`Sorry, but your browser does not support recording video. Please try again using a different browser such as Chrome.`}
        confirm={onCancel}
        confirmButtonText={`Ok`}
      />
      {
        (!videoBlob || videoBlob.size == 0) &&
        <>
          <Webcam
            ref={webcamRef}
            audio={true}
            muted={true}
            mirrored={true}
            width={commonContext.dimensions.width}
            height={commonContext.dimensions.height}
            videoConstraints={{
              width: { min: 640, ideal: 1920, max: 1920 },
              height: { min: 400, ideal: 1080, max: 1080 },
              // aspectRatio: 1.777777778,
              // frameRate: { max: 30 },
              // facingMode: { exact: "user" },
            }}
          />
          <Reanimated.View style={[styles.durationTimer, timerStyle]}>
            <Text
              style={{
                color: Palette.white,
                fontSize: 18,
                textAlign: 'center',
              }}
            >
              {`${countingDown ? i18n.t(`CameraView_TimeRemaining_Message`, { 0: convertNumberSecondsToTimestamp(remainingMs / 1000) }) : convertNumberSecondsToTimestamp(durationMs / 1000)}`}
            </Text>
          </Reanimated.View>
          <PressableOpacity
            style={styles.captureButton}
            onPress={() => { isRecording ? handleStopCaptureClick() : handleStartCaptureClick() }}
          >
            <Reanimated.View style={[styles.flex]}>
              <Reanimated.View style={[styles.shadow, shadowStyle]} />
              <View style={styles.button} />
            </Reanimated.View>
          </PressableOpacity>
        </>
      }
      {
        videoUrl.length > 0 &&
        <>
          <video
            width={commonContext.dimensions.width}
            height={commonContext.dimensions.height}
            controls={true}
            src={videoUrl}
          />
          <PressableOpacity
            style={styles.closeButton}
            onPress={() => setShowRerecordWarning(true)}
          >
            <IonIcon name="arrow-undo-outline" size={35} color="white" style={styles.icon} />
            <Text style={styles.buttonText}>
              {`Re-record`}
            </Text>
          </PressableOpacity>

          <PressableOpacity style={styles.saveButton} onPress={onSavePressed} >
            <IonIcon name="checkmark-circle-outline" size={35} color="white" style={styles.icon} />
            <Text style={styles.buttonText}>
              {`Save`}
            </Text>
          </PressableOpacity>

          <PromptModal
            show={showRerecordWarning}
            heading={`Warning!`}
            prompt={`Clicking "Yes" below means you will discard your current recording. Are you sure you want to re-record?`}
            confirm={() => setShowRerecordWarning(false)}
            confirmButtonText={`No`}
            //backdropHeight?: number,
            deny={onCancel}
            denyButtonText={`Yes`}
          //dismiss={async () => { setShowRerecordWarning(false); }}
          // working?: boolean,
          // testID?: string,
          // children?: ReactElement | ReactElement[]
          />
        </>
      }
      {
        prompt != undefined && prompt.length > 0 &&
        <View
          style={[
            styles.questionPrompt,
          ]}>
          <Text
            style={[styles.questionPromptText, { textAlign: 'left' }]}
            numberOfLines={10}
          >
            {prompt}
          </Text>
        </View>
      }
      <PressableOpacity
        style={[
          styles.backButton
        ]}
        onPress={onCancel}
        disabledOpacity={0.4}
      >
        <IonIcon name={'arrow-back'} color="white" size={32} />
      </PressableOpacity>
    </View>
  );
}

const styles = StyleSheet.create({
  captureButton: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: SAFE_AREA_PADDING.paddingBottom,
  },
  durationTimer: {
    position: 'absolute',
    alignSelf: 'center',
    bottom: SAFE_AREA_PADDING.paddingBottom + BUTTON_SIZE + (CONTENT_SPACING * 2),
    backgroundColor: 'red',
    padding: 4,
    paddingHorizontal: 8,
    minWidth: 90,
    display: 'flex',
    alignItems: 'center',
  },
  backButton: {
    //marginBottom: (CONTENT_SPACING * 2) + BUTTON_SIZE,
    width: BUTTON_SIZE,
    height: BUTTON_SIZE,
    borderRadius: BUTTON_SIZE / 2,
    backgroundColor: 'rgba(50, 50, 50, 0.6)',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    alignSelf: 'flex-end',
    top: SAFE_AREA_PADDING.paddingTop,
    left: SAFE_AREA_PADDING.paddingLeft,
  },
  questionPrompt: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignSelf: 'center',
    maxWidth: '95%',
    left: SAFE_AREA_PADDING.paddingRight * 2 + BUTTON_SIZE,
    top: SAFE_AREA_PADDING.paddingTop,
    position: 'absolute',
  },
  questionPromptText: {
    backgroundColor: '#00000066',
    color: 'white',
    padding: 10,
    fontFamily: 'Arial',
    fontSize: 24,
    fontWeight: 'normal',
    borderRadius: 10,
    overflow: 'hidden',
  },
  text: {
    color: 'white',
    fontSize: 11,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  flex: {
    flex: 1,
  },
  shadow: {
    position: 'absolute',
    width: CAPTURE_BUTTON_SIZE - 4,
    height: CAPTURE_BUTTON_SIZE - 4,
    borderRadius: (CAPTURE_BUTTON_SIZE - 4) / 2,
    backgroundColor: '#ff0000db',
    marginLeft: 2,
    marginTop: 2,
  },
  button: {
    width: CAPTURE_BUTTON_SIZE,
    height: CAPTURE_BUTTON_SIZE,
    borderRadius: CAPTURE_BUTTON_SIZE / 2,
    borderWidth: BORDER_WIDTH,
    borderColor: 'white',
  },
  closeButton: {
    position: 'absolute',
    bottom: '20%',//SAFE_AREA_PADDING.paddingTop,
    left: SAFE_AREA_PADDING.paddingLeft * 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Palette.red.concat('99'),
    color: 'white',
    padding: 20,
    fontFamily: 'Arial',
    fontSize: 24,
    fontWeight: 'normal',
    borderRadius: 100,
    overflow: 'hidden',
    borderColor: 'white',
    borderWidth: 1,
    width: '20%',
  },
  buttonText: {
    color: 'white',
    fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: 'normal',
  },
  saveButton: {
    position: 'absolute',
    bottom: '20%',//SAFE_AREA_PADDING.paddingTop,
    right: SAFE_AREA_PADDING.paddingLeft * 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Palette.green.concat('99'),
    color: 'white',
    padding: 20,
    fontFamily: 'Arial',
    fontSize: 24,
    fontWeight: 'normal',
    borderRadius: 100,
    overflow: 'hidden',
    borderColor: 'white',
    borderWidth: 1,
    width: '20%',
  },
  icon: {
    textShadowColor: 'black',
    textShadowOffset: {
      height: 0,
      width: 0,
    },
    textShadowRadius: 1,
  },
});