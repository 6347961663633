import { CommunityQuestionRecommendation } from "../API";
import { API } from 'aws-amplify';
import * as m from '../graphql/mutations';
import * as q from '../graphql/queries';
import * as cq from '../graphql/customQueries';
import { ICategoryService } from "../contracts/ICategoryService";
import { IUserService } from "../contracts/IUserService";
import log from "../business/logging/logger";
import { IAPIWrapper } from "../business/graphql/APIWrapper";
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { IQuestionRecommendationService, RecommendationFromFile } from "../contracts/IQuestionRecommendationService";

export class QuestionRecommendationService implements IQuestionRecommendationService {
  env: string;
  api: IAPIWrapper;
  categoryService: ICategoryService;
  userService: IUserService;
  initialRecommendations: RecommendationFromFile[];

  constructor(env: string, api: IAPIWrapper, categoryService: ICategoryService, userService: IUserService) {
    this.env = env;
    this.api = api;
    this.categoryService = categoryService;
    this.userService = userService;
    if (env == 'stage') {
      this.initialRecommendations = require(`../../supplemental/recommendations/stage/recommendations.json`).recommendations;
    } else if (env == 'production') {
      this.initialRecommendations = require(`../../supplemental/recommendations/production/recommendations.json`).recommendations;
    } else {
      this.initialRecommendations = require(`../../supplemental/recommendations/dev/recommendations.json`).recommendations;
    }
    
  }
  compareCommunityQuestionRecommendations(
    a: CommunityQuestionRecommendation,
    b: CommunityQuestionRecommendation,
    by: string,
    asc: boolean): number {
    if (by === "score") {
      if (a.score < b.score) {
        return asc ? -1 : 1;
      } else if (a.score > b.score) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    } else if (by === "category") {
      return this.categoryService.compareCategories(a.category.name, b.category.name, asc);
    } else if (by === 'questionText') {
      if (a.questionText < b.questionText) {
        return asc ? -1 : 1;
      } else if (a.questionText > b.questionText) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }
  async getFullRecommendationList(communityId: string): Promise<(CommunityQuestionRecommendation)[]> {
    const start1 = Date.now();
    const existing = await this.getRecommendedQuestions(communityId);
    const end1 = Date.now();
    console.warn(`getRecommendedQuestions took ${end1 - start1}ms`);
    const newRecs = await this.createInitialRecommendations(
      communityId,
      this.initialRecommendations
        .filter(r => !existing.some(e => e.questionText.toLowerCase() == r.text.toLowerCase() &&
          e.category.name.toLowerCase() == r.category.toLowerCase())));
    newRecs.forEach(n => {
      if (n) {
        existing.push(n);
      }
    });
    return existing.filter(e => e.status != "DELETED");
  }
  async createInitialRecommendations(communityId: string, recommendations?: RecommendationFromFile[]): Promise<(CommunityQuestionRecommendation | void)[]> {
    const todo = recommendations ?? this.initialRecommendations;

    // Create any missing categories (when multiple recs had the same category, we ended up with duplicate categories being created)
    const uniqueCategories = [... new Set(todo.map(t => t.category))];
    await Promise.all(uniqueCategories.map(category => {
      return this.categoryService.createNewCategory(category);
    }));

    // Create the recommendations
    return await Promise.all(todo.map(r => {
      return this.saveNewRecommendation(r.text, r.category, communityId, (d, u) => log.error(d));
    }));
  }
  async getRecommendedQuestions(communityId: string): Promise<CommunityQuestionRecommendation[]> {
    if (!communityId) {
      return [];
    }

    const page: any = await this.api.graphql({
      query: cq.recommendationsByCommunity2,
      variables: {
        communityID: communityId,
        limit: 1000,
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    });
    console.log(page);
    const reqs = page.data.recommendationsByCommunity.items;
    return reqs;
  }
  async saveNewRecommendation(
    questionText: string,
    categoryName: string,
    communityId: string,
    failure: (devMessage: string, userMessage: string) => void): Promise<CommunityQuestionRecommendation | void> {
    const newCategory = await this.categoryService.getValidCategory(categoryName, (m) => { failure(m, ''); });
    const user = await this.userService.getLoggedInUser();
    if (newCategory && user) {
      // Creating a new communityQuestion for existing community
      const result: any = await API.graphql({
        query: m.createCommunityQuestionRecommendation,
        variables: {
          input: {
            communityID: communityId,
            categoryID: newCategory.id,
            questionText: questionText,
            score: 0,
          }
        }
      });
      return result.data.createCommunityQuestionRecommendation;
    }
  }
  async saveExistingRecommendation(
    questionText: string,
    categoryName: string,
    communityQuestionRecommendationId: string,
    failure: (devMessage: string, userMessage: string) => void): Promise<void> {
    const newCategory = await this.categoryService.getValidCategory(categoryName, (m) => { failure(m, ''); });
    const rec = await this.getValidRecommendation(communityQuestionRecommendationId, (m) => { failure(m, ''); });

    if (rec && newCategory) {
      await API.graphql({
        query: m.updateCommunityQuestionRecommendation,
        variables: {
          input: {
            id: rec.id,
            communityID: rec.community.id,
            categoryID: newCategory.id,
            questionText: questionText,
            score: rec.score
          }
        }
      });
    }
  }
  async deleteExistingRecommendation(
    communityQuestionRecomendationId: string,
    failure: (devMessage: string, userMessage: string) => void): Promise<void> {
    const rec = await this.getValidRecommendation(communityQuestionRecomendationId, (m) => { failure(m, ''); });
    if (rec) {
      // Now we have everything we need to update the existing rec
      await API.graphql({
        query: m.updateCommunityQuestionRecommendation,
        variables: {
          input: {
            id: rec.id,
            status: "DELETED"
          }
        }
      });
    } else {
      // should not happen
      const error = `trying to delete recommendation that can't be found: ${communityQuestionRecomendationId}`;
      failure(error, '');
    }
  }
  async getValidRecommendation(communityQuestionRecommendationId: string, failure: (message: string) => void): Promise<CommunityQuestionRecommendation | undefined> {
    if (!communityQuestionRecommendationId) {
      return;
    }
    const req: any = await this.api.graphql({
      query: q.getCommunityQuestionRecommendation,
      variables: {
        id: communityQuestionRecommendationId,
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    });
    console.log(req);
    return req.data.getCommunityQuestionRecommendation;
  }
}