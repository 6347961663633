/* eslint-disable react/display-name */
import React, { useContext, useEffect, useState } from 'react';
import { Image, ScrollView, View } from 'react-native';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { CommunityTabParamList } from '../../../types/Navigation';
import log from '../../../business/logging/logger';
import { getVimeoMetadata, VimeoDisplayMetadata } from '../../../business/video/vimeo';
import NButton from '../../components/NButton';
import NText from '../../components/NText';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { subscribeToCommunityUpdates } from '../../../business/user/userHelper';
import { normalizeHeight } from '../../../business/layout/responseSize';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import ScrollableViewScreen from '../../components/Layouts/ScrollableViewScreen';
import { Icon } from 'react-native-elements';
import VimeoPlayer from '../../components/VimeoPlayer/VimeoPlayer';
import { useInterval } from '../../../business/hooks/useInterval';
const imageWidth = 1200;
const imageHeight = 700;
const maxImageWidth = 500;

export type VimeoProps = BottomTabScreenProps<CommunityTabParamList, 'Interview'>;

function VimeoScreen({ route, navigation }: VimeoProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const { communityService } = commonContext.services;
  const themeFromContext = commonContext.theme;
  const [pageLoading, setPageLoading] = useState(true);
  const isFocused = useIsFocused();
  const [communityName, setCommunityName] = useState('Community');
  const linkTo = useLinkTo();
  const { height } = commonContext.dimensions;
  const [subscription, setSubscription] = useState<any>();
  const communityId = route.params.communityId;
  const [pollInterval, setPollInterval] = useState<number|null>(null);
  const [meta, setMeta] = useState<VimeoDisplayMetadata>();
  const w = maxImageWidth;
  const h = imageHeight * (w / imageWidth)

  useEffect(() => {
    let mounted = isFocused;
    let sub: any = null;
    setPageLoading(true);
    if (mounted && communityId != undefined) {
      commonContext.analytics?.viewPage(route.name, route.params);
      handleCommunity(communityId, mounted);

      // subscribe to Community updates
      log.info(`UNSUBSCRIBING FROM COMMUNITY UPDATES`);
      subscription?.unsubscribe();
      sub = subscribeToCommunityUpdates(communityId, (c) => {
        handleCommunity(c.id, mounted);
      });
      setSubscription(sub);
    }

    return () => {
      log.info(`UNSUBSCRIBING FROM COMMUNITY UPDATES UNMOUNT`);
      subscription?.unsubscribe();
      mounted = false;
    };
  }, [communityId, isFocused]);

  useInterval(async () => {
    const m = await getVimeoMetadata(communityService, communityId);
    setMeta(m);

    if (m?.transcodingStatus == 'complete') {
      setPollInterval(null);
    }
  }, pollInterval);

  const handleCommunity = (communityId: string, mounted: boolean) => {
    log.info(`GOT COMMUNITY:`);
    log.info(communityId);
    getVimeoMetadata(communityService, communityId)
      .then((metadata) => {
        if (mounted) {
          if (metadata && metadata.embedUrl.length > 0) {
            log.info(`GOT METADATA FROM COMMUNITY: ${communityId}, ${JSON.stringify(metadata, null, 2)}`);

            setMeta(metadata);
            if (metadata.transcodingStatus != 'complete') {
              setPollInterval(10000); // poll transcode status every 10 seconds, starting now
            }
          } else {
            // no video, initialize
            setMeta(undefined);
          }
          setPageLoading(false);
        }
      })
      .catch((ex: any) => {
        log.error(ex);
      });
    communityService.getSingleCommunity(communityId)
      .then((community) => {
        if (mounted && community) {
          setCommunityName(community.name);
        }
      });
  }

  return (
    <ScrollableViewScreen
      navigation={navigation}
      isFocused={isFocused}
      refreshing={pageLoading}
      topTitle={`Interview`}
      bottomTitle={communityName}
      goBack={navigation.goBack}
      style={{
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
      contentContainerStyle={{
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
      }}
      testID={`vimeo-screen`}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          flex: 1,
        }}
      >
        {
          !meta &&
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              paddingBottom: 80,
            }}
            testID={`vimeo-screen-no-video-view`}
          >
            <Image
              source={require('../../../../assets/welcome/introduction/Enjoy_Your_Memories.jpg')}
              defaultSource={require('../../../../assets/welcome/introduction/Enjoy_Your_Memories.jpg')}
              resizeMethod='scale'
              resizeMode='contain'
              style={{
                width: '100%',
                height: h,
                marginTop: 0,
              }}
            />
            <NText
              style={{
                color: themeFromContext.colors.foreground,
                textAlign: 'center',
                ...themeFromContext.textVariants.header,
                fontWeight: 'normal',
                marginBottom: 10,
              }}
              testID='vimeo-screen-no-video-message-heading'
            >
              {`Come back soon!`}
            </NText>
            <NText
              style={{
                color: themeFromContext.colors.foreground,
                textAlign: 'center',
                paddingHorizontal: 20,
                ...themeFromContext.textVariants.body,
                fontSize: 18,
                fontWeight: '300',
              }}
              testID='vimeo-screen-no-video-message-body'
            >
              {`Once your interview has been recorded and edited by the Novella team, this is where you'll come to watch and enjoy the finished product.`}
            </NText>
          </View>
        }
        {
          meta && // HAS VIDEO
          <View
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignSelf: 'center',
              flex: 1,
              paddingTop: 10,
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
            testID={`vimeo-screen-video-view`}
          >
              <VimeoPlayer
                embedUrl={meta.embedUrl}
                width={meta.videoWidth}
                height={meta.videoHeight}
                transcodingStatus={meta.transcodingStatus}
                m3u8={meta.m3u8}
                thumbnailUrls={meta.thumbnailUrls}
                //heightConstrained={true}
              />
              <ScrollView
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  padding: 10,
                  //marginBottom: 10,
                  flex: 1,
                }}
                contentContainerStyle={{
                  alignItems: 'flex-start',
                  justifyContent: 'flex-start',
                  //padding: 10,
                }}
              >
                <NText
                  style={{
                    color: themeFromContext.colors.foreground,
                    ...themeFromContext.textVariants.header,
                    marginBottom: 8,
                  }}
                  testID={`vimeo-screen-video-title`}
                >
                  {`${meta.title}`}
                </NText>
                <NText
                  style={{
                    color: themeFromContext.colors.text,
                    ...themeFromContext.textVariants.body,
                  }}
                  testID={`vimeo-screen-video-description`}
                >
                  {`${meta.description}`}
                </NText>
              </ScrollView>
              <NButton
                t={'brightLink'}
                title={`Tell a friend about Novella`}
                onPress={() => linkTo(`/rewards/${commonContext.loggedInUser?.id}`)}
                style={{
                  padding: 10,
                }}
                titleStyle={{
                  textDecorationLine: 'underline',
                }}
                iconPosition='right'
                icon={
                  <Icon
                    size={themeFromContext.textVariants.body.fontSize}
                    name={'heart-outline'}
                    type='material-community'
                    color={themeFromContext.colors.primary}
                    style={{
                      padding: 4
                    }}
                  />
                }
              />
              <View
                style={{
                  paddingBottom: normalizeHeight(80, height),
                }}
              >
              </View>
            </View>
        }

      </View>
    </ScrollableViewScreen>
  );
}

export default VimeoScreen;