const json = require('../../../amplify/.config/local-env-info.json');

export function getCurrentEnv() : string {
  let currentEnv = '';
    try {
      const envInfo = json;
      currentEnv = envInfo.envName;
    } catch (err) {
      console.error(err)
    }
    return currentEnv;
}

export function getRootDomain(): string {
  let rootDomain = 'localhost';
  try {
    const currentEnv = getCurrentEnv();
    if ( currentEnv == 'stage') {
      rootDomain = 'stage.getnovella.com';
    } else if ( currentEnv == 'dev') {
      rootDomain = 'dev.getnovella.com';
    } else if (currentEnv == 'prod' || currentEnv == 'production') {
      rootDomain = 'app.novella.live';
    }
  } catch (ex) {
    console.error(ex);
  }
  return rootDomain;
}

export function getCookieStorage() : any {
  // dev config defaults
  let domain = 'localhost';
  let secure = false;
  const path = '/';
  const expires = 365;

  try {
    const currentEnv = getCurrentEnv();
    console.info(`current environment: ${currentEnv}`);
    console.warn(`current environment: ${currentEnv}`);
    if ( currentEnv == 'stage') {
      domain = 'stage.getnovella.com';
      secure = true;
    } else if ( currentEnv == 'dev') {
      domain = 'dev.getnovella.com';
      secure = true;
    } else if (currentEnv == 'prod' || currentEnv == 'production') {
      domain = 'novella.live';
      secure = true;
    }
  } catch (ex) {
    console.error(ex);
  }

  return {
    domain: domain,
    secure: secure,
    path: path,
    expires: expires
  };
}