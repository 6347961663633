import { formatDistanceToNow, format } from "date-fns";

export function getRelativeTimeLabel(isoString?: string) : string {
  if (!isoString) {
    return "";
  }
  const thing = Date.parse(isoString);
  return formatDistanceToNow(thing, { addSuffix: true });
}

export function getAbsoluteFullTimeLabel(isoString?: string) : string {
  if (!isoString) {
    return "";
  }
  const thing = Date.parse(isoString);
  return format(thing, 'PPPp');
}

export function convertNumberSecondsToTimestamp(seconds: number) : string {
  return new Date(seconds * 1000).toISOString().slice(seconds < 3600 ? 14 : 11, 19);
}

export function convertStringSecondsToTimestamp(seconds: string) : string {
  return new Date(Number.parseInt(seconds) * 1000).toISOString().slice(Number.parseInt(seconds) < 3600 ? 14 : 11, 19);
}
