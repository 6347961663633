import { getLocalItem, removeLocalItem, setLocalItem } from '../cache/localForageHelper';

export const setBypass = async (val: boolean): Promise<void> => {
  await setLocalItem('bypass', val);
}

export const getBypass = async (): Promise<boolean | null> => {
  return await getLocalItem('bypass');
}

export const removeBypass = async (): Promise<void> => {
  await removeLocalItem('bypass');
}