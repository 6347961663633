import { Community, CommunityQuestion } from "../../API";
import { LikeMap, ICommunityService } from "../../contracts/ICommunityService";
import { Dictionary } from "../../types/data/Dictionary";

export const compareQuestionSections = (
  a: QuestionSection,
  b: QuestionSection,
  asc: boolean,
  community?: Community): number =>
{
  if (community && community.communityCategories) {
    const al = a.title.toLowerCase();
    const bl = b.title.toLowerCase();
    const ai = community.communityCategories.findIndex(x => x.toLowerCase() == al);
    const bi = community.communityCategories.findIndex(x => x.toLowerCase() == bl);
    if (ai == bi) {
      if (al < bl) {
        return asc ? -1 : 1;
      }
      if (al > bl) {
        return asc ? 1 : -1;
      }
      return 0;
    } else if (ai == -1) {
      return asc ? -1 : 1;
    } else if (bi == -1) {
      return asc ? 1 : -1;
    } else if (ai < bi) {
      return asc ? 1 : -1;
    } else {
      return asc ? -1 : 1;
    }
  } else {
    if (a.title < b.title) {
      return asc ? -1 : 1;
    }
    if (a.title > b.title) {
      return asc ? 1 : -1;
    }
    return 0;
  }
}

export type QuestionSection = {
  title: string
  data: CommunityQuestion[]
}

export const extractQuestionSections = (communityService: ICommunityService, questions: CommunityQuestion[], likeMap: LikeMap, sortParam: string, asc: boolean, community?: Community): QuestionSection[] => {
  const d: Dictionary<QuestionSection> = {};
  questions.forEach(r => {
    let dim = r.communityCategory ?? r.category?.name ?? '-';
    if (sortParam == 'likes') {
      const likedByMe = likeMap[r.id]?.getLiked();
      dim = likedByMe ? 'Liked by me' : 'Not liked by me';
    }
    if (!d[dim]) {
      d[dim] = {
        title: dim,
        data: [],
      };
    }
    d[dim].data.push(r);
  });
  const sectionArray = Object.values(d);
  return sort(communityService, sectionArray, likeMap, sortParam, asc, community);
}

const sort = (communityService: ICommunityService, sections: QuestionSection[], likeMap: LikeMap, by: string, asc: boolean, community?: Community) : QuestionSection[] => {
  sections.forEach(s => {
    s.data.sort((a, b) => {
      return communityService.compareCommunityQuestions(a, likeMap[a.id] ? likeMap[a.id].totalScore() : 0, b, likeMap[b.id] ? likeMap[b.id].totalScore() : 0, by, true);
    });
  })
  return sections.sort((a, b) => {
    return compareQuestionSections(a, b, asc, community);
  });
}