// Taken from: https://www.docs.developers.amplitude.com/data/sdks/typescript-react-native/

import { init, track, identify, Identify, setUserId } from '@amplitude/analytics-react-native';
import { AmplitudeReturn, Result, LogLevel, EventOptions } from '@amplitude/analytics-types';

const API_KEY = 'de39ba0dec6b4fe5dacbbd4935965a67';
const webConfig = { includeUtm: true, includeReferrer: true, logLevel: LogLevel.Error };

export const initAmplitudeAnalytics = (username?: string): void => {
  init(API_KEY, username, webConfig);
};

//export { AmplitudeClient } from 'amplitude-js';

export const logAmplitudeEvent = (eventName: string, eventProps: Record<string, any> | undefined): AmplitudeReturn<Result> =>
  track(eventName, eventProps);

export { setUserId };

export const setUserProperties = (data: any): AmplitudeReturn<Result> => {
  const identifyObj = new Identify();
  Object.entries(data).forEach(v => {
    if (typeof v[1] == 'string') {
      identifyObj.set(v[0], v[1]);
    } else {
      identifyObj.set(v[0], JSON.stringify(v[1]));
    }
  });

  return identify(identifyObj);
}