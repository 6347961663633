// Frameworks
import React, { useContext, useEffect, useState } from 'react';

// Theme
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { Button, ButtonProps } from 'react-native-elements';
import { Palette } from '../../Theme';
import { View } from 'react-native';
import { MAX_WIDTH, useSafeAreaInsets } from '../../business/layout/layout';
import { normalizeHeight } from '../../business/layout/responseSize';

export enum FABPosition {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  CENTER = "CENTER"
}

function NFAB(props: { position?: FABPosition, expand?: boolean, width?: number, height?: number, paddingHorizontal?: number, paddingVertical?: number, hidden?: boolean } & ButtonProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const paddingHorizontal = props.paddingHorizontal ?? 20;
  const paddingVertical = props.paddingVertical ?? 20;
  const buttonHeight = props.height ?? 60;
  const initialWidth = props.width ?? 60;
  const [buttonWidth, setButtonWidth] = useState(initialWidth);
  const [bottomMargin, setBottomMargin] = useState(0);
  const forceDimensions = !props.title && !props.expand;
  const { height } = commonContext.dimensions;
  const { bottom } = useSafeAreaInsets();

  const position = props.position ?? FABPosition.CENTER;
  const hidden = props.hidden;
  const disabled = hidden || props.disabled;
  const gap = 10;

  useEffect(() => {
    setBottomMargin(Math.max(normalizeHeight(50, height), 50) + bottom + gap);
  }, [height, bottom]);

  return (
    <View
      style={[
        {
          alignSelf: 'center',
          position: 'absolute',
          bottom: 0,
          marginBottom: bottomMargin,
          //marginHorizontal: 24,
          width: props.expand ? '100%' : 'auto',
          maxWidth: MAX_WIDTH,
          zIndex: 30,
          filter: 'drop-shadow(1px 4px 8px rgba(50, 50, 0, 0.2))',
        },
        (position == FABPosition.CENTER) ? {}
          : (position == FABPosition.RIGHT) ? { right: 10 }
          : { left: 10 },
        props.style,
      ]}
    >
      <Button
        {...props}
        style={{
          display: hidden ? 'none' : 'flex',
        }}
        containerStyle={[
          props.containerStyle,
          
        ]}
        buttonStyle={[
          {
            borderRadius: 100,
            backgroundColor: themeFromContext.colors.primary,
            paddingHorizontal: forceDimensions ? 0 : paddingHorizontal,
            paddingVertical: forceDimensions ? 0 : paddingVertical,
          },
          forceDimensions ? { width: buttonWidth, height: buttonHeight } : {},
          props.buttonStyle,
        ]}
        titleStyle={[
          {
            //...themeFromContext.buttons[props.t].titleStyle
          },
          props.titleStyle,
        ]}
        disabledStyle={{
          //...themeFromContext.buttons[props.t].buttonStyle,
          borderColor: hidden ? themeFromContext.colors.transparent : Palette.grey,
          backgroundColor: hidden ? themeFromContext.colors.transparent : Palette.lightgrey,
        }}
        disabledTitleStyle={{
          //...themeFromContext.buttons[props.t].titleStyle,
          color: hidden ? themeFromContext.colors.transparent : Palette.grey,
        }}
        loadingStyle={{
          borderColor: Palette.grey,
          backgroundColor: Palette.lightgrey,
        }}

        // disabledStyle={[
        //   {
        //     borderColor: hidden ? themeFromContext.colors.transparent : Palette.lightgrey,
        //     backgroundColor: hidden ? themeFromContext.colors.transparent : Palette.grey,
        //   },
        //   props.disabledStyle,
        // ]}
        // disabledTitleStyle={{
        //   //...themeFromContext.buttons[props.t].titleStyle,
        //   color: hidden ? themeFromContext.colors.transparent : themeFromContext.colors.background,
        // }}
        // we'll override disabled if they also specified hidden == true
        disabled={disabled}
      />
    </View>
  );
}
export default NFAB;