import { number } from "prop-types";
import LocalForageWrapper from "../storage/localForageWrapper";

export type ReadThroughCacheInput = {
  key: string,
  ttl?: number,
  ifNotFound: () => Promise<any>,
}

export async function readThroughCache(input: ReadThroughCacheInput) : Promise<any>
{
  const { key, ttl, ifNotFound } = input;
  let item = LocalForageWrapper.getItem(key);
  if (!item || isExpired(item)) {
    const fromSource = await ifNotFound();

    if (fromSource) {
      item = withExpiration(fromSource, ttl);
      LocalForageWrapper.setItem(key, item);
    }
  } else {
    //log.info(`got cached item for key: ${key}`);
    //log.info(item);
  }
  return item.value;
}

export function invalidateItem(key: string) : void {
  LocalForageWrapper.removeItem(key);
}

function isExpired(item: any)
{
  if (item && item != null) {
    if (!item.expireAt) {
      return true; // assume expired unless told otherwise
    } else {
      return (item.expireAt < Date.now());
    }
  }
}

function withExpiration(inputItem: any, ttl?: number)
{
  return {
    value: inputItem,
    expireAt: Date.now() + (ttl ?? 200 * 365 * 24 * 60 * 60 * 1000),
  };
}