import React, { useContext } from 'react'
import { Text, View } from 'react-native';
import { Button, ButtonProps } from 'react-native-elements';
import CommonContext from '../../../CommonContext';
import { CommonContextType } from '../../../types/CommonContextType';

function AuthDivider(props: { text?: string }): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const hasText = props.text && props.text.length > 0;
  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <View
        style={{
          borderTopColor: themeFromContext.colors.secondary,
          borderTopWidth: 1,
          height: hasText ? 10 : 2,
          flex: 1,
          width: '100%',
          marginVertical: 10,
          marginHorizontal: hasText ? 10 : 0,
        }}
      ></View>
      <Text
        style={{
          ...themeFromContext.textVariants.detail,
          color: themeFromContext.colors.secondary,
          marginTop: 10,
          marginBottom: 15,
          textAlign: 'center',
        }}
      >
        {props.text}
      </Text>
      <View
        style={{
          borderTopColor: themeFromContext.colors.secondary,
          borderTopWidth: 1,
          height: hasText ? 10 : 2,
          flex: 1,
          width: '100%',
          marginVertical: 10,
          marginHorizontal: hasText ? 10 : 0,
        }}
      ></View>
    </View>
  )
}

export default AuthDivider