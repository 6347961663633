import { CommunityInterviewRequest, CommunityInterviewStatus, CommunityInterviewRequestCommand } from "../API";
import * as q from '../graphql/queries';
import * as m from '../graphql/mutations';
import { IFullListQuerier } from "../business/graphql/FullListQuerier";
import { CommandOperation } from "../API";
import { IUserService } from "../contracts/IUserService";
import { IAPIWrapper } from "../business/graphql/APIWrapper";
import { IInterviewRequestService } from "../contracts/IInterviewRequestService";

export class InterviewRequestService implements IInterviewRequestService {
  flq: IFullListQuerier;
  api: IAPIWrapper;
  userService: IUserService;
  constructor(flq: IFullListQuerier, api: IAPIWrapper, userService: IUserService) {
    this.flq = flq;
    this.api = api;
    this.userService = userService;
  }
  compareCommunityInterviewRequests(a: CommunityInterviewRequest, b: CommunityInterviewRequest, asc: boolean) : number
  {
    if (a.updatedAt && b.updatedAt) {
      return asc ?
          a.updatedAt?.localeCompare(b.updatedAt) :
          -1 * a.updatedAt?.localeCompare(b.updatedAt);
      }
    else {
      return 0;
    }
  }
  async getInterviewRequests(communityId: string) : Promise<CommunityInterviewRequest[]>
  {
    const variables = {
      filter: {
        communityID: {
          eq: communityId
        }
      }
    };
    const list = await this.flq.queryFullList('listCommunityInterviewRequests', q.listCommunityInterviewRequests, variables);
    return list;
  }
  async createInterviewRequestCommand(communityId: string, subject: string, body: string, dateTime?: Date) : Promise<CommunityInterviewRequestCommand>
  {
    const loggedInUser = await this.userService.getLoggedInUser();
    const createResult: any = await this.api.graphql({
      query: m.createCommunityInterviewRequestCommand,
      variables: {
        input: {
          command: {
            operation: CommandOperation.CREATE
          },
          communityID: communityId,
          userID: loggedInUser.id,
          date: dateTime ? dateTime.toISOString() : null,
          status: CommunityInterviewStatus.REQUESTED,
          message: {
            subject: subject,
            body: body,
          }
        }
      }
    });
    return createResult.data.createCommunityInterviewRequestCommand;
  }
}