import React, { useContext } from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import CommonContext from '../../../../CommonContext';
import { CommonContextType } from '../../../../types/CommonContextType';
import { RootStackParamList } from '../../../../types/Navigation';
import { DrawerScreenProps } from '@react-navigation/drawer';
import { normalizeWidth } from '../../../../business/layout/responseSize';
import NText from '../../../components/NText';
import { Palette } from '../../../../Theme';
import { ITextVariant } from '../../../../types/Theme';

export type PreviewProps = DrawerScreenProps<RootStackParamList, 'Community Preview'>;

function Panel(props: { title: string, light?: boolean, styleOverride?: StyleProp<ViewStyle>, flex?: number, titleTextVariantOverride?: ITextVariant, children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined }): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { width } = commonContext.dimensions;

  return (
    <View
      style={[
        {
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          backgroundColor: 'rgb(250,250,250)',
          borderWidth: 1,
          borderColor: Palette.grey,
          justifyContent: 'space-between',
          //marginRight: width < TABLET_BREAK ? 0 : normalizeWidth(10, width),
          marginTop: normalizeWidth(10, width),
          borderRadius: 15,
          overflow: 'hidden',
        },
        props.flex ? { flex: props.flex } : {},
        //props?.styleOverride
      ]}
    >
      <NText
        style={[
          props.titleTextVariantOverride ?? themeFromContext.textVariants.previewHeader,
          {
            textAlign: 'center',
            maxWidth: 1000,
            width: '100%',
            backgroundColor: props.light ? 'rgb(250,250,250)' : Palette.purple,
            color: props.light ? Palette.purple : 'white',
            marginBottom: 4,
            marginTop: props.light ? 20 : 0,
          }
        ]}
        testID={`panel-title`}
      >
        {props.title}
      </NText>
      <View
        style={[
          {
            padding: normalizeWidth(10, width),
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          },
          props.styleOverride,
        ]}
      >
        {props.children}
      </View>
    </View>
  );
}

export default Panel;