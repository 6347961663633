/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getCurrentEnv } from "../env/currentEnv";
import * as Application from 'expo-application';
const util = require("util");

const env = getCurrentEnv();
const version = Application.nativeApplicationVersion != null ? Application.nativeApplicationVersion : "0";
const build = Application.nativeBuildVersion != null ? Application.nativeBuildVersion : "0";
const MYSTORAGE_KEY_PREFIX = `@LocalForageWrapper:${env}:${version}:${build}:`;
let dataMemory = {};

/** @class */
export default class LocalForageWrapper {
  static syncPromise = null;
  /**
   * This is used to set a specific item in storage
   */
  static setItem(key, value) {
    // if (key.startsWith("ntum")) {
    //   // console.log(`local forage wrapper 1`);
    //   // console.log(util.inspect(value, true, 12, true));
    //   // console.log(`local forage wrapper 1.5`);

    //   if (value == {} || value == null || value == undefined) {
    //     console.log(
    //       `why the hell are you clearing the uploads??????? should never ever happen!`
    //     );
    //   }
    // }

    // const getCircularReplacer = () => {
    //   const seen = new WeakSet();
    //   return (key, value) => {
    //     if (typeof value === "object" && value !== null) {
    //       if (seen.has(value)) {
    //         if (!Object.keys(value).includes("uri")) {
    //           console.log(`already seen:`);
    //           console.log(value);
    //           return;
    //         }
    //       }
    //       seen.add(value);
    //     }
    //     return value;
    //   };
    // };

    AsyncStorage.setItem(MYSTORAGE_KEY_PREFIX + key, JSON.stringify(value));
    // if (key.startsWith("ntum")) {
    //   ///const test = AsyncStorage.getItem(MYSTORAGE_KEY_PREFIX + key);
    //   // console.log(
    //   //   `XXXXX: tried  blah putting item into Async storage and got this back:`
    //   // );
    //   // console.log(JSON.parse(test));
    //   console.log(`local forage wrapper 2`);
    // }
    dataMemory[key] = value;
    return dataMemory[key];
  }

  /**
   * This is used to get a specific key from storage
   */
  static getItem(key) {
    //console.log(`LocalForageWrapper.getItem 1 - ${key}`);
    const value = Object.prototype.hasOwnProperty.call(dataMemory, key)
      ? dataMemory[key]
      : undefined;
    //console.log(`LocalForageWrapper.getItem 2 - ${key}`);
    return value;
  }

  /**
   * This is used to remove an item from storage
   */
  static removeItem(key) {
    if (key.startsWith("ntum")) {
      console.error(`remove upload cache should never ever happen!`);
    }
    AsyncStorage.removeItem(MYSTORAGE_KEY_PREFIX + key);
    return delete dataMemory[key];
  }

  /**
   * This is used to clear the storage
   */
  static clear() {
    console.error(`clearing upload cache should never ever happen!`);
    dataMemory = {};
    return dataMemory;
  }

  /**
   * Will sync the LocalForageWrapper data from AsyncStorage to storageWindow LocalForageWrapper
   */
  static sync() {
    if (!LocalForageWrapper.syncPromise) {
      LocalForageWrapper.syncPromise = new Promise((res, rej) => {
        AsyncStorage.getAllKeys().then((keys) => {
          AsyncStorage.multiGet(keys)
            .then((values) => {
              values.forEach((item) => {
                if (item[0].startsWith(MYSTORAGE_KEY_PREFIX)) {
                  const memoryKey = item[0].replace(MYSTORAGE_KEY_PREFIX, "");
                  dataMemory[memoryKey] = JSON.parse(item[1]);
                  // console.log(`XXXXX: got from async storage: ${memoryKey}`);
                  // console.log(dataMemory[memoryKey]);
                }
              });
            })
            .then(() => {
              res("SUCCESS");
            });
        });
      });
    }
    return LocalForageWrapper.syncPromise;
  }
}
