import AsyncStorage from "@react-native-async-storage/async-storage";
import log from "../logging/logger";

export async function setLocalItem<T>(key: string, value: T): Promise<void> {
  await AsyncStorage.setItem(key, JSON.stringify(value), (err: any) => {
    if (err) {
      log.info(`Error trying to set item to Local Forage:`);
      log.info(err);
    }
  });
}

export async function getLocalItem<T>(key: string): Promise<T | null> {
  const thing: string | null = await AsyncStorage.getItem(key);
  if (thing != null) {
    return JSON.parse(thing) as T;
  }
  return null;
}

export async function removeLocalItem(key: string): Promise<void> {
  return await AsyncStorage.removeItem(key);
}