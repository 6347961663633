import React, { useContext } from 'react'
import { View } from 'react-native'
import { RootStackParamList } from '../../../../types/Navigation';
import { CommonContextType } from '../../../../types/CommonContextType';
import CommonContext from '../../../../CommonContext';
import { Palette } from '../../../../Theme';
import { DrawerScreenProps } from '@react-navigation/drawer';
import SignIn2Inner from './SignIn2Inner';

export type SignIn2Props = DrawerScreenProps<RootStackParamList, 'Sign In'>;

function SignIn2(props: SignIn2Props): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);

  return (
    <>
      <View
        style={{
          top: 0,
          left: 0,
          position: 'absolute',
          display: 'flex',
          flex: 1,
          width: commonContext.dimensions.width,
          height: '100%',
          backgroundColor: Palette.plum,
        }}
      >
      </View>
      <SignIn2Inner {...props} />
    </>
  )
}

export default SignIn2;
