// Frameworks
import React, { useContext, useState } from 'react';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { Icon } from 'react-native-elements';
import { Menu } from 'react-native-paper';
import { Palette } from '../../Theme';
import { ButtonMenu } from './ButtonMenu';
import { flagContent } from '../../business/supportRequests/flagContent';
import PromptModal from './Modals/PromptModal';
import { obfuscateEmail } from '../../business/supportRequests/obfuscateEmail';
import { ActivityIndicator, Alert, Linking, Platform } from 'react-native';
import * as FileSystem from 'expo-file-system';
import { DOWNLOAD_CACHE_STORAGE_DIRECTORY } from '../../constants/directories';
import { v4 as uuid } from 'uuid';
import * as MediaLibrary from 'expo-media-library';

interface Props<X> {
  vertical?: boolean,
  canEdit: boolean,
  canDelete: boolean,
  canExport?: boolean,
  onEdit: () => void,
  onDelete: () => void,
  onExport?: () => void,
  exportLink?: string,
  exportQuality?: string,
  getItemToFlag: () => X,
  testID?: string,
  loading?: boolean
}

interface FlaggableItem {
  id: string,
}

function ItemEditButtonMenu<T extends FlaggableItem>(props: Props<T>): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  // const {top} = useSafeAreaInsets();
  // const visible = props.canDelete || props.canEdit;

  //const [menuAnchor, setMenuAnchor] = useState({x: 0, y: 0});
  const [menuOpen, setMenuOpen] = useState(false);
  const [showFlagModal, setShowFlagModal] = useState(false);
  const [flagging, setFlagging] = useState(false);
  const [showFlagConfirmation, setShowFlagConfirmation] = useState(false);
  const [isExporting, setIsExporting] = useState(false);

  const pressEdit = () => {
    setMenuOpen(false);
    props.onEdit();
  };

  const pressDelete = () => {
    setMenuOpen(false);
    props.onDelete();
  };

  const pressExport = async () => {
    setIsExporting(true);
    if (props.exportLink) {
      if (Platform.OS === 'web') {
        Linking.openURL(props.exportLink);
      } else {
        try {
          const tempFileName = uuid();
          const path = DOWNLOAD_CACHE_STORAGE_DIRECTORY + `${tempFileName}.mp4`;
          // Download the video using FileSystem

          console.log(`dling: ${props.exportLink}`);

          const downloadResumable = FileSystem.createDownloadResumable(
            props.exportLink,
            path // Save the video in the Document Directory with a specific name
          );

          console.log(`dling: ${path}`);
    
          const result = await downloadResumable.downloadAsync();

          console.log(`downloaded: ${result?.uri}`);

          let uri = '';
          if (result) {
            uri = result.uri;
          }
          if (uri.length > 0) {
            // Save the video to the device's photos library using MediaLibrary
            const asset = await MediaLibrary.createAssetAsync(uri);

            console.log(`created asset: ${asset?.uri}`);

            FileSystem.deleteAsync(uri)
            .then(() => {
              console.log(`deleted file: ${uri}`);
            })
            .catch(ex => {
              console.log(`error deleting file: ${uri}`);
              console.error(ex);
            });


          } else {
            console.error(`'There was an error saving the video to your device: ${props.exportLink}`);
            Alert.alert('Error', 'There was an error saving the video to your device.');
          }
    
         
        } catch (error) {
          console.error('Error saving video:', error);
        }
        setIsExporting(false);
        setMenuOpen(false);
      }
    }
  }

  const pressFlag = () => {
    setShowFlagModal(true);
  }

  const cancelFlag = async () => {
    setShowFlagModal(false);
  }

  const flagItem = async (): Promise<void> => {
    const item: T = props.getItemToFlag();
    if (item) {
      if (item.id.length > 0) {
        setFlagging(true)
        await flagContent(item.id, Object.keys(item).join(','), `loggedInUser: ${obfuscateEmail(commonContext.loggedInUser?.email)} --- ${commonContext.loggedInUser?.id}`);
        setFlagging(false);
        setShowFlagModal(false);
        setMenuOpen(false);
        setTimeout(() => {
          setShowFlagConfirmation(true);  
        }, 700);
      }
    }
  }

  const pressConfirm = () => {
    setShowFlagConfirmation(false);
  }

  return (
    <>
        <ButtonMenu vertical={props.vertical} open={menuOpen} setOpen={setMenuOpen}>
          {
            props.canExport &&
            <Menu.Item
              icon={() => (
                !isExporting ?
                  <Icon
                    size={themeFromContext.textVariants.header.fontSize}
                    name='cloud-download-alt'
                    type='font-awesome-5'
                    solid
                    color={themeFromContext.colors.background}
                    style={{
                      alignItems: 'flex-start',
                    }}
                  />
                  :
                  <ActivityIndicator
                    size={'small'}
                    color={themeFromContext.colors.background}
                    style={{
                      alignItems: 'flex-start',
                    }}
                  />)}
              title={(props.exportQuality != undefined && props.exportQuality.length > 0) ? `Download (${props.exportQuality})` : 'Download'}
              titleStyle={{
                fontFamily: themeFromContext.textVariants.body.fontFamily,
                fontSize: themeFromContext.textVariants.body.fontSize,
                color: themeFromContext.colors.background
              }}
              style={{
                backgroundColor: Palette.spinach,
                borderTopLeftRadius: 25,
                borderTopRightRadius: 25,
                paddingHorizontal: 20,
              }}
              onPress={pressExport}
              testID={`${props.testID}-export`}
            />
          }
          {
            props.canEdit &&
            <Menu.Item
              icon={() => (
                <Icon
                  size={themeFromContext.textVariants.header.fontSize}
                  name='edit'
                  type='font-awesome-5'
                  solid
                  color={themeFromContext.colors.background}
                  style={{
                    alignItems: 'flex-start',
                  }}
                />)}
              title='Edit'
              titleStyle={{
                fontFamily: themeFromContext.textVariants.body.fontFamily,
                fontSize: themeFromContext.textVariants.body.fontSize,
                color: themeFromContext.colors.background
              }}
              style={{
                backgroundColor: Palette.yellow,
                borderTopLeftRadius: props.canExport ? 0 : 25,
                borderTopRightRadius: props.canExport ? 0 : 25,
                paddingHorizontal: 20,
              }}
              onPress={pressEdit}
              testID={`${props.testID}-edit`}
            />
          }
          {
            props.canDelete &&
            <Menu.Item
              icon={() => (
                <Icon
                  size={themeFromContext.textVariants.header.fontSize}
                  name='trash-alt'
                  type='font-awesome-5'
                  solid
                  color={themeFromContext.colors.background}
                  style={{
                    alignItems: 'flex-start',
                  }}
                />)}
              title='Delete'
              titleStyle={{
                fontFamily: themeFromContext.textVariants.body.fontFamily,
                fontSize: themeFromContext.textVariants.body.fontSize,
                color: themeFromContext.colors.background
              }}
              style={[
                {
                  backgroundColor: themeFromContext.colors.danger,
                  paddingHorizontal: 20,
                },
                props.canEdit ? {} : {
                  borderTopLeftRadius: 25,
                  borderTopRightRadius: 25,
                }
              ]}
              onPress={pressDelete}
              testID={`${props.testID}-delete`}
            />
          }
          <Menu.Item
            icon={() => (
              <Icon
                size={themeFromContext.textVariants.header.fontSize}
                name='flag'
                type='font-awesome-5'
                solid
                color={themeFromContext.colors.background}
                style={{
                  alignItems: 'flex-start',
                }}
              />)}
            title='Report as inappropriate'
            titleStyle={{
              fontFamily: themeFromContext.textVariants.body.fontFamily,
              fontSize: themeFromContext.textVariants.body.fontSize,
              color: themeFromContext.colors.background
            }}
            style={[
              {
                backgroundColor: Palette.purple,
                paddingHorizontal: 20,
              },
              props.canEdit || props.canDelete ? {} : {
                borderTopLeftRadius: 25,
                borderTopRightRadius: 25,
              }
            ]}
            onPress={pressFlag}
            testID={`${props.testID}-flag`}
          />
        </ButtonMenu>
        <PromptModal
            show={showFlagModal}
            heading={`Report as inappropriate?`}
            prompt={`Are you sure you want to report this content? If you do, we will review it within 24 hours and take appropriate action.`}
            confirm={flagItem}
            confirmButtonText={`Report it`}
            //backdropHeight?: number,
            deny={cancelFlag}
            denyButtonText={`Cancel`}
            dismiss={cancelFlag}
            working={flagging}
            testID={`flag-modal`}
        >
        </PromptModal>
        <PromptModal
            show={showFlagConfirmation}
            heading={`Success`}
            prompt={`Thank you for reporting this and bringing it to our attention.`}
            confirm={pressConfirm}
            confirmButtonText={`Done`}
            testID={`flag-confirmation-modal`}
        >
        </PromptModal>
    </>
  );
}
export default ItemEditButtonMenu;