import { Dictionary } from "../../types/data/Dictionary";
import LocalForageWrapper from "../storage/localForageWrapper";
import { TUSUpload, UploadStatus } from "./TUSUpload";
import { AnswerModel } from "./AnswerModelManager";
import { IUploadListener } from "./UploadListenerLibrary";
import * as FileSystem from 'expo-file-system';
import { UPLOAD_STORAGE_DIRECTORY, UPLOAD_SUBDIRECTORY } from "../../constants/directories";
import { Platform } from "react-native";

interface HasId {
  id: string;
}

export interface IUploadManager<T extends HasId, U> {
  wakeUpInProgressUploads(listeners: IUploadListener<U>[]): Promise<void>;
  //canStart(id: string): boolean;
  startNext(): Promise<void>;
  upsert(item: T): Promise<void>;
  canRemove(id: string): boolean;
  remove(id: string): Promise<void>;
  canCancel(id: string): boolean;
  cancel(id: string): Promise<void>;
  canDeleteData(item: T): boolean;
  deleteData(item: T): Promise<void>;
  canRestart(is: string): boolean;
  restart(id: string): Promise<void>;
  canPause(id: string): boolean;
  pause(id: string): Promise<void>;
  // pause(id: string): Promise<void>;
  // resume(id: string): Promise<void>;
  get(id: string): T | undefined;
  getAll(): Dictionary<T>;
  getMatching(propertyName: string, propertyValue: string): T[];
}

// SINGLETON
const UPLOAD_CACHE_KEY = `ntum`;
export const AnswerUploadManager: IUploadManager<AnswerModel, TUSUpload> = (function () {

  // function privateMethod() {
  //   // ...
  // }

  // paths for local files have structure like:
  // file:///var/mobile/Containers/Data/Application/38527BB8-775F-49D3-B4CE-01DB0F7CE25A/Documents/uploads/filename.extension
  function recreatePath(oldPath: string): string {
    //console.log(`old path: ${oldPath}`);
    const filename = oldPath.substring(oldPath.indexOf(UPLOAD_SUBDIRECTORY) + UPLOAD_SUBDIRECTORY.length);

    //console.log(`filename: ${filename}`);
    const newPath = `${UPLOAD_STORAGE_DIRECTORY}${filename}`;

    //console.log(`new path: ${newPath}`);
    return newPath;
  }

  function getAll(): Dictionary<AnswerModel> {
    try {
      const thing: Dictionary<AnswerModel> = LocalForageWrapper.getItem(UPLOAD_CACHE_KEY);
      if (thing != undefined && thing != null) {

        //here we can update any file paths, in case the application's root director has changed?
        if (Platform.OS == "ios") {
          Object.entries(thing).forEach(e => {
            const oldPath: string = e[1].tusUpload.item.uri;
            const newPath = recreatePath(oldPath);
            e[1].tusUpload.item = { uri: newPath };
          });
        }

        return thing;
      }
    } catch (ex) {
      console.log(`whatever`);
    }
    return {};
  }

  async function startNextInner() : Promise<void> {
    const inMemoryUploadProcesses = getAll();
      let item: AnswerModel | undefined;
      let inProgress: AnswerModel | undefined;
      console.log(`ALL UPLOADS:`);
      Object.values(inMemoryUploadProcesses).forEach(x => {
        console.log(`title: ${x.title}\tid: ${x.id}\tstatus: ${x.tusUpload.status}\t committed: ${x.committed}`);
      });
      Object.values(inMemoryUploadProcesses).forEach(v => {
        if (v.tusUpload.status == UploadStatus.IN_PROGRESS) {
          inProgress = v;
        } else {
          if (v.tusUpload.status == UploadStatus.READY) {
            if (!item) {
              item = v;
            } else {
              if (v.created < item.created) {
                item = v;
              }
            }
          }
        }
      });
      if (inProgress) {
        if (typeof inProgress.tusUpload.startOrResume === "undefined") {
          // if the in-progress upload doesn't have defined functions, it was just pulled out of cold storage (async storage) and needs to be rehydrated AND RESTARTED
          console.log(`rehydrating in-progress upload!`);
          inProgress.tusUpload = TUSUpload.rehydrate(inProgress.tusUpload)
          console.log(`calling startOrResume on redydrated in-progress upload!`);
          if (typeof inProgress.tusUpload.startOrResume !== "undefined") {
            await inProgress.tusUpload.startOrResume();
          }
          inMemoryUploadProcesses[inProgress.id] = inProgress;
          console.log(`MODIFIED UPLOADS:`);
          Object.values(inMemoryUploadProcesses).forEach(x => {
            console.log(`title: ${x.title}\tid: ${x.id}\tstatus: ${x.tusUpload.status}\t committed: ${x.committed}`);
          })
          LocalForageWrapper.setItem(UPLOAD_CACHE_KEY, inMemoryUploadProcesses);
        }
      } else {
        // if there is no in-progress upload
        if (item) { // if there is a "ready" upload
          if (typeof item.tusUpload.startOrResume === "undefined") { // if the "ready" upload doesn't have defined functions, it was just pulled out of cold storage (async storage) and needs to be rehydrated BEFORE IT IS STARTED
            console.log(`rehydrating ready upload!`);
            item.tusUpload = TUSUpload.rehydrate(item.tusUpload)
          }
          console.log(`starting ready upload with id: ${item.id}`);
          if (typeof item.tusUpload.startOrResume !== "undefined") {
            await item.tusUpload.startOrResume();
          }
          inMemoryUploadProcesses[item.id] = item;
          console.log(`MODIFIED UPLOADS:`);
          Object.values(inMemoryUploadProcesses).forEach(x => {
            console.log(`title: ${x.title}\tid: ${x.id}\tstatus: ${x.tusUpload.status}\t committed: ${x.committed}`);
          })
          LocalForageWrapper.setItem(UPLOAD_CACHE_KEY, inMemoryUploadProcesses);
        }
      }
    
  }
  async function removeInner(id: string) {
    console.log(`will remove`);
    let inMemoryUploadProcesses = getAll();
    let changed = false;
    const mod: Dictionary<AnswerModel> = {};
    Object.entries(inMemoryUploadProcesses).forEach(u => {
      if (u[0] != id) {
        mod[u[0]] = u[1];
      } else {
        changed = true;
      }
    });
    if (changed) {
      inMemoryUploadProcesses = mod;
      LocalForageWrapper.setItem(UPLOAD_CACHE_KEY, inMemoryUploadProcesses);
      await startNextInner();
    }
  }

  return { // public interface

    async wakeUpInProgressUploads(): Promise<void> {
      try {
        await (LocalForageWrapper.sync() as unknown as Promise<string>);//.then((result) => {
        await this.startNext();
      } catch (ex) {
        console.error(`Failed to resume in-progress uploads`);
        console.error(ex);
      }
    },

    // canStart(id: string): boolean {
    //   const inMemoryUploadProcesses = getAll();
    //   const item = inMemoryUploadProcesses[id];
    //   Object.values(inMemoryUploadProcesses).forEach(v => {
    //     if (v.tusUpload.status == UploadStatus.IN_PROGRESS) {
    //       return false;
    //     } else {
    //       if (v.tusUpload.status == UploadStatus.READY && v.created < item.created) {
    //         return false;
    //       }
    //     }
    //   });
    //  return true;
    // },

    async startNext(): Promise<void> {
      await startNextInner();
    },

    async upsert(item: AnswerModel): Promise<void> {
      const inMemoryUploadProcesses = getAll();
      if (!inMemoryUploadProcesses[item.id]) {
        item.tusUpload.status = UploadStatus.READY;
      }
      inMemoryUploadProcesses[item.id] = item;
      LocalForageWrapper.setItem(UPLOAD_CACHE_KEY, inMemoryUploadProcesses);
      console.log(`UPSERTED UPLOADS:`);
      Object.values(inMemoryUploadProcesses).forEach(x => {
        console.log(`title: ${x.title}\tid: ${x.id}\tstatus: ${x.tusUpload.status}\t committed: ${x.committed}`);
      });
      await this.startNext();
    },

    canRemove(id: string): boolean {
      const inMemoryUploadProcesses = getAll();
      const item: AnswerModel | undefined = inMemoryUploadProcesses[id];
      if (item) {
        return (item.tusUpload.status == UploadStatus.CANCELED ||
                item.tusUpload.status == UploadStatus.COMPLETED );
      }
      return false;
    },

    async remove(id: string): Promise<void> {
      console.log(`pressed remove`);
      if (this.canRemove(id)) {
        await removeInner(id);
      }
    },

    // "cancel" means stop upload forever and delete any cached data
    canCancel(id: string): boolean {
      // if (Platform.OS == 'web') {
      //   return false;
      // }
      const inMemoryUploadProcesses = getAll();
      const item: AnswerModel | undefined = inMemoryUploadProcesses[id];
      if (item) {
        return (item.tusUpload.status != UploadStatus.CANCELED && item.tusUpload.status != UploadStatus.COMPLETED);
      }
      return false;
    },
    
    async cancel(id: string): Promise<void> {
      //console.log(`can I cancel upload with id: ${id}?`)
      if (this.canCancel(id)) {
        //console.log(`YES`);
        //console.log(`trying to cancel upload with id: ${id}`);
        const inMemoryUploadProcesses = getAll();
        // Object.values(inMemoryUploadProcesses).forEach(x => {
        //   console.log(`id: ${x.id}\tstatus: ${x.tusUpload.status}`);
        // })
        const item: AnswerModel | undefined = inMemoryUploadProcesses[id];
        if (item) {
          //console.log(`calling cancel on TUSUpload`);
          if (typeof item.tusUpload.cancel !== "undefined") {
            await item.tusUpload.cancel();
            await this.deleteData(item);
            inMemoryUploadProcesses[item.id] = item;
            // Object.values(inMemoryUploadProcesses).forEach(x => {
            //   console.log(`id: ${x.id}\tstatus: ${x.tusUpload.status}`);
            // })
            LocalForageWrapper.setItem(UPLOAD_CACHE_KEY, inMemoryUploadProcesses);
            await this.startNext();
          } else {
            await removeInner(item.id);
          }
          
        }
      } else {
        console.log(`NO`);
      }
    },

    canRestart(id: string): boolean {
      const inMemoryUploadProcesses = getAll();
      const item: AnswerModel | undefined = inMemoryUploadProcesses[id];
      if (item) {
        return (item.tusUpload.status == UploadStatus.PAUSED ||
                item.tusUpload.status == UploadStatus.FAILED );
      }
      return false;
    },

    async restart(id: string): Promise<void> {
      if (this.canRestart(id)) {
        const inMemoryUploadProcesses = getAll();
        const item: AnswerModel | undefined = inMemoryUploadProcesses[id];
        if (item) {
          console.log(`restart 1`);
          // always do this when restarting?
          item.tusUpload = TUSUpload.rehydrate(item.tusUpload);
          if (typeof item.tusUpload.reset !== "undefined") {
            await item.tusUpload.reset();
            console.log(`restart 2`);
            inMemoryUploadProcesses[id] = item;
            LocalForageWrapper.setItem(UPLOAD_CACHE_KEY, inMemoryUploadProcesses);
            console.log(`restart 3`);
            await this.startNext();
            console.log(`restart 4`);
          } else {
            await removeInner(item.id);
          }
          
        }
      }
    },

    canDeleteData(item: AnswerModel): boolean {
      if (item) {
        return (item.tusUpload.status == UploadStatus.CANCELED || item.tusUpload.status == UploadStatus.COMPLETED);
      }
      return false;
    },

    async deleteData(item: AnswerModel): Promise<void> {
      if (this.canDeleteData(item)) {
        if (item && Platform.OS != 'web') {
          console.log(`preparing to delete file at: ${item.tusUpload.item.uri}`);
          try{
            await FileSystem.deleteAsync(item.tusUpload.item.uri);
            console.log(`deleted: ${item.tusUpload.item.uri}`);
          } catch (e) {
            console.log(`error deleting file at: ${item.tusUpload.item.uri}`)
            console.log(e);
          }
        }
      }
    },

    canPause(id: string): boolean {
      const inMemoryUploadProcesses = getAll();
      const item: AnswerModel | undefined = inMemoryUploadProcesses[id];
      if (item) {
        return (item.tusUpload.status == UploadStatus.IN_PROGRESS || item.tusUpload.status == UploadStatus.READY);
      }
      console.log(false);
      return false;
    },
    
    async pause(id: string): Promise<void> {
      if (this.canPause(id)) {
        const inMemoryUploadProcesses = getAll();
        Object.values(inMemoryUploadProcesses).forEach(x => {
          console.log(`title: ${x.title}\tid: ${x.id}\tstatus: ${x.tusUpload.status}\t committed: ${x.committed}`);
        })
        const item: AnswerModel | undefined = inMemoryUploadProcesses[id];
        if (item) {
          if (typeof item.tusUpload.pause === "undefined") {
            console.log(`stop - rehydrate`);
            item.tusUpload = TUSUpload.rehydrate(item.tusUpload);
          }
          if (typeof item.tusUpload.pause !== "undefined") {
            await item.tusUpload.pause();
            inMemoryUploadProcesses[item.id] = item;
            Object.values(inMemoryUploadProcesses).forEach(x => {
              console.log(`title: ${x.title}\tid: ${x.id}\tstatus: ${x.tusUpload.status}\t committed: ${x.committed}`);
            })
            LocalForageWrapper.setItem(UPLOAD_CACHE_KEY, inMemoryUploadProcesses);
            await this.startNext();
          } else {
            await removeInner(item.id);
          }
          
        }
      }
    },

    // async pause(id: string): Promise<void> {
    //   const inMemoryUploadProcesses = getAll();
    //   const item: AnswerModel | undefined = inMemoryUploadProcesses[id];
    //   if (item) {
    //     await item.tusUpload.pause();
    //     LocalForageWrapper.setItem(UPLOAD_CACHE_KEY, inMemoryUploadProcesses);
    //     await startNext();
    //   }
    // },

    // async resume(id: string): Promise<void> {
    //   const inMemoryUploadProcesses = getAll();
    //   const item: AnswerModel | undefined = inMemoryUploadProcesses[id];
    //   if (item) {
    //     await item.tusUpload.startOrResume();
    //     LocalForageWrapper.setItem(UPLOAD_CACHE_KEY, inMemoryUploadProcesses);
    //   }
    // },

    get(id: string): AnswerModel | undefined {
      const inMemoryUploadProcesses = getAll();
      return inMemoryUploadProcesses[id];
    },

    getAll(): Dictionary<AnswerModel> {
      return getAll();
    },

    getMatching(propertyName: string, propertyValue: string): AnswerModel[] {
      const inMemoryUploadProcesses = getAll();
      return Object.values(inMemoryUploadProcesses).filter(a => a[propertyName] == propertyValue);
    },
    // publicMethod1: function () {
    //   // All private members are accessible here
    // },
    // publicMethod2: function () {
    //   // whatever
    // }
  };
})();
