/* tslint:disable */
/* eslint-disable */

export const listCommunityQuestions2 = /* GraphQL */ `
  query ListCommunityQuestions2(
    $filter: ModelCommunityQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate(
          limit: 1000
          filter: { status: { eq: ADDED } }
        ) {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus(
          limit: 1000
          filter: { status: { eq: ADDED } }
        ) {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
            user {
              firstName
              lastName
              email
              status
            }
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const answersByQuestion2 = /* GraphQL */ `
  query AnswersByQuestion2(
    $communityQuestionID: ID
    $statusDate: ModelCommunityQuestionAnswerByCommunityQuestionByStatusByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityQuestionAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByQuestion(
      communityQuestionID: $communityQuestionID
      statusDate: $statusDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityQuestionID
        communityID
        userID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        communityQuestionAnswerCommentsByStatusDate(
          limit: 1000
          filter: { status: { eq: ADDED } }
        ) {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus(
          limit: 1000
          filter: { status: { eq: ADDED } }
        ) {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        owner
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;

// export const listCommunityQuestionsForActivityHistory = /* GraphQL */ `
//   query listCommunityQuestionsForActivityHistory(
//     $filter: ModelCommunityQuestionFilterInput
//     $limit: Int
//     $nextToken: String
//   ) {
//     listCommunityQuestions(
//       filter: $filter
//       limit: $limit
//       nextToken: $nextToken
//     ) {
//       items {
//         id
//         communityID
//         userID
//         date
//         status
//         questionText
//         createdAt
//         updatedAt
//         community {
//           id
//           name
//           createdAt
//           updatedAt
//           owner
//           requiresModeration
//         }
//         owner
//         communityQuestionAnswersByStatusDate(
//           limit: 1000
//           filter: { status: { eq: ADDED } }
//         ) {
//           items {
//             id
//             communityQuestionID
//             userID
//             communityID
//             status
//             date
//             content
//             createdAt
//             updatedAt
//             owner
//             communityQuestionAnswerCommentsByStatusDate(
//               limit: 1000
//               filter: { status: { eq: ADDED } }
//             ) {
//               items {
//                 id
//                 userID
//                 communityID
//                 communityQuestionAnswerID
//                 content
//                 status
//                 date
//                 createdAt
//                 updatedAt
//                 owner
//                 user {
//                   id
//                   firstName
//                   lastName
//                   email
//                   status
//                   profileImageKey
//                   username
//                   identityID
//                   createdAt
//                 }
//               }
//               nextToken
//             }
//             communityQuestionAnswerLikesByStatus(
//               limit: 1000
//               filter: { status: { eq: ADDED } }
//             ) {
//               items {
//                 id
//                 userID
//                 communityID
//                 communityQuestionAnswerID
//                 status
//                 date
//                 createdAt
//                 updatedAt
//                 owner
//                 user {
//                   id
//                   firstName
//                   lastName
//                   email
//                   status
//                   profileImageKey
//                   username
//                   identityID
//                   createdAt
//                 }
//               }
//               nextToken
//             }
//             user {
//               id
//               firstName
//               lastName
//               email
//               status
//               profileImageKey
//               username
//               identityID
//               createdAt
//             }
//           }
//           nextToken
//         }
//         communityQuestionLikesByStatus(
//           limit: 1000
//           filter: { status: { eq: ADDED } }
//         ) {
//           items {
//             id
//             userID
//             communityQuestionID
//             status
//             date
//             createdAt
//             updatedAt
//             user {
//               id
//               firstName
//               lastName
//               email
//               status
//               profileImageKey
//               username
//               identityID
//               createdAt
//             }
//           }
//           nextToken
//         }
//         user {
//           id
//           firstName
//           lastName
//           email
//           status
//           profileImageKey
//           username
//           identityID
//           createdAt
//           updatedAt
//         }
//       }
//       nextToken
//     }
//   }
// `;

export const getCommunityForActivityHistory = /* GraphQL */ `
  query GetCommunityForActivityHistory($id: ID!) {
    getCommunity(id: $id) {
      id
      name
      imageKey
      type
      allowPublicPages
      alias
      publicPreviewHeaderURL
      publicPreviewDescription
      requiresModeration
      allowUnauthenticatedAnswers
      vimeoVideos
      createdAt
      updatedAt
      owner
      communityQuestionsByStatusDate(
        limit: 1000
        filter: { status: { eq: ADDED } }
      ) {
        items {
          id
          communityID
          userID
          date
          status
          questionText
          imageKeys
          createdAt
          updatedAt
          community {
            id
            name
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate(
            limit: 1000
            filter: { status: { eq: ADDED } }
          ) {
            items {
              id
              communityQuestionID
              userID
              communityID
              status
              date
              content
              createdAt
              updatedAt
              owner
              communityQuestionAnswerCommentsByStatusDate(
                limit: 1000
                filter: { status: { eq: ADDED } }
              ) {
                items {
                  id
                  userID
                  communityID
                  communityQuestionAnswerID
                  content
                  status
                  date
                  createdAt
                  updatedAt
                  owner
                  user {
                    id
                    firstName
                    lastName
                    status
                    profileImageKey
                    username
                    identityID
                    createdAt
                  }
                }
                nextToken
              }
              communityQuestionAnswerLikesByStatus(
                limit: 1000
                filter: { status: { eq: ADDED } }
              ) {
                items {
                  id
                  userID
                  communityID
                  communityQuestionAnswerID
                  status
                  date
                  createdAt
                  updatedAt
                  owner
                  user {
                    id
                    firstName
                    lastName
                    status
                    profileImageKey
                    username
                    identityID
                    createdAt
                  }
                }
                nextToken
              }
              user {
                id
                firstName
                lastName
                status
                profileImageKey
                username
                identityID
                createdAt
              }
            }
            nextToken
          }
          communityQuestionLikesByStatus(
            limit: 1000
            filter: { status: { eq: ADDED } }
          ) {
            items {
              id
              userID
              communityQuestionID
              status
              date
              createdAt
              updatedAt
              user {
                id
                firstName
                lastName
                status
                profileImageKey
                username
                identityID
                createdAt
                updatedAt
              }
            }
            nextToken
          }
          user {
            id
            firstName
            lastName
            status
            profileImageKey
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityUsersByStatusDate(limit:1000, filter: {
        or: [
          {
            status: {
              eq: INVITED
            }
          },
          {
            status: {
              eq: MEMBER
            }
          },
          {
            status: {
              eq: ORGANIZER
            }
          },
          {
            status: {
              eq: SUBJECT
            }
          }
        ]
      }) {
        items {
          id
          communityID
          userID
          joinedOn
          invitedByUserID
          invitedOn
          invitation {
            invitationMessage
          }
          status
          relationshipToSpotlight
          owner
          createdAt
          updatedAt
          user {
            id
            firstName
            lastName
            status
            profileImageKey
            username
            identityID
            createdAt
            updatedAt
          }
          invitedByUser {
            id
            firstName
            lastName
            status
            profileImageKey
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;

export const recommendationsByCommunity2 = /* GraphQL */ `
  query RecommendationsByCommunity(
    $communityID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityQuestionRecommendationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recommendationsByCommunity(
      communityID: $communityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityID
        categoryID
        questionText
        status
        category {
          name
        }
      }
      nextToken
    }
  }
`;