import log from '../logging/logger';
import { API } from 'aws-amplify';
import awsConfig from '../../aws-exports';
API.configure(awsConfig);

const API_NAME = `novellaREST`;

type FlaggedContent = {
    contentId: string,
    contentType: string,
    context: string,
}

type FlaggedContentRequestBody = {
  eventType: string,
  payload: FlaggedContent,
}

export async function flagContent(contentId: string, contentType: string, context: string): Promise<any> {
  const apiName = API_NAME;
  const path = `/supportRequest`;

  const init: { body: FlaggedContentRequestBody } = {
    body: {
      eventType: 'report_inappropriate_content',
      payload: {
        contentId: contentId,
        contentType: contentType,
        context: context,
      }
    }
  }

  try {
    console.log(`FLAGGING CONTENT ${contentId} (${contentType}) in context ${context}`);
    const r = await API.post(apiName, path, init);

    log.info(`GOT FLAGGED CONTENT RESPONSE:`);
    log.info(r);
    return r;
  } catch (ex) {
    log.error(`ERROR TRYING TO FLAG CONTENT: ${contentId} (${contentType}) in context ${context}`);
    log.error(ex);
  }
}