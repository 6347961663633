/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($email: String) {
    onCreateUser(email: $email) {
      id
      firstName
      lastName
      email
      status
      profileImageKey
      communityLimits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      welcomesSeen
      helpsSeen
      nextSteps {
        communityID
        nextStepsByRole {
          INVITED
          MEMBER
          ORGANIZER
          SUBJECT
          REMOVED
          BANNED
          ADMIN
        }
      }
      referralCode
      referredByUserID
      username
      identityID
      createdAt
      updatedAt
      communityQuestionsByStatusDate {
        items {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionAnswersByUserStatusDate {
        items {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionlikesByStatusDate {
        items {
          id
          userID
          communityQuestionID
          status
          date
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityUsersByStatusDate {
        items {
          id
          communityID
          userID
          joinedOn
          invitedByUserID
          invitedOn
          invitation {
            invitationMessage
          }
          status
          relationshipToSpotlight
          owner
          group
          createdAt
          updatedAt
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
          invitedByUser {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($id: ID) {
    onUpdateUser(id: $id) {
      id
      firstName
      lastName
      email
      status
      profileImageKey
      communityLimits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      welcomesSeen
      helpsSeen
      nextSteps {
        communityID
        nextStepsByRole {
          INVITED
          MEMBER
          ORGANIZER
          SUBJECT
          REMOVED
          BANNED
          ADMIN
        }
      }
      referralCode
      referredByUserID
      username
      identityID
      createdAt
      updatedAt
      communityQuestionsByStatusDate {
        items {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionAnswersByUserStatusDate {
        items {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionlikesByStatusDate {
        items {
          id
          userID
          communityQuestionID
          status
          date
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityUsersByStatusDate {
        items {
          id
          communityID
          userID
          joinedOn
          invitedByUserID
          invitedOn
          invitation {
            invitationMessage
          }
          status
          relationshipToSpotlight
          owner
          group
          createdAt
          updatedAt
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
          invitedByUser {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($id: ID) {
    onDeleteUser(id: $id) {
      id
      firstName
      lastName
      email
      status
      profileImageKey
      communityLimits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      welcomesSeen
      helpsSeen
      nextSteps {
        communityID
        nextStepsByRole {
          INVITED
          MEMBER
          ORGANIZER
          SUBJECT
          REMOVED
          BANNED
          ADMIN
        }
      }
      referralCode
      referredByUserID
      username
      identityID
      createdAt
      updatedAt
      communityQuestionsByStatusDate {
        items {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionAnswersByUserStatusDate {
        items {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionlikesByStatusDate {
        items {
          id
          userID
          communityQuestionID
          status
          date
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityUsersByStatusDate {
        items {
          id
          communityID
          userID
          joinedOn
          invitedByUserID
          invitedOn
          invitation {
            invitationMessage
          }
          status
          relationshipToSpotlight
          owner
          group
          createdAt
          updatedAt
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
          invitedByUser {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateCommunity = /* GraphQL */ `
  subscription OnCreateCommunity($id: ID) {
    onCreateCommunity(id: $id) {
      id
      name
      imageKey
      type
      allowPublicPages
      alias
      publicPreviewHeaderURL
      publicPreviewDescription
      requiresModeration
      allowUnauthenticatedAnswers
      limits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      vimeoVideos
      communityCategories
      interviewTab {
        header
        bodyLine1
        bodyLine2
      }
      createdAt
      updatedAt
      owner
      communityQuestionsByStatusDate {
        items {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionRecommendations {
        items {
          id
          communityID
          categoryID
          questionText
          score
          status
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
        }
        nextToken
      }
      communityUsersByStatusDate {
        items {
          id
          communityID
          userID
          joinedOn
          invitedByUserID
          invitedOn
          invitation {
            invitationMessage
          }
          status
          relationshipToSpotlight
          owner
          group
          createdAt
          updatedAt
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
          invitedByUser {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateCommunity = /* GraphQL */ `
  subscription OnUpdateCommunity($id: ID) {
    onUpdateCommunity(id: $id) {
      id
      name
      imageKey
      type
      allowPublicPages
      alias
      publicPreviewHeaderURL
      publicPreviewDescription
      requiresModeration
      allowUnauthenticatedAnswers
      limits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      vimeoVideos
      communityCategories
      interviewTab {
        header
        bodyLine1
        bodyLine2
      }
      createdAt
      updatedAt
      owner
      communityQuestionsByStatusDate {
        items {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionRecommendations {
        items {
          id
          communityID
          categoryID
          questionText
          score
          status
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
        }
        nextToken
      }
      communityUsersByStatusDate {
        items {
          id
          communityID
          userID
          joinedOn
          invitedByUserID
          invitedOn
          invitation {
            invitationMessage
          }
          status
          relationshipToSpotlight
          owner
          group
          createdAt
          updatedAt
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
          invitedByUser {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteCommunity = /* GraphQL */ `
  subscription OnDeleteCommunity($id: ID) {
    onDeleteCommunity(id: $id) {
      id
      name
      imageKey
      type
      allowPublicPages
      alias
      publicPreviewHeaderURL
      publicPreviewDescription
      requiresModeration
      allowUnauthenticatedAnswers
      limits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      vimeoVideos
      communityCategories
      interviewTab {
        header
        bodyLine1
        bodyLine2
      }
      createdAt
      updatedAt
      owner
      communityQuestionsByStatusDate {
        items {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionRecommendations {
        items {
          id
          communityID
          categoryID
          questionText
          score
          status
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
        }
        nextToken
      }
      communityUsersByStatusDate {
        items {
          id
          communityID
          userID
          joinedOn
          invitedByUserID
          invitedOn
          invitation {
            invitationMessage
          }
          status
          relationshipToSpotlight
          owner
          group
          createdAt
          updatedAt
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
          invitedByUser {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateCommunityUser = /* GraphQL */ `
  subscription OnCreateCommunityUser {
    onCreateCommunityUser {
      id
      communityID
      userID
      joinedOn
      invitedByUserID
      invitedOn
      invitation {
        invitationMessage
      }
      status
      relationshipToSpotlight
      owner
      group
      createdAt
      updatedAt
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      invitedByUser {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateCommunityUser = /* GraphQL */ `
  subscription OnUpdateCommunityUser {
    onUpdateCommunityUser {
      id
      communityID
      userID
      joinedOn
      invitedByUserID
      invitedOn
      invitation {
        invitationMessage
      }
      status
      relationshipToSpotlight
      owner
      group
      createdAt
      updatedAt
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      invitedByUser {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteCommunityUser = /* GraphQL */ `
  subscription OnDeleteCommunityUser {
    onDeleteCommunityUser {
      id
      communityID
      userID
      joinedOn
      invitedByUserID
      invitedOn
      invitation {
        invitationMessage
      }
      status
      relationshipToSpotlight
      owner
      group
      createdAt
      updatedAt
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      invitedByUser {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateCommunityUserByUserId = /* GraphQL */ `
  subscription OnCreateCommunityUserByUserId($userID: String) {
    onCreateCommunityUserByUserId(userID: $userID) {
      id
      communityID
      userID
      joinedOn
      invitedByUserID
      invitedOn
      invitation {
        invitationMessage
      }
      status
      relationshipToSpotlight
      owner
      group
      createdAt
      updatedAt
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      invitedByUser {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateCommunityUserByUserId = /* GraphQL */ `
  subscription OnUpdateCommunityUserByUserId($userID: String) {
    onUpdateCommunityUserByUserId(userID: $userID) {
      id
      communityID
      userID
      joinedOn
      invitedByUserID
      invitedOn
      invitation {
        invitationMessage
      }
      status
      relationshipToSpotlight
      owner
      group
      createdAt
      updatedAt
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      invitedByUser {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteCommunityUserByUserId = /* GraphQL */ `
  subscription OnDeleteCommunityUserByUserId($userID: String) {
    onDeleteCommunityUserByUserId(userID: $userID) {
      id
      communityID
      userID
      joinedOn
      invitedByUserID
      invitedOn
      invitation {
        invitationMessage
      }
      status
      relationshipToSpotlight
      owner
      group
      createdAt
      updatedAt
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      invitedByUser {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateCommunityInterviewRequest = /* GraphQL */ `
  subscription OnCreateCommunityInterviewRequest($communityID: String) {
    onCreateCommunityInterviewRequest(communityID: $communityID) {
      id
      communityID
      userID
      date
      status
      message {
        subject
        body
      }
      owner
      createdAt
      updatedAt
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateCommunityInterviewRequest = /* GraphQL */ `
  subscription OnUpdateCommunityInterviewRequest(
    $id: ID
    $communityID: String
  ) {
    onUpdateCommunityInterviewRequest(id: $id, communityID: $communityID) {
      id
      communityID
      userID
      date
      status
      message {
        subject
        body
      }
      owner
      createdAt
      updatedAt
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteCommunityInterviewRequest = /* GraphQL */ `
  subscription OnDeleteCommunityInterviewRequest(
    $id: ID
    $communityID: String
  ) {
    onDeleteCommunityInterviewRequest(id: $id, communityID: $communityID) {
      id
      communityID
      userID
      date
      status
      message {
        subject
        body
      }
      owner
      createdAt
      updatedAt
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateXAssignEmailToPlaceholder = /* GraphQL */ `
  subscription OnCreateXAssignEmailToPlaceholder($communityUserID: ID) {
    onCreateXAssignEmailToPlaceholder(communityUserID: $communityUserID) {
      id
      status
      reason
      communityUserID
      userID
      communityID
      email
      invitation {
        invitationMessage
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateXAssignEmailToPlaceholder = /* GraphQL */ `
  subscription OnUpdateXAssignEmailToPlaceholder($id: ID) {
    onUpdateXAssignEmailToPlaceholder(id: $id) {
      id
      status
      reason
      communityUserID
      userID
      communityID
      email
      invitation {
        invitationMessage
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteXAssignEmailToPlaceholder = /* GraphQL */ `
  subscription OnDeleteXAssignEmailToPlaceholder($id: ID) {
    onDeleteXAssignEmailToPlaceholder(id: $id) {
      id
      status
      reason
      communityUserID
      userID
      communityID
      email
      invitation {
        invitationMessage
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateCommunityQuestionAnswer = /* GraphQL */ `
  subscription OnCreateCommunityQuestionAnswer {
    onCreateCommunityQuestionAnswer {
      id
      communityQuestionID
      userID
      communityID
      status
      date
      content
      createdAt
      updatedAt
      communityQuestion {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      communityQuestionAnswerCommentsByStatusDate {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          content
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionAnswerLikesByStatus {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateCommunityQuestionAnswer = /* GraphQL */ `
  subscription OnUpdateCommunityQuestionAnswer {
    onUpdateCommunityQuestionAnswer {
      id
      communityQuestionID
      userID
      communityID
      status
      date
      content
      createdAt
      updatedAt
      communityQuestion {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      communityQuestionAnswerCommentsByStatusDate {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          content
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionAnswerLikesByStatus {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteCommunityQuestionAnswer = /* GraphQL */ `
  subscription OnDeleteCommunityQuestionAnswer {
    onDeleteCommunityQuestionAnswer {
      id
      communityQuestionID
      userID
      communityID
      status
      date
      content
      createdAt
      updatedAt
      communityQuestion {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      communityQuestionAnswerCommentsByStatusDate {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          content
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionAnswerLikesByStatus {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateCommunityQuestionAnswerByQuestionId = /* GraphQL */ `
  subscription OnCreateCommunityQuestionAnswerByQuestionId(
    $communityQuestionID: String
  ) {
    onCreateCommunityQuestionAnswerByQuestionId(
      communityQuestionID: $communityQuestionID
    ) {
      id
      communityQuestionID
      userID
      communityID
      status
      date
      content
      createdAt
      updatedAt
      communityQuestion {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      communityQuestionAnswerCommentsByStatusDate {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          content
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionAnswerLikesByStatus {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateCommunityQuestionAnswerByQuestionId = /* GraphQL */ `
  subscription OnUpdateCommunityQuestionAnswerByQuestionId(
    $communityQuestionID: String
  ) {
    onUpdateCommunityQuestionAnswerByQuestionId(
      communityQuestionID: $communityQuestionID
    ) {
      id
      communityQuestionID
      userID
      communityID
      status
      date
      content
      createdAt
      updatedAt
      communityQuestion {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      communityQuestionAnswerCommentsByStatusDate {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          content
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionAnswerLikesByStatus {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteCommunityQuestionAnswerByQuestionId = /* GraphQL */ `
  subscription OnDeleteCommunityQuestionAnswerByQuestionId(
    $communityQuestionID: String
  ) {
    onDeleteCommunityQuestionAnswerByQuestionId(
      communityQuestionID: $communityQuestionID
    ) {
      id
      communityQuestionID
      userID
      communityID
      status
      date
      content
      createdAt
      updatedAt
      communityQuestion {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      communityQuestionAnswerCommentsByStatusDate {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          content
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionAnswerLikesByStatus {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateCommunityQuestionAnswerComment = /* GraphQL */ `
  subscription OnCreateCommunityQuestionAnswerComment {
    onCreateCommunityQuestionAnswerComment {
      id
      userID
      communityID
      communityQuestionAnswerID
      content
      status
      date
      createdAt
      updatedAt
      communityQuestionAnswer {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateCommunityQuestionAnswerComment = /* GraphQL */ `
  subscription OnUpdateCommunityQuestionAnswerComment {
    onUpdateCommunityQuestionAnswerComment {
      id
      userID
      communityID
      communityQuestionAnswerID
      content
      status
      date
      createdAt
      updatedAt
      communityQuestionAnswer {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteCommunityQuestionAnswerComment = /* GraphQL */ `
  subscription OnDeleteCommunityQuestionAnswerComment {
    onDeleteCommunityQuestionAnswerComment {
      id
      userID
      communityID
      communityQuestionAnswerID
      content
      status
      date
      createdAt
      updatedAt
      communityQuestionAnswer {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateCommunityQuestionAnswerCommentByAnswerId = /* GraphQL */ `
  subscription OnCreateCommunityQuestionAnswerCommentByAnswerId(
    $communityQuestionAnswerID: String
  ) {
    onCreateCommunityQuestionAnswerCommentByAnswerId(
      communityQuestionAnswerID: $communityQuestionAnswerID
    ) {
      id
      userID
      communityID
      communityQuestionAnswerID
      content
      status
      date
      createdAt
      updatedAt
      communityQuestionAnswer {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateCommunityQuestionAnswerCommentByAnswerId = /* GraphQL */ `
  subscription OnUpdateCommunityQuestionAnswerCommentByAnswerId(
    $communityQuestionAnswerID: String
  ) {
    onUpdateCommunityQuestionAnswerCommentByAnswerId(
      communityQuestionAnswerID: $communityQuestionAnswerID
    ) {
      id
      userID
      communityID
      communityQuestionAnswerID
      content
      status
      date
      createdAt
      updatedAt
      communityQuestionAnswer {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteCommunityQuestionAnswerCommentByAnswerId = /* GraphQL */ `
  subscription OnDeleteCommunityQuestionAnswerCommentByAnswerId(
    $communityQuestionAnswerID: String
  ) {
    onDeleteCommunityQuestionAnswerCommentByAnswerId(
      communityQuestionAnswerID: $communityQuestionAnswerID
    ) {
      id
      userID
      communityID
      communityQuestionAnswerID
      content
      status
      date
      createdAt
      updatedAt
      communityQuestionAnswer {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreatePacemakerLastRun = /* GraphQL */ `
  subscription OnCreatePacemakerLastRun {
    onCreatePacemakerLastRun {
      id
      entries {
        job
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdatePacemakerLastRun = /* GraphQL */ `
  subscription OnUpdatePacemakerLastRun {
    onUpdatePacemakerLastRun {
      id
      entries {
        job
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeletePacemakerLastRun = /* GraphQL */ `
  subscription OnDeletePacemakerLastRun {
    onDeletePacemakerLastRun {
      id
      entries {
        job
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateZAnswerCreatedNotifySig = /* GraphQL */ `
  subscription OnCreateZAnswerCreatedNotifySig {
    onCreateZAnswerCreatedNotifySig {
      id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateZAnswerCreatedNotifySig = /* GraphQL */ `
  subscription OnUpdateZAnswerCreatedNotifySig {
    onUpdateZAnswerCreatedNotifySig {
      id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteZAnswerCreatedNotifySig = /* GraphQL */ `
  subscription OnDeleteZAnswerCreatedNotifySig {
    onDeleteZAnswerCreatedNotifySig {
      id
      createdAt
      updatedAt
    }
  }
`;
export const onCreateZAnswerCreatedNotifyBacklog = /* GraphQL */ `
  subscription OnCreateZAnswerCreatedNotifyBacklog {
    onCreateZAnswerCreatedNotifyBacklog {
      communityID
      communityQuestionAnswerID
      communityQuestionAnswerCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateZAnswerCreatedNotifyBacklog = /* GraphQL */ `
  subscription OnUpdateZAnswerCreatedNotifyBacklog {
    onUpdateZAnswerCreatedNotifyBacklog {
      communityID
      communityQuestionAnswerID
      communityQuestionAnswerCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteZAnswerCreatedNotifyBacklog = /* GraphQL */ `
  subscription OnDeleteZAnswerCreatedNotifyBacklog {
    onDeleteZAnswerCreatedNotifyBacklog {
      communityID
      communityQuestionAnswerID
      communityQuestionAnswerCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateZAnswerCreatedNotifyComplete = /* GraphQL */ `
  subscription OnCreateZAnswerCreatedNotifyComplete {
    onCreateZAnswerCreatedNotifyComplete {
      communityID
      communityQuestionAnswerID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onUpdateZAnswerCreatedNotifyComplete = /* GraphQL */ `
  subscription OnUpdateZAnswerCreatedNotifyComplete {
    onUpdateZAnswerCreatedNotifyComplete {
      communityID
      communityQuestionAnswerID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onDeleteZAnswerCreatedNotifyComplete = /* GraphQL */ `
  subscription OnDeleteZAnswerCreatedNotifyComplete {
    onDeleteZAnswerCreatedNotifyComplete {
      communityID
      communityQuestionAnswerID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onCreateZCommentMadeNotifySig = /* GraphQL */ `
  subscription OnCreateZCommentMadeNotifySig {
    onCreateZCommentMadeNotifySig {
      id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateZCommentMadeNotifySig = /* GraphQL */ `
  subscription OnUpdateZCommentMadeNotifySig {
    onUpdateZCommentMadeNotifySig {
      id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteZCommentMadeNotifySig = /* GraphQL */ `
  subscription OnDeleteZCommentMadeNotifySig {
    onDeleteZCommentMadeNotifySig {
      id
      createdAt
      updatedAt
    }
  }
`;
export const onCreateZCommentMadeNotifyBacklog = /* GraphQL */ `
  subscription OnCreateZCommentMadeNotifyBacklog {
    onCreateZCommentMadeNotifyBacklog {
      communityID
      communityQuestionAnswerID
      communityQuestionAnswerCommentCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateZCommentMadeNotifyBacklog = /* GraphQL */ `
  subscription OnUpdateZCommentMadeNotifyBacklog {
    onUpdateZCommentMadeNotifyBacklog {
      communityID
      communityQuestionAnswerID
      communityQuestionAnswerCommentCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteZCommentMadeNotifyBacklog = /* GraphQL */ `
  subscription OnDeleteZCommentMadeNotifyBacklog {
    onDeleteZCommentMadeNotifyBacklog {
      communityID
      communityQuestionAnswerID
      communityQuestionAnswerCommentCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateZCommentMadeNotifyComplete = /* GraphQL */ `
  subscription OnCreateZCommentMadeNotifyComplete {
    onCreateZCommentMadeNotifyComplete {
      communityID
      communityQuestionAnswerID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onUpdateZCommentMadeNotifyComplete = /* GraphQL */ `
  subscription OnUpdateZCommentMadeNotifyComplete {
    onUpdateZCommentMadeNotifyComplete {
      communityID
      communityQuestionAnswerID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onDeleteZCommentMadeNotifyComplete = /* GraphQL */ `
  subscription OnDeleteZCommentMadeNotifyComplete {
    onDeleteZCommentMadeNotifyComplete {
      communityID
      communityQuestionAnswerID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onCreateZCommunityInvitationReminderNotifySig = /* GraphQL */ `
  subscription OnCreateZCommunityInvitationReminderNotifySig {
    onCreateZCommunityInvitationReminderNotifySig {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const onUpdateZCommunityInvitationReminderNotifySig = /* GraphQL */ `
  subscription OnUpdateZCommunityInvitationReminderNotifySig {
    onUpdateZCommunityInvitationReminderNotifySig {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const onDeleteZCommunityInvitationReminderNotifySig = /* GraphQL */ `
  subscription OnDeleteZCommunityInvitationReminderNotifySig {
    onDeleteZCommunityInvitationReminderNotifySig {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const onCreateZCommunityInvitationReminderNotifyBacklog = /* GraphQL */ `
  subscription OnCreateZCommunityInvitationReminderNotifyBacklog {
    onCreateZCommunityInvitationReminderNotifyBacklog {
      communityUserID
      userID
      communityID
      status
      invitedByUserID
      invitedOn
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateZCommunityInvitationReminderNotifyBacklog = /* GraphQL */ `
  subscription OnUpdateZCommunityInvitationReminderNotifyBacklog {
    onUpdateZCommunityInvitationReminderNotifyBacklog {
      communityUserID
      userID
      communityID
      status
      invitedByUserID
      invitedOn
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteZCommunityInvitationReminderNotifyBacklog = /* GraphQL */ `
  subscription OnDeleteZCommunityInvitationReminderNotifyBacklog {
    onDeleteZCommunityInvitationReminderNotifyBacklog {
      communityUserID
      userID
      communityID
      status
      invitedByUserID
      invitedOn
      createdAt
      updatedAt
    }
  }
`;
export const onCreateZCommunityInvitationReminderNotifyComplete = /* GraphQL */ `
  subscription OnCreateZCommunityInvitationReminderNotifyComplete {
    onCreateZCommunityInvitationReminderNotifyComplete {
      communityUserID
      userID
      communityID
      status
      invitedByUserID
      invitedOn
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onUpdateZCommunityInvitationReminderNotifyComplete = /* GraphQL */ `
  subscription OnUpdateZCommunityInvitationReminderNotifyComplete {
    onUpdateZCommunityInvitationReminderNotifyComplete {
      communityUserID
      userID
      communityID
      status
      invitedByUserID
      invitedOn
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onDeleteZCommunityInvitationReminderNotifyComplete = /* GraphQL */ `
  subscription OnDeleteZCommunityInvitationReminderNotifyComplete {
    onDeleteZCommunityInvitationReminderNotifyComplete {
      communityUserID
      userID
      communityID
      status
      invitedByUserID
      invitedOn
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onCreateZCommunityNextStepCalculateSig = /* GraphQL */ `
  subscription OnCreateZCommunityNextStepCalculateSig {
    onCreateZCommunityNextStepCalculateSig {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const onUpdateZCommunityNextStepCalculateSig = /* GraphQL */ `
  subscription OnUpdateZCommunityNextStepCalculateSig {
    onUpdateZCommunityNextStepCalculateSig {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const onDeleteZCommunityNextStepCalculateSig = /* GraphQL */ `
  subscription OnDeleteZCommunityNextStepCalculateSig {
    onDeleteZCommunityNextStepCalculateSig {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const onCreateZCommunityNextStepCalculateBacklog = /* GraphQL */ `
  subscription OnCreateZCommunityNextStepCalculateBacklog {
    onCreateZCommunityNextStepCalculateBacklog {
      id
      communityID
      nextStepType
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateZCommunityNextStepCalculateBacklog = /* GraphQL */ `
  subscription OnUpdateZCommunityNextStepCalculateBacklog {
    onUpdateZCommunityNextStepCalculateBacklog {
      id
      communityID
      nextStepType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteZCommunityNextStepCalculateBacklog = /* GraphQL */ `
  subscription OnDeleteZCommunityNextStepCalculateBacklog {
    onDeleteZCommunityNextStepCalculateBacklog {
      id
      communityID
      nextStepType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateZCommunityNextStepCalculateComplete = /* GraphQL */ `
  subscription OnCreateZCommunityNextStepCalculateComplete {
    onCreateZCommunityNextStepCalculateComplete {
      id
      communityID
      nextStepType
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onUpdateZCommunityNextStepCalculateComplete = /* GraphQL */ `
  subscription OnUpdateZCommunityNextStepCalculateComplete {
    onUpdateZCommunityNextStepCalculateComplete {
      id
      communityID
      nextStepType
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onDeleteZCommunityNextStepCalculateComplete = /* GraphQL */ `
  subscription OnDeleteZCommunityNextStepCalculateComplete {
    onDeleteZCommunityNextStepCalculateComplete {
      id
      communityID
      nextStepType
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onCreateZFirstCommunityQuestionNotifySig = /* GraphQL */ `
  subscription OnCreateZFirstCommunityQuestionNotifySig {
    onCreateZFirstCommunityQuestionNotifySig {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const onUpdateZFirstCommunityQuestionNotifySig = /* GraphQL */ `
  subscription OnUpdateZFirstCommunityQuestionNotifySig {
    onUpdateZFirstCommunityQuestionNotifySig {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const onDeleteZFirstCommunityQuestionNotifySig = /* GraphQL */ `
  subscription OnDeleteZFirstCommunityQuestionNotifySig {
    onDeleteZFirstCommunityQuestionNotifySig {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const onCreateZFirstCommunityQuestionNotifyBacklog = /* GraphQL */ `
  subscription OnCreateZFirstCommunityQuestionNotifyBacklog {
    onCreateZFirstCommunityQuestionNotifyBacklog {
      communityID
      communityCreatedAt
      communityQuestionID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateZFirstCommunityQuestionNotifyBacklog = /* GraphQL */ `
  subscription OnUpdateZFirstCommunityQuestionNotifyBacklog {
    onUpdateZFirstCommunityQuestionNotifyBacklog {
      communityID
      communityCreatedAt
      communityQuestionID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteZFirstCommunityQuestionNotifyBacklog = /* GraphQL */ `
  subscription OnDeleteZFirstCommunityQuestionNotifyBacklog {
    onDeleteZFirstCommunityQuestionNotifyBacklog {
      communityID
      communityCreatedAt
      communityQuestionID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateZFirstCommunityQuestionNotifyComplete = /* GraphQL */ `
  subscription OnCreateZFirstCommunityQuestionNotifyComplete {
    onCreateZFirstCommunityQuestionNotifyComplete {
      communityID
      communityCreatedAt
      communityQuestionID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onUpdateZFirstCommunityQuestionNotifyComplete = /* GraphQL */ `
  subscription OnUpdateZFirstCommunityQuestionNotifyComplete {
    onUpdateZFirstCommunityQuestionNotifyComplete {
      communityID
      communityCreatedAt
      communityQuestionID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onDeleteZFirstCommunityQuestionNotifyComplete = /* GraphQL */ `
  subscription OnDeleteZFirstCommunityQuestionNotifyComplete {
    onDeleteZFirstCommunityQuestionNotifyComplete {
      communityID
      communityCreatedAt
      communityQuestionID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onCreateZQuestionAskedNotifySig = /* GraphQL */ `
  subscription OnCreateZQuestionAskedNotifySig {
    onCreateZQuestionAskedNotifySig {
      id
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateZQuestionAskedNotifySig = /* GraphQL */ `
  subscription OnUpdateZQuestionAskedNotifySig {
    onUpdateZQuestionAskedNotifySig {
      id
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteZQuestionAskedNotifySig = /* GraphQL */ `
  subscription OnDeleteZQuestionAskedNotifySig {
    onDeleteZQuestionAskedNotifySig {
      id
      createdAt
      updatedAt
    }
  }
`;
export const onCreateZQuestionAskedNotifyBacklog = /* GraphQL */ `
  subscription OnCreateZQuestionAskedNotifyBacklog {
    onCreateZQuestionAskedNotifyBacklog {
      communityID
      communityQuestionID
      communityQuestionCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateZQuestionAskedNotifyBacklog = /* GraphQL */ `
  subscription OnUpdateZQuestionAskedNotifyBacklog {
    onUpdateZQuestionAskedNotifyBacklog {
      communityID
      communityQuestionID
      communityQuestionCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteZQuestionAskedNotifyBacklog = /* GraphQL */ `
  subscription OnDeleteZQuestionAskedNotifyBacklog {
    onDeleteZQuestionAskedNotifyBacklog {
      communityID
      communityQuestionID
      communityQuestionCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateZQuestionAskedNotifyComplete = /* GraphQL */ `
  subscription OnCreateZQuestionAskedNotifyComplete {
    onCreateZQuestionAskedNotifyComplete {
      communityID
      communityQuestionID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onUpdateZQuestionAskedNotifyComplete = /* GraphQL */ `
  subscription OnUpdateZQuestionAskedNotifyComplete {
    onUpdateZQuestionAskedNotifyComplete {
      communityID
      communityQuestionID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onDeleteZQuestionAskedNotifyComplete = /* GraphQL */ `
  subscription OnDeleteZQuestionAskedNotifyComplete {
    onDeleteZQuestionAskedNotifyComplete {
      communityID
      communityQuestionID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const onCreateCategory = /* GraphQL */ `
  subscription OnCreateCategory {
    onCreateCategory {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateCategory = /* GraphQL */ `
  subscription OnUpdateCategory {
    onUpdateCategory {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteCategory = /* GraphQL */ `
  subscription OnDeleteCategory {
    onDeleteCategory {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateCommunityCommand = /* GraphQL */ `
  subscription OnCreateCommunityCommand($owner: String) {
    onCreateCommunityCommand(owner: $owner) {
      id
      command {
        operation
      }
      communityID
      name
      imageKey
      type
      allowPublicPages
      limits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      vimeoVideos
      communityCategories
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateCommunityCommand = /* GraphQL */ `
  subscription OnUpdateCommunityCommand($owner: String) {
    onUpdateCommunityCommand(owner: $owner) {
      id
      command {
        operation
      }
      communityID
      name
      imageKey
      type
      allowPublicPages
      limits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      vimeoVideos
      communityCategories
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteCommunityCommand = /* GraphQL */ `
  subscription OnDeleteCommunityCommand($owner: String) {
    onDeleteCommunityCommand(owner: $owner) {
      id
      command {
        operation
      }
      communityID
      name
      imageKey
      type
      allowPublicPages
      limits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      vimeoVideos
      communityCategories
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateCommunityInterviewRequestCommand = /* GraphQL */ `
  subscription OnCreateCommunityInterviewRequestCommand($owner: String) {
    onCreateCommunityInterviewRequestCommand(owner: $owner) {
      id
      command {
        operation
      }
      communityID
      userID
      date
      status
      message {
        subject
        body
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateCommunityInterviewRequestCommand = /* GraphQL */ `
  subscription OnUpdateCommunityInterviewRequestCommand($owner: String) {
    onUpdateCommunityInterviewRequestCommand(owner: $owner) {
      id
      command {
        operation
      }
      communityID
      userID
      date
      status
      message {
        subject
        body
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteCommunityInterviewRequestCommand = /* GraphQL */ `
  subscription OnDeleteCommunityInterviewRequestCommand($owner: String) {
    onDeleteCommunityInterviewRequestCommand(owner: $owner) {
      id
      command {
        operation
      }
      communityID
      userID
      date
      status
      message {
        subject
        body
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateCommunityQuestion = /* GraphQL */ `
  subscription OnCreateCommunityQuestion($owner: String) {
    onCreateCommunityQuestion(owner: $owner) {
      id
      communityID
      userID
      categoryID
      communityCategory
      date
      status
      source
      questionText
      imageKeys
      clonedFromCommunityQuestionRecommendationId
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
        owner
      }
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      owner
      communityQuestionAnswersByStatusDate {
        items {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionLikesByStatus {
        items {
          id
          userID
          communityQuestionID
          status
          date
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateCommunityQuestion = /* GraphQL */ `
  subscription OnUpdateCommunityQuestion($owner: String) {
    onUpdateCommunityQuestion(owner: $owner) {
      id
      communityID
      userID
      categoryID
      communityCategory
      date
      status
      source
      questionText
      imageKeys
      clonedFromCommunityQuestionRecommendationId
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
        owner
      }
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      owner
      communityQuestionAnswersByStatusDate {
        items {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionLikesByStatus {
        items {
          id
          userID
          communityQuestionID
          status
          date
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteCommunityQuestion = /* GraphQL */ `
  subscription OnDeleteCommunityQuestion($owner: String) {
    onDeleteCommunityQuestion(owner: $owner) {
      id
      communityID
      userID
      categoryID
      communityCategory
      date
      status
      source
      questionText
      imageKeys
      clonedFromCommunityQuestionRecommendationId
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
        owner
      }
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      owner
      communityQuestionAnswersByStatusDate {
        items {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionLikesByStatus {
        items {
          id
          userID
          communityQuestionID
          status
          date
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateCommunityQuestionAnswerCommand = /* GraphQL */ `
  subscription OnCreateCommunityQuestionAnswerCommand($owner: String) {
    onCreateCommunityQuestionAnswerCommand(owner: $owner) {
      id
      command {
        operation
      }
      communityQuestionAnswerID
      communityQuestionID
      communityID
      userID
      status
      date
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateCommunityQuestionAnswerCommand = /* GraphQL */ `
  subscription OnUpdateCommunityQuestionAnswerCommand($owner: String) {
    onUpdateCommunityQuestionAnswerCommand(owner: $owner) {
      id
      command {
        operation
      }
      communityQuestionAnswerID
      communityQuestionID
      communityID
      userID
      status
      date
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteCommunityQuestionAnswerCommand = /* GraphQL */ `
  subscription OnDeleteCommunityQuestionAnswerCommand($owner: String) {
    onDeleteCommunityQuestionAnswerCommand(owner: $owner) {
      id
      command {
        operation
      }
      communityQuestionAnswerID
      communityQuestionID
      communityID
      userID
      status
      date
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateCommunityQuestionAnswerCommentCommand = /* GraphQL */ `
  subscription OnCreateCommunityQuestionAnswerCommentCommand($owner: String) {
    onCreateCommunityQuestionAnswerCommentCommand(owner: $owner) {
      id
      command {
        operation
      }
      communityQuestionAnswerCommentID
      userID
      communityID
      communityQuestionAnswerID
      content
      status
      date
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateCommunityQuestionAnswerCommentCommand = /* GraphQL */ `
  subscription OnUpdateCommunityQuestionAnswerCommentCommand($owner: String) {
    onUpdateCommunityQuestionAnswerCommentCommand(owner: $owner) {
      id
      command {
        operation
      }
      communityQuestionAnswerCommentID
      userID
      communityID
      communityQuestionAnswerID
      content
      status
      date
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteCommunityQuestionAnswerCommentCommand = /* GraphQL */ `
  subscription OnDeleteCommunityQuestionAnswerCommentCommand($owner: String) {
    onDeleteCommunityQuestionAnswerCommentCommand(owner: $owner) {
      id
      command {
        operation
      }
      communityQuestionAnswerCommentID
      userID
      communityID
      communityQuestionAnswerID
      content
      status
      date
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateCommunityQuestionAnswerLike = /* GraphQL */ `
  subscription OnCreateCommunityQuestionAnswerLike($owner: String) {
    onCreateCommunityQuestionAnswerLike(owner: $owner) {
      id
      userID
      communityID
      communityQuestionAnswerID
      status
      date
      createdAt
      updatedAt
      communityQuestionAnswer {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateCommunityQuestionAnswerLike = /* GraphQL */ `
  subscription OnUpdateCommunityQuestionAnswerLike($owner: String) {
    onUpdateCommunityQuestionAnswerLike(owner: $owner) {
      id
      userID
      communityID
      communityQuestionAnswerID
      status
      date
      createdAt
      updatedAt
      communityQuestionAnswer {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteCommunityQuestionAnswerLike = /* GraphQL */ `
  subscription OnDeleteCommunityQuestionAnswerLike($owner: String) {
    onDeleteCommunityQuestionAnswerLike(owner: $owner) {
      id
      userID
      communityID
      communityQuestionAnswerID
      status
      date
      createdAt
      updatedAt
      communityQuestionAnswer {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateCommunityQuestionLike = /* GraphQL */ `
  subscription OnCreateCommunityQuestionLike {
    onCreateCommunityQuestionLike {
      id
      userID
      communityQuestionID
      status
      date
      createdAt
      updatedAt
      communityQuestion {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateCommunityQuestionLike = /* GraphQL */ `
  subscription OnUpdateCommunityQuestionLike {
    onUpdateCommunityQuestionLike {
      id
      userID
      communityQuestionID
      status
      date
      createdAt
      updatedAt
      communityQuestion {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteCommunityQuestionLike = /* GraphQL */ `
  subscription OnDeleteCommunityQuestionLike {
    onDeleteCommunityQuestionLike {
      id
      userID
      communityQuestionID
      status
      date
      createdAt
      updatedAt
      communityQuestion {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateCommunityQuestionRecommendation = /* GraphQL */ `
  subscription OnCreateCommunityQuestionRecommendation($owner: String) {
    onCreateCommunityQuestionRecommendation(owner: $owner) {
      id
      communityID
      categoryID
      questionText
      score
      status
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
        owner
      }
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const onUpdateCommunityQuestionRecommendation = /* GraphQL */ `
  subscription OnUpdateCommunityQuestionRecommendation($owner: String) {
    onUpdateCommunityQuestionRecommendation(owner: $owner) {
      id
      communityID
      categoryID
      questionText
      score
      status
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
        owner
      }
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const onDeleteCommunityQuestionRecommendation = /* GraphQL */ `
  subscription OnDeleteCommunityQuestionRecommendation($owner: String) {
    onDeleteCommunityQuestionRecommendation(owner: $owner) {
      id
      communityID
      categoryID
      questionText
      score
      status
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
        owner
      }
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const onCreateCommunityUserCommand = /* GraphQL */ `
  subscription OnCreateCommunityUserCommand($owner: String) {
    onCreateCommunityUserCommand(owner: $owner) {
      id
      command {
        operation
      }
      communityUserId
      communityID
      userID
      joinedOn
      invitedByUserID
      invitedOn
      invitation {
        invitationMessage
      }
      status
      relationshipToSpotlight
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateCommunityUserCommand = /* GraphQL */ `
  subscription OnUpdateCommunityUserCommand($owner: String) {
    onUpdateCommunityUserCommand(owner: $owner) {
      id
      command {
        operation
      }
      communityUserId
      communityID
      userID
      joinedOn
      invitedByUserID
      invitedOn
      invitation {
        invitationMessage
      }
      status
      relationshipToSpotlight
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteCommunityUserCommand = /* GraphQL */ `
  subscription OnDeleteCommunityUserCommand($owner: String) {
    onDeleteCommunityUserCommand(owner: $owner) {
      id
      command {
        operation
      }
      communityUserId
      communityID
      userID
      joinedOn
      invitedByUserID
      invitedOn
      invitation {
        invitationMessage
      }
      status
      relationshipToSpotlight
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateInvitation = /* GraphQL */ `
  subscription OnCreateInvitation {
    onCreateInvitation {
      id
      email
      toNotify
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInvitation = /* GraphQL */ `
  subscription OnUpdateInvitation {
    onUpdateInvitation {
      id
      email
      toNotify
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInvitation = /* GraphQL */ `
  subscription OnDeleteInvitation {
    onDeleteInvitation {
      id
      email
      toNotify
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserIdToIdentityId = /* GraphQL */ `
  subscription OnCreateUserIdToIdentityId {
    onCreateUserIdToIdentityId {
      userID
      identityID
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserIdToIdentityId = /* GraphQL */ `
  subscription OnUpdateUserIdToIdentityId {
    onUpdateUserIdToIdentityId {
      userID
      identityID
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserIdToIdentityId = /* GraphQL */ `
  subscription OnDeleteUserIdToIdentityId {
    onDeleteUserIdToIdentityId {
      userID
      identityID
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserCommand = /* GraphQL */ `
  subscription OnCreateUserCommand($owner: String) {
    onCreateUserCommand(owner: $owner) {
      id
      command {
        operation
      }
      userID
      firstName
      lastName
      email
      status
      profileImageKey
      communityLimits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      welcomesSeen
      helpsSeen
      username
      identityID
      referralCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateUserCommand = /* GraphQL */ `
  subscription OnUpdateUserCommand($owner: String) {
    onUpdateUserCommand(owner: $owner) {
      id
      command {
        operation
      }
      userID
      firstName
      lastName
      email
      status
      profileImageKey
      communityLimits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      welcomesSeen
      helpsSeen
      username
      identityID
      referralCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteUserCommand = /* GraphQL */ `
  subscription OnDeleteUserCommand($owner: String) {
    onDeleteUserCommand(owner: $owner) {
      id
      command {
        operation
      }
      userID
      firstName
      lastName
      email
      status
      profileImageKey
      communityLimits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      welcomesSeen
      helpsSeen
      username
      identityID
      referralCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onCreateUserGoogleTokens = /* GraphQL */ `
  subscription OnCreateUserGoogleTokens($owner: String) {
    onCreateUserGoogleTokens(owner: $owner) {
      owner
      accessToken
      refreshToken
      scopes
      expiresOn
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserGoogleTokens = /* GraphQL */ `
  subscription OnUpdateUserGoogleTokens($owner: String) {
    onUpdateUserGoogleTokens(owner: $owner) {
      owner
      accessToken
      refreshToken
      scopes
      expiresOn
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserGoogleTokens = /* GraphQL */ `
  subscription OnDeleteUserGoogleTokens($owner: String) {
    onDeleteUserGoogleTokens(owner: $owner) {
      owner
      accessToken
      refreshToken
      scopes
      expiresOn
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserReferralCode = /* GraphQL */ `
  subscription OnCreateUserReferralCode {
    onCreateUserReferralCode {
      referralCode
      firstName
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserReferralCode = /* GraphQL */ `
  subscription OnUpdateUserReferralCode {
    onUpdateUserReferralCode {
      referralCode
      firstName
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserReferralCode = /* GraphQL */ `
  subscription OnDeleteUserReferralCode {
    onDeleteUserReferralCode {
      referralCode
      firstName
      createdAt
      updatedAt
    }
  }
`;
