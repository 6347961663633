import React, { useEffect } from 'react'
import { RootStackParamList } from '../../../types/Navigation';
import { View } from 'react-native';
import { DrawerScreenProps } from '@react-navigation/drawer';
import NActivityIndicator from '../../components/ActivityIndicators/NActivityIndicator';
import { useLinkTo } from '@react-navigation/native';

export type GregHowellProps = DrawerScreenProps<RootStackParamList, 'Greg Howell'>;

function GregHowell(props: GregHowellProps): React.ReactElement {
  const linkTo = useLinkTo();

  useEffect(() => {
    setTimeout(() => {
      linkTo(`/preview/greghowell`);
    }, 200);
  }, [])
  

  return (
    <View style={{
      display: 'flex',
      flexDirection: 'row',
      minHeight: '100%',
      justifyContent: 'center',
      alignContent: 'center',
    }}>
      <NActivityIndicator />
    </View>
  )
}

export default GregHowell;
