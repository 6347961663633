import { Dimensions, PixelRatio, Platform } from "react-native";

// based on iphone 6/7/8's scale
const canonicalWidth = 375;
const canonicalHeight = 667;

// magic numbers
const minWidthScale = 1;
const maxWidthScale = 1.4;
const minHeightScale = 0.1;
const maxHeightScale = 3;

const {width, height} = Dimensions.get('window');

export function normalizeWidth(size: number, screenWidth: number) : number {
  let scale = (screenWidth ?? width) / canonicalWidth;
  scale = Math.max(scale, minWidthScale);
  scale = Math.min(scale, maxWidthScale);
  const newSize = size * scale;
  if (Platform.OS === 'ios') {
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  } else {
    return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
  }
}

export function normalizeHeight(size: number, screenHeight: number) : number {
  let scale = (screenHeight ?? height) / canonicalHeight;
  scale = Math.max(scale, minHeightScale);
  scale = Math.min(scale, maxHeightScale);
  const newSize = size * scale;
  if (Platform.OS === 'ios') {
    return Math.round(PixelRatio.roundToNearestPixel(newSize));
  } else {
    return Math.round(PixelRatio.roundToNearestPixel(newSize)) - 2;
  }
}