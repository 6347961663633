/* eslint-disable react/display-name */
import React, { useContext, useEffect, useState } from 'react';
import { Image, ImageBackground, Platform, Pressable, View } from 'react-native';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import log from '../../business/logging/logger';
import { getUserFile } from '../../business/storage/userContent';
import * as FaceDetector from 'expo-face-detector';
import { getDownloadCacheDirectory, getUploadDirectory } from '../../constants/directories';
import { v4 as uuid } from 'uuid';
import * as FileSystem from 'expo-file-system'
import EmailPreviewModal2 from './EmailPreviewModal2';

const ConstrainedUserImage = React.memo((props: { imageKey: string, maxWidth: number, maxHeight?: number, fallbackUri?: string }): React.ReactElement => {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const { userService } = commonContext.services;
  const [imageUrl, setImageUrl] = useState('');
  const [viewHeight, setViewHeight] = useState(1);
  const [viewWidth, setViewWidth] = useState(1);
  const [imageWidth, setImageWidth] = useState(1);
  const [imageHeight, setImageHeight] = useState(1);
  const [scaledImageHeight, setScaledImageHeight] = useState(1);
  const [topOffset, setTopOffset] = useState(0);
  const [calculatedTopOffset, setCalculatedTopOffset] = useState(0);
  const [showZoomView, setShowZoomView] = useState(false);
  const [showImage, setShowImage] = useState(false);

  console.log(`RUNNING ConstrainedUserImage for image`);

  useEffect(() => {
    if (props.imageKey.length > 0 && commonContext.loggedInUser) {
      getUserFile(userService, props.imageKey, commonContext.loggedInUser.id)
        .then(url => {
          Image.getSize(url, (width, height) => {
            setImageUrl(url);
            setImageHeight(height);
            setImageWidth(width);
          });
        })
        .catch(ex => {
          log.error(ex);
        });
    }
  }, [props.imageKey, commonContext.loggedInUser?.id]);

  useEffect(() => {

    if (imageHeight > 1 && imageWidth > 1 && imageUrl.length > 0) {
      const ar = 16.0/9.0;
      const maxViewWidth = props.maxWidth;
      const vw = maxViewWidth;
      const vh = vw / ar;
      setViewHeight(vh);
      setViewWidth(vw);
      console.log(`view height: ${vh}, view width: ${vw}, image width: ${imageWidth}, image height: ${imageHeight}`)
      const r = imageWidth / vw;
      const sh = imageHeight / r;
      console.log(`scaled image height: ${sh}`);
      setScaledImageHeight(sh);

      const tOffset = 0 - ((sh - vh) / 3);
      setTopOffset(tOffset);

      const defaultDisplayTop = 0 - tOffset;
      const defaultDisplayBottom = defaultDisplayTop + vh;

      console.log(`initial top offset: ${tOffset}`);
      if (Platform.OS === 'ios' || Platform.OS === 'android') {
        // use FaceDetector to find the face and offset the image to center it

        // first, we need to download the remote image and save it to a local file
        // then we can use the local file to detect the face
        // this is because FaceDetector doesn't support remote images

        // download the remote image

        getDownloadCacheDirectory()
          .then(dir => {
            const newFileName = uuid();
            const finalDestination = `${dir}${newFileName}`;
            const startDt = Date.now();
            console.log(`starting to copy remote file to destination: ${finalDestination}`);
            console.log(`remote file: ${imageUrl}`);

            FileSystem.downloadAsync(imageUrl, finalDestination)
              .then(() => {
                const downloadEndDt = Date.now();
                console.log(`finished copying remote file to destination: ${finalDestination} in ${downloadEndDt - startDt} ms`);
                console.log(`detecting face for image: ${finalDestination}`);

                FaceDetector.detectFacesAsync(finalDestination, {
                  mode: FaceDetector.FaceDetectorMode.fast,
                  detectLandmarks: FaceDetector.FaceDetectorLandmarks.none,
                  runClassifications: FaceDetector.FaceDetectorClassifications.none,
                })
                  .then(({ faces }) => {
          
                    const endDt = Date.now();
                    console.log(`finished detecting faces for image: ${finalDestination} in ${endDt - downloadEndDt} ms`);
                    console.log(`found faces: ${JSON.stringify(faces, null, 2)}`);
          
                    if (faces.length > 0) {
                      console.log(`found a face: ${JSON.stringify(faces[0], null, 2)}`);
          
                      // find the face that occupies the most space in the image
                      let idx = 0;
                      let maxSizeSeen = 0;
                      for (let i = 0; i < faces.length; i++) {
                        const size = faces[i].bounds.size.width * faces[i].bounds.size.height;
                        if (size > maxSizeSeen) {
                          idx = i;
                          maxSizeSeen = size;
                        }
                      }

                      const face = faces[idx];
                      const faceTop = face.bounds.origin.y / r;
                      const faceBottom = (face.bounds.origin.y + face.bounds.size.height) / r;

                      // is the face within the displayTop and displayBottom?
                      // if so, we don't need to do anything
                      if (faceTop > defaultDisplayTop && faceBottom < defaultDisplayBottom) {
                        console.log('face is within display bounds, no need to adjust');
                        setCalculatedTopOffset(0);
                        setShowImage(true);
                        return;
                      } else {
                        console.log('face is not within display bounds, adjusting');
                        // if the face extends below the defaultDisplayBottom, we need to adjust the top offset so the bottom of the face is within the display bounds and the display area is still covered
                        if (faceBottom > defaultDisplayBottom) {
                          const faceOffset = faceBottom - defaultDisplayBottom + defaultDisplayTop;
                          const caclculatedOffset = 0 - faceOffset;
                          setCalculatedTopOffset(caclculatedOffset);
                          console.log(`top offset based on low face: ${caclculatedOffset}`)
                        } else if (faceTop < defaultDisplayTop) {
                          // if the face extends above the defaultDisplayTop, we need to adjust the top offset so the top of the face is within the display bounds and the display area is still covered
                          const faceOffset = Math.max(faceTop - (face.bounds.size.height * 0.2), 0); // seems like we need to add a little extra to the top, since the head is taller than the face
                          const caclculatedOffset = 0 - faceOffset;
                          setCalculatedTopOffset(caclculatedOffset);
                          console.log(`top offset based on high face: ${caclculatedOffset}`)
                        }
                      }
                    }

                    // finally show the image, now that we have the top offset
                    setShowImage(true);

                    FileSystem.deleteAsync(finalDestination)
                      .then(() => {
                        console.log(`deleted file: ${finalDestination}`);
                      })
                      .catch(ex => {
                        console.log(`error deleting file: ${finalDestination}`);
                        console.error(ex);
                      });
                  })
                  .catch(ex => {
                    console.log('error detecting face');
                    console.error(ex);
                    setShowImage(true);
                  });
              })
              .catch(ex => {
                console.log('error downloading image');
                console.error(ex);
                setShowImage(true);
              });
          });
      } else {
        setShowImage(true);
      }
    }

  }, [imageWidth, imageHeight, imageUrl, props.maxWidth, props.maxHeight]);

  return (
    <>
      {
        imageUrl && imageUrl.length > 0 && showImage &&
        <Pressable
          onPress={() => setShowZoomView(true)}
        >
        <ImageBackground
          source={{uri: imageUrl}}
          style={{
            height: viewHeight, // <-- you can adjust visible area
            width: viewWidth,  // <-- same here
            overflow: 'hidden',
            display: 'flex',
          }}
          imageStyle={{
            resizeMode: "stretch",
            height: scaledImageHeight,
            top: calculatedTopOffset != 0 ? calculatedTopOffset : topOffset,
          }}
        ></ImageBackground>
        </Pressable>
      }
      <EmailPreviewModal2 isVisible={showZoomView} onClose={() => setShowZoomView(false)} image={{ uri: imageUrl}} />
    </>
  );
});

export default ConstrainedUserImage;