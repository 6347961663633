import React, { useContext } from 'react'
import { Button, ButtonProps } from 'react-native-elements';
import CommonContext from '../../../CommonContext';
import { CommonContextType } from '../../../types/CommonContextType';

function AuthPageLink(props: ButtonProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  return (
    <Button
      containerStyle={{flex: 1, alignItems: 'center', padding: 10}}
      buttonStyle={{
        backgroundColor: themeFromContext.colors.background
      }}
      titleStyle={{
        color: themeFromContext.colors.primary,
        ...themeFromContext.textVariants.authSecondaryButton
      }}
      {...props}
    />
  )
}

export default AuthPageLink