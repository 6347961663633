// Frameworks
import React, { useContext } from 'react';
import { View } from 'react-native';

// Theme
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { Text } from 'react-native-elements';
import { StepProps } from '../../../types/Steps';
import NButton from '../../components/NButton';
import { PHONE_BREAK } from '../../../business/layout/layout';

export enum CommunityType {
  ME,
  OTHERS
}

function NewCommunityGetStarted(props: StepProps<CommunityType>): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const {width} = commonContext.dimensions;

  const chooseMe = () => {
    props.onPayloadChange(CommunityType.ME);
    props.onFinish();
  }

  const chooseOthers = () => {
    props.onPayloadChange(CommunityType.OTHERS);
    props.onFinish();
  }

  return (
    <>
      {
        props.step === props.currentStep &&
        <View style={{
          backgroundColor: themeFromContext.colors?.background,
          //height: '100%'
        }}>
          <Text
            style={{
              //paddingHorizontal: 60,
              textAlign: 'center',
              ...themeFromContext.textVariants.wizardBody
            }}
          >
            {`Every Novella is focused on one or two people you and your loved ones would like to know better.\n\nThis could be your parents, a sibling, a child, or a close friend.`}
          </Text>
          <Text
            style={{
              textAlign: 'center',
              //paddingHorizontal: 60,
              paddingVertical: 30,
              ...themeFromContext.textVariants.wizardSubHeader
            }}
          >
            {`Who is this Novella about?`}
          </Text>
          <View
            style={
              width > PHONE_BREAK ?
              {
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                justifyContent: 'space-evenly',
              } : {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }
            }
          >
            <NButton
              t="secondary"
              titleStyle={{
                fontSize: 14,
                fontWeight: '700',
              }}
              buttonStyle={{
                marginVertical: 10,
              }}
              containerStyle={{
                display: 'flex',
                flex: 1,
                marginHorizontal: 8,
              }}
              title={`It's about me!`}
              onPress={chooseMe}
              testID='create-community-get-started-about-me-button'
            />
            <NButton
              t="primary"
              titleStyle={{
                fontSize: 14,
                fontWeight: '700',
              }}
              buttonStyle={{
                marginVertical: 10,
              }}
              containerStyle={{
                display: 'flex',
                flex: 1,
                marginHorizontal: 8,
              }}
              title={`Someone else`}
              onPress={chooseOthers}
              testID='create-community-get-started-someone-else-button'
            />
          </View>
        </View>
      }
    </>
  );
}
export default NewCommunityGetStarted;