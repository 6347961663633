const branchio = require('branchio-sdk')

const client = branchio({ 
  key: 'key_live_pCfoExyX3ZU2ORBP1jp6XbikxvogNLpf', // this is not sensitive, and is intended to be publicly visible
  secret: 'XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX', // we don't want to put the real secret here, and we can do everything we need without it
});

export async function getBranchIODDL(urlRoot: string, path: string, question: string, asker: string): Promise<string> {
  const data = {
    '$og_title': `${asker} wants to know...`,
    '$og_description': question,
    '$og_image_url': `https://www.meetnovella.com/wp-content/uploads/2023/04/novella-profile-pic-385x385-1.png`,
    '$deeplink_path': `${path}`,
    '$canonical_url': `${urlRoot}${path}`,
    '$desktop_url': `${urlRoot}${path}`,
    '$ios_url': `${path}`,
    '$android_url': `${path}`,
  };
  const { url } = await client.link({ data: data });
  console.log(`created short branch url: ${url}`);
  console.log(data);

  return url;
}