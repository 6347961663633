import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function Message(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={97.851}
      height={100}
      viewBox="0 0 97.851 100"
      {...props}
    >
      <G data-name="Message Illustration">
        <Path
          data-name="Path 71382"
          d="M53.352 22a47.856 47.856 0 00-40.665 73.08l58.638 19.129A47.857 47.857 0 0053.352 22z"
          transform="translate(-5.5) translate(0 -17.703)"
          fill="#7fcece"
        />
        <Path
          data-name="Path 71383"
          d="M90.587 0H51.325a9.822 9.822 0 00-9.811 9.811v67.321a48.041 48.041 0 005.673 7.488l46.89 13.924a47.564 47.564 0 006.318-2.074V9.811A9.822 9.822 0 0090.587 0z"
          transform="translate(-5.5) translate(-28.98)"
          fill="#0052a9"
        />
        <Path
          data-name="Path 71384"
          d="M57.1 0h-5.775a9.822 9.822 0 00-9.811 9.811v67.321A48.089 48.089 0 0058.584 93.7L55.772 6.646S52.555 2.756 57.1 0z"
          transform="translate(-5.5) translate(-28.98)"
          fill="#003370"
        />
        <Path
          data-name="Path 71385"
          d="M112.925 27.76h-1.582a3.834 3.834 0 00-3.4 1.979 3.294 3.294 0 01-2.9 1.751H81.549a3.293 3.293 0 01-2.9-1.751 3.835 3.835 0 00-3.405-1.979h-1.582a4.389 4.389 0 00-4.389 4.389v74.539a47.921 47.921 0 0047.5 14.1 4.369 4.369 0 00.542-2.113V32.149a4.389 4.389 0 00-4.389-4.389z"
          transform="translate(-5.5) translate(-51.317 -22.338)"
          fill="#ebf1f7"
        />
        <Path
          data-name="Path 71386"
          d="M73.663 27.76a4.389 4.389 0 00-4.389 4.389v74.539a48.113 48.113 0 007.486 6.669V32.149a4.375 4.375 0 011.35-3.165 3.914 3.914 0 00-2.864-1.224z"
          transform="translate(-5.5) translate(-51.318 -22.338)"
          fill="#cee3f4"
        />
        <Path
          data-name="Path 71387"
          d="M146.411 19.141a1.507 1.507 0 111.506-1.506 1.508 1.508 0 01-1.506 1.506z"
          transform="translate(-5.5) translate(-112.177 -12.978)"
          fill="#0065c6"
        />
        <Path
          data-name="Path 71388"
          d="M180.816 19.143h-8.7a1.506 1.506 0 110-3.012h8.7a1.506 1.506 0 010 3.012z"
          transform="translate(-5.5) translate(-132.86 -12.98)"
          fill="#0065c6"
        />
        <Path
          data-name="Path 71389"
          d="M178.251 65.223h-2.912a1.506 1.506 0 010-3.012h2.912a1.506 1.506 0 110 3.012z"
          transform="translate(-5.5) translate(-135.455 -50.06)"
          fill="#b0d4ea"
        />
        <Path
          data-name="Path 71390"
          d="M69.274 94.17h48.04v16.436h-48.04z"
          transform="translate(-5.5) translate(-51.318 -75.777)"
          fill="#cee3f4"
        />
        <Path
          data-name="Path 71391"
          d="M69.274 94.17h7.486v16.436h-7.486z"
          transform="translate(-5.5) translate(-51.318 -75.777)"
          fill="#b0d4ea"
        />
        <Path
          data-name="Path 71392"
          d="M69.274 262.472h48.04v16.436h-48.04z"
          transform="translate(-5.5) translate(-51.318 -211.208)"
          fill="#cee3f4"
        />
        <Path
          data-name="Path 71393"
          d="M69.274 262.472h7.486v16.436h-7.486z"
          transform="translate(-5.5) translate(-51.318 -211.208)"
          fill="#b0d4ea"
        />
        <Path
          data-name="Path 71394"
          d="M69.274 430.988a47.921 47.921 0 0047.5 14.1 4.369 4.369 0 00.542-2.113v-12.201H69.274z"
          transform="translate(-5.5) translate(-51.318 -346.638)"
          fill="#cee3f4"
        />
        <Path
          data-name="Path 71395"
          d="M76.76 437.657v-6.883h-7.486v.214a48.318 48.318 0 007.486 6.669z"
          transform="translate(-5.5) translate(-51.318 -346.638)"
          fill="#b0d4ea"
        />
        <Path
          data-name="Path 71396"
          d="M96.4 112.379a4.661 4.661 0 104.661 4.662 4.675 4.675 0 00-4.661-4.662z"
          transform="translate(-5.5) translate(-69.399 -90.43)"
          fill="#6edb9a"
        />
        <Path
          data-name="Path 71397"
          d="M180.271 131.546h-21.554a1.506 1.506 0 010-3.012h21.554a1.506 1.506 0 110 3.012z"
          transform="translate(-5.5) translate(-122.08 -103.43)"
          fill="#b0d4ea"
        />
        <Path
          data-name="Path 71398"
          d="M96.4 196.53a4.661 4.661 0 104.661 4.662 4.675 4.675 0 00-4.661-4.662z"
          transform="translate(-5.5) translate(-69.399 -158.145)"
          fill="#fce453"
        />
        <Path
          data-name="Path 71399"
          d="M180.271 215.7h-21.554a1.506 1.506 0 010-3.012h21.554a1.506 1.506 0 110 3.012z"
          transform="translate(-5.5) translate(-122.08 -171.146)"
          fill="#cee3f4"
        />
        <Path
          data-name="Path 71400"
          d="M96.4 280.681a4.661 4.661 0 104.661 4.661 4.675 4.675 0 00-4.661-4.661z"
          transform="translate(-5.5) translate(-69.399 -225.861)"
          fill="#e25ca5"
        />
        <Path
          data-name="Path 71401"
          d="M180.271 299.848h-21.554a1.506 1.506 0 010-3.012h21.554a1.506 1.506 0 110 3.012z"
          transform="translate(-5.5) translate(-122.08 -238.86)"
          fill="#b0d4ea"
        />
        <Path
          data-name="Path 71402"
          d="M96.4 364.832a4.661 4.661 0 104.661 4.661 4.675 4.675 0 00-4.661-4.661z"
          transform="translate(-5.5) translate(-69.399 -293.576)"
          fill="#33bafe"
        />
        <Path
          data-name="Path 71403"
          d="M99.869 119.773a4.675 4.675 0 01-4.661-4.661 4.626 4.626 0 01.836-2.654 4.66 4.66 0 104.187 7.3c-.12.009-.24.015-.362.015z"
          transform="translate(-5.5) translate(-69.399 -90.494)"
          fill="#71c994"
        />
        <Path
          data-name="Path 71404"
          d="M99.869 203.925a4.675 4.675 0 01-4.661-4.661 4.626 4.626 0 01.836-2.654 4.66 4.66 0 104.187 7.3c-.12.009-.24.015-.362.015z"
          transform="translate(-5.5) translate(-69.399 -158.21)"
          fill="#ffc533"
        />
        <Path
          data-name="Path 71405"
          d="M99.869 288.076a4.675 4.675 0 01-4.661-4.661 4.625 4.625 0 01.836-2.654 4.66 4.66 0 104.187 7.3c-.12.009-.24.015-.362.015z"
          transform="translate(-5.5) translate(-69.399 -225.925)"
          fill="#dd3f95"
        />
        <Path
          data-name="Path 71406"
          d="M99.869 372.226a4.675 4.675 0 01-4.661-4.661 4.626 4.626 0 01.836-2.654 4.66 4.66 0 104.187 7.3c-.12.009-.24.015-.362.015z"
          transform="translate(-5.5) translate(-69.399 -293.639)"
          fill="#00a8fd"
        />
        <Path
          data-name="Path 71407"
          d="M180.271 384h-21.554a1.506 1.506 0 010-3.012h21.554a1.506 1.506 0 110 3.012z"
          transform="translate(-5.5) translate(-122.08 -306.576)"
          fill="#cee3f4"
        />
        <Path
          data-name="Path 71408"
          d="M100.641 448.983a4.658 4.658 0 00-3.633 1.751 47.908 47.908 0 007.75 5.088 4.619 4.619 0 00.544-2.177 4.675 4.675 0 00-4.661-4.662z"
          transform="translate(-5.5) translate(-73.635 -361.291)"
          fill="#0052a9"
        />
        <Path
          data-name="Path 71409"
          d="M180.271 468.15h-21.554a1.506 1.506 0 110-3.012h21.554a1.506 1.506 0 110 3.012z"
          transform="translate(-5.5) translate(-122.08 -374.291)"
          fill="#b0d4ea"
        />
        <Path
          data-name="Path 71410"
          d="M247.556 15.648h-17.53a20.267 20.267 0 00-20.208 20.208 20.267 20.267 0 0020.208 20.208h6.27a2.663 2.663 0 012.663 2.663v3.7a.572.572 0 00.985.4l5.689-5.935a2.664 2.664 0 011.923-.821 20.208 20.208 0 000-40.416z"
          transform="translate(-5.5) translate(-164.412 -12.592)"
          fill="#e25ca5"
        />
        <Path
          data-name="Path 71411"
          d="M233.126 15.648h-3.1a20.267 20.267 0 00-20.208 20.208 20.267 20.267 0 0020.208 20.208h6.27c-8.137-4.113-2.951-10.543-2.951-10.543V27.069c-6.09-6.09-.22-11.421-.22-11.421z"
          transform="translate(-5.5) translate(-164.413 -12.592)"
          fill="#dd3f95"
        />
        <Path
          data-name="Path 71412"
          d="M314.3 74.122h-22.939a1.968 1.968 0 00-1.962 1.962v14.528a1.968 1.968 0 001.962 1.962H314.3a1.968 1.968 0 001.962-1.962V76.084a1.968 1.968 0 00-1.962-1.962z"
          transform="translate(-5.5) translate(-228.45 -59.645)"
          fill="#f9a52b"
        />
        <Path
          data-name="Path 71413"
          d="M295.421 90.612V76.084a1.968 1.968 0 011.962-1.962h-6.022a1.968 1.968 0 00-1.962 1.962v14.528a1.968 1.968 0 001.962 1.962h6.022a1.968 1.968 0 01-1.962-1.962z"
          transform="translate(-5.5) translate(-228.45 -59.645)"
          fill="#f78c2d"
        />
        <Path
          data-name="Path 71414"
          d="M308.2 121.53a3.61 3.61 0 01-4.935 0l-.778-.729-9.479 8.875a1.95 1.95 0 001.258.46H317.2a1.951 1.951 0 001.258-.46l-9.479-8.875z"
          transform="translate(-5.5) translate(-231.353 -97.207)"
          fill="#ffc533"
        />
        <Path
          data-name="Path 71415"
          d="M300.284 146.19a1.968 1.968 0 01-1.962-1.962v-3.477L293 145.73a1.978 1.978 0 001.255.46h6.024z"
          transform="translate(-5.5) translate(-231.352 -113.261)"
          fill="#f9a52b"
        />
        <Path
          data-name="Path 71416"
          d="M317.2 74.122h-22.937a1.951 1.951 0 00-1.258.46l10.258 9.6a3.61 3.61 0 004.935 0l10.258-9.6a1.95 1.95 0 00-1.256-.46z"
          transform="translate(-5.5) translate(-231.352 -59.645)"
          fill="#fce453"
        />
        <Path
          data-name="Path 71417"
          d="M293.005 74.582l5.317 4.979v-3.477a1.968 1.968 0 011.962-1.962h-6.022a1.977 1.977 0 00-1.257.46z"
          transform="translate(-5.5) translate(-231.352 -59.645)"
          fill="#ffc533"
        />
        <Path
          data-name="Path 71418"
          d="M99.445 450.927a4.626 4.626 0 01.216-1.4 4.679 4.679 0 00-2.652 1.645q1.581 1.272 3.271 2.408a4.625 4.625 0 01-.835-2.653z"
          transform="translate(-5.5) translate(-73.636 -361.729)"
          fill="#003370"
        />
      </G>
    </Svg>
  )
}

export default Message
