import React, { useContext, useEffect } from 'react'
import { View } from 'react-native'
import { RootStackParamList } from '../../../../types/Navigation';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import { Text } from 'react-native-elements';
import { CommonContextType } from '../../../../types/CommonContextType';
import CommonContext from '../../../../CommonContext';
import NButton from '../../../components/NButton';
import { Palette } from '../../../../Theme';
import { DrawerScreenProps } from '@react-navigation/drawer';
import GetStartedPage from './GetStartedPage';

export type GettingStartedProps = DrawerScreenProps<RootStackParamList, 'Getting Started'>;

function GettingStarted(props: GettingStartedProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const linkTo = useLinkTo();

  useEffect(() => {
    if (commonContext.loggedInUser) {
      props.navigation.navigate('Home');
    }
  }, [commonContext.loggedInUser]);

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      // analytics
      commonContext.analytics?.viewPage('Getting Started', {});
    }
  }, [isFocused]);

  function goToFast1p1q(): void {
    linkTo(`/fpq`);
  }

  function goToFast1p1qSelf(): void {
    linkTo(`/fpq/self`);
  }

  function goToOther(): void {
    linkTo(`/other`);
  }

  function goToSignUp(): void {
    linkTo(`/signup`);
  }

  return (
    <GetStartedPage navigation={props.navigation}>
      <Text
        style={{
          color: Palette.white,
          marginTop: -10,
          marginBottom: 20,
          ...themeFromContext.textVariants.askQuestionHeader,
        }}
      >
        {`Getting started with Novella`}
      </Text>
      <Text
        style={{
          color: Palette.white,
          marginTop: -10,
          marginBottom: 20,
          ...themeFromContext.textVariants.body,
          fontSize: 16,
        }}
      >
        {`What would you like to do?`}
      </Text>
      <View
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: 260,
          alignItems: 'center',
          //flex: 1,
        }}
      >
        <View
          style={{
            display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
            width: '100%',
          }}
        >
        <NButton
          t={'action'}
          title={`Invite someone to record a Novella`}
          // titleStyle={{
          //   textTransform: 'uppercase',
          // }}
          style={{
            minWidth: '100%',
            //paddingVertical: 10,
            alignContent: 'center',
            alignSelf: 'center',
            alignItems: 'center',
          }}
          buttonStyle={{
            maxWidth: '75%',
            minWidth: '75%',
            paddingVertical: 10,
          }}
          onPress={goToFast1p1q}
        />
        <NButton
          t={'primary2'}
          title={`Start recording my own Novella`}
          // titleStyle={{
          //   textTransform: 'uppercase',
          // }}
          style={{
            minWidth: '100%',
            //paddingVertical: 10,
            alignContent: 'center',
            alignSelf: 'center',
            alignItems: 'center',
          }}
          buttonStyle={{
            maxWidth: '75%',
            minWidth: '75%',
            paddingVertical: 10,
          }}
          onPress={goToFast1p1qSelf}
        />

        <NButton
          t={'secondary2'}
          title={`I was invited to Novella`}
          // titleStyle={{
          //   textTransform: 'uppercase',
          // }}
          style={{
            minWidth: '100%',
            //paddingVertical: 10,
            alignContent: 'center',
            alignSelf: 'center',
            alignItems: 'center',
          }}
          buttonStyle={{
            maxWidth: '75%',
            minWidth: '75%',
            paddingVertical: 10,
          }}
          onPress={goToSignUp}
        />
        </View>
        {/* <View
          style={{
            display: 'flex',
            // justifyContent: 'center',
            // alignItems: 'center',
            width: '100%',
          }}
        >
          <NButton
            t={'secondary2'}
            title={`See other options...`}
            // titleStyle={{
            //   textTransform: 'uppercase',
            // }}
            style={{
              minWidth: '100%',
              paddingVertical: 10,
              alignContent: 'center',
              alignSelf: 'center',
              alignItems: 'center',
            }}
            buttonStyle={{
              maxWidth: '75%',
              minWidth: '75%',
              paddingVertical: 10,
            }}
            onPress={goToOther}
          />
        </View> */}
      </View>
    </GetStartedPage>
  )
}

export default GettingStarted;
