// Frameworks
import React, { useContext } from 'react';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { Button, Icon } from 'react-native-elements';
import { Image, ImageURISource, View } from 'react-native';
import NText from './NText';
import ImageZoom from 'react-native-image-pan-zoom';
import { isMobileWeb } from '../../business/env/detectMobile';
import i18n from 'i18n-js';
import NModal from './Modals/NModal';
import { isMobile } from 'react-device-detect';

function EmailPreviewModal2(props: { isVisible: boolean, image: ImageURISource, onClose: () => void }): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { width, height } = commonContext.dimensions;

  return (
    <NModal
      isVisible={props.isVisible}
      maxWidth={width}
      overlayStyle={{
        backgroundColor: "#00000000",
        borderColor: "#00000000",
        shadowColor: "#00000000",
        height: height,
        display: 'flex',
        justifyContent: 'center',
        bottom: 0,
        padding: 0,
        transform: [],
      }}
      name={'Waiting'}
      scrollDisabled={true}
    //type={'island'}
    >
      <ImageZoom
        cropWidth={width} // this must correspond to the width of the modal overlay, even though it is transparent
        cropHeight={height}
        imageWidth={width * 0.8}
        imageHeight={height * 0.8}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          //width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <View
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '90%',
            width: '100%',
            //padding: 4,
          }}
        >
          <Button
            title={'Close'}
            titleStyle={{
              fontWeight: '700',
              color: 'white',
            }}
            containerStyle={{
              borderWidth: 0,
              borderRadius: 0,
            }}
            style={{
              marginBottom: 10,
            }}
            buttonStyle={{
              backgroundColor: themeFromContext.colors.transparent,
              borderWidth: 4,
              borderRadius: 14,
              borderColor: 'white',
            }}
            icon={
              <Icon
                iconStyle={{
                  marginEnd: 2
                }}
                size={24}
                name={'close-circle-outline'}
                type='ionicon'
                color={themeFromContext.colors.background}
              />
            }
            iconPosition={'left'}
            onPress={props.onClose}
          />
          {
            !isMobileWeb() && isMobile &&
            <NText
              style={[
                {
                  ...themeFromContext.textVariants.body,
                  paddingHorizontal: 14,
                },
                {
                  color: 'white',
                  marginBottom: 10,
                }
              ]}
            >
              {i18n.t('OnePersonOneQuestion_EmailPreview_ZoomMessage')}
            </NText>
          }
          <Image
            source={props.image}
            defaultSource={props.image}
            resizeMethod='scale'
            resizeMode='contain'
            style={{
              width: '100%',//normalizeHeight(343, height),
              height: '100%',
              //marginTop: normalizeHeight(20, height),
              //marginVertical: normalizeHeight(30, height),
            }}
            testID={`email-preview-modal`}
          />
        </View>
      </ImageZoom>
    </NModal>
  );
}
export default EmailPreviewModal2;