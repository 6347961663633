import React, { useContext, useEffect, useState } from 'react';
import { ListRenderItem, Platform, Text, View } from 'react-native';
import CommonContext from '../../../CommonContext';
import { CommonContextType } from '../../../types/CommonContextType';
import { RootStackParamList } from '../../../types/Navigation';
import { useIsFocused } from '@react-navigation/native';
import { normalizeHeight } from '../../../business/layout/responseSize';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import ScrollableFlatListScreen from '../../components/Layouts/ScrollableFlatListScreen';
import { AnswerUploadManager } from '../../../business/upload/AnswerUploadManager';
import UploadLineItemSmall from '../../components/UploadLineItemSmall';
import { Provider } from 'react-native-paper';
import { Palette } from '../../../Theme';
import I18n from 'i18n-js';

const HELP_NAME = 'REFERRAL_PROMPT';

type UploadsProps = NativeStackScreenProps<RootStackParamList, 'Uploads'>;

function UploadsScreen(props: UploadsProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { height } = commonContext.dimensions;
  const { route, navigation } = props;
  const [refreshing, setRefreshing] = useState(true);
  const isFocused = useIsFocused();
  const [pendingUploadIds, setPendingUploadIds] = useState<string[]>([]);

  useEffect(() => {
    if (isFocused) {
      const ups = AnswerUploadManager.getAll();
      setPendingUploadIds(Object.values(ups).map(u => u.id));
      setRefreshing(false);
    }
  }, [isFocused]);

  const removePendingUpload = (id: string): void => {
    const idx = pendingUploadIds.findIndex(p => p == id);
    pendingUploadIds.splice(idx, 1);
    setPendingUploadIds(pendingUploadIds);
    //setRefresh(!refresh);
  }

  const renderUploadLineItem: ListRenderItem<string> = ({ item }) => (
    <UploadLineItemSmall key={item} id={item} onRemove={removePendingUpload} showQuestion={true} isFocus={isFocused} />
  );

  return (
    <Provider>
      <ScrollableFlatListScreen
        navigation={navigation}
        goBack={navigation.goBack}
        style={{
          display: 'flex',
          width: '100%',
        }}
        refreshing={refreshing}
        data={pendingUploadIds}
        renderItem={renderUploadLineItem}
        keyExtractor={(item) => { return `up-puli-${item}` }}
        topTitle={route.name}
        isFocused={isFocused}
        ListHeaderComponent={() => (
          <View
            style={{padding: 4}}
          >
            {
              Platform.OS == 'web' &&
              <>
                <Text
                  style={{
                    //width: '100%',
                    textAlign: 'center',
                    //fontStyle: 'italic',
                    //fontWeight: '400',
                    fontSize: 14,
                    color: Palette.white,
                    fontStyle:'italic',
                    backgroundColor: Palette.red.concat('99'),
                    paddingHorizontal: 20,
                    paddingVertical: 6,
                    marginBottom: 6,
                    borderColor: Palette.red,
                    borderWidth: 1,
                    borderRadius: 4,
                    // overflow: 'hidden',
                  }}
                >
                  {I18n.t(`AnswersPage_PendingUploads_RefreshWarning`)}
                </Text>
                <Text
                  style={{
                    //width: '100%',
                    textAlign: 'center',
                    //fontStyle: 'italic',
                    //fontWeight: '400',
                    fontSize: 14,
                    color: Palette.white,
                    fontStyle:'italic',
                    backgroundColor: Palette.yellow.concat('99'),
                    paddingHorizontal: 20,
                    paddingVertical: 6,
                    marginBottom: 6,
                    borderColor: Palette.yellow,
                    borderWidth: 1,
                    borderRadius: 4,
                    // overflow: 'hidden',
                  }}
                >
                  {I18n.t(`AnswersPage_PendingUploads_RefreshWarning2`)}
                </Text>
              </>
            }
          </View>
        )}
        ListEmptyComponent={() => (
          <View style={{
            alignItems: 'center',
            //height: '100%',
            justifyContent: 'center',
            padding: normalizeHeight(30, height),
            paddingBottom: normalizeHeight(100, height),
          }}>
            <Text
              style={{
                ...themeFromContext.textVariants.body,
                fontSize: 18,
                fontStyle: 'italic',
                fontWeight: '400',
                color: Palette.white,
                textAlign: 'center',
                marginVertical: themeFromContext.spacing.m,
              }}
              testID='uploads-empty-list-heading'
            >
              {`No pending uploads at this time.`}
            </Text>
            {/* <Text
            style={{
              ...themeFromContext.textVariants.body,
              color: Palette.grey,
              textAlign: 'center',
              marginVertical: themeFromContext.spacing.s
            }}
          >
            {`Please use the button below to create your first community and invite others to join.`}
          </Text> */}
          </View>
        )}
        ListFooterComponent={() => (
          <View style={{
            minHeight: normalizeHeight(160, height),
            alignItems: 'center',
            paddingVertical: themeFromContext.spacing.m
          }} />)
        }
      >
      </ScrollableFlatListScreen>
    </Provider>
  );
}

export default UploadsScreen;