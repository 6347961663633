import React from 'react';
import { View, ViewProps } from 'react-native';
import { Palette } from '../../../Theme';

function BottomTabButtonBadge(props: ViewProps): React.ReactElement {
  return (
    <View
      {...props}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: 4,
        alignSelf: 'center',
        borderRadius: 50,
        overflow: 'hidden',
        backgroundColor: Palette.pink,
        width: 10,
        height: 10,
        marginLeft: 30,
        marginBottom: 30,
        position: 'absolute',
      }}
    >
    </View>
  );
}
export default BottomTabButtonBadge;