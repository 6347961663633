// Frameworks
import React, { useContext } from 'react';

// Local components
import Card from './Card';
import MemberList from './MemberList';

// Navigation
import { useLinkTo } from '@react-navigation/native';
import { Community, CommunityUser } from '../../../API';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { Palette } from '../../../Theme';
import log from '../../../business/logging/logger';
import { View } from 'react-native';

function CommunityListItem(props: { /* role: CommunityUserStatus, */ community: Community, members: CommunityUser[], index: number, nextSteps?: number }) : React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const linkTo = useLinkTo();
  const tap = () => {
    try{
      log.info(`/community/${props.community.id}/activity/${props.community.id}`);
      linkTo(`/community/${props.community.id}/activity/${props.community.id}`);
    } catch (ex) {
      log.info(JSON.stringify(ex, null, 2));
    }
  }

  return (
    <Card
      handleTap={tap}
      colorChoice={1}
      margin='s'
      title={props.community.name}
      badge={props.nextSteps?.toString()}
      testID={`community-card-${props.index}`}
    >
      <View
        style={{
          minHeight: 60 * 17/16,
        }}
        pointerEvents={'none'}
      >
      <MemberList
        show={20}
        members={props.members}
        size={60}
        color={Palette.lightgrey}
        backgroundColor={themeFromContext.colors.background}
        borderColor={themeFromContext.colors.background}
        textColor={Palette.grey}
        extraColor={'#777'}
        extraTextColor={'#FFF'} />
      </View>
    </Card>
  );
}
export default CommunityListItem;