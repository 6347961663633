// Frameworks
import React, { useContext } from 'react';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { Platform, Text, View } from 'react-native';
import { Palette } from '../../Theme';
import { CommunityQuestion } from '../../API';
import { Button, Icon } from 'react-native-elements';

function WatchAnswersButton(props: { question: CommunityQuestion, clickWatchLink: (question: CommunityQuestion) => Promise<void>, visibleAnswerCount: number }): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { question, clickWatchLink, visibleAnswerCount } = props;

  return (
    <View
      style={{
        display: visibleAnswerCount > 0 ? 'flex' : 'none',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Button
        title={`Watch ${visibleAnswerCount}`}
        titleStyle={{
          color: Palette.plum,
          ...themeFromContext.textVariants.cardBottomButton,
          fontWeight: '700',
          paddingStart: 2,
        }}
        buttonStyle={{
          padding: 0,
          paddingRight: 6,
          borderWidth: 1,
          borderRadius: 100,
          paddingLeft: 3,
          borderColor: Palette.lightpurple.concat('66'),
          marginHorizontal: 2,
        }}
        onPress={async () => {
          await clickWatchLink(question);
        }}
        type='clear'
        icon={
          <Icon
            size={themeFromContext.textVariants.header.fontSize}
            name={'play-circle'}
            type='ionicon'
            color={'#91CCCE'}
          />}
        testID={`watch-answers-button-${question.id}`}
      />
      {/* <View
        style={{
          display: 'flex',
          alignContent: 'center',
          justifyContent: 'center',
          borderRadius: 100,
          overflow: 'hidden',
          backgroundColor: Palette.lightgrey,
          minWidth: themeFromContext.textVariants.header.fontSize,
          height: themeFromContext.textVariants.header.fontSize,
          padding: Platform.OS !== 'web' ? 0 : 8,
        }}
      >
        <Text
          style={{
            textAlign: 'center',
            textAlignVertical: 'center',
            color: themeFromContext.colors.text,
            ...themeFromContext.textVariants.cardBottomButton,
            fontSize: 16,
          }}
          testID={`answers-button-count-${question.id}`}
        >
          {visibleAnswerCount}
        </Text>
      </View> */}
    </View>
  );
}
export default WatchAnswersButton;