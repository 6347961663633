/* eslint-disable no-case-declarations */
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Hub, Cache, Auth } from 'aws-amplify';
import { Linking, Platform } from 'react-native';
//import { Linking } from 'react-native';
import { ICommunityService } from '../../contracts/ICommunityService';
import { IUserService } from '../../contracts/IUserService';
import AnalyticsWrapper from '../analytics/analyticsWrapper';
import { recordAuthEvent } from '../analytics/authEventRecorder';
import { exchangeCodeForTokens, getReturnUrl, isGoogleCallback } from '../auth/googleHttp';
import { removeLocalItem } from '../cache/localForageHelper';
import { getRootDomain } from '../env/currentEnv';
import log from '../logging/logger';
import { autoJoin, getAutoJoin } from '../public/autoJoin';
import { getParameterByName } from '../querystring/parseQueryString';
import { setBypass } from '../redirect/bypass';
import { getSignInRedirect, removeSignInRedirect } from '../redirect/redirect';
import LocalForageWrapper from '../storage/localForageWrapper';
import { rememberUser } from './userMemory';

//const logger = new Logger('My-Logger');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function configureListener(userService: IUserService, communityService: ICommunityService, analytics: AnalyticsWrapper, setAuthState: (authState: string) => void) : (data: any) => void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const listener = async (data : any) => {
        // log.info(`hub listener auth:`);
        // log.info(JSON.stringify(data, null, 2));
        switch (data.payload.event) {
            case 'parsingCallbackUrl':
                const url: string = data.payload.data.url;
                if (url != undefined && url != null) {
                    if (url.toLowerCase().includes('linked_external_user_facebook')) {
                        log.info(`re-running facebook sign in`);
                        await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Facebook});
                    } else if (url.toLowerCase().includes('linked_external_user_google')) {
                        log.info(`re-running google sign in`);
                        await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google});
                    } else if (url.toLowerCase().includes('linked_external_user_signinwithapple')) {
                        log.info(`re-running apple sign in`);
                        await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple});
                    } else if (url.toLowerCase().includes('code=')) {
                        log.info(`FULL: ${url}`);

                        const state = getParameterByName('state', url);
                        if (state) {
                            log.info(`STATE: ${state}`);
                            const decodedState = decodeURI(state);
                            log.info(`DECODED STATE: ${decodedState}`);
                            if (isGoogleCallback(decodedState)) {

                                log.info(`parsing authorization code`);
                                const code = getParameterByName('code', url);
                                log.info(`AUTHORIZATION CODE: ${code}`);
                                const returnUrl = getReturnUrl(decodedState);
                                log.info(`WILL RETURN TO: ${returnUrl}`);

                                if (code) {
                                    log.info(`TRYING TO GET TOKEN`);
                                    // exchange code for tokens
                                    const tokens = await exchangeCodeForTokens(code);

                                    if (tokens) {
                                        if (!tokens.refreshToken) {
                                            log.warn(`Google tokens are missing refresh token, user needs to de-auth and re-auth.`);
                                            await userService.requestGoogleContactsPermissions(returnUrl, true);
                                        } else {
                                            await userService.createOrUpdateUserGoogleTokens(tokens);
                                            if (Platform.OS == 'web') {
                                                window.open(returnUrl , "_self");
                                            } else {
                                                console.log(`!!!!! trying to return to: ${returnUrl}`);
                                                // This redirects to a browser... :(
                                                // figure out a different way to navigate without using a URL... use a route instead...
                                                //Linking.openURL(returnUrl);
                                                //navigate(returnUrl);
                                                //Linking.openURL(`/create/7bc2cf90-1274-40f6-b6d1-d07cb18e4cc3`);
                                                const withScheme = convertToScheme(returnUrl);
                                                Linking.openURL(withScheme);
                                            }
                                        }
                                    } else {
                                        // something went wrong...
                                        log.warn(`thought we got an oauth2 event, but didn't get any tokens`);    
                                    }
                                } else {
                                    // something went wrong...
                                    log.warn(`thought we got an oauth2 event, but couldn't find a code!`);
                                }
                            }
                        }
                    }
                }
                break;
            case 'signIn':
                log.info('user signed in, clearing cache');
                Cache.clear();
                // await recordAuthEvent(communityService, analytics, data);
                rememberUser(data);
                const redirectPath = await getSignInRedirect();
                const communityId = await getAutoJoin();
                if (communityId) {
                    await autoJoin(userService, communityService, communityId, () => {
                        setBypass(true) // bypass welcome screens
                            .then(() => {
                                if (redirectPath) {
                                    removeSignInRedirect()
                                        .then(() => {
                                            setAuthState('signIn');
                                            if (Platform.OS == 'web') {
                                                window.open(redirectPath , "_self");
                                            } else {
                                                Linking.openURL(`com.novella.ios:/${redirectPath}`);
                                            }
                                        });
                                } else {
                                    setAuthState('signIn');
                                    if (Platform.OS == 'web') {
                                        window.open(`/community/${communityId}/questions/asked/${communityId}` , "_self");
                                    } else {
                                        Linking.openURL(`com.novella.ios://community/${communityId}/questions/asked/${communityId}`);
                                    }
                                }
                            });
                    });
                } else {
                    //updateAuth('mainNav');
                    if (redirectPath) {
                        removeSignInRedirect()
                            .then(() => {
                                if (Platform.OS == 'web') {
                                    window.open(redirectPath , "_self");
                                } else {
                                    setAuthState('signIn');
                                    Linking.openURL(`com.novella.ios:/${redirectPath}`);
                                }
                            });
                    } else {
                        if (Platform.OS == 'web') {
                            window.open(`/` , "_self");
                        } else {
                            //Linking.openURL(`live.novella.app://home`); //this doesn't work
                            setAuthState('signIn');
                        }
                    }
                }
                // do this last, don't make the rest wait for it...
                await recordAuthEvent(communityService, analytics, data);
                break;
            case 'signUp':
                Cache.clear();
                log.info('user signed up');
                await recordAuthEvent(communityService, analytics, data);
                //updateAuth('auth');
                break;
            case 'signOut':
                log.info('user signed out');
                Cache.clear();
                await recordAuthEvent(communityService, analytics, data);
                await removeLocalItem('joiningCommunity');
                await removeSignInRedirect();
                LocalForageWrapper.removeItem('loggedInUser');
                LocalForageWrapper.removeItem('googleTokens');
                //updateAuth('auth');

                setAuthState('signOut');
                if (Platform.OS == 'web') {
                    window.open(`/` , "_self");
                } else {
                    //Linking.openURL(`/`);
                }
                break;
            case 'signIn_failure':
                log.info('user sign in failed');
                log.info(JSON.stringify(data, null, 2));
                //await recordAuthEvent(communityService, analytics, data);
                //updateAuth('auth');
                break;
            case 'tokenRefresh':
                log.info('token refresh succeeded');
                //Linking.openURL(`/`); // go home
                break;
            case 'tokenRefresh_failure':
                Cache.clear();
                log.warn('token refresh failed');
                //updateAuth('auth');
                if (Platform.OS == 'web') {
                    window.open(`/signin` , "_self");
                } else {
                    setAuthState('signIn');
                }
                break;
            case 'configured':
                log.info('the Auth module is configured');
        }
    }
    return listener;
}

function convertToScheme(returnUrl: string): string {
    const root = getRootDomain();
    console.log(`root domain: ${root}`);
    let withScheme = returnUrl;
    if (returnUrl.startsWith(`https://${root}/`)) {
        const returnPath = returnUrl.replace(`https://${root}/`, '');
        console.log(`returnPath: ${returnPath}`);
        withScheme = `com.novella.ios://${returnPath}`;
        console.log(`withScheme: ${withScheme}`);
    }
    return withScheme;
}

export default function InitializeAuthListener(userService: IUserService, communityService: ICommunityService, analytics: AnalyticsWrapper, setAuthState: (authState: string) => void) : void {
  //log.info("init auth listener");
  const listener = configureListener(userService, communityService, analytics, setAuthState);
  Hub.listen('auth', listener);
}