// Frameworks
import React, { useContext, useState } from 'react';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { LayoutChangeEvent, Platform, View } from 'react-native';
import NButton from '../NButton';
import i18n from 'i18n-js';
import log from '../../../business/logging/logger';
import { Palette } from '../../../Theme';
import { Icon } from 'react-native-elements';

function PQButtons(props: { back?: () => void, next?: () => Promise<void>, nextType?: "next" | "submit" | "finish", validate?: () => number, react?: (n: number) => void, onLayout?: (event: LayoutChangeEvent) => void }) : React.ReactElement {
  
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const [submitting, setSubmitting] = useState(false);

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: 6,
        paddingBottom: Platform.OS == 'web' ? 16 : 0,
      }}
      onLayout={props.onLayout}
    >
      <NButton
        t={'secondary'}
        title={i18n.t('OnePersonOneQuestion_Back_BtnText')}
        hidden={props.back == undefined}
        onPress={props.back}
        icon={
          <Icon
            name='arrow-back'
            type='ionicons'
            color={Palette.plum}
            size={20}
            style={{
              marginRight: 4,
            }}
          />
        }
      />
     <NButton
        t={'primary'}
        title={props.nextType == "submit" ? i18n.t('OnePersonOneQuestion_Submit') : props.nextType == 'finish' ? i18n.t('FastOnePersonOneQuestion_Finish_SelfMode') : i18n.t('OnePersonOneQuestion_Next_BtnText')}
        hidden={props.next == undefined && (props.nextType == undefined || props.nextType.length == 0)}
        loading={submitting}
        disabled={props.next == undefined || submitting}
        iconPosition={props.nextType == "submit" ? 'left' : 'right'}
        icon={
          props.nextType == "submit" ?
          <Icon
            name='send'
            type='ionicons'
            color={Palette.plum}
            size={20}
            style={{
              marginRight: 4,
            }}
          />
          : props.nextType == 'finish' ? undefined :
          <Icon
            name='arrow-forward'
            type='ionicons'
            color={props.next == undefined ? Palette.grey : Palette.plum}
            size={20}
            style={{
              marginLeft: 4,
            }}
          />
        }
        onPress={!props.next ? undefined : async () => {
          if (!props.validate) {
              if (props.nextType == 'submit' || props.nextType == 'finish') {
                setSubmitting(true);
                await props.next();
                setSubmitting(false);
              } else {
                await props.next();
              }
          } else {
              const validationResult = props.validate();
              if (validationResult == -1) {
                if (props.nextType == 'submit' || props.nextType == 'finish') {
                  setSubmitting(true);
                  await props.next();
                  setSubmitting(false);
                } else {
                  await props.next();
                }
              } else {
                  log.info(`trying to scroll to y value: ${validationResult}`);
                  if (props.react) {
                    props.react(validationResult);
                  }
              }
          }
      }}
     />
    </View>
  );
}
export default PQButtons;