export function arraysEqual<T>(a: T[], b: T[], ignoreOrder = false): boolean {
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;

  let a2 = a;
  let b2 = b;

  // If you don't care about the order of the elements inside
  // the array, you should sort both arrays here.
  // Please note that calling sort on an array will modify that array.
  // you might want to clone your array first.
  if (!ignoreOrder) {
    // clone the arrays
    a2 = [...a];
    b2 = [...b];
    a2.sort();
    b2.sort();
  }

  for (let i = 0; i < a2.length; ++i) {
    if (a2[i] !== b2[i]) return false;
  }
  return true;
}