import { ReactElement } from "react";

export const TOP_HEADER_BASE_HEIGHT = 40;
export const MIN_TOP_HEADER_HEIGHT = 33;
export const TOP_TITLE_BASE_HEIGHT = 30;
export const BOTTOM_TITLE_BASE_HEIGHT = 20;
export const WEBSITE_HEADER_HEIGHT = 70;
export const getSecondaryHeight = (bottomTitle: string | undefined) : number => {
  return (bottomTitle ? TOP_TITLE_BASE_HEIGHT + BOTTOM_TITLE_BASE_HEIGHT : TOP_TITLE_BASE_HEIGHT); }
export const getSecondaryHeight2 = (topTitle: string | ReactElement | undefined, bottomTitle: string | undefined) : number => {
  let ish = 0;
  if (topTitle) {
    ish += TOP_TITLE_BASE_HEIGHT;
  }
  if (bottomTitle) {
    ish += BOTTOM_TITLE_BASE_HEIGHT;
  }
  //ish = normalizeHeight(ish, windowHeight);
  return ish;
}