import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import log from '../logging/logger';
import { IAPIWrapper } from './APIWrapper';

export interface IFullListQuerier {
  queryFullList(name: string, query: string, variables?: any, authMode?: GRAPHQL_AUTH_MODE) : Promise<any[]>;
} 

export class FullListQuerier implements IFullListQuerier {
  api: IAPIWrapper;
  constructor(api: IAPIWrapper) {
    this.api = api;
  }
  async queryFullList(name: string, query: string, variables?: any, authMode?: GRAPHQL_AUTH_MODE) : Promise<any[]> {
    try {
      const usePageSize = true;
      const pageSize = 1000;
      const mode = authMode ? authMode : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS;

      let obj: any = {
        query: query,
        authMode: mode
      }

      if (variables) {

        if (usePageSize) {
          variables = {
            limit: pageSize,
            ...variables
          };
        }

        obj = {
          ...obj,
          variables: variables
        };
      }

      //console.time(name);
      const result: any = await this.api.graphql(obj);
      //log.info(result);
      let apiCalls = 1;

      let existing: any[] = result.data[name].items;
      let nextToken: string = result.data[name].nextToken;
      while (nextToken && nextToken !== '' && nextToken !== null) {
        const page: any = await this.api.graphql({
          query: query,
          variables: {
            ...variables,
            nextToken: nextToken
          },
          authMode: mode
        });
        //log.info(`again - ${name}`);
        apiCalls = apiCalls + 1;
        existing = existing.concat(page.data[name].items);
        nextToken = page.data[name].nextToken;
      }
      //console.timeEnd(name);
      //log.info(`${apiCalls}:\t${name}`);
      //log.info(`${existing.length} items returned`);
      return existing;
    } catch (ex) {
      log.info(`something bad happened in FullListQuerier ${name}`);
      log.info(ex.errors);
      throw ex;
      //return [];
    }
  }
}