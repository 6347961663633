import * as React from "react"
import Svg, { G, Path, Circle } from "react-native-svg"

function AddMember(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 95.703999 100.02825"
      height={100.02825}
      width={95.704002}
      {...props}
    >
      <G data-name="Add Member Illustration">
        <G data-name="Group 3672">
          <Path
            fill="#7fcecf"
            transform="translate(-10.998 .002) translate(11 4.297) translate(-11 -22)"
            d="M98.1 97.222a47.852 47.852 0 10-78.5 0z"
            data-name="Path 71307"
          />
        </G>
        <G data-name="Group 3673">
          <Circle
            fill="#61bcbd"
            r={38.816002}
            cy={38.816002}
            cx={38.816002}
            data-name="Ellipse 259"
            transform="translate(-10.998 .002) translate(19.957 8.435)"
          />
        </G>
        <G data-name="Group 3674">
          <Path
            fill="#af624a"
            transform="translate(-10.998 .002) translate(32.651 31.819) translate(-121.852 -162.915)"
            d="M167.117 162.915h-37.685c0 7.58-7.58 14.433-7.58 25.788a31.385 31.385 0 005.859 18.555h41.126A31.385 31.385 0 00174.7 188.7c0-11.352-7.583-18.205-7.583-25.785z"
            data-name="Path 71308"
          />
        </G>
        <G data-name="Group 3675">
          <Path
            fill="#9181f2"
            transform="translate(-10.998 .002) translate(19.602 61.44) translate(-55.043 -314.574)"
            d="M133.54 332.653c-1.8-4.954-3.719-9.244-5.35-10.684-4.573-4.038-24.138-7.311-24.643-7.4h-18.51c-.5.084-20.07 3.357-24.643 7.4-1.631 1.44-3.549 5.73-5.35 10.684A48.043 48.043 0 0074.815 349h2.141l2.141 1.668.035.011 7.432 1.828q2.059.335 4.174.49l4.245.128a48.011 48.011 0 008.724-.921l4.282-1.063a47.946 47.946 0 0025.555-18.486s-.004 0-.004-.002z"
            data-name="Path 71309"
          />
        </G>
        <G data-name="Group 3676">
          <Path
            fill="#816ae2"
            transform="translate(-10.998 .002) translate(19.602 63.518) translate(-55.044 -325.213)"
            d="M79.186 359.255l7.378 1.815c1.371.223 3.849.325 5.17.492 0 0-15.346-18.232-16.917-36.35-5.676 1.344-12.055 3.228-14.42 5.317-1.631 1.44-3.549 5.73-5.35 10.684a48.037 48.037 0 0019.778 16.344s4.329 1.687 4.361 1.698z"
            data-name="Path 71310"
          />
        </G>
        <G data-name="Group 3677">
          <Path
            fill="#816ae2"
            transform="translate(-10.998 .002) translate(46.697 61.44) translate(-193.771 -314.575)"
            d="M218.091 315.1c-1.69-.321-2.8-.506-2.911-.525h-18.51c-.115.019-1.216.2-2.9.523.39.52 4.907 6.331 12.112 6.331 7.279 0 11.895-5.915 12.209-6.329z"
            data-name="Path 71311"
          />
        </G>
        <G data-name="Group 3685">
          <Path
            fill="#feb98a"
            transform="translate(-10.998 .002) translate(49.596 43.112) translate(-208.614 -220.734)"
            d="M227.124 220.734h-18.51v18.328a13.822 13.822 0 009.213 3.924 14.157 14.157 0 009.3-3.924z"
            data-name="Path 71318"
          />
        </G>
        <G data-name="Group 3686">
          <Path
            fill="#fca870"
            transform="translate(-10.998 .002) translate(49.596 43.112) translate(-208.614 -220.734)"
            d="M208.614 220.734v18.328c18.5 0 18.51-10.43 18.51-10.43v-7.9h-18.51z"
            data-name="Path 71319"
          />
        </G>
        <G data-name="Group 3687">
          <Path
            fill="#ce846a"
            transform="translate(-10.998 .002) translate(38.537) translate(-151.991)"
            d="M188.517 7.114A20.836 20.836 0 00172.527 0c-6.689 0-9.567 1.1-12.26 2.344C157.574 3.588 157.394 9 157.394 9a20.46 20.46 0 00-5.4 13.881v5.874h41.071v-5.876c0-4.494-.682-11.406-4.546-15.766z"
            data-name="Path 71320"
          />
        </G>
        <G data-name="Group 3688">
          <Path
            fill="#c3785c"
            transform="translate(-10.998 .002) translate(38.537 8.999) translate(-151.991 -46.073)"
            d="M151.991 59.954v5.874h8.368V53.657c-2.1-1.373-3.085-4.71-2.965-7.584a20.46 20.46 0 00-5.403 13.881z"
            data-name="Path 71321"
          />
        </G>
        <G data-name="Group 3689">
          <Path
            fill="#feb98a"
            transform="translate(-10.998 .002) translate(72.012 27.201) translate(-323.382 -139.268)"
            d="M324.881 143.233s2.028-4.938 5.644-3.792-.087 11.848-7.143 12.523z"
            data-name="Path 71322"
          />
        </G>
        <G data-name="Group 3690">
          <Path
            fill="#fca870"
            transform="translate(-10.998 .002) translate(37.141 27.201) translate(-144.844 -139.268)"
            d="M151.895 143.233s-2.028-4.938-5.644-3.792.087 11.848 7.143 12.523z"
            data-name="Path 71323"
          />
        </G>
        <G data-name="Group 3701">
          <G data-name="Group 3693">
            <G data-name="Group 3692">
              <G data-name="Group 3691">
                <Path
                  fill="#e80054"
                  transform="translate(-10.998 .002) translate(27.981) translate(0 12.647) translate(-97.945 -64.751)"
                  d="M101.2 71.266a3.258 3.258 0 013.258 3.258 3.258 3.258 0 106.515 0v-9.773H101.2a3.258 3.258 0 000 6.515z"
                  data-name="Path 71324"
                />
              </G>
            </G>
          </G>
          <G data-name="Group 3694">
            <Path
              fill="#cc0053"
              transform="translate(-10.998 .002) translate(27.981) translate(3.258 12.647) translate(-114.625 -64.751)"
              d="M124.4 74.524v-9.773h-9.773c3.729 0 9.773 5.776 9.773 9.773z"
              data-name="Path 71325"
            />
          </G>
          <G data-name="Group 3697">
            <G data-name="Group 3696">
              <G data-name="Group 3695">
                <Path
                  fill="#ff4155"
                  transform="translate(-10.998 .002) translate(27.981) translate(12.647) translate(-162.697)"
                  d="M169.212 3.258a3.258 3.258 0 003.258 3.258 3.258 3.258 0 010 6.515h-9.77V3.258a3.258 3.258 0 016.515 0z"
                  data-name="Path 71326"
                />
              </G>
            </G>
          </G>
          <G data-name="Group 3698">
            <Path
              fill="#e80054"
              transform="translate(-10.998 .002) translate(27.981) translate(12.647 3.258) translate(-162.697 -16.679)"
              d="M162.7 16.679v9.773h9.773c-3.734 0-9.773-5.776-9.773-9.773z"
              data-name="Path 71327"
            />
          </G>
          <G data-name="Group 3699">
            <Path
              fill="#ff4155"
              transform="translate(-10.998 .002) translate(27.981) translate(8.748 8.747) translate(-142.732 -44.786)"
              d="M150.531 50.731l-1.854 1.854a1.311 1.311 0 01-1.854 0l-3.707-3.707a1.311 1.311 0 010-1.854l1.854-1.854a1.311 1.311 0 011.854 0l3.707 3.707a1.311 1.311 0 010 1.854z"
              data-name="Path 71328"
            />
          </G>
          <G data-name="Group 3700">
            <Path
              fill="#e80054"
              transform="translate(-10.998 .002) translate(27.981) translate(8.748 9.131) translate(-142.732 -46.752)"
              d="M148.677 52.313l-3.707-3.707a1.311 1.311 0 010-1.854l-1.854 1.854a1.311 1.311 0 000 1.854l3.707 3.707a1.311 1.311 0 001.854 0l1.854-1.854a1.311 1.311 0 01-1.854 0z"
              data-name="Path 71329"
            />
          </G>
        </G>
        <G data-name="Group 3702">
          <Path
            fill="#ffc9a4"
            transform="translate(-10.998 .002) translate(41.743 16.307) translate(-168.403 -83.491)"
            d="M173.566 83.767a6.167 6.167 0 00-3.991 4.466 38.7 38.7 0 00-1.172 7.614c0 12.6 7.66 27.374 17.109 27.374 9.449 0 17.109-14.775 17.109-27.374 0-.327-.006-.407-.017-.729-15.904 0-21.911-13.726-29.038-11.351z"
            data-name="Path 71330"
          />
        </G>
        <G data-name="Group 3703">
          <Path
            fill="#feb98a"
            transform="translate(-10.998 .002) translate(41.743 16.307) translate(-168.403 -83.493)"
            d="M189.418 122.374c-7.567-3.311-13.2-15.719-13.2-26.525a26.577 26.577 0 012.437-11.421 6.414 6.414 0 00-5.086-.659 6.166 6.166 0 00-3.991 4.466 38.705 38.705 0 00-1.172 7.614c0 12.6 7.66 27.374 17.109 27.374a9.693 9.693 0 003.903-.849z"
            data-name="Path 71331"
          />
        </G>
        <G data-name="Group 3705">
          <G data-name="Group 3704">
            <Path
              fill="#feb98a"
              transform="translate(-10.998 .002) translate(55.922 34.363) translate(-241 -175.936)"
              d="M241.2 179.161l1.648-2.625a1.28 1.28 0 012.168 0l1.648 2.625a1.28 1.28 0 01-1.084 1.961h-3.3a1.28 1.28 0 01-1.08-1.961z"
              data-name="Path 71332"
            />
          </G>
        </G>
        <G data-name="Group 3708">
          <G data-name="Group 3706">
            <Path
              fill="#2d2d59"
              transform="translate(-10.998 .002) translate(52.992 42.478) translate(-226 -217.485)"
              d="M227.833 222.052h8.053a6.089 6.089 0 001.76-2.662 1.459 1.459 0 00-1.393-1.905h-8.786a1.459 1.459 0 00-1.393 1.905 6.089 6.089 0 001.759 2.662z"
              data-name="Path 71333"
            />
          </G>
          <G data-name="Group 3707">
            <Path
              fill="#ff4155"
              transform="translate(-10.998 .002) translate(52.992 42.478) translate(1.833 3.044) translate(-235.384 -233.072)"
              d="M243.437 234.6a6.084 6.084 0 01-8.053 0 6.084 6.084 0 018.053 0z"
              data-name="Path 71334"
            />
          </G>
        </G>
        <G data-name="Group 3709">
          <Path
            fill="#2d2d59"
            transform="translate(-10.998 .002) translate(64.711 29.189) translate(-285.999 -149.45)"
            d="M290.882 151.918a2.442 2.442 0 10-4.883 0 1.01 1.01 0 001.726.685 1.011 1.011 0 011.43 0 1.01 1.01 0 001.727-.685z"
            data-name="Path 71335"
          />
        </G>
        <G data-name="Group 3710">
          <Path
            fill="#2d2d59"
            transform="translate(-10.998 .002) translate(48.109 29.189) translate(-201 -149.45)"
            d="M201 151.918a2.442 2.442 0 114.883 0 1.01 1.01 0 01-1.726.685 1.011 1.011 0 00-1.43 0 1.01 1.01 0 01-1.727-.685z"
            data-name="Path 71336"
          />
        </G>
        <G data-name="Group 3711">
          <Path
            fill="#af624a"
            transform="translate(-10.998 .002) translate(47.133 24.212) translate(-196 -123.968)"
            d="M201.371 126.9h-3.906a1.465 1.465 0 110-2.93h3.906a1.465 1.465 0 110 2.93z"
            data-name="Path 71337"
          />
        </G>
        <G data-name="Group 3712">
          <Path
            fill="#af624a"
            transform="translate(-10.998 .002) translate(63.734 24.212) translate(-281 -123.968)"
            d="M286.371 126.9h-3.906a1.465 1.465 0 110-2.93h3.906a1.465 1.465 0 110 2.93z"
            data-name="Path 71338"
          />
        </G>
        <G data-name="Group 3713">
          <Path
            fill="#6550dd"
            transform="translate(-10.998 .002) translate(27.677 80.115) translate(-96.386 -410.187)"
            d="M101.157 422.129a14.784 14.784 0 01.919-10.472 1.027 1.027 0 00-1.669-1.147 15.528 15.528 0 00-4.021 8.009 48.031 48.031 0 004.771 3.61z"
            data-name="Path 71339"
          />
        </G>
        <G data-name="Group 3714">
          <Path
            fill="#816ae2"
            transform="translate(-10.998 .002) translate(84.232 80.115) translate(-385.95 -410.187)"
            d="M386.973 422.129a14.783 14.783 0 00-.919-10.472 1.027 1.027 0 011.669-1.147 15.528 15.528 0 014.021 8.009 48.03 48.03 0 01-4.771 3.61z"
            data-name="Path 71340"
          />
        </G>
        <G data-name="Group 3715">
          <Path
            fill="#fff"
            transform="translate(-10.998 .002) translate(54.945 71.452) translate(-236 -365.832)"
            d="M240.632 366.283l.581 1.177a.808.808 0 00.609.442l1.3.189a.808.808 0 01.448 1.379l-.94.916a.808.808 0 00-.232.716l.222 1.294a.808.808 0 01-1.173.852l-1.162-.611a.808.808 0 00-.752 0l-1.162.611a.809.809 0 01-1.173-.852l.222-1.294a.809.809 0 00-.232-.716l-.94-.916a.808.808 0 01.448-1.379l1.3-.189a.808.808 0 00.609-.442l.581-1.177a.808.808 0 011.45 0z"
            data-name="Path 71341"
          />
        </G>
        <G
          transform="translate(-10.998 .002) translate(28.922 10.354)"
          data-name="Group 3671"
        >
          <Circle
            fill="#73c3f9"
            transform="rotate(-45 111.778 -11.584)"
            r={14.023}
            cy={14.023}
            cx={14.023}
            data-name="Ellipse 258"
          />
          <Path
            fill="#4fabf7"
            transform="translate(-195.063 -233.759)"
            d="M264.263 320.611c-6.878-1.2-6.372-6.924-6.372-6.924l-2.753-10.5c-4.638-1.97-2.365-7.462-2.363-7.467a14.024 14.024 0 1011.488 24.893z"
            data-name="Path 71304"
          />
          <Path
            fill="#e9efff"
            transform="translate(-241.088 -277.778)"
            d="M306.138 351.365h-1.948a.273.273 0 01-.273-.273v-1.948a2.06 2.06 0 00-4.119 0v1.948a.273.273 0 01-.273.273h-1.948a2.06 2.06 0 100 4.119h1.948a.273.273 0 01.273.273v1.948a2.06 2.06 0 004.119 0v-1.948a.273.273 0 01.273-.273h1.948a2.06 2.06 0 100-4.119z"
            data-name="Path 71305"
          />
          <Path
            fill="#bec8f7"
            transform="translate(-241.087 -277.777)"
            d="M302.4 353.423a2.059 2.059 0 012.059-2.059h-.27a.273.273 0 01-.273-.273v-1.948a2.06 2.06 0 10-4.119 0v1.948a.273.273 0 01-.273.273h-1.948a2.06 2.06 0 000 4.119h1.948a.273.273 0 01.273.273v1.944a2.06 2.06 0 004.119 0v-1.948a.273.273 0 01.273-.273h.27a2.059 2.059 0 01-2.059-2.056z"
            data-name="Path 71306"
          />
        </G>
      </G>
    </Svg>
  )
}

export default AddMember
