// Frameworks
import React, { useContext } from 'react';
import { View, Text, Pressable, GestureResponderEvent } from 'react-native';
import { Icon } from 'react-native-elements';
import { getRandomArbitraryNumber } from '../../../business/math/math';

// Theme
import { IColorChoices, ISpacing } from '../../../types/Theme';
import CommonContext from '../../../CommonContext';
import { Palette } from '../../../Theme';
import { CommonContextType } from '../../../types/CommonContextType';
import CardBadge from './CardBadge';

function Card(props: { handleTap: (event: GestureResponderEvent) => void, margin: keyof ISpacing, title: string, badge?: string, colorChoice?: keyof IColorChoices, testID: string, children: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;

  const choice = props.colorChoice ? props.colorChoice : getRandomItem(themeFromContext.card ? themeFromContext.card.colors : {}) as keyof IColorChoices;

  return (
    <Pressable
      testID={props.testID}
      onPress={props.handleTap}
      style={({ pressed }) => [
        {
          ...themeFromContext.card.style,
          backgroundColor: pressed ? Palette.lightgrey : themeFromContext.colors.background,
          //opacity: pressed ? 0.5 : 1,
          padding: themeFromContext.spacing?.l,
          //marginHorizontal: 24,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          //width: '90%'
        }]}>
         
      <View style={{
        flex: 1,
        //flexShrink: 10
      }}>
        <Text
          selectable={false}
          style={{
            color: themeFromContext.colors?.text,
            ...themeFromContext.textVariants.cardHeader,
            paddingBottom: themeFromContext.spacing?.m,
          }}
          numberOfLines={1}
          testID={`card-title`}
        >
          {props.title}
        </Text>
        <View>
          {props.children}
        </View>
      </View>
      <View style={{
        //flex: 1,
        //flexShrink: 1,
        alignItems: 'flex-end'
      }}>
        <Icon
          name='arrow-forward-circle-outline'
          type='ionicon'
          size={40}
          color={Palette.teal}
        />
      </View>
      {
        props.badge &&
        <CardBadge text={props.badge} />
      }
    </Pressable>
  );
}
export default Card;

function getRandomItem(items: IColorChoices): keyof IColorChoices {
  const arr = Object.entries(items as IColorChoices);
  const l = Math.max(arr.length, 1);
  const i = getRandomArbitraryNumber(0, l - 1);
  const thing = arr[i];
  return Number.parseInt(thing[0]);
}
