import React, { useContext } from 'react'
import { View } from 'react-native'
import { RootStackParamList } from '../../../../types/Navigation';
import { CommonContextType } from '../../../../types/CommonContextType';
import CommonContext from '../../../../CommonContext';
import { Palette } from '../../../../Theme';
import { DrawerScreenProps } from '@react-navigation/drawer';
import { LinearGradient } from 'expo-linear-gradient';
import { Text } from 'react-native-elements';

export type ScanCodeProps = DrawerScreenProps<RootStackParamList, 'Scan Code'>;

function ScanCode(props: ScanCodeProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);

  return (
    <>
      <View
        style={{
          top: 0,
          left: 0,
          position: 'absolute',
          display: 'flex',
          flex: 1,
          width: commonContext.dimensions.width,
          height: '100%',
          backgroundColor: Palette.plum,
        }}
      >
        <LinearGradient
          style={[
            {
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              borderColor: Palette.plum,
            },
          ]}
          colors={[ Palette.purple, Palette.plum]}
          start={{x: 0, y: 0.5}}
          end={{x: 1, y: 0.5}}
        ></LinearGradient>
      </View>
      <Text
        style={{
          color: Palette.white,
          ...commonContext.theme.textVariants.header
        }}
      >
        {`Not supported on web yet. Please download our app from the App Store.`}
      </Text>
    </>
  )
}

export default ScanCode;
