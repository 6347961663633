import { RelatedUserData } from "./userHelper";

export class SubjectList {
  subjects: RelatedUserData[]

  constructor(subjects: RelatedUserData[]) {
      this.subjects = subjects;
  }

  public text () : string {
    const names: string[] = this.subjects.map(s => {
      const fullName = s.user.fullName;
      const email = s.user.email;
      return fullName.length > 0 ? fullName : email;
    })

    if (names.length === 0) {
      // error
      return '';
    } else if (names.length === 1) {
      return names[0];
    } else if (names.length === 2) {
      return `${names[0]} and ${names[1]}`;
    } else { // (names.length > 2)
      let text = '';
      for (let index = 0; index < names.length; index++) {
        const name = names[index];
        if (index === 0) {
          text = name;
        } else if (index === names.length - 1) {
          text = text + `, and ${name}`;
        } else {
          text = text + `, ${name}`;
        }
      }
      return text;
    }    
  }
}