/* eslint-disable react/display-name */
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import RecommendedQuestions from '../../screens/Recommendations/RecommendedQuestions';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import { CommunityTabParamList, HomeStackParamList } from '../../../types/Navigation';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import MembersScreen from '../../screens/Members/MembersScreen';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import log from '../../../business/logging/logger';
import {
  useTourGuideController, // hook to start, etc.
} from 'rn-tourguide'
import PromptModal from '../../components/Modals/PromptModal';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import InterviewScreen from '../../screens/Interview/InterviewScreen';
import NBottomTabBar from './NBottomTabBar';
import { NextStepType } from '../../../API';
import { extractNextStepsFromUser } from '../../../business/user/userHelper';
import i18n from 'i18n-js';
import { getBypass } from '../../../business/redirect/bypass';
import QuestionsStack from './QuestionsStack';
import ActivityHistory from '../../screens/ActivityHistory/ActivityHistory';
import { VideoVisibility } from '../../../contracts/ICommunityService';
import AddMember from '../../../../assets/svg/illustrations/AddMember';
import Question from '../../../../assets/svg/illustrations/Question';
import MovieCamera from '../../../../assets/svg/illustrations/MovieCamera';

export type CommunityProps = NativeStackScreenProps<HomeStackParamList, 'Community'>;

const Tab = createBottomTabNavigator<CommunityTabParamList>()
const HELP_NAME = 'FIRST_COMMUNITY';
const NEXT_STEPS_MODAL = 'next-steps';
const TUTORIAL_MODAL = 'tutorial-modal';
const MODAL_WAIT_PERIOD = 300000; // 5 minutes
const NEXT_STEPS_FOR_ROUTE: { [key: string]: NextStepType[] } = {
  "Questions": [NextStepType.ASK_QUESTIONS, NextStepType.LIKE_QUESTIONS],
  "Recommended": [],
  "Members": [NextStepType.INVITE_MEMBERS, NextStepType.INVITE_ORGANIZERS],
  "Interview": [NextStepType.REQUEST_INTERVIEW],
  "Activity": [],
};
const ROUTE_FOR_NEXT_STEP: { [key in NextStepType]: keyof CommunityTabParamList } = {
  "ASK_QUESTIONS": "Questions",
  "LIKE_QUESTIONS": "Questions",
  "INVITE_MEMBERS": "Members",
  "INVITE_ORGANIZERS": "Members",
  "REQUEST_INTERVIEW": "Interview",
};

function CommunityNavigator(props: CommunityProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { width } = commonContext.dimensions;
  const linkTo = useLinkTo();
  // eslint-disable-next-line react/prop-types
  const communityId = props.route.params?.communityId;
  const [hasCommunityVideo, setHasCommunityVideo] = useState(false);
  //const [showTutorialModal, setShowTutorialModal] = useState(false);
  const [deniedTour, setDeniedTour] = useState(false);
  const [nextStepMapForTabs, setNextStepMapForTabs] = useState<NextStepType[]>([]);
  const [nextStepMapForModal, setNextStepMapForModal] = useState<NextStepType[]>([]);
  //const [showNextStepModal, setShowNextStepModal] = useState(false);
  const [currentTab, setCurrentTab] = useState<keyof CommunityTabParamList>();
  const isFocused = useIsFocused();
  const { userService, communityService } = commonContext.services;

  // Use Hooks to control!
  const {
    canStart, // a boolean indicate if you can start tour guide
    start, // a function to start the tourguide
    stop, // a function to stop it
    eventEmitter, // an object for listening some events
  } = useTourGuideController();

  // const handleOnStart = () => console.log('start')

  const startTutorial = () => {
    if (commonContext.showingModal == TUTORIAL_MODAL) {
      eventEmitter?.on('stop', handleOnStop);
      log.info(`want to show help: ${HELP_NAME}`);
      if (canStart && start) { // test if you can start otherwise nothing will happen
        log.info(`starting help: ${HELP_NAME}`);
        commonContext.setShowingModal(HELP_NAME);
        start();
      } else {
        commonContext.setShowingModal();
      }
    }
  }

  const handleOnStop = () => {
    log.info(`help finished: ${HELP_NAME}`)
    if (commonContext.showingModal == HELP_NAME) {
      commonContext.setShowingModal();
    }

    if (commonContext.loggedInUser) {
      log.info(`trying to flag as seen: ${HELP_NAME}`);
      userService.flagHelpAsSeen(HELP_NAME, commonContext.loggedInUser);
    }
  }

  // React.useEffect(() => {
  //   eventEmitter?.on('start', handleOnStart)
  //   eventEmitter?.on('stop', handleOnStop)
  //   eventEmitter?.on('stepChange', handleOnStepChange)

  //   return () => {
  //     eventEmitter?.off('start', handleOnStart)
  //     eventEmitter?.off('stop', handleOnStop)
  //     eventEmitter?.off('stepChange', handleOnStepChange)
  //   }
  // }, [])

  useEffect(() => {
    // get all the welcomes the logged-in user has already seen
    const seen = commonContext.loggedInUser?.helpsSeen ? commonContext.loggedInUser?.helpsSeen.map(s => s.toUpperCase()) : [];

    // log.info(`seen:`);
    // log.info(seen);

    getBypass()
      .then((b) => {
        const bypassTutorial = b ?? false;
        // log.info(`BYPASS TUTORIAL??? ${bypassTutorial}`);
        if (!seen.includes(HELP_NAME) && !deniedTour && !bypassTutorial) {
          //setShowTutorialModal(true);
          if (!commonContext.showingModal) {
            setTimeout(() => commonContext.setShowingModal(TUTORIAL_MODAL), 400);
          }
        }
      });

    return () => {
      // mounted = false;
      eventEmitter?.off('stop', handleOnStop)
    }
  }, []);

  useEffect(() => {
    if (commonContext.loggedInUser && isFocused) {
      const map = extractNextStepsFromUser(commonContext.loggedInUser, commonContext.membership);
      setNextStepMapForTabs(map[communityId]);
      const copy = { ...map };
      setNextStepMapForModal(copy[communityId]);
    }
  }, [commonContext.loggedInUser, communityId]);

  useEffect(() => {
    // next step modal
    if (nextStepMapForModal && nextStepMapForModal.length > 0) {
      setTimeout(() => {
        tryPopupNextStepModal();
      }, 400); // this is just slowing down the toggling between modal states so that the "fade in/out" can complete between toggles...
    }

  }, [nextStepMapForModal]);


  useLayoutEffect(() => {
    // eslint-disable-next-line react/prop-types
    // props?.navigation?.setOptions({
    //   title: `Community`
    // });
    if (communityId) {
      // log.info(`here 1`);
      // log.info(communityId);
      // log.info(commonContext.communities);
      // TODO: BAD! Not the right place or manner for security!!!
      // is the user a member of this community?
      if (commonContext.communities[communityId]) {

        if (commonContext.communities[communityId].vimeoVideos && commonContext.communities[communityId].vimeoVideos != null && commonContext.communities[communityId].vimeoVideos.length > 0) {
          log.info(`here 2`);
          let meta;
          let videoId = '';
          let title;
          let description;
          let visibility;
          try {
            log.info(`here 3`);
            meta = JSON.parse(commonContext.communities[communityId].vimeoVideos[0]);
            videoId = meta.id;
            title = meta.title;
            description = meta.description;
            visibility = meta.visibility ?? VideoVisibility.ORGANIZER;
            setHasCommunityVideo(true);
            log.info(`here 4`);

          } catch (ex) {
            log.info(`failed to parse string as JSON: ${commonContext.communities[communityId].vimeoVideos[0]}`);
          }
          log.info(`here 5`);
        }

        //if (community) {
        // eslint-disable-next-line react/prop-types
        // props?.navigation?.setOptions({
        //   title: community.name
        // });
        //}
      } else {
        log.info(`here 6`);
        // if not part of this community, re-route him back to home
        linkTo(`/communities/${commonContext.loggedInUser?.id}`);
      }

    }
  }, [communityId]);

  function goToNextStep(nextStep: NextStepType): void {
    // remove next step from local map
    // however, the only way to remove the next step from the backend is to actually DO it
    dismissNextStepModal(nextStep);
    switch (nextStep) {
      case NextStepType.INVITE_MEMBERS:
        linkTo(`/community/${communityId}/members/${communityId}`);
        return;
      case NextStepType.ASK_QUESTIONS:
        linkTo(`/community/${communityId}/asked/${communityId}`);
        return;
      case NextStepType.INVITE_ORGANIZERS:
        linkTo(`/community/${communityId}/members/${communityId}`);
        return;
      case NextStepType.LIKE_QUESTIONS:
        linkTo(`/community/${communityId}/asked/${communityId}`);
        return;
      case NextStepType.REQUEST_INTERVIEW:
        linkTo(`/community/${communityId}/interview/${communityId}`);
        return;
      default:
        return;
    }
  }

  function dismissNextStepModal(nextStep: NextStepType) {
    if (commonContext.showingModal == NEXT_STEPS_MODAL) {
      commonContext.setShowingModal();
    }
    //setShowNextStepModal(false);

    setTimeout(() => {
      if (nextStepMapForTabs) {
        const modified = nextStepMapForTabs.filter(ns => ns != nextStep);
        setNextStepMapForTabs(modified);
      }
    }, 500);

    setTimeout(() => {
      const modified = nextStepMapForModal.filter(ns => ns != nextStep);
      setNextStepMapForModal(modified);
    }, MODAL_WAIT_PERIOD);
  }

  function tryPopupNextStepModal() {
    if (commonContext.showingModal) {
      setTimeout(() => {
        tryPopupNextStepModal();
      }, MODAL_WAIT_PERIOD);
    } else {
      if (nextStepMapForModal && nextStepMapForModal.length > 0) {
        const nextStep = nextStepMapForModal[0];
        const nsRouteName = ROUTE_FOR_NEXT_STEP[nextStep];

        log.info(currentTab);

        if (currentTab == nsRouteName) {
          // are we already on the screen for the next step? If so, hide the modal and move the next step to the back and wait another period...
          if (commonContext.showingModal == NEXT_STEPS_MODAL) {
            commonContext.setShowingModal();
          }
          //setShowNextStepModal(false);
          const modified = [...nextStepMapForModal];
          const moveToLast = modified.shift();
          if (moveToLast) {
            modified.push(moveToLast);
          }
          setTimeout(() => {
            setNextStepMapForModal(modified);
          }, MODAL_WAIT_PERIOD);
        } else {
          // else, show the modal
          if (!commonContext.showingModal) {
            commonContext.setShowingModal(NEXT_STEPS_MODAL);
          }
          //setShowNextStepModal(true);
        }
      } else {
        if (commonContext.showingModal == NEXT_STEPS_MODAL) {
          commonContext.setShowingModal();
        }
        //setShowNextStepModal(false);
      }
    }
  }

  function getNextStepImage(nextStep: NextStepType): React.ReactElement | undefined {
    switch (nextStep) {
      case NextStepType.INVITE_MEMBERS:
        return <AddMember />; // require('../../assets/svg/illustrations/AddMember.svg');
      case NextStepType.ASK_QUESTIONS:
        return <Question />; // require('../../assets/svg/illustrations/Question.svg');
      case NextStepType.INVITE_ORGANIZERS:
        return <AddMember />; //require('../../assets/svg/illustrations/AddMember.svg');
      case NextStepType.LIKE_QUESTIONS:
        return <Question />; //require('../../assets/svg/illustrations/Question.svg');
      case NextStepType.REQUEST_INTERVIEW:
        return <MovieCamera />;// require('../../assets/svg/illustrations/MovieCamera.svg');
      default:
        return;
    }
  }

  function getNextStepHeader(nextStep: NextStepType): string {
    return i18n.t(`Page_Community_Next_Step_Modal_Header__${nextStep}`);
  }

  function getNextStepBody(nextStep: NextStepType): string {
    return i18n.t(`Page_Community_Next_Step_Modal_Body__${nextStep}`);
  }

  function onButtonTabTap(name: string) {
    const possibleForThisTab = NEXT_STEPS_FOR_ROUTE[name];

    // if next steps are in the user's list, move them to the front and try to show the modal
    if (nextStepMapForTabs) {
      const actualForThisTab = nextStepMapForTabs.filter(ns => possibleForThisTab.includes(ns));
      if (actualForThisTab && actualForThisTab.length > 0) {
        let mod: NextStepType[] = [];
        if (nextStepMapForModal) {
          mod = nextStepMapForModal.filter(ns1 => !actualForThisTab.includes(ns1));
        }
        mod = [...actualForThisTab, ...mod];
        setNextStepMapForModal(mod);
        if (!commonContext.showingModal) {
          commonContext.setShowingModal(NEXT_STEPS_MODAL);
        }
        //setShowNextStepModal(true);
      }
    }
  }

  return (
    <>
      <Tab.Navigator
        // sceneContainerStyle={{
        //   maxWidth: 1200,
        //   alignSelf: 'center'
        // }}
        tabBar={(tabBarProps) => (<NBottomTabBar communityId={communityId} nextSteps={nextStepMapForTabs} onTap={onButtonTabTap} {...tabBarProps} />)}
        backBehavior='history'
        screenListeners={({ navigation, route }) => ({
          state: (e) => {
            // Do something with the state
            //log.info(`state changed: ${JSON.stringify(e.data, null, 2)}`);

            // Do something with the `navigation` object
            if (navigation.isFocused()) {
              log.info(`changed route to: ${route.name}`);
              setCurrentTab(route.name);
            }
          },
        })}
        screenOptions={({ route }) => ({
          headerShown: false,
        })}
      >

        <Tab.Screen name="Activity" component={ActivityHistory} />
        <Tab.Screen name="Questions" component={QuestionsStack} />
        <Tab.Screen name="Members" component={MembersScreen} />
        {
          hasCommunityVideo &&
          <Tab.Screen name="Interview" component={InterviewScreen} />
        }
        {/* <Tab.Screen name="Test" component={Test} /> */}
        {/* <Tab.Screen name="Watch" component={VimeoScreen} /> */}
      </Tab.Navigator>
      <PromptModal
        show={commonContext.showingModal == TUTORIAL_MODAL}
        heading={`Hi there!`}
        prompt={`Welcome to your first Novella community! Want to take a quick tour?`}
        confirmButtonText={`Sure!`}
        confirm={startTutorial}
        denyButtonText={`No thanks`}
        deny={() => {
          setDeniedTour(true);
          if (commonContext.showingModal == TUTORIAL_MODAL) {
            commonContext.setShowingModal();
          }
          //setShowTutorialModal(false);
        }}
        testID={`community-tutorial-modal`}
      />
      {/* This modal tells you there's a next step on a page, then take you there...? */}
      {
        nextStepMapForModal && nextStepMapForModal[0] &&
        <PromptModal
          confirm={() => goToNextStep(nextStepMapForModal[0])}
          // working={confirmingCode || signingIn}
          confirmButtonText={i18n.t('Page_Community_Next_Step_Modal_Button_Ok')}
          deny={() => dismissNextStepModal(nextStepMapForModal[0])}
          denyButtonText={i18n.t('Page_Community_Next_Step_Modal_Button_NoThanks')}
          heading={getNextStepHeader(nextStepMapForModal[0])}
          prompt={getNextStepBody(nextStepMapForModal[0])}
          show={commonContext.showingModal == NEXT_STEPS_MODAL}
          testID={`community-next-step-modal`}
        >
          {getNextStepImage(nextStepMapForModal[0])}
          {/* <Image
            source={getNextStepImage(nextStepMapForModal[0])}
            resizeMode='contain'
            style={{
              width: '100%',
              height: normalizeWidth(100, width),
              marginBottom: 25,
            }}
          /> */}
        </PromptModal>
      }
    </>
  );
}

export default CommunityNavigator;