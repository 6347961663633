// Frameworks
import React, { useContext, useEffect, useState } from 'react';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { LayoutChangeEvent, Platform, Text, View } from 'react-native';
import NText from '../NText';
import { Palette } from '../../../Theme';
import { normalizeHeight } from '../../../business/layout/responseSize';
import NActivityIndicator from '../ActivityIndicators/NActivityIndicator';

import { MAX_WIDTH } from '../../../business/layout/layout';
import log from '../../../business/logging/logger';
import InlinePlayer from './InlinePlayer';
import { VimeoThumbnail } from '../../../business/video/vimeo';

const template2 = `<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
<html>
<body>
    <iframe
      src="{{embedUrl}}&badge=0&autopause=0&quality=1080p&player_id=0&app_id=58479"
      frameborder="0"
      allow="autoplay; fullscreen; picture-in-picture"
      allowfullscreen
      style="position:absolute;top:0;left:0;"
      title="{{title}}"
      width="{{width}}"
      height="{{height}}"
    >
    </iframe>
  <script src="https://player.vimeo.com/api/player.js"></script>
</body>

</html>`;

type MyProps = {
  embedUrl: string,
  m3u8?: string,
  asset?: any,
  thumbnailUrls?: VimeoThumbnail[],
  width: number,
  height: number,
  transcodingStatus: string,
  heightConstrained?: boolean
}

function VimeoPlayer(props: MyProps): React.ReactElement {
  const { embedUrl, width, height, transcodingStatus, m3u8, asset, thumbnailUrls, heightConstrained } = props;

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const [transcodeStatus, setTranscodeStatus] = useState(transcodingStatus);
  const [iframeHtml, setIframeHtml] = useState('');
  const [htmlContentWidth, setHtmlContentWidth] = useState(0);
  const [calculatedHeight, setCalculatedHeight] = useState(0);
  const [loading, setLoading] = useState(false);
  const [thumbnails, setThumbnails] = useState<VimeoThumbnail[]|undefined>();
  const [thumbnail, setThumbnail] = useState<VimeoThumbnail|undefined>();
  const [constrainedHeight, setConstrainedHeight] = useState(height);

  //console.log(`top level vimeo player has transcoding status = ${transcodingStatus}`);

  useEffect(() => {
    if (Platform.OS == 'web' && embedUrl.length > 0 && width > 0 && height > 0 && transcodingStatus.length > 0) {
      let finalHtml;
      if (!heightConstrained) {
        if (width >= height) {
          finalHtml = getIframeHtml(embedUrl, MAX_WIDTH, ((MAX_WIDTH / width) * height));
        } else {
          const ch = height;
          const cw = width;
          finalHtml = getIframeHtml(embedUrl, cw, ch);
        }
      } else {
        const ch = commonContext.dimensions.height * 0.33;
        const cw = (width / height) * ch;
        finalHtml = getIframeHtml(embedUrl, cw, ch);
        setConstrainedHeight(ch);
      }
      setIframeHtml(finalHtml)
      //log.info(`GOT IFRAME HTML?`);
      //log.info(finalHtml);
      setTranscodeStatus(transcodingStatus);
      setLoading(false);
    } else if (Platform.OS != 'web' && m3u8 != undefined && m3u8.length > 0 && width > 0 && height > 0 && transcodingStatus.length > 0) {
      setThumbnails(thumbnailUrls);
      setTranscodeStatus(transcodingStatus);
      setLoading(false);
    } else if (Platform.OS != 'web' && asset != undefined && width > 0 && height > 0) {
      // console.log(`trying to show video element for asset:`);
      // console.log(asset);
      setTranscodeStatus('complete');
      setLoading(false);
    } else if (transcodingStatus.length > 0) {
      setTranscodeStatus(transcodingStatus);
    } else {
      setLoading(true);
    }
  }, [embedUrl, width, height, transcodingStatus, m3u8, thumbnailUrls]);

  useEffect(() => {
    setCalculatedHeight(height);
  }, [height]);

  useEffect(() => {
    if (thumbnails) {
      // old implementation

      // let idx = 0;
      // for(let i = 0; i < thumbnails.length - 1; i++) {
      //   if (thumbnails[i].height <= htmlContentWidth || thumbnails[i].width <= htmlContentWidth) {
      //     idx = i + 1;
      //   }
      // }
      // //console.log(`choosing thumbnail url at index: ${idx}, with width: ${thumbnails[idx].width} and height: ${thumbnails[idx].height} for contentWidth: ${htmlContentWidth}`);
      // setThumbnail(thumbnails[idx]);

      // playerContentHeight={heightConstrained ? undefined : calculatedHeight}
      
      const originalLink = thumbnails[0].link;
      const originalLinkWithPlayButton = thumbnails[0].link_with_play_button;
      const originalHeight = thumbnails[0].height;
      const originalWidth = thumbnails[0].width;
      const originalSizeString = `${originalWidth}x${originalHeight}`;

      const customWidth = htmlContentWidth;
      const customHeight = heightConstrained ? htmlContentWidth : calculatedHeight;
      const customSizeString = `${customWidth}x${customHeight}`;
      const customLink = originalLink.replace(originalSizeString, customSizeString);
      const customLinkWithPlayButton = originalLinkWithPlayButton.replace(originalSizeString, customSizeString);
      
      const customThumbnail: VimeoThumbnail = {
        link_with_play_button: customLinkWithPlayButton,
        link: customLink,
        width: customWidth,
        height: customHeight,
      }
      // console.log(`using custom thumbnail: ${customLink}`);
      console.log(`using custom thumbnail with play button: ${customLinkWithPlayButton}`);
      setThumbnail(customThumbnail);
    }
  }, [htmlContentWidth, thumbnails, heightConstrained, calculatedHeight]);

  
  
  const getIframeHtml = (url: string, width: number, height: number): string => {
    return template2
      .replace(new RegExp(`{{embedUrl}}`, 'g'), url)
      .replace(new RegExp(`{{width}}`, 'g'), width.toString())
      .replace(new RegExp(`{{height}}`, 'g'), height.toString());
  }

  const onLayoutVideo = (event: LayoutChangeEvent) => {
    const frameWidth = event.nativeEvent.layout.width;
    // if (frameWidth != htmlContentWidth) {
      setHtmlContentWidth(frameWidth);
      setCalculatedHeight(htmlContentWidth * 1.25);
    // }
  }

  //console.log(`VimeoPlayer: ${(new Date(Date.now())).toISOString()}`);
  return (
    <>
      {
        loading &&
        <View
          style={[
            {
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: Platform.OS == 'web' ? constrainedHeight : htmlContentWidth,
              //flex: 1,
              paddingVertical: normalizeHeight(30, commonContext.dimensions.height),
            },
            //width > 0 && height > 0 ? { width: width, height: height } : {}
          ]}
          testID={`vimeo-screen-loading-view`}
        >
          <NActivityIndicator color={Palette.teal} />
        </View>
      }
      {
        !loading && transcodeStatus != 'complete' &&
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: Platform.OS == 'web' ? constrainedHeight : htmlContentWidth,
            //flex: 1,
            paddingVertical: normalizeHeight(30, commonContext.dimensions.height),
          }}
          testID={`vimeo-screen-transcoding-view`}
        >
          <NActivityIndicator color={Palette.teal} />
          <NText
            style={{
              ...themeFromContext.textVariants.body,
              color: themeFromContext.colors.secondary,
              marginTop: 25,
            }}
          >
            {`Processing video...`}
          </NText>
        </View>
      }
      {
        !loading && transcodeStatus == 'complete' && // HAS VIDEO
        <View
          onLayout={onLayoutVideo}
          style={[
            {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            alignSelf: 'center',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundColor: Platform.OS != 'web' ? 'grey' : Palette.transparent,
            },
            Platform.OS != 'web' ? { height: heightConstrained ? htmlContentWidth : calculatedHeight, } : {}
          ]}
          testID={`vimeo-screen-video-view`}
        >
          <InlinePlayer playerContentWidth={htmlContentWidth} m3u8={m3u8} asset={asset} iframeHtml={iframeHtml} thumbnail={thumbnail} playerContentHeight={heightConstrained ? undefined : calculatedHeight}  />
        </View>
      }
    </>
  );
}

const areEqual = (prevProps: MyProps, nextProps: MyProps) => {
  /*if the props are equal, it won't update*/

  const embedUrlEqual = nextProps.embedUrl == prevProps.embedUrl;
  const heightEqual = nextProps.height == prevProps.height;
  const heightConstrainedEqual = nextProps.heightConstrained == prevProps.heightConstrained;
  const m3u8Equal = nextProps.m3u8 == prevProps.m3u8;
  const transcodingStatusEqual = nextProps.transcodingStatus == prevProps.transcodingStatus;
  const widthEqual = nextProps.width == prevProps.width;
  const assetEqual = nextProps.asset == prevProps.asset;
  const thumbnailUrlsEqual = nextProps.thumbnailUrls?.length == prevProps.thumbnailUrls?.length;

  console.log(`VimeoPlayer check!`);

  try {
    if (!embedUrlEqual) {
      console.log(`InlinePlayer: embedUrl not equal!`);
      console.log(`InlinePlayer: ${nextProps.embedUrl}`);
      console.log(`InlinePlayer: ${prevProps.embedUrl}`);
      return false;
    }
    if (!heightEqual) {
      console.log(`InlinePlayer: height not equal!`);
      console.log(`InlinePlayer: ${nextProps.height}`);
      console.log(`InlinePlayer: ${prevProps.height}`);
      return false;
    }
    if (!heightConstrainedEqual) {
      console.log(`InlinePlayer: heightConstrained not equal!`);
      console.log(`InlinePlayer: ${nextProps.heightConstrained}`);
      console.log(`InlinePlayer: ${prevProps.heightConstrained}`);
      return false;
    }
    if (!m3u8Equal) {
      console.log(`InlinePlayer: m3u8 not equal!`);
      console.log(`InlinePlayer: ${nextProps.m3u8}`);
      console.log(`InlinePlayer: ${prevProps.m3u8}`);
      return false;
    }
    if (!transcodingStatusEqual) {
      console.log(`InlinePlayer: transcodingStatus not equal!`);
      console.log(`InlinePlayer: ${nextProps.transcodingStatus}`);
      console.log(`InlinePlayer: ${prevProps.transcodingStatus}`);
      return false;
    }
    if (!widthEqual) {
      console.log(`InlinePlayer: width not equal!`);
      console.log(`InlinePlayer: ${nextProps.width}`);
      console.log(`InlinePlayer: ${prevProps.width}`);
      return false;
    }
    if (!assetEqual) {
      console.log(`InlinePlayer: asset not equal!`);
      console.log(`InlinePlayer: ${nextProps.asset}`);
      console.log(`InlinePlayer: ${prevProps.asset}`);
      return false;
    }
    if (!thumbnailUrlsEqual) {
      console.log(`InlinePlayer: thumbnailUrls not equal!`);
      console.log(`InlinePlayer: ${nextProps.thumbnailUrls?.length}`);
      console.log(`InlinePlayer: ${prevProps.thumbnailUrls?.length}`);
      return false;
    }

    return true;
  } catch (ex) {
    console.log(`${ex}`)
  }

  return false;
};

// export default React.memo(VimeoPlayer, areEqual);

export default VimeoPlayer;