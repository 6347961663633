import React, { useContext } from 'react';
import { View, Text } from 'react-native';
import CommonContext from '../../../CommonContext';
import { Palette } from '../../../Theme';
import { CommonContextType } from '../../../types/CommonContextType';

function CardBadge(props: { text: string }): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;

  return (
      <View
        style={{
          display: 'flex',
          position: 'absolute',
          right: -8,
          top: -8,
          justifyContent: 'center',
          alignItems: 'center',
          paddingHorizontal: 8,
          paddingVertical: 4,
          borderRadius: 50,
          overflow: 'hidden',
          backgroundColor: Palette.pink,
          minWidth: 30,
          minHeight: 30,
        }}
      >
        <Text
          style={{
            ...themeFromContext.textVariants.communityBadge,
            color: themeFromContext.colors.background,
          }}
        >
          {props.text}
        </Text>
      </View>
  );
}
export default CardBadge;