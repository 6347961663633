import { TUSFR } from '../video/tusFileReader';
import * as tus from "tus-js-client";
import { Platform } from "react-native";
import { UploadListenerLibrary } from './UploadListenerLibrary';

export enum UploadStatus {
  READY = 'READY',
  IN_PROGRESS = 'IN_PROGRESS',
  PAUSED = 'PAUSED',
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

export class TUSUpload {
  id: string;
  item: any;
  fileName: string;
  mimeType: string;
  status: UploadStatus;
  totalBytes: number;
  uploadedBytes: number;
  error: any;
  private upload: tus.Upload;
  private uploadLink: string;
  private chunkSize: number;

  static rehydrate(source: TUSUpload) : TUSUpload {
    //const formattedUri: string = source.item.uri.replace(`file:///`, `file://`);
    //const newItem = { uri: formattedUri };

    if (Platform.OS != 'ios') {
      return source;
    }

    const destination = new TUSUpload(source.id, source.item, source.uploadLink, source.fileName, source.mimeType, source.totalBytes);
    destination.status = source.status;
    destination.uploadedBytes = source.uploadedBytes;
    destination.error = source.error;
    return destination;
  }

  constructor(id: string, item: any, uploadLink: string, fileName: string, mimeType: string, totalBytes: number) {
    this.id = id;
    this.item = item;
    this.uploadLink = uploadLink;
    this.chunkSize = 1 * 1024 * 1024; // setting this to 1MB because Vimeo wasn't consistently letting us "resume" paused uploads when we used bigger chunks...
    this.fileName = fileName;
    this.mimeType = mimeType;
    this.totalBytes = totalBytes;
    this.status = UploadStatus.READY;
    this.uploadedBytes = 0;
    this.error = undefined;

    let uploadOptions: tus.UploadOptions = {
      uploadUrl: this.uploadLink,
      chunkSize: this.chunkSize,
      retryDelays: [0, 1000, 3000, 5000],
      metadata: {
        filename: this.fileName,
        filetype: this.mimeType,
      },
      // not supported for RN!!!! urlStorage: new tus.FileUrlStorage(STORAGE_PATH),
      onError: (error: any) => {
        this.status = UploadStatus.FAILED;
        this.error = error;
        this.annouceUpdate('onError');
        console.log(error);
      },
      onProgress: (uploaded: any, total: any) => {
        this.totalBytes = total;
        this.uploadedBytes = uploaded;
        this.status = UploadStatus.IN_PROGRESS;
        if (total != uploaded) {
          this.annouceUpdate(`onProgress`);
        }
        console.log(`uploaded: ${uploaded}`);
      },
      onSuccess: () => {
        this.status = UploadStatus.COMPLETED;
        this.annouceUpdate(`onSuccess`);
      }
    }
    if (Platform.OS == 'ios' || Platform.OS == 'android') {
      uploadOptions = {
        ...uploadOptions,
        fileReader: new TUSFR(totalBytes), // this reads the file from phone storage in chunks rather than copying it all into memory first, helping avoid memory issues with large files
      }
    }
    this.upload = new tus.Upload(this.item, uploadOptions);
    //this.annouceUpdate(`constructor`);
  }

  async annouceUpdate(callerName: string): Promise<void> {
    const listeners = UploadListenerLibrary.getListeners();
    //console.log(`TUSUpload.annouceUpdate - from [${callerName}] - ${listeners.length} listeners -  ${this.id}`);
    await Promise.all(listeners.map(l => {
      //console.log(`[${this.id}] calling announceUpdate on listener owned by: ${l.name}`);
      if (l && l.listen) {
        return l.listen(this);
      }
    }));
  }

  // COPIED FROM: https://github.com/tus/tus-js-client/blob/master/docs/usage.md#example-upload-with-pause-button
  async startOrResume(): Promise<void> {
    console.log(`Start the upload`);
    this.status = UploadStatus.IN_PROGRESS;
    this.upload.start()
    await this.annouceUpdate(`startOrResume`);
  }
  async reset(): Promise<void> {
    this.status = UploadStatus.READY;
    await this.annouceUpdate(`reset`);
  }
  async cancel(): Promise<void> {
    this.status = UploadStatus.CANCELED;
    await this.upload.abort(true);
    await this.annouceUpdate(`cancel`);
  }
  async pause(): Promise<void> {
    this.status = UploadStatus.PAUSED;
    await this.upload.abort(false);
    await this.annouceUpdate(`pause`);
  }
}