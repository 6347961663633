/* eslint-disable react/display-name */
import React, { useContext, useState } from 'react'
import { Platform } from 'react-native';
import { Input, InputProps } from 'react-native-elements';
import CommonContext from '../../../CommonContext';
import { Palette } from '../../../Theme';
import { CommonContextType } from '../../../types/CommonContextType';


const AuthPageInput = React.forwardRef((props: InputProps, ref: any) => //<NavLink innerRef={ref} {...props} />);
{
  const [isFocused, setIsFocused] = useState(false);
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
   return (
    <Input
      ref={ref}
      selectionColor={Palette.white}
      labelStyle={{
        marginVertical: 8,
        color: themeFromContext.colors.foreground,
        ...themeFromContext.textVariants.inputLabel
      }}
      inputContainerStyle={[
        {
          //borderBottomWidth: 0,
        },
        props.inputContainerStyle,
        { paddingHorizontal: 0, borderWidth: 2, borderRadius: 12, borderColor: Palette.transparent },
        Platform.OS != 'web' && isFocused && { borderColor: themeFromContext.colors.primary }
      ]}
      inputStyle={[
        {
          borderRadius: 8,
          borderColor: themeFromContext.colors.secondary,
          borderWidth: 0,
          paddingHorizontal: 16,
          paddingVertical: 8,
          ...themeFromContext.textVariants.body
        },
      ]}
      placeholderTextColor={themeFromContext.colors.secondary}
      onBlur={() => setIsFocused(false)}
      onFocus={() => setIsFocused(true)}
      {...props}
    />
  );
});

export default AuthPageInput;