import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function Family(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      viewBox="0 0 100 100"
      {...props}
    >
      <G data-name="Family Illustration">
        <Path
          data-name="Path 70991"
          d="M.661 58.151A50 50 0 11100 50a50.28 50.28 0 01-.881 9.4 10.9 10.9 0 01-10.728 8.81H12.575A12.052 12.052 0 01.661 58.151z"
          fill="#7fcecf"
          transform="translate(.001)"
        />
        <Path
          data-name="Path 70992"
          d="M49.628 80.484a40.411 40.411 0 1179.572 1.007 8.807 8.807 0 01-8.67 7.121H59.257a9.741 9.741 0 01-9.63-8.127z"
          transform="translate(.001) translate(-39.505 -26.945)"
          fill="#61bcbd"
        />
        <Path
          data-name="Path 70993"
          d="M18.184 279.363a50.284 50.284 0 01-11.345 10.515 1.637 1.637 0 01-2.479-.892 49.676 49.676 0 01-1.478-6.659 10.806 10.806 0 017.449-2.963z"
          transform="translate(.001) translate(-2.32 -224.8)"
          fill="#3d4ec6"
        />
        <Path
          data-name="Path 70994"
          d="M54.7 290.206v14.038a15.287 15.287 0 01-20.78 14.3 7.851 7.851 0 01-1.056-.49A50.118 50.118 0 019.891 288.61a10.846 10.846 0 0110.727-9.248h23.237A10.844 10.844 0 0154.7 290.206z"
          transform="translate(.001) translate(-7.96 -224.799)"
          fill="#5766ce"
        />
        <Path
          data-name="Path 70995"
          d="M76.528 271.587l-6.907 11.835a.391.391 0 01-.675 0l-3.511-6.022a.391.391 0 00-.675 0l-3.512 6.018a.391.391 0 01-.675 0l-6.907-11.835A.391.391 0 0154 271h22.191a.391.391 0 01.337.587z"
          transform="translate(.001) translate(-43.142 -218.07)"
          fill="#fcf2b6"
        />
        <Path
          data-name="Path 70996"
          d="M51.505 77.848v15.3H12.467v-16.46a.731.731 0 01.562-.712l27.743-6.605a8.714 8.714 0 0110.733 8.477z"
          transform="translate(.001) translate(-10.032 -55.628)"
          fill="#ff9f22"
        />
        <Path
          data-name="Path 70997"
          d="M13.075 164.262v1.549a3.873 3.873 0 11-7.746 0v-1.549a3.873 3.873 0 117.746 0z"
          transform="translate(.001) translate(-4.289 -129.063)"
          fill="#ffbeaa"
        />
        <Path
          data-name="Path 70998"
          d="M7.653 80.517v3.1a3.867 3.867 0 001.549 3.1 3.873 3.873 0 01-3.872-3.873v-1.552a3.873 3.873 0 013.87-3.873 3.867 3.867 0 00-1.549 3.1zM26.242 57.28A17.04 17.04 0 009.2 74.32v6.2a17.048 17.048 0 0012.393 16.4v5.28a4.647 4.647 0 109.295 0v-5.284a17.048 17.048 0 0012.393-16.4v-6.2A17.04 17.04 0 0026.242 57.28z"
          transform="translate(.001) translate(-4.289 -46.093)"
          fill="#faa68e"
        />
        <Path
          data-name="Path 70999"
          d="M78.386 74.968v6.2A17.048 17.048 0 0165.8 97.616a8.185 8.185 0 01-4.271 0 17.048 17.048 0 01-12.577-16.451v-6.2a17.044 17.044 0 0114.717-16.88 17.044 17.044 0 0114.717 16.883z"
          transform="translate(.001) translate(-39.391 -46.74)"
          fill="#ffbeaa"
        />
        <Path
          data-name="Path 71000"
          d="M89.415 173.421a1.162 1.162 0 111.643-1.643.852.852 0 001.206 0 1.162 1.162 0 111.643 1.643 3.176 3.176 0 01-4.493 0zm6.941 4.276a1.162 1.162 0 00-1.643 0 4.32 4.32 0 01-6.1 0 1.162 1.162 0 10-1.643 1.643 6.647 6.647 0 009.389 0 1.162 1.162 0 000-1.643z"
          transform="translate(.001) translate(-69.708 -137.954)"
          fill="#e09380"
        />
        <Path
          data-name="Path 71001"
          d="M67.48 140.9a2.324 2.324 0 11-2.324-2.324 2.324 2.324 0 012.324 2.324zm12.393-2.324A2.324 2.324 0 1082.2 140.9a2.324 2.324 0 00-2.327-2.322z"
          transform="translate(.001) translate(-50.561 -111.512)"
          fill="#4c2c21"
        />
        <Path
          data-name="Path 71002"
          d="M79.036 139.74a1.162 1.162 0 11-1.162-1.162 1.162 1.162 0 011.162 1.162zm13.555-1.162a1.162 1.162 0 101.162 1.162 1.162 1.162 0 00-1.162-1.162z"
          transform="translate(.001) translate(-61.73 -111.512)"
          fill="#fff5f5"
        />
        <Path
          data-name="Path 71003"
          d="M37.253 42.006a8.52 8.52 0 01-8.52 8.52H14.791a2.324 2.324 0 01-2.324-2.326 14.717 14.717 0 0114.717-14.714h1.549a8.52 8.52 0 018.52 8.52z"
          transform="translate(.001) translate(-10.032 -26.946)"
          fill="#ff9f22"
        />
        <Path
          data-name="Path 71004"
          d="M52.125 42.005a8.52 8.52 0 018.52-8.52h22a.775.775 0 01.775.775 16.266 16.266 0 01-16.268 16.265h-6.507a8.52 8.52 0 01-8.52-8.52z"
          transform="translate(.001) translate(-41.945 -26.945)"
          fill="#ffb52d"
        />
        <Path
          data-name="Path 71005"
          d="M423.474 111.979V139.1h-18.218v-27.886h17.454a.765.765 0 01.764.765z"
          transform="translate(.001) translate(-326.105 -89.493)"
          fill="#2b3894"
        />
        <Path
          data-name="Path 71006"
          d="M307.975 111.214V139.1h-9.317v-27.385a.5.5 0 01.5-.5z"
          transform="translate(.001) translate(-240.327 -89.493)"
          fill="#232e7a"
        />
        <Path
          data-name="Path 71007"
          d="M310.977 107v-3.74a17.048 17.048 0 01-12.393-16.4v-6.2a17.04 17.04 0 1134.081 0v3.1a3.873 3.873 0 013.873 3.873v1.457a3.933 3.933 0 01-3.8 3.964 3.871 3.871 0 01-1.178-.158 17.081 17.081 0 01-11.292 10.361V107h9.295a10.96 10.96 0 011.186.064 2.339 2.339 0 011.809 3.4 50.05 50.05 0 01-37.076 26.592v-19.211A10.844 10.844 0 01306.33 107z"
          transform="translate(.001) translate(-237.774 -51.198)"
          fill="#d47f6c"
        />
        <Path
          data-name="Path 71008"
          d="M364.576 81.313v6.2a17.048 17.048 0 01-12.581 16.451 8.185 8.185 0 01-4.271 0 17.048 17.048 0 01-12.582-16.454v-6.2a17.044 17.044 0 0114.717-16.88 17.044 17.044 0 0114.717 16.883z"
          transform="translate(.001) translate(-269.685 -51.846)"
          fill="#e09380"
        />
        <Path
          data-name="Path 71009"
          d="M375.605 179.766a1.162 1.162 0 011.643-1.643.852.852 0 001.206 0 1.162 1.162 0 011.643 1.643 3.176 3.176 0 01-4.493 0zm6.94 4.276a1.162 1.162 0 00-1.643 0 4.32 4.32 0 01-6.1 0 1.162 1.162 0 00-1.643 1.643 6.647 6.647 0 009.389 0 1.161 1.161 0 00-.002-1.643z"
          transform="translate(.001) translate(-300.001 -143.059)"
          fill="#b2705b"
        />
        <Path
          data-name="Path 71010"
          d="M353.671 147.247a2.324 2.324 0 11-2.324-2.324 2.324 2.324 0 012.324 2.324zm12.393-2.324a2.324 2.324 0 102.324 2.324 2.324 2.324 0 00-2.324-2.324z"
          transform="translate(.001) translate(-280.856 -116.618)"
          fill="#232e7a"
        />
        <Path
          data-name="Path 71011"
          d="M365.227 146.085a1.162 1.162 0 11-1.162-1.162 1.162 1.162 0 011.162 1.162zm13.555-1.162a1.162 1.162 0 101.162 1.162 1.162 1.162 0 00-1.162-1.162z"
          transform="translate(.001) translate(-292.024 -116.618)"
          fill="#fff5f5"
        />
        <Path
          data-name="Path 71012"
          d="M321.895 44.477A12.393 12.393 0 01309.5 56.87h-8.52a2.324 2.324 0 01-2.324-2.324 14.717 14.717 0 0114.719-14.716h3.873a4.647 4.647 0 014.647 4.647z"
          transform="translate(.001) translate(-240.327 -32.051)"
          fill="#232e7a"
        />
        <Path
          data-name="Path 71013"
          d="M420.667 52.479v4.391h-8.78a10.119 10.119 0 01-10.119-10.119v-.671a6.25 6.25 0 016.25-6.25 12.649 12.649 0 0112.649 12.649z"
          transform="translate(.001) translate(-323.298 -32.051)"
          fill="#2b3894"
        />
        <Path
          data-name="Path 71014"
          d="M367.413 375.956l-.049.04a50.113 50.113 0 01-7.247 4.96 2.436 2.436 0 01-.879.264 3.83 3.83 0 01-4.267-3.832v-20.3h17.04v9.032a12.727 12.727 0 01-4.598 9.836z"
          transform="translate(.001) translate(-285.641 -287.347)"
          fill="#ffc14f"
        />
        <Path
          data-name="Path 71015"
          d="M317.861 288.707a49.96 49.96 0 01-17.512 29.89v-26.769a21.841 21.841 0 0010.542-6.068 10.8 10.8 0 016.97 2.947zm-46.171 7.887v21.321c4.824 3.867 20.913-.818 20.913-.818v-25.269a21.841 21.841 0 01-10.542-6.068 10.844 10.844 0 00-10.371 10.834z"
          transform="translate(.001) translate(-218.626 -229.948)"
          fill="#ffde91"
        />
        <Path
          data-name="Path 71016"
          d="M282.952 286.606a10.846 10.846 0 00-6.615 9.988v24.532a39.764 39.764 0 01-4.647-3.211v-21.321a10.844 10.844 0 0110.372-10.834q.438.44.89.846z"
          transform="translate(.001) translate(-218.626 -229.948)"
          fill="#ffcd71"
        />
        <Path
          data-name="Path 71017"
          d="M132.156 410.68a49.806 49.806 0 01-4.647-2.386v-5.945a10.844 10.844 0 0110.844-10.844h6.931a10.844 10.844 0 00-10.844 10.844v7.64s-1.532 1.028-2.284.691z"
          transform="translate(.001) translate(-102.605 -315.039)"
          fill="#d3d3d8"
        />
        <Path
          data-name="Path 71018"
          d="M196.229 402.35v6.3a50.1 50.1 0 01-44.925 2.031v-8.331a10.844 10.844 0 0110.844-10.844h23.237a10.844 10.844 0 0110.844 10.844z"
          transform="translate(.001) translate(-121.753 -315.04)"
          fill="#ececf1"
        />
        <Path
          data-name="Path 71019"
          d="M244.918 454.349v10.326q-1.121.049-2.255.05-1.631 0-3.237-.1v-10.276a1.1 1.1 0 011.1-1.1h3.3a1.1 1.1 0 011.1 1.1z"
          transform="translate(.001) translate(-192.663 -364.725)"
          fill="#232e7a"
        />
        <Path
          data-name="Path 71020"
          d="M243.82 419.791h-3.3a1.1 1.1 0 01-1.1-1.1v-6.4a1.1 1.1 0 011.1-1.1h3.3a1.1 1.1 0 011.1 1.1v6.4a1.1 1.1 0 01-1.1 1.1z"
          transform="translate(.001) translate(-192.664 -330.879)"
          fill="#3342ae"
        />
        <Path
          data-name="Path 71021"
          d="M219.378 384.749l-6.226 10.67a.781.781 0 01-1.349 0l-3.513-6.019-3.512 6.018a.781.781 0 01-1.35 0l-6.226-10.67a.781.781 0 01.675-1.175H218.7a.781.781 0 01.678 1.176z"
          transform="translate(.001) translate(-158.6 -308.657)"
          fill="#d3d3d8"
        />
        <Path
          data-name="Path 71022"
          d="M177.713 218.02v16.033h-23.237v-16.43c0-.338.163-.611.364-.611h22.273c.331 0 .6.451.6 1.008z"
          transform="translate(.001) translate(-124.305 -174.627)"
          fill="#7c3f2c"
        />
        <Path
          data-name="Path 71023"
          d="M233.4 218.485v15.567h-29.352v-17.04h27.883a1.473 1.473 0 011.469 1.473z"
          transform="translate(.001) translate(-164.195 -174.627)"
          fill="#954c35"
        />
        <Path
          data-name="Path 71024"
          d="M151.211 281.827a3.873 3.873 0 01-3.873-3.873V276.4a3.873 3.873 0 117.746 0v1.549a3.873 3.873 0 01-3.873 3.878z"
          transform="translate(.001) translate(-118.561 -219.303)"
          fill="#ffbeaa"
        />
        <Path
          data-name="Path 71025"
          d="M149.662 192.66v3.1a3.867 3.867 0 001.549 3.1 3.873 3.873 0 01-3.872-3.873v-1.549a3.873 3.873 0 013.872-3.873 3.867 3.867 0 00-1.549 3.095zm35.63-3.1v-3.1a17.04 17.04 0 00-34.081 0v6.2a17.048 17.048 0 0012.393 16.4v5.289a4.647 4.647 0 109.295 0v-5.289a17.081 17.081 0 0011.292-10.36 3.875 3.875 0 004.973-3.714v-1.549a3.873 3.873 0 00-3.872-3.875z"
          transform="translate(.001) translate(-118.561 -136.333)"
          fill="#faa68e"
        />
        <Path
          data-name="Path 71026"
          d="M220.395 187.111v6.2a17.048 17.048 0 01-12.581 16.451 8.185 8.185 0 01-4.271 0 17.048 17.048 0 01-12.581-16.451v-6.2a17.044 17.044 0 0114.717-16.883 17.044 17.044 0 0114.716 16.883z"
          transform="translate(.001) translate(-153.664 -136.98)"
          fill="#ffbeaa"
        />
        <Path
          data-name="Path 71027"
          d="M231.424 285.564a1.162 1.162 0 011.643-1.643.852.852 0 001.206 0 1.162 1.162 0 011.643 1.643 3.176 3.176 0 01-4.493 0zm6.941 4.276a1.162 1.162 0 00-1.643 0 4.32 4.32 0 01-6.1 0 1.162 1.162 0 10-1.643 1.643 6.647 6.647 0 009.389 0 1.162 1.162 0 000-1.643z"
          transform="translate(.001) translate(-183.981 -228.194)"
          fill="#e09380"
        />
        <Path
          data-name="Path 71028"
          d="M209.489 253.045a2.324 2.324 0 11-2.324-2.324 2.324 2.324 0 012.324 2.324zm12.393-2.324a2.324 2.324 0 102.324 2.324 2.324 2.324 0 00-2.324-2.324z"
          transform="translate(.001) translate(-164.834 -201.752)"
          fill="#232e7a"
        />
        <Path
          data-name="Path 71029"
          d="M221.045 251.883a1.162 1.162 0 11-1.162-1.162 1.162 1.162 0 011.162 1.162zm13.555-1.162a1.162 1.162 0 101.162 1.162 1.162 1.162 0 00-1.162-1.162z"
          transform="translate(.001) translate(-176.002 -201.752)"
          fill="#fff5f5"
        />
        <Path
          data-name="Path 71030"
          d="M177.713 150.275a12.393 12.393 0 01-12.393 12.393h-8.52a2.324 2.324 0 01-2.324-2.324 14.717 14.717 0 0114.717-14.717h3.873a4.647 4.647 0 014.647 4.648z"
          transform="translate(.001) translate(-124.305 -117.185)"
          fill="#7c3f2c"
        />
        <Path
          data-name="Path 71031"
          d="M276.485 158.277v4.391h-8.78a10.119 10.119 0 01-10.119-10.119v-.671a6.25 6.25 0 016.25-6.25 12.649 12.649 0 0112.649 12.649z"
          transform="translate(.001) translate(-207.277 -117.185)"
          fill="#954c35"
        />
        <Path
          data-name="Path 71032"
          d="M150.243 339.62h-2.29a.977.977 0 01-.977-.977v-.977a.977.977 0 01.977-.977h2.29a.977.977 0 01.977.977v.977a.976.976 0 01-.977.977zM180 358.564h-2.29a.977.977 0 01-.977-.977v-.977a.977.977 0 01.977-.977H180a.977.977 0 01.977.977v.977a.977.977 0 01-.977.977zm28.609-21.873h-2.29a.977.977 0 01-.977-.977v-.977a.977.977 0 01.977-.977h2.29a.977.977 0 01.977.977v.977a.976.976 0 01-.973.977z"
          transform="translate(.001) translate(-118.271 -268.573)"
          fill="#ff9f22"
        />
        <Path
          data-name="Path 71033"
          d="M158.139 339.62h-2.2a1.465 1.465 0 010-2.93h2.2a.732.732 0 01.732.732v1.465a.732.732 0 01-.732.733zm29.761 18.944h-2.2a1.465 1.465 0 010-2.93h2.2a.732.732 0 01.732.732v1.465a.732.732 0 01-.732.733zm28.609-21.873h-2.2a1.465 1.465 0 010-2.93h2.2a.732.732 0 01.732.732v1.465a.732.732 0 01-.732.733z"
          transform="translate(.001) translate(-124.306 -268.573)"
          fill="#ffc14f"
        />
      </G>
    </Svg>
  )
}

export default Family
