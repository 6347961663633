// Frameworks
import React, { useContext, useEffect, useState } from 'react';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { Button, Icon } from 'react-native-elements';
import { Menu } from 'react-native-paper';
import { Palette } from '../../Theme';
import { useSafeAreaInsets } from '../../business/layout/layout';
import { FlatList, Text, View } from 'react-native';
import NButton from './NButton';

type FilterProps = {
  filterOptions: string[],
  selectedFilters: Set<string>,
  sortOptions: string[],
  selectedSort: string,
  onFilterSelectionChanged: (selectedFilterOptions: Set<string>) => void,
  onSortSelectionChanged: (selectedSortOption: string) => void,
  onClose?: () => void,
  testID?: string,
}

function Filter(props: FilterProps): React.ReactElement {
  const { filterOptions, selectedFilters, sortOptions, selectedSort, onFilterSelectionChanged, onSortSelectionChanged, onClose, testID } = props;
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { top } = useSafeAreaInsets();

  const [menuAnchor, setMenuAnchor] = useState({ x: 0, y: 0 });
  const [menuVisible, setMenuVisible] = useState(false);
  const [allFiltersSelected, setAllFiltersSelected] = useState(false);

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setAllFiltersSelected(filterOptions.every(f => selectedFilters.has(f)));
  }, [selectedFilters, filterOptions])

  return (
    <>
      <Button
        style={{
          display: 'flex',
        }}
        buttonStyle={{
          paddingVertical: 0,
        }}
        onPress={(event) => {
          setMenuAnchor({ x: event.nativeEvent.pageX + 40, y: event.nativeEvent.pageY - 32 + top });
          setMenuVisible(true);
        }}
        type='clear'
        icon={
          <Icon
            size={16}
            name='funnel'
            type='ionicon'
            color={Palette.teal}
          />
        }
        iconRight
        title={`FILTER`}
        titleStyle={{
          color: Palette.white,
          marginRight: 8,
          fontWeight: '600',
          fontSize: 14,
        }}
        hitSlop={{ top: 20, right: 20, bottom: 20, left: 20 }}
        testID={props.testID}
      />
      <Menu
        style={{
          borderRadius: 25,
          overflow: 'hidden',
          display: 'flex',
          //maxHeight: '80%',
        }}
        contentStyle={{
          backgroundColor: '#91CCCE',
          paddingVertical: 0,
          borderRadius: 25,
          //display: 'flex',
          //flex: 1,
          //maxHeight: '80%',
          overflow: 'hidden',
        }}
        visible={menuVisible}
        anchor={{
          x: menuAnchor.x, y: menuAnchor.y
        }}
        onDismiss={() => {
          setMenuVisible(false);
          if (onClose) {
            onClose();
          }
        }}
      >
        {
          sortOptions.length > 0 &&
          <>

            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingHorizontal: 18,
                paddingVertical: 12,
              }}
            >
              <Text
                style={{
                  color: Palette.white,
                  fontSize: 18,
                  fontWeight: '700',
                }}
              >
                {`Sort by`}
              </Text>
              <NButton
                buttonStyle={{
                  borderWidth: 0,
                }}
                style={{
                  borderWidth: 0,
                }}
                t='brightLink'
                onPress={() => {
                  setMenuVisible(false);
                  if (onClose) {
                    onClose();
                  }
                }}
                hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                icon={
                  <Icon
                    name='times-circle'
                    type='font-awesome'
                    color={'#313067'}
                    size={24}
                  />
                }
                testID={`filter-close-button`}
              />
            </View>
            {
              sortOptions.map(s => (
                <Menu.Item
                  key={s}
                  icon={() => (
                    <Icon
                      size={themeFromContext.textVariants.header.fontSize}
                      //name={s == sortSelection ? 'checkmark-circle' : 'ellipse-outline'}
                      name={s == selectedSort ? 'radio-button-on' : 'radio-button-off'}
                      type={'ionicon'}
                      color={s == selectedSort ? Palette.plum : Palette.lightgrey}
                      style={{
                        alignItems: 'flex-start',
                      }}
                    />
                  )}
                  title={s}
                  titleStyle={{
                    textTransform: 'capitalize',
                    fontFamily: themeFromContext.textVariants.body.fontFamily,
                    fontSize: 18,
                    fontWeight: '600',
                    color: Palette.plum
                  }}
                  // style={{
                  //   backgroundColor: Palette.yellow,
                  //   borderTopLeftRadius: 25,
                  //   borderTopRightRadius: 25,
                  //   paddingHorizontal: 20,
                  // }}
                  onPress={() => {
                    if (selectedSort != s) {
                      onSortSelectionChanged(s);
                    }
                  }}
                  style={{
                    backgroundColor: '#FFFFFF38',
                    marginBottom: 4,
                  }}
                  testID={`${props.testID}-sort-option`}
                />
              ))
            }
          </>
        }
        {
          filterOptions.length > 0 &&
          <>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingHorizontal: 18,
                paddingVertical: 12,
              }}
            >

              <Text
                style={{
                  color: Palette.white,
                  fontSize: 18,
                  fontWeight: '700',
                }}
              >
                {`Show`}
              </Text>

              <View style={{ flex: 1, }}></View>
              <NButton
                buttonStyle={{
                  borderWidth: 0,
                }}
                style={{
                  borderWidth: 0,
                }}
                titleStyle={{
                  color: Palette.white,
                  textDecorationLine: 'underline',
                }}
                t='link'
                onPress={() => {
                  if (allFiltersSelected) {
                    onFilterSelectionChanged(new Set<string>());
                  } else {
                    onFilterSelectionChanged(new Set<string>(filterOptions));
                  }
                }}
                hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
                title={allFiltersSelected ? `Unselect all` : 'Select all'}
                testID={`filter-select-all-button`}
              />
            </View>
            <FlatList
              style={{
                display: 'flex',
                maxHeight: 250,
              }}
              extraData={refresh}
              data={filterOptions}
              renderItem={({item}) => (
                <Menu.Item
                    key={item}
                    icon={() => (
                      <Icon
                        size={themeFromContext.textVariants.header.fontSize}
                        name={selectedFilters.has(item) ? 'checkbox' : 'square-outline'}
                        type={'ionicon'}
                        color={selectedFilters.has(item) ? Palette.plum : Palette.lightgrey}
                        style={{
                          alignItems: 'flex-start',
                        }}
                      />)}
                    title={item}
                    titleStyle={{
                      fontFamily: themeFromContext.textVariants.body.fontFamily,
                      fontSize: 18,
                      fontWeight: '600',
                      color: Palette.plum
                    }}
                    onPress={() => {

                      if (selectedFilters.has(item)) {
                        selectedFilters.delete(item);
                      } else {
                        selectedFilters.add(item);
                      }

                      onFilterSelectionChanged(selectedFilters);
                      setRefresh(!refresh);
                    }}
                    style={{
                      backgroundColor: '#FFFFFF38',
                      marginBottom: 4,
                    }}
                    testID={`${props.testID}-sort-option`}
                  />
              )}
              showsVerticalScrollIndicator={false}
            />
          </>
        }
      </Menu>
    </>
  );
}

export default Filter;