import React, { ReactElement, ReactNode, useContext, useEffect, useState } from 'react';
import { PanResponderInstance, Platform, SectionList, SectionListProps, View } from 'react-native';
import NActivityIndicator from '../ActivityIndicators/NActivityIndicator';
import { MAX_WIDTH, useSafeAreaInsets } from '../../../business/layout/layout';
import { Palette } from '../../../Theme';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import Header from '../Header/Header';
import { getSecondaryHeight, TOP_HEADER_BASE_HEIGHT } from '../../../constants/header';
import { isDesktop } from 'react-device-detect';

function ScrollableSectionListScreen(props: {
  navigation: any,
  refreshing: boolean,
  topTitle: string,
  isFocused: boolean,
  bottomTitle: string,
  p?: PanResponderInstance,
  goBack?: () => void,
  filter?: ReactNode,

  children?: ReactElement | ReactElement[],
} & SectionListProps<any, any>): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { top } = useSafeAreaInsets();
  const { height } = commonContext.dimensions;
  const [secondaryHeight, setSecondaryHeight] = useState(getSecondaryHeight(props.bottomTitle));
  const [primaryHeight, setPrimaryHeight] = useState(TOP_HEADER_BASE_HEIGHT);
  // const [anim] = useState(new Animated.Value(0));
  //const positionY = React.useRef(anim).current;
  
  //const onScroll = Animated.event([{ nativeEvent: { contentOffset: { y: positionY } } }], { useNativeDriver: false });

  useEffect(() => {
    setPrimaryHeight(TOP_HEADER_BASE_HEIGHT);
    setSecondaryHeight(getSecondaryHeight(undefined));
  }, [props.bottomTitle, height]);

  // const translateY = positionY.interpolate({
  //   inputRange: [0, primaryHeight * 2, Number.MAX_VALUE],
  //   outputRange: [0, 0, 0], // don't scroll header anymore
  //   //outputRange: [0, 0 - primaryHeight, 0 - primaryHeight],
  // });

  return (
    <View
      style={{
        justifyContent: 'center',
        height: '100%',
        backgroundColor: Palette.plum,
      }}
      testID={`scrollable-sectionlist-screen-${props.topTitle}`}
    >
      <Header
        navigation={props.navigation}
        primaryHeight={primaryHeight}
        secondaryHeight={secondaryHeight}
        //translateY={translateY}
        topTitle={props.topTitle}
        bottomTitle={props.bottomTitle}
        menuAction={props.navigation.openDrawer}
        goBack={props.goBack}
      />
      {/* <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          flex: 1,
          width: '100%',
          justifyContent: 'center',
        }}
      > */}
      <View
        style={{
          display: (!props.refreshing && props.isFocused) ? 'flex' : 'none',
          //top: Animated.add(Animated.add(Animated.multiply(Animated.subtract(1, progress), topPadding), secondaryHeight), topOffsetCompensation),
          top: top + primaryHeight + secondaryHeight,
          // transform: [{
          //   translateY: translateY
          // }],
          // paddingTop: Animated.subtract(0, translateY),
          maxWidth: MAX_WIDTH,
          width: '100%',
          alignSelf: 'center',
          //position: 'absolute',
          bottom: 0,
          backgroundColor: Palette.plum,
          borderTopLeftRadius: themeFromContext.slideUp.style.borderTopLeftRadius,
          borderTopRightRadius: themeFromContext.slideUp.style.borderTopRightRadius,
          //overflow: 'hidden',
          flex: 1,
          height: '100%',
          overflow: 'hidden',
        }}
      >
      <SectionList
        showsVerticalScrollIndicator={isDesktop && Platform.OS == 'web'}
        {...props}
        keyExtractor={item => item.id}
        style={{
          display: (!props.refreshing && props.isFocused) ? 'flex' : 'none',
          //top: Animated.add(Animated.add(Animated.multiply(Animated.subtract(1, progress), topPadding), secondaryHeight), topOffsetCompensation),
          //top: top + primaryHeight + secondaryHeight,
          // transform: [{
          //   translateY: translateY
          // }],
          // paddingTop: Animated.subtract(0, translateY),
          maxWidth: MAX_WIDTH,
          width: '100%',
          alignSelf: 'center',
          //position: 'absolute',
          bottom: 0,
          backgroundColor: Palette.plum,
          borderTopLeftRadius: themeFromContext.slideUp.style.borderTopLeftRadius,
          borderTopRightRadius: themeFromContext.slideUp.style.borderTopRightRadius,
          //overflow: 'hidden',
          flex: 1,
          height: '100%',
        }}
        //onScroll={onScroll}
        //scrollIndicatorInsets={{ top: Animated.add(scrollIndicatorInsetTop, secondaryHeight) }}
        contentContainerStyle={{
          display: 'flex',
          //flex: 1, // this made ios scrolling not work
          paddingHorizontal: 10,
        }}
        bounces={false}
        alwaysBounceVertical={false}
        testID='scrollable-sectionlist-screen'
        stickySectionHeadersEnabled={false}
      />
      </View>
      {/* <Animated.View
        style={{
          height: '100%',
          width: 14,
          backgroundColor: Palette.lightpurple,
          borderTopRightRadius: themeFromContext.slideUp.style.borderTopRightRadius,
          marginLeft: -14,
          top: top + primaryHeight + secondaryHeight,
          transform: [{
            translateY: translateY
          }],
        }}
      >
        <View
          style={{
            width: 14,
            borderRadius: 8,
            backgroundColor: '#bc6ff1',
            height: scrollIndicatorSize
          }}
        />
      </Animated.View>
      </View> */}
      <View style={{
        display: (props.refreshing && props.isFocused) ? 'flex' : 'none',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <NActivityIndicator />
      </View>
      {props.children}
    </View>
  );
}

export default ScrollableSectionListScreen;