// Frameworks
import React, { ReactElement, useContext } from 'react';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import NModal from './NModal';
import NText from '../NText';
import NButton from '../NButton';
import { View } from 'react-native';
import { Icon, Text } from 'react-native-elements';

function PromptModal(props: {
  show: boolean,
  heading: string,
  prompt: string,
  confirm: () => void,
  confirmButtonText: string,
  backdropHeight?: number,
  deny?: () => void,
  denyButtonText?: string,
  dismiss?: () => Promise<void>,
  working?: boolean,
  testID?: string,
  children?: ReactElement | ReactElement[]
}): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;

  return (
    <NModal
      name={props.heading}
      onDone={props.dismiss}
      //type={'island'}
      //backdropHeight={props.backdropHeight}
      isVisible={props.show}
      //maxWidth={400}
      doneButtonIcon={
        <Icon
          name='times-circle'
          type='font-awesome'
          color={'#313067'}
          size={30}
        />
      }
      //testID={props.testID}
    >
      <View
        style={{
          margin: 10,
          marginBottom: 20,
          alignItems: 'center',
        }}
      >
        {
          // props.dismiss &&
          // <View
          //   style={{
          //     width: '100%',
          //     alignItems: 'flex-end',
          //     paddingRight: 10,
          //     paddingTop: 10,
          //     zIndex: 100,
          //   }}
          // >
          //   <Icon
          //     type='material-community'
          //     name='close-circle-outline'
          //     color={themeFromContext.colors.danger}
          //     onPress={props.dismiss}
          //     testID={`slide-up-modal-close-button`}
          //   />
          // </View>
        }
        <NText
          style={{
            ...themeFromContext.textVariants.header,
            textAlign: 'center',
            margin: 10,
          }}
          testID='prompt-modal-heading'
        >
          {props.heading}
        </NText>
        <Text
          style={{
            ...themeFromContext.textVariants.body,
            textAlign: 'left',
            margin: 10,
            marginBottom: 25,
          }}
          testID='prompt-modal-text'
        >
          {props.prompt}
        </Text>
        {props.children}
        <NButton
          t='primary'
          title={props.confirmButtonText}
          onPress={props.confirm}
          style={{
            margin: 10,
          }}
          loading={props.working}
          testID='prompt-modal-primary-button'
        />
        {
          props.denyButtonText != undefined && props.denyButtonText.length > 0 && props.deny &&
          <NButton
            t='secondary'
            title={props.denyButtonText}
            onPress={props.deny}
            style={{
              margin: 10,
            }}
            testID='prompt-modal-secondary-button'
          />
        }
      </View>
    </NModal>
  );
}
export default PromptModal;