import { Community } from "../../API";

export function isGregHowellCommunityId(communityId?: string) : boolean {
  return communityId == '5d03338a-8e48-4509-a852-9b0d8a6e4cb9' // prod
    || communityId == '05a847e7-083b-411c-b6ee-9cc4abb59d4b' // dev
    || communityId == 'c28606da-e5fa-48c4-bbff-ec42ca6bdd36' // dev
    || communityId == '53ad1567-2c0f-4f21-b3c7-2a1f10b9cf7f'; // dev
}

export function isGregHowellCommunity(community?: Community) : boolean {
  return isGregHowellCommunityId(community?.id) ||
    community?.alias == 'greghowell';
}