/* eslint-disable react/display-name */
// Frameworks
import { DrawerScreenProps } from '@react-navigation/drawer';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { View } from 'react-native';
import NewCommunityChooseMembers from './NewCommunityChooseMembers';
import NewCommunityChooseSubjects from './NewCommunityChooseSubjects';
import NewCommunityGetStarted, { CommunityType } from './NewCommunityGetStarted';
import NewCommunityInviteEveryone from './NewCommunityInviteEveryone';
import PromptModal from '../../components/Modals/PromptModal';
import ScrollableViewScreen from '../../components/Layouts/ScrollableViewScreen';
import StepError from './StepError';
import StepHeader from './StepHeader';
import log from '../../../business/logging/logger';
import { SubjectList } from '../../../business/user/subjectList';
import { convertGoogleContactsToRelatedUserData, convertRelatedUserToRelatedUserData, convertUserToUserData, findUsersSetDifference, mergeUserDataByEmailOrNames, refreshAuthToken, RelatedUserData } from '../../../business/user/userHelper';
import { Community, CommunityUserStatus } from '../../../API';
import CommonContext from '../../../CommonContext';
import { RootStackParamList } from '../../../types/Navigation';
import { IWizardConfig } from '../../../types/Steps';
import { CommonContextType } from '../../../types/CommonContextType';
import * as Progress from 'react-native-progress';
import { Palette } from '../../../Theme';
import StepContentWrapper from './StepContent';
import { CommunityServiceError } from '../../../contracts/ICommunityService';
import { Auth } from 'aws-amplify';
import Family from '../../../../assets/svg/illustrations/Family';
import AddMember from '../../../../assets/svg/illustrations/AddMember';
import People from '../../../../assets/svg/illustrations/People';
import Envelope from '../../../../assets/svg/illustrations/Envelope';

export type CreateCommunityProps = DrawerScreenProps<RootStackParamList, 'Create'>;

const wizardConfig: IWizardConfig = {
  steps: [
    {
      image: <Family />,
      title: `Get Started`,
      step: 1
    },
    {
      image: <AddMember />,
      title: `Who is this Novella about?`,
      step: 2
    },
    {
      image: <People />,
      title: `Include others`,
      step: 3
    },
    {
      image: <Envelope />,
      title: `Wrap-up and invite!`,
      step: 4
    },
  ]
};

function CreateCommunity({ route, navigation }: CreateCommunityProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const { userService, communityService, relatedUserService } = commonContext.services;
  const themeFromContext = commonContext.theme;
  const [communityType, setCommunityType] = useState<CommunityType>(CommunityType.ME);
  //const [subjects, setSubjects] = useState<SubjectList>();
  const [members, setMembers] = useState<RelatedUserData[]>([]);
  const [customNote, setCustomNote] = useState('');
  const [current, setCurrent] = useState(1);
  const stateRef = useRef(1);
  stateRef.current = current;
  const [relatedUsers, setRelatedUsers] = useState<RelatedUserData[]>();
  //const [loggedInUser, setLoggedInUser] = useState<User>();
  const [subjectList, setSubjectList] = useState<SubjectList>();
  const [maxMembers, setMaxMembers] = useState(communityService.DEFAULT_MAX_MEMBERS);
  const [maxSubjects, setMaxSubjects] = useState(communityService.DEFAULT_MAX_SUBJECTS);
  const [maxInvitationLength, setMaxInvitationLength] = useState(communityService.DEFAULT_MAX_INVITATION_MESSAGE_LENGTH);
  const [wizardError, setWizardError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showGoogleAuthModal, setShowGoogleAuthModal] = useState(false);
  const [deniedContacts, setDeniedContacts] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [newlyCreatedCommunity, setNewlyCreatedCommunity] = useState<Community>();
  const isFocused = useIsFocused();
  const [googleWorking, setGoogleWorking] = useState(false);

  const linkTo = useLinkTo();
  if (!commonContext.loggedInUser) {
    linkTo(`/signin`);
  }

  useEffect(() => {
    if (route && route.params && route.params.authorized == 1) {
      setGoogleWorking(false);
      setShowGoogleAuthModal(false);
      setRefresh(!refresh);
    }
  }, [route?.params.authorized]);

  useEffect(() => {
    let mounted = true;
    setWizardError('');

    let promises: Promise<any>[] = [relatedUserService.getKnownUsers()];
    if (!deniedContacts) {
      promises = [
        ...promises,
        userService.getMyGoogleContacts(() => {
          setTimeout(() => setShowGoogleAuthModal(true), 400);
        }),
      ];
    }

    Promise.all(promises)
      .then((results) => {
        if (mounted) {
          const knownUsers = results[0];
          const googleContacts = results[1];

          let related = convertRelatedUserToRelatedUserData(userService, knownUsers);
          if (googleContacts) {
            related = mergeUserDataByEmailOrNames<RelatedUserData>(related, convertGoogleContactsToRelatedUserData(googleContacts), (item: RelatedUserData) => item.user);
          }
          setRelatedUsers(related);
        }
      })
      .catch((ex) => {
        log.info(`Error getting Google contacts, we might need the user to reauthorize?`);
        log.info(ex);
        setShowGoogleAuthModal(true);
      });

    // getLoggedInUser()
    //   .then((user) => {
    //     if (mounted) {
          commonContext.analytics?.viewPage(route.name, route.params);
          // setLoggedInUser(user);
          if (commonContext.loggedInUser?.communityLimits && commonContext.loggedInUser?.communityLimits.maxMembers) {
            setMaxMembers(commonContext.loggedInUser?.communityLimits.maxMembers)
          }
          if (commonContext.loggedInUser?.communityLimits && commonContext.loggedInUser?.communityLimits.maxSubjects) {
            setMaxSubjects(commonContext.loggedInUser?.communityLimits.maxSubjects)
          }
          if (commonContext.loggedInUser?.communityLimits && commonContext.loggedInUser?.communityLimits.maxInvitationLength) {
            setMaxInvitationLength(commonContext.loggedInUser?.communityLimits.maxInvitationLength)
          }
      //   }
      // })
      // .catch((ex) => {
      //   log.error(ex);
      // });
    return () => {
      mounted = false;
    }
  }, [refresh]);

  const reset = () => {
    setCurrent(1);
    setCommunityType(CommunityType.ME);
    setSubjectList(new SubjectList([]));
    setMembers([]);
    setCustomNote('');
    setRelatedUsers([]);
    setMaxMembers(communityService.DEFAULT_MAX_MEMBERS);
    setWizardError('');
    setIsLoading(false);
    setRefresh(!refresh);
    setShowConfirmation(false);
  }

  const authorizeContacts = async () => {
    if (commonContext.loggedInUser) {
      setGoogleWorking(true);
      await userService.requestGoogleContactsPermissions(`create/${commonContext.loggedInUser.id}?authorized=1`);
    }
  }

  const denyContacts = () => {
    setDeniedContacts(true);
    setShowGoogleAuthModal(false);
    setRefresh(!refresh);
  }

  const createCommunityAndInvite = async () => {

    log.info(`CREATE COMMUNITY WITH:`);
    log.info(communityType);
    log.info(subjectList?.subjects);
    log.info(members);
    log.info(customNote);

    // subjects
    if (!subjectList || subjectList.subjects.length === 0) {
      setWizardError(`Must have some Spotlight members...`);
      return;
    } else if (subjectList.subjects.length > communityService.DEFAULT_MAX_SUBJECTS) {
      setWizardError(`Too many Spotlight members...`);
      return;
    } else {
      setWizardError('');
    }

    // members
    if (members.length > maxMembers) {
      setWizardError(`Too many members...`);
      return;
    } else {
      setWizardError('');
    }

    // const dupes: string[] = [];
    // members.forEach(m => {
    //   if (subjectList.subjects.some(s => s.user.email == m.user.email)) {
    //     dupes.push(m.user.fullName);
    //   }
    // })
    // if (dupes.length > 0) {
    //   setWizardError(`Oops! Some people are in both lists: ${dupes.join(', ')}`);
    //   return;
    // }

    // logged-in user
    if (!commonContext.loggedInUser) {
      setWizardError(`No logged-in user! How did this happen?`);
      return;
    }

    // start async stuff
    setIsLoading(true);

    try {
      const community = await communityService.createCommunity(subjectList.text(), commonContext.loggedInUser, CommunityUserStatus.ORGANIZER, customNote);
      log.info(`created community: ${community.id}`);
      setNewlyCreatedCommunity(community);

      // if the logged-in user is in the memebers list, this represents a harmless misunderstanding (she thought she needed to add herself)
      // remove the logged-in user, if present
      let cleanMembers = members.filter(m => m.user.id != commonContext.loggedInUser?.id);

      // if a subject is also in the members list, this represents another harmless misunderstanding (the organizer thought the subject needed to be added twice)
      // remove subjects, if present, matched by either email, id, or FN + LN
      cleanMembers = findUsersSetDifference<RelatedUserData, RelatedUserData>(cleanMembers, subjectList.subjects, (item: RelatedUserData) => item.user, (item: RelatedUserData) => item.user);
      // cleanMembers = cleanMembers.filter(m => !subjectList.subjects.some(s => 
      //   s.user.email == m.user.email ||
      //   s.user.id == m.user.id ||
      //   (s.user.firstName.toLowerCase() == m.user.firstName.toLowerCase() && s.user.lastName.toLowerCase() == m.user.lastName.toLowerCase())));

      await communityService.inviteAndSendInvitations(cleanMembers.map(m => m.user), community.id, CommunityUserStatus.MEMBER, customNote); // as MEMBER for now, until we have an "accept" mechanism

      await communityService.inviteAndSendInvitations(subjectList.subjects.map(m => m.user), community.id, CommunityUserStatus.SUBJECT, customNote); // as SUBJECT for now, until we have an "accept" mechanism

      // for any non-users, send system invitations with custom content: name of community, custom note, name of inviting person
      //this is handled by UserListener lambda

      // for any users, send community notifications with custom content: name of community, custom note, name of inviting person
      //this is handled by CommunityUserStreamListener lambda

      // we need to refresh the logged-in user's auth tokens to reflect new community membership
      await refreshAuthToken(() => {
        // popup confirmation
        setShowConfirmation(true);
      });
    } catch (ex) {
      if (ex instanceof CommunityServiceError) {
        setWizardError(ex.userMessage);
      }
      log.error(ex);
      //setIsLoading(false);
    }
  }

  const done = () => {
    reset();
    if (newlyCreatedCommunity) {
      linkTo(`/community/${newlyCreatedCommunity.id}/members/${newlyCreatedCommunity.id}`);
    }
  }

  const handleCommunityType = (returnedCommunityType: CommunityType): void => {
    if (returnedCommunityType != communityType) {
      if (subjectList && subjectList.subjects.length > 0) {
        setSubjectList(new SubjectList([]));
      }
    }
    if (returnedCommunityType == CommunityType.ME) {
      if (commonContext.loggedInUser) {
        const rud: RelatedUserData = {
          commonUsers: [],
          user: convertUserToUserData(userService, commonContext.loggedInUser)
        }
        setSubjectList(new SubjectList([rud]));
      }
    }
    setCommunityType(returnedCommunityType);
  }

  const handleSubjects = (returnedSubjectList: SubjectList): void => {
    setSubjectList(returnedSubjectList);
  }

  const handleMembers = (returnedMembers: RelatedUserData[]): void => {
    setMembers(returnedMembers);
  }

  const handleCustomNote = (returnedCustomNote: string): void => {
    setCustomNote(returnedCustomNote)
  }

  const stepForward = () => {
    setCurrent(stateRef.current >= wizardConfig.steps.length ? stateRef.current : stateRef.current + 1);
    setWizardError('');
  }

  const stepBack = () => {
    setCurrent(stateRef.current <= 1 ? stateRef.current : stateRef.current - 1);
    setWizardError('');
  }

  const cancel = () => {
    reset();
    linkTo(`/communities/${commonContext.loggedInUser?.id}`);
  }

  return (
    <>
      {
        wizardConfig &&
        <ScrollableViewScreen
          navigation={navigation}
          isFocused={isFocused}
          refreshing={isLoading}
          bottomTitle={`New Community`}
          goBack={() => {
            log.info(`step: ${stateRef.current}`);
            if (stateRef.current == 1) {
              cancel();
            } else {
              stepBack();
            }
          }}

          showsVerticalScrollIndicator={false}
          topTitle={
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                justifyContent: 'space-between',
                width: '100%',
                paddingHorizontal: 20,
                paddingVertical: 30,
              }}
            >
              <View></View>
              <View style={{ flex: 1, width: '100%', marginHorizontal: 100, justifyContent: 'center' }}>
                <Progress.Bar progress={stateRef.current / wizardConfig.steps.length} width={null} height={5} borderColor={Palette.transparent} borderRadius={50} color={themeFromContext.colors.primary} unfilledColor={'#ffffff33'} />
              </View>
              <View></View>
            </View>
          }
        >
          {/* <StepsWrapper isLoading={isLoading}> */}
            <StepHeader config={wizardConfig} currentStep={stateRef.current} />
            <StepError message={wizardError} />
            <StepContentWrapper>
              <NewCommunityGetStarted step={1} currentStep={stateRef.current} inputPayload={communityType} onPayloadChange={handleCommunityType} onFinish={stepForward} />
              <NewCommunityChooseSubjects step={2} currentStep={stateRef.current} max={maxSubjects} inputPayload={subjectList} onPayloadChange={handleSubjects} onFinish={stepForward} relatedUsers={relatedUsers} />
              <NewCommunityChooseMembers step={3} currentStep={stateRef.current} max={maxMembers} inputPayload={members} onPayloadChange={handleMembers} onFinish={stepForward} relatedUsers={relatedUsers} subjectList={subjectList} />
              <NewCommunityInviteEveryone step={4} currentStep={stateRef.current} max={maxInvitationLength} inputPayload={customNote} onPayloadChange={handleCustomNote} onFinish={createCommunityAndInvite} subjectList={subjectList} />
            </StepContentWrapper>
            {/* <StepFooter config={wizardConfig} currentStep={current} back={stepBack} cancel={cancel} /> */}
          {/* </StepsWrapper> */}
        </ScrollableViewScreen>
      }
      <PromptModal
        show={showConfirmation}
        heading={`Success`}
        prompt={`Nice work! Your community was created, and we've sent out email invitations.`}
        confirm={done}
        confirmButtonText={'OK'}
        testID='prompt-modal-community-creation-confirmation'
      />
      {
        commonContext.loggedInUser &&
        <PromptModal
          show={showGoogleAuthModal}
          heading={`Contacts`}
          working={googleWorking}
          confirm={authorizeContacts}
          confirmButtonText={`OK, sounds good!`}
          deny={denyContacts}
          denyButtonText={`No thanks`}
          prompt={`Inviting friends and family is the most exciting part of Novella! To make it easier, let us import contacts from a Google Account.\n\nWe promise not to misuse (or even store) any of this data.\n\nIf you choose "No thanks" then you can just enter the emails by hand.`}
          testID='prompt-modal-google-contacts'
        />
      }
    </>
  );
}

export default CreateCommunity;
