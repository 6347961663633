import React, { ReactElement, ReactNode, useContext, useEffect, useState } from 'react';
import { FlatListProps, Platform, View } from 'react-native';
import NActivityIndicator from '../ActivityIndicators/NActivityIndicator';
import { Palette } from '../../../Theme';
import { MAX_WIDTH, useSafeAreaInsets } from '../../../business/layout/layout';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { normalizeHeight } from '../../../business/layout/responseSize';
import Header from '../Header/Header';
import { getSecondaryHeight, TOP_HEADER_BASE_HEIGHT } from '../../../constants/header';
import { isDesktop } from 'react-device-detect';
import { KeyboardAwareFlatList } from 'react-native-keyboard-aware-scroll-view';

function ScrollableFlatListScreen(props: {
  navigation: any,
  refreshing: boolean,
  topTitle: string,
  isFocused: boolean,
  backgroundColor?: string,
  bottomTitle?: string,
  goBack?: () => void,
  filter?: ReactNode,
  narrow?: boolean,
  children?: ReactElement | (ReactElement | boolean | undefined)[],
} & FlatListProps<any>): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { top } = useSafeAreaInsets();
  const { height, width } = commonContext.dimensions;
  const [secondaryHeight, setSecondaryHeight] = useState(getSecondaryHeight(props.bottomTitle));
  const [primaryHeight, setPrimaryHeight] = useState(normalizeHeight(TOP_HEADER_BASE_HEIGHT, height));
  // const [anim] = useState(new Animated.Value(0));
  //const positionY = React.useRef(anim).current;
  
  //const onScroll = Animated.event([{ nativeEvent: { contentOffset: { y: positionY } } }], { useNativeDriver: false });

  useEffect(() => {
    setPrimaryHeight(TOP_HEADER_BASE_HEIGHT);
    setSecondaryHeight(getSecondaryHeight(undefined));
  }, [props.bottomTitle, height]);

  // const translateY = positionY.interpolate({
  //   inputRange: [0, primaryHeight * 2, Number.MAX_VALUE],
  //   outputRange: [0, 0, 0], // dont' scroll header anymore
  //   // outputRange: [0, 0 - primaryHeight, 0 - primaryHeight],
  // })

  return (
    <View
      style={{
        justifyContent: 'center',
        flex: 1,
        backgroundColor: Palette.plum,
        overflow: 'hidden',
      }}
      testID={`scrollable-flatlist-screen-${props.topTitle}`}
    >
      <Header
        navigation={props.navigation}
        primaryHeight={primaryHeight}
        secondaryHeight={secondaryHeight}
        //translateY={translateY}
        topTitle={props.topTitle}
        bottomTitle={props.bottomTitle}
        menuAction={props.navigation.openDrawer}
        filter={props.filter}
        goBack={props.goBack}
      />
      <View
        style={{
          display: (!props.refreshing && props.isFocused) ? 'flex' : 'none',
          //top: Animated.add(Animated.add(Animated.multiply(Animated.subtract(1, progress), containerPaddingTop), secondaryHeight), topOffsetCompensation),
          top: top + primaryHeight + secondaryHeight,
          // transform: [{
          //   translateY: translateY
          // }],
          // paddingTop: Animated.subtract(0, translateY),
          maxWidth: MAX_WIDTH,
          //width: '100%',
          width: props.narrow ? width - 20 : width,
          alignSelf: 'center',
          //position: 'absolute',
          bottom: 0,
          backgroundColor: props.backgroundColor ?? Palette.plum,
          borderTopLeftRadius: themeFromContext.slideUp.style.borderTopLeftRadius,
          borderTopRightRadius: themeFromContext.slideUp.style.borderTopRightRadius,
          //overflow: 'hidden',
          flex: 1,
          height: '100%',
          overflow: 'hidden',
        }}
      >
      <KeyboardAwareFlatList
        showsVerticalScrollIndicator={isDesktop && Platform.OS == 'web'}
        {...props}
        style={{
          display: (!props.refreshing && props.isFocused) ? 'flex' : 'none',
          //top: Animated.add(Animated.add(Animated.multiply(Animated.subtract(1, progress), containerPaddingTop), secondaryHeight), topOffsetCompensation),
          //top: top + primaryHeight + secondaryHeight,
          // transform: [{
          //   translateY: translateY
          // }],
          // paddingTop: Animated.subtract(0, translateY),
          maxWidth: MAX_WIDTH,
          width: '100%',
          alignSelf: 'center',
          //position: 'absolute',
          bottom: 0,
          backgroundColor: props.backgroundColor ?? Palette.plum,
          borderTopLeftRadius: themeFromContext.slideUp.style.borderTopLeftRadius,
          borderTopRightRadius: themeFromContext.slideUp.style.borderTopRightRadius,
          //overflow: 'hidden',
          flex: 1,
          height: '100%',
        }}
        //onScroll={onScroll}
        //scrollIndicatorInsets={{ top: Animated.add(scrollIndicatorInsetTop, secondaryHeight) }}
        contentContainerStyle={{
          display: 'flex',
          //flex: 1, // this made ios scrolling not work :(
          paddingHorizontal: 10,
          //paddingTop: 20,
        }}
        bounces={false}
        alwaysBounceVertical={false}
        testID='scrollable-flatlist-screen'
        extraScrollHeight={100}
        enableResetScrollToCoords={false}
      />
      </View>
      {
        props.refreshing && props.isFocused &&
        <View style={{
          display: (props.refreshing && props.isFocused) ? 'flex' : 'none',
          height: '100%',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <NActivityIndicator />
        </View>
      }
      {props.children}
    </View>
    // </>
  );
}

export default ScrollableFlatListScreen;