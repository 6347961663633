import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function People(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={97.804}
      height={100}
      viewBox="0 0 97.804 100"
      {...props}
    >
      <G data-name="People Illustration">
        <Path
          data-name="Path 4104"
          d="M63.275 108A48.906 48.906 0 1154.1 11h.374a2.73 2.73 0 012.726 2.731v.055a43.481 43.481 0 0043.481 43.481 2.621 2.621 0 012.627 2.609v.024a48.737 48.737 0 01-13.576 33.814.88.88 0 01-.452.25c-8.263 1.788-21.932 11.411-24.78 13.506a2.969 2.969 0 01-1.22.532z"
          transform="translate(-5.5) translate(0 -8.805)"
          fill="#cdebec"
        />
        <Path
          data-name="Path 4105"
          d="M287.609 59.9H238.7a2.933 2.933 0 01-2.933-2.933V13.933a2.926 2.926 0 012.91-2.933h.03a48.9 48.9 0 0148.902 48.9z"
          transform="translate(-5.5) translate(-184.305 -8.804)"
          fill="#7fcecf"
        />
        <Path
          data-name="Path 4106"
          d="M51.353 240.838a3.049 3.049 0 013.049 3.049v48.879a48.9 48.9 0 01-48.9-48.9v-.031a3.011 3.011 0 013.019-3z"
          transform="translate(-5.5) translate(0 -192.767)"
          fill="#7fcecf"
        />
        <Path
          data-name="Path 4107"
          d="M54.4 11v48.9H5.5A48.9 48.9 0 0154.4 11z"
          transform="translate(-5.5) translate(0 -8.804)"
          fill="#cdebec"
        />
        <Path
          data-name="Path 4108"
          d="M294.5 188.243v-3.052a13.845 13.845 0 0113.845-13.845h4.26a13.845 13.845 0 0113.845 13.845v3.052z"
          transform="translate(-5.5) translate(-231.315 -137.145)"
          fill="#03bdd6"
        />
        <Path
          data-name="Path 4109"
          d="M301.529 185.12v3.123H294.5v-3.052a13.845 13.845 0 0113.845-13.845h3.788a14.108 14.108 0 00-10.604 13.774z"
          transform="translate(-5.5) translate(-231.315 -137.145)"
          fill="#0198b0"
        />
        <Path
          data-name="Path 4110"
          d="M429.395 88.464v1.153a2.882 2.882 0 01-5.765 0v-1.153a2.882 2.882 0 115.765 0z"
          transform="translate(-5.5) translate(-334.671 -68.5)"
          fill="#954d3b"
        />
        <Path
          data-name="Path 4111"
          d="M324.8 23.173v4.612a12.688 12.688 0 01-8.742 12.058v3.073a3.94 3.94 0 11-7.881 0v-3.072a12.717 12.717 0 01-7.923-7.565 2.884 2.884 0 01-3.7-2.764v-1.153a2.882 2.882 0 012.882-2.882v-2.307a12.682 12.682 0 0125.365 0z"
          transform="translate(-5.5) translate(-232.959 -8.397)"
          fill="#a95947"
        />
        <Path
          data-name="Path 4112"
          d="M298.282 27.785v2.306a2.878 2.878 0 001.153 2.306 2.883 2.883 0 01-2.882-2.882v-1.153a2.883 2.883 0 012.882-2.882 2.877 2.877 0 00-1.153 2.305zm4.612 0v-4.612a12.685 12.685 0 0110.953-12.565 12.693 12.693 0 00-14.412 12.565v4.612a12.693 12.693 0 0014.412 12.565 12.685 12.685 0 01-10.954-12.565zm10.243-.611a1.44 1.44 0 01-2.038 0 .865.865 0 00-1.223 1.223 3.169 3.169 0 004.484 0 .865.865 0 10-1.223-1.223zm3.051 5.286a.865.865 0 00-1.223 0 4.031 4.031 0 01-5.695 0 .865.865 0 00-1.223 1.223 5.763 5.763 0 008.141 0 .864.864 0 00-.001-1.223z"
          transform="translate(-5.5) translate(-232.958 -8.397)"
          fill="#954d3b"
        />
        <Path
          data-name="Path 4113"
          d="M311.093 22.456a12.683 12.683 0 0125.325 0 17.993 17.993 0 00-25.325 0z"
          transform="translate(-5.5) translate(-244.596 -8.397)"
          fill="#67382a"
        />
        <Path
          data-name="Path 4114"
          d="M315.467 19.163a14.97 14.97 0 00-4.374 3.293 12.694 12.694 0 0114.536-11.827 12.7 12.7 0 00-10.162 8.534z"
          transform="translate(-5.5) translate(-244.596 -8.397)"
          fill="#533022"
        />
        <Path
          data-name="Path 4115"
          d="M70.021 18.256A18.256 18.256 0 1151.764 0a18.256 18.256 0 0118.257 18.256z"
          transform="translate(-5.5) translate(-22.418)"
          fill="#463b83"
        />
        <Path
          data-name="Path 4116"
          d="M55.583 43.377a18.261 18.261 0 01-11.757-34.3A20.768 20.768 0 0039.2 22.162a19.934 19.934 0 0016.461 19.7c.611.095 1.511 1.176-.078 1.515z"
          transform="translate(-5.5) translate(-22.417 -7.264)"
          fill="#012d4e"
        />
        <Path
          data-name="Path 4117"
          d="M76.889 185.821v2.927H44.94v-2.927a13.844 13.844 0 0113.845-13.844h4.26a13.844 13.844 0 0113.844 13.844z"
          transform="translate(-5.5) translate(-31.568 -137.65)"
          fill="#fdf061"
        />
        <Path
          data-name="Path 4118"
          d="M51.17 185.821v2.927h-6.23v-2.927a13.844 13.844 0 0113.845-13.844h4.26q.5 0 .985.035a13.845 13.845 0 00-12.86 13.809z"
          transform="translate(-5.5) translate(-31.568 -137.65)"
          fill="#fdcb6b"
        />
        <Path
          data-name="Path 4119"
          d="M179.836 89.074v1.153a2.882 2.882 0 01-5.765 0v-1.153a2.882 2.882 0 115.765 0z"
          transform="translate(-5.5) translate(-134.924 -68.988)"
          fill="#d89c56"
        />
        <Path
          data-name="Path 4120"
          d="M75.24 23.782v4.612a12.688 12.688 0 01-8.74 12.059v3.073a3.94 3.94 0 11-7.881 0v-3.073a12.717 12.717 0 01-7.923-7.565 2.884 2.884 0 01-3.7-2.764v-1.153a2.882 2.882 0 012.882-2.882v-2.307a12.682 12.682 0 0125.365 0z"
          transform="translate(-5.5) translate(-33.211 -8.884)"
          fill="#e3ac6e"
        />
        <Path
          data-name="Path 4121"
          d="M48.722 28.394V30.7a2.878 2.878 0 001.153 2.306 2.883 2.883 0 01-2.882-2.882v-1.153a2.883 2.883 0 012.882-2.882 2.877 2.877 0 00-1.153 2.305zm4.612 0v-4.612a12.685 12.685 0 0110.952-12.565 12.693 12.693 0 00-14.411 12.565v4.612A12.693 12.693 0 0064.286 40.96a12.685 12.685 0 01-10.953-12.566zm10.243-.612a1.44 1.44 0 01-2.038 0 .865.865 0 10-1.223 1.223 3.169 3.169 0 004.484 0 .865.865 0 00-1.223-1.223zm3.051 5.286a.865.865 0 00-1.223 0 4.031 4.031 0 01-5.695 0 .865.865 0 10-1.223 1.223 5.763 5.763 0 008.141 0 .865.865 0 000-1.223z"
          transform="translate(-5.5) translate(-33.21 -8.884)"
          fill="#d89c56"
        />
        <Path
          data-name="Path 4122"
          d="M77.736 15.147l-.562 3.747a.658.658 0 01-.651.561H62.906c.817-5.9 6.32-10.355 12.388-10.355s12.389 4.7 12.389 10.355h-6.048a.659.659 0 01-.589-.364l-2.07-4.141a.658.658 0 00-1.24.2z"
          transform="translate(-5.5) translate(-45.948 -7.284)"
          fill="#463b83"
        />
        <Path
          data-name="Path 4123"
          d="M140.413 73.157a1.729 1.729 0 111.729-1.729 1.729 1.729 0 01-1.729 1.729zm12.687-1.73a1.729 1.729 0 10-1.729 1.729 1.729 1.729 0 001.729-1.729zM90.6 69.82a1.729 1.729 0 101.729 1.729A1.729 1.729 0 0090.6 69.82zm12.682 1.73a1.729 1.729 0 11-1.729-1.729 1.729 1.729 0 011.73 1.728z"
          transform="translate(-5.5) translate(-66.73 -55.786)"
          fill="#533022"
        />
        <Path
          data-name="Path 4124"
          d="M83.3 253.845v21a2 2 0 01-2 2H57.6a2 2 0 01-2-2v-21a13.845 13.845 0 0127.689 0z"
          transform="translate(-5.5) translate(-40.109 -192.096)"
          fill="#fcf6e7"
        />
        <Path
          data-name="Path 4125"
          d="M62.6 276.845h-4.99a2 2 0 01-2-2v-21a13.856 13.856 0 0116.34-13.62 13.849 13.849 0 00-11.35 13.62v21a2 2 0 002 2z"
          transform="translate(-5.5) translate(-40.109 -192.096)"
          fill="#fcf498"
        />
        <Path
          data-name="Path 4126"
          d="M99.123 424.58v3.331a48.815 48.815 0 01-26.4-14.412 13.784 13.784 0 018.3-2.763h4.26a13.845 13.845 0 0113.84 13.844z"
          transform="translate(-5.5) translate(-53.801 -328.752)"
          fill="#013f69"
        />
        <Path
          data-name="Path 4127"
          d="M129.538 425.708a48.806 48.806 0 01-18.82-13.115 13.64 13.64 0 013.089-1.858h1.887a13.844 13.844 0 0113.845 13.845z"
          transform="translate(-5.5) translate(-84.216 -328.752)"
          fill="#463b83"
        />
        <Path
          data-name="Path 4128"
          d="M179.836 327.854v1.153a2.882 2.882 0 11-5.765 0v-1.153a2.882 2.882 0 015.765 0z"
          transform="translate(-5.5) translate(-134.924 -260.107)"
          fill="#e1a595"
        />
        <Path
          data-name="Path 4129"
          d="M75.24 262.563v4.612a12.688 12.688 0 01-8.74 12.059v3.073a3.94 3.94 0 01-7.881 0v-3.073a12.717 12.717 0 01-7.923-7.565 2.885 2.885 0 01-3.7-2.764v-1.153a2.882 2.882 0 012.882-2.882v-2.306a12.682 12.682 0 1125.365 0z"
          transform="translate(-5.5) translate(-33.211 -200.005)"
          fill="#eac0b5"
        />
        <Path
          data-name="Path 4130"
          d="M48.722 267.175v2.306a2.878 2.878 0 001.153 2.306 2.883 2.883 0 01-2.882-2.882v-1.153a2.882 2.882 0 012.882-2.882 2.877 2.877 0 00-1.153 2.305zm4.612 0v-4.612A12.685 12.685 0 0164.286 250a12.693 12.693 0 00-14.412 12.565v4.612a12.682 12.682 0 0012.682 12.682 12.81 12.81 0 001.729-.117 12.685 12.685 0 01-10.952-12.567zm10.243-.611a1.44 1.44 0 01-2.038 0 .865.865 0 00-1.223 1.223 3.169 3.169 0 004.484 0 .865.865 0 00-1.223-1.223zm3.051 5.286a.865.865 0 00-1.223 0 4.031 4.031 0 01-5.695 0 .865.865 0 00-1.223 1.223 5.763 5.763 0 008.141 0 .865.865 0 000-1.223z"
          transform="translate(-5.5) translate(-33.21 -200.005)"
          fill="#e1a595"
        />
        <Path
          data-name="Path 4131"
          d="M90.1 255.833a8.516 8.516 0 01-7.328-4.172 13.393 13.393 0 01-16.764 2.165c.865-4.607 8.981-8.773 11.77-8.947 6.068 0 12.682 5.164 12.388 10.953z"
          transform="translate(-5.5) translate(-48.43 -196.002)"
          fill="#fcf6e7"
        />
        <Path
          data-name="Path 4132"
          d="M69.147 255.256a13.331 13.331 0 01-3.14-1.425c0-4.547 6.34-8.88 11.646-8.946a13.866 13.866 0 00-8.507 10.371z"
          transform="translate(-5.5) translate(-48.43 -196.006)"
          fill="#fcf498"
        />
        <Path
          data-name="Path 4133"
          d="M312.6 410.735a13.785 13.785 0 018.473 2.895 43.027 43.027 0 01-26.573 14.336v-3.386a13.845 13.845 0 0113.845-13.844h4.26z"
          transform="translate(-5.5) translate(-231.315 -328.752)"
          fill="#0ed1b2"
        />
        <Path
          data-name="Path 4134"
          d="M300.933 426.551a44.835 44.835 0 01-6.433 1.414v-3.385a13.844 13.844 0 0113.845-13.845h2.287a14.5 14.5 0 00-9.7 13.685v2.132z"
          transform="translate(-5.5) translate(-231.315 -328.752)"
          fill="#0ab59e"
        />
        <Path
          data-name="Path 4135"
          d="M332.86 241v10.773a1 1 0 01-1 1h-25.686a1 1 0 01-1-1A11.776 11.776 0 01316.947 240h14.91a1 1 0 011.003 1z"
          transform="translate(-5.5) translate(-239.856 -192.096)"
          fill="#f4aa39"
        />
        <Path
          data-name="Path 4136"
          d="M429.395 327.854v1.153a2.882 2.882 0 11-5.765 0v-1.153a2.882 2.882 0 115.765 0z"
          transform="translate(-5.5) translate(-334.671 -260.107)"
          fill="#f3d6ac"
        />
        <Path
          data-name="Path 4137"
          d="M324.8 262.563v4.612a12.688 12.688 0 01-8.742 12.058v3.073a3.94 3.94 0 11-7.881 0v-3.073a12.717 12.717 0 01-7.923-7.565 2.885 2.885 0 01-3.7-2.764v-1.153a2.882 2.882 0 012.882-2.882v-2.306a12.682 12.682 0 1125.365 0z"
          transform="translate(-5.5) translate(-232.959 -200.005)"
          fill="#f8e6cc"
        />
        <Path
          data-name="Path 4138"
          d="M298.282 267.175v2.306a2.878 2.878 0 001.153 2.306 2.883 2.883 0 01-2.882-2.882v-1.153a2.883 2.883 0 012.882-2.882 2.877 2.877 0 00-1.153 2.305zm4.612 0v-4.612A12.685 12.685 0 01313.846 250a12.693 12.693 0 00-14.412 12.565v4.612a12.682 12.682 0 0012.682 12.682 12.81 12.81 0 001.729-.117 12.685 12.685 0 01-10.952-12.567z"
          transform="translate(-5.5) translate(-232.958 -200.005)"
          fill="#f3d6ac"
        />
        <Path
          data-name="Path 4139"
          d="M354.952 333.668a.865.865 0 011.223-1.223 1.44 1.44 0 002.038 0 .865.865 0 011.223 1.223 3.17 3.17 0 01-4.484 0zm6.312 4.063a.865.865 0 00-1.223 0 4.031 4.031 0 01-5.695 0 .865.865 0 00-1.223 1.223 5.763 5.763 0 008.14 0 .864.864 0 000-1.223z"
          transform="translate(-5.5) translate(-278.035 -265.886)"
          fill="#eac0b5"
        />
        <Path
          data-name="Path 4140"
          d="M336.879 256.459a12.651 12.651 0 01-6.392-3.315 12.7 12.7 0 01-12.235 0 12.65 12.65 0 01-6.392 3.315c0-5.966 6.221-11.579 12.509-11.579s12.684 4.964 12.51 11.579z"
          transform="translate(-5.5) translate(-245.211 -196.002)"
          fill="#f4aa39"
        />
        <Path
          data-name="Path 4141"
          d="M318.06 240c-7.933 3.189-6.548 10.4-7.786 11.182a17.16 17.16 0 01-3.8 1.6 1.432 1.432 0 01-.469-.015 1 1 0 01-.83-.988A11.776 11.776 0 01316.948 240z"
          transform="translate(-5.5) translate(-239.857 -192.096)"
          fill="#fc9302"
        />
        <Path
          data-name="Path 4142"
          d="M90.6 312.547a1.729 1.729 0 111.729-1.729 1.729 1.729 0 01-1.729 1.729zm12.682-1.73a1.729 1.729 0 10-1.729 1.729 1.729 1.729 0 001.73-1.729zm38.859 0a1.729 1.729 0 10-1.729 1.729 1.729 1.729 0 001.73-1.729zm10.953 0a1.729 1.729 0 10-1.729 1.729 1.729 1.729 0 001.735-1.729z"
          transform="translate(-5.5) translate(-66.73 -247.394)"
          fill="#463b83"
        />
        <Path
          data-name="Path 4143"
          d="M149.656 69.7a.865.865 0 11-.865.865.865.865 0 01.865-.865zm10.088.865a.865.865 0 10.865-.865.865.865 0 00-.865.862zm-60.765.122a.865.865 0 10.865-.865.865.865 0 00-.865.861zm11.818-.865a.865.865 0 11-.865.865.865.865 0 01.868-.868zm-11.818 48.522a.865.865 0 10.865-.865.865.865 0 00-.865.865zm10.953 0a.865.865 0 10.865-.865.865.865 0 00-.865.865zm38.859 0a.865.865 0 10.865-.865.865.865 0 00-.865.865zm10.953 0a.865.865 0 10.865-.865.865.865 0 00-.865.865z"
          transform="translate(-5.5) translate(-74.821 -55.785)"
          fill="#fff5f5"
        />
      </G>
    </Svg>
  )
}

export default People
