import { NativeSyntheticEvent, TextInputKeyPressEventData } from "react-native";

export async function onPressEnterAsync(e: NativeSyntheticEvent<TextInputKeyPressEventData>, action: () => Promise<void>) : Promise<void> {
  if (e.nativeEvent.key == 'Enter') {
    e.stopPropagation();
    e.preventDefault();
    await action();
  }
}

export function onPressEnter(e: NativeSyntheticEvent<TextInputKeyPressEventData>, action: () => void) : void {
  if (e.nativeEvent.key == 'Enter') {
    e.stopPropagation();
    e.preventDefault();
    action();
  }
}

// export async function onPressTab(e: NativeSyntheticEvent<TextInputKeyPressEventData>, action: (() => void) | undefined) : Promise<void> {
//   if (e.nativeEvent.key == 'Tab' && action) {
//     e.stopPropagation();
//     e.preventDefault();
//     action();
//   }
// }