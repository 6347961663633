import * as React from "react"
import Svg, { G, Path } from "react-native-svg"

function Envelope(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={102.245}
      height={100}
      viewBox="0 0 102.245 100"
      {...props}
    >
      <G data-name="Envelope Illustration">
        <Path
          data-name="Path 71226"
          d="M168.151 55.428a50 50 0 01-81.726 38.72 2.224 2.224 0 01-.139-3.292 50 50 0 000-70.711 2.231 2.231 0 01.155-3.306 50 50 0 0181.709 38.59z"
          transform="translate(0 -5.5) translate(-68.151)"
          fill="#7fcecf"
        />
        <Path
          data-name="Path 71227"
          d="M96.351 128.687a6.109 6.109 0 01-4.8 3.853 238 238 0 01-28.49 1.96h-.816c-8.876-.028-33.72-.486-43.935-1.322a50 50 0 010-77.353c10.216-.836 35.059-1.294 43.935-1.322h.816a237.993 237.993 0 0128.488 1.957 6.109 6.109 0 014.8 3.853 96.8 96.8 0 015.896 34.187 96.8 96.8 0 01-5.893 34.187z"
          transform="translate(0 -5.5) translate(0 -39)"
          fill="#ffd95c"
        />
        <Path
          data-name="Path 71228"
          d="M69.524 134.123h-.816c-8.876-.028-33.72-.486-43.935-1.322a50.313 50.313 0 01-5.4-5.15l30.2-20.833a4.468 4.468 0 00.862 0l31.352 21.626a2.622 2.622 0 003.281-4.072l-22.083-20.684C79 98.748 92.454 90.325 100.372 80.647A47.3 47.3 0 0097.2 68.729c-9.064 12.716-23.712 23.792-39.833 29.7H42.634A83.9 83.9 0 019.589 76.681a49.95 49.95 0 018.434-14.534 50 50 0 00.287 77.116c10.216.836 35.059 1.294 43.935 1.322h.816a237.993 237.993 0 0028.488-1.957 6.109 6.109 0 004.8-3.853q.47-1.228.906-2.5a242.921 242.921 0 01-27.734 1.847zM6.838 87.97c7.759 6.582 18.114 12 30.177 15.719l-21.071 19.736a49.773 49.773 0 01-9.482-29.3 50.485 50.485 0 01.375-6.153z"
          transform="translate(0 -5.5) translate(0 -45.086)"
          fill="#f7b64c"
        />
        <Path
          data-name="Path 71229"
          d="M200.612 219.806a15.306 15.306 0 11-15.306-15.306 15.306 15.306 0 0115.306 15.306z"
          transform="translate(0 -5.5) translate(-135.306 -158.388)"
          fill="#fd4343"
        />
        <Path
          data-name="Path 71230"
          d="M192.293 237.074a.466.466 0 01-.316-.836q.178-.137.35-.281a10.934 10.934 0 10-16.562-13.722q-.006-.208-.006-.418a15.238 15.238 0 011.992-7.556 15.308 15.308 0 1020.87 20.87 15.212 15.212 0 01-6.315 1.942h-.013zm-6.986-2.94a6.56 6.56 0 116.56-6.56 6.56 6.56 0 01-6.56 6.56z"
          transform="translate(0 -5.5) translate(-135.307 -166.156)"
          fill="#d00041"
        />
        <Path
          data-name="Path 71231"
          d="M141.272 205l2.514 5.094a3.462 3.462 0 002.606 1.894l5.621.817a.577.577 0 01.32.984l-4.068 3.965a3.461 3.461 0 00-1 3.064l.96 5.6a.577.577 0 01-.837.608l-5.028-2.643a3.461 3.461 0 00-3.222 0l-5.028 2.643a.577.577 0 01-.837-.608l.96-5.6a3.462 3.462 0 00-1-3.064l-4.068-3.965a.577.577 0 01.32-.984l5.621-.817a3.461 3.461 0 002.606-1.894l2.525-5.094a.577.577 0 011.035 0z"
          transform="translate(0 -5.5) translate(-102.674 -158.527)"
          fill="#fdf7bd"
        />
        <Path
          data-name="Path 71232"
          d="M182.724 178.026l-4.068 3.965a3.461 3.461 0 00-1 3.064l.018.107a13.869 13.869 0 01-10.495-9.669 3.46 3.46 0 00.93-1.161l2.514-5.094a.577.577 0 011.035 0l2.514 5.094a3.462 3.462 0 002.606 1.894l5.621.817a.577.577 0 01.325.983zm25.309-11.148l-3.576-1.411a3.205 3.205 0 01-1.805-1.805l-1.411-3.576a.534.534 0 00-.994 0l-1.411 3.576a3.205 3.205 0 01-1.805 1.805l-3.576 1.411a.534.534 0 000 .994l3.576 1.411a3.205 3.205 0 011.805 1.805l1.411 3.576a.534.534 0 00.994 0l1.411-3.576a3.205 3.205 0 011.805-1.805l3.576-1.411a.534.534 0 000-.994z"
          transform="translate(0 -5.5) translate(-133.065 -122.768)"
          fill="#fff5f5"
        />
      </G>
    </Svg>
  )
}

export default Envelope
