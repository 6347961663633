import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { BottomTabBarProps } from '@react-navigation/bottom-tabs';
import { TourGuideZone } from 'rn-tourguide';
import { normalizeHeight } from '../../../business/layout/responseSize';
import { Platform, Pressable, View } from 'react-native';
import { Icon, Text } from 'react-native-elements';
import { useLinkTo } from '@react-navigation/native';
import { DELIMITER } from '../RootNavigator/HelpTooltip';
import { MAX_WIDTH, PHONE_BREAK, useSafeAreaInsets } from '../../../business/layout/layout';
import BottomTabButtonBadge from './NBottomTabButtonBadge';
import { CommunityType, NextStepType } from '../../../API';
import { tabForNextStep } from '../../../business/user/userHelper';
import log from '../../../business/logging/logger';
import { Palette } from '../../../Theme';

function NBottomTabBar(props: { communityId: string, onTap?: (name: string) => void, nextSteps?: NextStepType[] } & BottomTabBarProps): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { width, height } = useContext<CommonContextType>(CommonContext).dimensions;
  const linkTo = useLinkTo();
  const { bottom } = useSafeAreaInsets();

  const [bottomBarHeight, setBottomBarHeight] = useState(Math.max(normalizeHeight(50, height), 50));
  const [tooltipBottomOffset, setTooltipBottomOffset] = useState(normalizeHeight(250, height));
  const [iconSize, setIconSize] = useState(Math.max(normalizeHeight(20, height), 20));
  const [tabsWithNextStep, setTabsWithNextStep] = useState<string[]>([]);

  useEffect(() => {
    let tabs: string[] = [];
    if (props.nextSteps) {
      props.nextSteps.forEach(ns => {
        tabs = [ ...tabs, tabForNextStep(ns) ];
      });
    }
    setTabsWithNextStep(tabs);
  }, [props.nextSteps]);

  useEffect(() => {
    setBottomBarHeight(Math.max(normalizeHeight(50, height), 50));
    setIconSize(Math.max(normalizeHeight(24, height), 24));
    setTooltipBottomOffset(normalizeHeight(250, height));
  }, [height]);

  const onPress = (route: any, index: number) => {
    if (props.onTap) {
      props.onTap(route.name);
    }
    if (route.name === 'Questions') {
      linkTo(`/community/${props.communityId}/${route.name.toLowerCase()}/asked/${props.communityId}`);
    } else {
      linkTo(`/community/${props.communityId}/${route.name.toLowerCase()}/${props.communityId}`);
    }
  };

  return (
    <View
      pointerEvents={'box-none'}
      style={{
        filter: 'drop-shadow(-1px -2px 8px rgba(50, 50, 0, 0.1))',
        position: 'absolute',
        bottom: 0,
        width: '100%',
        shadowColor: Platform.OS!=='web'?'#171717': '',
        shadowOpacity:Platform.OS!=='web'? 0.07:0,
        shadowRadius: Platform.OS!=='web'?5:0,
        overflow: Platform.OS == 'web' ? 'hidden' : 'visible',
        //backgroundColor: themeFromContext.colors.background,
      }}
    >
      <View
        pointerEvents={'box-none'}
        style={{
          display: 'flex',
          flexDirection: 'row',
          height: bottomBarHeight + (bottom / 2),
          alignItems: 'center',
          maxWidth: MAX_WIDTH,
          alignSelf: 'center',
          width: '100%',
          backgroundColor: themeFromContext.colors.transparent,
        }}>
        {
          // TAB BUTTONS
          props.state.routes.map((route, index) => {
            const { options } = props.descriptors[route.key];
            const isFocused = props.state.index === index;
            const displayName = route.name === 'Recommended' ? 'Suggested' : route.name;
            const showNextStepBadge = tabsWithNextStep.includes(route.name);

            let iconName = 'chatbubbles-outline';
            if (route.name === 'Questions') {
              iconName = isFocused ? 'chatbubbles' : 'chatbubbles';
            } else if (route.name === 'Recommended') {
              iconName = isFocused ? 'bulb' : 'bulb';
            } else if (route.name === 'Members') {
              iconName = isFocused ? 'people' : 'people';
            } else if (route.name === 'Interview') {
              iconName = isFocused ? 'play-circle' : 'play-circle';
            } else if (route.name === 'Watch') {
              iconName = isFocused ? 'play-circle' : 'play-circle';
            } else if (route.name === 'Activity') {
              iconName = isFocused ? 'notifications' : 'notifications';
            } else {
              //return;
            }

            let zone = 1;
            let text = '';
            const communityType = commonContext.communities[props.communityId]?.type ?? CommunityType.DEFAULT;
            if (route.name === 'Questions') {
              zone = 2;
              text = 
                communityType == CommunityType.PASSED
                  ?
                  `Questions${DELIMITER}Already know what you want to share about the Spotlight, or to ask other members?\n\nGo here to type your prompts or questions. You will also see any other questions that have been asked.`
                  :
                  `Questions${DELIMITER}Already know what you want to ask? Go here to add your questions. You will also see any questions others have asked.`;
            } else if (route.name === 'Recommended') {
              //zone = 2;
              text = 
                communityType == CommunityType.PASSED
                  ?
                  `Suggested${DELIMITER}Still unsure what to share? Visit the "Suggested" page to get some ideas and choose from our most recommended prompts and questions.`
                  :
                  `Suggested${DELIMITER}Still unsure? Visit the "Suggested" page to get some ideas and choose from questions we've recommended just for you!`;
            } else if (route.name === 'Members') {
              zone = 3;
              text = 
                communityType == CommunityType.PASSED
                  ?
                  `Members${DELIMITER}The "Members" page is where you can see who else is part of this community. Organizers can invite others from here, too.`
                  :
                  `Members${DELIMITER}The "Members" page is where you can see who else is part of this community. Organizers and Spotlight members can invite others from here, too.`;
            } else if (route.name === 'Interview') {
              zone = 5;
              text = 
                communityType == CommunityType.PASSED
                  ?
                  `Asked${DELIMITER}Already know what you want to share about the Spotlight, or to ask other members?\n\nGo here to type your prompts or questions. You will also see any other questions that have been asked.`
                  :
                  `Interview${DELIMITER}When everyone is done asking questions, the Organizer goes here to contact the Novella team about scheduling an interview of the Spotlight member(s). This is also where you'll come to watch and enjoy the finished product.`;
            } else if (route.name === 'Activity') {
              zone = 1;
              text = 
                communityType == CommunityType.PASSED
                  ?
                  `Activity History${DELIMITER}This shows the activity of all members across the entire Novella community. You can view, filter, sort, and navigate directly to any past activity.`
                  :
                  `Activity History${DELIMITER}This shows the activity of all members across the entire Novella community. You can view, filter, sort, and navigate directly to any past activity.`;
            }

            const style: any = {
              flex: 1,
              height: '100%',
              overflow: 'hidden',
              borderWidth: 1,
              borderColor: Palette.lightpurple,
              backgroundColor: Palette.lightpurple,
              paddingBottom: (bottom/3*2 ),
              //filter: 'drop-shadow(-1px -2px 8px rgba(50, 50, 0, 0.1))',
            };
            // if (route.name === 'Questions') {
            //   // nothing
            // } else if (route.name === 'Recommended') {
            //   style = {
            //     ...style,
            //     //borderTopRightRadius: 50,
            //     marginRight: 32,
            //   }
            // } else if (route.name === 'Members') {
            //   style = {
            //     ...style,
            //     //borderTopLeftRadius: 50,
            //     marginLeft: 32,
            //   }
            // } else if (route.name === 'Interview') {
            //   // nothing
            // }

            return (
              // eslint-disable-next-line react/jsx-key
              <TourGuideZone
                style={{
                  ...style,
                }}
                key={`tgz-${route.name}`}
                zone={zone}
                text={text}
                shape='circle'
                tooltipBottomOffset={tooltipBottomOffset}
                borderRadius={0}
                maskOffset={-10}
              >
                <Pressable
                  pointerEvents='auto'
                  accessibilityRole="button"
                  accessibilityState={isFocused ? { selected: true } : {}}
                  accessibilityLabel={options.tabBarAccessibilityLabel}
                  testID={`community-bottom-tab-${route.name}`}
                  onPress={() => onPress(route, index)}
                  //onLongPress={onLongPress}
                  style={({ pressed }) => [
                    {
                      flex: 1,
                      backgroundColor: themeFromContext.colors.transparent,
                      height: '100%',
                      justifyContent: 'center',
                      borderTopWidth: 4,
                    },
                    isFocused ?
                    {
                      borderTopColor: Palette.plum,
                    } :
                    {
                      borderTopColor: Palette.transparent,
                    }
                  ]}
                >
                  {
                    showNextStepBadge &&
                    <BottomTabButtonBadge testID={`bottom-tab-bar-badge-${route.name}`} />
                  }
                  <Icon name={iconName} type='ionicon' color={isFocused ? Palette.plum : Palette.white} size={iconSize} />
                  <Text
                    numberOfLines={1}
                    style={[
                      width > PHONE_BREAK ? themeFromContext.textVariants.bottomTabLabelLarge : themeFromContext.textVariants.bottomTabLabelSmall,
                      {
                        textAlign: 'center',
                        color: isFocused ? Palette.plum : Palette.white,
                        fontWeight: isFocused ? '700' : '400',
                      }
                    ]}
                  >
                    {displayName}
                  </Text>
                </Pressable>
              </TourGuideZone>
            );
          })
        }
      </View>
    </View>
  );
}
export default NBottomTabBar;