// Frameworks
import React, { useContext, useEffect, useState } from 'react';
import { Animated, Easing, Platform, View } from 'react-native';
import { Avatar, Icon, Text } from 'react-native-elements';

// Expo tools
import Constants from 'expo-constants';
import * as ImagePicker from 'expo-image-picker';
import * as Permissions from 'expo-permissions';
import * as ImageManipulator from "expo-image-manipulator";

// Theme
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';

// Navigation
import { RootStackParamList } from '../../../types/Navigation';
import { DrawerScreenProps } from '@react-navigation/drawer';
import { User } from '../../../API';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import NButton from '../../components/NButton';
import log from '../../../business/logging/logger';
import { v4 as uuid } from 'uuid';
import NTextInput from '../../components/NTextInput';
import { subscribeToUserUpdates } from '../../../business/user/userHelper';
import MemberAvatar from '../../components/MemberAvatar';
import { getUserFile, putUserFile } from '../../../business/storage/userContent';
import ScrollableViewScreen from '../../components/Layouts/ScrollableViewScreen';
import SlideUpView from '../../components/Modals/SlideUpView';
import { MAX_WIDTH } from '../../../business/layout/layout';
import { Palette } from '../../../Theme';

export type ProfileProps = DrawerScreenProps<RootStackParamList, 'Profile'>;

const profilePhotoSize = 385;

function ProfileScreen({ route, navigation }: ProfileProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const { userService } = commonContext.services;
  const themeFromContext = commonContext.theme;
  const [fullName, setFullName] = useState('');
  //const [loggedInUser, setLoggedInUser] = useState<User>();
  const [targetUser, setTargetUser] = useState<User>();
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [saving, setSaving] = React.useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [profileImageKey, setProfileImageKey] = useState('');
  const [profileImageUri, setProfileImageUri] = useState('');
  const [newFilename, setNewFilename] = useState('');
  const [modalProfileImageUri, setModalProfileImageUri] = useState('');
  const targetUserId = route.params?.userId;
  const isFocused = useIsFocused();
  const { width, height } = commonContext.dimensions;
  const scale = Math.min(width, height);
  const fullAvatarSize = Math.min(scale / 2, profilePhotoSize * 0.67);
  const [anim] = useState(new Animated.Value(0));
  const [showRewardFooter, setShowRewardFooter] = useState(false);
  const linkTo = useLinkTo();

  useEffect(() => {
    if (showRewardFooter) {
      Animated.timing(
        anim,
        {
          toValue: 1,
          duration: 500,
          useNativeDriver: false,
          easing: Easing.ease,
        }
      ).start();
    } else {
      Animated.timing(
        anim,
        {
          toValue: 0,
          duration: 10,
          useNativeDriver: false,
          easing: Easing.ease,
        }
      ).start();
    }
  }, [showRewardFooter]);

  useEffect(() => {
    log.info(`newTargetUserId: ${targetUserId}`);
    let mounted = true;
    setLoading(true);
    let subscription: any = null;
    if (isFocused) {

      if (!commonContext.loggedInUser) {
        setTimeout(() => {
          linkTo(`/signin`);
        }, 500);
      }

      commonContext.analytics?.viewPage(route.name, route.params);

      Promise.all([
        userService.getUser(targetUserId)
      ]).then((results) => {
        if (mounted) {
          const tu = results[0]; // can be null / undefined
          handleTargetUser(tu);

          setTimeout(() => {
            setShowRewardFooter(true);
          }, 1000);
        }
      }).catch((ex) => {
        // sometimes we get errors we don't care about, so try to get the user data and bind anyways...
        log.warn(ex.errors);
        if (mounted && ex.data) {
          const tu = ex.data.getUser;
          handleTargetUser(tu);

          setTimeout(() => {
            setShowRewardFooter(true);
          }, 1000);
        }
      });

      // subscribe to User updates
      subscription = subscribeToUserUpdates(targetUserId, (u) => {
        if (mounted) {
          handleTargetUser(u);
        }
      });
    } else {
      setShowRewardFooter(false);
    }

    return () => {
      subscription?.unsubscribe();
      mounted = false;
      //log.info(`UNsubscribed for userId: ${targetUserId}`);
    };
  }, [targetUserId, isFocused]);

  const handleTargetUser = (tu: User) => {

    // if we were waiting for an updated user record, we got it and can stop the UI cues
    setSaving(false);
    setEditing(false);
    //showProfileEdit(false);

    setTargetUser(tu);
    setFullName(userService.getFullName(tu));

    if (tu.profileImageKey) {
      setProfileImageKey(tu.profileImageKey);
      setNewFilename('');
      getUserFile(userService, tu.profileImageKey, tu.id)
        .then(url => {
          //log.info(url);
          setProfileImageUri(url);
          setModalProfileImageUri(url);
        })
        .catch(ex => {
          log.error(ex);
        })
    }
    setLoading(false);
  }

  async function getPermissions() {
    if (Constants.platform?.ios) {
      const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
      if (status !== 'granted') {
        log.error('Sorry, we need camera roll permissions to make this work!');
      }
    }
  }

  async function pickImage() {
    try {
      await getPermissions();
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true, aspect: [1, 1], quality: 1,
      });
      if (!result.canceled) {
        const iw = result.assets[0].width;
        const ih = result.assets[0].height;
        const cropSide = Math.min(iw, ih);
        const resizeSide = Math.min(cropSide, profilePhotoSize);
        let actions: ImageManipulator.Action[] = [{ resize: { width: resizeSide, height: resizeSide } }];
        if (iw != ih) {
          let cropOriginX = 0;
          let cropOriginY = 0;
          if (iw > ih) {
            //landscape
            const diff = iw - ih;
            cropOriginX = diff / 2;
          } else {
            // portrait
            const diff = ih - iw;
            cropOriginY = diff / 2;
          }

          // order matters, so put crop first
          actions = [{ crop: { originX: cropOriginX, originY: cropOriginY, width: cropSide, height: cropSide } }, ...actions];
        }
        const manipResult = await ImageManipulator.manipulateAsync(
          result.assets[0].uri,
          actions,
          { compress: 0.9 }
        );
        //setFormState({ ...formState, image: imageId })
        const filename = uuid();
        setNewFilename(filename)
        setModalProfileImageUri(manipResult.uri);
      }
      log.info({ result });
    } catch (error) {
      log.info({ error });
    }
  }

  const showProfileEdit = (show: boolean) => {
    if (show != editing) {
      setEditing(show);
      if (show) {
        setShowRewardFooter(false);
        if (targetUser?.firstName) {
          setFirstName(targetUser?.firstName);
        }
        if (targetUser?.lastName) {
          setLastName(targetUser?.lastName);
        }

        setModalProfileImageUri(profileImageUri);
        setNewFilename('');
      } else {
        setTimeout(() => {
          setShowRewardFooter(true);
        }, 1000);
      }
    }
  }

  const pressSave = async () => {
    if (firstName == targetUser?.firstName && lastName == targetUser?.lastName && newFilename.length === 0) {
      showProfileEdit(false);
      return;
    }

    setSaving(true);
    try {

      let storageKey = profileImageKey;
      const imageUpdated = (newFilename.length > 0);
      if (imageUpdated) {
        const response = await fetch(modalProfileImageUri)
        const blob = await response.blob();
        storageKey = await putUserFile(userService, newFilename, blob);
        setProfileImageKey(storageKey);
        //await Storage.put(profileImageKey, blob);
      }

      // Don't need to await because we don't use the response anyway
      userService.updateUserProfile(targetUserId, firstName, lastName, storageKey);

      // DEBUG
      // if (targetUser?.id) {
      //   log.info(`DEBUG: getting username for userId: ${targetUser.id}`);
      //   const username = targetUser?.username;
      //   const identityId = targetUser?.identityID;
      //   log.info(`DEBUG: got username: ${username}`);
      //   log.info(`DEBUG: got identityId: ${identityId}`);
      //   log.info(`DEBUG: uploading image with key: ${storageKey}`);
      //   const me = await Auth.currentAuthenticatedUser();

      //   log.info(`DEBUG: current cognito user:`);
      //   log.info(me);
      // }

    } catch (ex) {
      log.error(ex);
      setSaving(false);
    }

    // don't hang the modal longer than 5 seconds, no matter what
    setTimeout(() => {
      setSaving(false);
      setEditing(false);
    }, 5000);
  }

  return (
    <View
      style={{
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <ScrollableViewScreen
        navigation={navigation}
        isFocused={isFocused}
        refreshing={loading}
        //topTitle={``}
        bottomTitle={`Profile`}
        //goBack={() => linkTo(`/communities/${commonContext.loggedInUser?.id}/`)}
        goBack={navigation.goBack}
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          //zIndex: 10,
        }}
        contentContainerStyle={{
          alignItems: 'center',
          justifyContent: 'flex-start',
          flex: 1,
          //height: '100%',
          padding: 24,
        }}
        testID={`profile-screen-view`}
      >

        <View
          style={{
            ...themeFromContext.card.style,
            //width: '100%',
            //marginTop: 0 - 80,
            paddingVertical: 40,
            alignItems: 'center',
          }}
        >
          <MemberAvatar
            size={120}
            userData={targetUser}
            ignoreUpdate={true}
            containerStyle={{
              marginBottom: 16,
              backgroundColor: Palette.lightteal
            }}
          >
            {
              commonContext.loggedInUser?.id === targetUserId &&
              <Avatar.Accessory
                type='material-community'
                name='camera'
                size={20}
                onPress={() => showProfileEdit(true)}
                color={themeFromContext.colors.foreground}
                style={{
                  backgroundColor: themeFromContext.colors.background,
                  width: Platform.OS !== 'web' ? 30 : 0,
                  height: Platform.OS !== 'web' ? 30 : 0,
                  padding: Platform.OS !== 'web' ? 0 : 16,
                  borderRadius: 100,
                  marginLeft: 0 - 6,
                  marginTop: 0 - 6,
                  filter: 'drop-shadow(2px 2px 6px rgba(50, 50, 0, 0.2))'
                }}
              />
            }
          </MemberAvatar>
          <Text
            style={{
              ...themeFromContext.textVariants.profileHeader,
              paddingHorizontal: 10,
              textAlign: 'center',
            }}
          >
            {fullName}
          </Text>
          {
            commonContext.loggedInUser?.id === targetUserId &&
            <Text
              style={{
                ...themeFromContext.textVariants.profileEmail,
                paddingHorizontal: 10,
                textAlign: 'center',
              }}
              numberOfLines={1}
            >
              {targetUser?.email}
            </Text>
          }
          {
            commonContext.loggedInUser?.id === targetUserId &&
            <View
              style={{
                filter: 'drop-shadow(2px 4px 8px rgba(50, 50, 0, 0.2))'
              }}
            >
              <NButton
                t="primary"
                title={`EDIT`}
                onPress={() => showProfileEdit(true)}
                style={{
                  marginTop: 16,
                }}
                buttonStyle={{
                  paddingHorizontal: 38,
                }}
                titleStyle={{
                  ...themeFromContext.textVariants.detail,
                }}
              />
            </View>
          }
        </View>
      </ScrollableViewScreen>


      <SlideUpView
        onCancel={() => { showProfileEdit(false); }}
        onCommit={pressSave}
        show={editing}
        working={saving}
        buttonTitle={'OK'}
        percent={90}
      >
        <View
          style={{
            alignItems: 'center',
          }}
        >
          <Avatar
            size={fullAvatarSize}
            rounded
            title={targetUser ? userService.getInitials(targetUser) : ''}
            source={{
              uri: modalProfileImageUri,
            }}
            overlayContainerStyle={{
              backgroundColor: themeFromContext.colors.primary
            }}
            containerStyle={{
              marginBottom: 10,
            }}
            placeholderStyle={{
              backgroundColor: themeFromContext.colors.primary,
            }}
          />
          <Text
            style={{
              ...themeFromContext.textVariants.detail,
              color: themeFromContext.colors.secondary,
              textAlign: 'right',
              marginBottom: themeFromContext.spacing.s,
            }}
          >
            {`Recommended dimensions: ${profilePhotoSize}x${profilePhotoSize}`}
          </Text>
          <NButton
            title={`Choose image`}
            onPress={pickImage}
            t={'link'}
          />
          <View
            style={{
              width: '100%',
              paddingBottom: 80,
            }}
          >
            <View>
              <Text
                style={{
                  ...themeFromContext.textVariants.formLabel,
                  //color: themeFromContext.colors.primary,
                  textAlign: 'left',
                  paddingLeft: 10,
                }}
              >
                {`First name:`}
              </Text>
            </View>
            <View>
              <NTextInput
                value={firstName}
                onChangeText={setFirstName}
                maxLength={100}
              />
            </View>

            <View>
              <Text
                style={{
                  ...themeFromContext.textVariants.formLabel,
                  //color: themeFromContext.colors.primary,
                  textAlign: 'left',
                  paddingLeft: 10,
                }}
              >
                {`Last name:`}
              </Text>
            </View>
            <View>
              <NTextInput
                value={lastName}
                onChangeText={setLastName}
                maxLength={100}
              />
            </View>

          </View>
        </View>
      </SlideUpView>

      <Animated.View
        style={[
          {
            //display: showRewardFooter ? 'flex' : 'none',
            display: 'none',
            //flexDirection: 'row',
            position: 'absolute',
            transform: [
              // from HEIGHT to HEIGHT - VIEWHEIGHT
              { translateY: Animated.multiply(-200, anim) }
            ],
            bottom: -200,
            //overflow: 'hidden',
            width: '100%',
            maxWidth: MAX_WIDTH,
            alignSelf: 'center',
            justifyContent: 'center',
            zIndex: 10000,
            padding: 24,
            backgroundColor: themeFromContext.colors.transparent,
          },
        ]}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: width < 500 ? 'column' : 'row',
            flex: 1,
            //height: normalizeHeight(70, height),
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            // borderRadius: 8,
            // borderWidth: 1,
            // borderColor: Palette.grey,
            //...themeFromContext.card.style,
            borderColor: themeFromContext.colors.toastBorder,
            backgroundColor: themeFromContext.colors.toastBackground,
            borderWidth: 1,
            borderRadius: 8,
            padding: 14,
          }}
        >
          <Icon
            size={32}
            name={'gift-outline'}
            type='material-community'
            color={Palette.heart}
            style={{
              paddingBottom: 4,
            }}
          />
          <Text
            style={{
              ...themeFromContext.textVariants.body,
              textAlign: 'center',
              padding: 10,
            }}
          >
            {`Earn $50 when you refer a friend!`}
          </Text>
          <NButton
            t={`secondary`}
            title={`Learn more`}
            onPress={() => linkTo(`/rewards/${commonContext.loggedInUser?.id}`)}
          // icon={
          //   <Icon
          //     size={themeFromContext.textVariants.header.fontSize}
          //     name={'gift-outline'}
          //     type='material-community'
          //     color={themeFromContext.colors.background}
          //     style={{
          //       padding: 4,
          //       paddingTop: 0,
          //     }}
          //   />
          // }
          />
        </View>
      </Animated.View>
    </View>
  );
}

export default ProfileScreen;