import { ICommunityService } from "../../contracts/ICommunityService";
import { IUserService } from "../../contracts/IUserService";
import { convertUserToUserData, subscribeTo } from "../user/userHelper";
import log from "../logging/logger";
import { Auth } from "aws-amplify";
import { CommunityUser } from "../../API";
import * as s from '../../graphql/subscriptions';
import { getLocalItem, removeLocalItem, setLocalItem } from "../cache/localForageHelper";

export const setAutoJoin = async (communityId: string): Promise<void> => {
  await setLocalItem('joiningCommunity', communityId);
}

export const getAutoJoin = async (): Promise<string | null> => {
  return await getLocalItem('joiningCommunity');
}

let subCreate: any;
let subUpdate: any;
export const autoJoin = async (userService: IUserService, communityService: ICommunityService, communityId: string, done: () => void): Promise<void> => {
  const lu = await userService.getLoggedInUser();

  if (subCreate) {
    subCreate.unsubscribe();
  }
  if (subUpdate) {
    subUpdate.unsubscribe();
  }
  subCreate = subscribeTo<CommunityUser>(
    lu.id,
    "userID",
    s.onCreateCommunityUserByUserId,
    "onCreateCommunityUserByUserId",
    (item: CommunityUser) => {
      if (item.communityID == communityId) {
        log.info('JOINED via create, need to refresh tokens!');
        removeLocalItem('joiningCommunity')
          .then(() => {
            Auth.currentAuthenticatedUser()
              .then(cognitoUser => {
                const currentSession = cognitoUser.signInUserSession;
                cognitoUser.refreshSession(currentSession.refreshToken, () => {
                  // do something with the new session
                  removeLocalItem('joiningCommunity')
                    .then(() => {
                      done();
                    })
                });
              });
          })
          .catch((e2) => {
            log.error(e2);
          });
      }
    }
  );
  subUpdate = subscribeTo<CommunityUser>(
    lu.id,
    "userID",
    s.onUpdateCommunityUserByUserId,
    "onUpdateCommunityUserByUserId",
    (item: CommunityUser) => {
      if (item.communityID == communityId) {
        log.info('JOINED via update, need to refresh tokens!');
        removeLocalItem('joiningCommunity')
          .then(() => {
            Auth.currentAuthenticatedUser()
              .then(cognitoUser => {
                const currentSession = cognitoUser.signInUserSession;
                cognitoUser.refreshSession(currentSession.refreshToken, () => {
                  // do something with the new session
                  removeLocalItem('joiningCommunity')
                    .then(() => {
                      done();
                    })
                });
              });
          })
          .catch((e2) => {
            log.error(e2);
          });
      }
    }
  );

  const pendingCommands = await communityService.join(communityId);
  if (!pendingCommands) {
    done();
  }
}