import React, { useContext, useState } from 'react';
import { Linking, Text, View } from 'react-native';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { RootStackParamList } from '../../../types/Navigation';
import { DrawerScreenProps } from '@react-navigation/drawer';
import { useIsFocused } from '@react-navigation/native';
import ScrollableViewScreen from '../../components/Layouts/ScrollableViewScreen';
import PromptModal from '../../components/Modals/PromptModal';
import { requestAccountDeletion } from '../../../business/supportRequests/accountDeletion';
import NButton from '../../components/NButton';
import { Palette } from '../../../Theme';
import { normalizeWidth } from '../../../business/layout/responseSize';
import { obfuscateEmail } from '../../../business/supportRequests/obfuscateEmail';

type HelpProps = DrawerScreenProps<RootStackParamList, 'Help'>;

function HelpScreen({ route, navigation }: HelpProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const isFocused = useIsFocused();
  const [showAccountDeletionModal, setShowAccountDeletionModal] = useState(false);
  const [requstingAccountDeletion, setRequestingAccountDeletion] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const pressRequestAccountDeletion = () => {
    setShowAccountDeletionModal(true);
  }

  const cancelRequestAccountDeletion = async () => {
    setShowAccountDeletionModal(false);
  }

  const requestDeletion = async (): Promise<void> => {
    if (commonContext.loggedInUser && commonContext.loggedInUser.id.length > 0) {
      setRequestingAccountDeletion(true)
      await requestAccountDeletion(commonContext.loggedInUser?.id, `loggedInUser: ${obfuscateEmail(commonContext.loggedInUser?.email)} --- ${commonContext.loggedInUser?.id}`);
      setRequestingAccountDeletion(false);
      setShowAccountDeletionModal(false);
      setTimeout(() => {
        setShowConfirmation(true);
      }, 700);
    }
  }

  const pressConfirm = () => {
    setShowConfirmation(false);
  }

  return (
    <View
      style={{
        height: '100%',
        overflow: 'hidden',
      }}
    >
      <ScrollableViewScreen
        navigation={navigation}
        isFocused={isFocused}
        //topTitle={``}
        bottomTitle={`Help`}
        refreshing={false}
        //goBack={() => linkTo(`/communities/${commonContext.loggedInUser?.id}/`)}
        goBack={navigation.goBack}
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          height: '100%',
          //zIndex: 10,
        }}
        contentContainerStyle={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          flex: 1,
          //height: '100%',
          padding: 24,
        }}
        containsScrollView={true}
        testID={`help-screen-view`}
      >

        <View
          style={{
            borderRadius: 13,
            padding: 24,
            backgroundColor: '#6D6D6D20',
            alignItems: 'flex-start',
            zIndex: 100,
            display: 'flex',
            margin: normalizeWidth(20, commonContext.dimensions.width),
            //flex: 1,
          }}
        >
          <Text
            style={{
              fontSize: 20,
              fontWeight: '600',
              color: Palette.plum
            }}
          >
            {`Get Support`}
          </Text>
          <Text
            style={{
              fontSize: 16,
              fontWeight: '300',
              color: Palette.plum,
              marginVertical: 12,
              lineHeight: 20,
            }}
          >
            {`Questions? Comments? Issues? We'd love to help - email us at the address below!`}
          </Text>
          <Text
            style={{
              color: Palette.teal,
              fontWeight: '700',
              textAlign: 'center',
              fontSize: 16,
              marginVertical: 12,
              lineHeight: 20,
              alignSelf: 'center',
            }}
            onPress={() => { Linking.openURL(`mailto:support@meetnovella.com`); }}
            testID={`support-email-button`}
          >
            {`support@meetnovella.com`}
          </Text>

        </View>


        <View
          style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'flex-end',
            alignItems: 'center',
            paddingBottom: 24,
          }}
        >
          <NButton
            t={`danger`}
            title={`Delete my Novella account`}
            onPress={pressRequestAccountDeletion}
          />
        </View>

      </ScrollableViewScreen>
      <PromptModal
        show={showAccountDeletionModal}
        heading={`Delete your account and erase all your data?`}
        prompt={`Oh no! We're sorry to see you go:(\n\nAre you absolutely sure you want to delete your account? If you do, Novella will delete all your user data: your account credentials and profile information; all the questions you've asked; all the videos you've recorded; all your comments; all your likes, etc. All of this data will be lost permanently, without any way to recover it.`}
        confirm={requestDeletion}
        confirmButtonText={`Yes, I'm sure`}
        //backdropHeight?: number,
        deny={cancelRequestAccountDeletion}
        denyButtonText={`Cancel`}
        dismiss={cancelRequestAccountDeletion}
        working={requstingAccountDeletion}
        testID={`request-account-deletion-modal`}
      >
      </PromptModal>
      <PromptModal
        show={showConfirmation}
        heading={`Account deletion in progress...`}
        prompt={`We've received your request, and will begin the process of deleting your Novella account and all associated data. This can take time to complete, and we thank you for your patience.`}
        confirm={pressConfirm}
        confirmButtonText={`Done`}
        testID={`request-account-deletion-confirmation-modal`}
      >
      </PromptModal>
    </View>
  );
}

export default HelpScreen;