import React, { useContext, useEffect, useState } from 'react';
import { Divider, Text } from 'react-native-elements';
import MemberAvatar from '../../components/MemberAvatar';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { StyleProp, View, ViewStyle } from 'react-native';
import { LikeData, VideoVisibility, VimeoVideoMetadata } from '../../../contracts/ICommunityService';
import ItemEditButtonMenu from '../../components/ItemEditButtonMenu';
import { CommunityQuestionAnswer, CommunityQuestionAnswerComment, CommunityUserStatus } from '../../../API';
import log from '../../../business/logging/logger';
import NText from '../../components/NText';
import VimeoPlayer from '../../components/VimeoPlayer/VimeoPlayer';
import { useInterval } from '../../../business/hooks/useInterval';
import { getVimeoMetadataForAnswer, VimeoDisplayMetadata, VimeoThumbnail } from '../../../business/video/vimeo';
import { getRelativeTimeLabel } from '../../../business/date/dateFormatter';
import { getPowers } from '../../../business/answers/answerPostPowerRules';
import VisibilityPicker4 from '../../components/VisibilityPicker4';
import { useLinkTo } from '@react-navigation/native';
import { extractVideoMetadata } from '../../../business/answers/videoMetadata';
import LikeButton from '../../components/LikeButton';
import CommentButton from '../../components/CommentButton';

type AnswerItemCardProps = {
  answer: CommunityQuestionAnswer,
  role: CommunityUserStatus,
  moderated: boolean,
  comments: CommunityQuestionAnswerComment[],
  likes: LikeData,
  index: number,
  hideMenuButton?: boolean,
  toggleLike: (answer: CommunityQuestionAnswer) => Promise<void>,
  toggleEditText: (answer: CommunityQuestionAnswer) => void,
  toggleDelete: (answer: CommunityQuestionAnswer) => void,
  cardStyle?: StyleProp<ViewStyle>,
}

function AnswerItemCard(props: AnswerItemCardProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { communityService } = commonContext.services;
  const [pollInterval, setPollInterval] = useState<number | null>(null);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  //const [videoMetadata, setVideoMetadata] = useState<VimeoDisplayMetadata>();
  const { answer, likes, comments, role, moderated, index, toggleLike, toggleEditText, toggleDelete } = props;
  const [likeDisabled, setLikeDisabled] = useState(false);
  const [visibility, setVisibility] = useState<VideoVisibility>(VideoVisibility.SELF);
  const [canSee, setCanSee] = useState(true);
  const [canEditText, setCanEditText] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [visibilityTargets, setVisibilityTargets] = useState<VideoVisibility[]>([]);
  //const [comments, setComments] = useState<CommunityQuestionAnswerComment[]>([]);
  const [videoEmbedUrl, setVideoEmbedUrl] = useState('');
  const [videoWidth, setVideoWidth] = useState(0);
  const [videoHeight, setVideoHeight] = useState(0);
  const [videoTranscodingStatus, setVideoTranscodingStatus] = useState('complete');
  const [m3u8, setM3u8] = useState('');
  const [thumbnailUrls, setThumbnailUrls] = useState<VimeoThumbnail[]|undefined>();
  const [m3u8ExpirationTime, setM3u8ExpirationTime] = useState('');
  const [downloadLink, setDownloadLink] = useState('');
  const [downloadQuality, setDownloadQuality] = useState('');
  const linkTo = useLinkTo();

  useEffect(() => {
    const loggedInUserRole = commonContext.isAdmin ? "ADMIN" : role;
    const isOriginalAuthor = answer.userID == commonContext.loggedInUser?.id;
    const communityRequiresModeration = moderated;
    const fullMetadata = extractVideoMetadata(answer.content);

    //get the "group" that the user is in.  This is so a SUPERVIEWER can view videos that only the organizer/spotlight can see (started with the GH community)
    try {      
      const communityGroup = loggedInUserRole != "ADMIN" ? commonContext.loggedInUser?.communityUsersByStatusDate?.items.find(cu => cu?.communityID == answer.communityID)?.group : "";
     
      const powers = getPowers(loggedInUserRole, isOriginalAuthor, communityRequiresModeration, fullMetadata?.visibility, communityGroup);

      setCanDelete(powers.canDelete);
      setCanEditText(powers.canEditText)
      setCanSee(powers.canSee);
      setVisibilityTargets(powers.visibilityTargets);

      if (fullMetadata) {
        setVisibility(fullMetadata?.visibility);
        setTitle(fullMetadata.title);
        setDescription(fullMetadata.description);
      }
    } catch (ex) {
      log.error(ex);
      log.warn("error - seems like could not get group - missing answer or communityuser");
    }
  }, [answer.userID, answer.content, answer.communityID, role, moderated, commonContext.loggedInUser]);

  useEffect(() => {
    getVimeoMetadataForAnswer(communityService, answer.id)
      .then(metadata => {
        if (metadata) {
          // console.log(`metadata:`)
          // console.log(metadata);
          extractMetadata(metadata);

          if (metadata.videoId.length > 0) {
            commonContext.analytics?.viewItems([
              {
                item_id: metadata.videoId,
                item_name: metadata.title,
                item_category: 'Video',
              }
            ]);
          }
        }
      })
      .catch(err => {
        log.error(`could not get vimeo metadata for answer: ${answer.id}`);
        log.error(err);
      });
      // commonContext.services.communityService.getComments(answer.id)
      //   .then(c => {
      //     setComments(c);
      //   });
  }, [answer.id]);

  useEffect(() => {
    if (videoTranscodingStatus != 'complete') {
      setPollInterval(10000);
    }
  }, [videoTranscodingStatus]);

  useInterval(async () => {
    //log.info(`Checking transcode status of video for communityQuestionAnswerId: ${answer.id}`);

    //console.log(`polling for transcodingStatus`);

    const m = await getVimeoMetadataForAnswer(communityService, answer.id);
    //log.info(`GET VIDEO METADATA RESPONSE:`)
    //log.info(m);
    extractMetadata(m);

    
    //console.log(`transcoding status: ${m?.transcodingStatus}`);

    if (m?.transcodingStatus == 'complete') {
      setPollInterval(null);
    }
  }, pollInterval);

  function extractMetadata(metadata: VimeoDisplayMetadata | undefined) {
    if (metadata?.embedUrl != videoEmbedUrl) {
      setVideoEmbedUrl(metadata?.embedUrl ?? '');
    }
    if (metadata?.videoWidth != videoWidth) {
      setVideoWidth(metadata?.videoWidth ?? 0);
    }
    if (metadata?.videoHeight != videoHeight) {
      setVideoHeight(metadata?.videoHeight ?? 0);
    }
    if (metadata?.transcodingStatus != videoTranscodingStatus) {
      setVideoTranscodingStatus(metadata?.transcodingStatus ?? '');
    }
    //console.info(`m3u8: ${metadata?.m3u8}`);
    if (metadata?.m3u8 != m3u8 && m3u8 == '') {
      //console.info(`setting m3u8: ${metadata?.m3u8 ?? ''}`)
      setM3u8(metadata?.m3u8 ?? '');
    }
    if (metadata?.m3u8ExpirationTime != m3u8ExpirationTime) {
      setM3u8ExpirationTime(metadata?.m3u8ExpirationTime ?? '');
    }
    if (metadata?.thumbnailUrls != thumbnailUrls) {
      setThumbnailUrls(metadata?.thumbnailUrls);
      //console.log(`thumbnail url: ${metadata?.thumbnailUrls}`);
    }
    if(metadata?.downloadLink != downloadLink) {
      setDownloadLink(metadata?.downloadLink ?? '');
    }
    if(metadata?.downloadQuality != downloadQuality) {
      setDownloadQuality(metadata?.downloadQuality ?? '');
    }

    loadVideo(metadata?.title, metadata?.description);
  }

  function loadVideo(vTitle: string | undefined, vDescription: string | undefined): void {
    setTitle(vTitle ?? '');
    setDescription(vDescription ?? '');
  }

  const saveVisibility = async (v: VideoVisibility): Promise<void> => {
    const contentArray = answer.content;
    const arr: VimeoVideoMetadata[] = JSON.parse(contentArray);
    //pretty hacky, assuming we always use the first....
    const first = arr[0];
    if (first) {
      const videoMetadata: VimeoVideoMetadata = {
        ...first,
        visibility: v,
      }
      arr[0] = videoMetadata;
      answer.content = JSON.stringify(arr);
      await commonContext.services.communityService.saveExistingAnswer(answer);
    }
  }

  return (
    <>
      {
        canSee &&
        <View
          style={[
            { // card
              ...themeFromContext.card.style,
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              marginTop: 0,
              zIndex: 1,
            },
            props.cardStyle,
          ]}
          testID={`answer-card-${index}`}
        >
          <View style={{ // head / top
            display: 'flex',
            flexDirection: 'row',
            padding: themeFromContext.spacing.s,
            alignItems: 'center',
            flex: 1, // stretch down as far as needed
          }}>
            <View>
              <MemberAvatar
                size={43}
                userData={answer.user}
                ignoreUpdate={true}
                containerStyle={{
                  marginRight: 8,
                  //backgroundColor: themeFromContext.colors.overlay
                }}
                titleStyle={{
                  color: themeFromContext.colors.background
                }}
              />
            </View>
            <View
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <View style={{
                //display: 'flex',
                //flex: 1,
                display: 'flex',
                //width: '100%',
                //flexGrow: 1,
                //flexShrink: 0.1,
                //flexDirection: 'column',
                flexShrink: 1,
                padding: themeFromContext.spacing.s,
                zIndex: 1,
              }}>
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  <Text
                    style={{
                      ...themeFromContext.textVariants.body,
                      fontWeight: 'bold',
                    }}
                    numberOfLines={1}
                    testID={`asked-question-card-header-top`}
                  >
                    {commonContext.services?.userService.getFullName(answer.user)}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                  }}
                >
                  <Text
                    style={{
                      paddingEnd: themeFromContext.spacing.s,
                      ...themeFromContext.textVariants.detail,
                      color: themeFromContext.colors.secondary
                    }}
                    numberOfLines={1}
                    testID={`answer-card-header-bottom`}
                  >
                    {getRelativeTimeLabel(answer.date)}
                    {/* {(new Date(answer.date)).toLocaleDateString()} */}
                  </Text>
                </View>
              </View>
              {
                !props.hideMenuButton &&
                <View>
                  <ItemEditButtonMenu canExport={canDelete} exportLink={downloadLink} exportQuality={downloadQuality} getItemToFlag={() => { return answer; }} canEdit={canEditText} canDelete={canDelete} onEdit={() => toggleEditText(answer)} onDelete={() => toggleDelete(answer)} testID={`edit-item-button-${index}`} />
                </View>
              }
            </View>
          </View>

          {/* <View style={{
            //paddingHorizontal: themeFromContext.spacing.m,
            //paddingTop: themeFromContext.spacing.s,
            //zIndex: 1,
          }}> */}
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                //padding: 10,
                //marginBottom: 10,
                //flex: 1,
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                //padding: 10,
                zIndex: 1,
              }}
            >
              {
                ((title != undefined && title.length > 0) || (description != undefined && description.length > 0)) &&
                <View
                  style={{
                    padding: themeFromContext.spacing.s,
                    paddingTop: 0,
                  }}
                >
                  {
                    title != undefined && title.length > 0 &&
                    <NText
                      style={{
                        color: themeFromContext.colors.foreground,
                        ...themeFromContext.textVariants.body,
                        fontWeight: 'bold',
                        //paddingHorizontal: themeFromContext.spacing.s
                      }}
                      testID={`vimeo-screen-video-title`}
                    >
                      {`${title}`}
                    </NText>
                  }
                  {
                    description != undefined && description.length > 0 &&
                    <NText
                      style={{
                        color: themeFromContext.colors.text,
                        ...themeFromContext.textVariants.body,
                        //paddingHorizontal: themeFromContext.spacing.s,
                        //paddingBottom: themeFromContext.spacing.s,
                      }}
                      testID={`vimeo-screen-video-description`}
                    >
                      {`${description}`}
                    </NText>
                  }
                </View>
              }
              <VimeoPlayer
                embedUrl={videoEmbedUrl}
                width={videoWidth}
                height={videoHeight}
                transcodingStatus={videoTranscodingStatus}
                m3u8={m3u8}
                thumbnailUrls={thumbnailUrls}
                heightConstrained
              />
              <View
                style={{
                  padding: themeFromContext.spacing.s,
                  width: '100%',
                  zIndex: 1,
                }}
              >
                <VisibilityPicker4 dropDownDirection={'TOP'} allowed={visibilityTargets} authorId={answer.userID} visibility={visibility} saveVisibility={saveVisibility} />
              </View>
            </View>
          {/* </View> */}

          {/* <Divider orientation="horizontal" width={1} /> */}

          <View style={{ display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 18 }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-around',
                width: '100%',
                paddingBottom: 6,
              }}
            >
              <LikeButton target={answer} likes={likes} toggleLike={toggleLike} />
              <CommentButton answer={answer} comments={comments} />
            </View>
          </View>
        </View>
      }
    </>
  );
}

export default AnswerItemCard;