import { NativeScrollEvent, NativeSyntheticEvent } from "react-native";

export class FABScrollHelper {
  previousScrollOffsetY: number;
  direction: 'up'|'down';
  tip: number;
  showFAB: (show: boolean) => void;

  constructor(showFAB: (show: boolean) => void) {
    this.previousScrollOffsetY = 0;
    this.direction = 'up';
    this.tip = 0;
    this.showFAB = showFAB;
  }

  handleScroll(event: NativeSyntheticEvent<NativeScrollEvent>): void {
    const diff = 100;
    const current = event.nativeEvent.contentOffset.y;
    const previous = this.previousScrollOffsetY;
    let newDirection = this.direction;
    let newTip = this.tip;

    //console.log(`previous: ${previous}\tcurrent: ${current}\tdirection: ${this.direction}\ttip:${this.tip}`);

    if (current > previous && this.direction == 'up') {
      newDirection = 'down';
      // changed direction
      newTip = previous;
    } else if (current < previous && this.direction == 'down') {
      newDirection = 'up';
      // changed direction
      newTip = previous;
    }

    if (current > this.tip + diff && this.direction == 'down') {
      this.showFAB(false);
    } else if (current < this.tip && this.direction == 'up') {
      this.showFAB(true);
    }

    this.previousScrollOffsetY = current;
    this.direction = newDirection;
    this.tip = newTip;
  }
}