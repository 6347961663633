import React, { useContext, useEffect, useState } from 'react'
import {
  View, Text
} from 'react-native'
import { Auth } from 'aws-amplify'
import { useLinkTo } from '@react-navigation/native';
import { RootStackParamList } from '../../../../types/Navigation';
import AuthPage from '../AuthPage';
import AuthPageInput from '../AuthPageInput';
import AuthPageHeader from '../AuthPageHeader';
import { CommonContextType } from '../../../../types/CommonContextType';
import CommonContext from '../../../../CommonContext';
import AuthPageLink from '../AuthPageLink';
import NButton from '../../../components/NButton';
import { DrawerScreenProps } from '@react-navigation/drawer';
import { getCurrentEnv } from '../../../../business/env/currentEnv';

export type ConfirmCodeProps = DrawerScreenProps<RootStackParamList, 'Confirm Code'>;

function ConfirmCode(props: ConfirmCodeProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const [email, setEmail] = useState('');
  const [code, setCode] = useState('');
  const [codeError, setCodeError] = useState('');
  const linkTo = useLinkTo();
  const [env, setEnv] = useState(getCurrentEnv());

  useEffect(() => {
    if (props.route.params?.c) {
      setCode(props.route.params.c.toString())
    }
    if (props.route.params?.email) {
      setEmail(props.route.params.email)
    }
  }, [props]);

  const confirmCode = () => {
    Auth.confirmSignUp(email, code)
      .then(() => {
        linkTo(`/signIn?email=${email}&confirmed=${true}`);
      })
      .catch(err => {
        //log.info('error resetting password:', err);

        if (err.name == 'CodeMismatchException') {
          setCodeError(err.message);
        } else if (err.name == 'UserNotFoundException') {
          setCodeError(`Invalid email / code combination`);
        } else {
          setCodeError('');
        }
      });
  }

  return (
    <AuthPage>
      <AuthPageHeader text='Enter your confirmation code' />
      {props.route.params?.email &&
        <Text
          style={{
            textAlign: 'center',
            color: themeFromContext.colors.danger,
            ...themeFromContext.textVariants.body
          }}
        >
          {`We sent you an email! Please enter the confirmation code here.`}
        </Text>
      }
      <AuthPageInput
        label='Email *'
        value={email}
        onChangeText={setEmail}
        placeholder='Enter your email'
        autoCompleteType='email'
        keyboardType='email-address'
        textContentType='emailAddress'
        autoCapitalize='none'
        autoCorrect={false}
        testID='confirm-code-email-input'
      />
      <AuthPageInput
        label='Confirmation code *'
        value={code}
        onChangeText={setCode}
        placeholder='Enter confirmation code'
        keyboardType='number-pad'
        autoCapitalize='none'
        autoCorrect={false}
        errorMessage={codeError}
        testID='confirm-code-code-input'
      />
      {/* <AuthPagePrimaryButton
        onPress={confirmCode}
        title='Confirm'
        //disabled={code.length == 0 || !isValidEmail(email)}
      /> */}
      <NButton
        testID='confirm-code-confirm-button'
        containerStyle={{padding: 10, width: '100%'}}
        t='primary'
        onPress={confirmCode}
        title={`Confirm`}
      />
      <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <AuthPageLink
          onPress={() => linkTo(`/signIn${email ? '?email=' + email : ''}`)}
          title='Sign in'
        />
        {
          env != 'production' && env != 'prod' &&
          <AuthPageLink
            onPress={() => linkTo(`/signUp${email ? '?email=' + email : ''}`)}
            title='Create account'
          />
        }
      </View>
    </AuthPage>
  )
}

export default ConfirmCode