import { WelcomeSequence } from "./WelcomeScreensDefinitions";

export const WELCOME_SEQUENCES: WelcomeSequence[] = [
  {
    name: `INTRODUCTION_WELCOME_SCREEN`,
    welcomesByCommunityType: {
      DEFAULT: [
        {
          heading: `Welcome to Novella`,
          imageSource: require('./introduction/Novella_Welcome.jpg'),
          body: `Novella helps you capture and preserve the stories of your loved ones through personalized interviews.\n\nHere's how...`,
        },
        {
          heading: `Invite Others`,
          imageSource: require('./introduction/Invite_Other_People.jpg'),
          body: `First, you'll pick the people you want to interview — the "Spotlight" members. After that, you can choose to invite other family or friends to collaborate with you.`,
        },
        {
          heading: `Ask Questions`,
          imageSource: require('./introduction/Create_Interview_Questions.jpg'),
          body: `You can work by yourself or with others you invite to write and prioritize meaningful (or silly!) questions to ask the Spotlight members.`,
        },
        {
          heading: `Recommended Questions`,
          imageSource: require('./introduction/Recommended_Questions.jpg'),
          body: `You can choose from our recommended questions if you want, or just use them for inspiration!`,
        },
        {
          heading: `Multiple Recording Options`,
          imageSource: require('./introduction/Record_Interview_1.jpg'),
          body: `You or your Spotlight members can record answers in our Novella App, or opt for the “Red Carpet” experience of a professional cinematic interview!\n\nThere are multiple options to suit your needs.`,
        },
        {
          heading: `Enjoy the Memories`,
          imageSource: require('./introduction/Enjoy_Your_Memories.jpg'),
          body: `All your memories are saved in the Novella app, where you can relive the memories of your loved ones anytime, and pass them down to future generations.\n\nReady? Let's go!`,
        },
      ],
      PASSED: [
        {
          heading: `Welcome to Novella`,
          imageSource: require('./introduction/Novella_Welcome.jpg'),
          body: `Novella helps you capture and preserve the stories of your loved ones through personalized interviews.\n\nHere's how...`,
        },
        {
          heading: `Invite Others`,
          imageSource: require('./introduction/Invite_Other_People.jpg'),
          body: `First, you'll pick the person you want to be the "Spotlight" — the person you'll be sharing stories and memories about.\n\nYou can invite any others to collaborate and share their stories as well.`,
        },
        {
          heading: `Choose Questions`,
          imageSource: require('./introduction/Create_Interview_Questions.jpg'),
          body: `Then, you'll choose meaningful questions / topics to prompt sharing about the Spotlight member.`,
        },
        {
          heading: `Recommended Questions`,
          imageSource: require('./introduction/Recommended_Questions.jpg'),
          body: `Not sure where to start? Don't worry! You can choose from our recommended questions if you want, or just use them for inspiration.`,
        },
        {
          heading: `Multiple Recording Options`,
          imageSource: require('./introduction/Record_Interview_1.jpg'),
          body: `You'll be able to record everything you want to share in our Novella app, or choose a professional, in-person interview experience with cinema-style equipment for yourself or a group.`,
        },
        {
          heading: `Enjoy the Memories`,
          imageSource: require('./introduction/Enjoy_Your_Memories.jpg'),
          body: `Recordings from all members are saved together in the Novella app, where you can relive and share the memories at anytime.\n\nReady? Let's go!`,
        },
      ]
    },
  },
];