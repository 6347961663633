import { GraphQLOptions } from '@aws-amplify/api-graphql';
import { API } from 'aws-amplify';
export interface IAPIWrapper {
  /**
     * Executes a GraphQL operation
     *
     * @param {GraphQLOptions} GraphQL Options
     * @param {object} additionalHeaders headers to merge in after any `graphql_headers` set in the config
     * @returns {Promise<GraphQLResult> | Observable<object>}
     */
   graphql(options: GraphQLOptions, additionalHeaders?: {
    [key: string]: string;
  }): any;
}

export class APIWrapper implements IAPIWrapper {
  graphql(options: GraphQLOptions, additionalHeaders?: { [key: string]: string; }) : any {
    return API.graphql(options, additionalHeaders);
  }
}