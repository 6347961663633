import { Community, CommunityQuestion, CommunityQuestionAnswer, CommunityQuestionAnswerComment, CommunityQuestionRecommendation, CommunityQuestionSource, CommunityUser, CommunityUserCommand, CommunityUserStatus, User, xAssignEmailToPlaceholder } from "../API";
import { Dictionary } from "../types/data/Dictionary";
import { UserData } from '../business/user/userHelper';

export class CommunityServiceError {
  devMessage: string
  userMessage: string

  constructor(devMessage: string, userMessage: string) {
    this.devMessage = devMessage;
    this.userMessage = userMessage;
  }
}

export class LikeData {
  private loggedInUserLikes: boolean
  totalLikes: number
  constructor(userLikes: boolean, totalLikes: number) {
    this.loggedInUserLikes = userLikes;
    this.totalLikes = totalLikes;
  }

  getLiked(): boolean {
    return this.loggedInUserLikes;
  }

  like(liked: boolean): boolean {
    if (!liked) {
      if (this.loggedInUserLikes) {
        this.loggedInUserLikes = false;
        this.totalLikes--;
        return true;
      }
      return false;
    } else {
      if (!this.loggedInUserLikes) {
        this.loggedInUserLikes = true;
        this.totalLikes++;
        return true;
      }
      return false;
    }
  }

  totalScore(): number {
    return this.loggedInUserLikes ? this.totalLikes + 0.5 : this.totalLikes; // the extra 0.5 is for sorting, the "self" user should be a tie-breaker
  }
}

export type LikeMap = {
  [key: string]: LikeData
}

export enum VideoVisibility {
  SELF = "SELF",
  ORGANIZER = "ORGANIZER",
  COMMUNITY = "COMMUNITY",
}

export type VimeoVideoMetadata = {
  id: string
  title: string
  description: string
  visibility: VideoVisibility
  uploadedByUserId?: string
  thumbnailId?: string
}

export interface ICommunityService {
  DEFAULT_MAX_SUBJECTS: number;
  DEFAULT_MAX_MEMBERS: number;
  DEFAULT_MAX_INVITATION_MESSAGE_LENGTH: number;
  compareCommunityQuestions(
    a: CommunityQuestion,
    aLikes: number,
    b: CommunityQuestion,
    bLikes: number,
    by: string,
    asc: boolean): number;
  compareCommunityQuestionAnswers(
    a: CommunityQuestionAnswer,
    b: CommunityQuestionAnswer,
    by: string,
    asc: boolean): number;
  compareCommunityUsers(
    a: CommunityUser,
    b: CommunityUser,
    by: string,
    asc: boolean): number;
  compareCommunityUsersPriority(
    a: CommunityUser,
    b: CommunityUser) : number;
  createCommunity(
    name: string,
    creator: User,
    creatorStatus: CommunityUserStatus,
    invitationMessage: string,
    maxSubjects?: number,
    maxMembers?: number,
    maxInvitationLength?: number): Promise<Community>;
  getCommunityDataForLoggedInUser(): Promise<Community[]>;
  isManagementRole(role: CommunityUserStatus): boolean;
  getLoggedInUserCommunityRole(communityId: string): Promise<CommunityUserStatus>;
  //getLoggedInUserCommunityGroup(communityID: string, userID: string): Promise<string>;
  canManageCommunityUser(roleInCommunity: CommunityUserStatus, loggedInUser: User, communityUser: CommunityUser): boolean;
  getAllowedMembersByCommunity(communityIds: string[]): Promise<Dictionary<CommunityUser[]>>;
  getJoinedMembersByCommunity(communityIds: string[]): Promise<Dictionary<CommunityUser[]>>;
  getJoinedMembers(communityIds: string[]): Promise<CommunityUser[]>;
  getMembers(communityIds: string[], statuses: CommunityUserStatus[]): Promise<CommunityUser[]>;
  removeMembers(
    members: CommunityUser[],
    communityId: string,
    failure: (devMessage: string, userMessage: string) => void): Promise<void>;
  assignEmailToPlaceholder(communityUser: CommunityUser, email: string, invitationMessage: string) : Promise<xAssignEmailToPlaceholder>;
  mergePlaceholderAndSendInvitation(
    email: string,
    placeholder: CommunityUser,
    invitationMessage: string) : Promise<xAssignEmailToPlaceholder>;
  inviteAndSendInvitations(
    userData: UserData[],
    communityId: string,
    status: CommunityUserStatus,
    invitationMessage: string): Promise<void>;
  join(
    communityId: string) : Promise<CommunityUserCommand[] | undefined>;
  getQuestions(communityId: string): Promise<CommunityQuestion[]>;
  getQuestion(communityQuestionId: string): Promise<CommunityQuestion | undefined>;
  getCommunityForActivityHistory(communityId: string): Promise<Community|undefined>;
  deleteExistingQuestion(
    communityQuestionId: string,
    failure: (devMessage: string, userMessage: string) => void): Promise<void>;
  saveExistingQuestion(
    questionText: string,
    categoryName: string,
    communityQuestionId: string,
    imageKeys: string[]): Promise<void>;
  getQuestionsWithSameText(
    communityId: string,
    communityQuestionId: string,
    questionText: string[]): Promise<CommunityQuestion[]>;
  saveNewQuestion(
    questionText: string,
    categoryName: string,
    communityId: string,
    source: CommunityQuestionSource,
    imageKeys: string[]): Promise<void>;
  acceptRecommendations(
    recommendations: CommunityQuestionRecommendation[],
    communityId: string,
    source: CommunityQuestionSource): Promise<void>;
  getSingleCommunity(communityId: string): Promise<Community | undefined>;

  // LIKES - QUESTIONS
  toggleCommunityQuestionLike(communityQuestion: CommunityQuestion): Promise<void>;
  getLikeMapForQuestions(items: CommunityQuestion[]): Promise<LikeMap>;
  buildUnauthenticatedLikeMapForQuestions(questions: CommunityQuestion[], userFirstName: string, userLastName: string) : LikeMap;

  // LIKES - ANSWERS
  toggleCommunityQuestionAnswerLike(communityQuestionAnswer: CommunityQuestionAnswer): Promise<void>;
  getLikeMapForAnswers(items: CommunityQuestionAnswer[]): Promise<LikeMap>;
  buildUnauthenticatedLikeMapForAnswers(answers: CommunityQuestionAnswer[], userFirstName: string, userLastName: string) : LikeMap;

  // COMMENTS
  getComments(communityQuestionAnswerId: string): Promise<CommunityQuestionAnswerComment[]>;
  saveNewComment(
    communityQuestionAnswerId: string,
    content: string): Promise<void>;
  saveExistingComment(
    communityQuestionAnswerComment: CommunityQuestionAnswerComment): Promise<void>;

  updateCommunityWithVimeoVideo(communityId: string, vimeoVideoMetadata: VimeoVideoMetadata | null) : Promise<any>;
  sort(members: CommunityUser[], by: string, asc: boolean) : CommunityUser[];
  getCommunityCategories(communityId: string) : Promise<string[]>;
  setCommunityCategories(communityId: string, communityCategories: string[]) : Promise<void>;
  getAnswers(communityQuestionId: string): Promise<CommunityQuestionAnswer[]>;
  getAnswer(communityQuestionAnswerId: string): Promise<CommunityQuestionAnswer | undefined>;
  // deleteExistingAnswer(
  //   communityQuestionAnswerId: string,
  //   failure: (devMessage: string, userMessage: string) => void): Promise<void>;
  saveNewAnswer(
    communityQuestionId: string,
    videoMetadata: VimeoVideoMetadata,
    actorUserId?: string): Promise<void>;
  saveExistingAnswer(
    communityQuestionAnswer: CommunityQuestionAnswer): Promise<void>;
}