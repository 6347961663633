import { Auth } from 'aws-amplify';
//import { ICache }  from '@aws-amplify/cache/lib-esm/types';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
//import { User, UserCommand, CommandOperation, UserGoogleTokens, CommunityQuestion } from '../API';
import * as m from '../graphql/mutations';
import * as q from '../graphql/queries';
import log from '../business/logging/logger';
import { CreateUserCommandInput, CreateUserInput, User, UserCommand, CommandOperation, UserGoogleTokens, CommunityQuestion, UserStatus } from '../API';
import { authorizeGoogleContacts, getGoogleContacts, GoogleTokens, refreshAccessToken, SCOPE } from '../business/auth/googleHttp';
import { readThroughCache } from '../business/cache/cacheHelper';
import { GoogleContact, subscribeToUserUpdates } from '../business/user/userHelper';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { IFullListQuerier } from '../business/graphql/FullListQuerier';
import { IAPIWrapper } from '../business/graphql/APIWrapper';
import { ICache } from '../business/storage/ICache';
import { v4 as uuid } from 'uuid';
import { IUserService } from '../contracts/IUserService';

const REQUIRED_GOOGLE_SCOPES = [
  SCOPE.CONTACTS,
  SCOPE.CONTACTS_OTHER
]

const DEFAULT_MAX_SUBJECTS = 2;
const DEFAULT_MAX_MEMBERS = 30;
const DEFAULT_MAX_INVITATION_MESSAGE_LENGTH = 500;
let lock = '';

export class UserService implements IUserService {
  api: IAPIWrapper;
  cache: ICache;
  flq: IFullListQuerier;
  subscription: any;
  constructor(api: IAPIWrapper, cache: ICache, flq: IFullListQuerier) {
    this.api = api;
    this.cache = cache;
    this.flq = flq;
  }
  compareUsers(
    a: User,
    b: User,
    by: string,
    asc: boolean) : number
  {
    if (by === "email") {
      const ea = a.email ?? '';
      const eb = b.email ?? '';
      return asc ?
        ea.toLowerCase().localeCompare(eb.toLowerCase()) :
        -1 * ea.toLowerCase().localeCompare(eb.toLowerCase());
    } else if (by === 'firstName') {
      return asc ?
        a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()) :
        -1 * a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
    } else if (by === 'lastName') {
      return asc ?
        a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase()) :
        -1 * a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase());
    } else if (by === 'fullName') {
      const fa = this.getFullName(a);
      const fb = this.getFullName(b);
      return asc ?
        fa.toLowerCase().localeCompare(fb.toLowerCase()) :
        -1 * fa.toLowerCase().localeCompare(fb.toLowerCase());
    } else {
      return 0;
    }
  }
  getInitials(user?: User) : string
  {
    if (!user) {
      return '';
    }

    let initials = user.email ? user.email[0].toLocaleUpperCase() : '?';
    if (user.firstName.length > 0 && user.lastName.length > 0) {
      initials = user.firstName[0].toLocaleUpperCase();// + user.lastName[0].toLocaleUpperCase();
    } 
    return initials;
  }
  getFullName(user?: User) : string
  {
    if (!user) {
      return '';
    }

    if (user.firstName.length > 0 && user.lastName.length > 0) {
      return this.makeFullName(user.firstName, user.lastName);
    } else {
      return user.email ?? 'Unknown';
    }
  }
  makeFullName(first: string, last: string) : string {
    return first + ' ' + last;
  }
  
  async createUserWithIdentity(firstName: string, lastName: string, email: string, status: UserStatus, indentityId: string, username: string, referredByUserId?: string) : Promise<User|undefined>
  {
    let input: CreateUserInput = {
      firstName: firstName,
      lastName: lastName,
      email: email.toLowerCase(),
      status: status,
      username: username,
      identityID: indentityId,
      communityLimits: {
        maxSubjects: DEFAULT_MAX_SUBJECTS,
        maxMembers: DEFAULT_MAX_MEMBERS,
        maxInvitationLength: DEFAULT_MAX_INVITATION_MESSAGE_LENGTH
      }
    };

    if (referredByUserId && referredByUserId.length > 0) {
      input = {
        ...input,
        referredByUserID: referredByUserId,
      }
    }

    try {
      // had a nasty race condition where a single cognito user was getting two user records... this is a bit aof a hack, but...?
      const key = uuid();
      if (lock == '') {
        log.info('creating lock', key)
        lock = key;
      }
      if (lock == key) {
        log.info(`got lock`, lock);
        const createResult: any = await this.api.graphql({
          query: m.createUser,
          variables: {
            input: input
          }
        });
        
        return createResult.data.createUser;
      } else {
        log.info(`didn't get lock, waiting and trying get user`, key);
        const variables = { filter: { email: { eq: email.toLowerCase() } } };
        setTimeout(async() => {
          const list = await this.flq.queryFullList('listUsers', q.listUsers, variables);
          const loggedInUser: User = list[0];
          log.info(`got user after waiting`);
          return loggedInUser;
        }, 3000);
      }
    } catch (ex) {
      log.error(ex);
      throw ex;
    }
  }
  async createUser(firstName: string, lastName: string, status: UserStatus, email?: string | null) : Promise<User>
  {
    const input: CreateUserInput = {
      firstName: firstName,
      lastName: lastName,
      status: status,
      communityLimits: {
        maxSubjects: DEFAULT_MAX_SUBJECTS,
        maxMembers: DEFAULT_MAX_MEMBERS,
        maxInvitationLength: DEFAULT_MAX_INVITATION_MESSAGE_LENGTH
      }
    }
    if (email) {
      input.email = email.toLowerCase();
    }
    const createResult: any = await this.api.graphql({
      query: m.createUser,
      variables: {
        input: input
      }
    });
    return createResult.data.createUser;
  }
  //don't export
  async sendUserCommand(input: CreateUserCommandInput) : Promise<UserCommand>
  {
    const createResult: any = await this.api.graphql({
      query: m.createUserCommand,
      variables: {
        input: input
      }
    });
    return createResult.data.createUserCommand;
  }
  async createOrUpdateUserGoogleTokens(tokens: GoogleTokens) : Promise<UserGoogleTokens>
  {
    const user = await Auth.currentAuthenticatedUser();
    log.info(`UPDATE GOOGLE TOKENS FOR USER`);
    log.info(tokens);
    log.info(user);
    const owner = user.attributes.sub;
    let finalTokens;
    try {
      finalTokens = await this.updateUserGoogleTokens(owner, tokens);
      log.info(`CACHING TOKENS:`);
      log.info(JSON.stringify(finalTokens, null, 2));
      this.cache.setItem('googleTokens', finalTokens);
      const test = this.cache.getItem('googleTokens');
      log.info(`SUCCESSFULLY CACHED TOKENS:`);
      log.info(JSON.stringify(test, null, 2));
    } catch (ex: any) {
      if (ex.errors[0].errorType == "DynamoDB:ConditionalCheckFailedException") {
        log.info(`TRYING CREATE INSTEAD`)
        finalTokens = await this.createUserGoogleAccessToken(owner, tokens);
        this.cache.setItem('googleTokens', finalTokens);
      } else {
        log.error(ex);
        throw ex;
      }
    }
    return finalTokens;
  }
  async getUserGoogleTokens() : Promise<UserGoogleTokens | undefined>
  {
    let tokens: UserGoogleTokens = await readThroughCache({
      key: 'googleTokens',
      ifNotFound: async () => {
        log.info(`NOTHING IN CACHE! GET FROM DB:`);
        const user = await Auth.currentAuthenticatedUser();
        const t = await this.getUserGoogleTokensFromDB(user.attributes.sub);
        log.info(`GOT TOKENS FROM DB:`);
        log.info(JSON.stringify(t, null, 2));
        return t;
      }
    })

    log.info(`CACHED TOKENS:`);
    log.info(tokens);

    if (this.needsReauthorization(tokens)) {
      return;
    }  

    if (tokens && tokens.expiresOn) {
      const isExpired = (new Date(tokens.expiresOn)).getTime() < Date.now();
      log.info(`EXPIRED? : ${isExpired}`);
      if (isExpired) {
        if (tokens.refreshToken) {
          log.info(`TRYING TO REFRESH`);
          try {
            const newTokens = await refreshAccessToken(tokens.refreshToken);
            log.info(`REFRESHED TOKENS:`);
            log.info(newTokens);
            tokens = await this.createOrUpdateUserGoogleTokens(newTokens);
          } catch (ex: any) {
            log.info(`REFRESH ERROR:`);
            log.info(JSON.stringify(ex, null, 2));

            if (ex.message == 'Request failed with status code 400') {
              // the user might have revoked access
              throw `Request reauthorization`;
            }

            throw ex;
          }
        }
      }
    }
    return tokens;
  }
  //don't export
  needsReauthorization(tokens: UserGoogleTokens): boolean {

    if (!tokens) {
      return true;
    }
    
    // checking for missing scopes

    let needsAuthorization = false;
    if (!tokens.scopes) {
      if (REQUIRED_GOOGLE_SCOPES.length > 0) {
        needsAuthorization = true;
      }
    } else {
      const req = REQUIRED_GOOGLE_SCOPES.map(r => r.substring(r.lastIndexOf('/')));
      const have = tokens.scopes.map(s => s.substring(s.lastIndexOf('/')));
      const missing = req.filter(r => !have.includes(r));

      if (missing && missing.length > 0) {
        log.info(`MISSING SCOPES!!!`);
        log.info(missing);
        needsAuthorization = true;
      }
    }
    return needsAuthorization;
  }
  //don't export
  async getUserGoogleTokensFromDB(owner: string) : Promise<UserGoogleTokens>
  {
    const getResult: any = await this.api.graphql({
      query: q.getUserGoogleTokens,
      variables: {
        owner: owner
      }
    })
    return getResult.data.getUserGoogleTokens;
  }
  //don't export
  async updateUserGoogleTokens(owner: string, tokens: GoogleTokens) : Promise<UserGoogleTokens>
  {
    const expiresOn = (new Date(Date.now() + (tokens.expiresInSeconds * 1000))).toISOString();
    const scopes = tokens.scopes.split(' ');

    log.info(`RECEIVED GOOGLE TOKENS:`);
    log.info(JSON.stringify(tokens, null, 2));

    // accessToken: String
    // refreshToken: String
    // scopes: [String]
    // expiresOn: AWSDateTime

    const updateResult: any = await this.api.graphql({
      query: m.updateUserGoogleTokens,
      variables: {
        input: {
          owner: owner,
          accessToken: tokens.accessToken,
          refreshToken: tokens.refreshToken,
          scopes: scopes,
          expiresOn: expiresOn,
        }
      }
    });

    log.info(`UPDATE RESULT:`);
    log.info(JSON.stringify(updateResult, null, 2));

    return updateResult.data.updateUserGoogleTokens;
  }
  //don't export
  async createUserGoogleAccessToken(owner: string, tokens: GoogleTokens) : Promise<UserGoogleTokens>
  {
    const expiresOn = (new Date(Date.now() + (tokens.expiresInSeconds * 1000))).toISOString();
    const scopes = tokens.scopes.split(' ');

    // accessToken: String
    // refreshToken: String
    // scopes: [String]
    // expiresOn: AWSDateTime

    const createResult: any = await this.api.graphql({
      query: m.createUserGoogleTokens,
      variables: {
        input: {
          owner: owner,
          accessToken: tokens.accessToken,
          refreshToken: tokens.refreshToken,
          scopes: scopes,
          expiresOn: expiresOn,
        }
      }
    });

  return createResult.data.createUserGoogleTokens;
  }
  async requestGoogleContactsPermissions(returnPath: string, needsConsent?: boolean) : Promise<void>
  {
    const u = await Auth.currentAuthenticatedUser();
    await authorizeGoogleContacts(u.attributes.email, returnPath, REQUIRED_GOOGLE_SCOPES, needsConsent);
  }
  async getMyGoogleContacts(requestPermission: () => void) : Promise<GoogleContact[] | undefined>
  {
    try {
      const tokens = await this.getUserGoogleTokens();
      if (!tokens) {
        requestPermission();
      } else {
        const contacts = await getGoogleContacts(tokens.accessToken ?? ''); // fix weird condition after re-deploy
        log.info(`GOOGLE CONTACTS:`);
        log.info(contacts);
        return contacts;             
      }
    } catch (ex) {
      log.warn(`Error getting contacts:`);
      log.warn(ex);
      requestPermission();
    }
  }
  async updateUserProfile(id: string, firstName: string, lastName: string, profileImageKey: string) : Promise<UserCommand>
  {
    const existing = await this.getUser(id);
    if (!existing) {
      throw new Error(`Tried to update non-existent User with id: ${id}`);
    }

    const input: CreateUserCommandInput = {
      command: { operation: CommandOperation.UPDATE_PARTIAL },
      userID: id,
      firstName: firstName,
      lastName: lastName,
      profileImageKey: profileImageKey,
    };

    const command = await this.sendUserCommand(input);

    // clean up local names if the logged-in user is the one who was updated
    const cur = await Auth.currentAuthenticatedUser();
    if (cur.attributes.email.toLowerCase() == existing.email) {
    
      const result = await Auth.updateUserAttributes(cur, { "family_name": lastName, "given_name": firstName });
      // log.info(`UPDATED LOGGED-IN USER NAMES AND GOT RESULT:`);
      // log.info(result);
      this.cache.removeItem('loggedInUser');
    }

    return command;
  }
  //don't export
  async updateUser(id: string, firstName: string, lastName: string, status: UserStatus, identityId: string, username: string) : Promise<User>
  {
    const getResult: any = await this.api.graphql({
      query: q.getUser,
      variables: {
        id: id
      }
    })
    const existing: User = getResult.data.getUser;
    if (!existing) {
      throw new Error(`Tried to update non-existent User with id: ${id}`);
    }
    const updateResult: any = await this.api.graphql({
      query: m.updateUser,
      variables: {
        input: {
          id: id,
          firstName: firstName,
          lastName: lastName,
          email: existing.email,
          status: status,
          identityID: identityId,
          username: username,
        }
      }
    });
    return updateResult.data.updateUser;
  }
  async getUser(id: string) : Promise<User>
  {
    let u;
    try {
      const getResult: any = await this.api.graphql({
        query: q.getUser,
        variables: {
          id: id
        }
      });

      u = getResult.data.getUser;
    } catch (ex: any) {
      log.error(ex.errors);
      if (ex.data) {
        u = ex.data.getUser;
      }
    }
    return u;
  }
  async setIdentity(user: User) : Promise<string>
  {
    if (!user.identityID || user.identityID.length === 0) {
      const creds = await Auth.currentUserCredentials();
      const createResult: any = await this.api.graphql({
        query: m.createUserCommand,
        variables: {
          input: {
            command: {
              operation: CommandOperation.UPDATE_PARTIAL
            },
            userID: user.id,
            identityID: creds.identityId
          }
        }
      });
      return creds.identityId;
    } else {
      return user.identityID;
    }
  }
  async getQuestionsByUser(userId: string) : Promise<CommunityQuestion[]>
  {
    const variables = { userID: userId };
    // log.info(`variables:`);
    // log.info(JSON.stringify(variables, null, 2));
    const results = await this.flq.queryFullList('questionsByUser', q.questionsByUser, variables);
    return results;
  }
  //don't export
  async getOrCreateUserFromAuthenticatedUser() : Promise<User|undefined>
  {
    log.info('getOrCreateUserFromAuthenticatedUser');
    const user = await Auth.currentAuthenticatedUser();
    const variables = { filter: { email: { eq: user.attributes.email.toLowerCase() } } };

    log.info(`looking for user with email: ${user.attributes.email.toLowerCase()}`);
    const results = await Promise.all([
      this.flq.queryFullList('listUsers', q.listUsers, variables),
      Auth.currentUserCredentials(),
      AsyncStorage.getItem('referredBySignUpCode'),
    ]);

    const list = results[0];
    const creds = results[1];
    const referralCode: string = results[2] as string;
    
    //log.info(list);
    const users = list;
    if (users.length === 0) {
      // Analytics.record({
      //   name: 'userRecordCreated',
      //   attributes: {
      //     firstName: user.attributes.given_name,
      //     lastName: user.attributes.family_name,
      //     email: user.attributes.email.toLowerCase(),
      //     status: UserStatus.JOINED,
      //     cognitoUsername: user.attributes.sub,
      //     identityId: creds.identityId,
      //   }
      // });

      let referredByUserId;
      if (referralCode && referralCode.length > 0) {
        const referrer = await this.getUserFromReferralCode(referralCode);
        if (referrer) {
          referredByUserId = referrer.id;
        }
      }

      log.info(`creating user: ${user.attributes.given_name}, ${user.attributes.family_name}, ${UserStatus.JOINED}, ${creds.identityId}, ${user.attributes.sub}, ${referredByUserId}`);
      return await this.createUserWithIdentity(
          user.attributes.given_name,
          user.attributes.family_name,
          user.attributes.email.toLowerCase(),
          UserStatus.JOINED,
          creds.identityId,
          user.attributes.sub,
          referredByUserId
      );
    } else {
      const loggedInUser: User = users[0];
      log.info(`found loggedInUser: ${JSON.stringify(loggedInUser)}`);
      if (loggedInUser.firstName !== user.attributes.given_name ||
          loggedInUser.lastName !== user.attributes.family_name ||
          loggedInUser.status !== UserStatus.JOINED ||
          loggedInUser.identityID !== creds.identityId ||   // We need to do this in case the cogntio user gets deleted, then re-created... we don't want to lose the user record connection
          loggedInUser.username !== user.attributes.sub) {  // see above comment
        log.info(`updating user: ${user.attributes.given_name}, ${user.attributes.family_name}, ${UserStatus.JOINED}, ${creds.identityId}, ${user.attributes.sub}`)
        return await this.updateUser(loggedInUser.id, user.attributes.given_name, user.attributes.family_name, UserStatus.JOINED, creds.identityId, user.attributes.sub);
      } else {
        return loggedInUser;
      }
    }
  }
  async getLoggedInUserFullName() : Promise<string>
  {
    const user = await this.getLoggedInUser();
    return this.makeFullName(user.firstName, user.lastName);
  }
  async getLoggedInUser() : Promise<User>
  {
    //console.trace('getLoggedInUser');
    let u = this.cache.getItem('loggedInUser');
    if (!u) {
      log.info("getting logged in user from DB");
      const user = await this.getOrCreateUserFromAuthenticatedUser();
      
      if (this.subscription) {
        this.subscription.unsubscribe();
      }

      this.subscription = user ? subscribeToUserUpdates(user.id, (u) => {
        log.info(`loggedInUser subscription received update -- putting in cache`)
        this.cache.setItem('loggedInUser', u);
      }) : undefined;

      this.cache.setItem('loggedInUser', user);
      u = this.cache.getItem('loggedInUser');
    } else {
      //log.info("got user from cache");
    }
    return u;
  }
  async getUserReferralCode(userId: string) : Promise<string>
  {
    const u = await this.getUser(userId);
    if (u.referralCode && u.referralCode.length > 0) {
      return u.referralCode;
    } else {
      const code = this.generateUserReferralCode(u);
      await this.sendUserCommand({
        command: {
          operation: CommandOperation.UPDATE_PARTIAL
        },
        userID: u.id,
        referralCode: code
      })
      return code;
    }
  }
  //don't export
  generateUserReferralCode(user: User) : string
  {
    // full name, strip everything that's not a letter, make lowercase, get first <=6 chars
    const s = this.getFullName(user).replace(/[^a-zA-Z]/g, "").toLowerCase().substring(0, 6);
    return s + user.id.substring(30);
  }
  async getUserFromReferralCode(referralCode: string) : Promise<User>
  {
    //const variables = { referralCode: referralCode };
    const variables = { referralCode: referralCode };
    const results = await this.flq.queryFullList('usersByReferralCode', q.usersByReferralCode, variables);
    return results[0];
  }
  async getFirstNameFromReferralCode(referralCode: string) : Promise<string>
  {
    const getResult: any = await this.api.graphql({
      query: q.getUserReferralCode,
      variables: {
        referralCode: referralCode
      },
      authMode: GRAPHQL_AUTH_MODE.AWS_IAM,
    });

    if (getResult && getResult.data && getResult.data.getUserReferralCode && getResult.data.getUserReferralCode.firstName && getResult.data.getUserReferralCode.firstName.length > 0) {
      return getResult.data.getUserReferralCode.firstName;
    } else {
      return `Someone`; // ?? shouldn't happen
    }
  }
  async isLoggedInUserAdmin() : Promise<boolean>
  {
    const user = await Auth.currentAuthenticatedUser();
    let isAdmin = false;
    try {
      if (user.signInUserSession.accessToken.payload['cognito:groups']) {
        isAdmin = user.signInUserSession.accessToken.payload['cognito:groups'].indexOf('NovellaAdmins') !== -1;
      }
    } catch (error) {
      log.error(error);
      return false;
    }
    return isAdmin;
  }
  
  async flagWelcomeAsSeen(sequenceName: string, loggedInUser: User) : Promise<UserCommand | null>
  {
    // const getResult: any = await api.graphql({
    //   query: q.getUser,
    //   variables: {
    //     id: id
    //   }
    // })
    // const existing: User = getResult.data.getUser;
    // if (!existing) {
    //   throw new Error(`Tried to update non-existent User with id: ${id}`);
    // }

    let newWelcomes = [ sequenceName ];

    if (loggedInUser.welcomesSeen) {
      if (loggedInUser.welcomesSeen.some(x => x.toUpperCase() == sequenceName.toUpperCase())) {
        return null;
      }  
      newWelcomes = [ ...loggedInUser.welcomesSeen, sequenceName ];
    }

    const input: CreateUserCommandInput = {
      command: { operation: CommandOperation.UPDATE_PARTIAL },
      userID: loggedInUser.id,
      welcomesSeen: newWelcomes
    };

    const command = await this.sendUserCommand(input);

    return command;
  }
  async flagHelpAsSeen(helpName: string, loggedInUser: User) : Promise<UserCommand | null>
  {
    // const getResult: any = await api.graphql({
    //   query: q.getUser,
    //   variables: {
    //     id: id
    //   }
    // })
    // const existing: User = getResult.data.getUser;
    // if (!existing) {
    //   throw new Error(`Tried to update non-existent User with id: ${id}`);
    // }

    let newHelps = [ helpName ];

    if (loggedInUser.helpsSeen) {
      log.info(`USER SERVICE: helps already seen:`);
      log.info(loggedInUser.helpsSeen);
      if (loggedInUser.helpsSeen.some(x => x.toUpperCase() == helpName.toUpperCase())) {
        log.info(`USER SERVICE: help already seen: ${helpName}`);
        return null;
      }  
      newHelps = [ ...loggedInUser.helpsSeen, helpName ];
    }

    log.info(`USER SERVICE: newHelps for user:`);
    log.info(newHelps);

    const input: CreateUserCommandInput = {
      command: { operation: CommandOperation.UPDATE_PARTIAL },
      userID: loggedInUser.id,
      helpsSeen: newHelps
    };

    const command = await this.sendUserCommand(input);

    return command;
  }
}