import { ThemeType, ITextVariants, ITextVariant, IButtonStyle } from "./types/Theme";

export const Palette = {
  teal: '#08b9aa',
  yellow: '#f3b844',
  plum: '#32306b',
  orange: '#e36848',
  spinach: '#598a48',
  pink: '#e0338a',
  purple: '#636dad',
  lightteal: '#7fcecf',
  lightyellow: '#f9d35c',
  lightorange: '#f78f47',
  lightspinach: '#d7e07d',
  lightpink: '#f6a0a5',
  lightpurple: '#9b9dca',
  white: '#fffeff',
  black: '#3a393b',
  red: '#ff0020',
  green: '#009c20',
  grey: '#ACB5B5',
  overlaygrey: 'rgba(50, 50, 50, 0.7)',
  lightgrey: '#e3e3e3',
  transparent: 'rgba(255, 255, 255, 0)',
  faintgrey: 'rgba(245, 245, 245, 1)',
  textgrey: '#4c4d4f',
  heart: '#EE4571',
}

const colors = {
  background: Palette.white,
  foreground: Palette.black,
  primary: Palette.teal,
  secondary: Palette.grey,
  tertiary: Palette.lightpink,
  success: Palette.green,
  danger: Palette.red,
  warning: Palette.lightyellow,
  failure: Palette.red,
  card: Palette.white,
  text: Palette.textgrey,
  border: Palette.black,
  notification: Palette.yellow,
  overlay: Palette.overlaygrey,
  detail: Palette.lightgrey,
  transparent: Palette.transparent,
  toastBorder: Palette.grey,
  toastBackground: Palette.white,
  toastMessage: Palette.textgrey,
}

const textVariants: ITextVariants = {
  header: {
    //fontFamily: 'Arial',
    fontSize: 24,
    fontWeight: 'bold',
  },
  body: {
    //fontFamily: 'Arial',
    fontSize: 14,
    fontWeight: 'normal'
  },
  questionHeader: {
    //fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: 'bold'
  },
  detail: {
    //fontFamily: 'Arial',
    fontSize: 12,
    fontWeight: 'normal'
  },
  authSecondaryButton: {
    //fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: 'bold'
  },
  inputLabel: {
    //fontFamily: 'Arial',
    fontSize: 14,
    fontWeight: 'bold'
  },
  bottomTabLabelLarge: {
    //fontFamily: 'Arial',
    fontSize: 16, // no diff anymore
    fontWeight: 'normal'
  },
  bottomTabLabelSmall: {
    //fontFamily: 'Arial',
    fontSize: 14,
    fontWeight: 'normal'
  },
  cardHeader: {
    //fontFamily: 'Arial',
    fontSize: 18,
    fontWeight: '700',
    color: colors.text,
  },
  headerTopTitle: {
    //fontFamily: 'Arial',
    fontSize: 34,
    fontWeight: 'bold'
  },
  headerSecondTitle: {
    //fontFamily: 'Arial',
    fontSize: 18,
    fontWeight: 'bold'
  },
  memberCardEmail: {
    //fontFamily: 'Arial',
    fontSize: 14,
    fontWeight: '400'
  },
  memberCardJoined: {
    //fontFamily: 'Arial',
    fontSize: 13,
    fontWeight: '400',
    color: colors.text,
  },
  memberCardName: {
    //fontFamily: 'Arial',
    fontSize: 18,
    fontWeight: '700',
    color: colors.text,
  },
  avatarText: {
    //fontFamily: 'Arial',
    fontSize: 24,
    fontWeight: '400',
  },
  listFooter: {
    //fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: '400',
    color: colors.text,
  },
  listHeader: {
    //fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: '400',
    color: colors.text,
  },
  chip: {
    //fontFamily: 'Arial',
    fontSize: 9,
    fontWeight: '700',
  },
  recommendationHeading: {
    //fontFamily: 'Arial',
    fontSize: 14,
    fontWeight: '700',
    color: colors.text,
  },
  recommendationText: {
    //fontFamily: 'Arial',
    fontSize: 15,
    fontWeight: '400',
    color: colors.text,
  },
  cardBottomButton: {
    ////fontFamily: 'Arial',
    fontSize: 12,
    fontWeight: '500',
  },
  askQuestionHeader: {
    //fontFamily: 'Arial',
    fontSize: 20,
    fontWeight: '500',
  },
  formLabel: {
    //fontFamily: 'Arial',
    fontSize: 18,
    fontWeight: '700',
    color: colors.text,
  },
  profileHeader: {
    //fontFamily: 'Arial',
    fontSize: 24,
    fontWeight: '700',
    color: colors.text,
  },
  profileEmail: {
    //fontFamily: 'Arial',
    fontSize: 18,
    fontWeight: '400',
    color: colors.text,
  },
  wizardHeader: {
    //fontFamily: 'Arial',
    fontSize: 24,
    fontWeight: '700',
    color: colors.text,
    lineHeight: 30,
  },
  wizardSubHeader: {
    //fontFamily: 'Arial',
    fontSize: 18,
    fontWeight: '700',
    color: colors.text,
  },
  wizardBody: {
    //fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: '400',
    color: colors.text,
  },
  userPickerTop: {
    //fontFamily: 'Arial',
    fontSize: 18,
    fontWeight: '700',
    color: colors.text,
    lineHeight: 24,
  },
  userPickerBottom: {
    //fontFamily: 'Arial',
    fontSize: 14,
    fontWeight: '400',
    color: colors.text,
    lineHeight: 18,
  },
  userPickerAddUserHeader: {
    //fontFamily: 'Arial',
    fontSize: 14,
    fontWeight: '700',
    color: Palette.textgrey,
    lineHeight: 18,
  },
  userPickerAddUserFieldValue: {
    //fontFamily: 'Arial',
    fontSize: 13,
    fontWeight: '700',
    color: colors.text,
    lineHeight: 16,
  },
  userPickerAddUserFieldPlaceholder: {
    //fontFamily: 'Arial',
    fontSize: 13,
    fontWeight: '700',
    color: Palette.grey,
    lineHeight: 16,
  },
  userPickerAddUserButton: {
    //fontFamily: 'Arial',
    fontSize: 12,
    fontWeight: '700',
    lineHeight: 16,
  },
  userPickerChip: {
    //fontFamily: 'Arial',
    fontSize: 13,
    fontWeight: '400',
    lineHeight: 16,
  },
  toastMessage: {
    //fontFamily: 'Arial',
    fontSize: 18,
    fontWeight: '400',
    color: colors.toastMessage,
  },
  communityBadge: {
    //fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: '700'
  },
  previewHeader: {
    //fontFamily: 'Arial',
    fontSize: 20,
    fontWeight: '700',
    color: Palette.purple,
    lineHeight: 60,
  },
  previewLabel: {
    //fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: '700',
  },
  previewBody: {
    //fontFamily: 'Arial',
    fontSize: 12,
    fontWeight: '400'
  },
  previewBodyEmphasis: {
    //fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: '400',
    textTransform: 'uppercase',
    fontStyle: 'italic',
  },
  groupHeading: {
    //fontFamily: 'Arial',
    fontSize: 16,
    fontWeight: '700',
  },
}

const buttonStyle: IButtonStyle = {
  borderWidth: 1,
  borderRadius: 50,
  paddingHorizontal: 16,
  paddingVertical: 2,
  minHeight: 44,
};

const buttonStyle2: IButtonStyle = {
  borderWidth: 1,
  borderRadius: 50,
  paddingHorizontal: 16,
  paddingVertical: 2,
  minHeight: 44,
  minWidth: 214,
  marginVertical: 5,
};

const titleStyle: ITextVariant = {
  ...textVariants.body,
  fontWeight: 'bold',
}

export const LIGHT_THEME : ThemeType = {
  dark: false,
  colors: colors,
  spacing: {
    z: 0,
    s: 6,
    m: 16,
    l: 24,
    xl: 40,
  },
  textVariants: textVariants,
  slideUp: {
    style: {
      backgroundColor: colors.background,
      borderTopLeftRadius: 20,
      borderTopRightRadius: 20,
      borderWidth: 2,
      borderColor: '#DDEAEB',
      padding: 14,
      paddingBottom: 0,
    }
  },
  card: {
    style: {
      borderRadius: 20,
      borderColor: Palette.lightgrey,
      borderWidth: 1,
      marginVertical: 6,
      backgroundColor: colors.background
    },
    colors: {
      1: Palette.lightteal,
      2: Palette.lightyellow,
      3: Palette.lightorange,
      4: Palette.lightspinach,
      5: Palette.lightpink,
      6: Palette.lightpurple
    }
  },
  buttons: {
    secondary: {
      buttonStyle: {
        ...buttonStyle,
        borderColor: Palette.yellow,
        backgroundColor: colors.background,
      },
      titleStyle: {
        ...titleStyle,
        color: Palette.plum
      }
    },
    secondary2: {
      buttonStyle: {
        ...buttonStyle2,
        borderColor: Palette.lightteal,
        backgroundColor: Palette.transparent,
      },
      titleStyle: {
        ...titleStyle,
        color: Palette.lightteal
      }
    },
    mild: {
      buttonStyle: {
        ...buttonStyle,
        borderColor: Palette.teal,
        backgroundColor: Palette.white,
      },
      titleStyle: {
        ...titleStyle,
        color: Palette.teal
      }
    },
    mild2: {
      buttonStyle: {
        ...buttonStyle,
        borderColor: Palette.plum,
        backgroundColor: Palette.white,
      },
      titleStyle: {
        ...titleStyle,
        color: Palette.plum
      }
    },
    primary: {
      buttonStyle: {
        ...buttonStyle,
        borderColor: Palette.yellow,
        backgroundColor: Palette.yellow,
      },
      titleStyle: {
        ...titleStyle,
        color: Palette.plum
      }
    },
    primary2: {
      buttonStyle: {
        ...buttonStyle2,
        borderColor: Palette.lightteal,
        backgroundColor: Palette.lightteal,
      },
      titleStyle: {
        ...titleStyle,
        color: Palette.plum,
      },
    },
    action: {
      buttonStyle: {
        ...buttonStyle2,
        borderColor: Palette.yellow,
        backgroundColor: Palette.yellow,
      },
      titleStyle: {
        ...titleStyle,
        color: Palette.plum,
      },
    },
    link: {
      buttonStyle: {
        borderRadius: 0,
        borderWidth: 0,
        paddingHorizontal: 0,
        paddingVertical: 0,
        borderColor: Palette.transparent,
        backgroundColor: Palette.transparent,
      },
      titleStyle: {
        ...titleStyle,
        color: Palette.lightteal,
        fontWeight: 'normal',
        padding: 0,
      }
    },
    brightLink: {
      buttonStyle: {
        borderRadius: 0,
        borderWidth: 0,
        paddingHorizontal: 0,
        paddingVertical: 0,
        borderColor: colors.transparent,
        backgroundColor: colors.transparent,
      },
      titleStyle: {
        ...titleStyle,
        color: colors.primary,
        fontWeight: 'bold',
        padding: 0,
      }
    },
    danger: {
      buttonStyle: {
        ...buttonStyle,
        borderColor: colors.danger,
        backgroundColor: colors.danger,
      },
      titleStyle: {
        ...titleStyle,
        color: colors.background
      }
    }
  }
};

export const DARK_THEME = {
  ...LIGHT_THEME,
  dark: true,
  colors: {
    ...LIGHT_THEME.colors,
    background: Palette.black,
    foreground: Palette.white,
    primary: Palette.teal,
    tertiary: Palette.pink,
    success: Palette.green,
    danger: Palette.red,
    failure: Palette.red,
    card: Palette.black,
    text: Palette.white,
    border: Palette.white,
    notification: Palette.teal
  },
  card: {
    ...LIGHT_THEME.card,
    colors: {
      1: Palette.teal,
      2: Palette.yellow,
      3: Palette.orange,
      4: Palette.spinach,
      5: Palette.pink,
      6: Palette.purple
    }
  }
}
