// Frameworks
import React, { useContext, useEffect, useState } from 'react';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { ActivityItem, ActivityType } from './types';
import { Image, ImageBackground, LayoutChangeEvent, Platform, Pressable, Text, View } from 'react-native';
import MemberAvatar from '../../components/MemberAvatar';
import { Avatar, Icon } from 'react-native-elements';
import { Palette } from '../../../Theme';
import { getAbsoluteFullTimeLabel, getRelativeTimeLabel } from '../../../business/date/dateFormatter';
import { PHONE_BREAK, TABLET_BREAK } from '../../../business/layout/layout';
import { useLinkTo } from '@react-navigation/native';
import { getVimeoMetadataForAnswer } from '../../../business/video/vimeo';
import LikeButton from '../../components/LikeButton';
import CommentButton from '../../components/CommentButton';
import { LikeData, LikeMap } from '../../../contracts/ICommunityService';
import { CommunityQuestion, CommunityQuestionAnswer, CommunityQuestionAnswerComment } from "../../../API";
import log from '../../../business/logging/logger';
import { Dictionary } from '../../../types/data/Dictionary';
import WatchAnswersButton from '../../components/WatchAnswersButton';
import ConstrainedUserImage from '../../components/ConstrainedUserImage';

const getIconName = (activityType: ActivityType): string => {
  switch (activityType) {
    case ActivityType.Question:
      return 'help';
    case ActivityType.Answer:
      return 'check';
    case ActivityType.Comment:
      return 'comment-outline';
    case ActivityType.Like:
      return 'heart-outline';
    case ActivityType.CommunityJoin:
      return 'account-plus-outline';
    case ActivityType.CommunityCreation:
      return 'star-outline';
    default:
      return '';
  }
}

const getIconColor = (activityType: ActivityType): string => {
  switch (activityType) {
    case ActivityType.Question:
      return Palette.purple;
    case ActivityType.Answer:
      return Palette.teal;
    case ActivityType.Comment:
      return Palette.yellow;
    case ActivityType.Like:
      return Palette.heart;
    case ActivityType.CommunityJoin:
      return Palette.lightorange;
    case ActivityType.CommunityCreation:
      return Palette.lightyellow;
    default:
      return '';
  }
}

function ActivityItemCard(props: { item: ActivityItem, communityId: string, answerLikeMap: LikeMap, questionLikeMap: LikeMap, answerVisibilityMap: Dictionary<number> }): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const [actorFullName, setActorFullName] = useState('');
  const [actorDisplayName, setActorDisplayName] = useState('');
  const [targetUserDisplayName, setTargetUserDisplayName] = useState('');
  const [shouldDisplay, setShouldDisplay] = useState(false);
  const [cardWidth, setCardWidth] = useState(1);
  const linkTo = useLinkTo();

  // state specific to Answers
  const [thumbnailUrl, setThumbnailUrl] = useState<string | undefined>();
  const [answer, setAnswer] = useState<CommunityQuestionAnswer>();
  const [comments, setComments] = useState<CommunityQuestionAnswerComment[]>([]);
  const [answerLikes, setAnswerLikes] = useState<LikeData>(new LikeData(false, 0));

  // state specific to Question
  const [question, setQuestion] = useState<CommunityQuestion>();
  const [visibleAnswerCount, setVisibleAnswerCount] = useState(0);
  const [questionLikes, setQuestionLikes] = useState<LikeData>(new LikeData(false, 0));

  useEffect(() => {
    setActorFullName(commonContext.services.userService.getFullName(props.item.actor));

    if (props.item.activityType == ActivityType.Answer) {
      // initialize answer-specific state
      const a = props.item.item as CommunityQuestionAnswer;
      setAnswer(a);
      const c = a.communityQuestionAnswerCommentsByStatusDate?.items.filter(i => i != null);
      if (c && c.length > 0) {
        setComments(c);
      }
      setShouldDisplay(true);
      getVimeoMetadataForAnswer(commonContext.services.communityService, props.item.key)
        .then(metadata => {
          const thumbnailIdx = metadata?.thumbnailUrls.findIndex(t => t.width >= commonContext.dimensions.width);
          setThumbnailUrl(metadata?.thumbnailUrls[thumbnailIdx ?? 1].link);

          // only show the answer if the video is finished transcoding and thus ready to watch
          //setShouldDisplay(metadata?.transcodingStatus == 'complete');
        })
        .catch(ex => {
          console.warn(`unable to get vimeo thumbnail for answer id: ${props.item.key}`);
        });
    } else {
      // initialize state for items types other than answers
      setShouldDisplay(true);

      if (props.item.activityType == ActivityType.Question) {
        // initialize state specific to questions
        const q = props.item.item as CommunityQuestion;
        setQuestion(q);

        if (props.answerVisibilityMap) {
          log.info(`rendering answer button`);
          log.info(q.id);
          log.info(props.answerVisibilityMap[q.id]);
          setVisibleAnswerCount(props.answerVisibilityMap[q.id] ?? 0);
        }
      }
    }
  }, [props.answerVisibilityMap]);

  useEffect(() => {
    if (question && props.questionLikeMap) {
      setQuestionLikes(props.questionLikeMap[question.id]);
    } else {
      setQuestionLikes(new LikeData(false, 0));
    }
  }, [question, props.questionLikeMap]);

  useEffect(() => {
    if (answer && props.answerLikeMap) {
      setAnswerLikes(props.answerLikeMap[answer.id]);
    } else {
      setAnswerLikes(new LikeData(false, 0));
    }
  }, [answer, props.answerLikeMap]);

  useEffect(() => {
    const loggedInUserFullName = commonContext.services.userService.getFullName(commonContext.loggedInUser);
    setActorDisplayName(actorFullName == loggedInUserFullName ? `You` : actorFullName);
    setTargetUserDisplayName(props.item.targetUserName == loggedInUserFullName ? `your` : `${props.item.targetUserName}'s`);
  }, [commonContext.loggedInUser, actorFullName]);

  const toggleAnswerLike = async (answer: CommunityQuestionAnswer): Promise<void> => {
    try {
      let al = answerLikes;
      if (!al) {
        al = new LikeData(true, 1);
      } else {
        al.like(!al.getLiked());
      }
      log.info(answer.id);
      log.info(JSON.stringify(al, null, 2));
      setAnswerLikes(al);
      await commonContext.services.communityService.toggleCommunityQuestionAnswerLike(answer);
    } catch (ex) {
      log.error(ex);
    }
  }

  const toggleQuestionLike = async (question: CommunityQuestion): Promise<void> => {
    try {
      let ql = questionLikes;
      if (!ql) {
        ql = new LikeData(true, 1);
      } else {
        ql.like(!ql.getLiked());
      }
      log.info(question.id);
      log.info(JSON.stringify(ql, null, 2));
      setQuestionLikes(ql);
      await commonContext.services.communityService.toggleCommunityQuestionLike(question);
    } catch (ex) {
      log.error(ex);
    }
  }

  const clickAnswerLink = async (question: CommunityQuestion): Promise<void> => {
    log.info(`/community/${question.community.id}/questions/answers/${question.id}`);
    linkTo(`/community/${question.community.id}/questions/answers/${question.id}`);
  }

  const styles = {
    textBase: {
      fontFamily: 'Arial',
      fontSize: 16,
      color: Palette.textgrey,
      //lineHeight: '1.2em',
    },
    textBold: {
      fontWeight: '700',
    },
    textNormal: {
      fontWeight: '400',
    },
    textItalic: {
      fontStyle: 'italic',
      fontWeight: '400',
    }
  }

  const getCardWidth = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout;
    setCardWidth(width);
  }

  const topMarginHorizontal = commonContext.dimensions.width > TABLET_BREAK ? 10 : 0;
  const avatarSize = 43;
  const innerMarginLeft = 16;
  const avatarMargin = 12;

  //const leftGap = topMarginHorizontal + avatarSize + innerMarginLeft;
  const leftGap = avatarMargin + topMarginHorizontal
  const imageWidth = cardWidth - (innerMarginLeft * 2);
  return (
    <View
      style={{
        flex: 1,
        display: shouldDisplay ? 'flex' : 'none',
        //flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        marginBottom: 8,
        marginHorizontal: topMarginHorizontal,
        //padding: 8,
        ...themeFromContext.card.style,
        //borderRadius: 8,
        //backgroundColor: Palette.faintgrey,
        // borderRadius: 0,
        // marginVertical: 0,
        // borderWidth: 0,
      }}
      onLayout={getCardWidth}
    >
      {/* <View
        style={{
          position: 'absolute',
          borderLeftWidth: 1,
          borderLeftColor: Palette.grey,
          height: '100%',
          top: avatarSize,
          left: (avatarSize + topMarginHorizontal + 8) / 2,
        }}
      ></View> */}
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
      <MemberAvatar
        size={avatarSize}
        userData={props.item.actor}
        ignoreUpdate={true}
        icon={props.item.actor ? undefined : { type: 'material-community', name: getIconName(props.item.activityType) }}
        containerStyle={{
          //marginBottom: 16,
          backgroundColor: props.item.actor ? themeFromContext.colors.secondary : getIconColor(props.item.activityType),
          margin: avatarMargin,
        }}
      >
        {
          props.item.actor &&
          <Avatar.Accessory
            type='material-community'
            name={getIconName(props.item.activityType)}
            size={20}
            color={Palette.white}
            style={{
              backgroundColor: getIconColor(props.item.activityType),
              padding: Platform.OS !== 'web' ? 0 : 12,
              borderRadius: 100,
              marginRight: 0 - 6,
              marginBottom: 0 - 6,
              //filter: 'drop-shadow(2px 2px 6px rgba(50, 50, 0, 0.2))'
            }}
          />
        }
      </MemberAvatar>
      <View
        style={{
          display: 'flex',
          alignItems: 'flex-start',
          justifyContent: 'center',
          margin: 0,
          marginTop: 12,
          marginLeft: 6,
          height: avatarSize + 8,
        }}
      >
      <Text
        style={[
          styles.textBase,
          styles.textBold
        ]}
        numberOfLines={1}
      >
        {actorDisplayName}
      </Text>
      <Text
          style={{
            fontFamily: 'Arial',
            fontSize: 14,
            fontWeight: '400',
            color: Palette.grey,
            marginBottom: 8,
          }}
          numberOfLines={1}
          testID={`answer-card-header-bottom`}
        >
          {getAbsoluteFullTimeLabel(props.item.timestamp)}
          {/* {(new Date(answer.date)).toLocaleDateString()} */}
        </Text>
      </View>
      </View>
      <View
        style={{
          flex: 1,
          display: 'flex',
          width: '100%',
          marginLeft: innerMarginLeft,
          marginRight: 4,
          marginBottom: 12,
        }}
      >
        {
          props.item.activityType == ActivityType.Answer &&
          <Pressable
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: imageWidth,
            }}
            onPress={() => linkTo(`/community/${props.communityId}/questions/answer/comments/${props.item.key}`)}
          >
            <Text
              style={[
                styles.textBase,
                //styles.textBold
              ]}
              numberOfLines={3}
            >
              {actorDisplayName}
              <Text style={styles.textNormal}>
                {` answered `}
                <Text style={styles.textBold}>
                  {targetUserDisplayName}
                  <Text style={styles.textNormal}>
                    {` question: `}
                    <Text style={styles.textNormal}>
                      {`"${props.item.excerpt}"`}
                    </Text>
                  </Text>
                </Text>
              </Text>
            </Text>
            <ImageBackground
              source={{ uri: thumbnailUrl?.replace('r=pad', '') }}
              //defaultSource={{ uri: thumbnailUrl }}
              resizeMethod='scale'
              resizeMode='cover'
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: imageWidth,//normalizeHeight(343, height),
                height: imageWidth * 0.75,
                marginTop: 10,
              }}
            >

              <Icon name='play-circle-outline' type='material-community' size={48} color={Palette.white.concat('99')} />

            </ImageBackground>
          </Pressable>
        }
        {
          props.item.activityType == ActivityType.Question &&
          <Pressable
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: imageWidth,
            }}
            onPress={() => linkTo(`/community/${props.communityId}/questions/asked/${props.communityId}`)}
          >
          <Text
            style={[
              styles.textBase,
              //styles.textBold
            ]}
            numberOfLines={3}
          >
            {actorDisplayName}
            <Text style={styles.textNormal}>
              {` asked a question: `}
              <Text style={styles.textNormal}>
                {`"${props.item.excerpt}"`}
              </Text>
            </Text>
          </Text>
          {
            //props.item.targetItemType == 'Question' &&
            props.item.item &&
            (props.item.item as CommunityQuestion) != undefined &&
            (props.item.item as CommunityQuestion) != null &&
            (props.item.item as CommunityQuestion).imageKeys != undefined &&
            (props.item.item as CommunityQuestion).imageKeys != null &&
            (props.item.item as CommunityQuestion)?.imageKeys?.length > 0 &&
            <View
              style={{
                marginTop: 10,
              }}
            >
              <ConstrainedUserImage imageKey={(props.item.item as CommunityQuestion).imageKeys[0]} maxWidth={imageWidth} />
            </View>
          }
          </Pressable>
        }
        {
          props.item.activityType == ActivityType.Like &&
          <Pressable
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: imageWidth,
            }}
            onPress={() => {
              if (props.item.targetItemType == "Answer") {
                linkTo(`/community/${props.communityId}/questions/answer/comments/${props.item.targetItem?.id}`);
              } else {
                linkTo(`/community/${props.communityId}/questions/asked/${props.communityId}`);
              }
            }}
          >
          <Text
            style={[
              styles.textBase,
              //styles.textBold
            ]}
            numberOfLines={3}
          >
            {actorDisplayName}
            <Text style={styles.textNormal}>
              {` liked `}
              <Text style={styles.textBold}>
                {targetUserDisplayName}
                <Text style={styles.textNormal}>
                  {(props.item.targetItemType == 'Answer' ? ` answer` : ' question') + (props.item.excerpt != undefined && props.item.excerpt.length > 0 ? `"${props.item.excerpt}"` : `.`)}
                </Text>
              </Text>
            </Text>
          </Text>
          </Pressable>
        }
        {
          props.item.activityType == ActivityType.Comment &&
          <Pressable
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: imageWidth,
            }}
            onPress={() => linkTo(`/community/${props.communityId}/questions/answer/comments/${props.item.targetItem?.id}`)}
          >
            <Text
              style={[
                styles.textBase,
                //styles.textBold
              ]}
              numberOfLines={3}
            >
              {actorDisplayName}
              <Text style={styles.textNormal}>
                {` commented on `}
                <Text style={styles.textBold}>
                  {targetUserDisplayName}
                  <Text style={styles.textNormal}>
                    {` answer.`}
                  </Text>
                </Text>
              </Text>
            </Text>
            <Text
              style={styles.textItalic}
              numberOfLines={1}
            >
              {`"${props.item.excerpt}"`}
            </Text>
          </Pressable>
        }
        {
          props.item.activityType == ActivityType.CommunityJoin &&
          <Pressable
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              width: imageWidth,
            }}
            onPress={() => linkTo(`/profile/${props.item.actor.id}`)}
          >
          <Text
            style={[
              styles.textBase,
              //styles.textBold
            ]}
            numberOfLines={3}
          >
            {actorDisplayName}
            <Text style={styles.textNormal}>
              {` joined the community.`}
            </Text>
          </Text>
          </Pressable>
        }
        {
          props.item.activityType == ActivityType.CommunityCreation &&
          <Text
            style={[
              styles.textBase,
              styles.textNormal
            ]}
            numberOfLines={3}
          >
            {`Community was created.`}
          </Text>
        }
        
        {
          props.item.activityType == ActivityType.Question && question &&
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              marginTop: 10,
            }}
          >
            <LikeButton target={question} likes={questionLikes} toggleLike={toggleQuestionLike} />
            <View style={{padding: 10}}></View>
            <WatchAnswersButton question={question} visibleAnswerCount={visibleAnswerCount} clickWatchLink={clickAnswerLink} />
          </View>
        }
        {
          props.item.activityType == ActivityType.Answer && answer &&
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-start',
              width: '100%',
              marginTop: 10,
            }}
          >
            <LikeButton target={answer} likes={answerLikes} toggleLike={toggleAnswerLike} />
            <View style={{padding: 10}}></View>
            <CommentButton answer={answer} comments={comments} />
          </View>
        }
      </View>
    </View>
  );
}
export default ActivityItemCard;