import axios from 'axios';
import { ICommunityService, VideoVisibility, VimeoVideoMetadata } from '../../contracts/ICommunityService';
import log from '../logging/logger';
import { API } from 'aws-amplify';
import awsConfig from '../../aws-exports';
import { CommunityQuestionAnswer } from '../../API';
API.configure(awsConfig);

const API_NAME = `novellaREST`;

export type VimeoThumbnail = {
  width: number;
  height: number;
  link: string;
  link_with_play_button: string;
}

export async function getThumbnail(videoId: string, thumbnailId?: string): Promise<any> {
  const apiName = API_NAME;
  const path = `/vimeoThumbnails`;

  const qs: any = {
    videoId: videoId,
  };

  if (thumbnailId != undefined) {
    qs["thumbnailId"] = thumbnailId;
  }

  const init = {
    queryStringParameters: qs,
  }

  try {
    const r = await API.get(apiName, path, init);

    log.info(`GOT VIMEO RESPONSE:`);
    log.info(r);
    return r;
  } catch (ex) {
    log.error(`ERROR TRYING TO GET VIMEO THUMBNAIL:`)
    log.error(ex);
  }
}

export async function createThumbnail(videoId: string, timestamp: number): Promise<any> {
  const apiName = API_NAME;
  const path = `/vimeoThumbnails`;

  const qs: any = {
    videoId: videoId,
    t: timestamp,
  };

  const init = {
    queryStringParameters: qs,
    body: {}
  }

  try {
    const r = await API.post(apiName, path, init);

    log.info(`GOT VIMEO RESPONSE:`);
    log.info(r);
    return r;
  } catch (ex) {
    log.error(`ERROR TRYING TO CREATE VIMEO THUMBNAIL:`)
    log.error(ex);
  }
}

export async function editThumbnail(videoId: string, thumbnailId: string, active: boolean): Promise<any> {
  const apiName = API_NAME;
  const path = `/vimeoThumbnails`;

  const qs: any = {
    videoId: videoId,
    thumbnailId: thumbnailId,
    active: active ? 1 : 0,
  };

  const init = {
    queryStringParameters: qs,
    body: {}
  }

  try {
    const r = await API.patch(apiName, path, init);

    log.info(`GOT VIMEO RESPONSE:`);
    log.info(r);
    return r;
  } catch (ex) {
    log.error(`ERROR TRYING TO EDIT VIMEO THUMBNAIL:`)
    log.error(ex);
  }
}

export async function cleanThumbnails(videoId: string): Promise<void> {
  const apiName = API_NAME;
  const path = `/vimeoThumbnails`;

  const qs: any = {
    videoId: videoId,
  };

  const init = {
    queryStringParameters: qs,
  }

  try {
    const r = await API.get(apiName, path, init);

    console.log(`vimeo response to get all thumbnails:`);
    console.log(r);

    let promises = [];
    r.data.data.filter(t => !t.active).forEach(x => {
      const thumbnailId = x.uri.substring(x.uri.lastIndexOf('/') + 1);

      const qs2: any = {
        videoId: videoId,
        thumbnailId: thumbnailId,
      };
    
      const init2 = {
        queryStringParameters: qs2,
      }

      promises = [ ...promises, API.del(apiName, path, init2) ];
    });

    await Promise.all(promises);
  } catch (ex) {
    log.error(`ERROR TRYING TO CLEAN VIMEO THUMBNAILS FOR VIDEO ${videoId}:`)
    log.error(ex);
  }
}

export async function initializeVideo(size: number, name: string, approach: string): Promise<any> {

  if (size == 0) {
    throw `Video size cannot be 0`;
  }
  if (approach != 'post' && approach != 'tus') {
    throw `Vimeo upload approach must be "post" or "tus"`;
  }

  const apiName = API_NAME;
  const path = `/initializeVimeoVideo`;

  const qs: any = {
    size: size,
    approach: approach,
  };

  if (name.length > 0) {
    qs["name"] = name;
  }

  const initializeVideoInit = {
    queryStringParameters: qs,
    body: {}
  }

  try {
    const r = await API.post(apiName, path, initializeVideoInit);

    // log.info(`GOT VIMEO RESPONSE:`);
    // log.info(r);
    return r;
  } catch (ex) {
    log.error(`ERROR TRYING TO INITIALIZE VIMEO VIDEO:`)
    log.error(ex);
  }
}

export async function getVimeoVideosForCommunity(communityId: string): Promise<any> {
  const apiName = API_NAME;
  const path = `/vimeoVideo`;
  const getVideoInit = {
    queryStringParameters: {
      communityId: communityId,
    },
  }

  try {
    const r = await API.get(apiName, path, getVideoInit);

    //log.info(`GOT VIMEO RESPONSE:`);
    //log.info(r);
    return r;
  } catch (ex) {
    log.error(`ERROR TRYING TO GET VIMEO VIDEOS FOR COMMUNTIY (${communityId}):`);
    log.error(ex);
  }
}

export async function getVimeoVideosForAnswer(communityQuestionAnswerId: string): Promise<any> {
  const apiName = API_NAME;
  const path = `/vimeoVideo`;
  const getVideoInit = {
    queryStringParameters: {
      cqaid: communityQuestionAnswerId,
    },
  }

  try {
    const r = await API.get(apiName, path, getVideoInit);

    //log.info(`GOT VIMEO RESPONSE:`);
    //log.info(r);
    return r;
  } catch (ex) {
    log.error(`ERROR TRYING TO GET VIMEO VIDEOS FOR ANSWER (${communityQuestionAnswerId}):`);
    log.error(ex);
  }
}

// export async function getVideo(videoId: string) : Promise<any> {

//   if (videoId.length == 0) {
//     throw `Trying to get Vimeo video without providing a video id!`;
//   }

//   const config = {
//     headers: {
//       "Content-Type": "application/json",
//       "Authorization": `bearer ${WEAK_ACCESS_TOKEN}`,
//       "Accept": "application/vnd.vimeo.*+json;version=3.4",
//     },
//   };

//   const response = await axios.get(
//     `https://api.vimeo.com/videos/${videoId}`,
//     config,
//   );

//   return response;
// }

export type VimeoAnswerContent = {
  videos: VimeoDisplayMetadata[]
}

export type VimeoDisplayMetadata = {
  videoId: string
  embedUrl: string
  thumbnailUrls: VimeoThumbnail[]
  videoWidth: number
  videoHeight: number
  transcodingStatus: string
  title: string
  description: string
  visibility: VideoVisibility
  m3u8: string
  m3u8ExpirationTime: string
  downloadLink: string
  downloadQuality: string
}

// export function convertContentToVimeoMetadata(content: string) : VimeoDisplayMetadata | undefined {
//   let meta;
//   let videoId = '';
//   let title;
//   let description;
//   let visibility;
//   try {
//     meta = JSON.parse(content);
//     videoId = meta.id;
//     title = meta.title;
//     description = meta.description;
//     visibility = meta.visibility ?? VideoVisibility.ORGANIZER;
//   } catch (ex) {
//     log.info(`failed to parse string as JSON: ${content}`);
//   }

//   // failed to parse metadata so we probably just have a video is only
//   if (!videoId || videoId.length == 0) {
//     videoId = c.vimeoVideos[0];
//   }

//   const video = v.find(x => x.uri.replace('/videos/', '') == videoId);

//   if (video) {
//     return {
//       videoId: videoId,
//       embedUrl: video.player_embed_url,
//       videoHeight: video.height,
//       videoWidth: video.width,
//       transcodingStatus: video.transcode.status,
//       title: title,
//       description: description,
//       visibility: visibility,
//     }
//   }
// }

export async function getVimeoMetadata(communityService: ICommunityService, communityId: string): Promise<VimeoDisplayMetadata | undefined> {
  try {
    const promises = [
      communityService.getSingleCommunity(communityId),
      getVimeoVideosForCommunity(communityId)
    ];
    const results = await Promise.all(promises);
    const c = results[0];
    const v: any[] = results[1];

    //log.info(`FULL VIMEO VIDEO RESPONSE:`);
    //log.info(v);

    if (c && c?.vimeoVideos && c?.vimeoVideos.length > 0) {
      //log.info(`FOUND VIMEO VIDEOS: ${JSON.stringify(c.vimeoVideos, null, 2)}`);

      let meta;
      let videoId = '';
      let title;
      let description;
      let visibility;
      try {
        meta = JSON.parse(c.vimeoVideos[0]);
        videoId = meta.id;
        title = meta.title;
        description = meta.description;
        visibility = meta.visibility ?? VideoVisibility.ORGANIZER;
      } catch (ex) {
        log.info(`failed to parse string as JSON: ${c.vimeoVideos[0]}`);
      }

      // failed to parse metadata so we probably just have a video is only
      if (!videoId || videoId.length == 0) {
        videoId = c.vimeoVideos[0];
      }

      const video = v.find(x => x.uri.replace('/videos/', '') == videoId);

      if (video) {
        return {
          videoId: videoId,
          embedUrl: video.player_embed_url,
          thumbnailUrls: video.pictures.sizes,
          videoHeight: video.height,
          videoWidth: video.width,
          transcodingStatus: video.transcode.status,
          title: title,
          description: description,
          visibility: visibility,
          m3u8: video.play.hls.link,
          m3u8ExpirationTime: video.play.hls.link_expiration_time
        }
      }
    }
  } catch (ex) {
    log.error(`Error getting video metadata:`);
    log.error(ex);
  }
  return;
}

// this is the main one?
export async function getVimeoMetadataForAnswer(communityService: ICommunityService, communityQuestionAnswerId: string): Promise<VimeoDisplayMetadata | undefined> {
  try {
    const promises = [
      communityService.getAnswer(communityQuestionAnswerId),
      getVimeoVideosForAnswer(communityQuestionAnswerId)
    ];
    const results = await Promise.all(promises);
    const a: CommunityQuestionAnswer | undefined = results[0];
    const v: any[] = results[1];

    // log.info(`FULL VIMEO VIDEO RESPONSE:`);
    // log.info(v);

    if (a && a.content && a.content.length > 0) {
      //log.info(`FOUND VIMEO VIDEOS: ${a.content}`);

      let meta;
      let videoId = '';
      let title = '';
      let description = '';
      let visibility = VideoVisibility.ORGANIZER;
      try {
        const contentArray: VimeoVideoMetadata[] = JSON.parse(a.content);
        meta = contentArray[0];
        videoId = meta.id;
        title = meta.title;
        description = meta.description;
        visibility = meta.visibility ?? VideoVisibility.ORGANIZER;
      } catch (ex) {
        log.info(`failed to parse string as JSON array: ${a.content}`);
      }

      const video = v.find(x => x.uri.replace('/videos/', '') == videoId);
      // console.log(`Vimeo result:`);
      // console.log(video);

      let dl;
      if (video.download?.length > 0) {
        // find the download link with the highest quality
        dl = video.download.reduce((prevResult, current) => {
          if (prevResult.height > current.height) {
            return prevResult;
          } else {
            return current;
          }
        });
      }

      const dlLink = dl?.link;
      const dlRes = dl?.public_name;

      if (video) {
        return {
          videoId: videoId,
          embedUrl: video.player_embed_url,
          thumbnailUrls: video.pictures.sizes,
          videoHeight: video.height,
          videoWidth: video.width,
          transcodingStatus: video.transcode.status,
          title: title,
          description: description,
          visibility: visibility,
          m3u8: video.play?.hls?.link,
          m3u8ExpirationTime: video.play?.hls?.link_expiration_time,
          downloadLink: dlLink,
          downloadQuality: dlRes,
        }
      }
    }
  } catch (ex) {
    log.error(`Error getting video metadata:`);
    log.error(ex);
  }
  return;
}