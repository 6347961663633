// Frameworks
import React, { useContext, useEffect, useState } from 'react';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { Animated, Easing, LayoutChangeEvent, Modal, Platform, ScrollView, Text, View, ViewProps } from 'react-native';
import { MAX_WIDTH, useSafeAreaInsets } from '../../../business/layout/layout';
import NButton from '../NButton';
import Icon, { IconNode } from 'react-native-elements/dist/icons/Icon';
import { Palette } from '../../../Theme';

const PADDING_BOTTOM = 100;

function SlideUpView2(props: { show: boolean, delay?: number, title?: string, onDone: () => Promise<void>, doneButtonIcon?: IconNode, onLayout?: (event: LayoutChangeEvent) => void, children: Element[] | Element } & ViewProps): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { width, height } = commonContext.dimensions;
  const { top } = useSafeAreaInsets();
  const [shouldOpen, setShouldOpen] = useState(props.show);
  const [waiting, setWaiting] = useState(false);
  const [anim] = useState(new Animated.Value(0));
  const [viewHeight, setViewHeight] = useState(0);
  const [fullyOpen, setFullyOpen] = useState(false);
  const [titleHeight, setTitleHeight] = useState(0);
  const [totalHeight, setTotalHeight] = useState(0);

  useEffect(() => {
    if (props.onLayout) {
      props.onLayout({ nativeEvent: { layout: { height: totalHeight - titleHeight - PADDING_BOTTOM - 20, width: width } } });
    }
  }, [totalHeight, titleHeight]);

  useEffect(() => {
    setViewHeight(height * 0.8);
  }, [height, top]);

  useEffect(() => {
    if (props.show) {
      // open it
      setShouldOpen(true);

      setTimeout(() => {
        Animated.timing(
          anim,
          {
            toValue: 1,
            duration: 400,
            useNativeDriver: false,
            easing: Easing.ease,
          }
        ).start((result) => {
          setFullyOpen(true);
        });
      }, props.delay ?? 0);

    } else {
      // close it

      setFullyOpen(false);
      Animated.timing(
        anim,
        {
          toValue: 0,
          duration: 400,
          useNativeDriver: false,
          easing: Easing.ease,
        }
      ).start(() => {
        setShouldOpen(false);
      });

    }

    return () => { return; }
  }, [props.show]);

  const loading = waiting;

  const buttonPress = async () => {
    setWaiting(true);
    await props.onDone();
    setWaiting(false);
  }

  return (
    <Modal
      visible={shouldOpen}
      animationType={'fade'}
      onRequestClose={buttonPress}
      transparent
    >
      <View
        style={{
          height: '100%',
          width: '100%',
          backgroundColor: 'black',
          opacity: 0.8,
          position: 'absolute',
        }}
      >
      </View>
      <Animated.View
        style={[
          {
            display: shouldOpen ? 'flex' : 'none',
            position: 'absolute',
            transform: [
              // from HEIGHT to HEIGHT - VIEWHEIGHT
              { translateY: Animated.subtract(height, Animated.multiply(height, anim)) },
            ],
            bottom: 0,
            overflow: 'hidden',
            width: width,
            maxWidth: MAX_WIDTH,
            alignSelf: 'center',
            height: viewHeight,
            zIndex: 15,
            ...themeFromContext.slideUp.style,
          },
          props.style,
        ]}
        onLayout={(event) => {
          const { height } = event.nativeEvent.layout;
          setTotalHeight(height);
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            //paddingRight: 8,
            //paddingTop: 8,
            zIndex: 100,
          }}
          onLayout={(event) => {
            const { height } = event.nativeEvent.layout;
            setTitleHeight(height);
          }}
        >
          <Text
            style={{
              fontSize: 24,
              fontWeight: '700',
              color: Palette.plum,
              flex: 1,
              marginRight: 6,
            }}
            numberOfLines={1}
          >
            {props.title ?? ' '}
          </Text>
          <NButton
            buttonStyle={{
              borderWidth: 0,
            }}
            style={{
              borderWidth: 0,
            }}
            t='brightLink'
            onPress={buttonPress}
            hitSlop={{ top: 10, bottom: 10, left: 10, right: 10 }}
            icon={
              <Icon
                name='close-circle'
                type='ionicon'
                color={'#313067'}
                size={40}
              />
            }
            testID={`slide-up-modal-close-button`}
          />
        </View>
        <ScrollView
          style={{
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: PADDING_BOTTOM,
          }}
          showsVerticalScrollIndicator={Platform.OS == 'web'}
        >
          {props.children}
        </ScrollView>
      </Animated.View>
    </Modal>
  );
}
export default SlideUpView2;