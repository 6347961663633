import React, { useContext } from 'react';
import { View, StyleSheet, Text, Image } from 'react-native';
import { normalizeHeight } from '../../../business/layout/responseSize';
import CommonContext from '../../../CommonContext';
import { CommonContextType } from '../../../types/CommonContextType';
import { IWizardConfig } from '../../../types/Steps';


function StepHeader(props: { config: IWizardConfig, currentStep: number }): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { height } = commonContext.dimensions;

  const styles = StyleSheet.create({
    container: {
      flexDirection: 'column',
      alignContent: 'center',
      width: '100%',
      paddingVertical: 30,
      //height: '10%'
    }
  });



  return (
    <>
      {
        props.config.steps.length > 0 &&
        <View style={styles.container}>
          <View
            style={{
              width: '100%',//normalizeHeight(343, height),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              //height: normalizeHeight(100, height),
              //marginTop: normalizeHeight(20, height),
              marginBottom: normalizeHeight(20, height),
            }}
          >
            {props.config.steps[props.currentStep - 1].image}
          </View>
          {/* <Image
            source={props.config.steps[props.currentStep - 1].image}
            resizeMode='contain'
            style={{
              width: '100%',//normalizeHeight(343, height),
              height: normalizeHeight(100, height),
              //marginTop: normalizeHeight(20, height),
              marginBottom: normalizeHeight(20, height),
            }}
          /> */}
          <Text
            style={{
              alignSelf: 'center',
              textAlign: 'center',
              ...themeFromContext.textVariants.wizardHeader,
            }}
          >{props.config.steps[props.currentStep - 1].title}</Text>
        </View>
      }
    </>
  );
}

export default StepHeader;

