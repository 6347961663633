import log from '../logging/logger';
import { API } from 'aws-amplify';
import awsConfig from '../../aws-exports';
import { Community } from '../../API';
API.configure(awsConfig);

const API_NAME = `novellaREST`;

export async function getCommunityPreview(communityID: string): Promise<Community|undefined> {
  const apiName = API_NAME;
  const path = `/communityPreview`;
  const init = {
    queryStringParameters: { communityId: communityID},
    body: null // this is soooo important for a GET request...
  }

  try {
    const community: Community = await API.get(apiName, path, init);

    log.info(`GOT COMMUNITY PREVIEW RESPONSE:`);
    log.info(community);
    return community;
  } catch (ex) {
    log.error(`ERROR TRYING TO GET COMMUNITY PREVIEW:`)
    log.error(ex);
  }
}