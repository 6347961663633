import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react';
import { LayoutChangeEvent, Pressable, View } from 'react-native';
import PQTitle from './PQTitle';
import PQButtons from './PQButtons';
import { normalizeHeight } from '../../../business/layout/responseSize';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { Icon } from 'react-native-elements';
import SlideUpView2 from '../Modals/SlideUpView2';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';

function PQContent(props: { image?: React.ReactElement, title: string, validate?: () => number, back?: () => void, next?: () => Promise<void>, nextType?: "next" | "submit" | "finish", onLayout?: (event: LayoutChangeEvent) => void, infoChildren?: ReactElement | ReactElement[], children?: ReactElement | ReactElement[] }): React.ReactElement {
    const commonContext = useContext<CommonContextType>(CommonContext);
    const { height } = commonContext.dimensions;
    const [showInfoModal, setShowInfoModal] = useState(false);
    const scrollViewRef = useRef<JSX.Element>();

    const [totalWidth, setTotalWidth] = useState(0);
    const [totalHeight, setTotalHeight] = useState(0);
    const [titleHeight, setTitleHeight] = useState(0);
    const [buttonsHeight, setButtonsHeight] = useState(0);

    const onLayoutTotal = (event: LayoutChangeEvent) => {
        setTotalHeight(event.nativeEvent.layout.height);
    }

    const onLayoutTitle = (event: LayoutChangeEvent) => {
        setTitleHeight(event.nativeEvent.layout.height);
    }

    const onLayoutButtons = (event: LayoutChangeEvent) => {
        setButtonsHeight(event.nativeEvent.layout.height);
    }

    useEffect(() => {
      if (props.onLayout) {
        props.onLayout({nativeEvent: {layout: {height: totalHeight - titleHeight - buttonsHeight, width: totalWidth}}});
      }
    
    }, [totalWidth, totalHeight, titleHeight, buttonsHeight]);

    return (
        <View style={{display: 'flex', flex: 1, width: '100%',}} onLayout={onLayoutTotal}>
            <View
                style={{
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: props.infoChildren || props.image ? 10 : 0,
                    width: '100%',
                }}
            >
                {
                    props.infoChildren &&
                    <>
                        <Pressable
                            style={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                alignSelf: 'flex-end',
                                zIndex: 100,
                                // width: normalizeHeight(20, height),
                                // height: normalizeHeight(20, height),
                            }}
                            onPress={() => setShowInfoModal(true)}
                        >
                            <Icon
                                name='question-circle-o'
                                type='font-awesome'
                                color={'#313067'}
                                size={normalizeHeight(32, height)}
                            />
                            {/* <CircledI color={Palette.purple} width={normalizeHeight(20, height)} height={normalizeHeight(20, height)}  /> */}
                            {/* <Image
                                source={require('../../assets/circled-i.svg')}
                                defaultSource={require('../../assets/circled-i.svg')}
                                resizeMethod='scale'
                                resizeMode='contain'
                                style={{
                                    width: normalizeHeight(20, height),
                                    height: normalizeHeight(20, height),
                                    //marginTop: normalizeHeight(20, height),
                                    marginBottom: 0,
                                    tintColor: Palette.purple,
                                }}
                                testID={`interview-screen-no-video-request-image`}
                            /> */}
                        </Pressable>
                        <SlideUpView2
                            show={showInfoModal}
                            onDone={async () => setShowInfoModal(false)}
                            doneButtonIcon={
                                <Icon
                                    name='times-circle'
                                    type='font-awesome'
                                    color={'#313067'}
                                    size={30}
                                />
                            }
                        >
                            { props.infoChildren }
                        </SlideUpView2>
                    </>
                }
                {
                    props.image
                }
            </View>
            <PQTitle
                text={props.title}
                onLayout={onLayoutTitle}
            />
            <KeyboardAwareScrollView
                innerRef={(ref) => { scrollViewRef.current = ref; }}
                style={{
                    flex: 1,
                    display: 'flex',
                    width: '100%',
                    //height: '100%',
                }}
                contentContainerStyle={{
                    //flex: 1,
                    display: 'flex',
                    width: '100%',
                    //height: '100%',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                }}
                showsVerticalScrollIndicator
                //enableResetScrollToCoords={false}
                //keyboardDismissMode='on-drag'
                extraHeight={0}
            >
                {props.children}
            </KeyboardAwareScrollView>
            <PQButtons
                back={props.back}
                next={props.next}
                nextType={props.nextType}
                validate={props.validate}
                react={(n) => scrollViewRef.current?.scrollTo({ y: n, animated: true })}
                onLayout={onLayoutButtons}
            />
        </View>
    );
}

export default PQContent;