import React, { useContext } from 'react';
import { DrawerItem } from '@react-navigation/drawer';
import { Icon } from 'react-native-elements';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { Palette } from '../../../Theme';

function NDrawerItem(props: {label: string, focused: boolean, onPress: () => void, iconName: string, iconType?: string, testID?: string}): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { label, focused, onPress, iconName, iconType, testID } = props;

  return (
    <DrawerItem
      style={{
        borderRadius: 0,
        margin: 0,
        paddingHorizontal: 10,
        paddingVertical: 5,
      }}
      activeBackgroundColor={Palette.lightteal}
      activeTintColor={Palette.plum}
      inactiveTintColor={Palette.white}
      inactiveBackgroundColor={Palette.plum}
      labelStyle={{...themeFromContext.textVariants.body, fontWeight: 'bold'}}
      label={label}
      focused={focused}
      onPress={onPress}
      icon={(props) => (
        <Icon
          name={iconName}
          type={iconType ?? 'material-community'}
          color={props.focused ? Palette.plum : Palette.white}
          testID={testID}
        />
      )}
    />
  );
}

export default NDrawerItem;