// Frameworks
import React, { useContext, useEffect, useState } from 'react';
import { View } from 'react-native';

// Theme
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { StepProps } from '../../../types/Steps';
import RelatedUserPicker from '../../components/RelatedUserPicker';
import NButton from '../../components/NButton';
import { Text } from 'react-native-elements';
import { RelatedUserData } from '../../../business/user/userHelper';
import { SubjectList } from '../../../business/user/subjectList';

type NewCommunityChooseMembersProps = StepProps<RelatedUserData[]> & {
  relatedUsers: RelatedUserData[] | undefined
  subjectList: SubjectList | undefined
  max: number
};

function NewCommunityChooseMembers(props: NewCommunityChooseMembersProps): React.ReactElement {
  const myTurn = props.step === props.currentStep
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  //const [chosenUsers, setChosenUsers] = useState<RelatedUserData[]>([]);
  const [missingSubjectsError, setMissingSubjectsError] = useState('');

  useEffect(() => {
    // if (props.inputPayload) {
    //   setChosenUsers(props.inputPayload);
    // } else {
    //   setChosenUsers([]);
    // }

    if (!props.subjectList || !props.subjectList.subjects || props.subjectList.subjects.length === 0) {
      setMissingSubjectsError(`Oops! You can't finish this step with choosing who the Novella is about. Please go back.`);
    } else {
      setMissingSubjectsError('');
    }
  }, [props.subjectList])

  const finish = () => {
    props.onFinish();
  }

  const selectionChanged = (selectedUsers: RelatedUserData[]) => {
    //setChosenUsers(selectedUsers);
    props.onPayloadChange(selectedUsers);
  }

  return (
    <>
      {
        myTurn &&
        <View
          // style={{
          //   height: '100%',
          // }}
        >
         
         <Text
            style={{
              marginBottom: 10,
              textAlign: 'center',
              ...themeFromContext.textVariants.wizardBody
            }}
          >
            {missingSubjectsError.length > 0 ? missingSubjectsError : `Who else might want to know ${props.subjectList?.text()} better? You can invite up to ${props.max} more people.\n\nNote: This step is completely optional, and you can always invite people later.`}
          </Text>
          {
            props.inputPayload && props.inputPayload.length > props.max &&
            <View>
              <Text
                style={{
                  ...themeFromContext.textVariants.detail,
                  color: themeFromContext.colors.failure
                }}
              >
                {`Please limit your selection to ${props.max} people`}
              </Text>
            </View>
          }

          <RelatedUserPicker fullList={props.relatedUsers} alreadyPicked={props.inputPayload} onSelectionChanged={selectionChanged} />

          <NButton
            titleStyle={{
              fontSize: 14,
              fontWeight: '700',
            }}
            buttonStyle={{
              marginVertical: 10,
            }}
            style={{
              alignSelf: 'center',
            }}
            title={`Next step: Wrap-up!`}
            //disabled={!props.inputPayload || props.inputPayload.length === 0}
            t="primary"
            onPress={finish}
            testID='create-community-choose-members-next-button'
          />

        </View>
      }
    </>
  );
}
export default NewCommunityChooseMembers;