/* eslint-disable no-case-declarations */
import { Community } from "../../API";
import { Dictionary } from "../../types/data/Dictionary";

export function myCommuntiesReducer(state: Dictionary<Community>, action: { type: "set" | "add" | "update" | "remove", payload: any}): Dictionary<Community> {
  switch (action.type) {
    case "set":
      return action.payload;
    case "add":
      const newState = { ...state };
      newState[action.payload.id] = action.payload;
      return newState;
    case "remove":
      const newState2: Dictionary<Community> = {};
      Object.entries(state).forEach(e => {
        if (action.payload.id != e[0]) {
          newState2[e[0]] = e[1];
          }
      });
      return newState2;
    case "update":
      const newState3 = { ...state };
      newState3[action.payload.id] = action.payload;
      return newState3;
    default:
      return { ...state };
  }
}