/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

/*

THIS IS COPIED MUST BE MANUALLY COPIED TO: amplify/backend/function/CommunityQuestionAnswerCommentCommandStreamListener/src/index.js

*/
export function getPowers(loggedInUserRole, isOriginalAuthor) {
  let powers = {
    canSee: false,
    canEditText: false,
    canDelete: false,
  };

  const FULL_POWER = {
    canSee: true,
    canDelete: true,
    canEditText: true,
  }

  switch (loggedInUserRole) {
    case "ADMIN":
      powers = FULL_POWER;
      break;
    case "ORGANIZER":
    case "SUBJECT":
      if (isOriginalAuthor) {
        powers = FULL_POWER;
      } else {
        powers = {
          canSee: true,
          canEditText: false,
          canDelete: true,
        }
      }
      break;
    case "MEMBER":
      if (isOriginalAuthor) {
        powers = FULL_POWER;
      } else {
        powers = {
          canSee: true,
          canEditText: false,
          canDelete: false,
        };
      }
      break;
    default:
      // default powers == none
      break;
  }

  return powers;
}
