// Frameworks
import React, { Dispatch, useContext, useEffect, useReducer, useRef, useState } from 'react';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { ActivityIndicator, Pressable, StyleSheet, Text, View } from 'react-native';
import { AnswerModel } from '../../business/upload/AnswerModelManager';
import { Palette } from '../../Theme';
import NProgressBar from './NProgressBar';
import { AnswerUploadManager } from '../../business/upload/AnswerUploadManager';
import IonIcon from 'react-native-vector-icons/Ionicons';
import i18n from 'i18n-js';
import { TUSUpload, UploadStatus } from '../../business/upload/TUSUpload';
import { getRelativeTimeLabel } from '../../business/date/dateFormatter';
import { Icon } from 'react-native-elements';
import { ButtonMenu } from './ButtonMenu';
import { Menu } from 'react-native-paper';
import { IUploadListener, UploadListenerLibrary } from '../../business/upload/UploadListenerLibrary';


function uploadItemReducer(state: AnswerModel[] | undefined, action: { type: "set", payload: AnswerModel[] | undefined }): AnswerModel[] | undefined {
  switch (action.type) {
    case "set":
      //console.log(`CALLED DISPATCH SUCCESSFULLY!!!! WTH??????`);
      return action.payload;
    default:
      return state ? { ...state } : state;
  }
}


class HackyThing implements IUploadListener<TUSUpload> {
  name: string;
  private uploadItem: AnswerModel;
  private setUploadItem: Dispatch<{ type: "set"; payload: AnswerModel[] | undefined; }>;

  constructor(uploadItem: AnswerModel, setUploadItem: Dispatch<{ type: "set"; payload: AnswerModel[] | undefined; }>) {
    this.uploadItem = uploadItem;
    this.setUploadItem = setUploadItem;
    this.name = `UploadItem${uploadItem.id}`;
  }

  async listen(tusUpload: TUSUpload): Promise<void> {
    if (tusUpload.id == this.uploadItem.id) {
      this.uploadItem.tusUpload = tusUpload;
      this.setUploadItem({ type: 'set', payload: [this.uploadItem] });
      console.log(this.setUploadItem);
      console.log(`setting upload item: ${this.uploadItem.title}`);
    }
  }
}

type UploadLineItemSmallProps = {
  id: string,
  isFocus: boolean,
  showQuestion?: boolean,
  hideStatus?: boolean,
  onRemove: (id: string) => void,
}

function UploadLineItemSmall(props: UploadLineItemSmallProps): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { id, showQuestion, hideStatus, isFocus, onRemove } = props;
  const [status, setStatus] = useState('');
  //const [uploadItem, setUploadItem] = useState<AnswerModel|undefined>();

  const [uploadItems, dispatchUploadItemUpdate] = useReducer(uploadItemReducer, undefined);
  const [uploadItem, setUploadItem] = useState<AnswerModel>();
  const [canceling, setCanceling] = useState(false);

  //const [ht, setHt] = useState<HackyThing>();
  //const hackyThing = useRef<HackyThing>();
  //hackyThing.current = ht;

  //const [pendingUploads, setPendingUploads] = useState<AnswerModel[]>([]);
  const [ht] = useState<HackyThing>();
  const hackyThing = useRef<HackyThing>();
  hackyThing.current = ht;

  useEffect(() => {
    const upload = AnswerUploadManager.get(id);
    if (upload && isFocus) {
      hackyThing.current = new HackyThing(upload, dispatchUploadItemUpdate);
      UploadListenerLibrary.addListener(hackyThing.current);
      dispatchUploadItemUpdate({ type: "set", payload: [upload] });
    }
  }, [id, hackyThing.current, isFocus]);


  useEffect(() => {
    if (uploadItems) {
      const s = getStatusString(uploadItems[0].tusUpload.status);
      setStatus(s);
    }
  }, [uploadItems])

  useEffect(() => {
    if (uploadItems) {
      console.log(`upload item change registered on page: ${uploadItems[0].title} - ${uploadItems[0].tusUpload.status}`);
      setUploadItem(uploadItems[0]);
    }
  }, [uploadItems]);


  function getStatusString(s?: UploadStatus): string {
    switch (s) {
      case UploadStatus.CANCELED:
        return i18n.t(`VideoUploadCard_CanceledState_Text`);
      case UploadStatus.COMPLETED:
        return i18n.t(`VideoUploadCard_CompletedState_Text`);
      case UploadStatus.FAILED:
        return i18n.t(`VideoUploadCard_FailedState_Text`);
      case UploadStatus.IN_PROGRESS:
        return i18n.t(`VideoUploadCard_InProgressState_Text`);
      case UploadStatus.PAUSED:
        return i18n.t(`VideoUploadCard_PausedState_Text`);
      case UploadStatus.READY:
        return i18n.t(`VideoUploadCard_ReadyState_Text`);
      default:
        return '';
    }
  }

  //console.log(`RENDERING LINE ITEM: ${uploadItem.title}`);

  return (
    <>
      {
        uploadItem &&
        <View
          key={uploadItem?.id}
          style={[
            styles.informationCard,
            {
              padding: 0,
              borderWidth: 0,
            }
          ]}
        >
          <View
            key={uploadItem?.id}
            style={[
              styles.informationCard,
              {
                marginVertical: 0,
              },
              styles.row,
              uploadItem.tusUpload.status == UploadStatus.FAILED ? { borderColor: Palette.red, backgroundColor: 'rgba(255,180,180, .8)' } : {},
              uploadItem.tusUpload.status == UploadStatus.IN_PROGRESS ? { borderColor: Palette.yellow, backgroundColor: Palette.lightyellow.concat('77') } : {},
              uploadItem.tusUpload.status == UploadStatus.COMPLETED ? { borderColor: Palette.teal, backgroundColor: Palette.lightteal.concat('77') } : {},
            ]}
          >
            <View
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginRight: 2,
                marginLeft: -4,
              }}
            >
              {
                AnswerUploadManager.canRemove(uploadItem?.id) &&
                <Pressable
                  style={({ pressed }) => {
                    return {
                      ...styles.pressable,
                      opacity: pressed ? 0.3 : 1,
                    }
                  }}
                  hitSlop={20}
                  onPress={() => {
                    onRemove(uploadItem?.id);
                    AnswerUploadManager.remove(uploadItem?.id);
                    setUploadItem(undefined);
                  }}
                >
                  <Icon
                    name='times-circle'
                    type='font-awesome'
                    color={'#313067'}
                    size={40}
                  />
                  {/* <IonIcon name="close-circle-outline" color={themeFromContext.colors.danger} size={40} /> */}
                </Pressable>
              }
              {
                AnswerUploadManager.canPause(uploadItem?.id) &&
                <Pressable
                  style={({ pressed }) => {
                    return {
                      ...styles.pressable,
                      opacity: pressed ? 0.3 : 1,
                    }
                  }}
                  hitSlop={20}
                  onPress={() => {
                    AnswerUploadManager.pause(uploadItem?.id);
                  }}
                >
                  <Icon
                    name='pause-circle'
                    type='ionicon'
                    color={'#313067'}
                    size={40}
                  />
                  {/* <IonIcon name="pause-circle-outline" color={themeFromContext.colors.foreground} size={40} /> */}
                </Pressable>
              }
              {
                AnswerUploadManager.canRestart(uploadItem?.id) &&
                <Pressable
                  style={({ pressed }) => {
                    return {
                      ...styles.pressable,
                      opacity: pressed ? 0.3 : 1,
                    }
                  }}
                  hitSlop={20}
                  onPress={() => {
                    //setCanceling(true);
                    AnswerUploadManager.restart(uploadItem?.id);
                    //setCanceling(false);
                  }}
                >
                  <Icon
                    name='refresh-circle'
                    type='ionicon'
                    color={'#313067'}
                    size={40}
                  />
                  {/* <IonIcon name="refresh-circle-outline" color={themeFromContext.colors.primary} size={40} /> */}
                </Pressable>
              }
            </View>
            <View
              style={{
                display: 'flex',
                flex: 1,
                width: '100%',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                borderLeftWidth: 1,
                borderLeftColor: Palette.grey,
                paddingLeft: 10,
              }}
            >
              <View style={[styles.row, { alignItems: 'flex-start' }]}>
                <Text style={[styles.importantText, { flex: 1, }]}>
                  {getRelativeTimeLabel(uploadItem?.created)}
                </Text>
                {
                  AnswerUploadManager.canCancel(uploadItem?.id) &&
                  <ButtonMenu cancelButtonName={i18n.t(`VideoUploadCard_MenuCancelButtonName_Text`)} >
                    <Menu.Item
                      icon={() => (
                        canceling ? <ActivityIndicator /> :
                          <Icon
                            size={themeFromContext.textVariants.header.fontSize}
                            name='trash-alt'
                            type='font-awesome-5'
                            solid
                            color={themeFromContext.colors.background}
                            style={{
                              alignItems: 'flex-start',
                            }}
                          />
                      )}
                      title={uploadItem?.source == 'library' ? i18n.t(`VideoUploadCard_CancelUpload_ButtonText`) : i18n.t(`VideoUploadCard_DeleteVideo_ButtonText`)}
                      titleStyle={{
                        fontFamily: themeFromContext.textVariants.body.fontFamily,
                        fontSize: themeFromContext.textVariants.body.fontSize,
                        color: themeFromContext.colors.background
                      }}
                      style={{
                        backgroundColor: themeFromContext.colors.danger,
                        borderTopLeftRadius: 25,
                        borderTopRightRadius: 25,
                        paddingHorizontal: 20,
                      }}
                      onPress={async () => {
                        setCanceling(true);
                        await AnswerUploadManager.cancel(uploadItem?.id)
                        setCanceling(false);
                      }}
                      testID={`upload-line-item-${uploadItem?.id}-cancel`}
                    />
                  </ButtonMenu>
                }
              </View>
              {
                showQuestion &&
                <View style={styles.row}>
                  {
                    uploadItem?.questionText != undefined && uploadItem?.questionText.length > 0 &&
                    <Text style={{ fontSize: 18, fontStyle: 'italic', fontWeight: '300', marginBottom: 8 }} numberOfLines={3}>
                      {i18n.t('VideoUploadCard_Question_Text', { 0: uploadItem?.questionText })}
                    </Text>
                  }
                </View>
              }
              <View style={styles.row}>
                {
                  uploadItem?.title != undefined && uploadItem?.title.length > 0 &&
                  <Text numberOfLines={1}>
                    {i18n.t('VideoUploadCard_Title_Text', { 0: uploadItem?.title })}
                  </Text>
                }
              </View>
              <View
                style={{
                  flex: 1,
                  width: '100%',
                  justifyContent: 'center',
                  minHeight: 30,
                  maxHeight: 40,
                  paddingTop: 4,
                }}
              >
                <NProgressBar completed={uploadItem?.tusUpload.uploadedBytes} total={uploadItem?.tusUpload.totalBytes} />
                <View style={styles.row}>
                  <Text style={styles.importantText}>{`${Math.round(uploadItem?.tusUpload.uploadedBytes / 1024 / 1024)} / ${Math.round(uploadItem?.tusUpload.totalBytes / 1024 / 1024)} MB (${(Math.round(uploadItem.tusUpload.uploadedBytes / uploadItem.tusUpload.totalBytes * 100)).toFixed(2)}%)`}</Text>
                  {
                    !hideStatus &&
                    <Text style={styles.importantText}>{status}</Text>
                  }
                </View>

              </View>
            </View>
          </View>
        </View>
      }
    </>
  );
}

export default UploadLineItemSmall;


const styles = StyleSheet.create({
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    //borderWidth: 1,
    //borderColor: Palette.grey,
    //borderRadius: 20,
    //margin: 2,
    //padding: 10,
    flex: 1,
  },
  importantText: {
    //fontFamily: 'Arial',
    //fontSize: 14,
    fontWeight: '300',
    //textAlign: 'center',
    fontStyle: 'italic',
    color: Palette.textgrey
    //marginTop: 10,
  },
  informationCard: {
    display: 'flex',
    backgroundColor: Palette.white,
    borderColor: Palette.grey,
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
    marginVertical: 2,
    overflow: 'hidden',
    flex: 1,
  },
  pressable: {
    height: 50,
    width: 50,
    justifyContent: 'center',
    alignItems: 'center',
  },
});