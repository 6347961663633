import React, { useContext, useEffect, useRef, useState } from 'react';
import { Alert, LayoutChangeEvent, Linking, Platform, Pressable, Share, Text, TextInput, View } from 'react-native';
import CommonContext from '../../../CommonContext';
import { CommonContextType } from '../../../types/CommonContextType';
import { RootStackParamList } from '../../../types/Navigation';
import { DrawerScreenProps } from '@react-navigation/drawer';
import * as Clipboard from 'expo-clipboard';
import i18n from 'i18n-js';

import NButton from '../../components/NButton';
import PQPage from '../../components/OnePersonOneQuestion/PQPage';
import log from '../../../business/logging/logger';
import LocalForageWrapper from '../../../business/storage/localForageWrapper';
import { Palette } from '../../../Theme';
import { normalizeHeight, normalizeWidth } from '../../../business/layout/responseSize';
import NText from '../../components/NText';
import NTextInput from '../../components/NTextInput';
import { RecommendationFromFile } from '../../../contracts/IQuestionRecommendationService';
import { isValidEmail, refreshAuthToken } from '../../../business/user/userHelper';
import StepHeader from '../../components/StepHeader';
import Message from '../../../../assets/svg/illustrations/Message';
import Question from '../../../../assets/svg/illustrations/Question';
import Envelope from '../../../../assets/svg/illustrations/Envelope';
import { Icon } from 'react-native-elements';
import ScrollableViewScreen from '../../components/Layouts/ScrollableViewScreen';
import { useIsFocused } from '@react-navigation/native';
import { useSafeAreaInsets } from '../../../business/layout/layout';
import { createCommunityUnauthenticated, CreateCommunityUnauthenticatedResponse } from '../../../business/public/createCommunityUnauthenticated';
import { CommunityServiceError } from '../../../contracts/ICommunityService';
import { getRootDomain } from '../../../business/env/currentEnv';
import NToast from '../../components/NToast';
import { isMobileWeb } from '../../../business/env/detectMobile';
import VimeoPlayer from '../../components/VimeoPlayer/VimeoPlayer';
import Family from '../../../../assets/svg/illustrations/Family';
import { getBranchIODDL } from '../../../business/sharing/fastAnswerLinks';

type PQState = {
    // question text
    q: string,
    //category:
    c: string,
    // custom question
    cq: string,
    // use custom question?
    ucq: boolean
    // spotlight's first name
    sfn: string,
    // spotlight's last name
    sln: string,
    // user's first name
    fn: string,
    // user's last name
    ln: string,
    // user's email
    e: string,
    // time
    t: string,
    // community question id, if already created
    cqid?: string,
}

const newState = (): PQState => {
    return {
        q: '',
        c: '',
        cq: '',
        ucq: false,
        sfn: '',
        sln: '',
        fn: '',
        ln: '',
        e: '',
        t: new Date(Date.now()).toISOString(),
    };
}

const FALLBACK_VIDEO = require('../../../../assets/introducing_novella.mp4');
const CACHE_KEY = 'f1p1qs';
const QUESTIONS_PER_PAGE = 3;
const LOCAL_STATE_LIFETIME_MS = 2 * 24 * 60 * 60 * 1000; // 2 days
// for testing only const LOCAL_STATE_LIFETIME_MS = 10 * 1000; // 10 seconds

export type MyProps = DrawerScreenProps<RootStackParamList, 'Go Fast'>;

function FastOnePersonOneQuestion(props: MyProps): React.ReactElement {
    const commonContext = useContext<CommonContextType>(CommonContext);
    // very special logic: if we're logged in, we don't want to use the navigation prop
    const [navigation] = useState(commonContext.loggedInUser ? null : props.navigation);
    const { theme, dimensions, services } = commonContext;
    const { width, height } = dimensions;
    const [step, setStep] = useState(-1);
    const [isSaving, setIsSaving] = useState(false);
    const [showPreview, setShowPreview] = useState(false);
    const [expressedInterest, setExpressedInterest] = useState(false);
    const [showSecondSpotlight, setShowSecondSpotlight] = useState(false);
    const [newCommunityId, setNewCommunityId] = useState<string | undefined>();
    const isFocused = useIsFocused();
    const [open, setOpen] = useState(false);
    const [items, setItems] = useState<{ label: string, value: string }[]>([]);
    const { top, bottom } = useSafeAreaInsets();
    const [reset, setReset] = useState(false);
    const [rootDomain] = useState(getRootDomain());
    const [showCopyPanel, setShowCopyPanel] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [shareMessage, setShareMessage] = useState('');
    const [videoHeight, setVideoHeight] = useState(0);
    const spotlightType = props.route.params?.type;
    const [recordUrl, setRecordUrl] = useState('');

    // question recommendation state
    const [totalQuestionOptions, setTotalQuestionOptions] = useState<RecommendationFromFile[]>([]);
    const [nextQuestionOptions, setNextQuestionOptions] = useState<RecommendationFromFile[]>([]);
    const [lastOptionIndex, setLastOptionIndex] = useState(QUESTIONS_PER_PAGE - 1);

    // fpq state params
    const [q, setQ] = useState('');
    const qRef = useRef<TextInput>(null);
    const [qt, setQt] = useState(0);
    const [qError, setQError] = useState('');
    const [c, setC] = useState('');
    const cRef = useRef<TextInput>(null);
    const [ct, setCt] = useState(0);
    const [cq, setCq] = useState('');
    const cqRef = useRef<TextInput>(null);
    const [cqt, setCqt] = useState(0);
    const [cqError, setCqError] = useState('');
    const [ucq, setUcq] = useState(false);
    const [n, setN] = useState(i18n.t('FastOnePersonOneQuestion_PersonalNote'));

    const [sfn, setSfn] = useState('');
    const sfnRef = useRef<TextInput>(null);
    const [sfnt, setSfnt] = useState(0);
    const [sfnError, setSfnError] = useState('');
    const [sln, setSln] = useState('');
    const slnRef = useRef<TextInput>(null);
    const [slnt, setSlnt] = useState(0);
    const [slnError, setSlnError] = useState('');

    const [fn, setFn] = useState(commonContext.loggedInUser?.firstName ?? '');
    const fnRef = useRef<TextInput>(null);
    const [fnt, setFnt] = useState(0);
    const [fnError, setFnError] = useState('');
    const [ln, setLn] = useState(commonContext.loggedInUser?.lastName ?? '');
    const lnRef = useRef<TextInput>(null);
    const [lnt, setLnt] = useState(0);
    const [lnError, setLnError] = useState('');
    const [e, setE] = useState(commonContext.loggedInUser?.email ?? '');
    const eRef = useRef<TextInput>(null);
    const [et, setEt] = useState(0);
    const [eError, setEError] = useState('');
    const [submitError, setSubmitError] = useState('');
    const [cqid, setCqid] = useState('')

    useEffect(() => {
      setReset(!reset);
      console.log('resetting');
    }, [spotlightType]);

    useEffect(() => {
        // const dditems = Object.entries(Relationship).map(x => { return { label: i18n.t(x[1]), value: x[0] }; });
        // setItems(dditems);
        let recs = services.questionRecommendationService.initialRecommendations.filter(r => r.includeIn1p1q);
        shuffleRecs(recs);

        // load from local forage state?
        let pqs: PQState;// = LocalForageWrapper.getItem(CACHE_KEY);
        // if the state is more than 2 day sold, throw it away
        if (pqs && pqs.t < new Date(Date.now() - LOCAL_STATE_LIFETIME_MS).toISOString()) {
            LocalForageWrapper.setItem(CACHE_KEY, newState());
            pqs = LocalForageWrapper.getItem(CACHE_KEY);
        }

        let goToStep = -1;
        if (pqs) {
            setQ(pqs.q ? pqs.q.trim() : '');
            setC(pqs.c ? pqs.c.trim() : '');
            setCq(pqs.cq ? pqs.cq.trim() : '');
            setUcq(pqs.ucq ?? false);
            setSfn(pqs.sfn ? pqs.sfn.trim() : '');
            setSln(pqs.sln ? pqs.sln.trim() : '');
            setFn(commonContext.loggedInUser?.firstName ?? pqs.fn ? pqs.fn.trim() : '');
            setLn(commonContext.loggedInUser?.lastName ?? pqs.ln ? pqs.ln.trim() : '');
            setE(commonContext.loggedInUser?.email ?? pqs.e);
            setCqid(pqs.cqid ?? '');

            // step 0: sfn, sln
            // step 1: q
            // step 2: cq, ucq
            // step 3: fn, ln, e
            // step 4: ???

            if (pqs.sfn.length == 0 || pqs.sln.length == 0) {
                log.info('go to step 0');
                goToStep = 0;
            } else if (!pqs.ucq && pqs.q.length == 0) {
                log.info('go to step 1');
                goToStep = 1;
            } else if (pqs.ucq && pqs.cq.length == 0) {
                log.info('go to step 2');
                goToStep = 2;
            } else if (pqs.fn.length == 0 || pqs.ln.length == 0 || pqs.e.length == 0 || !isValidEmail(pqs.e)) {
                log.info('go to step 3');
                goToStep = 3;
            } else {
                log.info('go to step 4');
                goToStep = 4; // ???
            }

            const selected = recs.findIndex(r => r.text == pqs.q);
            if (selected >= 0) {
                const thing = recs.splice(selected, 1);
                recs = [thing[0], ...recs];
            }
        } else {
            clearAllFields();
        }

        setStep(goToStep);
        setTotalQuestionOptions(recs);
        getOptions(recs, 0, QUESTIONS_PER_PAGE);
        setLastOptionIndex(QUESTIONS_PER_PAGE);
    }, [reset]);

    useEffect(() => {
        // analytics
        const pageName = getPageName(step);
        const readableState = getReadableState();
        commonContext.analytics?.viewPage(pageName, readableState);
    }, [step]);

    const getReadableState = () => {
        const s = {
            SelectedQuestionText: q,
            SelectedQuestionCategory: c,
            CustomQuestionText: cq,
            UsingCustomQuestion: ucq,
            CustomNoteText: n,
            SpotlightFirstName: sfn,
            SpotlightLastName: sln,
            UserFirstName: fn,
            UserLastName: ln,
            UserEmail: e,
        };

        if (cqid.length > 0) {
            s["CommunityQuestionId"] = cqid;
        }

        return s;
    }

    const getPageName = (step: number): string => {
        switch (step) {
            case -1:
                return 'Intro';
            case 0:
                return 'Choose someone';
            case 1:
                return 'Pick A Question';
            case 2:
                return 'Write Your Own Question';
            case 3:
                return 'Contact Info';
            case 4:
                return 'Send Your Question';
            case 5:
                return 'Finished';
            default:
                return 'Unknown';
        }
    }

    const validate0 = (): number => {
        let errorY = -1;
        setSfnError('');
        setSlnError('');
        if (sfn.trim().length == 0) {
            setSfnError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : sfnt;
        }
        if (sln.trim().length == 0) {
            setSlnError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : slnt;
        }

        // analytics
        if (errorY == -1) {
            const pageName = getPageName(0);
            const formData = {
                SpotlightFirstName: sfn.trim(),
                SpotlightLastName: sln.trim(),
            }
            commonContext.analytics?.submitFormData(pageName, formData);
        }

        return errorY;
    }

    const validate1 = (): number => {
        let errorY = -1;
        setUcq(false);
        setQError('');
        if (q.trim().length == 0) {
            setQError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : qt;
        }

        // analytics
        if (errorY == -1) {
            const pageName = getPageName(1);
            const formData = {
                UsingCustomQuestion: false,
                QuestionText: q.trim(),
                QuestionCategory: c,
            }
            commonContext.analytics?.submitFormData(pageName, formData);
        }

        return errorY;
    }

    const validate2 = (): number => {
        let errorY = -1;
        setCqError('');
        setUcq(true);
        if (cq.trim().length == 0) {
            setCqError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : cqt;
        }

        // analytics
        if (errorY == -1) {
            const pageName = getPageName(2);
            const formData = {
                UsingCustomQuestion: true,
                CustomQuestionText: cq.trim(),
            }
            commonContext.analytics?.submitFormData(pageName, formData);
        }

        return errorY;
    }

    const validate3 = (): number => {
        let errorY = -1;
        setFnError('');
        setLnError('');
        setEError('');

        if (fn == undefined || fn.trim().length == 0) {// first name
            setFnError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : fnt;
        }
        if (ln == undefined || ln.trim().length == 0) {// last name
            setLnError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : lnt;
        }
        if (e == undefined || e.length == 0) {// email
            setEError(i18n.t('OnePersonOneQuestion_RequiredField_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : et;
        } else if (!isValidEmail(e)) {
            setEError(i18n.t('OnePersonOneQuestion_InvalidEmail_ErrorMessage'));
            errorY = errorY >= 0 ? errorY : et;
        }

        // analytics
        if (errorY == -1) {
            const pageName = getPageName(3);
            const formData = {
                UserFirstName: fn.trim(),
                UserLastName: ln.trim(),
                UserEmail: e,
            }
            commonContext.analytics?.submitFormData(pageName, formData);
        }

        return errorY;
    }

    function clearAllFields() {
        setQ('');
        setC('');
        setCq('');
        setUcq(false);
        setN(i18n.t('FastOnePersonOneQuestion_PersonalNote'));
        setSfn('');
        setSln('');
        setFn(commonContext.loggedInUser?.firstName ?? '');
        setLn(commonContext.loggedInUser?.lastName ?? '');
        setE(commonContext.loggedInUser?.email ?? '');
        setCqid('');
        setShareMessage('');
        setShowCopyPanel(false);
    }

    function getOptions(options: RecommendationFromFile[], lastShownIndex: number, numberToAdd: number) {
        let newEnd = lastShownIndex + numberToAdd;
        if (newEnd > options.length) {
            newEnd = options.length;
        }

        const result = options.slice(0, newEnd);

        setNextQuestionOptions(result);
    }

    function getNextOptions() {
        getOptions(totalQuestionOptions, lastOptionIndex, QUESTIONS_PER_PAGE);
        setLastOptionIndex(lastOptionIndex + QUESTIONS_PER_PAGE);
        //setQ('');
    }

    const getFullState = () => {
        const fullState: PQState = {
            q: q.trim(),
            c: c,
            cq: cq.trim(),
            ucq: ucq,
            sfn: sfn.trim(),
            sln: sln.trim(),
            fn: fn.trim(),
            ln: ln.trim(),
            e: e,
            t: new Date(Date.now()).toISOString(),
        };
        if (cqid.length > 0) {
            fullState.cqid = cqid;
        }
        return fullState;
    }

    useEffect(() => {
        // update local forage state
        const fullState = getFullState();
        LocalForageWrapper.setItem(CACHE_KEY, fullState);
    }, [q, c, cq, ucq, n, sfn, sln, fn, ln, e, cqid]);

    function shuffleRecs(r: any[]) {
        for (let i = r.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [r[i], r[j]] = [r[j], r[i]];
        }
    }

    const createCommunityAndStuff = async (page: 4|5): Promise<CreateCommunityUnauthenticatedResponse | undefined> => {
        //console.error(`NEED TO IMPLEMENT SHARE`);
        log.info(JSON.stringify(getFullState(), null, 2));

        // analytics
        const pageName = getPageName(page);
        const formData = getReadableState();

        console.log('submitting form data', formData);
        commonContext.analytics?.submitFormData(pageName, formData);

        try {
            const response: CreateCommunityUnauthenticatedResponse | undefined = await createCommunityUnauthenticated(getFullState());
            log.info(JSON.stringify(response, null, 2));
            setNewCommunityId(response?.community.id);

            // we need to refresh the logged-in user's auth tokens to reflect new community membership
            if (commonContext.loggedInUser) {
                await refreshAuthToken();
            }

            return response;
        } catch (ex) {
            if (ex instanceof CommunityServiceError) {
                setSubmitError(ex.userMessage);
            }
            log.error(ex);
        }
    }

    const createAccount = () => {
        log.info('go to create account');
        props.navigation.navigate('Sign Up');
        setReset(!reset);
        // if (Platform.OS == 'web') {
        //     window.open('https://meetnovella.com', "_self");
        // } else {
        //     Linking.openURL('https://meetnovella.com');
        // }
    }

    const styles = {
        body: {
            //fontFamily: 'Arial',
            //display: 'flex',
            fontSize: 16,
            lineHeight: 22,
            fontWeight: '400',
            textAlign: 'left',
            color: 'black',
            textDecorationLine: 'none',
            width: '100%',
        },
        prompt: {
            //fontFamily: 'Arial',
            fontSize: 16,
            fontWeight: '400',
            textAlign: 'right',
            whiteSpace: 'nowrap',
            padding: 6,
            paddingLeft: 2,
        },
        link: {
            //fontFamily: 'Arial',
            fontSize: 16,
            lineHeight: 22,
            fontWeight: '700',
            textAlign: 'left',
            marginTop: 10,
            textDecorationLine: 'underline',
            whiteSpace: 'nowrap',
            color: theme.colors.primary,
            width: '100%',
        },
        important: {
            //fontFamily: 'Arial',
            fontSize: 14,
            fontWeight: '400',
            textAlign: 'center',
            fontStyle: 'italic',
            marginTop: 10,
        },
        subHeading: {
            //fontFamily: 'Arial',
            fontSize: 16,
            fontWeight: '700',
            color: Palette.purple,
            //lineHeight: 16,
            textAlign: 'center',
            marginTop: 20,
        },
        inputHeading: {
            //fontFamily: 'Arial',
            fontSize: 16,
            fontWeight: '700',
            textAlign: 'left',
            width: '100%',
            marginTop: 20,
            color: Palette.purple,
        },
        question: {
            //fontFamily: 'Arial',
            fontSize: 16,
            fontWeight: '300',
            width: '100%',
            textAlign: 'center',
            color: theme.colors.text,
        },
        errorMessage: {
            //fontFamily: 'Arial',
            fontSize: 12,
            fontWeight: '400',
            textAlign: 'left',
            width: '100%',
            margin: 5,
            color: theme.colors.danger,
        },
        row: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'space-evenly',
            alignItems: 'flex-start',
            //maxWidth: 600,
            paddingVertical: 12,
        },
        column: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            //maxWidth: 600,
            padding: 12,
        },
        columnShort: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            //height: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            //maxWidth: 600,
            padding: 12,
        },
        unselectedButton: {
            flex: 1,
            borderColor: Palette.transparent,
            borderWidth: 2,
            borderRadius: 12,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 10,
        },
        selectedButton: {
            borderColor: theme.colors.primary,
        },
        buttonText: {
            ...theme.textVariants.askQuestionHeader,
            color: theme.colors.text,
            marginTop: 6,
            textAlign: 'center',
        },
        label: {
            //...theme.textVariants.inputLabel,
            //fontFamily: 'Arial',
            fontSize: normalizeWidth(14, dimensions.width),
            fontWeight: '700',
            color: theme.colors.text,
        },
        unselectedQuestion: {
            //flex: 1,
            borderColor: Palette.lightgrey,
            borderWidth: 2,
            borderRadius: 12,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: 12,
            marginVertical: 4,
            //overflow: 'scroll',
            minHeight: 60,
            width: '100%',
        },
        selectedQuestion: {
            borderColor: Palette.teal,
            borderWidth: 2,
            backgroundColor: Palette.lightteal.concat('55'),
            fontWeight: 'bold',
        },
        infoText: {
            ...theme.textVariants.body,
            paddingHorizontal: 14,
        },
        infoHeader: {
            ...theme.textVariants.headerSecondTitle,
            textAlign: 'center',
        },
        infoRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            paddingHorizontal: 14,
            marginBottom: normalizeHeight(14, height),
            minHeight: normalizeHeight(60, height),
        },
        bulletRow: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
            marginBottom: 6,
        },
        bulletText: {
            fontFamily: 'Arial',
            fontSize: 16,
            fontWeight: '400',
            textAlign: 'left',
            flex: 1,
        },
        bulletNumber: {
            fontFamily: 'Arial',
            fontSize: 16,
            fontWeight: '400',
            textAlign: 'right',
            marginRight: 6,
            minWidth: 28,
        },
    }

    function onLayoutInput(e: LayoutChangeEvent, getTop: (y: number) => void, control?: React.RefObject<React.Component>): void {
        if (control && control.current != null && control.current.measure) {
            control.current.measure((vx, vy, width, height, px, py) => {
                log.info(`${vx} : ${vy} : ${width} : ${height} : ${px} : ${py}`);
                const top = py;
                getTop(top - 50);
            });
        }
    }

    const headerSteps = [
        //i18n.t(`OnePersonOneQuestion_Header_Step_Intro`),
        i18n.t(`FastOnePersonOneQuestion_Header_Step_Person`),
        i18n.t(`FastOnePersonOneQuestion_Header_Step_Question`),
        i18n.t(`FastOnePersonOneQuestion_Header_Step_ContactInfo`),
        i18n.t(`FastOnePersonOneQuestion_Header_Step_Send`)
    ];

    const onFinish = async () => {

        // start async stuff
        setIsSaving(true);
        
        let communityQuestionId;
        if (cqid.length > 0) {
            communityQuestionId = cqid;
        } else {
            // first we create the community etc
            const communityResponse = await createCommunityAndStuff(5);
            console.log(`community response from fast 1p1q 'self mode'`);
            if (communityResponse) {
                communityQuestionId = communityResponse.question.id;
                setCqid(communityQuestionId);
            }
        }
        if (communityQuestionId) {
            // now that we have the community question id, we can create the answer url and share it
            try {
                const urlRootWeb = `https://${rootDomain}/`;
                const urlRootNative = `com.novella.ios://`;
                const urlPath = `fa/${communityQuestionId}/`;

                // wait till we're in the app store
                //const { fn, q } = getFullState();
                //const urlToRecord = await getBranchIODDL(urlRoot, urlPath, q, fn);
                const urlToRecord = Platform.OS == 'web' ? `${urlRootWeb}${urlPath}` : `${urlRootNative}${urlPath}`;
                setRecordUrl(urlToRecord);
                console.log(`url to record: ${urlToRecord}`)
                //const urlToShare = `${urlRoot}${urlPath}`;
                setIsSaving(false);
            } catch (error: any) {
                console.log(`error sharing`);
                Alert.alert(error.message);
                setIsSaving(false);
            }
        }
    };

    const onShare = async () => {

        // start async stuff
        setIsSaving(true);
        
        let communityQuestionId;
        if (cqid.length > 0) {
            communityQuestionId = cqid;
        } else {
            // first we create the community etc
            const communityResponse = await createCommunityAndStuff(4);
            console.log(`community response from fast 1p1q`);
            if (communityResponse) {
                communityQuestionId = communityResponse.question.id;
                setCqid(communityQuestionId);
            }
        }
        if (communityQuestionId) {
            // now that we have the community question id, we can create the answer url and share it
            try {
                const message = n;
                const title = i18n.t('FastOnePersonOneQuestion_Share_Subject');
                const urlRoot = `https://${rootDomain}`; 
                const urlPath = `/fa/${communityQuestionId}/`;

                // wait till we're in the app store
                const { fn, q } = getFullState();
                const urlToShare = await getBranchIODDL(urlRoot, urlPath, q, fn);
                console.log(`url to share: ${urlToShare}`)
                //const urlToShare = `${urlRoot}${urlPath}`;

                if (Platform.OS === 'web') {
                    console.warn(`web share... `);
                    // feature detecting navigator.share()
                    // if not supported or not on mobile web, show the slide up
                    if (!navigator.share || !isMobileWeb()) {
                        console.log(`web share on desktop... `);
                        setShareMessage(`${message}\n${urlToShare}`);
                        setShowCopyPanel(true);
                        setIsSaving(false);
                        return;
                    }
                }

                const content = {
                    message: `${message}\n${urlToShare}`, // iOS + Android
                    url: urlToShare, // iOS only
                    title: title // Android only
                };
                console.warn(`share content: ${JSON.stringify(content, null, 2)}`);
                const options = {
                    dialogTitle: 'Share via',
                    subject: title, // iOS only
                };
                console.warn(`share options: ${JSON.stringify(options, null, 2)}`);
                if (Platform.OS != 'web') {
                    console.log(`share on native... `);
                    const result = await Share.share(content, options);
                    console.log(JSON.stringify(result, null, 2));
                    if (result.action === Share.sharedAction) {
                        if (result.activityType) {
                            // shared with activity type of result.activityType
                            console.log(`shared with activity type of ${result.activityType}`);
                        } else {
                            // shared
                        }
                    } else if (result.action === Share.dismissedAction) {
                        // dismissed
                        console.log(`dismissed`);
                    }
                } else {
                    try {
                        console.log(`navigator.share()...`);
                        if (isMobileWeb()) {

                            console.log(`share on mobile web... `);
                            await navigator.share({
                                title: title,
                                text: `${message}\n${urlToShare}`,
                            });
                            console.log('Thanks for sharing!');
                        }
                    } catch (error) {
                        console.log(`navigator.share() error...`);
                        console.log(`Error: ${error.message}`);
                        setShareMessage(`${message}\n${urlToShare}`);
                        setShowCopyPanel(true);
                        setIsSaving(false);
                        return;
                    }
                    console.log(`navigator.share() done...`);
                }

                setIsSaving(false);
            } catch (error: any) {
                console.log(`error sharing`);
                Alert.alert(error.message);
                setIsSaving(false);
            }
        }
    };

    const pressCopyLink = async () => {
        await Clipboard.setStringAsync(shareMessage);

        setShowToast(true);

        setTimeout(() => {
            setShowToast(false);
        }, 5000);
    }

    const onLayout = (event: LayoutChangeEvent) => {
        setVideoHeight(event.nativeEvent.layout.height - 100);
    }

    const setSpotlightFN = (fn: string) => {
        setSfn(fn);
        if (spotlightType == 'self') {
            setFn(fn);
        }
    }

    const setSpotlightLN = (ln: string) => {
        setSln(ln);
        if (spotlightType == 'self') {
            setLn(ln);
        }
    }

    const onRecord = () => {
        Linking.openURL(recordUrl);
    }

    function renderWalkthrough(): React.ReactElement<any, string | React.JSXElementConstructor<any>> {
        return (
            <View style={{ display: 'flex', flex: 1 }}>
                {step == -1 &&
                    <View style={{ display: 'flex', flex: 1}}>
                        <StepHeader floating={navigation == null} stepDisplayNumber={0} stepTitles={headerSteps} />
                        <PQPage
                            hasSteps={true}
                            navigation={navigation}
                            title={i18n.t('OnePersonOneQuestion_Intro_Page_Title')}
                            next={async () => setStep(0)}
                            back={props.navigation.goBack}
                            onLayout={onLayout}
                        >
                            <View style={styles.column}>
                                <NText style={[styles.body, { marginBottom: 12 }]}>
                                    {i18n.t('OnePersonOneQuestion_Intro_Page_Body')}
                                </NText>
                                <VimeoPlayer
                                    embedUrl={`https://player.vimeo.com/video/778647119?h=cf51a0b614&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                                    asset={FALLBACK_VIDEO}
                                    height={videoHeight}
                                    width={videoHeight * 0.5647}
                                    transcodingStatus={'complete'}
                                    //heightConstrained={Platform.OS === 'web'}
                                />
                            </View>
                        </PQPage>
                    </View>}
                    {step == 0 &&
                    <View style={{ display: 'flex', flex: 1/* test */ }}>
                        <StepHeader floating={navigation == null} stepDisplayNumber={0} stepTitles={headerSteps} />
                        <PQPage
                            hasSteps={true}
                            navigation={navigation}
                            title={spotlightType == 'self' ? i18n.t('FastOnePersonOneQuestion_Zeroth_Page_Title_SelfMode') : i18n.t('FastOnePersonOneQuestion_Zeroth_Page_Title')}
                            image={<Family width={60} height={60} />}
                            //imageSource={require(`../../assets/svg/illustrations/Family.svg`)}
                            validate={validate0}
                            next={async () => setStep(1)}
                            back={() => setStep(-1)}
                            // infoChildren={<>
                            //     <NText style={[styles.infoHeader, { marginBottom: 14, }]}>
                            //         {i18n.t('OnePersonOneQuestion_First_Second_Third_Page_InfoHeader')}
                            //     </NText>
                            //     <NText style={[styles.infoText, { marginBottom: 14, }]}>
                            //         {i18n.t('OnePersonOneQuestion_First_Second_Third_Page_InfoText', { 0: i18n.t('OnePersonOneQuestion_SpotlightSingular') })}
                            //     </NText>
                            // </>}
                        >
                            <View style={styles.column}>
                                <NText style={styles.body}>
                                    {spotlightType == 'self' ? i18n.t('FastOnePersonOneQuestion_Zeroth_Page_Body_SelfMode') : i18n.t('FastOnePersonOneQuestion_Zeroth_Page_Body')}
                                </NText>
                                <View style={{ ...styles.column, padding: 0, marginTop: 12, flex: 1, alignItems: 'flex-start' }}>
                             
                                        <NTextInput
                                            inputContainerStyle={{
                                                marginVertical: 6,
                                            }}
                                            ref={sfnRef}
                                            onLayout={(e) => onLayoutInput(e, setSfnt, sfnRef)}
                                            value={sfn}
                                            onChangeText={setSpotlightFN}
                                            maxLength={128}
                                            hideMaxLength
                                            label={i18n.t('OnePersonOneQuestion_First_Page_FirstName')}
                                            labelStyle={styles.label}
                                            errorMessage={sfnError} />
                                        <NTextInput
                                            inputContainerStyle={{
                                                marginVertical: 6,
                                            }}
                                            ref={slnRef}
                                            onLayout={(e) => onLayoutInput(e, setSlnt, slnRef)}
                                            value={sln}
                                            onChangeText={setSpotlightLN}
                                            maxLength={128}
                                            hideMaxLength
                                            label={i18n.t('OnePersonOneQuestion_First_Page_LastName')}
                                            labelStyle={styles.label}
                                            errorMessage={slnError} />
                                </View>
                            </View>
                        </PQPage>
                    </View>}
                {step == 1 &&
                    <View style={{ display: 'flex', flex: 1 }}>
                        <StepHeader floating={navigation == null} stepDisplayNumber={1} stepTitles={headerSteps} />
                        <PQPage
                            hasSteps={true}
                            navigation={navigation}
                            title={spotlightType == 'self' ? i18n.t('FastOnePersonOneQuestion_First_Page_Title_SelfMode') : i18n.t('FastOnePersonOneQuestion_First_Page_Title')}
                            image={<Question width={60} height={60} />}
                            //imageSource={require(`../../assets/svg/illustrations/Message.svg`)}
                            validate={validate1}
                            next={async () => {
                                setUcq(false);
                                setStep(3);
                            }}
                            back={() => setStep(0)}
                            infoChildren={<>
                                <NText style={[styles.infoHeader, { marginBottom: 14, }]}>
                                    {i18n.t('FastOnePersonOneQuestion_First_Second_Third_Page_InfoHeader')}
                                </NText>
                                <NText style={[styles.infoText, { marginBottom: 14, }]}>
                                    {i18n.t('FastOnePersonOneQuestion_First_Second_Third_Page_InfoText')}
                                </NText>
                            </>}
                        >
                            <View style={styles.column}>
                                {
                                    spotlightType != 'self' &&
                                    <>
                                        <NText style={styles.body}>
                                            {i18n.t('FastOnePersonOneQuestion_First_Page_Body1')}
                                            <NText
                                                style={styles.link}
                                                onPress={() => {
                                                    setQ('');
                                                    setC('');
                                                    setStep(2);
                                                }}
                                            >
                                                {i18n.t('FastOnePersonOneQuestion_First_Page_Body2')}
                                            </NText>
                                        </NText>
                                        <NText style={styles.subHeading}>
                                            {i18n.t('FastOnePersonOneQuestion_First_Page_Subheading')}
                                        </NText>
                                    </>
                                }
                                {qError.length > 0 &&
                                    <Text style={styles.errorMessage}>
                                        {qError}
                                    </Text>}
                                <View style={[
                                    styles.columnShort,
                                    qError.length > 0 ? { borderColor: theme.colors.danger, borderWidth: 1, borderRadius: 12 } : {}
                                ]}>
                                    {nextQuestionOptions.map((question, i) => (
                                        <Pressable
                                            key={i}
                                            onPress={() => {
                                                setQ(question.text);
                                                setC(question.category);
                                            }}
                                            style={[
                                                styles.unselectedQuestion,
                                                q != question.text ? {} : styles.selectedQuestion,
                                            ]}
                                        >
                                            <NText style={styles.question}>
                                                {question.text}
                                            </NText>
                                        </Pressable>
                                    ))}
                                </View>
                                {nextQuestionOptions.length < totalQuestionOptions.length &&
                                    <NButton
                                        t={'mild2'}
                                        style={{
                                            marginVertical: 6,
                                        }}
                                        title={i18n.t(`OnePersonOneQuestion_More`)}
                                        onPress={getNextOptions} />}
                                <NText
                                    style={[
                                        styles.link,
                                        {
                                            textAlign: 'center',
                                        }
                                    ]}
                                    onPress={() => {
                                        setQ('');
                                        setC('');
                                        setStep(2);
                                    }}
                                >
                                    {i18n.t(`OnePersonOneQuestion_Own_Question`)}
                                </NText>
                            </View>
                        </PQPage>
                    </View>}
                {step == 2 &&
                    <View style={{ display: 'flex', flex: 1 }}>
                        <StepHeader floating={navigation == null} stepDisplayNumber={1} stepTitles={headerSteps} />
                        <PQPage
                            hasSteps={true}
                            navigation={navigation}
                            title={spotlightType == 'self' ? i18n.t('FastOnePersonOneQuestion_Second_Page_Title_SelfMode') : i18n.t('FastOnePersonOneQuestion_Second_Page_Title')}
                            image={<Question width={60} height={60} />}
                            //imageSource={require(`../../assets/svg/illustrations/Message.svg`)}
                            validate={validate2}
                            next={async () => {
                                setStep(3);
                            }}
                            back={() => setStep(1)}
                            infoChildren={<>
                                <NText style={[styles.infoHeader, { marginBottom: 14, }]}>
                                    {i18n.t('FastOnePersonOneQuestion_First_Second_Third_Page_InfoHeader')}
                                </NText>
                                <NText style={[styles.infoText, { marginBottom: 14, }]}>
                                    {i18n.t('FastOnePersonOneQuestion_First_Second_Third_Page_InfoText')}
                                </NText>
                            </>}
                        >
                            <View style={styles.column}>
                                <NText style={styles.body}>
                                    {spotlightType == 'self' ? i18n.t('FastOnePersonOneQuestion_Second_Page_Body_SelfMode') : i18n.t('FastOnePersonOneQuestion_Second_Page_Body')}
                                </NText>
                                <NText style={styles.subHeading}>
                                    {i18n.t('FastOnePersonOneQuestion_Second_Page_InputHeading')}
                                </NText>
                                <NTextInput
                                    inputContainerStyle={{
                                        marginVertical: 6,
                                    }}
                                    ref={cqRef}
                                    onLayout={(e) => onLayoutInput(e, setCqt, cqRef)}
                                    multiline={true}
                                    numberOfLines={4}
                                    maxLength={1000}
                                    placeholder="Type your question here..."
                                    value={cq}
                                    onChangeText={setCq}
                                    placeholderTextColor={theme.colors.text}
                                    errorMessage={cqError}
                                    scrollEnabled={false}
                                />
                            </View>
                        </PQPage>
                    </View>}
                {step == 3 &&
                    <View style={{ display: 'flex', flex: 1 }}>
                        <StepHeader floating={navigation == null} stepDisplayNumber={2} stepTitles={headerSteps} />
                        <PQPage
                            hasSteps={true}
                            navigation={navigation}
                            title={spotlightType == 'self' ? i18n.t('FastOnePersonOneQuestion_Third_Page_Title_SelfMode') : i18n.t('FastOnePersonOneQuestion_Third_Page_Title')}
                            image={<Message width={60} height={60} />}
                            //imageSource={require(`../../assets/svg/illustrations/Question.svg`)}
                            validate={validate3}
                            nextType={'finish'}
                            next={step3Next}
                            back={step3Back}
                        >
                            <View style={styles.column}>
                                <NText style={styles.body}>
                                    {spotlightType == 'self' ? i18n.t('FastOnePersonOneQuestion_Third_Page_Body_SelfMode') : i18n.t('FastOnePersonOneQuestion_Third_Page_Body')}
                                </NText>
                                <NText style={styles.subHeading}>
                                    {i18n.t('FastOnePersonOneQuestion_Third_Page_InputHeading')}
                                </NText>
                                <NTextInput
                                    inputContainerStyle={{
                                        marginVertical: 6,
                                    }}
                                    ref={fnRef}
                                    onLayout={(e) => onLayoutInput(e, setFnt, fnRef)}
                                    value={fn}
                                    onChangeText={setFn}
                                    maxLength={128}
                                    hideMaxLength
                                    label={i18n.t('FastOnePersonOneQuestion_Third_Page_FirstName')}
                                    labelStyle={styles.label}
                                    errorMessage={fnError}
                                    disabled={commonContext.loggedInUser != undefined}
                                    focusable={commonContext.loggedInUser == undefined} />
                                <NTextInput
                                    inputContainerStyle={{
                                        marginVertical: 6,
                                    }}
                                    ref={lnRef}
                                    onLayout={(e) => onLayoutInput(e, setLnt, lnRef)}
                                    value={ln}
                                    onChangeText={setLn}
                                    maxLength={128}
                                    hideMaxLength
                                    label={i18n.t('FastOnePersonOneQuestion_Third_Page_LastName')}
                                    labelStyle={styles.label}
                                    errorMessage={lnError}
                                    disabled={commonContext.loggedInUser != undefined}
                                    focusable={commonContext.loggedInUser == undefined} />
                                <NTextInput
                                    inputContainerStyle={{
                                        marginVertical: 6,
                                    }}
                                    ref={eRef}
                                    onLayout={(e) => onLayoutInput(e, setEt, eRef)}
                                    value={e}
                                    onChangeText={setE}
                                    maxLength={128}
                                    hideMaxLength
                                    label={i18n.t('FastOnePersonOneQuestion_Third_Page_Email')}
                                    labelStyle={styles.label}
                                    errorMessage={eError}
                                    disabled={commonContext.loggedInUser != undefined}
                                    focusable={commonContext.loggedInUser == undefined}
                                    autoCapitalize={'none'}
                                    keyboardType={'email-address'}
                                    autoCompleteType={'email'} />
                                <NText style={styles.important}>
                                    {i18n.t('OnePersonOneQuestion_Privacy')}
                                </NText>
                            </View>
                        </PQPage>
                    </View>}
                {step == 4 &&
                    <View style={{ display: 'flex', flex: 1 }}>
                        <StepHeader floating={navigation == null} stepDisplayNumber={3} stepTitles={headerSteps} />
                        <PQPage
                            hasSteps={true}
                            navigation={navigation}
                            title={i18n.t('FastOnePersonOneQuestion_Fourth_Page_Title')}
                            image={<Envelope width={60} height={60} />}
                            //imageSource={require(`../../assets/svg/illustrations/Envelope.svg`)}
                            back={() => setStep(3)}
                            next={cqid.length > 0 ? async () => {
                                setStep(5);
                                clearAllFields();
                            } : undefined}
                            nextType={'next'}
                        >
                            <View style={styles.column}>
                                <NText style={styles.body}>
                                    {i18n.t('FastOnePersonOneQuestion_Fourth_Page_Body1')}
                                </NText>
                                <NText style={styles.label}>
                                    {submitError}
                                </NText>
                                <NButton
                                    t={'primary'}
                                    title={i18n.t('FastOnePersonOneQuestion_Share')}
                                    titleStyle={{
                                        textTransform: 'uppercase',
                                    }}
                                    style={{
                                        marginTop: 34,
                                    }}
                                    icon={
                                        <Icon
                                            name="share-outline"
                                            type='ionicon'
                                            size={22}
                                            color={Palette.plum}
                                            style={{ marginRight: 4 }}
                                        />
                                    }
                                    onPress={onShare}
                                    loading={isSaving}
                                    disabled={isSaving}
                                />
                                <NText style={[styles.body, { marginTop: 34, }]}>
                                    {i18n.t('FastOnePersonOneQuestion_Fourth_Page_Body2')}
                                </NText>

                                {
                                    showCopyPanel &&
                                    <View style={[styles.columnShort, { marginTop: 34, backgroundColor: Palette.lightpurple.concat('25'), borderRadius: 20, borderWidth: 2, borderColor: Palette.lightpurple } ]}>
                                        <NText style={[styles.body, { marginBottom: 10, textAlign: 'center' }]}>
                                            {i18n.t(`FastOnePersonOneQuestion_ShareModalMessage`)}
                                        </NText>
                                        <NTextInput
                                            value={shareMessage}
                                            multiline={true}
                                            selectTextOnFocus={true}
                                            containerStyle={{
                                                flexShrink: 1,
                                                maxWidth: 600,
                                            }}
                                            testID={`share-text`}
                                        />
                                        <NButton
                                            title={i18n.t('FastOnePersonOneQuestion_CopyMessage')}
                                            t="action"
                                            onPress={pressCopyLink}
                                            testID='share-copy-link-button'
                                        />
                                        <NToast
                                            show={showToast}
                                            icon={
                                                <Icon
                                                    size={28}
                                                    name={'check-circle-outline'}
                                                    type='material-community'
                                                    color={Palette.teal}
                                                />
                                            }
                                            message={i18n.t('FastOnePersonOneQuestion_Toast_CopyMessage')}
                                            maxWidth={600}
                                        />
                                    </View>
                                }
                            </View>
                        </PQPage>
                    </View>}
                {step == 5 &&
                    <View style={{ display: 'flex', flex: 1 }}>
                        <StepHeader floating={navigation == null} stepDisplayNumber={3} stepTitles={headerSteps} />
                        <PQPage
                            hasSteps={true}
                            navigation={navigation}
                            title={spotlightType == 'self' ? i18n.t('FastOnePersonOneQuestion_Fifth_Page_Title_SelfMode') : i18n.t('FastOnePersonOneQuestion_Fifth_Page_Title')}
                        >
                            <View style={styles.column}>
                                <NText style={[styles.body]}>
                                    {spotlightType == 'self' ? i18n.t('FastOnePersonOneQuestion_Fifth_Page_Body1_SelfMode') : i18n.t('FastOnePersonOneQuestion_Fifth_Page_Body1')} &#127881; {/* party popper emoji */}
                                </NText>
                                {
                                    spotlightType == 'self' &&
                                    <NButton
                                        t={'primary'}
                                        title={i18n.t('FastOnePersonOneQuestion_Fifth_Page_RecordButton')}
                                        onPress={onRecord}
                                        style={{
                                            marginTop: 20,
                                        }}
                                    />
                                }
                                {
                                    !commonContext.loggedInUser &&
                                    <>
                                        {
                                            spotlightType != 'self' &&
                                            <NText style={[styles.subHeading, { marginTop: 20, }]}>
                                                {i18n.t('FastOnePersonOneQuestion_Fifth_Page_Body2')}
                                            </NText>
                                        }
                                        {
                                            spotlightType == 'self' &&
                                            <NText style={[styles.body, { marginTop: 20, }]}>
                                                {i18n.t('FastOnePersonOneQuestion_Fifth_Page_Body2_SelfMode')}
                                            </NText>
                                        }
                                        <NText style={[styles.body, { marginVertical: 10, }]}>
                                            {spotlightType == 'self' ? i18n.t('FastOnePersonOneQuestion_Fifth_Page_Body3_SelfMode') : i18n.t('FastOnePersonOneQuestion_Fifth_Page_Body3')}
                                        </NText>
                                        <View style={styles.bulletRow}>
                                            <NText style={styles.bulletNumber}>
                                                {`1.`}
                                            </NText>
                                            <NText style={styles.bulletText}>
                                                {spotlightType == 'self' ? i18n.t('FastOnePersonOneQuestion_Fifth_Page_Step1_Bullet1_SelfMode') : i18n.t('FastOnePersonOneQuestion_Fifth_Page_Step1_Bullet1')}
                                            </NText>
                                        </View>
                                        <View style={styles.bulletRow}>
                                            <NText style={styles.bulletNumber}>
                                                {`2.`}
                                            </NText>
                                            <NText style={styles.bulletText}>
                                                {spotlightType == 'self' ? i18n.t('FastOnePersonOneQuestion_Fifth_Page_Step1_Bullet2_SelfMode') : i18n.t('FastOnePersonOneQuestion_Fifth_Page_Step1_Bullet2')}
                                            </NText>
                                        </View>
                                        <View style={styles.bulletRow}>
                                            <NText style={styles.bulletNumber}>
                                                {`3.`}
                                            </NText>
                                            <NText style={styles.bulletText}>
                                                {spotlightType == 'self' ? i18n.t('FastOnePersonOneQuestion_Fifth_Page_Step1_Bullet3_SelfMode') : i18n.t('FastOnePersonOneQuestion_Fifth_Page_Step1_Bullet3')}
                                            </NText>
                                        </View>
                                        <View style={styles.bulletRow}>
                                            <NText style={styles.bulletNumber}>
                                                {`4.`}
                                            </NText>
                                            <NText style={styles.bulletText}>
                                                {spotlightType == 'self' ? i18n.t('FastOnePersonOneQuestion_Fifth_Page_Step1_Bullet4_SelfMode') : i18n.t('FastOnePersonOneQuestion_Fifth_Page_Step1_Bullet4')}
                                            </NText>
                                        </View>
                                    </>
                                }
                                {
                                    // commonContext.loggedInUser &&
                                    // <>
                                    //     <NText style={[styles.body, { marginTop: 14, }]}>
                                    //         {i18n.t('FastOnePersonOneQuestion_Fifth_Page_Body2_Auth')}
                                    //     </NText>
                                    //     <NText style={[styles.body, { marginTop: 14, }]}>
                                    //         {i18n.t('FastOnePersonOneQuestion_Fifth_Page_Body3_Auth')}
                                    //     </NText>
                                    // </>
                                }
                                {
                                    !commonContext.loggedInUser &&
                                    <NButton
                                        t={'primary2'}
                                        title={i18n.t('OnePersonOneQuestion_CreateAccount')}
                                        style={{
                                            marginTop: 20,
                                        }}
                                        onPress={createAccount} />
                                }
                                {
                                    // commonContext.loggedInUser &&
                                    // <NButton
                                    //     t={'secondary'}
                                    //     title={i18n.t('OnePersonOneQuestion_MyCommunities')}
                                    //     style={{
                                    //         marginTop: 20,
                                    //     }}
                                    //     onPress={done} />
                                }
                                {
                                    spotlightType != 'self' &&
                                    <>
                                        <NText style={[styles.body, { marginTop: 30, textAlign: 'center', fontStyle: 'italic' }]}>
                                            {i18n.t('FastOnePersonOneQuestion_Fifth_Page_Body4')}
                                        </NText>
                                        <NText style={[styles.link, { textAlign: 'center' }]}
                                            onPress={() => Linking.openURL("https://www.meetnovella.com/faqs")}
                                        >
                                            {i18n.t('OnePersonOneQuestion_Seventh_Page_Body4c')}
                                        </NText>
                                        <NText style={[styles.body, { textAlign: 'center', marginVertical: 2, }]}>
                                            {i18n.t('FastOnePersonOneQuestion_Fifth_Page_Body4b')}
                                        </NText>
                                        <NText
                                            style={[styles.link, { textAlign: 'center', marginTop: 0, }]}
                                            onPress={() => Linking.openURL(`mailto:${i18n.t('OnePersonOneQuestion_TeamEmailAddress')}`)}
                                        >
                                            {i18n.t('OnePersonOneQuestion_TeamEmailAddress')}
                                        </NText>
                                    </>
                                }
                                {/*   <NText style={styles.subHeading}>
                {i18n.t('OnePersonOneQuestion_Seventh_Page_Subtitle')}
            </NText>
              <NText style={{
                ...styles.body,
                marginTop: 12,
            }}>
                {i18n.t('OnePersonOneQuestion_Seventh_Page_Body2')}
            </NText>
              <NText style={styles.inputHeading}>
                {i18n.t('OnePersonOneQuestion_Seventh_Page_InputHeading')}
            </NText>
            <NTextInput
                value={rfn}
                onChangeText={setRfn}
                maxLength={128}
                hideMaxLength
                label={i18n.t('OnePersonOneQuestion_Seventh_Page_FirstName')}
                labelStyle={styles.label}
                errorMessage={rfnError}
            />
            <NTextInput
                value={rln}
                onChangeText={setRln}
                maxLength={128}
                hideMaxLength
                label={i18n.t('OnePersonOneQuestion_Seventh_Page_LastName')}
                labelStyle={styles.label}
                errorMessage={rlnError}
            />
            <NTextInput
                value={re}
                onChangeText={setRe}
                maxLength={128}
                hideMaxLength
                label={i18n.t('OnePersonOneQuestion_Seventh_Page_Email')}
                labelStyle={styles.label}
                errorMessage={reError}
            />
              <NText style={styles.important}>
                {i18n.t('OnePersonOneQuestion_Privacy')}
            </NText>
            <NButton
                t={'primary'}
                title={i18n.t('OnePersonOneQuestion_Submit')}
                style={{
                    marginTop: 20,
                }}
                onPress={() => {
                    alert(`${rfn} ${rln} at ${re}`);
                }}
            />
            <NButton
                t={'secondary'}
                title={i18n.t('OnePersonOneQuestion_NoThanks')}
                style={{
                    marginTop: 8,
                }}
                onPress={goHome}
            /> */}
                            </View>
                        </PQPage>
                    </View>}
            </View>
        );
    }

    return (
        <>
            {
                !commonContext.loggedInUser &&
                renderWalkthrough()
            }
            {
                commonContext.loggedInUser &&
                <ScrollableViewScreen
                    navigation={props.navigation}
                    isFocused={isFocused}
                    refreshing={false}
                    //topTitle={`Create New`}
                    bottomTitle={`Create New`}
                    goBack={props.navigation.goBack}
                    // style={{
                    //     display: 'flex',
                    //     flexDirection: 'column',
                    //     flex: 1,
                    //   }}
                    // contentContainerStyle={{
                    //     alignItems: 'center',
                    //     justifyContent: 'center',
                    //     flex: 1,
                    // }}
                    testID={`create-new-community-screen`}
                    scrollEnabled={false}
                    containsScrollView={true}
                >
                    {
                        renderWalkthrough()
                    }
                </ScrollableViewScreen>
            }
        </>
    );

    async function step3Next(): Promise<void> {
        if (spotlightType == 'self') {
            console.log('step3Next');
            await onFinish();
            setStep(5);
            console.log('step3Next done');
        } else {
            setStep(4);
        }
    }

    async function step3Back(): Promise<void> {
        if (ucq) {
            setStep(2);
        } else {
            setStep(1);
        }
    }
}

export default FastOnePersonOneQuestion;