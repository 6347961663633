import React, { useContext, useEffect } from 'react'
import { View } from 'react-native'
import { RootStackParamList } from '../../../../types/Navigation';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import { Text } from 'react-native-elements';
import { CommonContextType } from '../../../../types/CommonContextType';
import CommonContext from '../../../../CommonContext';
import NButton from '../../../components/NButton';
import { Palette } from '../../../../Theme';
import { DrawerScreenProps } from '@react-navigation/drawer';
import GetStartedPage from './GetStartedPage';

export type OtherProps = DrawerScreenProps<RootStackParamList, 'Other'>;

function Other(props: OtherProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const linkTo = useLinkTo();

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      // analytics
      commonContext.analytics?.viewPage('Other', {});
    }
  }, [isFocused]);


  function goToOtherOptions(): void {
    linkTo(`/other-options`);
  }

  function goToInvitedSpotlight(): void {
    linkTo(`/invited/self`);
  }

  function goToInvitedMember(): void {
    linkTo(`/invited`);
  }

  return (
    <GetStartedPage navigation={props.navigation}>
<Text
        style={{
          color: Palette.white,
          marginTop: -10,
          marginBottom: 20,
          ...themeFromContext.textVariants.askQuestionHeader,
        }}
      >
        {`Other Options:`}
      </Text>
      {/* <Text
        style={{
          color: Palette.white,
          marginTop: -10,
          marginBottom: 20,
          ...themeFromContext.textVariants.body,
          fontSize: 16,
        }}
      >
        {`What would you like to do?`}
      </Text> */}
      <View
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: 260,
          alignItems: 'center',
          //flex: 1,
        }}
      >
        <View
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
        <NButton
          t={'secondary2'}
          title={`I was invited by someone to record a Novella`}
          // titleStyle={{
          //   textTransform: 'uppercase',
          // }}
          style={{
            width: '100%',
            //paddingVertical: 10,
            alignContent: 'center',
            alignSelf: 'center',
            alignItems: 'center',
          }}
          buttonStyle={{
            maxWidth: '75%',
            minWidth: '75%',
            paddingVertical: 10,
          }}
          onPress={goToInvitedSpotlight}
        />
        <NButton
          t={'secondary2'}
          title={`I was invited to join someone else's Novella`}
          // titleStyle={{
          //   textTransform: 'uppercase',
          // }}
          style={{
            width: '100%',
            //paddingVertical: 10,
            alignContent: 'center',
            alignSelf: 'center',
            alignItems: 'center',
          }}
          buttonStyle={{
            maxWidth: '75%',
            minWidth: '75%',
            paddingVertical: 10,
          }}
          onPress={goToInvitedMember}
        />
        </View>
        <View
          style={{
            display: 'flex',
            //justifyContent: 'center',
            //alignItems: 'center',
            width: '100%',
          }}
        >
          <NButton
            t={'secondary2'}
            title={`Other...`}
            // titleStyle={{
            //   textTransform: 'uppercase',
            // }}
            style={{
              minWidth: '100%',
              paddingVertical: 10,
              alignContent: 'center',
              alignSelf: 'center',
              alignItems: 'center',
            }}
            buttonStyle={{
              maxWidth: '75%',
              minWidth: '75%',
              paddingVertical: 10,
            }}
            onPress={goToOtherOptions}
          />
        </View>
      </View>
    </GetStartedPage>
  )
}

export default Other;
