// Frameworks
import React, { ReactNode, useContext, useEffect, useState, } from 'react';
import { ReactElement } from 'react';
import { Animated, View } from 'react-native';
import { Text } from 'react-native-elements';
import { MAX_WIDTH } from '../../../business/layout/layout';
import { normalizeHeight } from '../../../business/layout/responseSize';
import { Palette } from '../../../Theme';
import CommonContext from '../../../CommonContext';
import { CommonContextType } from '../../../types/CommonContextType';
import { BOTTOM_TITLE_BASE_HEIGHT, TOP_TITLE_BASE_HEIGHT } from '../../../constants/header';

function HeaderSecond(props: { height: number, topTitle?: string | ReactElement, top: number, filter?: ReactNode }): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { height } = commonContext.dimensions;

  const [topTitleSize, setTopTitleSize] = useState(0);
  const [bottomTitleSize, setBottomTitleSize] = useState(0);

  useEffect(() => {
    setTopTitleSize(Math.max(normalizeHeight(TOP_TITLE_BASE_HEIGHT * 0.5, height) - 4, 21));
    setBottomTitleSize(Math.max(normalizeHeight(BOTTOM_TITLE_BASE_HEIGHT * 0.5, height), 12));
  }, [height]);

  return (
    <Animated.View
      style={{
        position: 'absolute',
        top: props.top,
        width: '100%',
        backgroundColor: Palette.plum,
        borderColor: Palette.plum,
      }}>
      <View // SECOND HEADER ROW
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          alignSelf: 'center',
          justifyContent: 'space-between',
          width: '100%',
          paddingTop: 0,
          height: props.height,
          backgroundColor: Palette.plum,
          maxWidth: MAX_WIDTH,
        }}
      >
        {
          typeof props.topTitle == 'string' &&
          <Text
            style={{
              ...themeFromContext.textVariants.headerTopTitle,
              fontSize: topTitleSize,
              color: Palette.white,
              textAlign: 'left',
              paddingLeft: 12,
              //width: '100%',
              flex: 1,
            }}
            testID={`header-top-title-string`}
          >
            {props.topTitle}
          </Text>
        }
        {
          typeof props.topTitle != 'string' &&
          <View
            style={{
              width: '100%',
              maxWidth: MAX_WIDTH,
            }}
            testID={`header-top-title-other`}
          >
            {props.topTitle}
          </View>
        }
        {props.filter}
      </View>
    </Animated.View>
  );
}
export default HeaderSecond;