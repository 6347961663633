import log from '../logging/logger';

const IMMEDIATE = true;

export type QuestionAnalyticsEvent = {
  eventName: string,
  community: string,
  question: string,
  category: string,
  source: string,
  firstName: string|undefined,
  lastName: string|undefined,
  email: string|undefined,
  username: string|undefined,
  imageKey: string|undefined,
}

export async function recordQuestionEvent(event: QuestionAnalyticsEvent) : Promise<void> {

  try {
    const e = {
      name: event.eventName,
      immediate: IMMEDIATE,
      attributes: {
          community: event.community,
          question: event.question,
          category: event.category,
          source: event.source,
          firstName: event.firstName,
          lastName: event.lastName,
          email: event.email,
          cognitoUsername: event.username,
          imageKey: event.imageKey,
      },
    };
    //log.info(JSON.stringify(e, null, 2));
    //await Analytics.record(e);
  } catch (ex) {
    log.info(`Failed to record analytics event for question event:`);
    log.info(JSON.stringify(event, null, 2));
  }
}