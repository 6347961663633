import React, { useContext } from 'react'
import { View } from 'react-native'
import { RootStackParamList } from '../../../../types/Navigation';
import { CommonContextType } from '../../../../types/CommonContextType';
import CommonContext from '../../../../CommonContext';
import { Palette } from '../../../../Theme';
import { DrawerScreenProps } from '@react-navigation/drawer';
import SignUpInner from './SignUpInner';

type SignUpProps = DrawerScreenProps<RootStackParamList, 'Sign Up'>;

function SignUp(props: SignUpProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);

  return (
    <>
      <View
        style={{
          top: 0,
          left: 0,
          //position: 'absolute',
          display: 'flex',
          flex: 1,
          width: commonContext.dimensions.width,
          height: '100%',
          minHeight: 800,
          backgroundColor: Palette.plum,
        }}
      >

      <SignUpInner {...props} />
      </View>
    </>
  );
}

export default SignUp;