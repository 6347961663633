import React, { useState, useContext, useEffect } from 'react'
import { View, Image, SafeAreaView } from 'react-native'

import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { RootStackParamList } from '../../../../types/Navigation';
import { CommonContextType } from '../../../../types/CommonContextType';
import CommonContext from '../../../../CommonContext';
import CountryDialCodes from '../../../../constants/CountryDialCodes';
import { isValidEmail } from '../../../../business/user/userHelper';
import AuthPageInput from '../AuthPageInput';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import { Button, Text } from 'react-native-elements';
import AuthPageTerms from '../AuthPageTerms';
import { preSignUpError } from '../../../../business/errors/preSignUpError';
import NButton from '../../../components/NButton';
import { Palette } from '../../../../Theme';
import log from '../../../../business/logging/logger';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { DrawerScreenProps } from '@react-navigation/drawer';
import { setAutoJoin } from '../../../../business/public/autoJoin';
import { ItemType } from 'react-native-dropdown-picker';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { DISPLAY, PHONE_BREAK, TABLET_BREAK } from '../../../../business/layout/layout';

type SignUpProps = DrawerScreenProps<RootStackParamList, 'Sign Up'>;

function SignUpInner(props: SignUpProps): React.ReactElement {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  //const [password2, setPassword2] = useState('');
  //const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const [successfulSignUp, setSuccessfulSignUp] = useState(false);
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError1, setPasswordError1] = useState('')
  const [passwordError2, setPasswordError2] = useState('')
  //const [phoneError, setPhoneError] = useState('');
  const [attemptingSignUp, setAttemptingSignUp] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const [appleLoading, setAppleLoading] = useState(false);
  const linkTo = useLinkTo();

  const [codes, setCodes] = useState<ItemType<string>[]>(CountryDialCodes.map(x => { return { label: x, value: x }; }));
  const [countryCodeOpen, setCountryCodeOpen] = useState(false);


  const { width, height } = commonContext.dimensions;
  const display = width > TABLET_BREAK ? DISPLAY.WEB : width <= PHONE_BREAK ? DISPLAY.PHONE : DISPLAY.TABLET;

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      // analytics
      commonContext.analytics?.viewPage('Sign Up', {});
    }
  }, [isFocused]);

  useEffect(() => {
    if (props.route.params?.email) {
      setEmail(props.route.params.email);
    }
    if (props.route.params?.c) {
      setAutoJoin(props.route.params.c);
    }
    if (props.route.params?.r) {
      AsyncStorage.setItem('referredBySignUpCode', props.route.params.r, (err: any) => {
        if (err) {
          log.info(`Error trying to set item to Local Forage:`);
          log.info(err);
          //log.info(value);
        }
      });
    }
  }, [props])

  const signUp = async () => {
    setAttemptingSignUp(true);
    let errored = false;

    // Validate email
    const cleanEmail = email.toLowerCase();
    if (!isValidEmail(cleanEmail)) {
      setEmailError(`Please enter a valid email address`);
      errored = true;
    } else {
      setEmailError('');
    }
    setEmail(cleanEmail);

    // Validate first name
    if (firstName == '') {
      setFirstNameError(`First name is required`);
      errored = true;
    } else {
      setFirstNameError('');
    }

    // Validate last name
    if (lastName == '') {
      setLastNameError(`Last name is required`);
      errored = true;
    } else {
      setLastNameError('');
    }

    // Validate password
    setPasswordError1('');

    // if (password1 !== password2) {
    //   setPasswordError2(`Passwords must match!`);
    //   errored = true;
    // } else if (password1 == '' || password2 == '') {
    //   setPasswordError2(`Password cannot be empty`);
    //   errored = true;
    // } else {
    //   setPasswordError2('');
    // }

    if (password1 == '') {
      setPasswordError1(`Password cannot be empty`);
      errored = true;
    } else {
      setPasswordError1('');
    }

    // Validate phone
    // let cleanPhone = phone.replace(/[-.() ]*/gm, '');
    // //log.info(cleanPhone);
    // // strip country code if present
    // const code = CountryDialCodes.find(c => cleanPhone.startsWith(c));
    // if (code) {
    //   setCountryCode(code);
    //   cleanPhone = cleanPhone.substring(code.length);
    // }
    // setPhone(cleanPhone);
    // // make sure enough digits were provided
    // //log.info(cleanPhone);
    // if (cleanPhone.length > 0 && cleanPhone.length != 10) {
    //   setPhoneError(`Please enter a valid phone number`);
    //   errored = true;
    // } else {
    //   setPhoneError('');
    // }

    if (errored) {
      setAttemptingSignUp(false);
      return;
    }

    Auth.signUp({
      username: cleanEmail,
      password: password1,
      attributes: {
        email: cleanEmail,
        given_name: firstName,
        family_name: lastName,
        //phone_number: cleanPhone.length > 0 ? countryCode + cleanPhone : ''
      }
    })
      .then(() => {
        setAttemptingSignUp(false);
        linkTo(`/signin${cleanEmail ? '?email=' + cleanEmail : ''}&confirmed=${true}`);
      })
      .catch((err) => {
        if (err.name == 'InvalidPasswordException') {
          setPasswordError1(err.message);
        } else {
          setPasswordError1('');
        }

        if (err.name == 'UsernameExistsException') {
          setEmailError(err.message);
        } else if (err.name == 'UserLambdaValidationException') {
          setEmailError(preSignUpError(err.message));
        } else {
          //log.info(err);
          setEmailError(err.message);
        }

        setAttemptingSignUp(false);
      });
  }

  return (
    <SafeAreaView style={{
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      flex: 1,
      zIndex: 1,
    }}>
      <KeyboardAwareScrollView
        style={{
          //alignSelf: 'center',
          flex: 1,
          overflow: 'visible',
        }}
        contentContainerStyle={{
          //flexGrow: 1,
          //justifyContent: 'space-around',
          display: 'flex',
          overflow: 'visible',
          // minHeight: '100%',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1,
        }}
      >
        <View
          style={{
            // flex: 1,
            flexGrow: display == DISPLAY.PHONE ? 1 : 0,
            width: '100%',
            maxWidth: display == DISPLAY.PHONE ? '100%' : 400,
            alignItems: 'center',
            //backgroundColor: themeFromContext.colors.background,
            borderRadius: 10,
            paddingHorizontal: 30,
            // paddingTop: top,
            // paddingBottom: bottom,
            justifyContent: 'space-evenly',
            marginLeft: 0,
            zIndex: 1,
            overflow: 'visible',
          }}
        >
          <Image
            source={require('../../../../../assets/logo-white-teal-alpha.png')}
            resizeMode='contain'
            style={{ height: height / 10, width: '100%' }}
          />
               <Text
              style={{
                ...themeFromContext.textVariants.askQuestionHeader,
                color: Palette.white,
                width: '100%',
                textAlign: 'left',
                marginBottom: 10,
                marginTop: 25,
                //paddingVertical: 20,
              }}
            >
              {`Create an account`}
            </Text>
          {!successfulSignUp &&
            <>
              <AuthPageInput
                //label='First Name *'
                onChangeText={setFirstName}
                placeholder='First name'
                //autoCompleteType='name'
                keyboardType='default'
                textContentType='givenName'
                autoCapitalize='words'
                autoCorrect={false}
                errorMessage={firstNameError}
                testID='sign-up-first-name-input'
                inputStyle={{
                  borderRadius: 10,
                  borderColor: Palette.white,
                  borderWidth: 2,
                  paddingHorizontal: 16,
                  paddingVertical: 8,
                  ...themeFromContext.textVariants.body,
                  color: Palette.white,
                  backgroundColor: Palette.white.concat('33'),
                }}
                placeholderTextColor={Palette.white.concat('aa')}
              />
              <AuthPageInput
                //label='Last Name *'
                onChangeText={setLastName}
                placeholder='Last name'
                //autoCompleteType='name'
                keyboardType='default'
                textContentType='familyName'
                autoCapitalize='words'
                autoCorrect={false}
                errorMessage={lastNameError}
                testID='sign-up-last-name-input'
                inputStyle={{
                  borderRadius: 10,
                  borderColor: Palette.white,
                  borderWidth: 2,
                  paddingHorizontal: 16,
                  paddingVertical: 8,
                  ...themeFromContext.textVariants.body,
                  color: Palette.white,
                  backgroundColor: Palette.white.concat('33'),
                }}
                placeholderTextColor={Palette.white.concat('aa')}
              />
              <AuthPageInput
                //label='Email *'
                value={email}
                onChangeText={setEmail}
                placeholder='Email'
                autoCompleteType='email'
                keyboardType='email-address'
                textContentType='emailAddress'
                autoCapitalize='none'
                autoCorrect={false}
                errorMessage={emailError}
                testID='sign-up-email-input'
                inputStyle={{
                  borderRadius: 10,
                  borderColor: Palette.white,
                  borderWidth: 2,
                  paddingHorizontal: 16,
                  paddingVertical: 8,
                  ...themeFromContext.textVariants.body,
                  color: Palette.white,
                  backgroundColor: Palette.white.concat('33'),
                }}
                placeholderTextColor={Palette.white.concat('aa')}
              />
              <AuthPageInput
                //label='Password *'
                onChangeText={setPassword1}
                placeholder='Create a password'
                autoCompleteType='password'
                //keyboardType='visible-password'
                textContentType='password'
                autoCapitalize='none'
                autoCorrect={false}
                secureTextEntry
                passwordRules='required: lower; required: digit; minlength: 7;'
                errorMessage={passwordError1}
                testID='sign-up-password-input'
                inputStyle={{
                  borderRadius: 10,
                  borderColor: Palette.white,
                  borderWidth: 2,
                  paddingHorizontal: 16,
                  paddingVertical: 8,
                  ...themeFromContext.textVariants.body,
                  color: Palette.white,
                  backgroundColor: Palette.white.concat('33'),
                }}
                placeholderTextColor={Palette.white.concat('aa')}
              />
              {/* <TextInput // this is a hack to prevent auto password creation nightmare loop... can't believe this "works: (https://stackoverflow.com/a/68039177)
                style={{
                  height: 0.1,
                }}
                
              />
              <AuthPageInput
                onChangeText={setPassword2}
                placeholder='Confirm password'
                autoCompleteType='password'
                //keyboardType='visible-password'
                textContentType='password'
                autoCapitalize='none'
                autoCorrect={false}
                secureTextEntry
                passwordRules='required: lower; required: digit; minlength: 7;'
                errorMessage={passwordError2}
                testID='sign-up-confirm-password-input'
                inputStyle={{
                  borderRadius: 10,
                  borderColor: Palette.white,
                  borderWidth: 2,
                  paddingHorizontal: 16,
                  paddingVertical: 8,
                  ...themeFromContext.textVariants.body,
                  color: Palette.white,
                  backgroundColor: Palette.white.concat('33'),
                }}
                placeholderTextColor={Palette.white.concat('aa')}
              /> */}
              {/* <Text
                style={{
                  alignSelf: 'flex-start',
                  marginHorizontal: 10,
                  marginVertical: 8,
                  color: Palette.white,
                  ...themeFromContext.textVariants.inputLabel
                }}>
                {`Phone number`}
              </Text>
              <View style={{ marginLeft: 10, flexDirection: 'row', zIndex: 1 }}>
                <NDropDownPicker
                  dropDownDirection={'DEFAULT'}
                  open={countryCodeOpen}
                  setOpen={setCountryCodeOpen}
                  value={countryCode}
                  setValue={setCountryCode}
                  items={codes}
                  setItems={setCodes}
                  onPressItem={(value) => {
                    setCountryCode(value);
                    setCountryCodeOpen(false);
                  }}
                />

                <AuthPageInput
                  containerStyle={{ flex: 1, flexGrow: 2 }}
                  onChangeText={setPhone}
                  value={phone}
                  placeholder='Optional'
                  autoCompleteType='tel'
                  keyboardType='phone-pad'
                  textContentType='telephoneNumber'
                  autoCapitalize='none'
                  autoCorrect={false}
                  errorMessage={phoneError}
                  testID='sign-up-phone-number-input'
                  inputStyle={{
                    borderRadius: 10,
                    borderColor: Palette.white,
                    borderWidth: 2,
                    paddingHorizontal: 16,
                    paddingVertical: 8,
                    ...themeFromContext.textVariants.body,
                    color: Palette.white,
                    backgroundColor: Palette.white.concat('33'),
                  }}
                  placeholderTextColor={Palette.white.concat('aa')}
                />
              </View> */}
              <AuthPageTerms textColor={Palette.white} />
              {/* <AuthPagePrimaryButton
            style={{paddingTop: 10}}
            onPress={signUp}
            title='Create Account'
            loading={attemptingSignUp}
            //disabled={!isValidEmail(email) || password1.length == 0 || firstName.length == 0 || lastName.length == 0}
          /> */}
              <NButton
                testID='sign-up-create-account-button'
                style={{ paddingTop: 10 }}
                //containerStyle={{ padding: 10, width: '100%' }}
                t='primary2'
                onPress={signUp}
                title={`Create Account`}
                loading={attemptingSignUp}
                loadingProps={{color: Palette.plum}}
              />
              <Text
                style={{
                  ...themeFromContext.textVariants.detail,
                  color: Palette.white,
                  margin: 5,
                }}
              >
                {`OR`}
              </Text>
              <NButton
                testID='sign-in-google-button'
                // containerStyle={{ paddingHorizontal: 10, width: '100%' }}
                loading={googleLoading}
                loadingProps={{
                  animating: true,
                  color: Palette.white,
                  size: 'small',
                }}
                //buttonStyle={{paddingVertical: 8}}
                buttonStyle={{
                  borderColor: Palette.white
                }}
                t={"secondary2"}
                title={
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignContent: 'center',
                      //width: '100%',
                      alignItems: 'center',
                    }}
                  >
                    <View>
                      <Image
                        source={require('../../../../../assets/social/g_25.png')}
                        style={{
                          height: 25,
                          width: 25,
                          marginRight: 6
                        }}
                      />
                    </View>
                    <View
                      style={{
                        //flex: 1,
                        //width: '100%',
                      }}
                    >
                      <Text
                        style={{
                          textAlign: 'center',
                          ...themeFromContext.textVariants.authSecondaryButton,
                          //fontWeight: themeFromContext.textVariants.detail.fontWeight,
                          color: Palette.white,
                          //color: themeFromContext.colors.foreground,
                        }}
                      >
                        {`Sign up with Google`}
                      </Text>
                    </View>
                  </View>
                }
                onPress={() => {
                  setGoogleLoading(true);
                  Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
                }}
              />
              <NButton
              testID='sign-up-apple-button'
              //containerStyle={{ paddingHorizontal: 10, paddingVertical: 5, width: '100%' }}
              loading={appleLoading}
              loadingProps={{
                animating: true,
                color: Palette.white,
                size: 'small',
              }}
              buttonStyle={{
                borderColor: Palette.white,
              }}
              //buttonStyle={{paddingVertical: 16}}
              t={"secondary2"}
              title={
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-around',
                    alignContent: 'center',
                    //width: '100%',
                    alignItems: 'center',
                  }}
                >
                  <Image
                    source={require('../../../../../assets/social/a25.png')}
                    style={{
                      height: 25,
                      width: 25,
                      marginRight: 6,
                      marginBottom: 3,
                      tintColor: Palette.white,
                    }}
                  />
                  <Text
                    style={{
                      textAlign: 'center',
                      ...themeFromContext.textVariants.authSecondaryButton,
                      //fontWeight: themeFromContext.textVariants.detail.fontWeight,
                      color: Palette.white,
                      //color: themeFromContext.colors.foreground,
                    }}
                  >
                    {`Sign up with Apple`}
                  </Text>
                </View>
              }
              onPress={() => {
                setAppleLoading(true);
                Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Apple });
              }}
            />
              <View
                style={{
                  width: '80%',
                  borderTopWidth: 1,
                  borderTopColor: Palette.lightgrey,
                  marginTop: 8,
                }}
              >
              </View>
              <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                  <Text
                    style={{
                      //marginEnd: 8,
                      marginVertical: 8,
                      color: Palette.white,
                      ...themeFromContext.textVariants.inputLabel
                    }}>
                    {`Already have an account? `}
                  </Text>
                  <Button
                    testID='sign-in-create-account-button'
                    buttonStyle={{
                      backgroundColor: Palette.transparent,
                      marginVertical: 0,
                      padding: 0,
                      borderWidth: 0
                    }}
                    titleStyle={{
                      marginVertical: 8,
                      paddingVertical: 0,
                      color: Palette.lightteal,
                      alignSelf: 'flex-start',
                      ...themeFromContext.textVariants.inputLabel
                    }}
                    onPress={() => {
                      linkTo(`/signIn${email ? '?email=' + email : ''}`)
                    }}
                    title='Sign in'
                  />
                </View>
              </View>
              {/* <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                <AuthPageLink
                  onPress={() => linkTo(`/confirmCode${email ? '?email=' + email : ''}`)}
                  title='Confirm code'
                  testID='sign-up-confirm-code-button'
                />
                <AuthPageLink
                  onPress={() => linkTo(`/signIn${email ? '?email=' + email : ''}`)}
                  title='Sign in'
                  testID='sign-up-sign-in-button'
                />
              </View> */}
            </>
          }
          {successfulSignUp &&
            <Text>
              Check email for confirmation code and link.
            </Text>
          }
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  )
}

export default SignUpInner;