// Frameworks
import React, { useContext } from 'react';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { Pressable, Text, View } from 'react-native';
import { Icon } from 'react-native-elements';
import { Palette } from '../../Theme';

function CategoryButton(props: { selected: boolean, label: string, expandable: boolean, onPress: () => void, dark?: boolean }): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;

  return (
    <Pressable
      style={[
        {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: props.dark ? Palette.purple : themeFromContext.colors.background,
          //borderBottomWidth: 1,
          padding: 8,
          marginHorizontal: 0,
          marginVertical: 5,
          borderColor: props.selected ? '#00000000' : '#DDEAEB',
          borderWidth: 1,
          borderRadius: 30,
        },
        (props.selected && props.expandable) ? { filter: 'drop-shadow(1px 2px 4px rgba(50, 50, 0, 0.15))' }
        : (props.selected && !props.expandable) ? { backgroundColor: themeFromContext.colors.primary, }
        : {}
      ]}
      onPress={props.onPress}
    >
      <View
        style={{
          flex: 1,
          paddingHorizontal: 4,
        }}
      />
      <View
        style={{
          flex: 10,
          padding: 6,
        }}
      >
        <Text
          style={[
            {
              ...themeFromContext.textVariants.groupHeading,
              textAlign: 'center',
              color: props.dark ? themeFromContext.colors.background : themeFromContext.colors.text,
            },
            (props.selected && !props.expandable) ? { color: themeFromContext.colors.background, } : {}
          ]}
          numberOfLines={1}
          testID={`category-button-${props.label}`}
        >
          {props.label}
        </Text>
      </View>
      <View
        style={{
          flex: 1,
          paddingHorizontal: 4,
        }}
      >
        {
          props.expandable &&
          <Icon
            type="material-community"
            name={props.selected ? "chevron-down" : "chevron-right"}
            size={30}
            // color={props.selected ? themeFromContext.colors.text : '#C0C7C7'}
            color={props.dark ? themeFromContext.colors.background : themeFromContext.colors.text}
          />
        }
      </View>
    </Pressable>
  );
}
export default CategoryButton;