import React, { useContext } from 'react'
import { Image, Text, View } from 'react-native';
import { ButtonProps } from 'react-native-elements';
import NButton from '../../components/NButton';
import CommonContext from '../../../CommonContext';
import { CommonContextType } from '../../../types/CommonContextType';

function AuthGoogleButton(props: ButtonProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  return (
    <NButton
      {...props}
      containerStyle={{ paddingHorizontal: 10, paddingVertical: 5, width: '100%' }}
      loadingProps={{
        animating: true,
        color: themeFromContext.colors.primary,
        size: 'small',
      }}
      buttonStyle={{
        borderColor: themeFromContext.colors.secondary
      }}
      t={"secondary"}
      title={
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignContent: 'center',
            width: '100%',
            alignItems: 'center',
          }}
        >
          <View>
            <Image
              source={require('../../../../assets/social/google_logo.svg')}
              resizeMode='contain'
              style={{
                height: 25,
                width: 25,
              }}
            />
          </View>
          <View
            style={{
              flex: 1,
              width: '100%',
            }}
          >
            <Text
              style={{
                textAlign: 'center',
                ...themeFromContext.textVariants.authSecondaryButton,
                //fontWeight: themeFromContext.textVariants.detail.fontWeight,
                color: themeFromContext.colors.secondary,
                //color: themeFromContext.colors.foreground,
              }}
            >
              {props.title}
            </Text>
          </View>
        </View>
      }
      onPress={props.onPress}
    />
  )
}

export default AuthGoogleButton