// Frameworks
import React, { useContext } from 'react';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { Text, TextProps, StyleSheet, } from 'react-native';
import { normalizeWidth } from '../../business/layout/responseSize';

function NText(props: TextProps & { children?: React.ReactNode }) : React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const { width } = commonContext.dimensions;
  const themeFromContext = commonContext.theme;
  const defaultFontSize = themeFromContext.textVariants.body.fontSize;
  let fontSize = defaultFontSize;
  const style = StyleSheet.flatten(props.style);
  if (style && style.fontSize) {
    fontSize = style.fontSize;
  }

  return (
    <Text
      {...props}
      style={[
        props.style,
        {
          fontSize: normalizeWidth(fontSize, width)
        }
      ]}
    >
      {props.children}
    </Text>
  );
}
export default NText;