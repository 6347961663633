import LocalForageWrapper from "../storage/localForageWrapper";

const MOST_RECENT_USER_KEY = 'mru';

export const rememberUser = (data: any) : void => {
  let email;
  if (data.payload.data.attributes) {
    email = data.payload.data.attributes?.email?.toLowerCase();
  } else {
    email = data.payload.data.signInUserSession.idToken.payload.email?.toLowerCase();
  }
  if (email) {
    LocalForageWrapper.setItem(MOST_RECENT_USER_KEY, email);
  }
}

export const getMostRecentUser = () : string | undefined => {
  const email: string = LocalForageWrapper.getItem(MOST_RECENT_USER_KEY);
  if (email && email.length > 0) {
    return email;
  }
}