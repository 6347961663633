import React from "react"
import { getCurrentEnv } from "./business/env/currentEnv";
import { ServicesContextFactory } from "./ServicesContext";
import { LIGHT_THEME } from "./Theme";
import { CommonContextType } from "./types/CommonContextType";

const CommonContext = React.createContext<CommonContextType>({
  dimensions: { width: 0, height: 0, orientation: 'portrait' },
  isAdmin: false,
  theme: LIGHT_THEME,
  communities: {},
  membership: {},
  showingModal: '',
  setShowingModal: () => {return;},
  services: new ServicesContextFactory(getCurrentEnv()).buildServicesContext(),
});

export default CommonContext;