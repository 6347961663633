import React, { useContext, useEffect, useState } from 'react'
import {
  Image,
} from 'react-native'
import { useLinkTo } from '@react-navigation/native';
import { RootStackParamList } from '../../../../types/Navigation';
import AuthPage from '../AuthPage';
import AuthPageHeader from '../AuthPageHeader';
import { CommonContextType } from '../../../../types/CommonContextType';
import CommonContext from '../../../../CommonContext';
import NButton from '../../../components/NButton';
import { Text } from 'react-native-elements';
import { normalizeHeight } from '../../../../business/layout/responseSize';
import { DrawerScreenProps } from '@react-navigation/drawer';

export type ReferralProps = DrawerScreenProps<RootStackParamList, 'Referral'>;

function Referral(props: ReferralProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const { userService } = commonContext.services;
  const themeFromContext = commonContext.theme;
  const [referralCode, setReferralCode] = useState('');
  const [referrerName, setReferrerName] = useState('');
  const {height} = commonContext.dimensions;
  const linkTo = useLinkTo();

  useEffect(() => {
    if (props.route.params.r) {
      const code = props.route.params.r;
      setReferralCode(code);

      userService.getFirstNameFromReferralCode(code)
        .then((firstName) => {
          setReferrerName(firstName);
        });
    }
  }, [props]);

  const pressGetStarted = () => {
    linkTo(`/signUp?r=${referralCode}`);
  }

  return (
    <AuthPage>
      <AuthPageHeader text='Get $50 cash back after your Novella interview' />
      <Image
        source={require('../../../../../assets/svg/illustrations/People.svg')}
        defaultSource={require('../../../../../assets/svg/illustrations/People.svg')}
        resizeMethod='scale'
        resizeMode='contain'
        style={{
          width: '100%',//normalizeHeight(343, height),
          height: normalizeHeight(120, height),
          marginTop: normalizeHeight(20, height),
          marginBottom: normalizeHeight(20, height),
        }}
      />
      {
        referrerName.length > 0 &&
        <Text
          style={{
            ...themeFromContext.textVariants.body,
            color: themeFromContext.colors.text,
            paddingVertical: 10,
          }}
          testID={`referral-landing-page-body-text`}
        >
          {`${referrerName} invited you to join Novella! If you sign up and record a Novella interview, you'll both receive a $50 cash reward.`}
        </Text>
      }
      <NButton
        testID='referral-landing-page-get-started-button'
        containerStyle={{
          paddingVertical: 30,
        }}
        t='primary'
        onPress={pressGetStarted}
        title={`Get started!`}
      />
    </AuthPage>
  )
}

export default Referral