import { CommandOperation, CommunityCommand, CommunityQuestionAnswer, CommunityQuestionAnswerCommand, CommunityQuestionAnswerComment, CommunityQuestionAnswerCommentCommand, CommunityQuestionAnswerCommentStatus, CommunityQuestionAnswerLikeStatus, CommunityUserCommand, CreateCommunityCommandInput, CreateCommunityQuestionAnswerCommandInput, CreateCommunityQuestionAnswerCommentCommandInput, CreateCommunityUserCommandInput, CreateCommunityUserInput, CreateXAssignEmailToPlaceholderInput, UpdateCommunityQuestionInput, UserStatus, xAssignEmailToPlaceholder } from '../API';
import * as m from '../graphql/mutations';
import * as q from '../graphql/queries';
import * as cq from '../graphql/customQueries';
import { IAPIWrapper } from '../business/graphql/APIWrapper';
import { IFullListQuerier } from '../business/graphql/FullListQuerier';
import log from '../business/logging/logger';
import { findUsersSetIntersection, findUsersSetDifference, UserData, isValidEmail } from '../business/user/userHelper';
import { CommunityQuestionAnswerStatus, CommunityQuestionLikeStatus, CommunityQuestionRecommendation, CommunityQuestionSource } from '../API';
import { OperationStatus } from '../API';
import { CommunityQuestionStatus } from '../API';
import { Community, CommunityQuestion, CommunityUser, CommunityUserStatus, User } from '../API';
import { Dictionary } from '../types/data/Dictionary';
import { ICategoryService } from '../contracts/ICategoryService';
import { IQuestionRecommendationService } from '../contracts/IQuestionRecommendationService';
import { IUserService } from '../contracts/IUserService';
import { getQuestionUnauthenticated } from '../business/public/unauthenticatedQuestion';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/api-graphql';
import { ICache } from '../business/storage/ICache';
import { arraysEqual } from '../business/arrays/arrayHelper';
import { CommunityServiceError, ICommunityService, LikeData, LikeMap, VimeoVideoMetadata } from '../contracts/ICommunityService';



type cip = {
  communityID: { eq: string }
}

type sip = {
  status: { eq: CommunityUserStatus }
}

type ep = {
  email: { eq: string }
}

type up = {
  userID: { eq: string }
}

type cqtp = {
  questionText: { eq: string }
}

type cqip = {
  communityQuestionID: { eq: string }
}

type cqaip = {
  communityQuestionAnswerID: { eq: string }
}

export class CommunityService implements ICommunityService {
  DEFAULT_MAX_SUBJECTS = 2;
  DEFAULT_MAX_MEMBERS = 30;
  DEFAULT_MAX_INVITATION_MESSAGE_LENGTH = 500;
  api: IAPIWrapper;
  cache: ICache;
  flq: IFullListQuerier;
  categoryService: ICategoryService;
  recommendationService: IQuestionRecommendationService;
  userService: IUserService;
  constructor(api: IAPIWrapper, cache: ICache, flq: IFullListQuerier, categoryService: ICategoryService, recommendationService: IQuestionRecommendationService, userService: IUserService) {
    this.api = api;
    this.cache = cache;
    this.flq = flq;
    this.categoryService = categoryService;
    this.recommendationService = recommendationService;
    this.userService = userService;
  }
  async getComments(communityQuestionAnswerId: string): Promise<CommunityQuestionAnswerComment[]> {
    if (!communityQuestionAnswerId || communityQuestionAnswerId.length == 0) {
      return [];
    }

    const getResult: any = await this.api.graphql({
      query: q.commentsByAnswer,
      variables: {
        communityQuestionAnswerID: communityQuestionAnswerId,
        filter: { status: { eq: "ADDED" } },
        limit: 1000,
      }
    })
    log.warn(`got comments!`);
    log.info(JSON.stringify(getResult, null, 2));
    const existing: CommunityQuestionAnswerComment[] = getResult.data.commentsByAnswer.items;
    if (!existing) {
      log.error(`Error trying to get comments for CommunityQuestionAnswer with id: ${communityQuestionAnswerId}`);
    }
    return existing;
  }
  async saveNewComment(communityQuestionAnswerId: string, content: string): Promise<void> {
    try {
      const results = await Promise.all([
        this.getAnswer(communityQuestionAnswerId),
        this.userService.getLoggedInUser(),
      ]);

      const answer = results[0];
      const luser = results[1];

      if (answer && luser) {
        // Creating a new communityQuestionAnswerComment for existing communityQuestionAnswer

        const input: CreateCommunityQuestionAnswerCommentCommandInput = {
          command: { operation: CommandOperation.CREATE },
          communityQuestionAnswerID: communityQuestionAnswerId,
          communityID: answer.communityID,
          content: content,
          date: new Date(Date.now()).toISOString(),
          status: CommunityQuestionAnswerCommentStatus.ADDED,
          userID: luser.id,
        };
        const cmd = await this.sendCommunityQuestionAnswerCommentCommand(input);
        log.warn(`sent community question answer command:`);
        log.info(JSON.stringify(cmd, null, 2));
      }
    } catch (ex) {
      log.error(`Failed to get prerequisites: questionAnswerId = '${communityQuestionAnswerId}'`);
      log.error(ex);
      return;
    }
  }
  async saveExistingComment(communityQuestionAnswerComment: CommunityQuestionAnswerComment): Promise<void> {
    try {
      if (communityQuestionAnswerComment) {
        /*

        */
        const input: CreateCommunityQuestionAnswerCommentCommandInput = {
          command: { operation: CommandOperation.UPDATE_PARTIAL },
          communityQuestionAnswerCommentID: communityQuestionAnswerComment.id,
          status: communityQuestionAnswerComment.status,
          content: communityQuestionAnswerComment.content,
        };
        await this.sendCommunityQuestionAnswerCommentCommand(input);
      }
    } catch (ex) {
      log.error(`Failed to send update command for comment: communityQuestionAnswerCommentId = '${communityQuestionAnswerComment.id}'`);
      log.error(ex);
      return;
    }
  }
  async toggleCommunityQuestionAnswerLike(communityQuestionAnswer: CommunityQuestionAnswer): Promise<void> {
    if (communityQuestionAnswer) {
      const user = await this.userService.getLoggedInUser();
      const variables = {
        filter: {
          userID: {
            eq: user.id
          },
          communityQuestionAnswerID: {
            eq: communityQuestionAnswer.id
          },
          status: {
            eq: CommunityQuestionAnswerLikeStatus.ADDED
          }
        }
      };

      const list = await this.flq.queryFullList('listCommunityQuestionAnswerLikes', q.listCommunityQuestionAnswerLikes, variables);

      if (!list || list.length === 0) {
        const createdDate = new Date(Date.now()).toISOString();
        // add new like as ADDED
        /*
          id: ID!
          userID: ID!
          communityID: ID!
          communityQuestionAnswerID: ID!
          status: CommunityQuestionAnswerLikeStatus!
          date: AWSDateTime!
        */
        // try {
        await this.api.graphql({
          query: m.createCommunityQuestionAnswerLike,
          variables: {
            input: {
              userID: user.id,
              communityID: communityQuestionAnswer.communityID,
              communityQuestionAnswerID: communityQuestionAnswer.id,
              status: CommunityQuestionAnswerLikeStatus.ADDED,
              date: createdDate,
            }
          }
        })
        // } catch (ex) {
        //   //log.info(ex);
        //   throw ex;
        // }
      } else {
        // flag all existing as DELETED
        const stuff = await Promise.all(list.map(async (l) => {
          return this.api.graphql({
            query: m.updateCommunityQuestionAnswerLike,
            variables: {
              input: {
                id: l.id,
                userID: l.user.id,
                communityID: l.communityID,
                communityQuestionAnswerID: l.communityQuestionAnswer.id,
                status: CommunityQuestionAnswerLikeStatus.DELETED,
                date: l.date,
              }
            }
          });
        }))
          .catch((reason) => { throw new CommunityServiceError(reason, ''); });
      }
    }
  }
  async getLikeMapForAnswers(items: CommunityQuestionAnswer[]): Promise<LikeMap> {
    const dict: LikeMap = {};
    if (!items || items.length === 0) {
      return dict;
    }
    const user = await this.userService.getLoggedInUser();
    const a: cqaip[] = [];
    items.map(i => a.push({ communityQuestionAnswerID: { eq: i.id } }));

    const variables = {
      filter: {
        status: {
          eq: CommunityQuestionAnswerLikeStatus.ADDED
        },
        or: a
      }
    };

    const list = await this.flq.queryFullList('listCommunityQuestionAnswerLikes', q.listCommunityQuestionAnswerLikes, variables);

    list.forEach(l => {
      if (!dict[l.communityQuestionAnswer.id]) {
        dict[l.communityQuestionAnswer.id] = new LikeData(false, 0);
      }
      if (l.user.id === user.id) {
        dict[l.communityQuestionAnswer.id].like(true);
      } else {
        dict[l.communityQuestionAnswer.id].totalLikes++;
      }
    });
    return dict;
  }
  buildUnauthenticatedLikeMapForAnswers(answers: CommunityQuestionAnswer[], userFirstName: string, userLastName: string): LikeMap {
    const dict: LikeMap = {};
    answers.forEach(q => {
      
      if (!dict[q.id]) {
        dict[q.id] = new LikeData(false, 0);
      }

      if (q.communityQuestionAnswerLikesByStatus && q.communityQuestionAnswerLikesByStatus.items.length > 0) {

        q.communityQuestionAnswerLikesByStatus.items.forEach(l => {
          if (l?.user.firstName.toLowerCase() == userFirstName.toLowerCase() && l?.user.lastName.toLowerCase() == userLastName.toLowerCase()) {
            dict[q.id].like(true);
          } else {
            dict[q.id].totalLikes++;
          }
        });
      }
    });
    return dict;
  }
  //don't export
  async sendCommunityQuestionAnswerCommentCommand(input: CreateCommunityQuestionAnswerCommentCommandInput) : Promise<CommunityQuestionAnswerCommentCommand>
  {
    const createResult: any = await this.api.graphql({
      query: m.createCommunityQuestionAnswerCommentCommand,
      variables: {
        input: input
      }
    });
    return createResult.data.createCommunityQuestionAnswerCommentCommand;
  }
  //don't export
  async sendCommunityQuestionAnswerCommand(input: CreateCommunityQuestionAnswerCommandInput, authMode: GRAPHQL_AUTH_MODE = GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS) : Promise<CommunityQuestionAnswerCommand>
  {
    const createResult: any = await this.api.graphql({
      query: m.createCommunityQuestionAnswerCommand,
      variables: {
        input: input
      },
      authMode: authMode,
    });
    return createResult.data.createCommunityQuestionAnswerCommand;
  }
  async saveNewAnswer(communityQuestionId: string, videoMetadata: VimeoVideoMetadata, actorUserId?: string): Promise<void> {
    const array: VimeoVideoMetadata[] = [ videoMetadata ];
    const content = JSON.stringify(array);
    
    log.warn(`saving new answer: ${communityQuestionId}, ${content}`);
    try {
      let luser;
      let question;
      try {
        const results = await Promise.all([
          this.getQuestion(communityQuestionId),
          this.userService.getLoggedInUser(),
        ]);
        question = results[0];
        luser = results[1];
      } catch (ex) {
        console.log(`Got error in saveNewAnswer:`);
        console.log(ex);
      }

      // log.info(JSON.stringify(question, null, 2));
      // log.info(JSON.stringify(luser, null, 2));

      if (question && luser) {
        // Creating a new communityQuestionAnswer for existing communityQuestion

        const input: CreateCommunityQuestionAnswerCommandInput = {
          command: { operation: CommandOperation.CREATE },
          communityQuestionID: communityQuestionId,
          communityID: question.communityID,
          content: content,
          date: new Date(Date.now()).toISOString(),
          status: CommunityQuestionAnswerStatus.ADDED,
          userID: actorUserId,
        };
        const cmd = await this.sendCommunityQuestionAnswerCommand(input);
        log.warn(`sent community question answer command:`);
        log.info(JSON.stringify(cmd, null, 2));
      } else {
        //if (luser) {
          const q = await getQuestionUnauthenticated(communityQuestionId);
          if (q) {
            const input: CreateCommunityQuestionAnswerCommandInput = {
              command: { operation: CommandOperation.CREATE },
              communityQuestionID: communityQuestionId,
              communityID: q.communityID,
              content: content,
              date: new Date(Date.now()).toISOString(),
              status: CommunityQuestionAnswerStatus.ADDED,
              userID: actorUserId,
            };
            log.info(`got here!`);
            log.info(input);
            const cmd = await this.sendCommunityQuestionAnswerCommand(input, GRAPHQL_AUTH_MODE.AWS_IAM);
            log.warn(`sent UNAUTHENTICATED community question answer command:`);
            log.info(JSON.stringify(cmd, null, 2));
          }
        //}
      }
    } catch (ex) {
      log.error(`Failed to get prerequisites: questionId = '${communityQuestionId}'`);
      log.error(ex);
      return;
    }
  }
  async saveExistingAnswer(communityQuestionAnswer: CommunityQuestionAnswer): Promise<void> {
    try {
      if (communityQuestionAnswer) {
        // Updating a communityQuestionAnswer

        // const existing = communityQuestionAnswer.content;
        // const arr: VimeoVideoMetadata[] = JSON.parse(existing);
        // let newArr = arr.filter(i => i.id != videoMetadata.id);
        // newArr = [ ...newArr, videoMetadata ];
        // const content = JSON.stringify(newArr);


        /*
        id?: string | null,
        command: CommandInput,
        communityQuestionAnswerID?: string | null,
        communityQuestionID?: string | null,
        communityID?: string | null,
        userID?: string | null,
        status?: CommunityQuestionAnswerStatus | null,
        date?: string | null,
        content?: string | null,
        */
        const input: CreateCommunityQuestionAnswerCommandInput = {
          command: { operation: CommandOperation.UPDATE_PARTIAL },
          communityQuestionAnswerID: communityQuestionAnswer.id,
          status: communityQuestionAnswer.status,
          content: communityQuestionAnswer.content,
        };
        await this.sendCommunityQuestionAnswerCommand(input);
      }
    } catch (ex) {
      log.error(`Failed to send update command for answer: communityQuestionAnswerId = '${communityQuestionAnswer.id}'`);
      log.error(ex);
      return;
    }
  }
  async getAnswers(communityQuestionId: string): Promise<CommunityQuestionAnswer[]>
  {  
    if (!communityQuestionId || communityQuestionId.length == 0) {
      return [];
    }

    const getResult: any = await this.api.graphql({
      query: cq.answersByQuestion2,
      variables: {
        communityQuestionID: communityQuestionId,
        filter: { status: { eq: "ADDED" } },
        limit: 1000,
      }
    })
    // log.warn(`got answers!`);
    // log.info(JSON.stringify(getResult, null, 2));
    const existing: CommunityQuestionAnswer[] = getResult.data.answersByQuestion.items;
    if (!existing) {
      log.error(`Error trying to get answers for CommunityQuestion with id: ${communityQuestionId}`);
    }
    return existing;
  }
  async getAnswer(communityQuestionAnswerId: string) : Promise<CommunityQuestionAnswer | undefined>
  {
    if (!communityQuestionAnswerId || communityQuestionAnswerId.length == 0) {
      return;
    }
    const getResult: any = await this.api.graphql({
      query: q.getCommunityQuestionAnswer,
      variables: {
        id: communityQuestionAnswerId
      }
    })
    const existing: CommunityQuestionAnswer = getResult.data.getCommunityQuestionAnswer;
    if (!existing) {
      log.error(`Error trying to get answer with CommunityQuestionAnswer with id: ${communityQuestionAnswerId}`);
    }
    return existing;
  }
  // async deleteExistingAnswer(communityQuestionAnswerId: string, failure: (devMessage: string, userMessage: string) => void): Promise<void> {
  //   const a = await this.getAnswer(communityQuestionAnswerId);
  //   if (a) {
  //     // Now we have everything we need to update the existing answer
  //     await this.api.graphql({
  //       query: m.updateCommunityQuestionAnswer,
  //       variables: {
  //         input: {
  //           id: a.id,
  //           communityQuestionID: a.communityQuestionID,
  //           userID: a.userID,
  //           status: CommunityQuestionAnswerStatus.DELETED,
  //           date: a.date,
  //           content: a.content,
  //         }
  //       }
  //     });
  //   } else {
  //     // should not happen
  //     const error = `trying to delete answer that can't be found: ${communityQuestionAnswerId}`;
  //     failure(error, '');
  //   }
  // }
  compareCommunityQuestions(
    a: CommunityQuestion,
    aLikes: number,
    b: CommunityQuestion,
    bLikes: number,
    by: string,
    asc: boolean): number
  {
    if (by === "likes") {
      if (aLikes < bLikes) {
        return asc ? -1 : 1;
      } else if (aLikes > bLikes) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    } else if (by === "date") {
      const ad = Date.parse(a.date);
      const bd = Date.parse(b.date);
      if (ad < bd) {
        return asc ? -1 : 1;
      } else if (ad > bd) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    } else if (by === "category") {
      return this.categoryService.compareCategories(a.category?.name ?? a.communityCategory ?? '', b.category?.name ?? b.communityCategory ?? '', asc);
    } else if (by === "person") {
      const aFullName = this.userService.getFullName(a.user);
      const bFullName = this.userService.getFullName(b.user);
      if (aFullName < bFullName) {
        return asc ? -1 : 1;
      } else if (aFullName > bFullName) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }
  compareCommunityQuestionAnswers(
    a: CommunityQuestionAnswer,
    b: CommunityQuestionAnswer,
    by: string,
    asc: boolean): number
  {
    if (by === "date") {
      const ad = Date.parse(a.date);
      const bd = Date.parse(b.date);
      if (ad < bd) {
        return asc ? -1 : 1;
      } else if (ad > bd) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }
  compareCommunityUsers(
    a: CommunityUser,
    b: CommunityUser,
    by: string,
    asc: boolean): number
  {
    if (by === "status") {
      return asc ?
        a.status.toLowerCase().localeCompare(b.status.toLowerCase()) :
        -1 * a.status.toLowerCase().localeCompare(b.status.toLowerCase());
    } else if (by === 'joinedOn') {
      const ja = Date.parse(a.joinedOn);
      const jb = Date.parse(b.joinedOn);
      if (ja < jb) {
        return asc ? -1 : 1;
      } else if (ja > jb) {
        return asc ? 1 : -1;
      } else {
        return 0;
      }
    } else if (by === 'createdAt') {
      if (a.createdAt && b.createdAt) {
        const ca = Date.parse(a.createdAt);
        const cb = Date.parse(b.createdAt);
        if (ca < cb) {
          return asc ? -1 : 1;
        } else if (ca > cb) {
          return asc ? 1 : -1;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    } else if (by === 'fullName' || by === 'lastName' || by === 'firstName' || by === 'email') {
      return this.userService.compareUsers(a.user, b.user, by, asc);
    } else {
      return 0;
    }
  }
  compareCommunityUsersPriority(
    a: CommunityUser,
    b: CommunityUser) : number
  {
    if (a.status === b.status) {
      const ja = Date.parse(a.joinedOn);
      const jb = Date.parse(b.joinedOn);
      if (ja < jb) {
        return -1;
      } else if (ja > jb) {
        return 1;
      } else {
        return this.userService.compareUsers(a.user, b.user, 'fullName', true);
      }
    } else {   
      if (a.status === CommunityUserStatus.SUBJECT) {
        return -1;
      } else if (b.status === CommunityUserStatus.SUBJECT) {
        return 1;
      } else if (a.status === CommunityUserStatus.ORGANIZER) {
        return -1;
      } else if (b.status === CommunityUserStatus.ORGANIZER) {
        return 1;
      } else {
        return 0;
      }
    }
  }
  async createCommunity(
    name: string,
    creator: User,
    creatorStatus: CommunityUserStatus,
    invitationMessage: string,
    maxSubjects?: number,
    maxMembers?: number,
    maxInvitationLength?: number): Promise<Community>
  {
    if (maxMembers && maxMembers > this.DEFAULT_MAX_MEMBERS) {
      throw new CommunityServiceError('', `Members cannot exceed ${this.DEFAULT_MAX_MEMBERS}.`);
    }

    if (maxSubjects && maxSubjects > this.DEFAULT_MAX_SUBJECTS) {
      throw new CommunityServiceError('', `Subjects cannot exceed ${this.DEFAULT_MAX_SUBJECTS}.`);
    }

    const now = new Date(Date.now()).toISOString()

    try {
      // Create the community itself
      const result1: any = await this.api.graphql({
        query: m.createCommunity,
        variables: {
          input: {
            name: name,
            allowUnauthenticatedAnswers: true,
            limits: {
              maxMembers: maxMembers ?? this.DEFAULT_MAX_MEMBERS,
              maxSubjects: maxSubjects ?? this.DEFAULT_MAX_SUBJECTS,
              maxInvitationLength: maxInvitationLength ?? this.DEFAULT_MAX_INVITATION_MESSAGE_LENGTH,
            },
          }
        }
      });
      const community: Community = result1.data.createCommunity;

      // Create the first CommunityUser (for the creator)
      const result2: any = await this.api.graphql({
        query: m.createCommunityUser,
        variables: {
          input: {
            communityID: community.id,
            userID: creator.id,
            joinedOn: now,
            invitedByUserID: creator.id,
            invitedOn: now,
            status: creatorStatus,
            invitation: {
              invitationMessage: invitationMessage,
            }
          }
        }
      });
      const cu: CommunityUser = result2.data.createCommunityUser;

      // Create the initial recommendations
      await this.recommendationService.createInitialRecommendations(community.id);

      return community;
    } catch (ex) {
      log.error(ex);
      throw ex;
    }
  }
  async getCommunityDataForLoggedInUser(): Promise<Community[]>
  {
    const list = await this.flq.queryFullList('listCommunities', listCommunitiesLite/*, variables*/);
    //const list = await fullListQuerier('listCommunities', q.listCommunities/*, variables*/);
    return list;
  }
  isManagementRole(role: CommunityUserStatus): boolean
  {
    return [CommunityUserStatus.ADMIN, CommunityUserStatus.ORGANIZER, CommunityUserStatus.SUBJECT].includes(role);
  }
  async getLoggedInUserCommunityRole(communityId: string): Promise<CommunityUserStatus>
  {
    let results: any[] = [];
    try {
      results = await Promise.all([
        this.getMembers([communityId], [CommunityUserStatus.ADMIN, CommunityUserStatus.ORGANIZER, CommunityUserStatus.SUBJECT, CommunityUserStatus.MEMBER]),
        this.userService.getLoggedInUser(),
        this.userService.isLoggedInUserAdmin()
      ]);
    } catch (ex) {
      log.error(ex);
    }

    if (results.length != 3) {
      throw `Unable to retrieve prerequisite data.`;
    }

    const managers: CommunityUser[] = results[0];
    const loggedInUser: User = results[1];
    const isAdmin: boolean = results[2];

    if (isAdmin) {
      return CommunityUserStatus.ADMIN;
    }

    const cus = managers.filter(m => m.user.id === loggedInUser.id);
    if (cus.length > 0) {
      return cus[0].status as CommunityUserStatus;
    }

    return CommunityUserStatus.REMOVED;
  }

  //this is for the GH community - workaround to allow a small group of members to view private (organizer visibility) videos
  // async getLoggedInUserCommunityGroup(communityID: string, userID: string): Promise<string>
  // {
  //   if (communityID) {
         
  //     const variables = {
  //       filter: {
  //         userID: {
  //           eq: userID
  //         },
  //         communityID: {
  //           eq: communityID
  //         }
  //       }
  //     };

  //     const list = await this.flq.queryFullList('listCommunityUsers', q.listCommunityUsers, variables);

  //     log.info(`entire list returned: ${JSON.stringify(list, null, 2)}`);
  //     log.info(`list[0].user.id: ${list[0].user.id}`);
  //     log.info(`list[0].group: ${list[0].group}`);

  //     return list[0].group != null ? list[0].group : "";
  //   }

  //   return "";
  // }
  canManageCommunityUser(roleInCommunity: CommunityUserStatus, loggedInUser: User, communityUser: CommunityUser): boolean
  {
    if (this.isManagementRole(roleInCommunity)) {
      if (communityUser.user.id === loggedInUser.id && communityUser.status === CommunityUserStatus.ORGANIZER) {
        return false;
      } else if (communityUser.status === CommunityUserStatus.SUBJECT && roleInCommunity != CommunityUserStatus.ADMIN) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  async getAllowedMembersByCommunity(communityIds: string[]): Promise<Dictionary<CommunityUser[]>>
  {
    const statuses = [
      CommunityUserStatus.MEMBER,
      CommunityUserStatus.SUBJECT,
      CommunityUserStatus.ORGANIZER,
      CommunityUserStatus.INVITED
    ];

    return await this.getMembersDictionary(communityIds, statuses);
  }
  async getJoinedMembersByCommunity(communityIds: string[]): Promise<Dictionary<CommunityUser[]>>
  {
    const statuses = [
      CommunityUserStatus.MEMBER,
      CommunityUserStatus.SUBJECT,
      CommunityUserStatus.ORGANIZER
    ];

    return await this.getMembersDictionary(communityIds, statuses);
  }
  async getJoinedMembers(communityIds: string[]): Promise<CommunityUser[]>
  {
    const statuses = [
      CommunityUserStatus.MEMBER,
      CommunityUserStatus.SUBJECT,
      CommunityUserStatus.ORGANIZER
    ];

    return await this.getMembers(communityIds, statuses);
  }

  async allSettled(promises: Promise<any>[])
    : Promise<({
        status: string;
        value: any;
    } | {
        status: string;
        reason: any;
    })[]> {
    return Promise.all(promises.map(promise => promise
        .then(value => ({ status: 'fulfilled', value }))
        .catch(reason => ({ status: 'rejected', reason }))
    ));
  }
  async getMembers(communityIds: string[], statuses: CommunityUserStatus[]): Promise<CommunityUser[]>
  {
    if (!communityIds || communityIds.length === 0 || communityIds.every(c => !c)) {
      return [];
    }

    const a: cip[] = [];
    communityIds.forEach(c => a.push({ communityID: { eq: c } }));

    const s: sip[] = [];
    statuses.forEach(status => s.push({ status: { eq: status } }));

    const variables = {
      filter: {
        and: [
          {
            or: a
          },
          {
            or: s
          }
        ]
      }
    };

    let list: CommunityUser[] = [];
    try {
      //list = await fullListQuerier('listCommunityUsers', q.listCommunityUsers, variables);
      list = await this.flq.queryFullList('listCommunityUsers', listCommunityUsersLite, variables);
    } catch (ex) {
      // if one user record has been DELETED completely from dynamo (which shouldn't really happen), then
      // our attempt above to get all membesr for all communities at once will fail...
      // so fallback to trying to get memebers one communtiy at a time, so SOME communities might get members...
      let promises: Promise<CommunityUser[]>[] = [];
      communityIds.forEach(id => {
        const variablesFallback = {
          filter: {
            and: [
              {
                communityID: { eq: id }
              },
              {
                or: s
              }
            ]
          } 
        }
        promises = [ ...promises, this.flq.queryFullList('listCommunityUsers', q.listCommunityUsers, variablesFallback) ]
      });

      const totalResult = await this.allSettled(promises);
      totalResult.forEach(r => {
        if (r.status == 'fulfilled') {
          list = [ ...list, ...r.value];
        }
      });
    }
    return list;
  }
  async removeMembers(
    members: CommunityUser[],
    communityId: string,
    failure: (devMessage: string, userMessage: string) => void): Promise<void>
  {
    const roleInCommunity = await this.getLoggedInUserCommunityRole(communityId);
    const u = await this.userService.getLoggedInUser();
    if (!this.isManagementRole(roleInCommunity)) {
      const userError = `You don't have permission to remove members from this community.`;
      const devError = `user ${u.id} tried to remove members from community ${communityId} -- how did this happen??`;
      failure(devError, userError);
      return;
    }

    const restricted = members.filter(m => !this.canManageCommunityUser(roleInCommunity, u, m));
    if (restricted.length > 0) {
      const userError = `You don't have permission to remove the following members from this community: ${restricted.map(r => r.user.firstName).join(', ')}`;
      const devError = `user ${u.id} tried to remove restricted members from community ${communityId} -- how did this happen??`;
      failure(devError, userError);
      return;
    }

    const c = await this.getSingleCommunity(communityId);

    if (c) {
      const mems = members.filter(cu => cu.community.id === c.id);

      if (mems.length !== members.length) {
        // warn?
      }


      log.info(`TRYING TO REMOVE MEMBERS:`);
      log.info(mems);

      // flag as removed
      await Promise.all(mems.map(async (cu) => {
        return this.api.graphql({
          query: m.updateCommunityUser,
          variables: {
            input: {
              id: cu.id,
              communityID: cu.community.id,
              userID: cu.user.id,
              joinedOn: cu.joinedOn,
              invitedByUserID: cu.invitedByUser.id,
              invitedOn: cu.invitedOn,
              status: CommunityUserStatus.REMOVED
            }
          }
        });
      }))
        .catch((reason) => failure(reason, 'Some members could not be removed. Please contact support@meetnovella.com for assistance.'));
    }
  }
  async assignEmailToPlaceholder(communityUser: CommunityUser, email: string, invitationMessage: string) : Promise<xAssignEmailToPlaceholder>
  {
    const input: CreateXAssignEmailToPlaceholderInput = {
      status: OperationStatus.REQUESTED,
      communityUserID: communityUser.id,
      communityID: communityUser.community.id,
      userID: communityUser.user.id,
      email: email,
      invitation: {
        invitationMessage: invitationMessage,
      },
    };

    const createResult: any = await this.api.graphql({
      query: m.createXAssignEmailToPlaceholder,
      variables: {
        input: input
      }
    });
    return createResult.data.createXAssignEmailToPlaceholder;
  }
  async mergePlaceholderAndSendInvitation(
    email: string,
    placeholder: CommunityUser,
    invitationMessage: string) : Promise<xAssignEmailToPlaceholder>
  {

    log.info(`MERGE: ${email}, ${placeholder.user.firstName}, ${invitationMessage}`);
    // throw `Not Implemented`;

    if (placeholder.user.status != UserStatus.PLACEHOLDER) {
      throw new CommunityServiceError(`Trying to merge a non-placeholder user: ${placeholder.user.id}`, `Oops, something went wrong!`);
    }

    if (!isValidEmail(email)) {
      throw new CommunityServiceError(`Must pass a valid email`, `Must pass a valid email`);
    }

    return await this.assignEmailToPlaceholder(placeholder, email, invitationMessage);
  }
  async inviteAndSendInvitations(
    userData: UserData[],
    communityId: string,
    status: CommunityUserStatus,
    invitationMessage: string): Promise<void>
  {
    log.info(`trying to invite ${userData.length} users: ${userData.map(d => d.email).join(', ')}`);
    if (userData.length === 0) {
      return;
    }

    log.info(`using status ${status} users`);
    if (status != CommunityUserStatus.INVITED && status != CommunityUserStatus.MEMBER && status != CommunityUserStatus.SUBJECT) {
      throw new CommunityServiceError('', `Can only invite users as "invited," "members," or "subjects"`);
    }

    let results: any[] = [];
    try {
      results = await Promise.all([
        this.getMembers([communityId], [CommunityUserStatus.ORGANIZER, CommunityUserStatus.SUBJECT, CommunityUserStatus.ADMIN, CommunityUserStatus.INVITED, CommunityUserStatus.MEMBER, CommunityUserStatus.BANNED]),
        this.userService.getLoggedInUser(),
        this.getSingleCommunity(communityId),
        this.userService.isLoggedInUserAdmin(),
      ]);
    } catch (ex) {
      log.error(ex);
    }

    if (results.length !== 4) {
      throw `Error retrieving prerequisite data`;
    }

    const currentMembers: CommunityUser[] = results[0];
    const loggedInUser: User = results[1];
    const community: Community = results[2];
    const isAdmin: boolean = results[3];
    log.info(loggedInUser);
    log.info(currentMembers);
    log.info(community);

    const maxLength = (community && community.limits && community.limits.maxInvitationLength) ? community.limits.maxInvitationLength : this.DEFAULT_MAX_INVITATION_MESSAGE_LENGTH;
    if (invitationMessage.length > maxLength) {
      throw new CommunityServiceError('', `Invitation message cannot exceed ${maxLength} characters.`);
    }

    // is the logged-in user an admin?
    let canInvite = isAdmin;

    // is the logged-in user an organizer or subject of this community?
    if (!canInvite) {
      canInvite = currentMembers.some(thing => (thing.status == CommunityUserStatus.ORGANIZER || thing.status == CommunityUserStatus.SUBJECT) && thing.user.id === loggedInUser.id);
    }

    log.info(`can invite: ${canInvite}`);

    if (!canInvite) {
      // Trying to do something you don't have permission to do...
      return;
    }

    // clean input emails to make them all lowercase
    userData.forEach(u => {
      u.email = u.email?.toLowerCase()
    })

    ////////
    // CREATE NECESSARY USER RECORDS
    ///////

    // don't create duplicate users!
    const a: ep[] = [];
    userData.forEach(i => {
      if (i.email && i.email.length > 0) {
        a.push({ email: { eq: i.email } });
      }
    });
    const variables = {
      filter: {
        or: a
      }
    };
    const list = a.length > 0 ? await this.flq.queryFullList('listUsers', q.listUsers, variables) : [];
    const matchedUsers: User[] = list;
    const matchedUserEmails: string[] = matchedUsers.map(m => m.email ?? ''); // already lowercase

    // Need to create User records for these
    // (either they have no email at all, in which case they are a placeholder, or they have an email but we found zero matches)
    const createdUsers: User[] = [];
    const newUsers: UserData[] = userData.filter(c => !c.email || !matchedUserEmails.includes(c.email));
    try {
      await Promise.all(newUsers.map(async (u) => {
        return this.userService.createUser(u.firstName, u.lastName, u.email ? UserStatus.REFERRED : UserStatus.PLACEHOLDER, u.email)
          .then((newUser) => {
            createdUsers.push(newUser);
          });
      }));
    } catch (ex) {
      log.error(ex);
      throw new CommunityServiceError('', `Unable to invite new users to Novella, please contact support@meetnovella.com`);
    }

    ////////
    // ADD USERS TO COMMUNITY
    ///////

    // at this point, createdUsers + matchedUsers == the full set of candidate users
    // log.info(`MATCHED USERS`);
    // log.info(matchedUsers);
    // log.info(`CREATED USERS`);
    // log.info(createdUsers);
    const candidateUsers: User[] = createdUsers.concat(matchedUsers);

    // look for existing members -- if we already have a member with a given email, don't do anything
    let existingMembers: CommunityUser[] = [];
    existingMembers = existingMembers.concat(currentMembers);
    // if (matchedUsers.length > 0) {
    //   const b: up[] = [];
    //   matchedUsers.map(i => b.push({ userID: { eq: i.id } }));
    //   const variables = {
    //     filter: {
    //       communityID: {
    //         eq: communityId
    //       },
    //       status: {
    //         ne: CommunityUserStatus.REMOVED
    //       },
    //       or: b
    //     }
    //   };
    //   const list = await fullListQuerier('listCommunityUsers', q.listCommunityUsers, variables);

    //   existingMembers = existingMembers.concat(list);
    // }
    // log.info(`EXISTING MEMBERS`);
    // log.info(existingMembers);
    const newMembers = findUsersSetDifference<User, CommunityUser>(candidateUsers, existingMembers, (item: User) => item, (item: CommunityUser) => item.user); // extract users to fit contract we already have
    const invitedDate = new Date(Date.now()).toISOString();

    // log.info(`NEW MEMBERS`);
    // log.info(newMembers);

    // create new community users with input status
    try{
      await Promise.all(newMembers.map(async (newbie) => {

        const input: CreateCommunityUserInput = {
          communityID: communityId,
          userID: newbie.id,
          joinedOn: invitedDate,
          invitedByUserID: loggedInUser.id,
          invitedOn: invitedDate,
          status: status,
          invitation: {
            invitationMessage: invitationMessage,
          }
        }

        return this.api.graphql({
          query: m.createCommunityUser,
          variables: {
            input: input
          }
        });
      }));
    } catch (ex) {
      log.error(ex);
      throw new CommunityServiceError('', `Unable to invite existing users, please contact support@meetnovella.com`);
    }
    // log.info(`ADDED MEMBERS`);
    // log.info(newMembers);

    // might be changing the status of existing members... this is allowed
    const existingAndCandidates: CommunityUser[] = findUsersSetIntersection<CommunityUser, User>(existingMembers, candidateUsers, (item: CommunityUser) => item.user, (item: User) => item);
    const modifiedMembers: CommunityUser[] = existingAndCandidates.filter(m => m.status != status);
    // modify existing community users to have the new input status
    try {
      await Promise.all(modifiedMembers.map(async (mod) => {
        return this.api.graphql({
          query: m.updateCommunityUser,
          variables: {
            input: {
              id: mod.id,
              communityID: mod.community.id,
              userID: mod.user.id,
              joinedOn: mod.joinedOn,
              invitedByUserID: mod.invitedByUser.id,
              invitedOn: mod.invitedOn,
              status: status,
              invitation: mod.invitation,
            }
          }
        });
      }))
    } catch (ex) {
      log.error(ex);
      throw new CommunityServiceError('', `Unable to update the status of existing users in the community, please contact support@meetnovella.com`);
    }
    // log.info(`MODIFIED MEMBERS`);
    // log.info(modifiedMembers);
  }
  async join(
    communityId: string): Promise<CommunityUserCommand[] | undefined>
  {
    let results: any[] = [];
    try {
      results = await Promise.all([
        this.getMembers([communityId], [CommunityUserStatus.ORGANIZER, CommunityUserStatus.SUBJECT, CommunityUserStatus.ADMIN, CommunityUserStatus.INVITED, CommunityUserStatus.MEMBER, CommunityUserStatus.BANNED]),
        this.userService.getLoggedInUser(),
        this.userService.isLoggedInUserAdmin(),
      ]);
    } catch (ex) {
      log.error(ex);
    }

    if (results.length !== 3) {
      throw `Error retrieving prerequisite data`;
    }

    const currentMembers: CommunityUser[] = results[0];
    const loggedInUser: User = results[1];
    const isAdmin: boolean = results[2];
    log.info(loggedInUser);
    log.info(currentMembers);
    log.info(isAdmin);

    ////////
    // ADD LOGGED-IN USER TO COMMUNITY
    ///////

    // at this point, createdUsers + matchedUsers == the full set of candidate users
    const candidateUsers: User[] = [ loggedInUser ];

    // look for existing members -- if we already have a member with a given email, don't do anything
    let existingMembers: CommunityUser[] = [];
    existingMembers = existingMembers.concat(currentMembers);

    log.info(`EXISTING MEMBERS`);
    log.info(existingMembers);
    const newMembers = findUsersSetDifference<User, CommunityUser>(candidateUsers, existingMembers, (item: User) => item, (item: CommunityUser) => item.user); // extract users to fit contract we already have
    const invitedDate = new Date(Date.now()).toISOString();

    log.info(`NEW MEMBERS`);
    log.info(newMembers);

    if (newMembers.length == 0) {
      // nothing to do...
      return;
    }

    // create new community users with input status
    let commands : CommunityUserCommand[];
    try{
      commands = await Promise.all(newMembers.map(async (newbie) => {

        const input: CreateCommunityUserCommandInput = {
          command: {
            operation: CommandOperation.CREATE,
          },
          communityID: communityId,
          userID: newbie.id,
          joinedOn: invitedDate,
          invitedByUserID: loggedInUser.id,
          invitedOn: invitedDate,
          status: CommunityUserStatus.MEMBER,
        }

        return this.api.graphql({
          query: m.createCommunityUserCommand,
          variables: {
            input: input
          }
        });
      }));
    } catch (ex) {
      log.error(ex);
      throw new CommunityServiceError('', `Unable to invite existing users, please contact support@meetnovella.com`);
    }
    log.info(`REQUESTED TO ADD NEW MEMBERS`);
    log.info(newMembers);
    return commands;
  }
  async getQuestions(communityId: string): Promise<CommunityQuestion[]>
  {
    if (!communityId) {
      return [];
    }
    const variables = {
      filter: {
        communityID: {
          eq: communityId
        },
        status: {
          ne: CommunityQuestionStatus.DELETED
        }
      }
    };
    const list = await this.flq.queryFullList('listCommunityQuestions', cq.listCommunityQuestions2, variables);
    return list;
  }
  async getCommunityForActivityHistory(communityId: string): Promise<Community|undefined> {
    if (!communityId) {
      return;
    }
    const getResult: any = await this.api.graphql({
      query: cq.getCommunityForActivityHistory,
      variables: {
        id: communityId
      }
    })
    const community: Community = getResult.data.getCommunity;
    if (!community) {
      log.error(`Tried to get non-existent Community with id: ${communityId}`);
    }
    return community;
  }
  async deleteExistingQuestion(
    communityQuestionId: string,
    failure: (devMessage: string, userMessage: string) => void): Promise<void>
  {
    const cq = await this.getQuestion(communityQuestionId);
    if (cq) {
      if (await this.hasAnswer(communityQuestionId)) {
        log.error("NICK, we need to only allow deleting answered questions if the logged-in user is in the NovellaAdmin group!");
      }

      // Now we have everything we need to update the existing question
      await this.api.graphql({
        query: m.updateCommunityQuestion,
        variables: {
          input: {
            id: cq.id,
            communityID: cq.community.id,
            userID: cq.user.id,
            categoryID: cq.category?.id,
            date: cq.date,
            status: CommunityQuestionStatus.DELETED,
            source: cq.source,
            questionText: cq.questionText,
            clonedFromCommunityQuestionRecommendationId: cq.clonedFromCommunityQuestionRecommendationId
          }
        }
      });
    } else {
      // should not happen
      const error = `trying to delete question that can't be found: ${communityQuestionId}`;
      failure(error, '');
    }
  }
  async saveExistingQuestion(
    questionText: string,
    categoryName: string,
    communityQuestionId: string,
    imageKeys: string[]): Promise<void>
  {
    // questions are "owned" by communities
    // question text cannot be changed once voted on or answered
    // question cannot be deleted once voted on or answered (except by admin)
    // aggregation of questions across communities happens downstream (e.g., DynamoDB Streams)

    const results = await Promise.all([
      this.categoryService.getValidCategory(categoryName),
      this.getQuestion(communityQuestionId)
    ]);

    const category = results[0];
    const cq = results[1];

    if (cq) {
      // Modifying an existing communityQuestion

      if (cq.questionText === questionText && (cq.category?.name === categoryName || cq.communityCategory == categoryName) && arraysEqual(cq.imageKeys ?? [], imageKeys)) {
        // nothing changed, so do nothing
        return;
      }

      if (await this.hasAnswer(communityQuestionId)) {
        // cannot edit a question that's already been answered! -- ask a new one!
        const error = `This question has already been answered and can't be changed. Please create a new question.`
        throw new CommunityServiceError('', error);
      }

      const matching = await this.getQuestionsWithSameText(cq.community.id, communityQuestionId, [questionText]);
      //log.info(matching);
      if (matching && matching.length > 0) {
        // the question was already asked
        const error = `Someone has already asked this question. Please ask something else.`
        throw new CommunityServiceError('', error);
      }

      if (!category && cq.community) {
        // this must be a community-specific category
        await this.addCommunityCategory(cq.community, categoryName);
      }

      const input: UpdateCommunityQuestionInput = {
        id: cq.id,
        communityID: cq.community.id,
        userID: cq.user.id,
        categoryID: category?.id,
        communityCategory: category ? null : categoryName,
        date: cq.date,
        status: cq.status,
        source: cq.source,
        questionText: questionText,
        clonedFromCommunityQuestionRecommendationId: cq.clonedFromCommunityQuestionRecommendationId,
        imageKeys: imageKeys,
      }

      await this.api.graphql({
        query: m.updateCommunityQuestion,
        variables: {
          input: input
        }
      });
    }
  }
  async getQuestionsWithSameText(
    communityId: string,
    communityQuestionId: string,
    questionText: string[]): Promise<CommunityQuestion[]>
  {
    if (!questionText || questionText.length === 0) {
      return [];
    }

    const a: cqtp[] = [];
    questionText.map(i => a.push({ questionText: { eq: i } }));
    const variables = {
      filter: {
        id: {
          ne: communityQuestionId
        },
        communityID: {
          eq: communityId
        },
        status: {
          ne: CommunityQuestionStatus.DELETED
        },
        or: a
      }
    };
    const list = await this.flq.queryFullList('listCommunityQuestions', q.listCommunityQuestions, variables);
    return list;
  }
  async saveNewQuestion(
    questionText: string,
    categoryName: string,
    communityId: string,
    source: CommunityQuestionSource,
    imageKeys: string[]): Promise<void>
  {
    // questions are "owned" by communities
    // question text cannot be changed once voted on or answered
    // question cannot be deleted once voted on or answered (except by admin)
    // aggregation of questions across communities happens downstream (e.g., DynamoDB Streams)

    try {
      const results = await Promise.all([
        this.categoryService.getValidCategory(categoryName),
        this.getSingleCommunity(communityId),
        this.userService.getLoggedInUser(),
      ]);

      const category = results[0];
      const community = results[1];
      const luser = results[2];

      if (community && luser) {
        // Creating a new communityQuestion for existing community
  
        const matching = await this.getQuestionsWithSameText(communityId, '', [questionText]);
        //log.info(matching);
        if (matching && matching.length > 0) {
          // the question was already asked
          const error = `Someone has already asked this question. Please ask something else.`
          throw new CommunityServiceError('', error);
        }

        if (!category && community) {
          // this must be a community-specific category
          await this.addCommunityCategory(community, categoryName);
        }

        await this.api.graphql({
          query: m.createCommunityQuestion,
          variables: {
            input: {
              communityID: community.id,
              userID: luser.id,
              categoryID: category ? category.id : '',
              communityCategory: category ? null : categoryName,
              date: new Date(Date.now()).toISOString(),
              status: CommunityQuestionStatus.ADDED,
              source: source,
              questionText: questionText,
              imageKeys: imageKeys
            }
          }
        });
      }
    } catch (ex) {
      log.error(`Failed to get prerequisites: category = '${categoryName}', communityId = '${communityId}'`);
      log.error(ex);
      return;
    }
  }
  private async addCommunityCategory(community: Community, categoryName: string) {
    let cc = [];
    if (!community.communityCategories) {
      cc = [categoryName];
      await this.setCommunityCategories(community.id, cc);
    } else {
      cc = [...community.communityCategories];
      if (!cc.map(c => c.toLowerCase()).includes(categoryName.toLowerCase())) {
        cc = [...cc, categoryName];
        await this.setCommunityCategories(community.id, cc);
      }
    }
  }
  async toggleCommunityQuestionLike(communityQuestion: CommunityQuestion): Promise<void>
  {
    if (communityQuestion) {
      const user = await this.userService.getLoggedInUser();
      const variables = {
        filter: {
          userID: {
            eq: user.id
          },
          communityQuestionID: {
            eq: communityQuestion.id
          },
          status: {
            eq: CommunityQuestionLikeStatus.ADDED
          }
        }
      };

      const list = await this.flq.queryFullList('listCommunityQuestionLikes', q.listCommunityQuestionLikes, variables);

      if (!list || list.length === 0) {
        const createdDate = new Date(Date.now()).toISOString();
        // add new like as ADDED
        // try {
        await this.api.graphql({
          query: m.createCommunityQuestionLike,
          variables: {
            input: {
              userID: user.id,
              communityQuestionID: communityQuestion.id,
              status: CommunityQuestionLikeStatus.ADDED,
              date: createdDate,
            }
          }
        })
        // } catch (ex) {
        //   //log.info(ex);
        //   throw ex;
        // }
      } else {
        // flag all existing as DELETED
        const stuff = await Promise.all(list.map(async (l) => {
          return this.api.graphql({
            query: m.updateCommunityQuestionLike,
            variables: {
              input: {
                id: l.id,
                userID: l.user.id,
                communityQuestionID: l.communityQuestion.id,
                status: CommunityQuestionLikeStatus.DELETED,
                date: l.date,
              }
            }
          });
        }))
          .catch((reason) => { throw new CommunityServiceError(reason, ''); });
      }
    }
  }
  async acceptRecommendations(
    recommendations: CommunityQuestionRecommendation[],
    communityId: string,
    source: CommunityQuestionSource): Promise<void>
  {
    // questions are "owned" by communities
    // question text cannot be changed once voted on or answered
    // question cannot be deleted once voted on or answered (except by admin)
    // aggregation of questions across communities happens downstream (e.g., DynamoDB Streams)

    const loggedInUser = await this.userService.getLoggedInUser();
    const c = await this.getSingleCommunity(communityId);

    if (c) {
      const date = new Date(Date.now()).toISOString();
      const recs = recommendations.filter(r => r.community.id === c.id);

      if (recs.length !== recommendations.length) {
        // warn?
        // we got a recommendation for a different community
      }

      const final: CommunityQuestionRecommendation[] = [];
      const matching = (await this.getQuestionsWithSameText(communityId, '', recs.map(r => r.questionText))).map(m => m.questionText);
      recs.forEach(r => {
        // only accept the rec if there's no existing question with identical text
        if (!matching.includes(r.questionText)) {
          final.push(r);
        }
      });

      // Creating a new communityQuestion for existing community
      await Promise.all(final.map(async (r) => {
        return this.api.graphql({
          query: m.createCommunityQuestion,
          variables: {
            input: {
              communityID: r.community.id,
              userID: loggedInUser.id,
              categoryID: r.category.id,
              date: date,
              status: CommunityQuestionStatus.ADDED,
              source: source,
              questionText: r.questionText,
              clonedFromCommunityQuestionRecommendationId: r.id
            }
          }
        });
      }))
        .catch((reason) => { throw new CommunityServiceError(reason, ''); });
    }
  }
  async getSingleCommunity(communityId: string): Promise<Community | undefined>
  {
    if (!communityId) {
      return;
    }

    const getResult: any = await this.api.graphql({
      query: q.getCommunity,
      variables: {
        id: communityId
      }
    })
    const existing: Community = getResult.data.getCommunity;
    if (!existing) {
      log.error(`Tried to get non-existent Community with id: ${communityId}`);
    }
    return existing;
  }
  async getLikeMapForQuestions(items: CommunityQuestion[]): Promise<LikeMap>
  {
    const dict: LikeMap = {};
    if (!items || items.length === 0) {
      return dict;
    }
    const user = await this.userService.getLoggedInUser();
    const a: cqip[] = [];
    items.map(i => a.push({ communityQuestionID: { eq: i.id } }));

    const variables = {
      filter: {
        status: {
          eq: CommunityQuestionLikeStatus.ADDED
        },
        or: a
      }
    };

    const list = await this.flq.queryFullList('listCommunityQuestionLikes', q.listCommunityQuestionLikes, variables);

    list.forEach(l => {
      if (!dict[l.communityQuestion.id]) {
        dict[l.communityQuestion.id] = new LikeData(false, 0);
      }
      if (l.user.id === user.id) {
        dict[l.communityQuestion.id].like(true);
      } else {
        dict[l.communityQuestion.id].totalLikes++;
      }
    });
    return dict;
  }
  buildUnauthenticatedLikeMapForQuestions(questions: CommunityQuestion[], userFirstName: string, userLastName: string) : LikeMap
  {
    const dict: LikeMap = {};
    questions.forEach(q => {
      
      if (!dict[q.id]) {
        dict[q.id] = new LikeData(false, 0);
      }

      if (q.communityQuestionLikesByStatus && q.communityQuestionLikesByStatus.items.length > 0) {

        q.communityQuestionLikesByStatus.items.forEach(l => {
          if (l?.user.firstName.toLowerCase() == userFirstName.toLowerCase() && l?.user.lastName.toLowerCase() == userLastName.toLowerCase()) {
            dict[q.id].like(true);
          } else {
            dict[q.id].totalLikes++;
          }
        });
      }
    });
    return dict;
  }
  async updateCommunityWithVimeoVideo(communityId: string, vimeoVideoMetadata: VimeoVideoMetadata | null) : Promise<any>
  {
    const existing = await this.getSingleCommunity(communityId);
    if (!existing) {
      throw new Error(`Tried to update non-existent Community with id: ${communityId}`);
    }

    let newVimeoVideos: string[] = [];
    if (vimeoVideoMetadata != null) {
      newVimeoVideos =  [ JSON.stringify(vimeoVideoMetadata) ];
    }

    // FOR NOW, WE ONLY ALLOW ONE
    // if (existing.vimeoVideos) {
    //   if (existing.vimeoVideos.some(x => x == vimeoVideoMetadata)) {
    //     return null;
    //   }  
    //   newVimeoVideos = [ ...existing.vimeoVideos, JSON.stringify(vimeoVideoMetadata) ];
    // }

    const input: CreateCommunityCommandInput = {
      command: { operation: CommandOperation.UPDATE_PARTIAL },
      communityID: communityId,
      vimeoVideos: newVimeoVideos,
    };

    const command = await this.sendCommunityCommand(input);

    return command;
  }
  async getCommunityCategories(communityId: string) : Promise<string[]>
  {
    if (!communityId || communityId.length == 0) {
      return [];
    }
    const existing = await this.getSingleCommunity(communityId);
    if (!existing) {
      return [];
    }

    if (!existing.communityCategories || existing.communityCategories == null) {
      return [];
    }

    return existing.communityCategories;
  }
  async setCommunityCategories(communityId: string, communityCategories: string[]) : Promise<any>
  {
    const existing = await this.getSingleCommunity(communityId);
    if (!existing) {
      throw new Error(`Tried to update non-existent Community with id: ${communityId}`);
    }

    const input: CreateCommunityCommandInput = {
      command: { operation: CommandOperation.UPDATE_PARTIAL },
      communityID: communityId,
      communityCategories: communityCategories,
    };

    const command = await this.sendCommunityCommand(input);

    return command;
  }
  sort(members: CommunityUser[], by: string, asc: boolean) : CommunityUser[]
  {
    return members.sort((a, b) => {
      return this.compareCommunityUsers(a, b, by, asc);
    });
  }
  //don't export
  async getMembersDictionary(communityIds: string[], statuses: CommunityUserStatus[]): Promise<Dictionary<CommunityUser[]>>
  {
    const dict: Dictionary<CommunityUser[]> = {};
    communityIds.forEach(c => {
      if (!dict[c]) {
        dict[c] = [];
      }
    });

    const list = await this.getMembers(communityIds, statuses);
    list.forEach((cu: CommunityUser) => {
      if (!dict[cu.community.id]) {
        dict[cu.community.id] = [];
      }
      dict[cu.community.id].push(cu);
    });
    return dict;
  }
  //don't export
  async hasAnswer(communityQuestionId: string): Promise<boolean>
  {
    const variables = {
      filter: {
        communityQuestionID: {
          eq: communityQuestionId
        },
        status: {
          ne: CommunityQuestionAnswerStatus.DELETED
        }
      }
    };
    const list = await this.flq.queryFullList('listCommunityQuestionAnswers', q.listCommunityQuestionAnswers, variables);
    //log.info(result1);
    return list.length > 0;
  }
  async getQuestion(communityQuestionId: string): Promise<CommunityQuestion | undefined>
  {
    const variables = {
      filter: {
        id: {
          eq: communityQuestionId
        },
        status: {
          ne: CommunityQuestionStatus.DELETED
        }
      }
    };
    const list = await this.flq.queryFullList('listCommunityQuestions', q.listCommunityQuestions, variables);
    const questions = list;

    if (questions.length === 0) {
      // ERROR
      const error = `looking for communityQuestionId that does not exist: ${communityQuestionId}`;
      log.error(error);
      throw new CommunityServiceError(error, '');
    }

    if (questions.length > 1) {
      // ERROR
      const error = `found ${questions.length} communityQuestions with same id: ${communityQuestionId}`;
      log.error(error);
      throw new CommunityServiceError(error, '');
    }

    return questions[0];
  }
  //don't export
  async sendCommunityCommand(input: CreateCommunityCommandInput) : Promise<CommunityCommand>
  {
    const createResult: any = await this.api.graphql({
      query: m.createCommunityCommand,
      variables: {
        input: input
      }
    });
    return createResult.data.createCommunityCommand;
  }
}

const listCommunitiesLite = /* GraphQL */ `
  query ListCommunities(
    $filter: ModelCommunityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        imageKey
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
        }
        vimeoVideos
        createdAt
        updatedAt
        owner
        type
      }
      nextToken
    }
  }
`;

const listCommunityUsersLite = /* GraphQL */ `
  query ListCommunityUsers(
    $filter: ModelCommunityUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        communityID
        userID
        joinedOn
        invitedByUserID
        invitedOn
        invitation {
          invitationMessage
        }
        status
        owner
        group
        createdAt
        updatedAt
        community {
          id
          name
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
        }
        invitedByUser {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          referralCode
        }
      }
      nextToken
    }
  }
`;