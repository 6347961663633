import React, { useContext, useEffect, useState } from 'react';
import { Image, ImageBackground, LayoutChangeEvent, Platform, SectionList, Text, View } from 'react-native';
import CommonContext from '../../../../CommonContext';
import { CommonContextType } from '../../../../types/CommonContextType';
import { RootStackParamList } from '../../../../types/Navigation';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import { DrawerScreenProps } from '@react-navigation/drawer';
import { getCommunityPreview } from '../../../../business/public/communityPreview';
import { normalizeHeight, normalizeWidth } from '../../../../business/layout/responseSize';
import NText from '../../../components/NText';
import { Palette } from '../../../../Theme';
import { TABLET_BREAK, useSafeAreaInsets } from '../../../../business/layout/layout';
import i18n from 'i18n-js';
import { getNote } from '../../../../business/user/communityUserHelper';
import log from '../../../../business/logging/logger';
import { Community, CommunityQuestion, CommunityUser } from '../../../../API';
import NButton from '../../../components/NButton';
import AskedQuestionCard from '../../../components/AskedQuestionCard';
import { LikeMap } from '../../../../services/CommunityService';
import AboutNovella from './AboutNovella';
import Panel from './Panel';
import NActivityIndicator from '../../../components/ActivityIndicators/NActivityIndicator';
import { autoJoin } from '../../../../business/public/autoJoin';
import { setSignInRedirect } from '../../../../business/redirect/redirect';
import PromptModal from '../../../components/Modals/PromptModal';
import CategoryButton from '../../../components/CategoryButton';
import { extractQuestionSections, QuestionSection } from '../../../../business/sections/questionSectionHelper';
import { WEBSITE_HEADER_HEIGHT } from '../../../../constants/header';
import WebHeaderMimic from '../../../components/WebHeaderMimic';
import { isGregHowellCommunity } from '../../../../business/gregHowell/gregHowellHelper';

export type PreviewProps = DrawerScreenProps<RootStackParamList, 'Community Preview'>;

const expanded: Set<string> = new Set<string>();

function CommunityPreview(props: PreviewProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const { communityService, userService } = commonContext.services;
  const themeFromContext = commonContext.theme;
  const [headerHeight, setHeaderHeight] = useState(WEBSITE_HEADER_HEIGHT);
  const { top } = useSafeAreaInsets();
  const { width, height } = commonContext.dimensions;
  const { route, navigation } = props;
  const [refreshing, setRefreshing] = useState(true);
  const linkTo = useLinkTo();
  const isFocus = useIsFocused();
  const [community, setCommunity] = useState<Community>();
  const [members, setMembers] = useState<CommunityUser[]>();
  const [error, setError] = useState('');
  const [customNote, setCustomNote] = useState('');
  //const [questions, setQuestions] = useState<CommunityQuestion[]>();
  const [questionSections, setQuestionSections] = useState<QuestionSection[]>([]);
  const [likeMap, setLikeMap] = useState<LikeMap>()
  const [organizerFullName, setOrganizerFullName] = useState('');
  const [columnHeight, setColumnHeight] = useState(0);
  const [joinButtonRefreshing, setJoinButtonRefreshing] = useState(false);
  const [askButtonRefreshing, setAskButtonRefreshing] = useState(false);
  const [watchButtonRefreshing, setWatchButtonRefreshing] = useState(false);
  const [showNavigateAwayModal, setShowNavigateAwayModal] = useState(false);
  const [modalHeading, setModalHeading] = useState('');
  const [modalMessage, setModalMessage] = useState('');
  const [modalPrimaryButtonText, setModalPrimaryButtonText] = useState('');
  const [modalSecondaryButtonText, setModalSecondaryButtonText] = useState<string | undefined>();
  const [modalPrimaryDestination, setModalPrimaryDestination] = useState('');
  const [modalSecondaryDestination, setModalSecondaryDestination] = useState<string | undefined>();
  const [refresh, setRefresh] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    setError('');
    setRefreshing(true);
    setJoinButtonRefreshing(true);
    setAskButtonRefreshing(true);
    if (isFocus) {
      configModal('', '', '', false, '');
      getCommunityPreview(route.params.communityId)
        .then(c => {
          if (c) {
            setCommunity(c);
            log.info(`GOT PREVIEW USERS:`)
            log.info(JSON.stringify(c.communityUsersByStatusDate, null, 2));
            if (c.communityUsersByStatusDate) {
              const m = c.communityUsersByStatusDate.items.filter(x => x != null);
              setMembers(m);
              if (m) {
                const n = findLatestCustomNote(m);
                log.info(n);
                setCustomNote(n);
                let o = m.find(x => x?.status == 'ORGANIZER');
                if (!o) {
                  o = m.find(x => x?.status == 'SUBJECT');
                }
                if (o) {
                  const n = userService.getFullName(o.user);
                  setOrganizerFullName(n);
                }
              }
            }
            log.info(`GOT PREVIEW QUESTIONS:`)
            log.info(JSON.stringify(c.communityQuestionsByStatusDate, null, 2));
            if (c.communityQuestionsByStatusDate) {
              const q = c.communityQuestionsByStatusDate.items.filter(x => x != null);
              const stuff = communityService.buildUnauthenticatedLikeMapForQuestions(q, '', '');
              setLikeMap(stuff);
              const sections = extractQuestionSections(communityService, q, stuff, 'category', false, c);
              sections.forEach(s => {
                expanded.add(s.title);
              })
              setQuestionSections(sections);
            }
          } else {
            setError(`Sorry, we couldn't find that page!`);
          }
          setRefreshing(false);
          setJoinButtonRefreshing(false);
          setAskButtonRefreshing(false);
        })
        .catch(err => {
          setError(err);
        });
    }
  }, [isFocus, route.params.communityId]);

  const findLatestCustomNote = (members: (CommunityUser | null)[]): string => {
    const copy: CommunityUser[] = members.filter(m => m != null);
    const sorted = communityService.sort(copy, 'createdAt', false);
    let note = '';
    // log.info(`LOOKING FOR LAST CUSTOM NOTE`);
    // log.info(`FROM USER: ${author.firstName}`);
    for (let i = 0; i < sorted.length; i++) {
      const n = getNote(sorted[i])
      if (n != '') {
        note = n;
        break;
      }
    }
    return note;
  }

  const toggleLikePost = async (question: CommunityQuestion): Promise<void> => {
    try {
      log.info(question);
      if (questionSections) {
        await communityService.toggleCommunityQuestionLike(question);
        const map = await communityService.getLikeMapForQuestions(questionSections.map(x => x.data).flat());
        setLikeMap(map);
      }
    } catch (ex) {
      log.info(ex);
      //await toggleLikeFailed(question);
    }
  }

  const layoutColumn = (event: LayoutChangeEvent) => {
    if (isFocus) {
      const h = event.nativeEvent.layout.height;
      log.info('column height: ' + h);
      setColumnHeight(h);
    }
  }

  const clickJoin = async () => {
    if (commonContext.loggedInUser) {
      const luid = commonContext.loggedInUser.id;
      log.info(members);
      log.info(commonContext.loggedInUser);
      const member = members?.find(m => m.user.id == luid);
      if (!member && community) {
        // add user to members

        // already logged-in, but not yet a member
        setJoinButtonRefreshing(true);
        await autoJoin(userService, communityService, community.id, () => {
          configModal(i18n.t('Page_MemberPreview_Modal_Header_GoingIn'), i18n.t('Page_MemberPreview_Join_Modal_Body_Join_Authenticated'), i18n.t('Page_MemberPreview_Modal_Ok'), true, `/community/${community.id}/questions/asked/${community.id}`); // go to community 
        });
      } else {

        // already logged in AND already a member
        configModal(i18n.t('Page_MemberPreview_Modal_Header_GoingIn'), i18n.t('Page_MemberPreview_Join_Modal_Body_Already_Member'), i18n.t('Page_MemberPreview_Modal_Ok'), true, `/community/${community?.id}/questions/asked/${community?.id}`);
      }
    } else {
      // not logged-in yet
      await setSignInRedirect(`/community/${community?.id}/questions/asked/${community?.id}`);
      configModal(i18n.t('Page_MemberPreview_Modal_Header_Login'), i18n.t('Page_MemberPreview_Join_Modal_Body_Login'), i18n.t('Page_MemberPreview_Modal_SignIn'), true, `/signin?c=${community?.id}`, i18n.t('Page_MemberPreview_Modal_CreateAccount'), `/signup?c=${community?.id}`);
    }
  }

  const clickAsk = async () => {
    if (commonContext.loggedInUser) {
      const luid = commonContext.loggedInUser.id;
      log.info(members);
      log.info(commonContext.loggedInUser);
      const member = members?.find(m => m.user.id == luid);
      if (!member && community) {
        // add user to members

        // already logged-in, but not yet a member
        setAskButtonRefreshing(true);
        await autoJoin(userService, communityService, community.id, () => {
          configModal(i18n.t('Page_MemberPreview_Modal_Header_GoingIn'), i18n.t('Page_MemberPreview_Ask_Modal_Body_Join_Authenticated'), i18n.t('Page_MemberPreview_Modal_Ok'), true, `/community/${community.id}/questions/asked/${community.id}/new`); // go to community 
        });
      } else {
        // already logged in AND already a member
        configModal(i18n.t('Page_MemberPreview_Modal_Header_GoingIn'), i18n.t('Page_MemberPreview_Ask_Modal_Body_Join_AlreadyMember'), i18n.t('Page_MemberPreview_Modal_Ok'), true, `/community/${community?.id}/questions/asked/${community?.id}/new`);
      }
    } else {
      // not logged-in yet
      await setSignInRedirect(`/community/${community?.id}/questions/asked/${community?.id}/new`);
      configModal(i18n.t('Page_MemberPreview_Modal_Header_Login'), i18n.t('Page_MemberPreview_Ask_Modal_Body_Login'), i18n.t('Page_MemberPreview_Modal_SignIn'), true, `/signin?c=${community?.id}`, i18n.t('Page_MemberPreview_Modal_CreateAccount'), `/signup?c=${community?.id}`);
    }
  }

  const toggleLikePre = async (question: CommunityQuestion): Promise<void> => {
    if (commonContext.loggedInUser) {
      const luid = commonContext.loggedInUser.id;
      log.info(members);
      log.info(commonContext.loggedInUser);
      const member = members?.find(m => m.user.id == luid);
      if (!member && community) {
        // add user to members

        // already logged-in, but not yet a member
        setAskButtonRefreshing(true);
        await autoJoin(userService, communityService, community.id, () => {
          toggleLikePost(question).then(() => {
            setAskButtonRefreshing(false);  
          });
          
          //configModal(i18n.t('Page_MemberPreview_Modal_Header_GoingIn'), i18n.t('Page_MemberPreview_Ask_Modal_Body_Join_Authenticated'), i18n.t('Page_MemberPreview_Modal_Ok'), true, `/community/${community.id}/asked/${community.id}/new`); // go to community 
        });
      } else {
        // already logged in AND already a member
        await toggleLikePost(question);
      }
    } else {
      // not logged-in yet
      await setSignInRedirect(`/community/${community?.id}/questions/asked/${community?.id}`);
      configModal(i18n.t('Page_MemberPreview_Modal_Header_Login'), i18n.t('Page_MemberPreview_Like_Modal_Body_Login'), i18n.t('Page_MemberPreview_Modal_SignIn'), true, `/signin?c=${community?.id}`, i18n.t('Page_MemberPreview_Modal_CreateAccount'), `/signup?c=${community?.id}`);
    }
  }

  const clickWatchVideosLink = async () : Promise<void>=> {
    log.info(`/community/${community?.id}/questions/asked/${community?.id}`);
    linkTo(`/community/${community?.id}/questions/asked/${community?.id}`);
  }

  const watchVideosPre = async (): Promise<void> => {
    if (commonContext.loggedInUser) {
      const luid = commonContext.loggedInUser.id;
      log.info(members);
      log.info(commonContext.loggedInUser);
      const member = members?.find(m => m.user.id == luid);
      if (!member && community) {
        // add user to members

        // already logged-in, but not yet a member
        setWatchButtonRefreshing(true);
        await autoJoin(userService, communityService, community.id, () => {
          clickWatchVideosLink().then(() => {
            setWatchButtonRefreshing(false);
          });
          //configModal(i18n.t('Page_MemberPreview_Modal_Header_GoingIn'), i18n.t('Page_MemberPreview_Ask_Modal_Body_Join_Authenticated'), i18n.t('Page_MemberPreview_Modal_Ok'), true, `/community/${community.id}/asked/${community.id}/new`); // go to community 
        });
      } else {
        // already logged in AND already a member
        await clickWatchVideosLink();
      }
    } else {
      // not logged-in yet
      setWatchButtonRefreshing(true);
      await setSignInRedirect(`/community/${community?.id}/questions/asked/${community?.id}`);
      configModal(i18n.t('Page_MemberPreview_Modal_Header_Login'), i18n.t('Page_MemberPreview_ViewAnswers_Modal_Body_Login'), i18n.t('Page_MemberPreview_Modal_SignIn'), true, `/signin?c=${community?.id}`, i18n.t('Page_MemberPreview_Modal_CreateAccount'), `/signup?c=${community?.id}`);
      setWatchButtonRefreshing(false);
    }
  }

  const clickAnswerLink = async (question: CommunityQuestion) : Promise<void>=> {
    log.info(`/community/${community?.id}/questions/answers/${question.id}`);
    linkTo(`/community/${community?.id}/questions/answers/${question.id}`);
  }

  const clickAnswerPre = async (question: CommunityQuestion): Promise<void> => {
    if (commonContext.loggedInUser) {
      const luid = commonContext.loggedInUser.id;
      log.info(members);
      log.info(commonContext.loggedInUser);
      const member = members?.find(m => m.user.id == luid);
      if (!member && community) {
        // add user to members

        // already logged-in, but not yet a member
        //setAskButtonRefreshing(true);
        await autoJoin(userService, communityService, community.id, () => {
          clickAnswerLink(question);
        });
      } else {
        // already logged in AND already a member
        await clickAnswerLink(question);
      }
    } else {
      // not logged-in yet
      await setSignInRedirect(`/community/${community?.id}/questions/answers/${question.id}`);
      configModal(i18n.t('Page_MemberPreview_Modal_Header_Login'), i18n.t('Page_MemberPreview_ViewAnswers_Modal_Body_Login'), i18n.t('Page_MemberPreview_Modal_SignIn'), true, `/signin?c=${community?.id}`, i18n.t('Page_MemberPreview_Modal_CreateAccount'), `/signup?c=${community?.id}`);
    }
  }

  const clickRecordLink = async (question: CommunityQuestion) : Promise<void>=> {
    log.info(`/answer/${question.id}/`);
    linkTo(`/answer/${question.id}/`);
  }

  const clickRecordPre = async (question: CommunityQuestion): Promise<void> => {
    if (commonContext.loggedInUser) {
      const luid = commonContext.loggedInUser.id;
      log.info(members);
      log.info(commonContext.loggedInUser);
      const member = members?.find(m => m.user.id == luid);
      if (!member && community) {
        // add user to members

        // already logged-in, but not yet a member
        //setAskButtonRefreshing(true);
        await autoJoin(userService, communityService, community.id, () => {
          clickRecordLink(question);
        });
      } else {
        // already logged in AND already a member
        await clickRecordLink(question);
      }
    } else {
      // not logged-in yet
      await setSignInRedirect(`/answer/${question.id}/`);
      configModal(i18n.t('Page_MemberPreview_Modal_Header_Login'), i18n.t('Page_MemberPreview_ViewAnswers_Modal_Body_Login'), i18n.t('Page_MemberPreview_Modal_SignIn'), true, `/signin?c=${community?.id}`, i18n.t('Page_MemberPreview_Modal_CreateAccount'), `/signup?c=${community?.id}`);
    }
  }

  const configModal = (heading: string, message: string, primaryButtonText: string, show: boolean, primaryDestination: string, secondaryButtonText?: string, secondaryDestination?: string) => {
    setShowNavigateAwayModal(show);
    setModalHeading(heading);
    setModalMessage(message);
    setModalPrimaryButtonText(primaryButtonText);
    setModalPrimaryDestination(primaryDestination);
    setModalSecondaryButtonText(secondaryButtonText);
    setModalSecondaryDestination(secondaryDestination);
  }

  const modalGoPrimary = () => {
    linkTo(modalPrimaryDestination);
  }

  const modalGoSecondary = () => {
    linkTo(modalSecondaryDestination);
  }

  const toggleExpanded = (sectionTitle: string) => {
    if (!expanded.has(sectionTitle)) {
      expanded.add(sectionTitle);
    } else {
      expanded.delete(sectionTitle);
    }
    setRefresh(!refresh);
  }

  const onLayout = (event: LayoutChangeEvent) => {
    setContentHeight(event.nativeEvent.layout.height);
  }

  return (
    <>
      <WebHeaderMimic navigation={props.navigation} />
      {
        error.length > 0 &&
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: normalizeWidth(10, width),
            //height: height - top - headerHeight,
            top: top + headerHeight,
            maxWidth: width < TABLET_BREAK ? '100%' : 1200,
            alignSelf: 'center',
          }}
          testID={`error-container`}
        >
          <NText
            style={{
              ...themeFromContext.textVariants.body,
              color: Palette.red,
            }}
          >
            {error}
          </NText>
        </View>
      }
      {
        error.length == 0 &&
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: normalizeWidth(10, width),
            //height: height - top - headerHeight,
            top: top + headerHeight,
            maxWidth: width < TABLET_BREAK ? '100%' : 1200,
            alignSelf: 'center',
          }}
          testID={`top-container`}
          onLayout={onLayout}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              //top: headerHeight,
              //flex: 1,
            }}
          >

            {/** HEADER */}
            <ImageBackground
              source={(community?.publicPreviewHeaderURL == undefined || community?.publicPreviewHeaderURL == null) ? {} : { uri: community?.publicPreviewHeaderURL }}
              resizeMode='cover'
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: Palette.faintgrey,
                borderWidth: 1,
                borderColor: Palette.grey,
                height: normalizeHeight(110, height),
                justifyContent: "center",
                borderRadius: 15,
                overflow: 'hidden',
              }}
            >
              {
                (community?.publicPreviewHeaderURL == undefined || community?.publicPreviewHeaderURL == null) && community && community.name.length > 0 &&
                <NText
                  style={{
                    ...themeFromContext.textVariants.header,
                    textAlign: 'center',
                    color: themeFromContext.colors.text,
                    maxWidth: 1000,
                    width: '100%',
                  }}
                  testID={`preview-header`}
                >
                  {i18n.t('Page_MemberPreview_Header', { 0: community ? community.name : '' })}
                </NText>
              }
            </ImageBackground>
          </View>

          {/** ROW */}
          <View
            style={{
              display: 'flex',
              flex: 1,
              flexDirection: width < TABLET_BREAK ? 'column' : 'row',
              //padding: normalizeWidth(10, width),
            }}
          >

            {/** COLUMN */}
            <View
              style={[
                {
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                },
                width < TABLET_BREAK ? { marginRight: 0 } : { flex: 1, marginRight: normalizeWidth(10, width) },
              ]}
              onLayout={layoutColumn}
            >

              {/** CUSTOM MESSAGE */}
              {
                customNote.length > 0 &&
                <Panel
                  title={i18n.t('Page_MemberPreview_MessageFromTheOrganizer')}
                  styleOverride={{
                    justifyContent: "flex-start",
                  }}
                >
                  {
                    refreshing &&
                    <NActivityIndicator
                      containerStyle={{
                        padding: 20,
                      }}
                      color={Palette.grey}
                    />
                  }
                  <NText
                    style={{
                      ...themeFromContext.textVariants.detail,
                      fontStyle: 'italic',
                      textAlign: 'center',
                      color: themeFromContext.colors.text,
                      maxWidth: 1000,
                      width: '80%',
                      marginTop: 8,
                    }}
                    testID={`preview-custom-note`}
                  >
                    {customNote}
                  </NText>
                  <NText
                    style={{
                      ...themeFromContext.textVariants.detail,
                      textAlign: 'right',
                      color: themeFromContext.colors.text,
                      maxWidth: 1000,
                      width: '80%',
                      marginTop: 12,
                    }}
                    testID={`preview-custom-note-by-line`}
                  >
                    {`— ${organizerFullName}`}
                  </NText>
                </Panel>
              }

              {/** INVITATION */}
              <Panel
                title={i18n.t('Page_MemberPreview_WhatIsThis')}
                flex={1}
              >
                {
                  refreshing &&
                  <NActivityIndicator
                    containerStyle={{
                      padding: 20,
                    }}
                    color={Palette.grey}
                  />
                }
                {
                  organizerFullName.length > 0 &&
                  <NText
                    style={{
                      ...themeFromContext.textVariants.previewBodyEmphasis,
                      textAlign: 'left',
                      color: themeFromContext.colors.text,
                      maxWidth: 1000,
                      width: '100%',
                      marginTop: 8,
                    }}
                    testID={`preview-organized-by`}
                  >
                    {i18n.t('Page_MemberPreview_OrganizedBy', { 0: organizerFullName.length > 0 ? organizerFullName : '' })}
                  </NText>
                }
                {
                  community &&
                  <>
                    <NText
                      style={{
                        ...themeFromContext.textVariants.previewLabel,
                        textAlign: 'left',
                        color: themeFromContext.colors.text,
                        maxWidth: 1000,
                        width: '100%',
                        marginTop: 16,
                      }}
                      testID={`preview-spotlight-label`}
                    >
                      {i18n.t('Page_MemberPreview_WhoIsThisFor')}
                    </NText>
                    <NText
                      style={{
                        ...themeFromContext.textVariants.previewBody,
                        textAlign: 'left',
                        color: themeFromContext.colors.text,
                        maxWidth: 1000,
                        width: '100%',
                        marginTop: 8,
                      }}
                      testID={`preview-spotlight-value`}
                    >
                      {community?.name}
                    </NText>
                    <NText
                      style={{
                        ...themeFromContext.textVariants.previewLabel,
                        textAlign: 'left',
                        color: themeFromContext.colors.text,
                        maxWidth: 1000,
                        width: '100%',
                        marginTop: 16,
                      }}
                      testID={`preview-what-are-we-doing-label`}
                    >
                      {i18n.t('Page_MemberPreview_WhatAreWeDoing')}
                    </NText>
                  </>
                }
                {
                  community && (!community.publicPreviewDescription || community.publicPreviewDescription.length == 0) &&
                  <>
                    <NText
                      style={{
                        ...themeFromContext.textVariants.previewBody,
                        textAlign: 'left',
                        color: themeFromContext.colors.text,
                        maxWidth: 1000,
                        width: '100%',
                        marginTop: 8,
                      }}
                      testID={`preview-what-are-we-doing-value-1`}
                    >
                      {i18n.t('Page_MemberPreview_What1', { 0: community ? community.name : '' })}
                    </NText>
                    <NText
                      style={{
                        ...themeFromContext.textVariants.previewBody,
                        textAlign: 'left',
                        color: themeFromContext.colors.text,
                        maxWidth: 1000,
                        width: '100%',
                        marginTop: 8,
                      }}
                      testID={`preview-what-are-we-doing-value-2`}
                    >
                      {i18n.t('Page_MemberPreview_What2')}
                    </NText>
                    <NText
                      style={{
                        ...themeFromContext.textVariants.previewBody,
                        textAlign: 'left',
                        color: themeFromContext.colors.text,
                        maxWidth: 1000,
                        width: '100%',
                        marginTop: 8,
                      }}
                      testID={`preview-what-are-we-doing-value-3`}
                    >
                      {i18n.t('Page_MemberPreview_What3', { 0: community ? community.name : '' })}
                    </NText>
                  </>
                }
                {
                  community != undefined && community.publicPreviewDescription && community.publicPreviewDescription.length > 0 &&
                  <>
                    <NText
                      style={{
                        ...themeFromContext.textVariants.previewBody,
                        textAlign: 'left',
                        color: themeFromContext.colors.text,
                        maxWidth: 1000,
                        width: '100%',
                        marginTop: 8,
                      }}
                      testID={`preview-what-are-we-doing-value-custom`}
                    >
                      {community.publicPreviewDescription}
                    </NText>
                  </>
                }
                <NButton
                  style={{
                    margin: 25,
                  }}
                  t='primary'
                  title={i18n.t('Page_MemberPreview_Button_JoinNow')}
                  onPress={clickJoin}
                  loading={joinButtonRefreshing}
                  testID={`preview-join-now-button`}
                />
              </Panel>
            </View>

            {/** COLUMN */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                width: '100%',
                maxHeight: columnHeight,
              }}
            >

              {/** QUESTIONS */}
              <Panel
                title={i18n.t('Page_MemberPreview_QuestionsAndTopics')}
                flex={1}
                styleOverride={{
                  justifyContent: 'flex-start',
                  flex: 1,
                }}
              >
                {
                  refreshing &&
                  <NActivityIndicator
                    containerStyle={{
                      padding: 20,
                    }}
                    color={Palette.grey}
                  />
                }
                {
                  questionSections && questionSections.length > 0 &&
                  <>
                    <NText
                      style={{
                        ...themeFromContext.textVariants.detail,
                        textAlign: 'center',
                        color: themeFromContext.colors.text,
                        maxWidth: 1000,
                        width: '95%',
                        marginTop: 8,
                      }}
                      testID={`preview-question-action-text`}
                    >
                      {i18n.t('Page_MemberPreview_QuestionActionText', { 0: community ? community.name : '' })}
                    </NText>
                    <NText
                      style={{
                        ...themeFromContext.textVariants.detail,
                        textAlign: 'center',
                        alignSelf: 'center',
                        fontStyle: 'italic',
                        color: themeFromContext.colors.text,
                        maxWidth: 1000,
                        width: '80%',
                        marginTop: 8,
                        marginBottom: 16,
                      }}
                      testID={`preview-question-list-footer`}
                    >
                      {i18n.t('Page_MemberPreview_QuestionActionText2')}
                    </NText>
                  </>
                }
                {
                  community && (!questionSections || questionSections.length == 0) &&
                  <>
                    <NText
                      style={{
                        ...themeFromContext.textVariants.detail,
                        textAlign: 'center',
                        fontStyle: 'italic',
                        color: themeFromContext.colors.text,
                        maxWidth: 1000,
                        width: '95%',
                        marginTop: 8,
                      }}
                      testID={`preview-empty-question-list`}
                    >
                      {i18n.t('Page_MemberPreview_QuestionEmptyText', { 0: community ? community.name : '' })}
                    </NText>
                    <View
                      style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <Image
                        source={require('../../../../../assets/svg/illustrations/Question.svg')}
                        defaultSource={require('../../../../../assets/svg/illustrations/Question.svg')}
                        resizeMethod='scale'
                        resizeMode='contain'
                        style={{
                          width: '100%',//normalizeHeight(343, height),
                          height: normalizeHeight(140, height),
                          marginTop: normalizeHeight(20, height),
                          marginBottom: normalizeHeight(30, height),
                        }}
                      />
                    </View>
                  </>
                }
                {/* {
                  likeMap && questions && questions.length > 0 &&
                  <FlatList
                    style={{
                      width: '100%',
                    }}
                    data={questions}
                    renderItem={({ item, index, separators }) => (
                      <AskedQuestionCard question={item} index={index} editable={false} likes={likeMap[item.id]} toggleLike={toggleLike} toggleEdit={() => {return;}} toggleDelete={() => {return;}} />
                    )}
                    ListFooterComponent={() => {
                      return (
                        <NText
                          style={{
                            ...themeFromContext.textVariants.detail,
                            textAlign: 'center',
                            alignSelf: 'center',
                            fontStyle: 'italic',
                            color: themeFromContext.colors.secondary,
                            maxWidth: 1000,
                            width: '80%',
                            marginTop: 8,
                          }}
                          testID={`preview-question-list-footer`}
                        >
                          {i18n.t('Page_MemberPreview_QuestionFooterText')}
                        </NText>
                      );
                    }}
                    testID={`preview-question-list`}
                  />
                } */}
                {
                  likeMap && questionSections && questionSections.length > 0 &&
                  <SectionList
                    sections={questionSections}
                    extraData={refresh}
                    renderItem={({ item, index, section, separators, }) => (
                      <>
                        {
                          expanded.has(section.title) &&
                          <AskedQuestionCard isPublic={true} question={item} index={index} editable={false} likes={likeMap[item.id]} toggleLike={toggleLikePre} toggleEdit={() => { return; }} toggleDelete={() => { return; }} clickAnswerLink={clickAnswerPre} clickRecordLink={clickRecordPre} />
                        }
                      </>
                    )}
                    renderSectionHeader={({ section: { title } }) => (
                      <CategoryButton
                        expandable={true}
                        label={title}
                        onPress={() => { toggleExpanded(title); }}
                        selected={expanded.has(title)}
                        dark
                      />
                    )}
                    // ListFooterComponent={() => (
                    //   <View style={{
                    //     minHeight: questionSections.length > 0 ? normalizeHeight(260, height) : 0,
                    //     paddingVertical: themeFromContext.spacing.m
                    //   }} />)
                    // }
                    ListFooterComponent={() => {
                      return (
                        (!isGregHowellCommunity(community) || Platform.OS == 'ios') &&
                        <NText
                          style={{
                            ...themeFromContext.textVariants.detail,
                            textAlign: 'center',
                            alignSelf: 'center',
                            fontStyle: 'italic',
                            color: themeFromContext.colors.secondary,
                            maxWidth: 1000,
                            width: '80%',
                            marginTop: 8,
                            marginBottom: 16,
                          }}
                          testID={`preview-question-list-footer`}
                        >
                          {i18n.t('Page_MemberPreview_QuestionFooterText')}
                        </NText>
                      );
                    }}
                    // ListHeaderComponent={() => (
                    //   <View
                    //     style={{
                    //       width: '100%',
                    //     }}
                    //   >
                    //     {
                    //       <View style={{ flex: 1, width: '100%', alignItems: 'center', paddingBottom: 25 }}></View>
                    //     }
                    //   </View>
                    // )}
                    ListEmptyComponent={() => (
                      <View
                        style={{
                          alignItems: 'center',
                          flex: 1,
                          //justifyContent: 'center',
                          paddingTop: 30,
                          paddingBottom: 130,
                        }}
                        testID={'asked-questions-list-empty-view'}
                      >
                        <Image
                          source={require('../../../../../assets/svg/illustrations/Question.svg')}
                          defaultSource={require('../../../../../assets/svg/illustrations/Question.svg')}
                          resizeMethod='scale'
                          resizeMode='contain'
                          style={{
                            width: '100%',//normalizeHeight(343, height),
                            height: normalizeHeight(140, height),
                            marginTop: normalizeHeight(20, height),
                            marginBottom: normalizeHeight(30, height),
                          }}
                        />
                        <Text
                          style={{
                            ...themeFromContext.textVariants.header,
                            color: themeFromContext.colors.text,
                            textAlign: 'center',
                            marginBottom: normalizeHeight(20, height),
                          }}
                        >
                          {`What would you like to know?`}
                        </Text>
                        <Text
                          style={{
                            ...themeFromContext.textVariants.body,
                            color: Palette.grey,
                            textAlign: 'center'
                          }}
                        >
                          {`Please ask a new question by taping on the "+" below, or vote for a question someone else asked!`}
                        </Text>
                      </View>
                    )}
                    keyExtractor={item => item.id}
                    style={{
                      width: '100%',
                      borderColor: Palette.lightgrey,
                      borderWidth: 1,
                      borderRadius: 12,
                      paddingVertical: 16,
                    }}
                    contentContainerStyle={{
                      display: 'flex',
                      flex: 1,
                      paddingHorizontal: 24,
                    }}
                    testID='preview-question-sectionlist'
                  />

                }
                {
                  (!isGregHowellCommunity(community) || Platform.OS == 'ios') &&
                  <NButton
                    style={{
                      margin: 25,
                    }}
                    t='primary'
                    title={i18n.t('Page_MemberPreview_Button_AskNew')}
                    loading={askButtonRefreshing}
                    onPress={clickAsk}
                    testID={`preview-ask-question-button`}
                  />
                }
                {
                  isGregHowellCommunity(community) && Platform.OS != 'ios' &&
                  <NButton
                    style={{
                      margin: 25,
                    }}
                    t='primary'
                    title={i18n.t('Page_MemberPreview_Button_WatchVideos')}
                    loading={watchButtonRefreshing}
                    onPress={watchVideosPre}
                    testID={`preview-watch-videos-button`}
                  />
                }
              </Panel>

            </View>

          </View>

          {/** ROW */}
          <View
            style={{
              display: 'flex',
              //flex: 1,
              flexDirection: 'row',
              //padding: normalizeWidth(10, width),
            }}
          >
            {/** COLUMN */}
            <View
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
              }}
            >

              {/** ABOUT NOVELLA */}
              <AboutNovella />

            </View>
          </View>

        </View>
      }
      <PromptModal
        show={showNavigateAwayModal}
        heading={modalHeading}
        prompt={modalMessage}
        confirmButtonText={modalPrimaryButtonText}
        backdropHeight={contentHeight + headerHeight + top}
        confirm={() => {
          setShowNavigateAwayModal(false);
          modalGoPrimary();
        }}
        denyButtonText={modalSecondaryButtonText}
        deny={() => {
          setShowNavigateAwayModal(false);
          modalGoSecondary();
        }}
        dismiss={async () => { configModal('', '', '', false, ''); }}
        testID={`community-preview-navigate-away-modal`}
      />
    </>
  );
}

export default CommunityPreview;