import React, { useContext, useEffect, useState } from 'react';
import { Chip, Text } from 'react-native-elements';
import MemberAvatar from './MemberAvatar';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { LayoutChangeEvent, Platform, View } from 'react-native';
import { LikeData, VimeoVideoMetadata } from '../../services/CommunityService';
import ItemEditButtonMenu from './ItemEditButtonMenu';
import { Palette } from '../../Theme';
import { CommunityQuestion, CommunityQuestionAnswer, CommunityUserStatus } from '../../API';
import { useLinkTo } from '@react-navigation/native';
import log from '../../business/logging/logger';
import { getPowers } from '../../business/answers/answerPostPowerRules';
import LikeButton from './LikeButton';
import WatchAnswersButton from './WatchAnswersButton';
import RecordAnswerButton from './RecordAnswerButton';
import { isGregHowellCommunityId } from '../../business/gregHowell/gregHowellHelper';
import ConstrainedUserImage from './ConstrainedUserImage';

type AskedQuestionCardProps = {
  question: CommunityQuestion,
  likes: LikeData,
  editable: boolean,
  index: number,
  toggleLike: (question: CommunityQuestion) => Promise<void>,
  toggleEdit: (question: CommunityQuestion) => void,
  toggleDelete: (question: CommunityQuestion) => void,
  clickAnswerLink: (question: CommunityQuestion) => Promise<void>,
  clickRecordLink: (question: CommunityQuestion) => Promise<void>,
  isPublic?: boolean,
}

function AskedQuestionCard(props: AskedQuestionCardProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { question, likes, editable, index, toggleLike, toggleEdit, toggleDelete, clickAnswerLink, clickRecordLink } = props;
  const [likeDisabled, setLikeDisabled] = useState(false);
  const [answers, setAnswers] = useState<CommunityQuestionAnswer[] | undefined>(question.communityQuestionAnswersByStatusDate?.items);
  const [visibleAnswerCount, setVisibleAnswerCount] = useState(0);
  //const [communityGroup, setCommunityGroup] = useState('');
  const [communityRole, setCommunityRole] = useState(CommunityUserStatus.MEMBER);
  const linkTo = useLinkTo();


  const [viewHeight, setViewHeight] = useState(0);
  const [viewWidth, setViewWidth] = useState(0);
  const [cardWidth, setCardWidth] = useState(0);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);


  useEffect(() => {
    let answerCount = 0;
    if (answers) {

      //get the "group" that the user is in.  This is so a SUPERVIEWER can view videos that only the organizer/spotlight can see (started with the GH community)
      try {
        const communityGroup = commonContext.isAdmin == false ? commonContext.loggedInUser?.communityUsersByStatusDate?.items.find(cu => cu?.communityID == question.communityID)?.group : "";

        answers.forEach(answer => {
          try {
            if (answer && answer != null && answer.content && answer.content.length > 0) {
              const item: VimeoVideoMetadata = JSON.parse(answer.content)[0];
              const powers = getPowers(communityRole, answer.userID == commonContext.loggedInUser?.id, question.community.requiresModeration, item.visibility, communityGroup, question.community.allowPublicPages);
              if (powers.canSee) {
                answerCount++;
              }
            }
          } catch (ex) {
            log.error(ex);
            log.warn(`answer could not be parsed for content: ${JSON.stringify(answer, null, 2)}`);
          }
        });
      } catch (ex) {
        log.error(ex);
        log.warn("error - could not get group - missing question or communityusers");
      }
      setVisibleAnswerCount(answerCount);
    }
  }, [answers, communityRole, commonContext.loggedInUser, question]);

  useEffect(() => {
    const communityId = question.communityID;
    commonContext.services.communityService.getLoggedInUserCommunityRole(communityId)
      .then(r => {
        setCommunityRole(r);
      })

  }, [question]);

  function onCardLayout(event: LayoutChangeEvent) {
    const { width, height } = event.nativeEvent.layout;
    setCardWidth(width);
  }

  return (
    <View
      style={{ // card
        ...themeFromContext.card.style,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}
      onLayout={onCardLayout}
      testID={`asked-question-card-${index}`}
    >
      <View style={{ // head / top
        display: 'flex',
        flexDirection: 'row',
        paddingHorizontal: 8,
        paddingTop: 8,
        alignItems: 'center',
        flex: 1, // stretch down as far as needed
      }}>
        <View>
          <MemberAvatar
            size={43}
            userData={question.user}
            ignoreUpdate={true}
            containerStyle={{
              marginRight: 8,
              //backgroundColor: themeFromContext.colors.overlay
            }}
            titleStyle={{
              color: themeFromContext.colors.background
            }}
          />
        </View>
        <View
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <View style={{
            //display: 'flex',
            flex: 1,
            flexBasis: '30%',
            display: 'flex',
            //width: '100%',
            //flexGrow: 1,
            //flexShrink: 0.1,
            //flexDirection: 'column',
            //flexShrink: 1,
            padding: themeFromContext.spacing.s
          }}>
            {/* <View
              style={{
                flex: 1,
                //width: '100%',
              }}
            > */}
            <Text
              style={{
                ...themeFromContext.textVariants.questionHeader,
                //fontSize: 14,
                color: Palette.purple,
                flex: 1,
                display: 'flex',
              }}
              numberOfLines={1}
              testID={`asked-question-card-header-top`}
            >
              {commonContext.services?.userService.getFullName(question.user)}
            </Text>
            {/* </View> */}
            {/* <View
              style={{
                flex: 1,
              }}
            > */}
            <Text
              style={{
                paddingEnd: themeFromContext.spacing.s,
                ...themeFromContext.textVariants.detail,
                fontSize: 12,
                color: themeFromContext.colors.text
              }}
              numberOfLines={1}
              testID={`asked-question-card-header-bottom`}
            >
              {(new Date(question.date)).toLocaleDateString()}
            </Text>
            {/* </View> */}
          </View>
          {
            ((question.category && question.category.name !== '') || question.communityCategory) &&
            <View
              style={{
                flexShrink: 1,
                marginLeft: 8,
                //flexGrow: 1,
                //width: '100%',
              }}
            >
              <Chip
                title={question.communityCategory?.toUpperCase() ?? question.category?.name.toUpperCase()}
                titleStyle={{ paddingHorizontal: themeFromContext.spacing.s, ...themeFromContext.textVariants.chip, color: Palette.plum }}
                buttonStyle={{ backgroundColor: themeFromContext.colors.background, borderColor: Palette.plum, borderWidth: 1, paddingVertical: themeFromContext.spacing.s, }}
                titleProps={{ numberOfLines: 1 }}
                style={{ width: 'min-content' }}
                testID={`asked-question-card-category-chip-${question.communityCategory ?? question.category?.name}`}
              />
            </View>
          }
          {
            !props.isPublic &&
            <ItemEditButtonMenu getItemToFlag={() => { return question; }} vertical={true} canEdit={editable} canDelete={editable} onEdit={() => toggleEdit(question)} onDelete={() => toggleDelete(question)} testID={`edit-item-button-${index}`} />
          }
        </View>
      </View>

      <View style={{
        paddingHorizontal: 8,
        paddingTop: 12,
      }}>
        <Text
          style={{
            ...themeFromContext.textVariants.body,
            fontSize: 16,
            fontWeight: '500',
            color: Palette.plum,
          }}
          testID={`asked-question-card-body`}
        >
          {question.questionText}
        </Text>
      </View>

      {
        question.imageKeys && question.imageKeys.length > 0 &&
        <View style={{
          paddingHorizontal: 2,
          paddingTop: 12,
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}>
          <ConstrainedUserImage imageKey={question.imageKeys[0]} maxWidth={cardWidth - 2} />
        </View>
      }

      <View style={{ paddingTop: 12 }} />

      <View style={{ display: 'flex', flexDirection: 'row-reverse', width: '100%', alignItems: 'center', justifyContent: 'space-between', paddingHorizontal: 8, paddingTop: 2, paddingBottom: 8 }}>

        {/* <ItemEditButtonMenu canEdit={editable} canDelete={editable} onEdit={() => toggleEdit(question)} onDelete={() => toggleDelete(question)} testID={`edit-item-button-${index}`} /> */}

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: (!isGregHowellCommunityId(question.communityID) || Platform.OS == 'ios') ? 'space-between' : 'space-around',
            width: '100%',
            flex: 1,
            //paddingLeft: 18,
          }}
        >
          <LikeButton target={question} likes={likes} toggleLike={toggleLike} />
          <WatchAnswersButton question={question} visibleAnswerCount={visibleAnswerCount} clickWatchLink={clickAnswerLink} />
          {
            (!isGregHowellCommunityId(question.communityID) || Platform.OS == 'ios') &&
            <RecordAnswerButton question={question} clickRecordLink={clickRecordLink} />
          }


          {/* <Button
            title={`View answers (${visibleAnswerCount})`}
            titleStyle={{
              color: Palette.teal,
              ...themeFromContext.textVariants.cardBottomButton,
              paddingStart: themeFromContext.spacing.s
            }}
            onPress={() => {
              log.info(`/community/${question.community.id}/questions/answers/${question.id}`);
              linkTo(`/community/${question.community.id}/questions/answers/${question.id}`);
            }}
            buttonStyle={{ alignSelf: 'center' }}
            type='clear'
            testID={`answers-button-${index}`}
          /> */}
        </View>
        {/* <View style={{
          display: 'flex',
          flexDirection: 'row',
          flexGrow: 1,
          paddingHorizontal: themeFromContext.spacing.m,
          alignItems: 'center'
        }}>
          {
            likes && likes.totalLikes > 0 &&
            <>
              <Icon
                size={themeFromContext.textVariants.header.fontSize}
                name='recommend'
                type='material'
                color={themeFromContext.colors.primary}
              />
              <Text style={{
                ...themeFromContext.textVariants.detail,
                color: themeFromContext.colors.foreground,
                paddingStart: themeFromContext.spacing.s
              }}>
                {likes.totalLikes}
              </Text>
            </>
          }
        </View> */}
      </View>
    </View>
  );
}

// function areEqual(prevProps: AskedQuestionCardProps, nextProps: AskedQuestionCardProps) : boolean {

//   if (prevProps.editable != nextProps.editable) return false;
//   if (prevProps.likes?.loggedInUserLikes != nextProps.likes?.loggedInUserLikes) return false;
//   if (prevProps.likes?.otherLikes != nextProps.likes?.otherLikes) return false;
//   if (prevProps.question.questionText != nextProps.question.questionText) return false;
//   if (prevProps.question.category.name != nextProps.question.category.name) return false;
//   return true;

// }
export default AskedQuestionCard;
