// Frameworks
import React, { useContext } from 'react';
import { Animated, Image, Linking, Platform, View } from 'react-native';
import { Palette } from '../../../Theme';
import CommonContext from '../../../CommonContext';
import { CommonContextType } from '../../../types/CommonContextType';
import HeaderMenuButton from './HeaderMenuButton';
import NText from '../NText';
import { normalizeHeight } from '../../../business/layout/responseSize';
import { TOP_HEADER_BASE_HEIGHT } from '../../../constants/header';
import { MAX_WIDTH } from '../../../business/layout/layout';
import { useLinkTo } from '@react-navigation/native';

function HeaderFirst(props: { navigation: any, height: number, maxWidth?: string, logoHeight?: number, top: number, title?: string, goBack?: () => void, goBackIconName?: string, menuAction?: () => void, menuIconName?: string, menuButtonTitle?: string }): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { width } = commonContext.dimensions;
  const linkTo = useLinkTo();

  const defaultGoBack = () => {
    if (Platform.OS == 'web') {
      window.open('https://meetnovella.com' , "_self");
    } else {
      Linking.openURL('https://meetnovella.com');
    }
  }

  const defaultGoBackIconName = 'home';

  const defaultMenuAction = () => {
    linkTo(`/signin`);
  }

  const defaultMenuActionTitle = 'Sign In';

  //log.info(`TOP INSET: ${top}`);
  return (
      <Animated.View // TOP ROW
        style={[
          {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: props.maxWidth ?? MAX_WIDTH,
            height: props.height,
            backgroundColor: Palette.plum,
            borderColor: Palette.plum,
            borderWidth: 1,
            paddingTop: 0,
            position: 'absolute',
            top: props.top,
            zIndex: 5,
          },
        ]}
      >
        <View
          style={{
            //flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}
        >
          <HeaderMenuButton position='left' action={commonContext.loggedInUser ? props.goBack : defaultGoBack} materialCommunityIconName={props.goBack ? 'arrow-left' : defaultGoBackIconName} />
        </View>
        <View
          style={{
            position: 'absolute',
            width: '100%',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          pointerEvents={'box-none'}
        >
          {
            props.title &&
            <NText
              style={{
                ...themeFromContext.textVariants.body,
                fontWeight: '700',
                color: Palette.white,
                textAlign: 'center',
                textAlignVertical: 'center',
                maxWidth: width - 100,
                //padding: normalizeHeight(10, height),
              }}
              numberOfLines={2}
            >
              {props.title}
            </NText>
          }
        </View>
        <View
          style={{
            //flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-end',
          }}
        >
          <HeaderMenuButton position='right' action={commonContext.loggedInUser ? props.menuAction : defaultMenuAction} materialCommunityIconName={props.menuIconName ?? 'menu'} menuButtonTitle={commonContext.loggedInUser ? props.menuButtonTitle : defaultMenuActionTitle} />
        </View>
      </Animated.View>
  );
}
export default HeaderFirst;