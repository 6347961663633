// Frameworks
import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { Animated, View, ScrollView, Modal, StyleProp, ViewStyle, Pressable } from 'react-native';
import log from '../../../business/logging/logger';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { Easing } from 'react-native';
import { MAX_WIDTH } from '../../../business/layout/layout';
import NButton from '../NButton';
import { IconNode } from 'react-native-elements/dist/icons/Icon';
//import { View } from 'react-native';

function NModal(props: {isVisible: boolean, name?: string, onDone?: () => Promise<void>, doneButtonIcon?: IconNode, maxWidth?: number, onBackdropPress?: () => void, overlayStyle?: StyleProp<ViewStyle>, scrollDisabled?: boolean, children: ReactElement | ReactElement[]}) : React.ReactElement {
  const { isVisible, name } = props;
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { width, height } = commonContext.dimensions;
  const { top } = useSafeAreaInsets();
  const [shouldOpen, setShouldOpen] = useState(isVisible);
  const [waiting, setWaiting] = useState(false);
  const [anim] = useState(new Animated.Value(0));
  const [viewHeight, setViewHeight] = useState(0);
  const [fullyOpen, setFullyOpen] = useState(false);
  //const [opening, setOpening] = useState(false);


  useEffect(() => {
    setViewHeight(height * 0.8);
  }, [height, top]);

  useEffect(() => {
    if (isVisible) {
      // open it
      //log.info(`OPEN MODAL START: ${name}`);
      setShouldOpen(true);
      //setOpening(true);

      Animated.timing(
        anim,
        {
          toValue: 1,
          duration: 400,
          useNativeDriver: false,
          easing: Easing.ease,
        }
      ).start(() => {
        setFullyOpen(true);
        //setOpening(false);
        //log.info(`OPEN MODAL END: ${name}`);
      });

    } else {
      // close it
      //log.info(`CLOSE MODAL START: ${name}`);
      setFullyOpen(false);
      Animated.timing(
        anim,
        {
          toValue: 0,
          duration: 400,
          useNativeDriver: false,
          easing: Easing.ease,
        }
      ).start(() => {
        //if (!opening) {
          setShouldOpen(false);
          //log.info(`CLOSE MODAL END: ${name}`);
        //}
      });

    }

    return () => { return; }
  }, [isVisible]);

  const buttonPress = async () => {
    if (props.onDone) {
      setWaiting(true);
      await props.onDone();
      setWaiting(false);
    }
  }

  return (
    <Modal 
    visible={shouldOpen}
    animationType={'fade'}
    onRequestClose={buttonPress}
    transparent
  >
    <Pressable
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: 'black',
        opacity: 0.8,
        position: 'absolute',
      }}
      onPress={() => {
        if (props.onBackdropPress) {
          props.onBackdropPress();
        }
      }}
    >
    </Pressable>
     <Animated.View
       style={[
         {
           display: shouldOpen ? 'flex' : 'none',
           position: 'absolute',
           transform: [
             // from HEIGHT to HEIGHT - VIEWHEIGHT
             { translateY: Animated.subtract(height, Animated.multiply(height, anim)) },
           ],
           bottom: 0,
           overflow: 'hidden',
           width: width,
           maxWidth: props.maxWidth ?? MAX_WIDTH,
           alignSelf: 'center',
           height: viewHeight,
           zIndex: 15,
           ...themeFromContext.slideUp.style,
         },
         props.overlayStyle ?? {}
//         props.style,
       ]}
     >
      {
        props.onDone &&
        <View
          style={{
            width: '100%',
            alignItems: 'flex-end',
            paddingRight: 10,
            paddingVertical: 10,
            zIndex: 100,
          }}
        >
          <NButton
            t='brightLink'
            title={props.doneButtonIcon ? undefined : `Done`}
            onPress={buttonPress}
            testID={`new-slide-up-modal-done-button`}
            icon={props.doneButtonIcon}
          />
        </View>
      }
      <ScrollView
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingBottom: 100,
        }}
        showsVerticalScrollIndicator={false}
        scrollEnabled={!props.scrollDisabled}
      >
        {props.children}
      </ScrollView>
      </Animated.View>
  </Modal>
    // <>
    //   <Animated.View
    //     style={{
    //       display: shouldOpen ? 'flex' : 'none',
    //       position: Platform.OS === 'ios' ? 'relative': 'fixed',
    //       flex: 1,
    //       top: 0,
    //       left: 0,
    //       height: backdropHeight ?? '100%',
    //       width: '100%',
    //       backgroundColor: 'black',
    //       opacity: Animated.multiply(0.8, anim),
    //       overflow: Platform.OS === 'ios' ? 'hidden': 'hide',
    //       zIndex: 50,
    //     }}
    //   >
    //   </Animated.View>
    //   <Animated.View
    //     style={[
    //       {
    //         display: shouldOpen ? 'flex' : 'none',
    //         position: 'absolute',
    //         //overflow: 'hidden',
    //         maxWidth: MAX_WIDTH,
    //         alignSelf: 'center',
    //         zIndex: 50,
    //       },
    //       type == 'slideup' ?
    //         {
    //           width: width,
    //           height: viewHeight,
    //           bottom: 0,
    //           transform: [
    //             // from HEIGHT to HEIGHT - VIEWHEIGHT
    //             { translateY: Animated.subtract(height, Animated.multiply(height, anim)) },
    //           ],
    //           ...themeFromContext.slideUp.style,
    //         } : {
    //           width: width * 0.8,
    //           top: height * 0.20,
    //           maxHeight: height * 0.60,
    //           overflow: 'hidden',
    //           opacity: Animated.multiply(1, anim),
    //           ...themeFromContext.card.style,
    //         },
    //       overlayStyle ?? {},
    //     ]}
    //   >
    //     {props.children}
    //   </Animated.View>
    // </>
    //   // <Overlay
    //   //   ModalComponent={Platform.OS === 'web' ? RNModal : undefined}
    //   //   overlayStyle={
    //   //     Object.assign(
    //   //       props.overlayStyle ?? {}, {
    //   //         borderRadius: 25,
    //   //         width: '90%',
    //   //         maxWidth: props.maxWidth ?? width * 0.9, //MAX_WIDTH * 0.9,
    //   //         display: 'flex',
    //   //         flexDirection: 'column',
    //   //       }
    //   //     )
    //   //   }
    //   //   animationType="fade"
    //   //   style={{
    //   //     margin: 0,
    //   //     maxWidth: width, //MAX_WIDTH,
    //   //   }}
    //   //   {...props}
    //   // >
    //   // </Overlay>
  );
}

export default NModal;