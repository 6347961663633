// Frameworks
import React, { useContext, useEffect, useState } from 'react';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { Animated, Easing, Modal, Platform, ScrollView, Text, View, ViewProps } from 'react-native';
import { Icon } from 'react-native-elements';
import { MAX_WIDTH, useSafeAreaInsets } from '../../../business/layout/layout';
import NFAB2 from '../NFAB2';
import { Palette } from '../../../Theme';
import NButton from '../NButton';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { isDesktop } from 'react-device-detect';

function SlideUpView(props: { stretchVertical?: boolean, show: boolean, percent?: number, title?: string, onCommit: () => Promise<void>, onCancel: () => void, onBack?: () => void, working?: boolean, buttonTitle?: string, labelColor?: string, buttonColors?: string[], icon?: Element, children: Element[] | Element } & ViewProps): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { width, height } = commonContext.dimensions;
  const { top } = useSafeAreaInsets();
  const [shouldOpen, setShouldOpen] = useState(props.show);
  const [waiting, setWaiting] = useState(false);
  const [anim] = useState(new Animated.Value(0));
  const [viewHeight, setViewHeight] = useState(0);
  const [fullyOpen, setFullyOpen] = useState(false);

  useEffect(() => {
    setViewHeight(height * (props.percent ?? 80) * 0.01);
  }, [height, top]);

  useEffect(() => {
    if (props.show) {
      // open it
      setShouldOpen(true);

      Animated.timing(
        anim,
        {
          toValue: 1,
          duration: 400,
          useNativeDriver: false,
          easing: Easing.ease,
        }
      ).start((result) => {
        setFullyOpen(true);
      });

    } else {
      // close it

      setFullyOpen(false);
      Animated.timing(
        anim,
        {
          toValue: 0,
          duration: 400,
          useNativeDriver: false,
          easing: Easing.ease,
        }
      ).start(() => {
        setShouldOpen(false);
      });

    }

    return () => { return; }
  }, [props.show]);

  const loading = props.working || waiting;

  const buttonPress = async () => {
    setWaiting(true);
    await props.onCommit();
    setWaiting(false);
  }

  return (
    <Modal 
      visible={shouldOpen}
      animationType={'fade'}
      onRequestClose={buttonPress}
      transparent
    >
      <View
        style={{
          height: '100%',
          width: '100%',
          backgroundColor: 'black',
          opacity: 0.8,
          position: 'absolute',
        }}
      >
      </View>
      <Animated.View
        style={[
          {
            display: shouldOpen ? 'flex' : 'none',
            position: 'absolute',
            transform: [
              // from HEIGHT to HEIGHT - VIEWHEIGHT
              { translateY: Animated.subtract(height, Animated.multiply(height, anim)) }
            ],
            bottom: 0,
            overflow: 'hidden',
            width: width,
            maxWidth: MAX_WIDTH,
            alignSelf: 'center',
            height: viewHeight,
            zIndex: 15,
            ...themeFromContext.slideUp.style,
          },
          props.style,
        ]}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
            //paddingRight: 8,
            //paddingTop: 8,
            zIndex: 100,
          }}
        >
          <Text
            style={{
              fontSize: 24,
              fontWeight: '700',
              color: Palette.plum,
              flex: 1,
              marginRight: 6,
            }}
            numberOfLines={1}
          >
            {props.title ?? ' '}
          </Text>
          <NButton
            buttonStyle={{
              borderWidth: 0,
            }}
            style={{
              borderWidth: 0,
            }}
            t='brightLink'
            onPress={props.onCancel}
            hitSlop={{top: 10, bottom: 10, left: 10, right: 10}}
            icon={
              <Icon
                name='close-circle'
                type='ionicon'
                color={'#313067'}
                size={40}
              />
            }
            testID={`slide-up-modal-close-button`}
          />
        </View>
        <KeyboardAwareScrollView
          style={{
            //flex: 1,
            display: 'flex',
            flexDirection: 'column',
            //paddingBottom: 100,
          }}
          contentContainerStyle={[
            {
              paddingBottom: 100,
            },
            props.stretchVertical ? {
              display: 'flex',
              flex: 1,
            } : {}
          ]}
          showsVerticalScrollIndicator={isDesktop && Platform.OS == 'web'}
          extraScrollHeight={100}
          scrollsToTop={false}
        >
          {props.children}
        </KeyboardAwareScrollView>
      </Animated.View>
      <NFAB2
        isVisible={true}
        onPress={buttonPress}
        left={
          props.icon ??
          <Icon
            name='checkmark'
            type='ionicon'
            color={props.labelColor ?? Palette.plum}
            size={32}
          />
        }
        right={
          <Text
            style={{
              fontSize: 20,
              color: props.labelColor ?? Palette.plum,
            }}
          >
            {props.buttonTitle}
          </Text>
        }
        colors={props.buttonColors}
        working={props.working}
      />
      {
        props.onBack &&
        <NFAB2
          position={'left'}
          isVisible={true}
          onPress={props.onBack}
          left={
            props.icon ??
            <Icon
              name='arrow-back'
              type='ionicon'
              color={props.labelColor ?? Palette.plum}
              size={32}
            />
          }
          colors={[Palette.lightyellow, Palette.yellow]}
        />
      }
      {/* <NFAB 
        style={{
          display: fullyOpen ? 'flex' : 'none',
        }}
        icon={
          props.icon ??
          <Icon
            name='check'
            type='font-awesome-5'
            color={themeFromContext.colors.background}
          />
        }
        position={FABPosition.CENTER}
        buttonStyle={{
          backgroundColor: themeFromContext.colors.background,
        }}
      />
      <NFAB
        style={{
          display: fullyOpen ? 'flex' : 'none',
        }}
        icon={
          props.icon ??
          <Icon
            name='check'
            type='font-awesome-5'
            color={themeFromContext.colors.background}
          />
        }
        loading={loading}
        disabled={loading}
        position={FABPosition.CENTER}
        onPress={buttonPress}
        buttonStyle={{
          backgroundColor: props.buttonColor ?? themeFromContext.colors.primary,
        }}
        testID={`slide-up-modal-save-button`}
      /> */}
    </Modal>
  );
}
export default SlideUpView;