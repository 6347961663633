// Frameworks
import React, { useContext, useEffect, useState } from 'react';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { Text } from 'react-native-elements';
import { Animated, Easing, View } from 'react-native';

function NToast(props: {show: boolean, icon: Element|undefined, message: string, maxWidth?: number }) : React.ReactElement {
  
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const [isOpen, setIsOpen] = useState(false);
  const [anim] = useState(new Animated.Value(0));
  const {height} = commonContext.dimensions;

  useEffect(() => {
    if (props.show) {
      // open it
      setIsOpen(true);

        Animated.timing(
          anim,
          {
            toValue: 1,
            duration: 300,
            useNativeDriver: false,
            easing: Easing.ease,
          }
        ).start();

    } else {
      // close it

      Animated.timing(
        anim,
        {
          toValue: 0,
          duration: 300,
          useNativeDriver: false,
          easing: Easing.ease,
        }
      ).start(() => {
        setIsOpen(false);
      });

    }

    return () => { return; }
  }, [props.show]);

  return (
    <Animated.View
      style={
        [
          {
            backgroundColor: themeFromContext.colors.transparent,
            display: isOpen ? 'flex' : 'none',
            position: 'absolute',
            transform: [
              // from HEIGHT to HEIGHT - VIEWHEIGHT
              { translateY: Animated.multiply(-50, anim) }
            ],
            opacity: anim,
            bottom: 0,
            width: '100%',
            filter: 'drop-shadow(1px 3px 6px rgba(50, 50, 0, 0.2))',
          },
          props.maxWidth ? { maxWidth: props.maxWidth } : {},
      ]}
    >
      {/* <View
        style={{
          backgroundColor: themeFromContext.colors.transparent,
          filter: 'drop-shadow(1px 3px 6px rgba(50, 50, 0, 0.2))',
        }}
      > */}
        <View
          style={{
            borderColor: themeFromContext.colors.toastBorder,
            backgroundColor: themeFromContext.colors.toastBackground,
            borderWidth: 1,
            borderRadius: 8,
            padding: 18,
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          testID={`toast`}
        >
          {props.icon}
          <Text
            style={{
              ...themeFromContext.textVariants.toastMessage,
              marginLeft: 20,
            }}
          >
            {props.message}
          </Text>

        </View>
      {/* </View> */}
    </Animated.View>
  );
}
export default NToast;