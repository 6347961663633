/* eslint-disable react/display-name */
import React from 'react';
import { RootStackParamList } from '../../../types/Navigation';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import HomeStackInner from './HomeStackInner';
export type HomeProps = NativeStackScreenProps<RootStackParamList, 'Home'>;

function HomeStack(props: HomeProps): React.ReactElement {
  
  return (
    <HomeStackInner {...props} />
  );
}

export default HomeStack;