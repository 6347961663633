import React, { useContext, useState } from 'react';
import { LayoutChangeEvent, Pressable, StyleProp, Text, View, ViewStyle } from 'react-native';
import Constants from 'expo-constants';
import * as ImagePicker from 'expo-image-picker';
import * as Permissions from 'expo-permissions';
import * as ImageManipulator from "expo-image-manipulator";
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import NButton from '../../components/NButton';
import log from '../../../business/logging/logger';
import { v4 as uuid } from 'uuid';
import { putUserFile } from '../../../business/storage/userContent';
import { Palette } from '../../../Theme';
import ConstrainedUserImage from '../../components/ConstrainedUserImage';

const MAX_SIDE_LENGTH = 1080;
const CARD_PADDING = 20;
const CARD_PADDING_SMALL = 8;

function ImageUploader(props: { buttonStyle?: StyleProp<ViewStyle>, imageContainerStyle?: StyleProp<ViewStyle>, imageKey: string, onSave: (storageKey: string) => Promise<void> }): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const { userService } = commonContext.services;
  const themeFromContext = commonContext.theme;
  const [imageKey, setImageKey] = useState(props.imageKey);
  const [imageUri, setImageUri] = useState('');
  const [parentWidth, setParentWidth] = useState(0);

  async function getPermissions() {
    if (Constants.platform?.ios) {
      const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
      if (status !== 'granted') {
        log.error('Sorry, we need camera roll permissions to make this work!');
      }
    }
  }

  async function pickImage() {
    try {
      await getPermissions();
      const result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true, aspect: [1, 1], quality: 1, allowsMultipleSelection: false,
      });
      if (!result.canceled) {
        const iw = result.assets[0].width;
        const ih = result.assets[0].height;

        let actions: ImageManipulator.Action[] = [];
        if (iw > MAX_SIDE_LENGTH || ih > MAX_SIDE_LENGTH) {
          const largerSide = Math.max(iw, ih);
          const resizeDim = Math.min(largerSide, MAX_SIDE_LENGTH);
          if (iw >= ih) {
            //landscape photo
            actions = [{ resize: { width: resizeDim } }];
          } else {
            // portrait photo
            actions = [{ resize: { height: resizeDim } }];
          }
        }
        const manipResult = await ImageManipulator.manipulateAsync(
          result.assets[0].uri,
          actions,
          { compress: 0.8 }
        );
        const filename = uuid();
        try {
          let storageKey = imageKey;
          const response = await fetch(manipResult.uri)
          const blob = await response.blob();
          storageKey = await putUserFile(userService, filename, blob);
          setImageKey(storageKey);
          setImageUri(manipResult.uri);

          await props.onSave(storageKey);
        } catch (ex) {
          log.error(ex);
        }
      }
      log.info({ result });
    } catch (error) {
      log.warn({ error });
    }
  }

  function onParentLayout(event: LayoutChangeEvent) {
    const { width, height } = event.nativeEvent.layout;
    setParentWidth(width);
  }

  return (
    <>
      {
        (!imageKey || imageKey.length == 0) &&
        <Pressable
          style={[
            {
              display: 'flex',
              padding: CARD_PADDING_SMALL,
              borderColor: Palette.grey,
              borderWidth: 1,
              borderRadius: 10,
              borderStyle: 'dashed',
            },
            props.imageContainerStyle,
          ]}
          onLayout={onParentLayout}
          onPress={pickImage}
        >
          <Text
            style={{
              width: '100%',
              textAlign: 'center',
              ...commonContext.theme.textVariants.body,
              color: commonContext.theme.colors.primary,
            }}
          >
            {`Choose image`}
          </Text>
          <Text
            style={{
              width: '100%',
              textAlign: 'center',
              ...commonContext.theme.textVariants.body,
              color: Palette.grey,
            }}
          >
            {`(optional)`}
          </Text>
        </Pressable>
      }
      {
        imageKey && imageKey.length > 0 &&
        <View
          style={[
            {
              display: 'flex',
              padding: CARD_PADDING,
              paddingBottom: CARD_PADDING_SMALL,
              borderColor: Palette.grey,
              borderWidth: 1,
              borderRadius: 10,
            },
            props.imageContainerStyle,
          ]}
          onLayout={onParentLayout}
        >
          <View
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ConstrainedUserImage imageKey={imageKey} fallbackUri={imageUri} maxWidth={parentWidth} />
          </View>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-evenly',
            }}
          >
            <NButton
              t={'brightLink'}
              title={'Replace'}
              onPress={pickImage}
              icon={{
                name: 'pencil-outline',
                type: 'material-community',
                color: themeFromContext.colors.primary,
              }}
              buttonStyle={{
                marginRight: 10,
              }}
            />
            <NButton
              t={'brightLink'}
              title={'Remove'}
              titleStyle={{ color: themeFromContext.colors.danger }}
              onPress={() => {
                setImageKey('');
                setImageUri('');
                props.onSave('');
              }}
              icon={{
                name: 'trash-outline',
                type: 'ionicon',
                color: themeFromContext.colors.danger,
              }}
              buttonStyle={{
                marginRight: 10,
              }}
            />
          </View>
        </View>
      }

    </>
  );
}

export default ImageUploader;