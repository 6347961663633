import React, { useContext } from 'react'
import { Button, ButtonProps } from 'react-native-elements';
import CommonContext from '../../../CommonContext';
import { CommonContextType } from '../../../types/CommonContextType';

function AuthPageSecondaryButton(props: ButtonProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  return (
    <Button
      containerStyle={{flex: 1, padding: 10, width: '100%'}}
      buttonStyle={{
        padding: 8,
        marginTop: 8,
        backgroundColor: themeFromContext.colors.background,
        borderRadius: 50,
        borderWidth: 1,
        borderColor: themeFromContext.colors.primary,
      }}
      titleStyle={{
        color: themeFromContext.colors.primary,
        ...themeFromContext.textVariants.authSecondaryButton
      }}
      {...props}
    />
  )
}

export default AuthPageSecondaryButton