import React from 'react'
import { RootStackParamList } from '../../../../types/Navigation';
import { View } from 'react-native';
import NActivityIndicator from '../../../components/ActivityIndicators/NActivityIndicator';
import { DrawerScreenProps } from '@react-navigation/drawer';

export type LoginRedirectProps = DrawerScreenProps<RootStackParamList, 'Login'>;

function LoginRedirect(props: LoginRedirectProps): React.ReactElement {
  return (
    <View style={{
      display: 'flex',
      flexDirection: 'row',
      minHeight: '100%',
      justifyContent: 'center',
      alignContent: 'center',
    }}>
      <NActivityIndicator />
    </View>
  )
}

export default LoginRedirect;
