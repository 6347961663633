// Frameworks
import React, { useContext } from 'react';

// Theme
import { IButtons } from '../../types/Theme';
import CommonContext from '../../CommonContext';
import { Button, ButtonProps } from 'react-native-elements';
import { Palette } from '../../Theme';
import { CommonContextType } from '../../types/CommonContextType';

function NButton(props: { t: keyof IButtons, hidden?: boolean } & ButtonProps) : React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { height, width } = commonContext.dimensions;

  const hidden = props.hidden;
  const disabled = hidden || props.disabled;

  return (
    <Button
      {...props}
      buttonStyle={[
        {
          ...themeFromContext.buttons[props.t].buttonStyle,
          minHeight: height / 20,
          marginHorizontal: 2, // weird hack to make the edges on desktop safari not look cut off
          //minWidth: width / 3,
        },
        props.buttonStyle
      ]}
      titleStyle={[
        {
          ...themeFromContext.buttons[props.t].titleStyle
        },
        props.titleStyle,
      ]}
      disabledStyle={[
        {
          //...themeFromContext.buttons[props.t].buttonStyle,
          borderColor: hidden ? themeFromContext.colors.transparent : Palette.grey,
          backgroundColor: hidden ? themeFromContext.colors.transparent : Palette.lightgrey,
        },
        props.disabledStyle,
      ]}
      disabledTitleStyle={{
        //...themeFromContext.buttons[props.t].titleStyle,
        color: hidden ? themeFromContext.colors.transparent : Palette.grey,
      }}
      iconContainerStyle={{
        display: hidden ? 'none' : 'flex',
      }}
      icon={
        hidden ? undefined : props.icon
      }

      // we'll override disabled if they also specified hidden == true
      disabled={disabled}
    />
  );
}
export default NButton;