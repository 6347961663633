import React, { useContext, useEffect, useRef, useState } from 'react';
import { Text } from 'react-native-elements';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { KeyboardAvoidingView, TextInput, View } from 'react-native';
import { Palette } from '../../../Theme';
import NButton from '../../components/NButton';
import { CommunityQuestionAnswerComment, CommunityQuestionAnswerCommentStatus } from '../../../API';
import { getPowers } from '../../../business/comments/commentPowerRules';
import MemberAvatar from '../../components/MemberAvatar';
import NTextInput from '../../components/NTextInput';
import { MAX_WIDTH } from '../../../business/layout/layout';
import ItemEditButtonMenu from '../../components/ItemEditButtonMenu';
import { getRelativeTimeLabel } from '../../../business/date/dateFormatter';

type CommentEditorProps = {
  comment: CommunityQuestionAnswerComment,
}

function CommentEditor(props: CommentEditorProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { comment } = props;
  const [editLinkEnabled, setEditLinkEnabled] = useState(false);
  const [showEditMode, setShowEditMode] = useState(false);
  const [proposedText, setProposedText] = useState('');
  const [savingComment, setSavingComment] = useState(false);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const commentTextRef = useRef<TextInput>(null);

  useEffect(() => {
    commonContext.services.communityService.getLoggedInUserCommunityRole(comment.communityID)
      .then(r => {
        const p = getPowers(commonContext.isAdmin ? "ADMIN" : r, comment.userID == commonContext.loggedInUser?.id);
        setCanEdit(p.canEditText);
        setCanDelete(p.canDelete);

        if (p.canSee) {
          setProposedText(comment.content);
        }
        setShowEditMode(false);
        setSavingComment(false);
      });
  }, [comment, commonContext.loggedInUser]);

  const pressSave = async (text: string) => {
    setSavingComment(true);
    comment.content = text;
    await commonContext.services.communityService.saveExistingComment(comment);
    setTimeout(() => {
      setShowEditMode(false);
      setSavingComment(false);
    }, 10000);
  }

  const pressCancel = async () => {
    setProposedText(comment.content);
    setShowEditMode(false);
    setSavingComment(false);
  }

  const editComment = () => {
    setShowEditMode(true);
    setTimeout(() => { // this is a bizarre hack I found here:  https://stackoverflow.com/questions/35522220/react-ref-with-focus-doesnt-work-without-settimeout-my-example
      commentTextRef.current?.focus();
    }, 200);
  }

  const deleteComment = async () => {
    setShowEditMode(false);
    setSavingComment(true);
    comment.status = CommunityQuestionAnswerCommentStatus.DELETED;
    await commonContext.services.communityService.saveExistingComment(comment);
    setTimeout(() => {
      setShowEditMode(false);
      setSavingComment(false);
    }, 10000);
  }

  return (
    <KeyboardAvoidingView behavior='padding' keyboardVerticalOffset={70} 
      style={{
        display: 'flex',
        width: '100%',
        maxWidth: MAX_WIDTH,
        paddingHorizontal: 6,
        backgroundColor: themeFromContext.colors.background,
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          width: '100%',
        }}
      >
        <View
          style={{
            paddingVertical: 4,
          }}
        >
          <MemberAvatar
            size={43}
            userData={comment.user}
            ignoreUpdate={true}
            containerStyle={{
              marginRight: 8,
              //backgroundColor: themeFromContext.colors.overlay
            }}
            titleStyle={{
              color: themeFromContext.colors.background
            }}
          />
        </View>
        <View
          style={{
            flex: 1,
          }}
        >
          {
            showEditMode &&
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <View
                style={{
                  flex: 1,
                }}
              >
                <NTextInput
                  ref={commentTextRef}
                  placeholder={`Write a comment...`}
                  multiline
                  value={proposedText}
                  onChangeText={setProposedText}
                  maxLength={500}
                  hideMaxLength
                  disabled={savingComment}
                  focusable={!savingComment}
                  testID={`create-comment-input`}
                  inputStyle={{
                    textAlignVertical: 'center',
                    borderWidth: 0,
                    backgroundColor: Palette.faintgrey,
                    borderRadius: 25,
                    paddingTop: 10,
                    paddingHorizontal: 12,
                  }}
                />
              </View>
              <View>
                <NButton
                  t='brightLink'
                  buttonStyle={{
                    marginLeft: 6,
                  }}
                  titleStyle={{
                    fontSize: 14,
                  }}
                  title={`Save`}
                  loading={savingComment}
                  loadingProps={{
                    color: Palette.teal,
                  }}
                  loadingStyle={{
                    backgroundColor: Palette.transparent,
                  }}
                  disabled={savingComment}
                  disabledStyle={{
                    backgroundColor: Palette.transparent,
                  }}
                  onPress={() => pressSave(proposedText)}
                >
                </NButton>
              </View>
              <View>
                <NButton
                  t='link'
                  buttonStyle={{
                    marginLeft: 6,
                  }}
                  titleStyle={{
                    fontSize: 14,
                    color: Palette.grey,
                  }}
                  title={`Cancel`}
                  //loading={savingComment}
                  // loadingProps={{
                  //   color: Palette.teal,
                  // }}
                  // loadingStyle={{
                  //   backgroundColor: Palette.transparent,
                  // }}
                  disabled={savingComment}
                  disabledStyle={{
                    backgroundColor: Palette.transparent,
                  }}
                  hidden={savingComment}
                  onPress={() => pressCancel()}
                >
                </NButton>
              </View>
            </View>
          }
          {
            !showEditMode &&
            <View
              style={{
                display: 'flex',
                paddingVertical: 4,
                width: '100%',
              }}
            >
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    flexShrink: 1,
                    borderWidth: 2,
                    borderColor: Palette.transparent,
                    backgroundColor: Palette.faintgrey,
                    borderRadius: 25,
                    padding: 15,
                    paddingHorizontal: 16,
                  }}
                >
                  <Text
                    style={{
                      ...themeFromContext.textVariants.body,
                      fontWeight: 'bold',
                      color: themeFromContext.colors.text,
                      marginEnd: themeFromContext.spacing.s,

                    }}
                  >
                    {commonContext.services.userService.getFullName(comment.user)}
                  </Text>
                  <Text
                    style={{
                      ...themeFromContext.textVariants.body,
                      color: themeFromContext.colors.text,
                      marginEnd: themeFromContext.spacing.s,
                    }}
                  >
                    {comment.content}
                  </Text>

                </View>
                <View>
                  <ItemEditButtonMenu getItemToFlag={() => { return comment; }} loading={savingComment} canEdit={canEdit} canDelete={canDelete} onEdit={editComment} onDelete={deleteComment} testID={`edit-item-button`} />
                </View>
              </View>
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Text
                  style={{
                    paddingLeft: 18,
                    ...themeFromContext.textVariants.detail,
                    color: Palette.textgrey,
                  }}
                  numberOfLines={1}
                  testID={`answer-card-header-bottom-created`}
                >
                  {getRelativeTimeLabel(comment.createdAt)}
                </Text>
                {
                  comment.createdAt != comment.updatedAt &&
                  <Text
                    style={{
                      paddingStart: 6,
                      ...themeFromContext.textVariants.detail,
                      color: themeFromContext.colors.secondary
                    }}
                    numberOfLines={1}
                    testID={`answer-card-header-bottom-updated`}
                  >
                    {`(edited)`}
                  </Text>
                }
              </View>
            </View>
          }
        </View>
      </View>
    </KeyboardAvoidingView>
  );
}

export default CommentEditor;