import React from 'react';
import { View } from 'react-native';

function StepContentWrapper(props: { children: React.ReactElement | React.ReactElement[] }): React.ReactElement {
  return (
    <View
      style={{
        width: '100%',
        maxWidth: 500,
        flex: 1,
        //alignItems: 'center',
        alignSelf: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingHorizontal: 8,
      }}
    >
      {props.children}
    </View>
  );
}

export default StepContentWrapper;