import React, { useState, useContext, useEffect } from 'react'
import { View, Image, Linking, Text, Platform, LayoutChangeEvent } from 'react-native'

import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth/lib/types";
import { RootStackParamList } from '../../../../types/Navigation';
import { CommonContextType } from '../../../../types/CommonContextType';
import CommonContext from '../../../../CommonContext';
import CountryDialCodes from '../../../../constants/CountryDialCodes';
import { isValidEmail } from '../../../../business/user/userHelper';
import AuthPageInput from '../AuthPageInput';
import { useLinkTo } from '@react-navigation/native';
import AuthPageTerms from '../AuthPageTerms';
import { preSignUpError } from '../../../../business/errors/preSignUpError';
import NButton from '../../../components/NButton';
import { Palette } from '../../../../Theme';
import log from '../../../../business/logging/logger';
import AsyncStorage from "@react-native-async-storage/async-storage";
import HeaderFirst from '../../../components/Header/HeaderFirst';
import { useSafeAreaInsets } from '../../../../business/layout/layout';
import { normalizeWidth } from '../../../../business/layout/responseSize';
import NText from '../../../components/NText';
import i18n from 'i18n-js';
import AuthGoogleButton from '../AuthGoogleButton';
import AuthDivider from '../AuthDivider';
import PromptModal from '../../../components/Modals/PromptModal';
import AuthPageFooter from '../AuthPageFooter';
import AuthPageSecondaryButton from '../AuthPageSecondaryButton';
import { Picker } from '@react-native-picker/picker';
import { onPressEnter, onPressEnterAsync } from '../../../../business/input/keyPress';
import { DrawerScreenProps } from '@react-navigation/drawer';
import { WEBSITE_HEADER_HEIGHT } from '../../../../constants/header';
import Envelope from '../../../../../assets/svg/illustrations/Envelope';
import QuestionMark from '../../../../../assets/svg/illustrations/QuestionMark';

export type GetStartedProps = DrawerScreenProps<RootStackParamList, 'Start'>;

function Start(props: GetStartedProps): React.ReactElement {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('+1');
  const [successfulSignUp, setSuccessfulSignUp] = useState(false);
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;

  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('')
  const [phoneError, setPhoneError] = useState('');
  const [attemptingSignUp, setAttemptingSignUp] = useState(false);
  const [googleLoading, setGoogleLoading] = useState(false);
  const linkTo = useLinkTo();
  const { top } = useSafeAreaInsets();
  const [headerHeight, setHeaderHeight] = useState(WEBSITE_HEADER_HEIGHT);
  const { width, height } = commonContext.dimensions;
  const [codeError, setCodeError] = useState('');
  //const [code, setCode] = useState('');
  //const [confirmingCode, setConfirmingCode] = useState(false);
  const [signingIn, setSigningIn] = useState(false);
  const [showCodeModal, setShowCodeModal] = useState(false);
  const [showPhoneModal, setShowPhoneModal] = useState(true);
  const [updatingPhone, setUpdatingPhone] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (props.route.params?.email) {
      setEmail(props.route.params.email);
    }
    if (props.route.params?.r) {
      AsyncStorage.setItem('referredBySignUpCode', props.route.params.r, (err: any) => {
        if (err) {
          log.info(`Error trying to set item to Local Forage:`);
          log.info(err);
          //log.info(value);
        }
      });
    }
  }, [props])

  const stepOne_basicData_clientValidation = () => {
    setAttemptingSignUp(true);
    let errored = false;

    // Validate email
    const cleanEmail = email.toLowerCase();
    if (!isValidEmail(cleanEmail)) {
      setEmailError(`Please enter a valid email address`);
      errored = true;
    } else {
      setEmailError('');
    }
    setEmail(cleanEmail);

    // Validate first name
    if (firstName == '') {
      setFirstNameError(`First name is required`);
      errored = true;
    } else {
      setFirstNameError('');
    }

    // Validate last name
    if (lastName == '') {
      setLastNameError(`Last name is required`);
      errored = true;
    } else {
      setLastNameError('');
    }

    // Validate password
    setPasswordError('');

    if (password1 !== password2) {
      setPasswordError(`Passwords must match!`);
      errored = true;
    } else if (password1 == '' || password2 == '') {
      setPasswordError(`Password cannot be empty`);
      errored = true;
    }

    if (errored) {
      setAttemptingSignUp(false);
      return;
    } else {

      if (phone.length == 0) {
        setAttemptingSignUp(false);
        setShowPhoneModal(true);
      } else {
        stepTwo_signUpWithPhone();
      }
    }
  }

  const stepTwo_signUpWithPhone = async () => {
    let errored = false;
    setUpdatingPhone(true);

    // Validate phone
    let cleanPhone = phone.replace(/[-.() ]*/gm, '');
    // strip country code if present
    let code = CountryDialCodes.find(c => cleanPhone.startsWith(c));
    if (code) {
      setCountryCode(code);
      cleanPhone = cleanPhone.substring(code.length);
    } else {
      code = countryCode;
    }
    setPhone(cleanPhone);
    // make sure enough digits were provided
    if (cleanPhone.length != 10) {
      setPhoneError(`Please enter a valid phone number`);
      errored = true;
    } else {
      setPhoneError('');
    }
    if (!errored) {
      const userAttributes = {
        email: email,
        given_name: firstName,
        family_name: lastName,
        phone_number: cleanPhone.length > 0 ? code + cleanPhone : ''
      };
      await signUpWithAttributes(userAttributes);
    }

    setUpdatingPhone(false);
  }

  const signUpWithAttributes = async (userAttributes: any) => {
    setPasswordError('');
    setEmailError('');
    setAttemptingSignUp(true);
    Auth.signUp({
      username: email,
      password: password1,
      attributes: userAttributes
    })
      .then(() => {
        setUpdatingPhone(false);
        setShowPhoneModal(false);
        setAttemptingSignUp(false);
        setShowCodeModal(true);
      })
      .catch((err) => {
        if (err.name == 'InvalidPasswordException') {
          setPasswordError(err.message);
        } else if (err.name == 'UsernameExistsException') {
          setEmailError(err.message);
        } else if (err.name == 'UserLambdaValidationException') {
          setEmailError(preSignUpError(err.message));
        } else {
          //log.info(err);
          setEmailError(err.message);
        }

        setUpdatingPhone(false);
        setShowPhoneModal(false);
        setAttemptingSignUp(false);
      });
  }

  const stepTwo_signUpNoPhone = async () => {
    setPhoneError('');
    setPhone('');

    const userAttributes = {
      email: email,
      given_name: firstName,
      family_name: lastName,
    };

    await signUpWithAttributes(userAttributes);
  }

  const confirmCodeAndSignIn = async () => {
    // confirm code
    // try {
    //   setConfirmingCode(true);
    //   if (code.length == 0) {
    //     setCodeError(`Code cannot be empty!`);
    //     setConfirmingCode(false);
    //     return;
    //   }
    //   await Auth.confirmSignUp(email, code);
    //   setConfirmingCode(false);
    // } catch (ex: any) {
    //   if (ex.name == 'CodeMismatchException') {
    //     setCodeError(ex.message);
    //   } else if (ex.name == 'UserNotFoundException') {
    //     setCodeError(`Invalid email / code combination`);
    //   } else {
    //     //log.info(`Unknown sign-in error: ${err.name}`);
    //     setCodeError(`Unknown error '${ex.name}: ${ex.message}! Please try again...`);
    //     log.info(JSON.stringify(ex, null, 2));
    //   }
    //   setConfirmingCode(false);
    //   return;
    // }

    // sign in
    try {
      setSigningIn(true);
      await Auth.signIn(email, password1);
      setSigningIn(false);
    } catch (ex: any) {
      if (ex.name == 'UserNotConfirmedException') {
        setCodeError(`Please check your email and click the verification link before signing in`);
      } else if (ex.name == 'UserNotFoundException' || ex.name == 'NotAuthorizedException') {
        setCodeError(`Invalid email / password combination`);
      } else {
        //log.info(`Unknown sign-in error: ${err.name}`);
        setCodeError(`Unknown error '${ex.name}: ${ex.message}! Please try again...`);
        log.info(JSON.stringify(ex, null, 2));
      }
      setSigningIn(false);
      return;
    }

    setShowCodeModal(false);
  }

  const onLayout = (event: LayoutChangeEvent) => {
    setContentHeight(event.nativeEvent.layout.height);
  }

  return (
    <>
      <View style={{ position: 'absolute', top: 0, height: top, width: '100%', backgroundColor: Palette.plum, zIndex: 10 }} />
      <HeaderFirst
        height={headerHeight}
        maxWidth={'100%'}
        logoHeight={50}
        navigation={props.navigation}
        top={top}
        goBack={() => {
          if (Platform.OS == 'web') {
            window.open('https://meetnovella.com' , "_self");
          } else {
            Linking.openURL('https://meetnovella.com')
          }
        }}
        goBackIconName={'home'}
        menuAction={() => linkTo(`/signin`)}
        menuIconName={'account-circle'}
        menuButtonTitle={'Sign In'}
      />
      <View
        style={{
          display: 'flex',
          alignItems: 'center',
          position: 'absolute',
          top: headerHeight,
          width: '100%',
          paddingVertical: normalizeWidth(30, width),
          paddingHorizontal: normalizeWidth(30, width),
          backgroundColor: themeFromContext.colors.background,
        }}
        onLayout={onLayout}
      >
        <NText
          style={{
            ...themeFromContext.textVariants.header,
            textAlign: 'center',
            color: Palette.plum,
          }}
          testID={`get-started-header`}
        >
          {i18n.t('Page_GetStarted_Header')}
        </NText>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-evenly',
            alignItems: 'center',
            width: '100%',
            maxWidth: 1200,
            paddingVertical: normalizeWidth(20, width),
          }}
        >
          <View
            style={{
              flex: 3,
              alignItems: 'center',
              justifyContent: 'center',
              margin: 6,
            }}
          >
            <Image
              source={require('../../../../../assets/svg/illustrations/AddMember.svg')}
              resizeMode='contain'
              style={{
                width: '100%',
                height: normalizeWidth(100, width),
              }}
            />
            <NText
              style={{
                ...themeFromContext.textVariants.body,
                fontWeight: '900',
                color: Palette.plum,
                textAlign: 'center',
                paddingTop: normalizeWidth(4, width),
              }}
            >
              {i18n.t('Page_GetStarted_StepName_SignUp')}
            </NText>
          </View>
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              source={require('../../../../../assets/svg/icons/RightArrow2.svg')}
              resizeMode='contain'
              style={{
                width: '100%',//normalizeHeight(343, height),
                height: 32,
                tintColor: Palette.lightpurple,
              }}
            />
          </View>
          <View
            style={{
              flex: 3,
              alignItems: 'center',
              justifyContent: 'center',
              margin: 6,
            }}
          >
            <Image
              source={require('../../../../../assets/svg/illustrations/People.svg')}
              resizeMode='contain'
              style={{
                width: '100%',
                height: normalizeWidth(100, width),
              }}
            />
            <NText
              style={{
                ...themeFromContext.textVariants.body,
                color: Palette.plum,
                textAlign: 'center',
                paddingTop: normalizeWidth(4, width),
              }}
            >
              {i18n.t('Page_GetStarted_StepName_SetupCommunity')}
            </NText>
          </View>
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Image
              source={require('../../../../../assets/svg/icons/RightArrow2.svg')}
              resizeMode='contain'
              style={{
                width: '100%',//normalizeHeight(343, height),
                height: 32,
                tintColor: Palette.lightpurple,
              }}
            />
          </View>
          <View
            style={{
              flex: 3,
              alignItems: 'center',
              justifyContent: 'center',
              margin: 6,
            }}
          >
            <Image
              source={require('../../../../../assets/svg/illustrations/Message.svg')}
              resizeMode='contain'
              style={{
                width: '100%',
                height: normalizeWidth(100, width),
              }}
            />
            <NText
              style={{
                ...themeFromContext.textVariants.body,
                color: Palette.plum,
                textAlign: 'center',
                paddingTop: normalizeWidth(4, width),
              }}
            >
              {i18n.t('Page_GetStarted_StepName_InviteFamily')}
            </NText>
          </View>
        </View>

        <NText
          style={{
            ...themeFromContext.textVariants.body,
            textAlign: 'center',
            color: themeFromContext.colors.text,
            maxWidth: 1000,
          }}
          testID={`get-started-body`}
        >
          {i18n.t('Page_GetStarted_Encouragement')}
        </NText>
        <View
          style={{
            display: 'flex',
            width: '100%',
            maxWidth: 400,
            padding: 30,
          }}
        >

          <AuthGoogleButton
            testID='get-started-google-button'
            loading={googleLoading}
            title={i18n.t('Page_GetStarted_Button_ContinueGoogle')}
            onPress={() => {
              setGoogleLoading(true);
              Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
            }}
          />

          <AuthDivider text={i18n.t('Page_GetStarted_Or')} />

          <AuthPageInput
            //label='First Name *'
            onChangeText={setFirstName}
            placeholder={i18n.t('Page_GetStarted_FirstName_Watermark')}
            //autoCompleteType='name'
            keyboardType='default'
            textContentType='givenName'
            autoCapitalize='words'
            autoCorrect={false}
            errorMessage={firstNameError}
            testID='get-started-first-name-input'
          />
          <AuthPageInput
            //label='Last Name *'
            onChangeText={setLastName}
            placeholder={i18n.t('Page_GetStarted_LastName_Watermark')}
            //autoCompleteType='name'
            keyboardType='default'
            textContentType='familyName'
            autoCapitalize='words'
            autoCorrect={false}
            errorMessage={lastNameError}
            testID='get-started-last-name-input'
          />
          <AuthPageInput
            //label='Email *'
            value={email}
            onChangeText={setEmail}
            placeholder={i18n.t('Page_GetStarted_Email_Watermark')}
            autoCompleteType='email'
            keyboardType='email-address'
            textContentType='emailAddress'
            autoCapitalize='none'
            autoCorrect={false}
            errorMessage={emailError}
            testID='get-started-email-input'
          />
          <AuthPageInput
            //label='Password *'
            onChangeText={setPassword1}
            placeholder={i18n.t('Page_GetStarted_Password_Watermark')}
            autoCompleteType='password'
            //keyboardType='visible-password'
            textContentType='password'
            autoCapitalize='none'
            autoCorrect={false}
            secureTextEntry
            passwordRules='required: lower; required: digit; minlength: 7;'
            testID='get-started-password-input'
          />
          <AuthPageInput
            onChangeText={setPassword2}
            placeholder={i18n.t('Page_GetStarted_ConfirmPassword_Watermark')}
            autoCompleteType='password'
            //keyboardType='visible-password'
            textContentType='password'
            autoCapitalize='none'
            autoCorrect={false}
            secureTextEntry
            passwordRules='required: lower; required: digit; minlength: 7;'
            errorMessage={passwordError}
            testID='get-started-confirm-password-input'
            onKeyPress={async (e) => onPressEnter(e, stepOne_basicData_clientValidation)}
          />
          <AuthPageTerms />
          <NButton
            testID='get-started-create-account-button'
            style={{ paddingTop: 10 }}
            containerStyle={{ padding: 10, width: '100%' }}
            t='primary'
            onPress={stepOne_basicData_clientValidation}
            title={i18n.t('Page_GetStarted_Button_Continue')}
            loading={attemptingSignUp || signingIn}
          />
          <AuthDivider />
          <Text
            style={{
              //marginEnd: 8,
              marginVertical: 8,
              color: themeFromContext.colors.secondary,
              textAlign: 'center',
              ...themeFromContext.textVariants.inputLabel
            }}>
              {i18n.t('Page_GetStarted_ExistingAccount_Label')}
          </Text>
          <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <AuthPageSecondaryButton
              onPress={() => linkTo(`/signIn${email ? '?email=' + email : ''}`)}
              title={i18n.t('Page_GetStarted_Button_SignIn')}
              testID='get-started-sign-in-button'
            />
            <AuthPageSecondaryButton
              onPress={() => linkTo(`/confirmCode${email ? '?email=' + email : ''}`)}
              title={i18n.t('Page_GetStarted_Button_ConfirmCode')}
              testID='get-started-confirm-code-button'
            />
          </View>
        </View>
        <AuthPageFooter />
      </View>
      <PromptModal
        confirm={confirmCodeAndSignIn}
        working={signingIn}
        confirmButtonText={i18n.t('Page_GetStarted_Code_Modal_Button_Continue')}
        heading={i18n.t('Page_GetStarted_Code_Modal_Header')}
        prompt={i18n.t('Page_GetStarted_Code_Modal_Body')}
        show={showCodeModal}
        backdropHeight={contentHeight + headerHeight + top}
        testID={`get-started-confirm-code-modal`}
      >
        <Envelope />
        {/* <Image
          source={require('../../../assets/svg/illustrations/Envelope.svg')}
          resizeMode='contain'
          style={{
            width: '100%',
            height: normalizeWidth(100, width),
            marginBottom: 25,
          }}
        /> */}
        <Text
          style={{
            ...themeFromContext.textVariants.body,
            textAlign: 'center',
            color: Palette.red,
          }}
          testID='prompt-modal-text'
        >
          {codeError}
        </Text>
      </PromptModal>
      <PromptModal
        confirm={stepTwo_signUpWithPhone}
        confirmButtonText={i18n.t('Page_GetStarted_Phone_Modal_Button_Ok')}
        deny={stepTwo_signUpNoPhone}
        denyButtonText={i18n.t('Page_GetStarted_Phone_Modal_Button_NoThanks')}
        working={updatingPhone || attemptingSignUp}
        heading={i18n.t('Page_GetStarted_Phone_Modal_Header')}
        prompt={i18n.t('Page_GetStarted_Phone_Modal_Body')}
        show={showPhoneModal}
        backdropHeight={contentHeight + headerHeight + top}
        testID={`get-started-phone-number-modal`}
      >
        <QuestionMark />
        {/* <Image
          source={require('../../../assets/svg/illustrations/QuestionMark.svg')}
          resizeMode='contain'
          style={{
            width: '100%',
            height: normalizeWidth(100, width),
            marginBottom: 25,
          }}
        /> */}
        <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
          <Picker
            mode='dropdown'
            style={{
              borderRadius: 3,
              borderColor: themeFromContext.colors.secondary,
              borderWidth: 1,
              color: themeFromContext.colors.foreground,
              fontFamily: themeFromContext.textVariants.inputLabel.fontFamily,
              fontSize: themeFromContext.textVariants.inputLabel.fontSize,
              fontWeight: themeFromContext.textVariants.inputLabel.fontWeight,
              padding: Platform.OS === 'ios' ? 0 : themeFromContext.spacing.z,
              marginHorizontal: 10,
            }}
            selectedValue={countryCode}
            onValueChange={setCountryCode}
          >
            {
              CountryDialCodes.map((c) => (
                <Picker.Item key={c} label={c} value={c} />
              ))
            }
          </Picker>
          <AuthPageInput
            containerStyle={{ flex: 1, flexGrow: 1 }}
            onChangeText={setPhone}
            value={phone}
            placeholder={i18n.t('Page_GetStarted_Phone_Modal_Phone_Watermark')}
            autoCompleteType='tel'
            keyboardType='phone-pad'
            textContentType='telephoneNumber'
            autoCapitalize='none'
            autoCorrect={false}
            errorMessage={phoneError}
            testID='get-started-phone-number-input'
            onKeyPress={async(e) => onPressEnterAsync(e, stepTwo_signUpWithPhone)}
          />
        </View>
      </PromptModal>
    </>
  )
}

export default Start;
