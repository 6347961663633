import React, { useContext, useEffect, useState } from 'react';
import { Image, NativeScrollEvent, NativeSyntheticEvent, Text, View } from 'react-native';
import { Button, Icon } from 'react-native-elements';
import { Community, CommunityUser, NextStepType, User } from '../../../API';
import CommonContext from '../../../CommonContext';
import { CommonContextType } from '../../../types/CommonContextType';
import CommunityListItem from './CommunityListItem';
import { HomeStackParamList } from '../../../types/Navigation';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import { Dictionary } from '../../../types/data/Dictionary';
import { normalizeHeight } from '../../../business/layout/responseSize';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import ScrollableFlatListScreen from '../../components/Layouts/ScrollableFlatListScreen';
import NFAB, { FABPosition } from '../../components/NFAB';
import { Palette } from '../../../Theme';
import { parse } from 'json2csv';
import { CSVLink } from "react-csv";
import log from '../../../business/logging/logger';
import PromptModal from '../../components/Modals/PromptModal';
import { extractNextStepsFromUser } from '../../../business/user/userHelper';
import NFAB2 from '../../components/NFAB2';
import { FABScrollHelper } from '../../../business/scroll/fabScrollHelper';

const HELP_NAME = 'REFERRAL_PROMPT';

type MyCommunitiesProps = NativeStackScreenProps<HomeStackParamList, 'My Communities'>;

function CommunitiesScreen(props: MyCommunitiesProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { route, navigation } = props;
  const [communityDataForUser, setCommunityDataForUser] = useState<Community[]>([])
  const [communityToMembersMap, setCommunityToMembersMap] = useState<Dictionary<CommunityUser[]>>({});
  // const [isAdmin, setIsAdmin] = useState(false);
  const [refreshing, setRefreshing] = useState(true);
  const linkTo = useLinkTo();
  const isFocus = useIsFocused();
  //const { height } = useContext<CommonContextType>(CommonContext).dimensions;
  const { height } = useContext<CommonContextType>(CommonContext).dimensions;
  const [csvData, setCsvData] = useState('');
  const [csvFileName, setCsvFileName] = useState('');
  //const [showReferralModal, setShowReferralModal] = useState(false);
  //const [loggedInUser, setLoggedInUser] = useState<User>();
  const [dismissedReferralModal, setDismissedReferralModal] = useState(false);
  const [nextStepMap, setNextStepMap] = useState<Dictionary<NextStepType[]>>({});
  const { userService } = commonContext.services;

  const userId = route.params.userId;

  // TODO: BAD! Not the right place or manner for security!!!
  const isAllowed = (loggedInUserId: string): boolean => {
    return (loggedInUserId === userId);
  }

  useEffect(() => {
    setRefreshing(true);
    if (isFocus) {
      commonContext.analytics?.viewPage(route.name, route.params);
      const user = commonContext.loggedInUser;
      if (user) {
        if (!isAllowed(user.id)) {
          linkTo(`/communities/${user?.id}`);
        } else {
          
          // NOTE: TEMPORARILY COMMENTING THIS OUT BECAUSE WE DON'T WANT THE REFERRAL MODAL POPPING UP AT THIS POINT
          //handleSeen(user);
        }
      }
      const communityUserData = commonContext.communities;
      const communityArray = Object.values(communityUserData);
      communityArray.sort((a, b) => {
        if (a.createdAt && b.createdAt) {
          if (a.createdAt < b.createdAt) {
            return 1;
          } else if (a.createdAt > b.createdAt) {
            return -1;
          } else {
            return 0;
          }
        } else {
          return 0;
        }
      });
      setCommunityDataForUser(communityArray);
      setCommunityToMembersMap(commonContext.membership);

      //log.info(`My Communities, loggedInUser:`);
      //log.info(JSON.stringify(commonContext.loggedInUser, null, 2));
      if (commonContext.loggedInUser) {
        //log.info(`My Communities, get next steps:`);
        const map = extractNextStepsFromUser(commonContext.loggedInUser, commonContext.membership);
        log.info(JSON.stringify(map, null, 2));
        setNextStepMap(map);
      }

      setRefreshing(false);
    }
  }, [userId, isFocus, commonContext.membership, commonContext.loggedInUser]);

  const handleSeen = async (user: User) => {
    // get all the welcomes the logged-in user has already seen
    const seen = user.helpsSeen ? user.helpsSeen.map(s => s.toUpperCase()) : [];

    log.info(`seen:`);
    log.info(seen);

    if (!seen.includes(HELP_NAME) && !dismissedReferralModal) {
      // perform logic for this help
      // log.info(`needs help: ${HELP_NAME}`);
      // log.info(`user.id: ${user.id}`);

      const qs = await userService.getQuestionsByUser(user.id);
      // log.info(qs);

      if (qs && qs.length > 0 && !commonContext.showingModal) {
        // show modal
        //commonContext.setShowingModal(HELP_NAME);
        //setShowReferralModal(true);
      }
    }
  }

  const goToCreate = () => {
    linkTo(`/create/${userId}/`)
  }

  type CommunityMemberExport = {
    CommunityName: string
    UserFirstName: string
    UserLastName: string
    UserEmail?: string
  }

  const downloadCommunitiesAsCSV = async () => {
    let fileName = '';
    let csv = '';
    for (let index = 0; index < communityDataForUser.length; index++) {
      const c = communityDataForUser[index];
      const members = communityToMembersMap[c.id];
      for (let i2 = 0; i2 < members.length; i2++) {
        const payload: CommunityMemberExport = {
          CommunityName: c.name,
          UserEmail: members[i2].user.email ?? undefined,
          UserFirstName: members[i2].user.firstName,
          UserLastName: members[i2].user.lastName,
        }

        if (index === 0 && i2 === 0) {
          const columnHeaders = Object.keys(payload).join(',');
          csv = csv.concat(columnHeaders, '\n');
          fileName = encodeURIComponent(`AllCommunities`);
        }

        const rowValues = parse(payload, { header: false });
        csv = csv.concat(rowValues, '\n');
      }
    }
    //log.info(fileName);
    //log.info(csv);

    setCsvData(csv);
    setCsvFileName(`${fileName}.csv`);
  }

  const [showButton, setShowButton] = useState(true);
  const [fabScroller] = useState(new FABScrollHelper(setShowButton));
  function handleScroll(event: NativeSyntheticEvent<NativeScrollEvent>): void {
    fabScroller.handleScroll(event);
  }

  return (
    <ScrollableFlatListScreen
      navigation={props.navigation}
      data={communityDataForUser}
      extraData={communityToMembersMap}
      topTitle={route.name}
      refreshing={refreshing}
      isFocused={isFocus}
      initialNumToRender={20}
      onScroll={handleScroll}
      renderItem={({ item, index, separators }) => {
        return (
          <CommunityListItem
            key={item.id}
            //role={item.status}
            community={item}
            members={communityToMembersMap[item.id]}
            nextSteps={nextStepMap[item.id] && nextStepMap[item.id].length > 0 ? nextStepMap[item.id].length : undefined}
            index={index}
          />
        )
      }}
      ListHeaderComponent={() => (
        <View
          style={{
            width: '100%',
          }}
        >
          {
            commonContext.isAdmin &&
            <View style={{ flex: 1, width: '100%', alignItems: 'center', paddingBottom: 10 }}>
              <Button
                buttonStyle={{
                  backgroundColor: themeFromContext.colors.background,
                }}
                title={`Generate CSV`}
                titleStyle={{
                  color: themeFromContext.colors.primary,
                  ...themeFromContext.textVariants.authSecondaryButton
                }}
                onPress={downloadCommunitiesAsCSV}
              />
              {
                csvData != '' && csvFileName != '' &&
                <CSVLink
                  style={{
                    padding: 10,
                    backgroundColor: themeFromContext.colors.background,
                    borderWidth: 1,
                    borderRadius: 25,
                    borderColor: themeFromContext.colors.primary,
                    borderStyle: 'solid',
                    color: themeFromContext.colors.primary,
                    fontFamily: themeFromContext.textVariants.authSecondaryButton.fontFamily,
                    fontSize: themeFromContext.textVariants.authSecondaryButton.fontSize,
                    fontWeight: 'normal',
                    textDecoration: 'none'
                  }}
                  data={csvData}
                  filename={csvFileName}
                >
                  {`Download`}
                </CSVLink>
              }
            </View>
          }
        </View>
      )}
      ListEmptyComponent={() => (
        <View style={{
          alignItems: 'center',
          //height: '100%',
          justifyContent: 'center',
          padding: normalizeHeight(30, height),
          paddingBottom: normalizeHeight(100, height),
        }}>
          <Image
            source={require('../../../../assets/communities/Blank_Community_Screen_Warming_75_Opacity.png')}
            defaultSource={require('../../../../assets/communities/Blank_Community_Screen_Warming_75_Opacity.png')}
            resizeMode='contain'
            resizeMethod='scale'
            style={{
              width: '100%',//normalizeHeight(343, height),
              height: normalizeHeight(200, height),
              //marginTop: normalizeHeight(20, height),
              marginBottom: normalizeHeight(30, height),
            }}
            testID='communities-empty-list-image'
          />
          <Text
            style={{
              ...themeFromContext.textVariants.header,
              color: Palette.white,
              textAlign: 'center',
              marginVertical: themeFromContext.spacing.m,
            }}
            testID='communities-empty-list-heading'
          >
            {`Welcome to Novella!`}
          </Text>
          <Text
            style={{
              ...themeFromContext.textVariants.body,
              color: Palette.white,
              textAlign: 'center',
              marginVertical: themeFromContext.spacing.s
            }}
          >
            {`Please use the button below to create your first community and invite others to join.`}
          </Text>
        </View>
      )}
      ListFooterComponent={() => (
        <View style={{
          minHeight: normalizeHeight(160, height),
          alignItems: 'center',
          paddingVertical: themeFromContext.spacing.m
        }} />)
      }
    >
      <NFAB2
        isVisible={showButton}
        hasBottomBar={false}
        left={
          <Icon
            name='add'
            type='ionicon'
            color={Palette.plum}
            size={32}
          />
        }
        right={
          <Text
            style={{
              fontSize: 20,
              color: Palette.plum,
            }}
          >
            {`Create new`}
          </Text>
        }
        onPress={goToCreate}
      />
      {/* <NFAB
        position={FABPosition.CENTER}
        expand={true}
        title={`Create a Community`}
        testID='communities-create-new-button'
        onPress={goToCreate}
        titleStyle={{
          fontSize: 14,
          fontWeight: '700',
        }}
        buttonStyle={{
          marginVertical: 10,
          paddingVertical: 18,
          marginHorizontal: 24,
        }}
      /> */}
      <PromptModal
        show={commonContext.showingModal == HELP_NAME}
        heading={`Enjoying Novella?`}
        prompt={`Refer a friend and earn cash rewards!`}
        confirmButtonText={`Tell me more`}
        confirm={() => {
          //setShowReferralModal(false);
          if (commonContext.showingModal == HELP_NAME) {
            commonContext.setShowingModal();
          }
          if (commonContext.loggedInUser) {
            userService.flagHelpAsSeen(HELP_NAME, commonContext.loggedInUser);
            setDismissedReferralModal(true);
            //setShowReferralModal(false);
            linkTo(`/rewards/${commonContext.loggedInUser.id}`);
          }
        }}
        denyButtonText={`Maybe later`}
        deny={() => {
          setDismissedReferralModal(true);
          if (commonContext.showingModal == HELP_NAME) {
            commonContext.setShowingModal();
          }
          //setShowReferralModal(false);
        }}
        testID={`prompt-modal-refer-a-friend`}
      />
    </ScrollableFlatListScreen>
  );
}

export default CommunitiesScreen;