import React, { useContext, useEffect, useState } from 'react'
import { Image, LayoutChangeEvent, Platform, SafeAreaView, View } from 'react-native'
import { RootStackParamList } from '../../../../types/Navigation';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import { Button, Icon, Text } from 'react-native-elements';
import { CommonContextType } from '../../../../types/CommonContextType';
import CommonContext from '../../../../CommonContext';
import NButton from '../../../components/NButton';
import { Palette } from '../../../../Theme';
import { DrawerScreenProps } from '@react-navigation/drawer';
import { getCurrentEnv } from '../../../../business/env/currentEnv';
import { DISPLAY, PHONE_BREAK, TABLET_BREAK, useSafeAreaInsets } from '../../../../business/layout/layout';
import SlideUpView2 from '../../../components/Modals/SlideUpView2';
import VimeoPlayer from '../../../components/VimeoPlayer/VimeoPlayer';
import { PressableOpacity } from 'react-native-pressable-opacity';
import IonIcon from 'react-native-vector-icons/Ionicons';

const fallbackVideo = require('../../../../../assets/introducing_novella.mp4');

export type QuickStartInnerProps = DrawerScreenProps<RootStackParamList, 'Quick Start'>;

function QuickStartInner(props: QuickStartInnerProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const linkTo = useLinkTo();
  const [env, setEnv] = useState(getCurrentEnv());
  const { width, height } = commonContext.dimensions;
  const display = width > TABLET_BREAK ? DISPLAY.WEB : width <= PHONE_BREAK ? DISPLAY.PHONE : DISPLAY.TABLET;
  const [showHowItWorks, setShowHowItWorks] = useState(false);
  const { bottom } = useSafeAreaInsets();
  const [videoHeight, setVideoHeight] = useState(0);

  useEffect(() => {
    if (commonContext.loggedInUser) {
      props.navigation.navigate('Home');
    }
  }, [commonContext.loggedInUser]);

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      // analytics
      commonContext.analytics?.viewPage('Landing Page', {});
    }
  }, [isFocused]);

  function goToGettingStarted(): void {
    linkTo(`/getting-started`);
  }

  const onLayout = (event: LayoutChangeEvent) => {
    const { height } = event.nativeEvent.layout;
    setVideoHeight(height);
  };

  const pressHowItWorks = () => {
    setShowHowItWorks(true);
    commonContext.analytics?.viewPage('How It Works', {});
  }

  return (
    <SafeAreaView style={{
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      flex: 1,
      zIndex: 1,
    }}>
      <View
        style={{
          //alignSelf: 'center',
          flex: 1,
          display: 'flex',
          // minHeight: '100%',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1,
        }}
      >
        <View
          style={{
            // flex: 1,
            flexGrow: display == DISPLAY.PHONE ? 1 : 0,
            width: '100%',
            maxWidth: display == DISPLAY.PHONE ? '100%' : 400,
            alignItems: 'center',
            //backgroundColor: themeFromContext.colors.background,
            borderRadius: 10,
            paddingHorizontal: 30,
            // paddingTop: top,
            // paddingBottom: bottom,
            justifyContent: 'center',
            zIndex: 1,
          }}
        >
          <Image
            source={require('../../../../../assets/logo-white-teal-alpha.png')}
            resizeMode='contain'
            style={{ height: height / 10, width: '100%', marginBottom: 20, marginTop: Platform.OS == 'web' ? -50 : -50, }}
          />

          <Text
            style={{
              color: Palette.white,
              marginTop: -10,
              marginBottom: 20,
              ...themeFromContext.textVariants.askQuestionHeader,
            }}
          >
            {`Capture Your Family's Story`}
          </Text>
          <View
            style={{
              display: 'flex'
            }}
          >
            <NButton
              t={'action'}
              title={`Get Started`}
              titleStyle={{
                textTransform: 'uppercase',
              }}
              // iconPosition={'left'}
              // icon={
              //   <Icon
              //     name='add'
              //     type='ionicon'
              //     color={Palette.plum}
              //     size={height / 28}
              //   />
              // }
              onPress={goToGettingStarted}
            />
            <NButton
              t={'secondary2'}
              title={`How It Works`}
              titleStyle={{
                textTransform: 'uppercase',
              }}
              style={{
                width: '100%',
              }}
              onPress={pressHowItWorks}
            />


            <View
              style={{
                width: '80%',
                marginTop: 15,
              }}
            >
            </View>

            <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
              <View style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'center' }}>
                <Text
                  style={{
                    //marginEnd: 8,
                    marginVertical: 8,
                    color: Palette.white,
                    ...themeFromContext.textVariants.inputLabel,
                    fontWeight: 'normal',
                  }}>
                  {`Already have an account? `}
                </Text>
                <Button
                  testID='sign-in-button'
                  buttonStyle={{
                    backgroundColor: Palette.transparent,
                    marginVertical: 0,
                    padding: 0,
                    borderWidth: 0
                  }}
                  titleStyle={{
                    marginVertical: 8,
                    paddingVertical: 0,
                    color: Palette.lightteal,
                    alignSelf: 'flex-start',
                    ...themeFromContext.textVariants.inputLabel,
                    //fontWeight: 'normal',
                  }}
                  onPress={() => {
                    linkTo(`/signin`)
                  }}
                  title='Sign In'
                />
              </View>
            </View>

            {/* <View
              style={{
              }}
            >
              <PressableOpacity
                style={{
                  width: 80,
                  height: 80,
                  borderRadius: 80 / 2,
                  backgroundColor: 'rgba(0, 0, 0, 0.3)',
                  justifyContent: 'center',
                  alignItems: 'center',
                  position: 'absolute',
                  alignSelf: 'center',
                  marginTop: 20,
                }}
                hitSlop={10}
                onPress={() => {
                  linkTo(`/scan`);
                }}
                disabledOpacity={0.4}
              >
                <IonIcon name="qr-code-outline" color="white" size={40} />
              </PressableOpacity>
            </View> */}


          </View>
        </View>
      </View>
      <SlideUpView2
        show={showHowItWorks}
        onDone={async () => setShowHowItWorks(false)}
        title={`How It Works`}
        onLayout={onLayout}
        doneButtonIcon={
          <Icon
            name='times-circle'
            type='font-awesome'
            color={'#313067'}
            size={30}
          />
        }
      >
        {/* <Text style={[commonContext.theme.textVariants.header, { textAlign: 'center', fontSize: 18, marginTop: 10, marginBottom: 20, }]}>{`How It Works`}</Text> */}
        <VimeoPlayer
          embedUrl={`https://player.vimeo.com/video/778647119?h=cf51a0b614&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
          asset={fallbackVideo}
          height={videoHeight}
          width={videoHeight * 0.5647}
          transcodingStatus={'complete'}
          heightConstrained={false}
        />
      </SlideUpView2>
    </SafeAreaView>
  )
}

export default QuickStartInner;
