import React, { useContext, useEffect, useRef, useState } from 'react';
import { Text, View, FlatList, NativeSyntheticEvent, TextInputKeyPressEventData, TextInput, Pressable } from 'react-native';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { Chip, Icon, Input, ListItem } from 'react-native-elements';
import log from '../../business/logging/logger';
import MemberAvatar from './MemberAvatar';
import { isValidEmail, RelatedUserData } from '../../business/user/userHelper';
import { Palette } from '../../Theme';
import NButton from './NButton';
import { ScrollView } from 'react-native-gesture-handler';
import { Dictionary } from '../../types/data/Dictionary';
import NActivityIndicator from './ActivityIndicators/NActivityIndicator';
import { normalizeHeight } from '../../business/layout/responseSize';
import NTextInput from './NTextInput';
import { v4 as uuid } from 'uuid';

function RelatedUserPicker(props: {
  fullList: RelatedUserData[] | undefined,
  alreadyPicked: RelatedUserData[] | undefined,
  onSelectionChanged: (users: RelatedUserData[]) => void
}): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const [searchText, setSearchText] = useState('');
  const [pickerData, setPickerData] = useState<RelatedUserData[]>([]);
  const [top, setTop] = useState<Dictionary<Set<RelatedUserData>>>({});
  const [filteredData, setFilteredData] = useState<RelatedUserData[]>([]);
  const [refreshList, setRefreshList] = useState(false);
  const [activeItemIndex, setActiveItemIndex] = useState(-1);
  const flatListRef = useRef<FlatList<RelatedUserData>>(null);
  const [emailToAdd, setEmailToAdd] = useState('');
  const [firstNameToAdd, setFirstNameToAdd] = useState('');
  const [lastNameToAdd, setLastNameToAdd] = useState('');
  const [addEmailError, setAddEmailError] = useState('');
  const [addFirstNameError, setAddFirstNameError] = useState('');
  const [addLastNameError, setAddLastNameError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [selected, setSelected] = useState(new Set<RelatedUserData>())
  const [showAddEmail, setShowAddEmail] = useState(true);
  const firstNameTextRef = useRef<TextInput>(null);
  const [fullList, setFullList] = useState<RelatedUserData[]>([]);
  const { height } = commonContext.dimensions;
  const [addButtonDisabled, setAddButtonDisabled] = useState(true);
  const [showSearchBar, setShowSearchBar] = useState(false);

  useEffect(() => {
    setAddButtonDisabled(!(emailToAdd.length > 0 || (firstNameToAdd.length > 0 && lastNameToAdd.length > 0)));
  }, [emailToAdd, firstNameToAdd, lastNameToAdd]);

  useEffect(() => {
    setShowSearchBar(fullList.length != selected.size);
  }, [fullList, selected]);

  useEffect(() => {
    if (props.fullList) {
      setIsLoading(false);
      extractRelatedUserData(props.fullList, props.alreadyPicked);
      clearAddErrors();
    } else {
      setIsLoading(true);
    }
  }, [props.fullList, props.alreadyPicked])

  const extractRelatedUserData = (relatedUsers: RelatedUserData[], alreadyPicked: RelatedUserData[] | undefined) => {
    // log.info(`extract picker data`);
    const extractedData: Dictionary<RelatedUserData> = {};
    if (alreadyPicked && alreadyPicked.length > 0) {
      alreadyPicked.forEach(p => {
        if (!extractedData[p.user.id]) {
          extractedData[p.user.id] = p;
        }
      });
      selected.clear();
      alreadyPicked.forEach(i => selected.add(i));
    } else {
      selected.clear();
    }

    // at this point, "selected" should have only and exactly the already-selected users

    relatedUsers.forEach(u => {
      if (!extractedData[u.user.id]) {
        extractedData[u.user.id] = u;
      }
    });

    const canonicalList = Object.values(extractedData);
    setFullList(canonicalList);

    const chars: string[] = extractUniqueCharacterSet(canonicalList);

    //log.info(`UNIQUE CHARS:`);
    //log.info(chars);

    const catalog: Dictionary<Set<RelatedUserData>> = {};

    // intialize bible entries
    chars.forEach(c => {
      if (!catalog[c]) {
        catalog[c] = new Set<RelatedUserData>();
      }
    })

    canonicalList.forEach(item => {
      const fn = item.user.firstName.toLowerCase();
      const ln = item.user.lastName.toLowerCase();
      const e = item.user.email ? item.user.email.toLowerCase() : '';
      chars.forEach(c => {
        if (fn.includes(c) || ln.includes(c) || e.includes(c)) {
          catalog[c].add(item);
        }
      });
    });


    // at this point, extractedData should be the union of "selected" and "all related users"
    // this is the full list of users that can be searched, and appear in the picker
    setPickerData(canonicalList);
    setTop(catalog);
  }

  const extractUniqueCharacterSet = (data: RelatedUserData[]): string[] => {
    let characters: string[] = [];
    data.forEach(item => {
      characters = [
        ...characters,
        ...Array.from(item.user.firstName.toLowerCase()),
        ...Array.from(item.user.lastName.toLowerCase()),
        ...Array.from(item.user.email ? item.user.email.toLowerCase() : '')
      ];
    })
    return [...new Set(characters)];
  }

  const showAll = () => {
    log.info(`SHOW ALL: ${fullList.length}`);
    setFilteredData(fullList);
  }

  const searchInputChanged = (text: string) => {
    if (!text || text.length === 0) {
      closePicker();
      return;
    }
    doSearch(text);
    setSearchText(text);
  };

  const doSearch = (text: string) => {
    //console.time('doSearch1');
    const formattedQuery = text.toLowerCase();

    if (formattedQuery.length === 0) {
      setFilteredData([]);
      return;
    }

    // narrow down the candidates
    let candidates;
    const formattedSearchText = searchText.toLowerCase();
    // if the query is just the previous search + 1 new character, then the candidates are simply the results of the previous search
    if (formattedSearchText.length > 0 &&
      formattedSearchText.length == formattedQuery.length - 1 &&
      formattedQuery.startsWith(formattedSearchText)) {
      candidates = new Set(filteredData ?? []);
    } else {
      candidates = top[formattedQuery[0]] ?? [];
    }

    let t: RelatedUserData[] = [...selected];
    let b: RelatedUserData[] = [];

    let count = 0;
    candidates.forEach(d => {
      count = count + 1;
      if (!selected.has(d)) {
        if (d.user.firstName.toLowerCase().startsWith(formattedQuery) ||
          d.user.lastName.toLowerCase().startsWith(formattedQuery) ||
          d.user.fullName.toLowerCase().startsWith(formattedQuery) ||
          (d.user.email && d.user.email.toLowerCase().startsWith(formattedQuery))) {
          t = [...t, d];
        } else if (d.user.fullName.toLowerCase().includes(formattedQuery) ||
          (d.user.email && d.user.email.toLowerCase().includes(formattedQuery))) {
          b = [...b, d];
        }
      }
    });
    //log.info(`SEARCHED: ${count}`);

    const filtered = [...t, ...b];

    setFilteredData(filtered);
    //console.timeEnd('doSearch1');
  }

  const toggleSelection = (item: RelatedUserData) => {
    if (!selected.has(item)) {
      selected.add(item);
    } else {
      selected.delete(item);
    }
    props.onSelectionChanged(Array.from(selected));
    setRefreshList(!refreshList);
  }

  const closePicker = () => {
    setFilteredData([]);
    setShowAddEmail(false);
    setSearchText('');
  }

  const clearAddErrors = () => {
    setAddEmailError('');
    setAddFirstNameError('');
    setAddLastNameError('');
  }

  const clearAddFields = () => {
    setEmailToAdd('');
    setFirstNameToAdd('');
    setLastNameToAdd('');
  }

  const scrollToIndex = (index: number) => {
    flatListRef.current?.scrollToIndex({ animated: true, index: index, viewPosition: 0.5 });
  }

  const scrollToTop = () => {
    flatListRef.current?.scrollToOffset({ animated: true, offset: 0 });
  }

  const keyPress = async (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.nativeEvent.key == 'ArrowDown') {
      e.stopPropagation();
      e.preventDefault();
      if (filteredData && activeItemIndex < filteredData.length - 1) {
        const newIndex = activeItemIndex + 1;
        setActiveItemIndex(newIndex);
        scrollToIndex(newIndex);
      }
    } else if (e.nativeEvent.key == 'ArrowUp') {
      e.stopPropagation();
      e.preventDefault();
      if (filteredData && activeItemIndex >= 0) {
        const newIndex = activeItemIndex - 1
        setActiveItemIndex(newIndex);
        if (newIndex >= 0) {
          scrollToIndex(newIndex);
        }
      }
    } else if (e.nativeEvent.key == 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      if (filteredData && filteredData.length > 0 && filteredData[activeItemIndex]) {
        const selectedItem = filteredData[activeItemIndex];
        toggleSelection(selectedItem);
      }
    } else {
      setActiveItemIndex(-1);
    }
  }

  const addEmail = () => {
    let emailError = '';
    let fnError = '';
    let lnError = '';
    let error = false;
    const e = emailToAdd.trim().toLowerCase();
    if (e.length > 0 && !isValidEmail(e)) {
      emailError = `Please enter a valid email`;
      error = true;
    }
    if (firstNameToAdd.length === 0) {
      fnError = `Required`;
      error = true;
    }
    if (lastNameToAdd.length === 0) {
      lnError = `Required`;
      error = true;
    }

    if (!error) {
      let item: RelatedUserData = {
        user: {
          fullName: `${firstNameToAdd} ${lastNameToAdd}`,
          firstName: firstNameToAdd,
          lastName: lastNameToAdd,
          email: e,
          id:`${e}-${uuid()}`
        },
        commonUsers: []
      };
      const matches = pickerData.filter(i => i.user.email?.toLowerCase() == e);
      const exists = matches.length > 0;
      if (matches.length > 0 && e.length > 0) {
        // emailError = `A user with this email already exists`;
        item = matches[0];
      }
      toggleSelection(item);
      setFilteredData(filteredData.length > 0 && !exists? [item].concat(filteredData) : []);
      clearAddFields();
      scrollToTop();
    }

    setAddEmailError(emailError);
    setAddFirstNameError(fnError)
    setAddLastNameError(lnError);
  }

  const emailKeyPress = async (e: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
    if (e.nativeEvent.key == 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      addEmail();
      setTimeout(() => { // this is a bizarre hack I found here:  https://stackoverflow.com/questions/35522220/react-ref-with-focus-doesnt-work-without-settimeout-my-example
        firstNameTextRef.current?.focus();
      }, 1);
    }
  }

  // const getCommonUserText = (item: RelatedUserData) : string => {
  //   const count = item.commonUsers.length;
  //   if (count === 0) {
  //     return '';
  //   } else {
  //     const showOthers = count > 1;
  //     const otherCount = count - 1;
  //     const others = otherCount > 1 ? 'others' : 'other';
  //     const text = `via ${item.commonUsers[0]}${showOthers ? `, and ${otherCount} ${others}` : ``}`;
  //     return text;
  //   }
  // }

  const toggleAddEmail = () => {
    clearAddFields();
    clearAddErrors();
    setShowAddEmail(!showAddEmail);
  }

  const renderItem = ({ item, index, separators }: { item: RelatedUserData, index: number, separators: any }) => (
    <ListItem
      style={{
        backgroundColor: activeItemIndex != index ? themeFromContext.colors.background : themeFromContext.colors.primary
      }}
      containerStyle={{
        paddingVertical: 10,
        paddingLeft: 10,
        paddingRight: 0,
        backgroundColor: activeItemIndex != index ? themeFromContext.colors.background : themeFromContext.colors.primary,
      }}
      onPress={() => { toggleSelection(item); }}
      testID={`user-picker-item-${(item.user.email && item.user.email.length > 0) ? item.user.email : item.user.firstName + '-' + item.user.lastName}`}
    >
      <MemberAvatar
        ignoreUpdate={true}
        size={48}
        userData={item.user}
      // containerStyle={{
      //   backgroundColor: themeFromContext.colors.overlay,
      // }}
      />
      <ListItem.Content>
        <ListItem.Title
          selectable={false}
        >
          <Text
            style={{
              ...themeFromContext.textVariants.userPickerTop,
              overflow: 'hidden'
            }}
          >
            {commonContext.services.userService.getFullName(item.user)}</Text>
        </ListItem.Title>
        <ListItem.Subtitle
          selectable={false}
          style={{ ...themeFromContext.textVariants.userPickerBottom }}
          numberOfLines={1}
        >
          {commonContext.services.userService.getFullName(item.user) != item.user.email ? item.user.email : ''}
          {/* {getCommonUserText(item)} */}
        </ListItem.Subtitle>
      </ListItem.Content>
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignSelf: 'center',
          height: '100%',
          alignItems: 'center',
          //alignContent: 'center',
          paddingHorizontal: 10,
          backgroundColor: activeItemIndex != index ? themeFromContext.colors.background : themeFromContext.colors.primary
        }}
      >
        <ListItem.CheckBox
          size={28}
          uncheckedColor={'#DDEAEB'}
          checkedColor={activeItemIndex === index ? themeFromContext.colors.background : themeFromContext.colors.primary}
          onPress={() => { toggleSelection(item); }}
          checked={selected.has(item)}
          checkedIcon="check-circle"
          uncheckedIcon="circle-o"
        />
      </View>
    </ListItem>
  );

  return (
    <View
      style={{
        width: '100%',
        display: 'flex',
        marginBottom: 10,
        //flex: 1,
      }}
    >
      {
        !isLoading &&
        <View style={{
          width: '100%',
          alignItems: 'center',
          display: 'flex',
          //flex: 1,
          //maxHeight: '100%', this was causing a lot of layout issues!
        }}>
          <ScrollView
            style={{
              width: '100%',
              //maxHeight: '40%',
              paddingVertical: selected.size > 0 ? 5 : 0,
              //flex: 0,
              flexGrow: 0,
            }}
            contentContainerStyle={{
              justifyContent: 'flex-start',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              width: '100%',
              //maxHeight: '40%',
            }}
          >
            {
              Array.from(selected, (item, num) => {
                return (
                  <Chip
                    testID={`user-picker-chip-${(item.user.email && item.user.email.length > 0) ? item.user.email : item.user.firstName + '-' + item.user.lastName}`}
                    key={`${item.user.id}-${num}`}
                    title={item.user.fullName.length > 0 ? item.user.fullName : item.user.email}
                    titleStyle={{
                      ...themeFromContext.textVariants.userPickerChip,
                      color: themeFromContext.colors.primary,
                      marginHorizontal: 4
                    }}
                    buttonStyle={{
                      backgroundColor: themeFromContext.colors.background,
                      borderColor: themeFromContext.colors.primary,
                      borderWidth: 1,
                      marginHorizontal: 4,
                      marginVertical: 2,
                      padding: 8,
                    }}
                    iconRight
                    icon={
                      <Icon
                        name="close"
                        type="material-community"
                        color={themeFromContext.colors.primary}
                        size={themeFromContext.textVariants.body.fontSize}
                        style={{
                          marginHorizontal: 4
                        }}
                        onPress={() => toggleSelection(item)}
                      />
                    }
                  />
                );
              })
            }
          </ScrollView>
          {
            //showAddEmail &&
            <View
              style={{
                display: 'flex',
                //flex: 1,
                borderColor: '#DDEAEB',
                borderWidth: 1,
                borderRadius: 10,
                width: '100%',
                justifyContent: 'center',
                padding: 8,
              }}
            >
              <View
                style={{
                  //flex: 1,
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  paddingHorizontal: 0,
                }}
              >
                <NTextInput
                  ref={firstNameTextRef}
                  onChangeText={setFirstNameToAdd}
                  value={firstNameToAdd}
                  placeholder={`First name`}
                  keyboardType="default"
                  autoFocus={false}
                  inputContainerStyle={{
                    marginRight: 4,
                    //borderBottomWidth: 0,
                  }}
                  style={{
                    //flex: 1,
                    borderRadius: 10,
                    borderColor: '#DDEAEB',
                    borderWidth: 1,
                    //width: '100%',
                    backgroundColor: themeFromContext.colors.background,
                    ...themeFromContext.textVariants.userPickerAddUserFieldValue,
                  }}
                  containerStyle={{
                    flex: 1,
                    padding: 4,
                    //marginHorizontal: 5,
                  }}
                  placeholderTextColor={themeFromContext.textVariants.userPickerAddUserFieldPlaceholder.color}
                  inputStyle={{
                    paddingHorizontal: 10,
                    //flex: 1,
                  }}
                  errorMessage={addFirstNameError}
                  hideMaxLength={true}
                  testID='user-picker-add-user-firstname-input'
                />
                <NTextInput
                  onChangeText={setLastNameToAdd}
                  value={lastNameToAdd}
                  placeholder={`Last name`}
                  keyboardType="default"
                  autoFocus={false}
                  inputContainerStyle={{
                    marginLeft: 4,
                    //borderBottomWidth: 0,
                  }}
                  style={{
                    borderRadius: 10,
                    borderColor: '#DDEAEB',
                    borderWidth: 1,
                    //width: '100%',
                    //flex: 1,
                    backgroundColor: themeFromContext.colors.background,
                    ...themeFromContext.textVariants.userPickerAddUserFieldValue,
                  }}
                  containerStyle={{
                    flex: 1,
                    padding: 4,
                    //marginHorizontal: 5,
                  }}
                  placeholderTextColor={themeFromContext.textVariants.userPickerAddUserFieldPlaceholder.color}
                  inputStyle={{
                    paddingHorizontal: 10,
                    //flex: 1,
                  }}
                  errorMessage={addLastNameError}
                  hideMaxLength={true}
                  testID='user-picker-add-user-lastname-input'
                />
              </View>
              {/* <View
                style={{
                  display: 'flex',
                  flex: 1,
                  paddingHorizontal: 10,
                }}
              > */}
                <NTextInput
                  onChangeText={setEmailToAdd}
                  value={emailToAdd}
                  placeholder={`Email (optional)`}
                  keyboardType="email-address"
                  onKeyPress={emailKeyPress}
                  autoFocus={false}
                  inputContainerStyle={{
                    //borderBottomWidth: 0,
                  }}
                  style={{
                    borderRadius: 10,
                    borderColor: '#DDEAEB',
                    borderWidth: 1,
                    width: '100%',
                    backgroundColor: themeFromContext.colors.background,
                    ...themeFromContext.textVariants.userPickerAddUserFieldValue,
                  }}
                  containerStyle={{
                    padding: 4,
                    //flex: 1,
                  }}
                  placeholderTextColor={themeFromContext.textVariants.userPickerAddUserFieldPlaceholder.color}
                  inputStyle={{
                    paddingHorizontal: 10,
                  }}
                  errorMessage={addEmailError}
                  hideMaxLength={true}
                  testID='user-picker-add-user-email-input'
                />
              {/* </View> */}
              {/* <View
                style={{
                  display: 'flex',
                  flex: 1,
                  paddingHorizontal: 10, // to match the input padding which I can't figure out how to remove
                }}
              > */}
                <NButton
                  buttonStyle={{
                    marginVertical: 8,
                    alignSelf: 'center',
                    //display: 'flex',
                    //flex: 1,
                  }}
                  title={`Add`}
                  titleStyle={{
                    ...themeFromContext.textVariants.userPickerAddUserButton,
                  }}
                  disabled={addButtonDisabled}
                  t="primary"
                  onPress={addEmail}
                  testID='user-picker-add-button'
                  icon={
                    <Icon
                      name="plus"
                      type="material-community"
                      color={addButtonDisabled ? Palette.grey : Palette.plum}
                      size={24}
                      style={{
                        marginRight: 4
                      }}
                    />
                  }
                />
              </View>
            // </View>
          }
          {
            showSearchBar &&
            <View
              style={{
                backgroundColor: themeFromContext.colors.background,
                paddingTop: 10,
                borderRadius: 20,
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <View
                style={{
                  flex: 1,
                  borderColor: `#DDEAEB`,
                  borderWidth: 1,
                  borderRadius: 8,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Input
                  style={{
                    backgroundColor: themeFromContext.colors.background,
                    margin: 0,
                    height: 58,
                  }}
                  inputContainerStyle={{
                    borderBottomWidth: 0,
                  }}
                  inputStyle={{
                    marginHorizontal: 8,
                    paddingHorizontal: 8,
                    color: themeFromContext.colors.foreground,
                    ...themeFromContext.textVariants.body,
                  }}
                  leftIcon={
                    <Icon
                      name="magnify"
                      type="material-community"
                      color={Palette.grey}
                    />
                  }
                  autoCapitalize="none"
                  autoCorrect={false}
                  rightIcon={
                    (filteredData.length == 0 && searchText.length == 0) ?
                      (<Icon
                        name="chevron-down"
                        type="material-community"
                        color={Palette.grey}
                        onPress={showAll}
                      />)
                      :
                      (<Icon
                        name="close"
                        type="material-community"
                        color={Palette.grey}
                        onPress={closePicker}
                      />)
                  }
                  value={searchText}
                  onChangeText={searchInputChanged}
                  placeholder={'Search Contacts'}
                  placeholderTextColor={themeFromContext.colors.secondary}
                  onKeyPress={keyPress}
                  errorStyle={{
                    height: 0,
                    padding: 0,
                    margin: 0,
                  }}
                  testID='user-picker-searchbar-input'
                />
              </View>
            </View>
          }
          {
            (searchText.length > 0 || filteredData.length > 0) &&
            <View
              style={{
                display: 'flex',
                //flexDirection: 'column',
                //flex: 1,
                //flexShrink: 1,
                alignItems: 'center',
                width: '100%',
              }}
            >
              <View // dropdown list
                style={{
                  flex: 1,
                  width: '100%',
                  //maxHeight: normalizeHeight(150, height),
                  //overflow: 'hidden',
                  //minHeight: normalizeHeight(100, height),
                }}
              >
                <FlatList
                  // style={{
                  //   borderColor: themeFromContext.colors.overlay,
                  //   borderLeftWidth: filteredData.length > 0 ? 1 : 0,
                  //   borderRightWidth: filteredData.length > 0 ? 1 : 0,
                  //   borderBottomWidth: filteredData.length > 0 ? 1 : 0,
                  // }}
                  onScrollToIndexFailed={info => {
                    log.warn(`scrollToIndex failed:`);
                    log.warn(info);
                    const wait = new Promise(resolve => setTimeout(resolve, 500));
                    wait.then(() => {
                      scrollToIndex(activeItemIndex);
                    });
                  }}
                  contentContainerStyle={{
                    borderColor: '#DDEAEB',
                    borderLeftWidth: filteredData.length > 0 ? 1 : 0,
                    borderRightWidth: filteredData.length > 0 ? 1 : 0,
                    borderBottomWidth: filteredData.length > 0 ? 1 : 0,
                    borderRadius: 10,
                    overflow: 'hidden',
                  }}
                  showsVerticalScrollIndicator={false}
                  data={filteredData}
                  extraData={refreshList}
                  keyExtractor={(item, index) => `${item.user.id}-${index}`}
                  ref={flatListRef}
                  renderItem={renderItem}
                />
              </View>
            </View>
          }
        </View>
      }
      {
        isLoading &&
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>
          <NActivityIndicator color={Palette.teal} />
        </View>
      }
    </View>
  );
}
export default RelatedUserPicker;