import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { Animated, ScrollViewProps, View } from 'react-native';
import NActivityIndicator from '../ActivityIndicators/NActivityIndicator';
import { MAX_WIDTH } from '../../../business/layout/layout';
import { useSafeAreaInsets } from '../../../business/layout/layout';
import { Palette } from '../../../Theme';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import Header from '../Header/Header';
import { getSecondaryHeight2, TOP_HEADER_BASE_HEIGHT } from '../../../constants/header';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'

function ScrollableViewScreen(props: {
  navigation: any,
  refreshing: boolean,
  topTitle?: string | ReactElement,
  isFocused: boolean,
  bottomTitle?: string,
  pageTitle?: string,
  containsScrollView?: boolean,
  goBack?: () => void,
  siblings?: ReactElement | boolean | (ReactElement | boolean | undefined)[]
  allowTopLevelAutoScroll?: boolean,
} & ScrollViewProps): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { top, bottom } = useSafeAreaInsets();
  const { height, width } = commonContext.dimensions;
  const [secondaryHeight, setSecondaryHeight] = useState(getSecondaryHeight2(props.topTitle, props.bottomTitle));
  const [primaryHeight, setPrimaryHeight] = useState(TOP_HEADER_BASE_HEIGHT);
  // const [anim] = useState(new Animated.Value(0));
  // const positionY = React.useRef(anim).current;
  
  // const onScroll = Animated.event([{ nativeEvent: { contentOffset: { y: positionY } } }], { useNativeDriver: false });

  useEffect(() => {
    setPrimaryHeight(TOP_HEADER_BASE_HEIGHT);
    setSecondaryHeight(getSecondaryHeight2(props.topTitle, undefined));
  }, [props.topTitle, props.bottomTitle, height]);

  // const translateY = positionY.interpolate({
  //   inputRange: [0, primaryHeight * 2, Number.MAX_VALUE],
  //   // THIS IS KEY FOR THIS VIEW... NO ANIMATION OF HEADER...
  //   outputRange: [0, 0, 0],
  // });

  return (
    <View
      style={{
        //height: '100%', PUTTING HEIGHT AT 100% WAS A MISTAKE BECAUSE IT STREACHES THIS VIEW DOWN BEYOND THE BOTTOM
        flex: 1,
        display: 'flex',
        backgroundColor: Palette.plum,
      }}
      testID={`scrollable-view-screen-${props.topTitle}`}
    >
      <Header
        navigation={props.navigation}
        primaryHeight={primaryHeight}
        secondaryHeight={secondaryHeight}
        // translateY={translateY}
        topTitle={props.topTitle}
        bottomTitle={props.bottomTitle}
        menuAction={props.navigation.openDrawer}
        goBack={props.goBack}
      />
      <KeyboardAwareScrollView
        {...props}
        style={[
          props.style,
          {
            display: (!props.refreshing && props.isFocused) ? 'flex' : 'none',
            flex: 1,
            position: 'absolute',
            //top: Animated.add(Animated.add(Animated.multiply(Animated.subtract(1, progress), topPadding), secondaryHeight), topOffsetCompensation),
            top: top + primaryHeight + secondaryHeight,
            // transform: [{
            //   translateY: translateY
            // }],
            // paddingTop: Animated.subtract(0, translateY),
            //paddingTop: Animated.subtract(0, translateY),
            bottom: 0,
            maxWidth: MAX_WIDTH,
            width: width - 20,
            alignSelf: 'center',
            backgroundColor: themeFromContext.colors.background,
            borderTopLeftRadius: themeFromContext.slideUp.style.borderTopLeftRadius,
            borderTopRightRadius: themeFromContext.slideUp.style.borderTopRightRadius,
            //height: Animated.subtract(height - primaryHeight - secondaryHeight, translateY),
          }
        ]}
        //onScroll={onScroll}
        showsVerticalScrollIndicator={false}
        //scrollIndicatorInsets={{ top: Animated.add(scrollIndicatorInsetTop, secondaryHeight) }}
        contentContainerStyle={[
          {
            display: 'flex',
            //flex: 1, // this made ios scrolling not work
            paddingHorizontal: 10,
            paddingTop: 10,
          },
          props.containsScrollView ? {height: height - (top + primaryHeight + secondaryHeight + bottom)} : {},
        ]}
        bounces={false}
        alwaysBounceVertical={false}
        testID={props.testID ?? 'scrollable-view-screen'}
        extraScrollHeight={100}
        enableAutomaticScroll={props.allowTopLevelAutoScroll ?? false}
      />
      {props.siblings}
      <View style={{
        display: (props.refreshing && props.isFocused) ? 'flex' : 'none',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
        <NActivityIndicator />
      </View>
    </View>
  );
}

export default ScrollableViewScreen;