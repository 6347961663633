import { VideoVisibility, VimeoVideoMetadata } from "../../contracts/ICommunityService";
import log from "../logging/logger";

export const extractVisibility = (content: string): VideoVisibility => {
  try {
    const arr: VimeoVideoMetadata[] = JSON.parse(content);
    const first = arr[0];
    return first.visibility;
  } catch (ex) {
    log.error(ex);

    return VideoVisibility.SELF;
  }
}

export const extractVideoMetadata = (content: string): VimeoVideoMetadata|undefined => {
  try {
    const arr: VimeoVideoMetadata[] = JSON.parse(content);
    const first = arr[0];
    return first;
  } catch (ex) {
    log.error(ex);
  }
}