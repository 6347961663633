// Frameworks
import React, { useContext, useEffect, useState } from 'react';
import { Text, View } from 'react-native';

// Theme
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { CommunityTabParamList } from '../../../types/Navigation';
//import { useIsFocused } from '@react-navigation/core';
import { Community } from '../../../API';
import { Provider } from 'react-native-paper';
import { Palette } from '../../../Theme';
import log from '../../../business/logging/logger';
import { normalizeHeight } from '../../../business/layout/responseSize';
import { useLinkTo, useIsFocused } from '@react-navigation/native';
import { LikeMap } from '../../../services/CommunityService';
import ScrollableFlatListScreen from '../../components/Layouts/ScrollableFlatListScreen';
import { ActivityItemsFromCommunity } from '../../../business/activityHistory/activityHistoryHelper';
import ActivityItemCard from './ActivityItemCard';
import LogoAnimation from '../../components/ActivityIndicators/LogoAnimation';
import LocalForageWrapper from '../../../business/storage/localForageWrapper';
import { Dictionary } from "../../../types/data/Dictionary";
import Filter from '../../components/Filter';
import { ActivityItem, ActivityType } from './types';

export type ActivityProps = BottomTabScreenProps<CommunityTabParamList, 'Activity'>;

const SORT_FILTER_CACHE_KEY = `ahsf`;

function ActivityHistory(props: ActivityProps): React.ReactElement {
  const { route, navigation } = props;
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  //const [refresh, setRefresh] = useState(false);
  const [refreshing, setRefreshing] = useState(true);
  //const [sortAsc, setSortAsc] = useState(false);
  const [questionLikeMap, setQuestionLikeMap] = useState<LikeMap>({});
  const [answerLikeMap, setAnswerLikeMap] = useState<LikeMap>({});
  const isFocused = useIsFocused();
  const communityId = route.params?.communityId;
  const [community, setCommunity] = useState<Community>();
  const { width, height } = commonContext.dimensions;
  const { communityService, categoryService } = commonContext.services;
  const [loading, setLoading] = useState(true);
  const [visibilityMap, setVisibilityMap] = useState<Dictionary<number>>({});
  const linkTo = useLinkTo();

  // activity data
  const [activityHistory, setActivityHistory] = useState<ActivityItem[]>();
  const [unsortedData, setUnsortedData] = useState<ActivityItem[]>();
  const [refilterAndSort, setRefilterAndSort] = useState(false);

  // sorting
  const [sortBy, setSortBy] = useState('Most Recent');

  // filters
  const [filterOptions, setFilterOptions] = useState<string[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<Set<string>>(new Set<string>());
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    setLoading(true);
    setRefreshing(true);
    if (isFocused) {

      const opts = ['Questions', 'Answers', 'Comments', 'Likes', 'Membership', 'My Activity'];
      setFilterOptions(opts);
      setSelectedFilters(new Set(opts));

      // const sfstate = LocalForageWrapper.getItem(SORT_FILTER_CACHE_KEY);
      // if (sfstate) {
      //   if (sfstate.sortBy) {
      //     setSortBy(sfstate.sortBy == 1 ? 'Most Popular' : sfstate.sortBy == 2 ? 'Most Recent' : sfstate.sortBy);
      //   }
      //   if (sfstate.selectedFilters) {
      //     try {
      //       setSelectedFilters(sfstate.selectedFilters);
      //     } catch (ex) {
      //       console.log(`bad activity history filter cache: ${ex}`);
      //     }
      //   }
      // }

      Promise.all([
        communityService.getCommunityForActivityHistory(communityId),
      ]).then((results) => {
        if (isFocused) {
          commonContext.analytics?.viewPage(route.name, route.params);
          const c = results[0];
          setCommunity(c);
          //setRefresh(!refresh);
        }
      }).catch((ex) => {
        log.error(ex);
      });
    }
  }, [isFocused, communityId]);


  async function loadData(community: Community, commonContext: CommonContextType): Promise<void> {
    console.log(`reloading activity history`);
    const data = await ActivityItemsFromCommunity(community, commonContext);
    console.log(`data:`);
    console.log(data);
    setUnsortedData(data);
  }

  useEffect(() => {
    if (community) {
      loadData(community, commonContext);
    }
  }, [community, commonContext.loggedInUser]);

  useEffect(() => {
    const m = getVisibilityMap(unsortedData ?? []);
    setVisibilityMap(m);
  }, [unsortedData]);
  
  useEffect(() => {
    // filter / sort activity data
    const filtered = unsortedData?.filter(item => 
      (
        (item.activityType == ActivityType.Answer && selectedFilters.has('Answers')) ||
        (item.activityType == ActivityType.Question && selectedFilters.has('Questions')) ||
        (item.activityType == ActivityType.Comment && selectedFilters.has('Comments')) ||
        (item.activityType == ActivityType.Like && selectedFilters.has('Likes')) ||
        (item.activityType == ActivityType.CommunityJoin && selectedFilters.has('Membership')) ||
        (item.activityType == ActivityType.CommunityCreation)
      )
      &&
      (selectedFilters.has('My Activity') || item.actor?.id != commonContext.loggedInUser?.id)
    );
    filtered?.sort((a, b) => compareActivityItems(a, b, sortBy, visibilityMap));
    setActivityHistory(filtered);
    setRefreshing(false);
  }, [refilterAndSort, unsortedData, sortBy, visibilityMap]);

  useEffect(() => {
    if (activityHistory) {
      setLoading(false);
    }
  }, [activityHistory]);

  useEffect(() => {
    const questions = community?.communityQuestionsByStatusDate?.items.filter(q => q != null);
    if (questions && questions.length > 0) {
      // handle like maps
      communityService.getLikeMapForQuestions(questions)
        .then((map) => {
          setQuestionLikeMap(map);
        });
      const answers = questions.flatMap(q => q?.communityQuestionAnswersByStatusDate?.items.filter(a => a != undefined && a != null));
      communityService.getLikeMapForAnswers(answers)
        .then((map) => {
          setAnswerLikeMap(map);
        });
    }
  }, [community]);

  const getVisibilityMap = (history: ActivityItem[]) : Dictionary<number> => {
    const answers = history?.filter(x => x.activityType == ActivityType.Answer);
    const questionAnswerCounts: Dictionary<number> = {};
    answers?.forEach(a => {
      if (a.targetItem?.id != undefined) {

        // we don't need to check if the answer can be seen by the target user here, since activityHistoryHelper already limits us to answers we can see

        if (!questionAnswerCounts[a.targetItem?.id]) {
          questionAnswerCounts[a.targetItem?.id] = 0;
        }
        questionAnswerCounts[a.targetItem?.id] += 1;
      }
    });
    log.info(JSON.stringify(questionAnswerCounts, null, 2));
    return questionAnswerCounts;
  }

  function calculatePopularityScore(activityItem: ActivityItem, answerCountMap: Dictionary<number>) {
    const COMMENT_TO_LIKE_RATIO = 1.5;
    const ANSWER_TO_LIKE_RATIO = 3;
    let score = activityItem.likeCount + (activityItem.commentCount * COMMENT_TO_LIKE_RATIO);
    if (answerCountMap && answerCountMap[activityItem.key]) {
      score += answerCountMap[activityItem.key] * ANSWER_TO_LIKE_RATIO;
    }
    log.info(`score: ${score}`);
    return score;
  }
  
  function compareActivityItems(a: ActivityItem, b: ActivityItem, sortBy: string, answerCountMap: Dictionary<number>) : number {
    if (sortBy == 'Most Popular') {
      const ascore = calculatePopularityScore(a, answerCountMap);
      const bscore = calculatePopularityScore(b, answerCountMap);
      //community creation always needs to show up first
      if (a.activityType == ActivityType.CommunityCreation) {
        return 1;
      } else if (b.activityType == ActivityType.CommunityCreation) {
        return -1;
      } else if (ascore < bscore) {
        return 1;
      } else if (ascore > bscore) {
        return -1;
      } else {
        if (a.timestamp < b.timestamp) { // fallback to timestamp
          return 1;
        } else if (a.timestamp > b.timestamp) {
          return -1;
        } else {
          return 0;
        }
      }
    } else {
      // newest first
      //community creation always needs to show up first
      if (a.activityType == ActivityType.CommunityCreation) {
        return 1;
      } else if (b.activityType == ActivityType.CommunityCreation) {
        return -1;
      } else if (a.timestamp < b.timestamp) {
        return 1;
      } else if (a.timestamp > b.timestamp) {
        return -1;
      } else {
        return 0;
      }
    }
  }

  async function sortAndFilterDone() {
    LocalForageWrapper.setItem(SORT_FILTER_CACHE_KEY, {
      selectedFilters: selectedFilters,
      sortBy: sortBy
    });
    setRefilterAndSort(!refilterAndSort);
  }

  const styles = {
    menuSectionHeader: {
      textTransform: 'uppercase',
      fontFamily: 'Arial',
      fontSize: 14,
      fontWeight: '500',
      color: Palette.grey,
      marginLeft: 20,
      marginBottom: 6,
    },
    menuSection: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      borderColor: Palette.grey,
      borderRadius: 12,
      borderWidth: 1,
      marginBottom: 20,
    },
    checkboxTextStyle: {
      fontFamily: 'Arial',
      fontSize: 14,
      fontWeight: '400'
    },
    checkboxContainerStyle: {
      backgroundColor: Palette.transparent,
      borderWidth: 0,
      padding: 5,
    },
    checkboxSize: 24,
    sortHeader: {
      textTransform: 'uppercase',
      fontFamily: 'Arial',
      fontSize: 12,
      fontWeight: '500',
      color: Palette.white,
    },
  }

  // async function onRefresh(): Promise<void> {
  //   setRefreshing(true);
  //   if (community) {
  //     await loadData(community, commonContext);
  //     setRefreshing(false);
  //   }
  // }

  return (
    <Provider>
      <ScrollableFlatListScreen
        navigation={props.navigation}
        goBack={props.navigation.goBack}
        topTitle={`Activity`}
        //backgroundColor={Palette.lightgrey}
        narrow
        isFocused={isFocused}
        refreshing={refreshing}
        // onRefresh={onRefresh}
        bottomTitle={community?.name ?? '...'}
        data={activityHistory}
        extraData={refresh}
        initialNumToRender={20}
        windowSize={51}
        renderItem={({ item, index }) => (
          <ActivityItemCard key={item.key} item={item} communityId={communityId} answerLikeMap={answerLikeMap} questionLikeMap={questionLikeMap} answerVisibilityMap={visibilityMap} />
        )}
        ListFooterComponent={() => (
          <View style={{
            minHeight: activityHistory && activityHistory.length > 0 ? normalizeHeight(260, height) : 0,
            paddingVertical: themeFromContext.spacing.m
          }} />)
        }
        ListHeaderComponent={() => (
          <View
            style={{
              width: '100%',
            }}
          >
            {
              activityHistory != undefined && activityHistory.length > 0 &&
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',
                  alignItems: 'center',
                }}
              >
                <View
                  style={{
                    padding: 10,
                  }}
                >
                  <Text style={styles.sortHeader}>
                    {sortBy}
                  </Text>
                </View>
                <View
                  style={{
                    flex: 1,
                    padding: 10,
                  }}
                >
                  <View
                    style={{
                      width: '100%',
                      alignSelf: 'center',
                      height: 0,
                      borderBottomWidth: 1,
                      borderBottomColor: Palette.white,
                      //marginBottom: 8
                    }}
                  >
                  </View>
                </View>              
              </View>
            }
          </View>
        )}
        ListEmptyComponent={() => (
          <View
            style={{
              alignItems: 'center',
              flex: 1,
              //justifyContent: 'center',
              paddingTop: 60,
              paddingBottom: 130,
            }}
            testID={'activity-history-list-empty-view'}
          >
            <View
              style={{
                display: 'flex',
                height: '100%',
                top: 0,
                width: '100%',
                justifyContent: 'center',
                //position: 'absolute',
              }}
            >
              <LogoAnimation color={Palette.grey} />
            </View>
          </View>
        )}
        filter={
          <Filter
            filterOptions={filterOptions}
            selectedFilters={selectedFilters}
            sortOptions={[ "Most Popular", "Most Recent" ]}
            selectedSort={sortBy}
            onFilterSelectionChanged={(selection: Set<string>) => {
              setSelectedFilters(selection);
              setRefresh(!refresh);
              sortAndFilterDone();
            }}
            onSortSelectionChanged={(selection: string) => {
              setSortBy(selection);
              setRefresh(!refresh);
            }}
            //onClose={sortAndFilterDone}
          />
        }
      />
    </Provider>
  );
}

export default ActivityHistory;
