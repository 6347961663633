import * as FileSystem from 'expo-file-system'
import base64 from 'base64-js'
import { verifyPermissions } from '../assets/assetHelper';

interface IFileReader {
  openFile(input: any, chunkSize: number): Promise<IFileSource>;
}

interface IFileSource {
  size: number;
  slice(start: number, end: number): Promise<SliceResult>;
  close(): void;
}

interface SliceResult {
  // Platform-specific data type which must be usable by the HTTP stack as a body.
  value: any;
  done: boolean;
}

export class TUSFR implements IFileReader {
  size?: number;

  constructor(size?: number) {
    this.size = size;
  }

  async openFile(input: any, chunkSize: number): Promise<IFileSource> {
    let finalSize = this.size;
    try {
      console.log(`TUSFR calling openFile on ${input.uri}`)
      if (!finalSize) {
        //console.log(`TUSFR don't know file size yet, so get from FileSystem.getInfoAsync`);
        const info = await FileSystem.getInfoAsync(input.uri, { size: true });
        //console.log(`TUSFR got info:`);
        //console.log(info);
        finalSize = info.size;
      }
    } catch (ex) {
      console.error(`something crashed in TUSFR!`);
      console.error(ex);
    }
    return new TusFileReader(input, finalSize ?? chunkSize);
  }
}

class TusFileReader implements IFileSource {
  file: any;
  size: number;
  constructor (file: any, size: number) {
    this.file = file
    this.size = size
  }
  close(): void {
    // do nothing
  }

  async slice (start: number, end: number): Promise<SliceResult> {
    const options = {
      encoding: FileSystem.EncodingType.Base64,
      length: Math.min(end, this.size) - start,
      position: start,
    }
    // console.log(`TusFileReader options`);
    // console.log(options);

    // //await verifyPermissions();

    // const info = await FileSystem.getInfoAsync(this.file.uri, { size: true });
    // console.log(`got info when slicing:`);
    // console.log(info);
    try {
      console.log(`slice: ${this.file.uri}\n${JSON.stringify(options, null, 2)}`);
      const data = await FileSystem.readAsStringAsync(this.file.uri, options);
      return {done: false, value: base64.toByteArray(data)};
    } catch (ex) {
      console.error(`something crashed in TusFileReader slice!`);
      console.error(ex);
      throw ex;
    }
  }
}