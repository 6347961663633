// Frameworks
import React, { useContext } from 'react';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { View } from 'react-native';

function NProgressBar(props: { completed: number, total: number }) : React.ReactElement {
  
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;

// height={10} borderColor={commonContext.theme.colors.foreground} borderRadius={50} color={commonContext.theme.colors.primary} unfilledColor={commonContext.theme.colors.background}

const notStarted = props.completed == 0;
const finished = props.completed == props.total;

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        //width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <View
        style={{
          borderColor: themeFromContext.colors.foreground,
          // borderTopLeftRadius: 50,
          // borderBottomLeftRadius: 50,
          // borderTopRightRadius: finished ? 50 : 0,
          // borderBottomRightRadius: finished ? 50 : 0,
          borderRadius: 0,
          backgroundColor: themeFromContext.colors.primary,
          height: 5,
          borderLeftWidth: notStarted ? 0 : 1,
          borderTopWidth: notStarted ? 0 : 1,
          borderBottomWidth: notStarted ? 0 : 1,
          borderRightWidth: finished ? 1 : 0,
          flex: props.completed,
        }}
      />
      <View
        style={{
          borderColor: themeFromContext.colors.foreground,
          // borderTopLeftRadius: notStarted ? 50 : 0,
          // borderBottomLeftRadius: notStarted ? 50 : 0,
          // borderTopRightRadius: finished ? 0 :50,
          // borderBottomRightRadius: finished ? 0 :50,
          borderRadius: 0,
          backgroundColor: themeFromContext.colors.background,
          height: 5,
          borderRightWidth: finished ? 0 : 1,
          borderTopWidth: finished ? 0 : 1,
          borderBottomWidth: finished ? 0 : 1,
          borderLeftWidth: notStarted ? 1 : 0,
          flex: props.total - props.completed,
        }}
      />

    </View>
  );
}
export default NProgressBar;