//import { ICache } from '@aws-amplify/cache/lib-esm/types';
//import { Cache } from "aws-amplify";
import { APIWrapper, IAPIWrapper } from "./business/graphql/APIWrapper";
import { FullListQuerier, IFullListQuerier } from "./business/graphql/FullListQuerier";
import { ICache } from "./business/storage/ICache";
import LocalForageWrapper from './business/storage/localForageWrapper';
import { ICategoryService } from './contracts/ICategoryService';
import { ICommunityService } from './contracts/ICommunityService';
import { IInterviewRequestService } from './contracts/IInterviewRequestService';
import { IQuestionRecommendationService } from './contracts/IQuestionRecommendationService';
import { IRelatedUserService } from './contracts/IRelatedUserService';
import { IUserService } from './contracts/IUserService';
import { ServicesContext } from "./types/ServicesContext";
import { CategoryService } from "./services/CategoryService";
import { UserService } from "./services/UserService";
import { QuestionRecommendationService } from "./services/QuestionRecommendationService";
import { CommunityService } from "./services/CommunityService";
import { InterviewRequestService } from "./services/InterviewRequestService";
import { RelatedUserService } from "./services/RelatedUserService";

export class ServicesContextFactory {
  flq: IFullListQuerier;
  cache: ICache;
  api: IAPIWrapper;
  categoryService: ICategoryService;
  communityService: ICommunityService;
  interviewRequestService: IInterviewRequestService;
  questionRecommendationService: IQuestionRecommendationService;
  userService: IUserService;
  relatedUserService: IRelatedUserService;
  constructor(env: string) {
    this.api = new APIWrapper();
    this.cache = LocalForageWrapper;
    this.flq = new FullListQuerier(this.api);
    this.categoryService = new CategoryService(this.flq, this.cache);
    this.userService = new UserService(this.api, this.cache, this.flq);
    this.questionRecommendationService = new QuestionRecommendationService(env, this.api, this.categoryService, this.userService);
    this.communityService = new CommunityService(this.api, this.cache, this.flq, this.categoryService, this.questionRecommendationService, this.userService);
    this.interviewRequestService = new InterviewRequestService(this.flq, this.api, this.userService);
    this.relatedUserService = new RelatedUserService(this.userService, this.communityService);
  }
  buildServicesContext() : ServicesContext {
    return {
      api: this.api,
      cache: this.cache,
      categoryService: this.categoryService,
      communityService: this.communityService,
      flq: this.flq,
      interviewRequestService: this.interviewRequestService,
      questionRecommendationService: this.questionRecommendationService,
      userService: this.userService,
      relatedUserService: this.relatedUserService,
    }
  }
}