// Frameworks
import React, { useContext, useState } from 'react';
import { View } from 'react-native';

// Theme
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { Icon, Text } from 'react-native-elements';
import { StepProps } from '../../../types/Steps';
import NButton from '../../components/NButton';
import { SubjectList } from '../../../business/user/subjectList';
import NTextInput from '../../components/NTextInput';
import { Palette } from '../../../Theme';
import EmailPreviewModal2 from '../../components/EmailPreviewModal2';

type NewCommunityInviteEveryoneProps = StepProps<string> & {
  subjectList: SubjectList | undefined,
  max: number
};

const PREVIEW = require(`../../../../assets/member-email-sample.jpg`);

function NewCommunityInviteEveryone(props: NewCommunityInviteEveryoneProps): React.ReactElement {
  const myTurn = props.step === props.currentStep;
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  //const [customNoteCount, setCustomNoteCount] = useState('');
  const [showEmailPreview, setShowEmailPreview] = useState(false);
  //const [loggedInUser, setLoggedInUser] = useState<User>();

  // useEffect(() => {
  //   // let mounted = true;
  //   setCustomNoteCount(`(${props.inputPayload?.length} / ${props.max} chars)`);
  //   // getLoggedInUser()
  //   //   .then((u) => {
  //   //     if (mounted) {
  //   //       setLoggedInUser(u);
  //   //     }
  //   //   });
  //   // return () => {
  //   //   mounted = false;
  //   // }
  // }, [props.inputPayload])

  const trySubmit = () => {
    // do validation
    props.onFinish();
  }

  return (
    <>
      {
        myTurn &&
        <>
          <View
            // style={{
            //   height: '100%',
            // }}
          >
            <View>
              <Text
                style={{
                  paddingBottom: 10,
                  textAlign: 'center',
                  ...themeFromContext.textVariants.wizardBody
                }}
              >
                {`Click below, and we'll send an email with detailed instructions to everyone you've invited so far. What will we send them? Click `}
                <Text
                  style={{
                    paddingBottom: 10,
                    textAlign: 'center',
                    ...themeFromContext.textVariants.wizardBody,
                    color: themeFromContext.colors.primary,
                    fontWeight: '700',
                  }}
                  onPress={() => setShowEmailPreview(true)}
                  testID={`create-community-wrap-up-email-preview-button`}
                >
                  {`here`}
                </Text>
                {` to preview the email.`}
              </Text>
            </View>
            <View
              style={{
                paddingVertical: 20,
              }}
            >
              <Text
                style={{
                  paddingBottom: 10,
                  textAlign: 'center',
                  ...themeFromContext.textVariants.wizardBody
                }}
              >
                {`If you want, you can write a friendly note and we'll include it in the invite.`}
              </Text>
            </View>
            <View
              style={{
                //flex: 1,
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <NTextInput
                multiline={true}
                maxHeight={200}
                maxLength={props.max}
                placeholder='Your note (optional)'
                value={props.inputPayload}
                onChangeText={value => props.onPayloadChange(value)}
                autoCorrect
                inputStyle={{
                  borderWidth: 0,
                  borderRadius: 0,
                  padding: 4,
                  textAlignVertical: 'bottom',
                }}
                inputContainerStyle={{
                  borderBottomColor: '#DDEAEB',
                  borderBottomWidth: 1,
                }}
                leftIconContainerStyle={{
                  justifyContent: 'flex-start',
                }}
                leftIcon={
                  <Icon
                    name='pencil-outline'
                    type='material-community'
                    color={Palette.grey}
                  />
                }
                testID={`create-community-wrap-up-note-input`}
              />
              {/* <Input
                placeholder={`Your note (optional)`}
                multiline={true}
                containerStyle={{
                  flexGrow: 1,
                }}
                inputStyle={{
                  textAlignVertical: 'top',
                  borderWidth: 1,
                  borderColor: themeFromContext.colors.overlay,
                  borderRadius: 25,
                  padding: themeFromContext.spacing.m,
                  width: '100%',
                }}
                value={props.inputPayload}
                onChangeText={value => props.onPayloadChange(value)}
                autoCorrect
                inputContainerStyle={{
                  borderBottomWidth: 0,
                  flexGrow: 1,
                  display: 'flex',
                  flexDirection: 'column',
                }}
                maxLength={props.max}
                errorMessage={customNoteCount}
                errorStyle={{
                  textAlign: 'right',
                  color: themeFromContext.colors.secondary,
                  ...themeFromContext.textVariants.detail
                }}
                testID='create-community-wrap-up-note-input'
              /> */}
            </View>
            <View
              style={{
                paddingVertical: 10,
              }}
            >
              <NButton
                icon={
                  <Icon
                    name='send'
                    type='ionicons'
                    color={Palette.plum}
                    size={20}
                    style={{
                      marginRight: 4,
                    }}
                  />
                }
                titleStyle={{
                  fontSize: 14,
                  fontWeight: '700',
                }}
                buttonStyle={{
                  marginVertical: 10,
                }}
                style={{
                  alignSelf: 'center',
                }}
                title={`Invite now!`}
                //disabled={!props.inputPayload || props.inputPayload.length === 0}
                t="primary"
                onPress={trySubmit}
                testID='create-community-wrap-up-invite-now-button'
              />
            </View>
          </View>
          {
            commonContext.loggedInUser &&
            <EmailPreviewModal2 isVisible={showEmailPreview} onClose={() => setShowEmailPreview(false)} image={PREVIEW} />
            // <EmailPreviewModal
            //   title={`Email Preview`}
            //   isVisible={showEmailPreview}              
            //   close={() => setShowEmailPreview(false)}
            // >
            //   <CommunityInvitationEmailPreview loggedInUser={commonContext.loggedInUser} communityName={props.subjectList ? props.subjectList.text() : ''} />
            // </EmailPreviewModal>
          }
        </>
      }
    </>
  );
}
export default NewCommunityInviteEveryone;