export function isMobileWeb() {
  try {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
      // true for mobile device
      return true;
    } else {
      // false for not mobile device
      return false;
    }
  } catch {
    return false;
  }
}