import { CommunityQuestionAnswer } from "../../API";
import { ICommunityService } from "../../contracts/ICommunityService";
import { Dictionary } from "../../types/data/Dictionary";

export const compareAnswerSections = (
  a: AnswerSection,
  b: AnswerSection,
  asc: boolean): number =>
{
  if (a.title < b.title) {
    return asc ? -1 : 1;
  }
  if (a.title > b.title) {
    return asc ? 1 : -1;
  }
  return 0;
}

export type AnswerSection = {
  title: string
  data: CommunityQuestionAnswer[]
}

export const extractAnswerSections = (communityService: ICommunityService, answers: CommunityQuestionAnswer[], sortParam: string, asc: boolean): AnswerSection[] => {
  const d: Dictionary<AnswerSection> = {};
  answers.forEach(a => {
    const dim =`${a.date}`;
    if (!d[dim]) {
      d[dim] = {
        title: dim,
        data: [],
      };
    }
    d[dim].data.push(a);
  });
  const sectionArray = Object.values(d);
  return sort(communityService, sectionArray, sortParam, asc);
}

const sort = (communityService: ICommunityService, sections: AnswerSection[], by: string, asc: boolean) : AnswerSection[] => {
  sections.forEach(s => {
    s.data.sort((a, b) => {
      return communityService.compareCommunityQuestionAnswers(a, b, by, true);
    });
  })
  return sections.sort((a, b) => {
    return compareAnswerSections(a, b, asc);
  });
}