import React, { useContext, useEffect, useState } from 'react'
import {
  View, Text
} from 'react-native'
import { Auth } from 'aws-amplify'
import { RootStackParamList } from '../../../../types/Navigation';
import { useLinkTo } from '@react-navigation/native';
import AuthPage from '../AuthPage';
import AuthPageInput from '../AuthPageInput';
import { CommonContextType } from '../../../../types/CommonContextType';
import CommonContext from '../../../../CommonContext';
import AuthPageHeader from '../AuthPageHeader';
import AuthPageLink from '../AuthPageLink';
import log from '../../../../business/logging/logger';
import NButton from '../../../components/NButton';
import { DrawerScreenProps } from '@react-navigation/drawer';

export type ForgotPasswordProps = DrawerScreenProps<RootStackParamList, 'Forgot Password'>;

function ForgotPassword(props: ForgotPasswordProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const [stage, setStage] = useState(0);
  const [email, setEmail] = useState('');
  const [required, setRequired] = useState(false);
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [codeError, setCodeError] = useState('');
  const [showHelp, setShowHelp] = useState(false);
  const linkTo = useLinkTo();

  useEffect(() => {
    if (props.route.params?.email) {
      setEmail(props.route.params.email);
    }
    if (props.route.params?.c) {
      setCode(props.route.params.c);
      setStage(1);
    }
    if (props.route.params?.required) {
      setRequired(true);
    }
  }, [props])

  const resetPassword = () => {
    Auth.forgotPassword(email)
      .then(() => {
        setStage(1);
        setShowHelp(true);
      })
      .catch(err => {
        //log.info('error: ', err)

        if (err.name == 'InvalidParameterException') {
          log.info(err);
          setEmailError(`Oops! Did you forget to confirm your account? Check your email for a confirmation code.`);
        } else {
          setEmailError('');
        }

        if (err.name == 'UserNotFoundException') {
          setEmailError('');
        }
      });
  }
  const confirmResetPassword = () => {
    Auth.forgotPasswordSubmit(email, code, password)
      .then(() => {
        linkTo(`/signIn${email ? '?email=' + email : ''}`)
      })
      .catch(err => {
        //log.info('error resetting password:', err)

        if (err.name == 'InvalidPasswordException') {
          setPasswordError(err.message);
        } else {
          setPasswordError('');
        }

        if (err.name == 'CodeMismatchException') {
          setCodeError(err.message);
        } else {
          setCodeError('');
        }
      });
  }
  return (
    <AuthPage>
      <AuthPageHeader text='Reset password' />
      {
        stage === Number(0) && required && !showHelp &&
        <Text
          style={{
            color: themeFromContext.colors.danger,
            ...themeFromContext.textVariants.body
          }}
        >
          {`Sorry for the inconvenience, but we need you to reset your password before signing in again.`}
        </Text>
      }
      <AuthPageInput
        label='Email *'
        value={email}
        onChangeText={(v) => setEmail(v)}
        placeholder='Enter your email'
        autoCompleteType='email'
        keyboardType='email-address'
        textContentType='emailAddress'
        autoCapitalize='none'
        autoCorrect={false}
        errorMessage={emailError}
      />
      {
        stage === Number(0) &&
        // <AuthPagePrimaryButton
        //   onPress={resetPassword}
        //   title='Get code'
        // />
        <NButton
          testID='forgot-password-get-code-button'
          containerStyle={{padding: 10, width: '100%'}}
          t='primary'
          onPress={resetPassword}
          title={`Get code`}
        />
      }
      {stage === Number(1) && (
        <>
          {showHelp  &&
          <Text
            style={{
              color: themeFromContext.colors.danger,
              ...themeFromContext.textVariants.body
            }}
          >
            We sent you an email! Please enter the confirmation code here.
          </Text>
          }
          <AuthPageInput
            label='Confirmation code *'
            value={code}
            onChangeText={(v) => setCode(v)}
            placeholder='Enter confirmation code'
            keyboardType='number-pad'
            autoCapitalize='none'
            autoCorrect={false}
            errorMessage={codeError}
          />
          <AuthPageInput
            label='New password *'
            onChangeText={(v) => setPassword(v)}
            placeholder='Create new password'
            autoCompleteType='password'
            //keyboardType='visible-password'
            textContentType='password'
            autoCapitalize='none'
            autoCorrect={false}
            secureTextEntry
            passwordRules='required: lower; required: digit; minlength: 7;'
            errorMessage={passwordError}
          />
          {/* <AuthPagePrimaryButton
            title='Reset Password'
            onPress={confirmResetPassword}
            disabled={password.length < 7}
          /> */}
          <NButton
            testID='forgot-password-reset-password-button'
            containerStyle={{padding: 10, width: '100%'}}
            t='primary'
            onPress={confirmResetPassword}
            title={`Reset Password`}
            disabled={password.length < 7}
          />
        </>
      )}
      <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
        <AuthPageLink
          onPress={() => linkTo(`/signIn${email ? '?email=' + email : ''}`)}
          title='Sign in'
        />
        <AuthPageLink
          onPress={() => linkTo(`/confirmCode${email ? '?email=' + email : ''}`)}
          title='Confirm code'
        />
      </View>
    </AuthPage>
  )
}

export default ForgotPassword