import React, { useContext, useEffect, useState } from 'react'
import { RootStackParamList } from '../../../../types/Navigation';
import { useIsFocused } from '@react-navigation/native';
import { Text } from 'react-native-elements';
import { CommonContextType } from '../../../../types/CommonContextType';
import CommonContext from '../../../../CommonContext';
import NButton from '../../../components/NButton';
import { Palette } from '../../../../Theme';
import { DrawerScreenProps } from '@react-navigation/drawer';
import GetStartedPage from './GetStartedPage';
import { isValidEmail } from '../../../../business/user/userHelper';
import { requestHelpGettingStarted } from '../../../../business/supportRequests/gettingStartedHelp';
import i18n from 'i18n-js';
import AuthPageInput from '../AuthPageInput';

export type OtherOptionsProps = DrawerScreenProps<RootStackParamList, 'Other Options'>;

function OtherOptions(props: OtherOptionsProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [emailError, setEmailError] = useState('');
  const [message, setMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [messageError, setMessageError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [buttonRefreshing, setButtonRefreshing] = useState(false);
  const { width } = commonContext.dimensions;

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      // analytics
      commonContext.analytics?.viewPage('Other Options', {});
      setSuccessMessage('');
    }
  }, [isFocused]);

  const clearErrors = () => {
    setEmailError('');
    setFirstNameError('');
    setLastNameError('');
    setMessageError('');
  }

  const clearFields = () => {
    setEmail('');
    setFirstName('');
    setLastName('');
    setMessage('');
  }

  const pressSubmit = async () => {
    clearErrors();
    let errored = false;
    if (!isValidEmail(email)) {
      setEmailError(`Please enter a valid email address`);
      errored = true;
    }

    if (firstName.trim().length == 0) {
      setFirstNameError(`Please enter your first name`);
      errored = true;
    }

    if (lastName.trim().length == 0) {
      setLastNameError(`Please enter your last name`);
      errored = true;
    }

    if (message.trim().length == 0) {
      setMessageError(`Please enter a message so we know how to help you`);
      errored = true;
    }

    if (errored) {
      return;
    }

    try {
      setButtonRefreshing(true);
      const response = await sendMessage(firstName, lastName, email, message);
      if (response.status == 200) {
        clearFields();
        setSuccessMessage(i18n.t('Help_SuccessMessage'));
      } else {
        setSuccessMessage(i18n.t('Help_ErrorMessage'));
      }
      setButtonRefreshing(false);
    } catch (err: any) {
      console.warn(err);
      setButtonRefreshing(false);
    }
  }

  async function sendMessage(firstName: string, lastName: string, email: string, msg?: string): Promise<any> {
    return await requestHelpGettingStarted(firstName, lastName, email, undefined, msg);
  }

  return (
    <GetStartedPage navigation={props.navigation}>

        <Text
          style={{
            ...themeFromContext.textVariants.askQuestionHeader,
            color: Palette.white,
            maxWidth: '75%',
            textAlign: 'center',
            marginBottom: 30,
          }}
        >
          {i18n.t('OtherOptions_Title')}
        </Text>

        <Text
          style={{
            color: Palette.white,
            
            marginBottom: 20,
            ...themeFromContext.textVariants.body,
            fontSize: 16,
          }}
        >
          {i18n.t('OtherOptions_Instructions')}
        </Text>

        <AuthPageInput
          testID='other-options-first-name'
          onChangeText={setFirstName}
          value={firstName}
          placeholder={i18n.t('Invited_FirstName_Label')}
          textContentType='name'
          autoCapitalize='words'
          autoCorrect={false}
          errorMessage={firstNameError}
          disabled={buttonRefreshing}
          inputStyle={{
            borderRadius: 10,
            borderColor: Palette.white,
            borderWidth: 2,
            paddingHorizontal: 16,
            paddingVertical: 8,
            ...themeFromContext.textVariants.body,
            color: Palette.white,
            backgroundColor: Palette.white.concat('33'),
          }}
          placeholderTextColor={Palette.white.concat('aa')}
        />

        <AuthPageInput
          testID='other-options-last-name'
          onChangeText={setLastName}
          value={lastName}
          placeholder={i18n.t('Invited_LastName_Label')}
          textContentType='name'
          autoCapitalize='words'
          autoCorrect={false}
          errorMessage={lastNameError}
          disabled={buttonRefreshing}
          inputStyle={{
            borderRadius: 10,
            borderColor: Palette.white,
            borderWidth: 2,
            paddingHorizontal: 16,
            paddingVertical: 8,
            ...themeFromContext.textVariants.body,
            color: Palette.white,
            backgroundColor: Palette.white.concat('33'),
          }}
          placeholderTextColor={Palette.white.concat('aa')}
        />

        <AuthPageInput
          testID='other-options-email'
          placeholder={i18n.t('Invited_Email_Label')}
          value={email}
          onChangeText={setEmail}
          autoCompleteType='email'
          keyboardType='email-address'
          textContentType='emailAddress'
          autoCapitalize='none'
          autoCorrect={false}
          errorMessage={emailError}
          disabled={buttonRefreshing}
          inputStyle={{
            borderRadius: 10,
            borderColor: Palette.white,
            borderWidth: 2,
            paddingHorizontal: 16,
            paddingVertical: 8,
            ...themeFromContext.textVariants.body,
            color: Palette.white,
            backgroundColor: Palette.white.concat('33'),
          }}
          placeholderTextColor={Palette.white.concat('aa')}
        />

        <AuthPageInput
          testID='other-options-message'
          placeholder={i18n.t('OtherOptions_Message_Label')}
          value={message}
          onChangeText={setMessage}
          //autoCompleteType='email'
          // keyboardType='default'
          // textContentType=''
          autoCapitalize='none'
          autoCorrect={false}
          errorMessage={messageError}
          multiline={true}
          numberOfLines={4}
          maxLength={1000}
          disabled={buttonRefreshing}
          inputStyle={{
            borderRadius: 10,
            borderColor: Palette.white,
            borderWidth: 2,
            paddingHorizontal: 16,
            paddingVertical: 8,
            paddingTop: 8,
            ...themeFromContext.textVariants.body,
            color: Palette.white,
            backgroundColor: Palette.white.concat('33'),
          }}
          placeholderTextColor={Palette.white.concat('aa')}
        />
        <NButton
          testID='invited-submit'
          buttonStyle={{ marginBottom: 8 }}
          t='primary2'
          onPress={pressSubmit}
          loading={buttonRefreshing}
          loadingProps={{
            color: Palette.plum,
          }}
          title={i18n.t('Invited_Submit_Button')}
        />

        <Text
          style={{
            ...themeFromContext.textVariants.askQuestionHeader,
            color: Palette.lightyellow,
            maxWidth: '75%',
            textAlign: 'center',
            marginVertical: 30,
          }}
        >
          {successMessage}
        </Text>
    </GetStartedPage>
  )
}

export default OtherOptions;