/* eslint-disable no-case-declarations */
import { CommunityUser } from "../../API";
import { Dictionary } from "../../types/data/Dictionary";

export function membershipReducer(state: Dictionary<CommunityUser[]>, action: { type: "set" | "add" | "update" | "remove", payload: any}): Dictionary<CommunityUser[]> {
  switch (action.type) {
    case "set":
      return action.payload;
    case "add":
      const newState = { ...state };
      if (!newState[action.payload.communityID]) {
        newState[action.payload.communityID] = [];
      }
      if (!newState[action.payload.communityID].some(x => x.id == action.payload.communityUser.id)) {
        newState[action.payload.communityID] = [ ...newState[action.payload.communityID], action.payload.communityUser ];
      }
      return newState;
    case "remove":
      const newState2 = { ...state };
      if (newState2[action.payload.communityID]) {
        newState2[action.payload.communityID] = newState2[action.payload.communityID].filter(x => x.id != action.payload.communityUser.id);
      }
      return newState2;
    case "update":
      const newState3 = { ...state };
      if (newState3[action.payload.communityID]) {
        const idx = newState3[action.payload.communityID].findIndex(x => x.id == action.payload.communityUser.id);
        if (idx >= 0) {
          newState3[action.payload.communityID][idx] = action.payload.communityUser;
        }
      }      
      return newState3;
    default:
      return { ...state };
  }
}