// Frameworks
import React, { useContext, useEffect, useState } from 'react';
import { Avatar, AvatarProps } from 'react-native-elements';

// Theme
import CommonContext from '../../CommonContext';
import { User } from '../../API';
import log from '../../business/logging/logger';
import { useLinkTo } from '@react-navigation/native';
import { getUserFile } from '../../business/storage/userContent';
import { subscribeToUserUpdates } from '../../business/user/userHelper';
import { Palette } from '../../Theme';
import { CommonContextType } from '../../types/CommonContextType';
import { ImageErrorEventData, ImageLoadEventData, NativeSyntheticEvent } from 'react-native';

function MemberAvatar(props:
  { 
    //margin: keyof ISpacing,
    //backgroundColor: string,
    //textColor: string,
    userData?: User,
    ignoreUpdate?: boolean,
    children?: React.ReactNode | React.ReactNode[]
  } & AvatarProps) : React.ReactElement
{
  const { userData } = props;
  const [profileImageUri, setProfileImageUri] = useState('');
  const commonContext = useContext<CommonContextType>(CommonContext);
  const { userService } = commonContext.services;
  const themeFromContext = commonContext.theme;
  const [initials, setInitials] = useState('');
  const [profilePath, setProfilePath] = useState('');
  const linkTo = useLinkTo();

  //log.info('user data:');
  //log.info(userData);

  useEffect(() => {
    let mounted = true;

    // subscribe to User updates
    let subscription: any = null;

    //log.info(`profile image key: ${userData?.profileImageKey}`);
    if (userData) {
      handleUserData(userData, mounted);

      // user entries that are for Google Contacts should NOT try to subscribe to updates...)
      if (!props.ignoreUpdate) {
        subscription = subscribeToUserUpdates(userData.id, (u) => {
          if (mounted) {
            handleUserData(u, mounted);
          }
        });
      }
    }

    return () => {
      subscription?.unsubscribe();
      mounted = false;
      //log.info(`UNsubscribed for userId: ${userData?.id}`);
    }
  }, [userData])

  function handleUserData(u: User, mounted: boolean) {
    setInitials(commonContext.services.userService.getInitials(u));
    setProfilePath(`/profile/${u.id}/`);
    if (u.profileImageKey) {
      getUserFile(userService, u.profileImageKey, u.id)
        .then(url => {
          if (mounted) {
            //log.info(userData.profileImageKey);
            //log.info(url);
            setProfileImageUri(url);
          }
        })
        .catch(ex => {
          log.error(ex);
        });
    }
  }

  const goToProfile = () => {
    linkTo(profilePath);
  }

  const hasProfileImage = profileImageUri != undefined && profileImageUri.length > 0;
  // log.info("show avatar:");
  // log.info(userData);
  return (
    <Avatar
      title={initials}
      {...props}
      rounded
      onPress={goToProfile}
      source={
        hasProfileImage ? { uri: profileImageUri } : undefined
      }
      titleStyle={[
        {
          color: themeFromContext.colors.background,
          fontFamily: themeFromContext.textVariants.body.fontFamily
        },
        props.titleStyle,
      ]}
      containerStyle={[
        {
          backgroundColor: !hasProfileImage ? Palette.lightpurple : Palette.transparent,
          //marginRight: themeFromContext.spacing ? themeFromContext.spacing[margin] : 0
        },
        props.containerStyle,
      ]}
      placeholderStyle={{
        backgroundColor: Palette.transparent,
      }}
      //overlayContainerStyle={props.containerStyle}
      //iconStyle={props.containerStyle}
      // avatarStyle={{
      //   backgroundColor: props.containerStyle.backgroundColor,
      // }}
    />
  );
}
export default MemberAvatar;