/* eslint-disable react/display-name */
import { HomeStackParamList } from '../../../types/Navigation';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import CommunitiesScreen from '../../screens/MyCommunities/CommunitiesScreen';
import CommunityNavigator from '../CommunityNavigator/CommunityNavigator';
import React, { useContext, useEffect, useState } from 'react';
import { RootStackParamList } from '../../../types/Navigation';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { NativeStackScreenProps } from '@react-navigation/native-stack';
import { WELCOME_SEQUENCES } from '../../../../assets/welcome/Screens';
import log from '../../../business/logging/logger';
import { getBypass } from '../../../business/redirect/bypass';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import branch from 'react-native-branch';

//export type CommunityProps = DrawerScreenProps<RootStackParamList, 'Community'>;
export type HomeProps = NativeStackScreenProps<RootStackParamList, 'Home'>;

const Stack = createNativeStackNavigator<HomeStackParamList>();

function HomeStackInner(props: HomeProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const isFocus = useIsFocused();
  const { route } = props;
  const linkTo = useLinkTo();

  useEffect(() => {
    if (isFocus) {
      if (commonContext.loggedInUser) {
        const seen = commonContext.loggedInUser.welcomesSeen ? commonContext.loggedInUser.welcomesSeen.map(s => s.toUpperCase()) : [];
        getBypass()
          .then((b) => {
            const bypassWelcomeScreens = b ?? false;
            log.info(`BYPASS WELCOMES??? ${bypassWelcomeScreens}`);
            if (!bypassWelcomeScreens && WELCOME_SEQUENCES.some(s => !seen.includes(s.name.toUpperCase()))) {
              props.navigation.navigate('Welcome', { requested: false });
            }

            console.log(`route name is ${route.name}`);
            console.log(route.params);
            if (commonContext.loggedInUser?.communityUsersByStatusDate?.items.length == 1) {
              console.log(`only one community, so redirecting to community screen...`);
              //setInitialRouteIsCommunity(true);
              //setInitialCommunityParam(commonContext.loggedInUser?.communityUsersByStatusDate?.items[0].communityID);

              // if (route.params?.screen == 'Community') {
              //   cid = route.params?.params?.communityId;
              // }
              if (route.params?.params?.params?.path && route.params?.params?.params?.path.length > 0) {
                linkTo(route.params?.params?.params?.path);
              } else {
                const cid = commonContext.loggedInUser?.communityUsersByStatusDate?.items[0].communityID;
                linkTo(`/community/${cid}/activity/${cid}`);
              }
              
            }

          });
      } else {
        console.log(`no logged in user in home stack, so redirecting to landing page...`);
        props.navigation.navigate('Quick Start');
      }
    }
  }, [commonContext.loggedInUser, isFocus]);

  // this is the "email verification link" case
  // the only way to detect it, that I know of, is to see that we're being directed to the root of the app with a username qs param
  useEffect(() => {
    if (route.name == 'Home' && route.params != undefined && route.params.username != undefined) {
      props.navigation.navigate('Sign In');
    }
  }, [route.params]);

  const styles = {
    headerStyle: {
      backgroundColor: themeFromContext.colors?.background,
    },
    headerTitleStyle: {
      color: themeFromContext.colors?.foreground,
      fontFamily: themeFromContext.textVariants.header.fontFamily,
      //fontSize: themeFromContext.textVariants.header.fontSize
    },
    headerTintColor: themeFromContext.colors.foreground,
  };
  return (
    <>
    {/* <Text style={{fontSize: 50}}>{`HOWDY PARDNER`}</Text> */}
      {
        commonContext.loggedInUser &&
        <Stack.Navigator
          screenOptions={() => ({
            ...styles,
            gestureEnabled: false,
            headerShown: false,
            //header: (props) => ( <Header {...props} /> ),
            sceneContainerStyle: {
              // maxWidth: MAX_WIDTH,
              // alignSelf: 'center',
              // width: '100%',
              //paddingHorizontal: 500,
            }
          })}
        >
          <Stack.Screen name='My Communities'
            component={CommunitiesScreen}
            initialParams={{ userId: commonContext.loggedInUser?.id }}
          />
          <Stack.Screen name='Community' component={CommunityNavigator}/>
        </Stack.Navigator>
      }
    </>
  );
}

export default HomeStackInner;