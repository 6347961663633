// Frameworks
import React, { ReactNode, useContext, useEffect, useRef, useState } from 'react';

// Theme
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { Button, ButtonProps, Icon } from 'react-native-elements';
import { Palette } from '../../Theme';
import { ActivityIndicator, Animated, Easing, GestureResponderEvent, PanResponder, PanResponderGestureState, PanResponderInstance, Platform, Pressable, StyleSheet, Text, View } from 'react-native';
import { MAX_WIDTH, useSafeAreaInsets } from '../../business/layout/layout';
import { normalizeHeight } from '../../business/layout/responseSize';
import { LinearGradient } from 'expo-linear-gradient';

function NFAB2(props: { isVisible: boolean, right?: ReactNode, left?: ReactNode, position?: "right"|"left", colors?: string[], hasBottomBar?: boolean, working?: boolean, onPress: () => void }): React.ReactElement {
  const { isVisible, right, left, hasBottomBar, onPress, colors, position, working } = props;
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const [bottomMargin, setBottomMargin] = useState(0);
  const { height, width } = commonContext.dimensions;
  const { bottom } = useSafeAreaInsets();
  const gap = 10;
  const [anim] = useState(new Animated.Value(0));
  const [shouldShow, setShouldShow] = useState(false);
  const [edgePadding, setEdgePadding] = useState(Math.max(width - MAX_WIDTH, 0) / 2);

  useEffect(() => {
    setEdgePadding(Math.max(width - MAX_WIDTH, 0) / 2);
  }, [width]);

  useEffect(() => {
    const bottomBarAdjustment = hasBottomBar ? Math.max(normalizeHeight(50, height), 50) : Math.max(normalizeHeight(10, height), 10);
    setBottomMargin(bottomBarAdjustment + (bottom / 2) + gap);
  }, [hasBottomBar, height, bottom]);

  useEffect(() => {
    if (isVisible) {
      // open it
      //log.info(`OPEN MODAL START: ${name}`);
      //setShouldShow(true);
      //setOpening(true);

      Animated.timing(
        anim,
        {
          toValue: 0,
          duration: 400,
          useNativeDriver: true,
          easing: Easing.ease,
        }
      ).start(() => {
        //setFullyOpen(true);
        //setOpening(false);
        //log.info(`OPEN MODAL END: ${name}`);
      });

    } else {
      // close it
      //log.info(`CLOSE MODAL START: ${name}`);
      //setFullyOpen(false);
      Animated.timing(
        anim,
        {
          toValue: 100,
          duration: 400,
          useNativeDriver: true,
          easing: Easing.ease,
        }
      ).start(() => {
        //if (!opening) {
          //setShouldShow(false);
          //log.info(`CLOSE MODAL END: ${name}`);
        //}
      });

    }

    return () => { return; }
  }, [isVisible]);

  return (
    <Animated.View
      style={[
        {
          display: 'flex',
          position: 'absolute',
          bottom: bottomMargin,
          zIndex: 30,
          filter: 'drop-shadow(1px 4px 8px rgba(50, 50, 0, 0.2))',
          //right: 20,
          shadowColor: Platform.OS!=='web'?'#171717': '',
          shadowOpacity:Platform.OS!=='web'? 0.2:0,
          shadowRadius: Platform.OS!=='web'?5:0,
          shadowOffset:  Platform.OS!=='web'?{width: 0, height: 4}:{},
          //transform: [{translateY: Animated.add(translateY, translateY2)}],
          transform: [
            // {translateY: translateY},
            { translateY: anim },
          ],
          
          // transform: [
          //   { translateY: props.translateY ?? 0 },
          // ],
        },
        position == 'left' ? { left: 20 + edgePadding } : { right: 20 + edgePadding }
        //styles.button
      ]}
    >
      <Pressable
        onPress={onPress}
        style={({ pressed }) => [
          {
            opacity: pressed ? 0.5 : 1,
          },
        ]}
      >
        <LinearGradient
          style={[
            {
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              //borderWidth: 1,
              borderColor: Palette.plum,
            },
            styles.button,
          ]}
          colors={colors ?? [ Palette.lightteal, Palette.teal]}
          start={{x: 0.2, y: 0.5}}
          end={{x: 1, y: 0.5}}
        >
          {
          working ? <ActivityIndicator color={Palette.plum} size={'large'} /> :
          <>
          {left}
          {right}
          </>
          }
        </LinearGradient>
      </Pressable>
    </Animated.View>
  );
}
export default NFAB2;

const styles = StyleSheet.create({
  button: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    borderRadius: 100,
    overflow: 'hidden',
  }
})