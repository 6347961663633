import React, { useContext, useEffect, useState } from 'react'
import { RootStackParamList } from '../../../types/Navigation';
import { FlatList, LayoutChangeEvent, ListRenderItem, Platform, Text, View } from 'react-native';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import { useLinkTo, useIsFocused } from '@react-navigation/native';
import ScrollableViewScreen from '../../components/Layouts/ScrollableViewScreen';
import * as Permissions from 'expo-permissions';
import log from '../../../business/logging/logger';
import { fileOpen, FileWithHandle } from 'browser-fs-access';
import { legacySetup } from '../../../business/file/fsBrowserAccess';
import NButton from '../../components/NButton';
import NText from '../../components/NText';
import NTextInput from '../../components/NTextInput';
import { Picker } from '@react-native-picker/picker';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { initializeVideo } from '../../../business/video/vimeo';
import { VideoVisibility } from '../../../contracts/ICommunityService';
import { CommunityQuestion, CommunityUser, CommunityUserStatus } from '../../../API';
import { Palette } from '../../../Theme';
import { isMobileWeb } from '../../../business/env/detectMobile';
import { QuestionHeaderCard } from '../../components/QuestionHeaderCard';
import { getQuestionUnauthenticated } from '../../../business/public/unauthenticatedQuestion';
import LocalForageWrapper from '../../../business/storage/localForageWrapper';
import PQPage from '../../components/OnePersonOneQuestion/PQPage';
import VimeoPlayer from '../../components/VimeoPlayer/VimeoPlayer';
import i18n from 'i18n-js';
import { normalizeHeight, normalizeWidth } from '../../../business/layout/responseSize';
// import { VisionCamera } from './camera/VisionCamera';
import VisibilityPicker4 from '../../components/VisibilityPicker4';
import { Provider } from 'react-native-paper';
import { TUSUpload, UploadStatus } from '../../../business/upload/TUSUpload';
import { AnswerUploadManager } from '../../../business/upload/AnswerUploadManager';
import { AnswerModel } from '../../../business/upload/AnswerModelManager';
import NActivityIndicatorModal from '../../components/ActivityIndicators/NActivityIndicatorModal';
// import { VisionCameraPermissions } from './camera/VisionCameraPermissions';
// import { Camera, CameraPermissionStatus } from 'react-native-vision-camera';
import UploadLineItemSmall from '../../components/UploadLineItemSmall';
import { VisionCamera } from '../Camera/VisionCamera';
import { convertNumberSecondsToTimestamp } from '../../../business/date/dateFormatter';
import getBlobDuration from 'get-blob-duration';

enum FileUploadStatus {
  NONE = "NONE",
  SELECTED = "SELECTED",
  UPLOADING = "UPLOADING",
  //TRANSCODING = "TRANSCODING",
  FAILED = "FAILED",
  COMPLETED = "COMPLETED",
  WAITING = "WAITING",
}

const CACHE_KEY1 = "aqb";
const CACHE_KEY2 = "ss";
const DEFAULT_MAX_VIDEO_DURATION = 15 * 60; // 15 minutes

const onMobile = () => {
  return Platform.OS != 'web' || isMobileWeb();
}

export type FastAnswerProps = BottomTabScreenProps<RootStackParamList, 'Fast Answer'>;

function FastAnswer({ route, navigation }: FastAnswerProps): React.ReactElement {
  const communityQuestionId = route.params.communityQuestionId;
  const linkTo = useLinkTo();
  const isFocused = useIsFocused();
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { communityService } = commonContext.services;
  const [pageLoading, setPageLoading] = useState(true);
  const [question, setQuestion] = useState<CommunityQuestion>()
  const [saving, setSaving] = useState(false);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [videoVisibility, setVideoVisibility] = useState(VideoVisibility.COMMUNITY);
  const [asUser, setAsUser] = useState(commonContext.loggedInUser?.id);
  const [members, setMembers] = useState<CommunityUser[]>([]);
  const [communityRole, setCommunityRole] = useState<CommunityUserStatus>();
  const [showVisibilityControl, setShowVisibilityControl] = useState(false);
  const [requiresModeration, setRequiresModeration] = useState(false);
  const [doWalkthrough, setDoWalkthrough] = useState(false);
  const [useUnauthMode, setUseUnauthMode] = useState(false);
  const [fileError, setFileError] = useState('');
  const [maxVideoDuration, setMaxVideoDuration] = useState(DEFAULT_MAX_VIDEO_DURATION);
  const [showWebcam, setShowWebcam] = useState(false);

  const [file, setFile] = useState<Blob | FileWithHandle>();
  const [uploadStatus, setUploadStatus] = useState(FileUploadStatus.NONE);

  const getFileExtension = (name: string): string => {
    const match = /\.([a-zA-Z0-9]+)$/.exec(name);
    if (match !== null) {
      return match[1];
    }
    return '';
  }

  const getMimeType = (extension: string): string => {
    const el = extension.toLowerCase();
    switch (el) {
      case 'jpg':
        return 'image/jpeg';
      case 'mp4':
        return 'video/mp4';
      case 'mov':
        return 'video/quicktime';
      case 'wmv':
        return 'video/x-ms-wmv';
      case 'avi':
        return 'video/x-msvideo';
      default:
        return `image/${extension}`;
    }
  }
  // END
  // copied from: https://github.com/tus/tus-js-client/blob/master/demos/reactnative/App.js

  useEffect(() => {

    if (isFocused) {
      const answeredQuestionBefore: boolean = LocalForageWrapper.getItem(CACHE_KEY1);
      setDoWalkthrough(!answeredQuestionBefore || !commonContext.loggedInUser);
    }

    if (commonContext.loggedInUser) {
      setUseUnauthMode(false);
      communityService.getQuestion(communityQuestionId)
        .then(q => {
          setQuestion(q);

          if (q) {
            communityService.getJoinedMembers([q.communityID])
              .then(m => {
                setMembers(m);
                setPageLoading(false);
              });
            if (q.community.requiresModeration) {
              setRequiresModeration(true);
            }
            if (q.community.limits?.maxVideoDurationSeconds != undefined) {
              setMaxVideoDuration(q.community.limits.maxVideoDurationSeconds);
            } else {
              setMaxVideoDuration(DEFAULT_MAX_VIDEO_DURATION);
            }
          }
        });
    } else {
      // user is unauthenticated
      setUseUnauthMode(true);
      // does the community allow this?
      getQuestionUnauthenticated(communityQuestionId)
        .then((q: CommunityQuestion | undefined) => {
          if (q) {
            if (q.community.limits?.maxVideoDurationSeconds != undefined) {
              setMaxVideoDuration(q.community.limits.maxVideoDurationSeconds);
            } else {
              setMaxVideoDuration(DEFAULT_MAX_VIDEO_DURATION);
            }
            setQuestion(q);
            setMembers(q.community.communityUsersByStatusDate.items.filter(i => i != null));
            setPageLoading(false);
          } else {
            // go to signin screen
            linkTo(`/signin`);
          }
        });
    }

  }, [communityQuestionId, isFocused]);

  useEffect(() => {
    if (isFocused) {
      let r;
      if (commonContext.loggedInUser && members) {
        const u = members.find(m => m.userID == commonContext.loggedInUser?.id);
        r = u?.status;
        setCommunityRole(r);
        if (members && members.length > 0) {
          setAsUser(members[0].userID);
        }
      }

      // analytics
      // we're putting this here so that we can get the community role, if possible
      // this placement assumes that members are ONLY set once after the question is retrieved from the backend
      // note: we want to record this analytics event whenever this screen enters focus
      if (question) {
        const pageName = `Create New Answer`;
        const data = {
          CommunityId: question.communityID,
          CommunityName: question.community.name,
          CommunityRole: r,
          QuestionId: question.id,
          QuestionText: question.questionText,
          QuestionCategoryId: question.categoryID,
          QuestionCategoryName: question.category?.name,
          QuestionCustomCommunityCategory: question.communityCategory,
          QuestionAskedByUserId: question.userID,
          QuestionAskedByUserFullName: commonContext.services.userService.getFullName(question.user),
          QuestionAskedByUserEmail: question.user.email,
        }
        commonContext.analytics?.viewPage(pageName, data);
      }
    }
  }, [members, isFocused]);

  useEffect(() => {
    const show = communityRole == CommunityUserStatus.ORGANIZER ||
      communityRole == CommunityUserStatus.SUBJECT ||
      commonContext.isAdmin;

    setShowVisibilityControl(show);
  }, [communityRole, commonContext.isAdmin]);

  useEffect(() => {
    if (requiresModeration && communityRole != CommunityUserStatus.ORGANIZER && communityRole != CommunityUserStatus.SUBJECT && !commonContext.isAdmin) {
      setVideoVisibility(VideoVisibility.ORGANIZER);
    }
  }, [requiresModeration, communityRole, commonContext.isAdmin]);

  async function getPermissions(): Promise<boolean> {
    if (Platform.OS == 'ios') {
      const { status } = await Permissions.askAsync(Permissions.MEDIA_LIBRARY);
      if (status !== 'granted') {
        log.error('Sorry, we need camera roll permissions to make this work!');
        return false;
      }
      return true;
    }
    return true;
  }

  async function recordWithWebcam() {
    setShowWebcam(true);
  }

  async function pickVideo() {
    try {
      const gotPermission = await getPermissions();
      if (gotPermission) {
        // works for web, most (all?) browsers
        const result = await fileOpen({
          mimeTypes: ['video/*'],
          legacySetup: legacySetup,
        });


        const duration = await getBlobDuration(result);
        if (Math.ceil(duration) > maxVideoDuration) {
          log.info(`Video duration ${duration} is too long! (max = ${maxVideoDuration} seconds)`);
          setFileError(i18n.t('VideoUpload_ExceedsMaximumDuration_ErrorMessage', { 0: convertNumberSecondsToTimestamp(maxVideoDuration) }));

          setFile(undefined);
          setUploadStatus(FileUploadStatus.NONE);
          return;
        }

        setFileError('');
        setFile(result);
        setUploadStatus(FileUploadStatus.SELECTED);
      } else {
        log.error(`didn't get appropriate permission to upload or record video!`);
      }
    } catch (error) {
      log.info({ error });
    }
  }

  const acceptRecording = (blob: Blob): void => {
    if (Platform.OS == 'ios' || Platform.OS == 'android') {
      return;
    }

    try {
      setFileError('');
      setFile(blob);
      setUploadStatus(FileUploadStatus.SELECTED);
      setShowWebcam(false);
    } catch (error) {
      console.error(error);
    }
  }

  const pressSave = async () => {
    if (Platform.OS == 'web' && !file) {
      return;
    }

    setSaving(true);
    try {
      const approach = 'tus';
      const source: 'library' | 'camera' = 'library';

      // getting a local file looks completely different on ios/android vs web, so we just split
      const blob = file;
      const size = blob.size;
      const extension = getFileExtension(file.name ?? '');
      const thingToUpload = blob; // this works with TUS upload

      console.log(`SIZE: ${size}`);
      console.log(`EXTENSION: ${extension}`);

      // platform agnostic stuff...

      // Call API to initialize the Vimeo video
      const videoData = await initializeVideo(size, title, approach);
      if (videoData.status >= 200 && videoData.status < 300) {
        // Video successfully initialized

        // Extract Vimeo metadata needed to upload
        const uploadLink = videoData.data.upload.upload_link;
        //const embedUrl = videoData.data.player_embed_url; //e.g., https://player.vimeo.com/video/673768241?h=5c89d75ccd
        const id = videoData.data.uri.replace('/videos/', '');
        const mimeType = getMimeType(extension);

        const tu = new TUSUpload(
          id,
          thingToUpload,
          uploadLink,
          `${id}.${extension}`,
          mimeType,
          size,
        );

        const am: AnswerModel = {
          id: id,
          title: title,
          description: description,
          visibility: videoVisibility,
          communityQuestionId: communityQuestionId,
          questionText: question?.questionText ?? '',
          userId: asUser ?? '',
          tusUpload: tu,
          committed: false,
          created: (new Date(Date.now())).toISOString(),
          source: source,
        };

        AnswerUploadManager.upsert(am);
        //await AnswerUploadManager.startNext();
        setUploadStatus(FileUploadStatus.COMPLETED);
        // reset();
        setSaving(false);
        await redirect();
      } else {
        log.error(`UNABLE TO CREATE VIDEO PLACEHOLDER IN VIMEO!`);
      }

      log.info(`VIDEO DATA:`);
      log.info(videoData);
      // log.info(JSON.stringify(videoData, null, 2));

    } catch (ex) {
      log.error(ex);
      setSaving(false);
    }
  }

  useEffect(() => {
    if (!isFocused && uploadStatus == FileUploadStatus.COMPLETED) {
      markComplete();
      reset();
    }
  }, [isFocused, uploadStatus]);

  const reset = () => {
    setUploadStatus(FileUploadStatus.NONE);
    setFile(undefined);
    setTitle('');
    setDescription('');
  }

  const redirect = async () => {
    if (commonContext.loggedInUser) {
      linkTo(`/community/${question?.communityID}/questions/answers/${question?.id}`);
    } else {
      //linkTo(`/uploads`);
      // await setSignInRedirect(`/community/${question?.communityID}/questions/answers/${question?.id}`);
      // linkTo(`/signin?c=${question?.communityID}`);
    }
  }

  const markComplete = () => {
    //setDoWalkthrough(false);
    LocalForageWrapper.setItem(CACHE_KEY1, true);
  }

  const goBack = async () => {
    reset();
    await redirect();
  }

  const noop = async () => { return; }

  return (
    <Provider>
      <NActivityIndicatorModal show={saving} />
      {
        !useUnauthMode &&
        <ScrollableViewScreen
          navigation={navigation}
          isFocused={isFocused}
          refreshing={pageLoading}
          topTitle={`Answers`}
          bottomTitle={`Record New`}
          goBack={goBack}
          style={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
          }}
          contentContainerStyle={{
            alignItems: 'center',
            justifyContent: 'center',
            flex: 1,
          }}
          testID={`create-new-answer-screen`}
        >
          {
            !doWalkthrough &&
            <>
              <View
                style={{
                  width: '100%',
                }}
              >
                {
                  question &&
                  <QuestionHeaderCard question={question} />
                }
              </View>
              {
                uploadStatus != FileUploadStatus.COMPLETED &&
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    flex: 1,
                  }}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '100%',
                      paddingVertical: 10,
                    }}
                    testID={`create-new-answer-view`}
                  >
                    <Text
                      style={{
                        ...themeFromContext.textVariants.questionHeader,
                        //color: themeFromContext.colors.primary,
                        textAlign: 'center',
                        paddingBottom: 30,
                        paddingTop: 15,
                      }}
                    >
                      {`Record your answer now or choose a video:`}
                    </Text>
                    {
                      !onMobile() &&
                      <NButton
                        style={{
                          marginBottom: 5
                        }}
                        t='primary'
                        title={`Record`}
                        //disabled={saving}
                        //loading={pickingVideo}
                        onPress={recordWithWebcam}
                      />
                    }
                    <NButton
                      style={{
                        marginBottom: 5
                      }}
                      t={!onMobile() ? 'secondary' : 'primary'}
                      title={onMobile() ? `Record / Upload` : `Choose file`}
                      disabled={saving}
                      //loading={pickingVideo}
                      onPress={pickVideo}
                    />
                    <Text
                      style={{
                        ...themeFromContext.textVariants.body,
                        color: themeFromContext.colors.text,
                        marginBottom: 5,
                      }}
                    >
                      {i18n.t('VideoUpload_MaximumDuration_WarningMessage', { 0: convertNumberSecondsToTimestamp(maxVideoDuration) })}
                    </Text>
                    {
                      fileError.length > 0 &&
                      <Text
                        style={{
                          ...themeFromContext.textVariants.body,
                          color: themeFromContext.colors.danger,
                          marginBottom: 5,
                        }}
                      >
                        {fileError}
                      </Text>
                    }
                    <Text
                      style={{
                        ...themeFromContext.textVariants.body,
                        color: themeFromContext.colors.secondary,
                        marginBottom: 5,
                      }}
                    >
                      {uploadStatus != FileUploadStatus.NONE ? `File selected: ${(file?.handle?.name) ?? 'Camera recording'}` : ``}
                    </Text>
                    {
                      (
                        (Platform.OS == 'web' && file)
                      ) &&
                      <View
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                          maxWidth: 600,
                          marginBottom: 10
                        }}
                      >
                        {
                          commonContext.isAdmin &&
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              width: '100%',
                              //paddingHorizontal: 20,
                              paddingTop: 30,
                            }}
                          >
                            <Text
                              style={{
                                ...themeFromContext.textVariants.inputLabel,
                                color: themeFromContext.colors.text,
                              }}
                            >
                              {`As User:`}
                            </Text>
                            <Picker
                              mode='dropdown'
                              style={{
                                borderRadius: 3,
                                borderColor: themeFromContext.colors.secondary,
                                borderWidth: 1,
                                color: themeFromContext.colors.foreground,
                                ...themeFromContext.textVariants.body,
                                padding: Platform.OS === 'ios' ? 0 : themeFromContext.spacing.z,
                                marginHorizontal: 10,
                              }}
                              selectedValue={asUser}
                              onValueChange={setAsUser}
                              enabled={!saving}
                              testID={`vimeo-screen-admin-panel-user-picker`}
                            >
                              {
                                members.map((v) => (
                                  <Picker.Item key={v.userID} label={`${v.user.firstName} ${v.user.lastName}`} value={v.userID} testID={`picker-option-${v.userID}`} />
                                ))
                              }
                            </Picker>
                          </View>
                        }
                        <NTextInput
                          placeholder={`(Optional)`}
                          value={title}
                          onChangeText={setTitle}
                          maxLength={128}
                          label={`Title:`}
                          labelStyle={{
                            //padding: 10,
                            ...themeFromContext.textVariants.inputLabel,
                            color: themeFromContext.colors.text,
                          }}
                          disabled={saving}
                          focusable={!saving}
                        />
                        <NTextInput
                          placeholder={`(Optional)`}
                          multiline={true}
                          //numberOfLines={4}
                          value={description}
                          onChangeText={setDescription}
                          maxLength={500}
                          label={`Description:`}
                          labelStyle={{
                            //padding: 10,
                            ...themeFromContext.textVariants.inputLabel,
                            color: themeFromContext.colors.text,
                          }}
                          disabled={saving}
                          focusable={!saving}
                          scrollEnabled={false}
                          inputStyle={{
                            textAlignVertical: 'center',
                            paddingTop: 8,
                            lineHeight: 18
                          }}
                        />
                        {
                          showVisibilityControl &&
                          <View
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              width: '100%',
                              //paddingHorizontal: 20,
                              paddingTop: 30,
                              zIndex: 1,
                            }}
                          >

                            <VisibilityPicker4
                              dropDownDirection={'BOTTOM'}
                              visibility={videoVisibility}
                              allowed={Object.values(VideoVisibility)}
                              authorId={commonContext.loggedInUser?.id ?? ''}
                              onValueChanged={(v) => {
                                if (v != null) {
                                  setVideoVisibility(v);
                                }
                              }}
                            />
                          </View>
                        }
                        {
                          uploadStatus != FileUploadStatus.NONE &&
                          (
                            (Platform.OS == 'web' && file)
                          ) &&
                          <>
                            <NButton
                              t='primary'
                              title={saving ? `Uploading` : `Save`}
                              onPress={() => pressSave()}
                              //loading={saving}
                              disabled={saving}
                              style={{
                                marginBottom: 70
                              }}
                            />
                          </>
                        }
                      </View>
                    }
                  </View>
                </View>
              }
            </>
          }
          {
            doWalkthrough &&
            <Walkthrough
              navigation={undefined}
              question={question}
              pickVideo={pickVideo}
              file={file}
              fileError={fileError}
              uploadStatus={uploadStatus}
              pressSave={pressSave}
              reset={reset}
              redirect={redirect}
              maxVideoDuration={maxVideoDuration}
              markComplete={markComplete}
              setShowCamera={setShowWebcam}
            />
          }
        </ScrollableViewScreen>
      }
      {
        useUnauthMode &&
        <Walkthrough
          navigation={navigation}
          question={question}
          pickVideo={pickVideo}
          file={file}
          fileError={fileError}
          uploadStatus={uploadStatus}
          pressSave={pressSave}
          reset={reset}
          redirect={redirect}
          maxVideoDuration={maxVideoDuration}
          markComplete={markComplete}
          setShowCamera={setShowWebcam}
        />
      }
      {
        showWebcam &&
        <VisionCamera active={showWebcam} onAccept={noop} onAcceptBlob={acceptRecording} onCancel={() => setShowWebcam(false)} prompt={question?.questionText} maxDuration={maxVideoDuration} />
      }
    </Provider>
  )
}

function Walkthrough(props: { navigation: any, question: CommunityQuestion | undefined, pickVideo: () => Promise<void>, file: Blob | FileWithHandle | undefined, fileError: string, uploadStatus: FileUploadStatus, pressSave: () => void, redirect: () => Promise<void>, reset: () => void, markComplete: () => void, maxVideoDuration: number, setShowCamera: (show: boolean) => void }): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const { navigation, question, pickVideo, file, fileError,  uploadStatus, pressSave, reset, markComplete, maxVideoDuration, setShowCamera } = props;
  const linkTo = useLinkTo();
  const [step, setStep] = useState(0);
  const isFocus = useIsFocused();
  const [pendingUploadIds, setPendingUploadIds] = useState<string[]>([]);
  const [numUploadsInProgress, setNumUploadsInProgress] = useState(0);
  const [refreshing, setRefreshing] = useState(true);
  const [videoHeight, setVideoHeight] = useState(0);
  // const appState = useRef(AppState.currentState);

  // useEffect(() => {
  //   const subscription = AppState.addEventListener('change', nextAppState => {
  //     if (
  //       appState.current != 'active' &&
  //       nextAppState === 'active'
  //     ) {
  //       console.log('NewAnswer page has come to the foreground!');
  //       console.log(`uploadStatus == ${uploadStatus}`);
  //       const s = LocalForageWrapper.getItem(CACHE_KEY2);
  //       if (s == 4 && uploadStatus != FileUploadStatus.UPLOADING && uploadStatus != FileUploadStatus.FAILED && uploadStatus != FileUploadStatus.WAITING) {
  //         setWalkthroughStep(0);
  //       }
  //     }
  //     appState.current = nextAppState;
  //   });

  //   return () => {
  //     subscription.remove();
  //   };
  // }, []);

  useEffect(() => {
    const currentStep = LocalForageWrapper.getItem(CACHE_KEY2);
    if (currentStep == 3 || currentStep == 4) {
      if (uploadStatus == FileUploadStatus.NONE) {
        setWalkthroughStep(1);
      }
    }
  }, []);

  useEffect(() => {
    const ups = AnswerUploadManager.getAll();
    const n = Object.values(ups).filter(u => u.tusUpload.status != UploadStatus.CANCELED && u.tusUpload.status != UploadStatus.COMPLETED).length;
    const currentStep = LocalForageWrapper.getItem(CACHE_KEY2);
    let nextStep;
    
    if (currentStep == 4) {
      if (n == 0) {
        nextStep = 0;
      }
      if (isFocus) {
        setPendingUploadIds(Object.values(ups).map(u => u.id));
        setRefreshing(false);
      }
    }

    if (n > 0) {
      nextStep = 4;
    }

    setNumUploadsInProgress(n);
    if (nextStep != undefined && currentStep != nextStep) {
      setWalkthroughStep(nextStep);
    } else if (currentStep && step != currentStep) {
      setWalkthroughStep(currentStep);
    } else {
      if (isFocus) {
        // analytics
        const pageName = getPageName(step);
        commonContext.analytics?.viewPage(pageName, {});
      }
    }
  }, [isFocus, step]);

  useEffect(() => {
    if (uploadStatus == FileUploadStatus.COMPLETED) {
      //markComplete();
      reset();
    } else if (uploadStatus == FileUploadStatus.SELECTED) {
      setWalkthroughStep(3);
    }
  }, [uploadStatus]);

  const setWalkthroughStep = (s: number) => {
    setStep(s);
    LocalForageWrapper.setItem(CACHE_KEY2, s);
  }


  const getPageName = (step: number): string => {
    switch (step) {
        case 0:
            return 'Spotlight Recording Intro';
        case 1:
            return 'Recording Tips';
        case 2:
            return 'Review Question';
        case 3:
            return 'Upload Recording';
        case 4:
            return 'Congrats';
        default:
            return 'Unknown';
    }
}

  const styles = {
    body: {
      fontSize: 16,
      fontWeight: '400',
      textAlign: 'left',
    },
    prompt: {
      fontSize: 16,
      fontWeight: '400',
      textAlign: 'right',
      whiteSpace: 'nowrap',
      padding: 6,
      paddingLeft: 2,
    },
    link: {
      fontSize: 20,
      fontWeight: '400',
      textAlign: 'center',
      marginTop: 10,
    },
    important: {
      fontSize: 14,
      fontWeight: '400',
      textAlign: 'center',
      fontStyle: 'italic',
      marginTop: 10,
    },
    askedQuestion: {
      fontSize: 16,
      fontWeight: '700',
      color: Palette.purple,
      fontStyle: 'italic',
      //lineHeight: 16,
      textAlign: 'center',
      marginVertical: 40,
      //flex: 1,
      textAlignVertical: 'center',
    },
    inputHeading: {
      fontSize: 16,
      fontWeight: '700',
      textAlign: 'left',
      width: '100%',
      marginTop: 20,
      color: Palette.purple,
    },
    question: {
      fontSize: 16,
      fontWeight: '100',
      width: '100%',
      textAlign: 'center',
      color: commonContext.theme.colors.text,
    },
    errorMessage: {
      fontSize: 12,
      fontWeight: '400',
      textAlign: 'left',
      width: '100%',
      margin: 5,
      color: commonContext.theme.colors.danger,
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'space-evenly',
      alignItems: 'flex-start',
      //maxWidth: 600,
      paddingVertical: 12,
    },
    bulletRow: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      marginBottom: 6,
    },
    bulletText: {
      fontSize: 16,
      fontWeight: '400',
      textAlign: 'left',
      flex: 1,
    },
    bulletNumber: {
      fontSize: 16,
      fontWeight: '400',
      textAlign: 'right',
      marginRight: 6,
      minWidth: 28,
    },
    pageColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      //maxWidth: 600,
      padding: 10,
      paddingBottom: 20,
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      height: '100%',
      justifyContent: 'flex-start',
      alignItems: 'center',
      //maxWidth: 600,
      paddingTop: 20,
    },
    textColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      minHeight: 200,
      justifyContent: 'center',
      alignItems: 'center',
      paddingTop: 20,
    },
    unselectedButton: {
      flex: 1,
      borderColor: Palette.transparent,
      borderWidth: 2,
      borderRadius: 12,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: 10,
    },
    selectedButton: {
      borderColor: commonContext.theme.colors.primary,
    },
    buttonText: {
      ...commonContext.theme.textVariants.askQuestionHeader,
      color: commonContext.theme.colors.text,
      marginTop: 6,
      textAlign: 'center',
    },
    label: {
      //...theme.textVariants.inputLabel,
      fontSize: normalizeWidth(16, commonContext.dimensions.width),
      fontWeight: 'bold',
      color: commonContext.theme.colors.text,
    },
    unselectedQuestion: {
      //flex: 1,
      borderColor: Palette.lightgrey,
      borderWidth: 2,
      borderRadius: 12,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingHorizontal: 12,
      //marginVertical: 4,
      //overflow: 'scroll',
      minHeight: 60,
      width: '100%',
    },
    selectedQuestion: {
      borderColor: commonContext.theme.colors.primary,
      borderWidth: 2,
    },
  };

  useEffect(() => {
    if (step == 4) {
      const ups = AnswerUploadManager.getAll();
      setPendingUploadIds(Object.values(ups).map(u => u.id));
      setRefreshing(false);
    }
  }, [step]);

  const removePendingUpload = (id: string): void => {
    const idx = pendingUploadIds.findIndex(p => p == id);
    pendingUploadIds.splice(idx, 1);
    setPendingUploadIds(pendingUploadIds);
    //setRefresh(!refresh);
  }

  const renderUploadLineItem: ListRenderItem<string> = ({ item }) => (
    <UploadLineItemSmall key={item} id={item} onRemove={removePendingUpload} showQuestion={false} isFocus={isFocus} hideStatus={true} />
  );

  function onLayout(e: LayoutChangeEvent) {
    const { height } = e.nativeEvent.layout;
    setVideoHeight(height - 200);
  }

  return (
<Provider>
    <View
      style={[
        {
          display: 'flex',
          flex: 1,
        },
        navigation ? {} : {
          padding: 10,
          paddingBottom: 20,
        }
      ]}
    >
      {
        step == 0 &&
        <PQPage
          navigation={navigation}
          title={i18n.t('NewAnswerWalkthrough_Intro_Title')}
          onLayout={onLayout}
        //next={() => setWalkthroughStep(1)}
        >
          <View style={styles.column}>
            <NText style={styles.body}>
              {i18n.t('NewAnswerWalkthrough_Intro_Body')}
            </NText>
            <View style={{ ...styles.column, flex: 1 }}>
              <VimeoPlayer
                embedUrl={`https://player.vimeo.com/video/778647119?h=cf51a0b614&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                //asset={fallbackVideo}
                height={videoHeight}
                width={videoHeight * 0.5625}
                transcodingStatus={'complete'}
                //heightConstrained={true}
              />
              <NButton
                t={'primary'}
                onPress={() => setWalkthroughStep(1)}
                title={i18n.t('NewAnswerWalkthrough_Intro_NextRecordingTips')}
                style={{
                  marginTop: 20,
                }}
              />
            </View>

          </View>
        </PQPage>
      }
      {
        step == 1 &&
        <PQPage
          navigation={navigation}
          title={i18n.t('NewAnswerWalkthrough_Step1_Title')}
          back={() => setWalkthroughStep(0)}
        //next={async () => setWalkthroughStep(2)}
        >
          <View style={styles.pageColumn}>
            <NText style={[styles.body, { marginBottom: 20 }]}>
              {i18n.t('NewAnswerWalkthrough_Step1_Body1')}
            </NText>

            <View style={styles.bulletRow}>
              <NText style={styles.bulletNumber}>
                {`1.`}
              </NText>
              <NText style={styles.bulletText}>
                {i18n.t('NewAnswerWalkthrough_Step1_Bullet1and2')}
              </NText>
            </View>
            <View style={styles.bulletRow}>
              <NText style={styles.bulletNumber}>
                {`2.`}
              </NText>
              <NText style={styles.bulletText}>
                {i18n.t('NewAnswerWalkthrough_Step1_Bullet3')}
              </NText>
            </View>

            <View style={styles.bulletRow}>
              <NText style={styles.bulletNumber}>
                {`3.`}
              </NText>
              <NText style={styles.bulletText}>
                {i18n.t('NewAnswerWalkthrough_Step1_Bullet4')}
              </NText>
            </View>
            <View style={styles.bulletRow}>
              <NText style={styles.bulletNumber}>
                {`4.`}
              </NText>
              <NText style={styles.bulletText}>
                {i18n.t('NewAnswerWalkthrough_Step1_Bullet5')}
              </NText>
            </View>
            <View style={styles.bulletRow}>
              <NText style={styles.bulletNumber}>
                {`5.`}
              </NText>
              <NText style={styles.bulletText}>
                {i18n.t('NewAnswerWalkthrough_Step1_Bullet6')}
              </NText>
            </View>

            <NButton
              t={'primary'}
              onPress={() => setWalkthroughStep(2)}
              title={i18n.t('NewAnswerWalkthrough_Step1_NextReviewQuestion')}
              style={{
                marginTop: 20,
              }}
            />
          </View>
        </PQPage>
      }
      {
        step == 2 &&
        <PQPage
          navigation={navigation}
          title={i18n.t('NewAnswerWalkthrough_Step2_Title')}
          back={() => setWalkthroughStep(1)}
          next={uploadStatus != FileUploadStatus.NONE ? async () => setWalkthroughStep(3) : undefined}
        >
          <View style={styles.pageColumn}>
            <NText style={[styles.body, { marginBottom: 20 }]}>
              {i18n.t('NewAnswerWalkthrough_Step2_Body1', { 0: question?.user.firstName ?? i18n.t('NewAnswerWalkthrough_Step2_OrganizerNameDefault') })}
            </NText>
            {
              question &&
              <View style={{ ...styles.unselectedQuestion }}>
                <NText style={[styles.askedQuestion]}>
                  {`"${question?.questionText}"`}
                </NText>
              </View>
            }
            <NText style={[styles.body, { marginTop: 20 }]}>
              {i18n.t('NewAnswerWalkthrough_Step2_Body2')}
            </NText>
            {
              !onMobile() &&
              <NButton
                style={{
                  marginTop: 20,
                  marginBottom: 10,
                }}
                t='primary'
                title={`Record`}
                //disabled={saving}
                //loading={pickingVideo}
                onPress={() => setShowCamera(true)}
              />
            }
            <NButton
              style={{
                marginTop: !onMobile() ? 0 : 20,
                marginBottom: !onMobile() ? 5 : 10,
              }}
              t={!onMobile() ? 'secondary' : 'primary'}
              title={onMobile() ? i18n.t('NewAnswerWalkthrough_Step2_RecordUpload') : i18n.t('NewAnswerWalkthrough_Step2_ChooseFile')}
              //disabled={saving}
              //loading={pickingVideo}
              onPress={() => {
                pickVideo().then(() => {
                  //if (uploadStatus != FileUploadStatus.NONE) {
                  //setWalkthroughStep(3);
                  //}
                  console.log(`testing what it's like to stay on step 2 page immediately after picking video`);
                  console.log(`consider showing a thumbnail here. also consider allowing them to watch it from here`)
                });
              }}
            />
            <Text
              style={{
                ...commonContext.theme.textVariants.body,
                color: commonContext.theme.colors.text,
                marginBottom: 5,
              }}
            >
              {i18n.t('VideoUpload_MaximumDuration_WarningMessage', { 0: convertNumberSecondsToTimestamp(maxVideoDuration) })}
            </Text>
            {
              fileError.length > 0 &&
              <Text
                style={{
                  ...commonContext.theme.textVariants.body,
                  color: commonContext.theme.colors.danger,
                  marginBottom: 5,
                }}
              >
                {fileError}
              </Text>
            }
            {
              uploadStatus != FileUploadStatus.NONE &&
              <>
                <NText style={[styles.label, { marginTop: 10 }]}>
                  {i18n.t('NewAnswerWalkthrough_Step2_VideoSelected')}
                </NText>
                <NText style={styles.body}>
                  {`${file?.handle?.name ?? i18n.t('NewAnswerWalkthrough_Step2_CameraRecording')}`}
                </NText>
                {/* <NButton
                  style={{
                    marginTop: 20,
                  }}
                  t='primary'
                  title={i18n.t('NewAnswerWalkthrough_Step2_Next')}
                  disabled={saving || pickingVideo}
                  onPress={() => setWalkthroughStep(3)}
                /> */}
              </>
            }
            {
              /* platform-specific links / videos? */
            }
            {
              // (isIOS || isIOS13) && Platform.OS == 'web' &&
              // <NText>{`[View video guide for Apple / iOS Devices (Mobile Web)]`}</NText>
            }
            {
              // isAndroid && Platform.OS == 'web' &&
              // <NText>{`[View video guide for Google / Android Devices (Mobile Web)]`}</NText>
            }
            {
              // isDesktop && Platform.OS == 'web' &&
              // <NText>{`[View video guide for Computers (Web)]`}</NText>
            }
            {
              // Platform.OS == 'ios' &&
              // <NText>{`[View video guide for Apple / iOS Devices (Mobile App)]`}</NText>
            }

          </View>
        </PQPage>
      }
      {
        // step == 3 &&
        // <PQPage
        //   navigation={navigation}
        //   title={i18n.t('FastAnswerWalkthrough_Step3_Title')}
        //   back={() => setWalkthroughStep(2)}
        //   next={async () => {
        //     if (validate3()) {
        //       setWalkthroughStep(4);
        //     }
        //   }}
        // >
        //   <View style={styles.pageColumn}>
        //   <NText style={[styles.body, { marginBottom: 12, }]}>
        //       {i18n.t('FastAnswerWalkthrough_Step3_Body1', { 0: question?.user.firstName ?? i18n.t('NewAnswerWalkthrough_Step2_OrganizerNameDefault')})}
        //     </NText>
        //     <NText style={[styles.body, { marginBottom: 20, }]}>
        //       {i18n.t('FastAnswerWalkthrough_Step3_Body2', { 0: question?.user.firstName ?? i18n.t('NewAnswerWalkthrough_Step2_OrganizerNameDefault')})}
        //     </NText>
        //     <NTextInput
        //         value={fn}
        //         onChangeText={handleFirstName}
        //         maxLength={128}
        //         hideMaxLength
        //         label={i18n.t('FastAnswerWalkthrough_Step3_FirstName')}
        //         labelStyle={styles.label}
        //         errorMessage={fnError}
        //         disabled={commonContext.loggedInUser != undefined}
        //         focusable={commonContext.loggedInUser == undefined} />
        //     <NTextInput
        //         value={ln}
        //         onChangeText={handleLastName}
        //         maxLength={128}
        //         hideMaxLength
        //         label={i18n.t('FastAnswerWalkthrough_Step3_LastName')}
        //         labelStyle={styles.label}
        //         errorMessage={lnError}
        //         disabled={commonContext.loggedInUser != undefined}
        //         focusable={commonContext.loggedInUser == undefined} />
        //   </View>
        // </PQPage>
      }
      {
        step == 3 &&
        <PQPage
          navigation={navigation}
          title={i18n.t('FastAnswerWalkthrough_Step4_Title')}
          back={() => setWalkthroughStep(2)}
        >
          <View style={styles.pageColumn}>
            <NText style={[styles.body, { marginBottom: 12, }]}>
              {i18n.t('NewAnswerWalkthrough_Step3_Body')}
            </NText>
            <NText style={[styles.inputHeading, { marginBottom: 40, textAlign: 'center', }]}>
              {i18n.t('FastAnswerWalkthrough_Step4_Body')}
            </NText>
            {/* <View
              style={{
                flex: 1,
                width: '100%',
                justifyContent: 'center',
                margin: 20,
                minHeight: 10,
                maxHeight: 15,
              }}
            >
              {
                saving &&
                <>
                  <Progress.Bar progress={percentComplete / 100} width={null} height={10} borderColor={commonContext.theme.colors.foreground} borderRadius={50} color={commonContext.theme.colors.primary} unfilledColor={commonContext.theme.colors.background} />
                  <Text>{`(${(Math.round(percentComplete * 100) / 100).toFixed(2)}% of ${Math.round(totalBytes / 1024 / 1024)} MB)`}</Text>
                </>
              }
            </View> */}
            <NButton
              t='primary'
              title={i18n.t('NewAnswerWalkthrough_Step3_SaveVideo')}
              onPress={async () => {
                console.log(`saving video`);
                await pressSave();
                console.log(`video saved`);
                if (commonContext.loggedInUser) {
                  setWalkthroughStep(0);
                } else {
                  console.log('setting walkthrough step to 5');
                  setWalkthroughStep(4);
                }
              }}
              //loading={saving}
              disabled={!file || file == null}
            />
            {
              // uploadStatus != FileUploadStatus.NONE &&
              // <>
              //   <NText style={[styles.label, { marginTop: 20 }]}>
              //     {i18n.t('NewAnswerWalkthrough_Step2_VideoSelected')}
              //   </NText>
              //   <NText style={styles.body}>
              //     {`${(Platform.OS == 'ios' || Platform.OS == 'android') ? fileUri.substring(fileUri.lastIndexOf('/') + 1) : file?.handle?.name ?? i18n.t('NewAnswerWalkthrough_Step2_CameraRecording')}`}
              //   </NText>
              // </>
            }

          </View>
        </PQPage>
      }
      {
        step == 4 &&
        <PQPage
          navigation={navigation}
          title={i18n.t('NewAnswerWalkthrough_Step4_Title')}
        >
          <View style={styles.pageColumn}>

            {
              numUploadsInProgress > 0 &&
              <NText
                style={[
                  styles.important,
                  {
                    backgroundColor: Palette.lightyellow,
                    borderColor: Palette.yellow,
                    borderWidth: 1,
                    borderRadius: 10,
                    padding: 10,
                    marginTop: 10,
                    overflow: 'hidden',
                  }
                ]}
              >
                {i18n.t('NewAnswerWalkthrough_Step3_BodyUploading')}
              </NText>
            }

            
              <FlatList
                style={{
                  display: 'flex',
                  //flex: 1,
                  width: '100%',
                  // backgroundColor: 'limegreen',
                }}
                refreshing={refreshing}
                data={pendingUploadIds}
                renderItem={renderUploadLineItem}
                keyExtractor={(item) => { return `ua-up-puli-${item}` }}
                // contentContainerStyle={{
                //   display: 'flex',
                //   flex: 1,
                //   width: '100%',
                // }}
                ListHeaderComponent={() => (
                  <View
                    style={{ padding: 4 }}
                  ></View>
                )}
                ListEmptyComponent={() => (
                  <View style={{
                    alignItems: 'center',
                    //height: '100%',
                    justifyContent: 'center',
                    padding: normalizeHeight(20, commonContext.dimensions.height),
                    paddingBottom: normalizeHeight(20, commonContext.dimensions.height),
                  }}>
                    <Text
                      style={{
                        ...commonContext.theme.textVariants.body,
                        fontSize: 18,
                        fontStyle: 'italic',
                        fontWeight: '400',
                        color: commonContext.theme.colors.text,
                        textAlign: 'center',
                        marginVertical: commonContext.theme.spacing.m,
                      }}
                      testID='uploads-empty-list-heading'
                    >
                      {`No pending uploads at this time.`}
                    </Text>
                  </View>
                )}
                ListFooterComponent={() => (
                  <View
                    style={{ padding: 4 }}
                  ></View>
                )}
              >
              </FlatList>

            <NText style={[styles.body, { marginBottom: 20, width: '100%' }]}>
              {i18n.t('NewAnswerWalkthrough_Step4_Body', { 0: question?.user.firstName ?? i18n.t('NewAnswerWalkthrough_Step4_OrganizerNameDefault') })} &#127881; {/* party popper emoji */}
            </NText>
            <NText style={[styles.body, { marginBottom: 20, width: '100%' }]}>
              {i18n.t('NewAnswerWalkthrough_Finish_Body')}
            </NText>

            {/* BUTTONS */}
            <View
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                //flex: 1,
              }}
            >
              <NButton
                t='primary'
                title={i18n.t('NewAnswerWalkthrough_Finish_CreateAccount')}
                buttonStyle={{
                  marginTop: 14
                }}
                onPress={() => {
                  if (question) {
                    linkTo(`/signup?c=${question.communityID}`)
                  } else {
                    linkTo(`/signup`);
                  }
                }}
              />
              <NButton
                t='secondary'
                title={i18n.t('NewAnswerWalkthrough_Finish_SignIn')}
                buttonStyle={{
                  marginTop: 14
                }}
                onPress={() => {
                  if (question) {
                    linkTo(`/signin?c=${question.communityID}`)
                  } else {
                    linkTo(`/signin`);
                  }
                }}
              />
            </View>
          </View>
        </PQPage>
      }
    </View>

    </Provider>
  )
}

export default FastAnswer;