import React, { useContext, useEffect, useState } from 'react';
import { Linking, Platform } from 'react-native';
import CommonContext from '../../../../CommonContext';
import { CommonContextType } from '../../../../types/CommonContextType';
import NText from '../../../components/NText';
import i18n from 'i18n-js';
import { template } from '../../../../business/video/iframe-youtube';
import IframeRenderer, { iframeModel } from '@native-html/iframe-plugin';
import RenderHTML from 'react-native-render-html';
import WebView from 'react-native-webview';
import { WebView as WebWebView } from 'react-native-web-webview';
import Panel from './Panel';

const renderers = {
  iframe: IframeRenderer
};

const customHTMLElementModels = {
  iframe: iframeModel
};

const renderersProps = {
  iframe: {
    scalesPageToFit: true,
    webViewProps: {
      /* Any prop you want to pass to iframe WebViews */
    }
  }
};

function AboutNovella(): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { width, height } = commonContext.dimensions;
  const [iframeHtml, setIframeHtml] = useState('');

  useEffect(() => {
    const f = template();
    setIframeHtml(f);
  }, []);

  return (
    <Panel
      title={i18n.t('Page_MemberPreview_AboutNovella')}
      titleTextVariantOverride={themeFromContext.textVariants.previewLabel}
      light={true}
      styleOverride={{
        marginBottom: 25,
      }}
    >    
      <NText
        style={{
          ...themeFromContext.textVariants.detail,
          textAlign: 'center',
          color: themeFromContext.colors.text,
          maxWidth: 1000,
          width: '100%',
        }}
        testID={`preview-about-novella-website-text`}
      >
        {i18n.t('Page_MemberPreview_Intro')}
        <NText
          style={{
            ...themeFromContext.textVariants.detail,
            fontWeight: 'bold',
            color: themeFromContext.colors.primary,
            textDecorationLine: 'none'
          }}
          onPress={() => Linking.openURL("https://www.meetnovella.com/")}
        >
          {`https://www.meetnovella.com`}
        </NText>
        {`.`}
      </NText>
      {/* <NText
        style={{
          ...themeFromContext.textVariants.previewLabel,
          textAlign: 'center',
          color: themeFromContext.colors.text,
          maxWidth: 1000,
          width: '100%',
          marginTop: 16,
        }}
      >
        {i18n.t('Page_MemberPreview_WhyWeExist')}
      </NText>
      <NText
        style={{
          ...themeFromContext.textVariants.previewBody,
          textAlign: 'center',
          color: themeFromContext.colors.text,
          maxWidth: 1000,
          width: '100%',
          marginTop: 8,
          marginBottom: 18,
        }}
      >
        {i18n.t('Page_MemberPreview_StoryIntro')}
      </NText>
      <RenderHTML
        contentWidth={width * 0.9}
        renderers={renderers}
        WebView={Platform.OS === 'web' ? WebWebView : WebView}
        source={{ html: iframeHtml }}
        customHTMLElementModels={customHTMLElementModels}
        defaultWebViewProps={
          {
          }
        }
        renderersProps={renderersProps}
      /> */}
    </Panel>
  );
}

export default AboutNovella;