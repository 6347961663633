// Frameworks
import React, { useContext, useEffect, useState } from 'react';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { Chip, ListItem, Text } from 'react-native-elements';
import { CommunityUserStatus } from '../../../API';
import { CommunityUser, User } from '../../../API';
import { Palette } from '../../../Theme';
import MemberAvatar from '../../components/MemberAvatar';
import NButton from '../../components/NButton';
import { Pressable, View } from 'react-native';

function MemberScreenListItem(props: {
  loggedInUser?: User,
  member: CommunityUser,
  communityRole: CommunityUserStatus,
  checked: boolean,
  onPress: () => void,
  onPressEmail: (member: CommunityUser) => void,
}): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const { communityService } = commonContext.services;
  const themeFromContext = commonContext.theme;
  const [canManage, setCanManage] = useState(false);
  const [member, setMember] = useState(props.member);

  useEffect(() => {
    if (props.loggedInUser) {
      setCanManage(communityService.canManageCommunityUser(props.communityRole, props.loggedInUser, props.member));
    }
    setMember(props.member);
  }, [props.member, props.communityRole]);

  const fillColor = (status: string): string => {
    return (status == CommunityUserStatus.SUBJECT ? Palette.pink : (status == CommunityUserStatus.ORGANIZER ? Palette.purple : themeFromContext.colors.primary ?? Palette.lightteal));
  }

  const pressCard = () => {
    if (props.loggedInUser && canManage) {
      props.onPress();
    }
  }
  return (
    <>
      <Pressable
        onPress={pressCard}
        style={{
          ...themeFromContext.card.style,
          padding: themeFromContext.spacing.m,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          overflow: 'hidden',
        }}
        testID={`member-list-item-${member.user.email ?? member.user.firstName + '-' + member.user.lastName}`}
      >
        {
          props.loggedInUser && canManage &&
            <ListItem.CheckBox
              onPress={pressCard}
              checked={props.checked}
              checkedIcon="check-circle"
              uncheckedIcon="circle-o"
              uncheckedColor={Palette.lightgrey}
              checkedColor={themeFromContext.colors.primary}
            />
        }
        <View
          style={{
            margin: 4,
            marginRight: 11,
          }}
        >
          <MemberAvatar
            size={54}
            userData={member.user}
            ignoreUpdate={true}
            containerStyle={{
              backgroundColor: fillColor(member.status).concat('55'),
            }}
            titleStyle={{
              color: fillColor(member.status),
            }}
            placeholderStyle={{
              backgroundColor: fillColor(member.status).concat('55'),
            }}
          />
        </View>
        <View
          style={{
            display: 'flex',
            flex: 1,
            margin: 4,
          }}
        >
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              flex: 1,
              //justifyContent: 'space-between',
            }}
          >
            <Text
              selectable={false}
              style={{
                ...themeFromContext.textVariants.cardHeader,
                textAlignVertical: 'bottom',
                marginRight: 10,
                flex: 1,
              }}
              numberOfLines={1}
            >
              {commonContext.services.userService.getFullName(member.user)}
            </Text>
            <Chip
              testID={`member-list-item-status-${member.user.email ?? member.user.firstName + '-' + member.user.lastName}`}
              title={member.status == CommunityUserStatus.SUBJECT ? "SPOTLIGHT" : member.status}
              titleStyle={{ paddingHorizontal: themeFromContext.spacing.s, ...themeFromContext.textVariants.chip }}
              buttonStyle={{
                backgroundColor: fillColor(member.status),
                paddingVertical: themeFromContext.spacing.s,
                marginBottom: 2,
              }}
              titleProps={{ numberOfLines: 1 }}
            />
          </View>
          {
            member.user.email && props.loggedInUser && communityService.isManagementRole(props.communityRole) &&
            <Text
              selectable={false}
              style={themeFromContext.textVariants.memberCardEmail}
              numberOfLines={1}
              testID={`member-list-item-email-text-${member.user.email ?? member.user.firstName + '-' + member.user.lastName}`}
            >
              {member.user.email}
            </Text>
          }
          {
            !member.user.email && props.loggedInUser && communityService.isManagementRole(props.communityRole) &&
            <NButton
              style={{
                alignItems: 'flex-start',
              }}
              buttonStyle={{
                minHeight: 24,
                marginHorizontal: 0,
              }}
              title={`Add email`}
              t={`brightLink`}
              onPress={() => props.onPressEmail(member)}
              testID={`member-list-item-add-email-link-${member.user.email ?? member.user.firstName + '-' + member.user.lastName}`}
            />
          }
          {/* <View style={{ display: 'flex', flexDirection: 'row', width: '100%' }}> */}
          <Text
            style={{
              flex: 1,
              ...themeFromContext.textVariants.memberCardJoined,
              alignSelf: 'flex-start',
              //paddingTop: 10
            }}
            numberOfLines={1}
          >
            {member.status !== CommunityUserStatus.INVITED ? `Joined: ${(new Date(member.joinedOn)).toLocaleDateString()}` : ''}
          </Text>
          {/* </View> */}
        </View>
        {/* <View style={{ alignSelf: 'flex-end', justifyContent: 'center', height: '100%' }}>
          <Chip
            testID={`member-list-item-status-${member.user.email ?? member.user.firstName + '-' + member.user.lastName}`}
            title={member.status == CommunityUserStatus.SUBJECT ? "SPOTLIGHT" : member.status}
            titleStyle={{ paddingHorizontal: themeFromContext.spacing.s, ...themeFromContext.textVariants.detail, fontWeight: 'bold' }}
            buttonStyle={{
              backgroundColor: fillColor(member.status),
              paddingVertical: themeFromContext.spacing.s
            }} />
        </View> */}
      </Pressable>
    </>
  );
}
export default MemberScreenListItem;