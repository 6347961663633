/* eslint-disable react/display-name */
import React, { useContext } from 'react';
import { CommunityTabParamList, QuestionsStackParamList } from '../../../types/Navigation';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { createNativeStackNavigator, NativeStackScreenProps } from '@react-navigation/native-stack';
import Answers from '../../screens/Answers/Answers';
import AnswerComments from '../../screens/AnswerComments/AnswerComments';
import AskedQuestions2 from '../../screens/AskedQuestions/AskedQuestions2';

//export type CommunityProps = DrawerScreenProps<RootStackParamList, 'Community'>;
export type QuestionsProps = NativeStackScreenProps<CommunityTabParamList, 'Questions'>;

const Stack = createNativeStackNavigator<QuestionsStackParamList>();

function QuestionsStack(props: QuestionsProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;

  const styles = {
    headerStyle: {
      backgroundColor: themeFromContext.colors?.background,
    },
    headerTitleStyle: {
      color: themeFromContext.colors?.foreground,
      fontFamily: themeFromContext.textVariants.header.fontFamily,
      //fontSize: themeFromContext.textVariants.header.fontSize
    },
    headerTintColor: themeFromContext.colors.foreground,
  };
  return (
    <>
      {
        commonContext.loggedInUser &&
        <Stack.Navigator
          screenOptions={({ navigation }) => ({
            ...styles,
            gestureEnabled: false,
            headerShown: false,
            //header: (props) => ( <Header {...props} /> ),
            sceneContainerStyle: {
              // maxWidth: MAX_WIDTH,
              // alignSelf: 'center',
              // width: '100%',
              //paddingHorizontal: 500,
            }
          })}
        >
          <Stack.Screen name='Asked'
            component={AskedQuestions2}
            initialParams={{ communityId: props.route.communityId }}
          />
          <Stack.Screen name='Answers' component={Answers} />
          <Stack.Screen name='Answer Comments' component={AnswerComments} />
        </Stack.Navigator>
      }
    </>
  );
}

export default QuestionsStack;