/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPacemakerLastRun = /* GraphQL */ `
  mutation CreatePacemakerLastRun(
    $input: CreatePacemakerLastRunInput!
    $condition: ModelPacemakerLastRunConditionInput
  ) {
    createPacemakerLastRun(input: $input, condition: $condition) {
      id
      entries {
        job
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePacemakerLastRun = /* GraphQL */ `
  mutation UpdatePacemakerLastRun(
    $input: UpdatePacemakerLastRunInput!
    $condition: ModelPacemakerLastRunConditionInput
  ) {
    updatePacemakerLastRun(input: $input, condition: $condition) {
      id
      entries {
        job
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePacemakerLastRun = /* GraphQL */ `
  mutation DeletePacemakerLastRun(
    $input: DeletePacemakerLastRunInput!
    $condition: ModelPacemakerLastRunConditionInput
  ) {
    deletePacemakerLastRun(input: $input, condition: $condition) {
      id
      entries {
        job
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const createZAnswerCreatedNotifySig = /* GraphQL */ `
  mutation CreateZAnswerCreatedNotifySig(
    $input: CreateZAnswerCreatedNotifySigInput!
    $condition: ModelzAnswerCreatedNotifySigConditionInput
  ) {
    createZAnswerCreatedNotifySig(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateZAnswerCreatedNotifySig = /* GraphQL */ `
  mutation UpdateZAnswerCreatedNotifySig(
    $input: UpdateZAnswerCreatedNotifySigInput!
    $condition: ModelzAnswerCreatedNotifySigConditionInput
  ) {
    updateZAnswerCreatedNotifySig(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteZAnswerCreatedNotifySig = /* GraphQL */ `
  mutation DeleteZAnswerCreatedNotifySig(
    $input: DeleteZAnswerCreatedNotifySigInput!
    $condition: ModelzAnswerCreatedNotifySigConditionInput
  ) {
    deleteZAnswerCreatedNotifySig(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const createZAnswerCreatedNotifyBacklog = /* GraphQL */ `
  mutation CreateZAnswerCreatedNotifyBacklog(
    $input: CreateZAnswerCreatedNotifyBacklogInput!
    $condition: ModelzAnswerCreatedNotifyBacklogConditionInput
  ) {
    createZAnswerCreatedNotifyBacklog(input: $input, condition: $condition) {
      communityID
      communityQuestionAnswerID
      communityQuestionAnswerCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateZAnswerCreatedNotifyBacklog = /* GraphQL */ `
  mutation UpdateZAnswerCreatedNotifyBacklog(
    $input: UpdateZAnswerCreatedNotifyBacklogInput!
    $condition: ModelzAnswerCreatedNotifyBacklogConditionInput
  ) {
    updateZAnswerCreatedNotifyBacklog(input: $input, condition: $condition) {
      communityID
      communityQuestionAnswerID
      communityQuestionAnswerCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteZAnswerCreatedNotifyBacklog = /* GraphQL */ `
  mutation DeleteZAnswerCreatedNotifyBacklog(
    $input: DeleteZAnswerCreatedNotifyBacklogInput!
    $condition: ModelzAnswerCreatedNotifyBacklogConditionInput
  ) {
    deleteZAnswerCreatedNotifyBacklog(input: $input, condition: $condition) {
      communityID
      communityQuestionAnswerID
      communityQuestionAnswerCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createZAnswerCreatedNotifyComplete = /* GraphQL */ `
  mutation CreateZAnswerCreatedNotifyComplete(
    $input: CreateZAnswerCreatedNotifyCompleteInput!
    $condition: ModelzAnswerCreatedNotifyCompleteConditionInput
  ) {
    createZAnswerCreatedNotifyComplete(input: $input, condition: $condition) {
      communityID
      communityQuestionAnswerID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const updateZAnswerCreatedNotifyComplete = /* GraphQL */ `
  mutation UpdateZAnswerCreatedNotifyComplete(
    $input: UpdateZAnswerCreatedNotifyCompleteInput!
    $condition: ModelzAnswerCreatedNotifyCompleteConditionInput
  ) {
    updateZAnswerCreatedNotifyComplete(input: $input, condition: $condition) {
      communityID
      communityQuestionAnswerID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const deleteZAnswerCreatedNotifyComplete = /* GraphQL */ `
  mutation DeleteZAnswerCreatedNotifyComplete(
    $input: DeleteZAnswerCreatedNotifyCompleteInput!
    $condition: ModelzAnswerCreatedNotifyCompleteConditionInput
  ) {
    deleteZAnswerCreatedNotifyComplete(input: $input, condition: $condition) {
      communityID
      communityQuestionAnswerID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const createZCommentMadeNotifySig = /* GraphQL */ `
  mutation CreateZCommentMadeNotifySig(
    $input: CreateZCommentMadeNotifySigInput!
    $condition: ModelzCommentMadeNotifySigConditionInput
  ) {
    createZCommentMadeNotifySig(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateZCommentMadeNotifySig = /* GraphQL */ `
  mutation UpdateZCommentMadeNotifySig(
    $input: UpdateZCommentMadeNotifySigInput!
    $condition: ModelzCommentMadeNotifySigConditionInput
  ) {
    updateZCommentMadeNotifySig(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteZCommentMadeNotifySig = /* GraphQL */ `
  mutation DeleteZCommentMadeNotifySig(
    $input: DeleteZCommentMadeNotifySigInput!
    $condition: ModelzCommentMadeNotifySigConditionInput
  ) {
    deleteZCommentMadeNotifySig(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const createZCommentMadeNotifyBacklog = /* GraphQL */ `
  mutation CreateZCommentMadeNotifyBacklog(
    $input: CreateZCommentMadeNotifyBacklogInput!
    $condition: ModelzCommentMadeNotifyBacklogConditionInput
  ) {
    createZCommentMadeNotifyBacklog(input: $input, condition: $condition) {
      communityID
      communityQuestionAnswerID
      communityQuestionAnswerCommentCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateZCommentMadeNotifyBacklog = /* GraphQL */ `
  mutation UpdateZCommentMadeNotifyBacklog(
    $input: UpdateZCommentMadeNotifyBacklogInput!
    $condition: ModelzCommentMadeNotifyBacklogConditionInput
  ) {
    updateZCommentMadeNotifyBacklog(input: $input, condition: $condition) {
      communityID
      communityQuestionAnswerID
      communityQuestionAnswerCommentCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteZCommentMadeNotifyBacklog = /* GraphQL */ `
  mutation DeleteZCommentMadeNotifyBacklog(
    $input: DeleteZCommentMadeNotifyBacklogInput!
    $condition: ModelzCommentMadeNotifyBacklogConditionInput
  ) {
    deleteZCommentMadeNotifyBacklog(input: $input, condition: $condition) {
      communityID
      communityQuestionAnswerID
      communityQuestionAnswerCommentCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createZCommentMadeNotifyComplete = /* GraphQL */ `
  mutation CreateZCommentMadeNotifyComplete(
    $input: CreateZCommentMadeNotifyCompleteInput!
    $condition: ModelzCommentMadeNotifyCompleteConditionInput
  ) {
    createZCommentMadeNotifyComplete(input: $input, condition: $condition) {
      communityID
      communityQuestionAnswerID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const updateZCommentMadeNotifyComplete = /* GraphQL */ `
  mutation UpdateZCommentMadeNotifyComplete(
    $input: UpdateZCommentMadeNotifyCompleteInput!
    $condition: ModelzCommentMadeNotifyCompleteConditionInput
  ) {
    updateZCommentMadeNotifyComplete(input: $input, condition: $condition) {
      communityID
      communityQuestionAnswerID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const deleteZCommentMadeNotifyComplete = /* GraphQL */ `
  mutation DeleteZCommentMadeNotifyComplete(
    $input: DeleteZCommentMadeNotifyCompleteInput!
    $condition: ModelzCommentMadeNotifyCompleteConditionInput
  ) {
    deleteZCommentMadeNotifyComplete(input: $input, condition: $condition) {
      communityID
      communityQuestionAnswerID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const createZCommunityInvitationReminderNotifySig = /* GraphQL */ `
  mutation CreateZCommunityInvitationReminderNotifySig(
    $input: CreateZCommunityInvitationReminderNotifySigInput!
    $condition: ModelzCommunityInvitationReminderNotifySigConditionInput
  ) {
    createZCommunityInvitationReminderNotifySig(
      input: $input
      condition: $condition
    ) {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const updateZCommunityInvitationReminderNotifySig = /* GraphQL */ `
  mutation UpdateZCommunityInvitationReminderNotifySig(
    $input: UpdateZCommunityInvitationReminderNotifySigInput!
    $condition: ModelzCommunityInvitationReminderNotifySigConditionInput
  ) {
    updateZCommunityInvitationReminderNotifySig(
      input: $input
      condition: $condition
    ) {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const deleteZCommunityInvitationReminderNotifySig = /* GraphQL */ `
  mutation DeleteZCommunityInvitationReminderNotifySig(
    $input: DeleteZCommunityInvitationReminderNotifySigInput!
    $condition: ModelzCommunityInvitationReminderNotifySigConditionInput
  ) {
    deleteZCommunityInvitationReminderNotifySig(
      input: $input
      condition: $condition
    ) {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const createZCommunityInvitationReminderNotifyBacklog = /* GraphQL */ `
  mutation CreateZCommunityInvitationReminderNotifyBacklog(
    $input: CreateZCommunityInvitationReminderNotifyBacklogInput!
    $condition: ModelzCommunityInvitationReminderNotifyBacklogConditionInput
  ) {
    createZCommunityInvitationReminderNotifyBacklog(
      input: $input
      condition: $condition
    ) {
      communityUserID
      userID
      communityID
      status
      invitedByUserID
      invitedOn
      createdAt
      updatedAt
    }
  }
`;
export const updateZCommunityInvitationReminderNotifyBacklog = /* GraphQL */ `
  mutation UpdateZCommunityInvitationReminderNotifyBacklog(
    $input: UpdateZCommunityInvitationReminderNotifyBacklogInput!
    $condition: ModelzCommunityInvitationReminderNotifyBacklogConditionInput
  ) {
    updateZCommunityInvitationReminderNotifyBacklog(
      input: $input
      condition: $condition
    ) {
      communityUserID
      userID
      communityID
      status
      invitedByUserID
      invitedOn
      createdAt
      updatedAt
    }
  }
`;
export const deleteZCommunityInvitationReminderNotifyBacklog = /* GraphQL */ `
  mutation DeleteZCommunityInvitationReminderNotifyBacklog(
    $input: DeleteZCommunityInvitationReminderNotifyBacklogInput!
    $condition: ModelzCommunityInvitationReminderNotifyBacklogConditionInput
  ) {
    deleteZCommunityInvitationReminderNotifyBacklog(
      input: $input
      condition: $condition
    ) {
      communityUserID
      userID
      communityID
      status
      invitedByUserID
      invitedOn
      createdAt
      updatedAt
    }
  }
`;
export const createZCommunityInvitationReminderNotifyComplete = /* GraphQL */ `
  mutation CreateZCommunityInvitationReminderNotifyComplete(
    $input: CreateZCommunityInvitationReminderNotifyCompleteInput!
    $condition: ModelzCommunityInvitationReminderNotifyCompleteConditionInput
  ) {
    createZCommunityInvitationReminderNotifyComplete(
      input: $input
      condition: $condition
    ) {
      communityUserID
      userID
      communityID
      status
      invitedByUserID
      invitedOn
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const updateZCommunityInvitationReminderNotifyComplete = /* GraphQL */ `
  mutation UpdateZCommunityInvitationReminderNotifyComplete(
    $input: UpdateZCommunityInvitationReminderNotifyCompleteInput!
    $condition: ModelzCommunityInvitationReminderNotifyCompleteConditionInput
  ) {
    updateZCommunityInvitationReminderNotifyComplete(
      input: $input
      condition: $condition
    ) {
      communityUserID
      userID
      communityID
      status
      invitedByUserID
      invitedOn
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const deleteZCommunityInvitationReminderNotifyComplete = /* GraphQL */ `
  mutation DeleteZCommunityInvitationReminderNotifyComplete(
    $input: DeleteZCommunityInvitationReminderNotifyCompleteInput!
    $condition: ModelzCommunityInvitationReminderNotifyCompleteConditionInput
  ) {
    deleteZCommunityInvitationReminderNotifyComplete(
      input: $input
      condition: $condition
    ) {
      communityUserID
      userID
      communityID
      status
      invitedByUserID
      invitedOn
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const createZCommunityNextStepCalculateSig = /* GraphQL */ `
  mutation CreateZCommunityNextStepCalculateSig(
    $input: CreateZCommunityNextStepCalculateSigInput!
    $condition: ModelzCommunityNextStepCalculateSigConditionInput
  ) {
    createZCommunityNextStepCalculateSig(input: $input, condition: $condition) {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const updateZCommunityNextStepCalculateSig = /* GraphQL */ `
  mutation UpdateZCommunityNextStepCalculateSig(
    $input: UpdateZCommunityNextStepCalculateSigInput!
    $condition: ModelzCommunityNextStepCalculateSigConditionInput
  ) {
    updateZCommunityNextStepCalculateSig(input: $input, condition: $condition) {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const deleteZCommunityNextStepCalculateSig = /* GraphQL */ `
  mutation DeleteZCommunityNextStepCalculateSig(
    $input: DeleteZCommunityNextStepCalculateSigInput!
    $condition: ModelzCommunityNextStepCalculateSigConditionInput
  ) {
    deleteZCommunityNextStepCalculateSig(input: $input, condition: $condition) {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const createZCommunityNextStepCalculateBacklog = /* GraphQL */ `
  mutation CreateZCommunityNextStepCalculateBacklog(
    $input: CreateZCommunityNextStepCalculateBacklogInput!
    $condition: ModelzCommunityNextStepCalculateBacklogConditionInput
  ) {
    createZCommunityNextStepCalculateBacklog(
      input: $input
      condition: $condition
    ) {
      id
      communityID
      nextStepType
      createdAt
      updatedAt
    }
  }
`;
export const updateZCommunityNextStepCalculateBacklog = /* GraphQL */ `
  mutation UpdateZCommunityNextStepCalculateBacklog(
    $input: UpdateZCommunityNextStepCalculateBacklogInput!
    $condition: ModelzCommunityNextStepCalculateBacklogConditionInput
  ) {
    updateZCommunityNextStepCalculateBacklog(
      input: $input
      condition: $condition
    ) {
      id
      communityID
      nextStepType
      createdAt
      updatedAt
    }
  }
`;
export const deleteZCommunityNextStepCalculateBacklog = /* GraphQL */ `
  mutation DeleteZCommunityNextStepCalculateBacklog(
    $input: DeleteZCommunityNextStepCalculateBacklogInput!
    $condition: ModelzCommunityNextStepCalculateBacklogConditionInput
  ) {
    deleteZCommunityNextStepCalculateBacklog(
      input: $input
      condition: $condition
    ) {
      id
      communityID
      nextStepType
      createdAt
      updatedAt
    }
  }
`;
export const createZCommunityNextStepCalculateComplete = /* GraphQL */ `
  mutation CreateZCommunityNextStepCalculateComplete(
    $input: CreateZCommunityNextStepCalculateCompleteInput!
    $condition: ModelzCommunityNextStepCalculateCompleteConditionInput
  ) {
    createZCommunityNextStepCalculateComplete(
      input: $input
      condition: $condition
    ) {
      id
      communityID
      nextStepType
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const updateZCommunityNextStepCalculateComplete = /* GraphQL */ `
  mutation UpdateZCommunityNextStepCalculateComplete(
    $input: UpdateZCommunityNextStepCalculateCompleteInput!
    $condition: ModelzCommunityNextStepCalculateCompleteConditionInput
  ) {
    updateZCommunityNextStepCalculateComplete(
      input: $input
      condition: $condition
    ) {
      id
      communityID
      nextStepType
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const deleteZCommunityNextStepCalculateComplete = /* GraphQL */ `
  mutation DeleteZCommunityNextStepCalculateComplete(
    $input: DeleteZCommunityNextStepCalculateCompleteInput!
    $condition: ModelzCommunityNextStepCalculateCompleteConditionInput
  ) {
    deleteZCommunityNextStepCalculateComplete(
      input: $input
      condition: $condition
    ) {
      id
      communityID
      nextStepType
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const createZFirstCommunityQuestionNotifySig = /* GraphQL */ `
  mutation CreateZFirstCommunityQuestionNotifySig(
    $input: CreateZFirstCommunityQuestionNotifySigInput!
    $condition: ModelzFirstCommunityQuestionNotifySigConditionInput
  ) {
    createZFirstCommunityQuestionNotifySig(
      input: $input
      condition: $condition
    ) {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const updateZFirstCommunityQuestionNotifySig = /* GraphQL */ `
  mutation UpdateZFirstCommunityQuestionNotifySig(
    $input: UpdateZFirstCommunityQuestionNotifySigInput!
    $condition: ModelzFirstCommunityQuestionNotifySigConditionInput
  ) {
    updateZFirstCommunityQuestionNotifySig(
      input: $input
      condition: $condition
    ) {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const deleteZFirstCommunityQuestionNotifySig = /* GraphQL */ `
  mutation DeleteZFirstCommunityQuestionNotifySig(
    $input: DeleteZFirstCommunityQuestionNotifySigInput!
    $condition: ModelzFirstCommunityQuestionNotifySigConditionInput
  ) {
    deleteZFirstCommunityQuestionNotifySig(
      input: $input
      condition: $condition
    ) {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const createZFirstCommunityQuestionNotifyBacklog = /* GraphQL */ `
  mutation CreateZFirstCommunityQuestionNotifyBacklog(
    $input: CreateZFirstCommunityQuestionNotifyBacklogInput!
    $condition: ModelzFirstCommunityQuestionNotifyBacklogConditionInput
  ) {
    createZFirstCommunityQuestionNotifyBacklog(
      input: $input
      condition: $condition
    ) {
      communityID
      communityCreatedAt
      communityQuestionID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateZFirstCommunityQuestionNotifyBacklog = /* GraphQL */ `
  mutation UpdateZFirstCommunityQuestionNotifyBacklog(
    $input: UpdateZFirstCommunityQuestionNotifyBacklogInput!
    $condition: ModelzFirstCommunityQuestionNotifyBacklogConditionInput
  ) {
    updateZFirstCommunityQuestionNotifyBacklog(
      input: $input
      condition: $condition
    ) {
      communityID
      communityCreatedAt
      communityQuestionID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteZFirstCommunityQuestionNotifyBacklog = /* GraphQL */ `
  mutation DeleteZFirstCommunityQuestionNotifyBacklog(
    $input: DeleteZFirstCommunityQuestionNotifyBacklogInput!
    $condition: ModelzFirstCommunityQuestionNotifyBacklogConditionInput
  ) {
    deleteZFirstCommunityQuestionNotifyBacklog(
      input: $input
      condition: $condition
    ) {
      communityID
      communityCreatedAt
      communityQuestionID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createZFirstCommunityQuestionNotifyComplete = /* GraphQL */ `
  mutation CreateZFirstCommunityQuestionNotifyComplete(
    $input: CreateZFirstCommunityQuestionNotifyCompleteInput!
    $condition: ModelzFirstCommunityQuestionNotifyCompleteConditionInput
  ) {
    createZFirstCommunityQuestionNotifyComplete(
      input: $input
      condition: $condition
    ) {
      communityID
      communityCreatedAt
      communityQuestionID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const updateZFirstCommunityQuestionNotifyComplete = /* GraphQL */ `
  mutation UpdateZFirstCommunityQuestionNotifyComplete(
    $input: UpdateZFirstCommunityQuestionNotifyCompleteInput!
    $condition: ModelzFirstCommunityQuestionNotifyCompleteConditionInput
  ) {
    updateZFirstCommunityQuestionNotifyComplete(
      input: $input
      condition: $condition
    ) {
      communityID
      communityCreatedAt
      communityQuestionID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const deleteZFirstCommunityQuestionNotifyComplete = /* GraphQL */ `
  mutation DeleteZFirstCommunityQuestionNotifyComplete(
    $input: DeleteZFirstCommunityQuestionNotifyCompleteInput!
    $condition: ModelzFirstCommunityQuestionNotifyCompleteConditionInput
  ) {
    deleteZFirstCommunityQuestionNotifyComplete(
      input: $input
      condition: $condition
    ) {
      communityID
      communityCreatedAt
      communityQuestionID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const createZQuestionAskedNotifySig = /* GraphQL */ `
  mutation CreateZQuestionAskedNotifySig(
    $input: CreateZQuestionAskedNotifySigInput!
    $condition: ModelzQuestionAskedNotifySigConditionInput
  ) {
    createZQuestionAskedNotifySig(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateZQuestionAskedNotifySig = /* GraphQL */ `
  mutation UpdateZQuestionAskedNotifySig(
    $input: UpdateZQuestionAskedNotifySigInput!
    $condition: ModelzQuestionAskedNotifySigConditionInput
  ) {
    updateZQuestionAskedNotifySig(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteZQuestionAskedNotifySig = /* GraphQL */ `
  mutation DeleteZQuestionAskedNotifySig(
    $input: DeleteZQuestionAskedNotifySigInput!
    $condition: ModelzQuestionAskedNotifySigConditionInput
  ) {
    deleteZQuestionAskedNotifySig(input: $input, condition: $condition) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const createZQuestionAskedNotifyBacklog = /* GraphQL */ `
  mutation CreateZQuestionAskedNotifyBacklog(
    $input: CreateZQuestionAskedNotifyBacklogInput!
    $condition: ModelzQuestionAskedNotifyBacklogConditionInput
  ) {
    createZQuestionAskedNotifyBacklog(input: $input, condition: $condition) {
      communityID
      communityQuestionID
      communityQuestionCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const updateZQuestionAskedNotifyBacklog = /* GraphQL */ `
  mutation UpdateZQuestionAskedNotifyBacklog(
    $input: UpdateZQuestionAskedNotifyBacklogInput!
    $condition: ModelzQuestionAskedNotifyBacklogConditionInput
  ) {
    updateZQuestionAskedNotifyBacklog(input: $input, condition: $condition) {
      communityID
      communityQuestionID
      communityQuestionCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const deleteZQuestionAskedNotifyBacklog = /* GraphQL */ `
  mutation DeleteZQuestionAskedNotifyBacklog(
    $input: DeleteZQuestionAskedNotifyBacklogInput!
    $condition: ModelzQuestionAskedNotifyBacklogConditionInput
  ) {
    deleteZQuestionAskedNotifyBacklog(input: $input, condition: $condition) {
      communityID
      communityQuestionID
      communityQuestionCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const createZQuestionAskedNotifyComplete = /* GraphQL */ `
  mutation CreateZQuestionAskedNotifyComplete(
    $input: CreateZQuestionAskedNotifyCompleteInput!
    $condition: ModelzQuestionAskedNotifyCompleteConditionInput
  ) {
    createZQuestionAskedNotifyComplete(input: $input, condition: $condition) {
      communityID
      communityQuestionID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const updateZQuestionAskedNotifyComplete = /* GraphQL */ `
  mutation UpdateZQuestionAskedNotifyComplete(
    $input: UpdateZQuestionAskedNotifyCompleteInput!
    $condition: ModelzQuestionAskedNotifyCompleteConditionInput
  ) {
    updateZQuestionAskedNotifyComplete(input: $input, condition: $condition) {
      communityID
      communityQuestionID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const deleteZQuestionAskedNotifyComplete = /* GraphQL */ `
  mutation DeleteZQuestionAskedNotifyComplete(
    $input: DeleteZQuestionAskedNotifyCompleteInput!
    $condition: ModelzQuestionAskedNotifyCompleteConditionInput
  ) {
    deleteZQuestionAskedNotifyComplete(input: $input, condition: $condition) {
      communityID
      communityQuestionID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCommunityCommand = /* GraphQL */ `
  mutation CreateCommunityCommand(
    $input: CreateCommunityCommandInput!
    $condition: ModelCommunityCommandConditionInput
  ) {
    createCommunityCommand(input: $input, condition: $condition) {
      id
      command {
        operation
      }
      communityID
      name
      imageKey
      type
      allowPublicPages
      limits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      vimeoVideos
      communityCategories
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCommunityCommand = /* GraphQL */ `
  mutation UpdateCommunityCommand(
    $input: UpdateCommunityCommandInput!
    $condition: ModelCommunityCommandConditionInput
  ) {
    updateCommunityCommand(input: $input, condition: $condition) {
      id
      command {
        operation
      }
      communityID
      name
      imageKey
      type
      allowPublicPages
      limits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      vimeoVideos
      communityCategories
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCommunityCommand = /* GraphQL */ `
  mutation DeleteCommunityCommand(
    $input: DeleteCommunityCommandInput!
    $condition: ModelCommunityCommandConditionInput
  ) {
    deleteCommunityCommand(input: $input, condition: $condition) {
      id
      command {
        operation
      }
      communityID
      name
      imageKey
      type
      allowPublicPages
      limits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      vimeoVideos
      communityCategories
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCommunity = /* GraphQL */ `
  mutation CreateCommunity(
    $input: CreateCommunityInput!
    $condition: ModelCommunityConditionInput
  ) {
    createCommunity(input: $input, condition: $condition) {
      id
      name
      imageKey
      type
      allowPublicPages
      alias
      publicPreviewHeaderURL
      publicPreviewDescription
      requiresModeration
      allowUnauthenticatedAnswers
      limits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      vimeoVideos
      communityCategories
      interviewTab {
        header
        bodyLine1
        bodyLine2
      }
      createdAt
      updatedAt
      owner
      communityQuestionsByStatusDate {
        items {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionRecommendations {
        items {
          id
          communityID
          categoryID
          questionText
          score
          status
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
        }
        nextToken
      }
      communityUsersByStatusDate {
        items {
          id
          communityID
          userID
          joinedOn
          invitedByUserID
          invitedOn
          invitation {
            invitationMessage
          }
          status
          relationshipToSpotlight
          owner
          group
          createdAt
          updatedAt
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
          invitedByUser {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateCommunity = /* GraphQL */ `
  mutation UpdateCommunity(
    $input: UpdateCommunityInput!
    $condition: ModelCommunityConditionInput
  ) {
    updateCommunity(input: $input, condition: $condition) {
      id
      name
      imageKey
      type
      allowPublicPages
      alias
      publicPreviewHeaderURL
      publicPreviewDescription
      requiresModeration
      allowUnauthenticatedAnswers
      limits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      vimeoVideos
      communityCategories
      interviewTab {
        header
        bodyLine1
        bodyLine2
      }
      createdAt
      updatedAt
      owner
      communityQuestionsByStatusDate {
        items {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionRecommendations {
        items {
          id
          communityID
          categoryID
          questionText
          score
          status
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
        }
        nextToken
      }
      communityUsersByStatusDate {
        items {
          id
          communityID
          userID
          joinedOn
          invitedByUserID
          invitedOn
          invitation {
            invitationMessage
          }
          status
          relationshipToSpotlight
          owner
          group
          createdAt
          updatedAt
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
          invitedByUser {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteCommunity = /* GraphQL */ `
  mutation DeleteCommunity(
    $input: DeleteCommunityInput!
    $condition: ModelCommunityConditionInput
  ) {
    deleteCommunity(input: $input, condition: $condition) {
      id
      name
      imageKey
      type
      allowPublicPages
      alias
      publicPreviewHeaderURL
      publicPreviewDescription
      requiresModeration
      allowUnauthenticatedAnswers
      limits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      vimeoVideos
      communityCategories
      interviewTab {
        header
        bodyLine1
        bodyLine2
      }
      createdAt
      updatedAt
      owner
      communityQuestionsByStatusDate {
        items {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionRecommendations {
        items {
          id
          communityID
          categoryID
          questionText
          score
          status
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
        }
        nextToken
      }
      communityUsersByStatusDate {
        items {
          id
          communityID
          userID
          joinedOn
          invitedByUserID
          invitedOn
          invitation {
            invitationMessage
          }
          status
          relationshipToSpotlight
          owner
          group
          createdAt
          updatedAt
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
          invitedByUser {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createCommunityInterviewRequestCommand = /* GraphQL */ `
  mutation CreateCommunityInterviewRequestCommand(
    $input: CreateCommunityInterviewRequestCommandInput!
    $condition: ModelCommunityInterviewRequestCommandConditionInput
  ) {
    createCommunityInterviewRequestCommand(
      input: $input
      condition: $condition
    ) {
      id
      command {
        operation
      }
      communityID
      userID
      date
      status
      message {
        subject
        body
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCommunityInterviewRequestCommand = /* GraphQL */ `
  mutation UpdateCommunityInterviewRequestCommand(
    $input: UpdateCommunityInterviewRequestCommandInput!
    $condition: ModelCommunityInterviewRequestCommandConditionInput
  ) {
    updateCommunityInterviewRequestCommand(
      input: $input
      condition: $condition
    ) {
      id
      command {
        operation
      }
      communityID
      userID
      date
      status
      message {
        subject
        body
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCommunityInterviewRequestCommand = /* GraphQL */ `
  mutation DeleteCommunityInterviewRequestCommand(
    $input: DeleteCommunityInterviewRequestCommandInput!
    $condition: ModelCommunityInterviewRequestCommandConditionInput
  ) {
    deleteCommunityInterviewRequestCommand(
      input: $input
      condition: $condition
    ) {
      id
      command {
        operation
      }
      communityID
      userID
      date
      status
      message {
        subject
        body
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCommunityInterviewRequest = /* GraphQL */ `
  mutation CreateCommunityInterviewRequest(
    $input: CreateCommunityInterviewRequestInput!
    $condition: ModelCommunityInterviewRequestConditionInput
  ) {
    createCommunityInterviewRequest(input: $input, condition: $condition) {
      id
      communityID
      userID
      date
      status
      message {
        subject
        body
      }
      owner
      createdAt
      updatedAt
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateCommunityInterviewRequest = /* GraphQL */ `
  mutation UpdateCommunityInterviewRequest(
    $input: UpdateCommunityInterviewRequestInput!
    $condition: ModelCommunityInterviewRequestConditionInput
  ) {
    updateCommunityInterviewRequest(input: $input, condition: $condition) {
      id
      communityID
      userID
      date
      status
      message {
        subject
        body
      }
      owner
      createdAt
      updatedAt
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteCommunityInterviewRequest = /* GraphQL */ `
  mutation DeleteCommunityInterviewRequest(
    $input: DeleteCommunityInterviewRequestInput!
    $condition: ModelCommunityInterviewRequestConditionInput
  ) {
    deleteCommunityInterviewRequest(input: $input, condition: $condition) {
      id
      communityID
      userID
      date
      status
      message {
        subject
        body
      }
      owner
      createdAt
      updatedAt
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createCommunityQuestion = /* GraphQL */ `
  mutation CreateCommunityQuestion(
    $input: CreateCommunityQuestionInput!
    $condition: ModelCommunityQuestionConditionInput
  ) {
    createCommunityQuestion(input: $input, condition: $condition) {
      id
      communityID
      userID
      categoryID
      communityCategory
      date
      status
      source
      questionText
      imageKeys
      clonedFromCommunityQuestionRecommendationId
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
        owner
      }
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      owner
      communityQuestionAnswersByStatusDate {
        items {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionLikesByStatus {
        items {
          id
          userID
          communityQuestionID
          status
          date
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateCommunityQuestion = /* GraphQL */ `
  mutation UpdateCommunityQuestion(
    $input: UpdateCommunityQuestionInput!
    $condition: ModelCommunityQuestionConditionInput
  ) {
    updateCommunityQuestion(input: $input, condition: $condition) {
      id
      communityID
      userID
      categoryID
      communityCategory
      date
      status
      source
      questionText
      imageKeys
      clonedFromCommunityQuestionRecommendationId
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
        owner
      }
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      owner
      communityQuestionAnswersByStatusDate {
        items {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionLikesByStatus {
        items {
          id
          userID
          communityQuestionID
          status
          date
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteCommunityQuestion = /* GraphQL */ `
  mutation DeleteCommunityQuestion(
    $input: DeleteCommunityQuestionInput!
    $condition: ModelCommunityQuestionConditionInput
  ) {
    deleteCommunityQuestion(input: $input, condition: $condition) {
      id
      communityID
      userID
      categoryID
      communityCategory
      date
      status
      source
      questionText
      imageKeys
      clonedFromCommunityQuestionRecommendationId
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
        owner
      }
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      owner
      communityQuestionAnswersByStatusDate {
        items {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionLikesByStatus {
        items {
          id
          userID
          communityQuestionID
          status
          date
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createCommunityQuestionAnswerCommand = /* GraphQL */ `
  mutation CreateCommunityQuestionAnswerCommand(
    $input: CreateCommunityQuestionAnswerCommandInput!
    $condition: ModelCommunityQuestionAnswerCommandConditionInput
  ) {
    createCommunityQuestionAnswerCommand(input: $input, condition: $condition) {
      id
      command {
        operation
      }
      communityQuestionAnswerID
      communityQuestionID
      communityID
      userID
      status
      date
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCommunityQuestionAnswerCommand = /* GraphQL */ `
  mutation UpdateCommunityQuestionAnswerCommand(
    $input: UpdateCommunityQuestionAnswerCommandInput!
    $condition: ModelCommunityQuestionAnswerCommandConditionInput
  ) {
    updateCommunityQuestionAnswerCommand(input: $input, condition: $condition) {
      id
      command {
        operation
      }
      communityQuestionAnswerID
      communityQuestionID
      communityID
      userID
      status
      date
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCommunityQuestionAnswerCommand = /* GraphQL */ `
  mutation DeleteCommunityQuestionAnswerCommand(
    $input: DeleteCommunityQuestionAnswerCommandInput!
    $condition: ModelCommunityQuestionAnswerCommandConditionInput
  ) {
    deleteCommunityQuestionAnswerCommand(input: $input, condition: $condition) {
      id
      command {
        operation
      }
      communityQuestionAnswerID
      communityQuestionID
      communityID
      userID
      status
      date
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCommunityQuestionAnswer = /* GraphQL */ `
  mutation CreateCommunityQuestionAnswer(
    $input: CreateCommunityQuestionAnswerInput!
    $condition: ModelCommunityQuestionAnswerConditionInput
  ) {
    createCommunityQuestionAnswer(input: $input, condition: $condition) {
      id
      communityQuestionID
      userID
      communityID
      status
      date
      content
      createdAt
      updatedAt
      communityQuestion {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      communityQuestionAnswerCommentsByStatusDate {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          content
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionAnswerLikesByStatus {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateCommunityQuestionAnswer = /* GraphQL */ `
  mutation UpdateCommunityQuestionAnswer(
    $input: UpdateCommunityQuestionAnswerInput!
    $condition: ModelCommunityQuestionAnswerConditionInput
  ) {
    updateCommunityQuestionAnswer(input: $input, condition: $condition) {
      id
      communityQuestionID
      userID
      communityID
      status
      date
      content
      createdAt
      updatedAt
      communityQuestion {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      communityQuestionAnswerCommentsByStatusDate {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          content
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionAnswerLikesByStatus {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteCommunityQuestionAnswer = /* GraphQL */ `
  mutation DeleteCommunityQuestionAnswer(
    $input: DeleteCommunityQuestionAnswerInput!
    $condition: ModelCommunityQuestionAnswerConditionInput
  ) {
    deleteCommunityQuestionAnswer(input: $input, condition: $condition) {
      id
      communityQuestionID
      userID
      communityID
      status
      date
      content
      createdAt
      updatedAt
      communityQuestion {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      communityQuestionAnswerCommentsByStatusDate {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          content
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionAnswerLikesByStatus {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createCommunityQuestionAnswerCommentCommand = /* GraphQL */ `
  mutation CreateCommunityQuestionAnswerCommentCommand(
    $input: CreateCommunityQuestionAnswerCommentCommandInput!
    $condition: ModelCommunityQuestionAnswerCommentCommandConditionInput
  ) {
    createCommunityQuestionAnswerCommentCommand(
      input: $input
      condition: $condition
    ) {
      id
      command {
        operation
      }
      communityQuestionAnswerCommentID
      userID
      communityID
      communityQuestionAnswerID
      content
      status
      date
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCommunityQuestionAnswerCommentCommand = /* GraphQL */ `
  mutation UpdateCommunityQuestionAnswerCommentCommand(
    $input: UpdateCommunityQuestionAnswerCommentCommandInput!
    $condition: ModelCommunityQuestionAnswerCommentCommandConditionInput
  ) {
    updateCommunityQuestionAnswerCommentCommand(
      input: $input
      condition: $condition
    ) {
      id
      command {
        operation
      }
      communityQuestionAnswerCommentID
      userID
      communityID
      communityQuestionAnswerID
      content
      status
      date
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCommunityQuestionAnswerCommentCommand = /* GraphQL */ `
  mutation DeleteCommunityQuestionAnswerCommentCommand(
    $input: DeleteCommunityQuestionAnswerCommentCommandInput!
    $condition: ModelCommunityQuestionAnswerCommentCommandConditionInput
  ) {
    deleteCommunityQuestionAnswerCommentCommand(
      input: $input
      condition: $condition
    ) {
      id
      command {
        operation
      }
      communityQuestionAnswerCommentID
      userID
      communityID
      communityQuestionAnswerID
      content
      status
      date
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCommunityQuestionAnswerComment = /* GraphQL */ `
  mutation CreateCommunityQuestionAnswerComment(
    $input: CreateCommunityQuestionAnswerCommentInput!
    $condition: ModelCommunityQuestionAnswerCommentConditionInput
  ) {
    createCommunityQuestionAnswerComment(input: $input, condition: $condition) {
      id
      userID
      communityID
      communityQuestionAnswerID
      content
      status
      date
      createdAt
      updatedAt
      communityQuestionAnswer {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateCommunityQuestionAnswerComment = /* GraphQL */ `
  mutation UpdateCommunityQuestionAnswerComment(
    $input: UpdateCommunityQuestionAnswerCommentInput!
    $condition: ModelCommunityQuestionAnswerCommentConditionInput
  ) {
    updateCommunityQuestionAnswerComment(input: $input, condition: $condition) {
      id
      userID
      communityID
      communityQuestionAnswerID
      content
      status
      date
      createdAt
      updatedAt
      communityQuestionAnswer {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteCommunityQuestionAnswerComment = /* GraphQL */ `
  mutation DeleteCommunityQuestionAnswerComment(
    $input: DeleteCommunityQuestionAnswerCommentInput!
    $condition: ModelCommunityQuestionAnswerCommentConditionInput
  ) {
    deleteCommunityQuestionAnswerComment(input: $input, condition: $condition) {
      id
      userID
      communityID
      communityQuestionAnswerID
      content
      status
      date
      createdAt
      updatedAt
      communityQuestionAnswer {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createCommunityQuestionAnswerLike = /* GraphQL */ `
  mutation CreateCommunityQuestionAnswerLike(
    $input: CreateCommunityQuestionAnswerLikeInput!
    $condition: ModelCommunityQuestionAnswerLikeConditionInput
  ) {
    createCommunityQuestionAnswerLike(input: $input, condition: $condition) {
      id
      userID
      communityID
      communityQuestionAnswerID
      status
      date
      createdAt
      updatedAt
      communityQuestionAnswer {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateCommunityQuestionAnswerLike = /* GraphQL */ `
  mutation UpdateCommunityQuestionAnswerLike(
    $input: UpdateCommunityQuestionAnswerLikeInput!
    $condition: ModelCommunityQuestionAnswerLikeConditionInput
  ) {
    updateCommunityQuestionAnswerLike(input: $input, condition: $condition) {
      id
      userID
      communityID
      communityQuestionAnswerID
      status
      date
      createdAt
      updatedAt
      communityQuestionAnswer {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteCommunityQuestionAnswerLike = /* GraphQL */ `
  mutation DeleteCommunityQuestionAnswerLike(
    $input: DeleteCommunityQuestionAnswerLikeInput!
    $condition: ModelCommunityQuestionAnswerLikeConditionInput
  ) {
    deleteCommunityQuestionAnswerLike(input: $input, condition: $condition) {
      id
      userID
      communityID
      communityQuestionAnswerID
      status
      date
      createdAt
      updatedAt
      communityQuestionAnswer {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createCommunityQuestionLike = /* GraphQL */ `
  mutation CreateCommunityQuestionLike(
    $input: CreateCommunityQuestionLikeInput!
    $condition: ModelCommunityQuestionLikeConditionInput
  ) {
    createCommunityQuestionLike(input: $input, condition: $condition) {
      id
      userID
      communityQuestionID
      status
      date
      createdAt
      updatedAt
      communityQuestion {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateCommunityQuestionLike = /* GraphQL */ `
  mutation UpdateCommunityQuestionLike(
    $input: UpdateCommunityQuestionLikeInput!
    $condition: ModelCommunityQuestionLikeConditionInput
  ) {
    updateCommunityQuestionLike(input: $input, condition: $condition) {
      id
      userID
      communityQuestionID
      status
      date
      createdAt
      updatedAt
      communityQuestion {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteCommunityQuestionLike = /* GraphQL */ `
  mutation DeleteCommunityQuestionLike(
    $input: DeleteCommunityQuestionLikeInput!
    $condition: ModelCommunityQuestionLikeConditionInput
  ) {
    deleteCommunityQuestionLike(input: $input, condition: $condition) {
      id
      userID
      communityQuestionID
      status
      date
      createdAt
      updatedAt
      communityQuestion {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createCommunityQuestionRecommendation = /* GraphQL */ `
  mutation CreateCommunityQuestionRecommendation(
    $input: CreateCommunityQuestionRecommendationInput!
    $condition: ModelCommunityQuestionRecommendationConditionInput
  ) {
    createCommunityQuestionRecommendation(
      input: $input
      condition: $condition
    ) {
      id
      communityID
      categoryID
      questionText
      score
      status
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
        owner
      }
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const updateCommunityQuestionRecommendation = /* GraphQL */ `
  mutation UpdateCommunityQuestionRecommendation(
    $input: UpdateCommunityQuestionRecommendationInput!
    $condition: ModelCommunityQuestionRecommendationConditionInput
  ) {
    updateCommunityQuestionRecommendation(
      input: $input
      condition: $condition
    ) {
      id
      communityID
      categoryID
      questionText
      score
      status
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
        owner
      }
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const deleteCommunityQuestionRecommendation = /* GraphQL */ `
  mutation DeleteCommunityQuestionRecommendation(
    $input: DeleteCommunityQuestionRecommendationInput!
    $condition: ModelCommunityQuestionRecommendationConditionInput
  ) {
    deleteCommunityQuestionRecommendation(
      input: $input
      condition: $condition
    ) {
      id
      communityID
      categoryID
      questionText
      score
      status
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
        owner
      }
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const createCommunityUserCommand = /* GraphQL */ `
  mutation CreateCommunityUserCommand(
    $input: CreateCommunityUserCommandInput!
    $condition: ModelCommunityUserCommandConditionInput
  ) {
    createCommunityUserCommand(input: $input, condition: $condition) {
      id
      command {
        operation
      }
      communityUserId
      communityID
      userID
      joinedOn
      invitedByUserID
      invitedOn
      invitation {
        invitationMessage
      }
      status
      relationshipToSpotlight
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateCommunityUserCommand = /* GraphQL */ `
  mutation UpdateCommunityUserCommand(
    $input: UpdateCommunityUserCommandInput!
    $condition: ModelCommunityUserCommandConditionInput
  ) {
    updateCommunityUserCommand(input: $input, condition: $condition) {
      id
      command {
        operation
      }
      communityUserId
      communityID
      userID
      joinedOn
      invitedByUserID
      invitedOn
      invitation {
        invitationMessage
      }
      status
      relationshipToSpotlight
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteCommunityUserCommand = /* GraphQL */ `
  mutation DeleteCommunityUserCommand(
    $input: DeleteCommunityUserCommandInput!
    $condition: ModelCommunityUserCommandConditionInput
  ) {
    deleteCommunityUserCommand(input: $input, condition: $condition) {
      id
      command {
        operation
      }
      communityUserId
      communityID
      userID
      joinedOn
      invitedByUserID
      invitedOn
      invitation {
        invitationMessage
      }
      status
      relationshipToSpotlight
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createCommunityUser = /* GraphQL */ `
  mutation CreateCommunityUser(
    $input: CreateCommunityUserInput!
    $condition: ModelCommunityUserConditionInput
  ) {
    createCommunityUser(input: $input, condition: $condition) {
      id
      communityID
      userID
      joinedOn
      invitedByUserID
      invitedOn
      invitation {
        invitationMessage
      }
      status
      relationshipToSpotlight
      owner
      group
      createdAt
      updatedAt
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      invitedByUser {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const updateCommunityUser = /* GraphQL */ `
  mutation UpdateCommunityUser(
    $input: UpdateCommunityUserInput!
    $condition: ModelCommunityUserConditionInput
  ) {
    updateCommunityUser(input: $input, condition: $condition) {
      id
      communityID
      userID
      joinedOn
      invitedByUserID
      invitedOn
      invitation {
        invitationMessage
      }
      status
      relationshipToSpotlight
      owner
      group
      createdAt
      updatedAt
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      invitedByUser {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const deleteCommunityUser = /* GraphQL */ `
  mutation DeleteCommunityUser(
    $input: DeleteCommunityUserInput!
    $condition: ModelCommunityUserConditionInput
  ) {
    deleteCommunityUser(input: $input, condition: $condition) {
      id
      communityID
      userID
      joinedOn
      invitedByUserID
      invitedOn
      invitation {
        invitationMessage
      }
      status
      relationshipToSpotlight
      owner
      group
      createdAt
      updatedAt
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      invitedByUser {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const createInvitation = /* GraphQL */ `
  mutation CreateInvitation(
    $input: CreateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    createInvitation(input: $input, condition: $condition) {
      id
      email
      toNotify
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateInvitation = /* GraphQL */ `
  mutation UpdateInvitation(
    $input: UpdateInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    updateInvitation(input: $input, condition: $condition) {
      id
      email
      toNotify
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteInvitation = /* GraphQL */ `
  mutation DeleteInvitation(
    $input: DeleteInvitationInput!
    $condition: ModelInvitationConditionInput
  ) {
    deleteInvitation(input: $input, condition: $condition) {
      id
      email
      toNotify
      status
      createdAt
      updatedAt
    }
  }
`;
export const createUsernameToUserId = /* GraphQL */ `
  mutation CreateUsernameToUserId(
    $input: CreateUsernameToUserIdInput!
    $condition: ModelUsernameToUserIdConditionInput
  ) {
    createUsernameToUserId(input: $input, condition: $condition) {
      username
      userID
    }
  }
`;
export const updateUsernameToUserId = /* GraphQL */ `
  mutation UpdateUsernameToUserId(
    $input: UpdateUsernameToUserIdInput!
    $condition: ModelUsernameToUserIdConditionInput
  ) {
    updateUsernameToUserId(input: $input, condition: $condition) {
      username
      userID
    }
  }
`;
export const deleteUsernameToUserId = /* GraphQL */ `
  mutation DeleteUsernameToUserId(
    $input: DeleteUsernameToUserIdInput!
    $condition: ModelUsernameToUserIdConditionInput
  ) {
    deleteUsernameToUserId(input: $input, condition: $condition) {
      username
      userID
    }
  }
`;
export const createUserIdToIdentityId = /* GraphQL */ `
  mutation CreateUserIdToIdentityId(
    $input: CreateUserIdToIdentityIdInput!
    $condition: ModelUserIdToIdentityIdConditionInput
  ) {
    createUserIdToIdentityId(input: $input, condition: $condition) {
      userID
      identityID
      createdAt
      updatedAt
    }
  }
`;
export const updateUserIdToIdentityId = /* GraphQL */ `
  mutation UpdateUserIdToIdentityId(
    $input: UpdateUserIdToIdentityIdInput!
    $condition: ModelUserIdToIdentityIdConditionInput
  ) {
    updateUserIdToIdentityId(input: $input, condition: $condition) {
      userID
      identityID
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserIdToIdentityId = /* GraphQL */ `
  mutation DeleteUserIdToIdentityId(
    $input: DeleteUserIdToIdentityIdInput!
    $condition: ModelUserIdToIdentityIdConditionInput
  ) {
    deleteUserIdToIdentityId(input: $input, condition: $condition) {
      userID
      identityID
      createdAt
      updatedAt
    }
  }
`;
export const createUserCommand = /* GraphQL */ `
  mutation CreateUserCommand(
    $input: CreateUserCommandInput!
    $condition: ModelUserCommandConditionInput
  ) {
    createUserCommand(input: $input, condition: $condition) {
      id
      command {
        operation
      }
      userID
      firstName
      lastName
      email
      status
      profileImageKey
      communityLimits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      welcomesSeen
      helpsSeen
      username
      identityID
      referralCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserCommand = /* GraphQL */ `
  mutation UpdateUserCommand(
    $input: UpdateUserCommandInput!
    $condition: ModelUserCommandConditionInput
  ) {
    updateUserCommand(input: $input, condition: $condition) {
      id
      command {
        operation
      }
      userID
      firstName
      lastName
      email
      status
      profileImageKey
      communityLimits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      welcomesSeen
      helpsSeen
      username
      identityID
      referralCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserCommand = /* GraphQL */ `
  mutation DeleteUserCommand(
    $input: DeleteUserCommandInput!
    $condition: ModelUserCommandConditionInput
  ) {
    deleteUserCommand(input: $input, condition: $condition) {
      id
      command {
        operation
      }
      userID
      firstName
      lastName
      email
      status
      profileImageKey
      communityLimits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      welcomesSeen
      helpsSeen
      username
      identityID
      referralCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      status
      profileImageKey
      communityLimits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      welcomesSeen
      helpsSeen
      nextSteps {
        communityID
        nextStepsByRole {
          INVITED
          MEMBER
          ORGANIZER
          SUBJECT
          REMOVED
          BANNED
          ADMIN
        }
      }
      referralCode
      referredByUserID
      username
      identityID
      createdAt
      updatedAt
      communityQuestionsByStatusDate {
        items {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionAnswersByUserStatusDate {
        items {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionlikesByStatusDate {
        items {
          id
          userID
          communityQuestionID
          status
          date
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityUsersByStatusDate {
        items {
          id
          communityID
          userID
          joinedOn
          invitedByUserID
          invitedOn
          invitation {
            invitationMessage
          }
          status
          relationshipToSpotlight
          owner
          group
          createdAt
          updatedAt
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
          invitedByUser {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      status
      profileImageKey
      communityLimits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      welcomesSeen
      helpsSeen
      nextSteps {
        communityID
        nextStepsByRole {
          INVITED
          MEMBER
          ORGANIZER
          SUBJECT
          REMOVED
          BANNED
          ADMIN
        }
      }
      referralCode
      referredByUserID
      username
      identityID
      createdAt
      updatedAt
      communityQuestionsByStatusDate {
        items {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionAnswersByUserStatusDate {
        items {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionlikesByStatusDate {
        items {
          id
          userID
          communityQuestionID
          status
          date
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityUsersByStatusDate {
        items {
          id
          communityID
          userID
          joinedOn
          invitedByUserID
          invitedOn
          invitation {
            invitationMessage
          }
          status
          relationshipToSpotlight
          owner
          group
          createdAt
          updatedAt
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
          invitedByUser {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      status
      profileImageKey
      communityLimits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      welcomesSeen
      helpsSeen
      nextSteps {
        communityID
        nextStepsByRole {
          INVITED
          MEMBER
          ORGANIZER
          SUBJECT
          REMOVED
          BANNED
          ADMIN
        }
      }
      referralCode
      referredByUserID
      username
      identityID
      createdAt
      updatedAt
      communityQuestionsByStatusDate {
        items {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionAnswersByUserStatusDate {
        items {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionlikesByStatusDate {
        items {
          id
          userID
          communityQuestionID
          status
          date
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityUsersByStatusDate {
        items {
          id
          communityID
          userID
          joinedOn
          invitedByUserID
          invitedOn
          invitation {
            invitationMessage
          }
          status
          relationshipToSpotlight
          owner
          group
          createdAt
          updatedAt
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
          invitedByUser {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const createUserGoogleTokens = /* GraphQL */ `
  mutation CreateUserGoogleTokens(
    $input: CreateUserGoogleTokensInput!
    $condition: ModelUserGoogleTokensConditionInput
  ) {
    createUserGoogleTokens(input: $input, condition: $condition) {
      owner
      accessToken
      refreshToken
      scopes
      expiresOn
      createdAt
      updatedAt
    }
  }
`;
export const updateUserGoogleTokens = /* GraphQL */ `
  mutation UpdateUserGoogleTokens(
    $input: UpdateUserGoogleTokensInput!
    $condition: ModelUserGoogleTokensConditionInput
  ) {
    updateUserGoogleTokens(input: $input, condition: $condition) {
      owner
      accessToken
      refreshToken
      scopes
      expiresOn
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserGoogleTokens = /* GraphQL */ `
  mutation DeleteUserGoogleTokens(
    $input: DeleteUserGoogleTokensInput!
    $condition: ModelUserGoogleTokensConditionInput
  ) {
    deleteUserGoogleTokens(input: $input, condition: $condition) {
      owner
      accessToken
      refreshToken
      scopes
      expiresOn
      createdAt
      updatedAt
    }
  }
`;
export const createUserReferralCode = /* GraphQL */ `
  mutation CreateUserReferralCode(
    $input: CreateUserReferralCodeInput!
    $condition: ModelUserReferralCodeConditionInput
  ) {
    createUserReferralCode(input: $input, condition: $condition) {
      referralCode
      firstName
      createdAt
      updatedAt
    }
  }
`;
export const updateUserReferralCode = /* GraphQL */ `
  mutation UpdateUserReferralCode(
    $input: UpdateUserReferralCodeInput!
    $condition: ModelUserReferralCodeConditionInput
  ) {
    updateUserReferralCode(input: $input, condition: $condition) {
      referralCode
      firstName
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserReferralCode = /* GraphQL */ `
  mutation DeleteUserReferralCode(
    $input: DeleteUserReferralCodeInput!
    $condition: ModelUserReferralCodeConditionInput
  ) {
    deleteUserReferralCode(input: $input, condition: $condition) {
      referralCode
      firstName
      createdAt
      updatedAt
    }
  }
`;
export const createXAssignEmailToPlaceholder = /* GraphQL */ `
  mutation CreateXAssignEmailToPlaceholder(
    $input: CreateXAssignEmailToPlaceholderInput!
    $condition: ModelxAssignEmailToPlaceholderConditionInput
  ) {
    createXAssignEmailToPlaceholder(input: $input, condition: $condition) {
      id
      status
      reason
      communityUserID
      userID
      communityID
      email
      invitation {
        invitationMessage
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateXAssignEmailToPlaceholder = /* GraphQL */ `
  mutation UpdateXAssignEmailToPlaceholder(
    $input: UpdateXAssignEmailToPlaceholderInput!
    $condition: ModelxAssignEmailToPlaceholderConditionInput
  ) {
    updateXAssignEmailToPlaceholder(input: $input, condition: $condition) {
      id
      status
      reason
      communityUserID
      userID
      communityID
      email
      invitation {
        invitationMessage
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteXAssignEmailToPlaceholder = /* GraphQL */ `
  mutation DeleteXAssignEmailToPlaceholder(
    $input: DeleteXAssignEmailToPlaceholderInput!
    $condition: ModelxAssignEmailToPlaceholderConditionInput
  ) {
    deleteXAssignEmailToPlaceholder(input: $input, condition: $condition) {
      id
      status
      reason
      communityUserID
      userID
      communityID
      email
      invitation {
        invitationMessage
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
