import { getLocalItem, removeLocalItem, setLocalItem } from '../cache/localForageHelper';

export const setSignInRedirect = async (path: string): Promise<void> => {
  await setLocalItem('signinredirect', path);
}

export const getSignInRedirect = async (): Promise<string | null> => {
  return await getLocalItem('signinredirect');
}

export const removeSignInRedirect = async (): Promise<void> => {
  await removeLocalItem('signinredirect');
}