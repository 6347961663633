import { API, Auth, graphqlOperation } from "aws-amplify";
import * as s from '../../graphql/subscriptions';
import { Community, CommunityUser, NextStepType, User } from "../../API";
import { RelatedUser } from "../../contracts/IRelatedUserService";
import { IUserService } from "../../contracts/IUserService";
import { Dictionary } from "../../types/data/Dictionary";
import log from "../logging/logger";

export function isValidEmail(email: string) : boolean {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export type GoogleContact = {
  id: string
  fullName?: string
  firstName?: string
  lastName?: string
  email: string
}

export type UserData = {
  id: string
  fullName: string
  firstName: string
  lastName: string
  email?: string | null
  profileImageKey?: string | null
  username?: string | null
  identityId?: string | null
}

export type RelatedUserData = {
  user: UserData
  commonUsers: string[]
}

export interface IUserIdentifiers {
  firstName: string
  lastName: string
  email?: string | null
}

export function findUsersSetDifference<T1, T2>(
  first: T1[],
  second: T2[],
  extractor1: (item: T1) => IUserIdentifiers,
  extractor2: (item: T2) => IUserIdentifiers,
) : T1[]
{
  // Match based on matching email or matching FN + LN
  // first - second
  // the way to think about this is: assume we've "seen" all the items in the second set already, and we're looking for all the items from the first set we haven't "seen" yet
  const alreadySeenEmails = new Set<string>(second.map(s => extractor2(s).email?.toLowerCase() ?? ''));
  const alreadySeenFNs = new Set<string>(second.map(s => extractor2(s).firstName.toLowerCase()));
  const alreadySeenLNs = new Set<string>(second.map(s => extractor2(s).lastName.toLowerCase()));
  let newOnes: T1[] = [];
  first.forEach(f => {
    const email = extractor1(f).email?.toLowerCase();
    const fn = extractor1(f).firstName.toLowerCase();
    const ln = extractor1(f).lastName.toLowerCase();
    if (email) {
      if (!alreadySeenEmails.has(email)) {
        newOnes = [ ...newOnes, f ];
        alreadySeenEmails.add(email ?? '');
      }
    } else if (!alreadySeenFNs.has(fn) || !alreadySeenLNs.has(ln)) {
      newOnes = [ ...newOnes, f ];
      alreadySeenFNs.add(fn);
      alreadySeenLNs.add(ln);
    }
  })
  return newOnes;
}

export function findUsersSetIntersection<T1, T2>(
  first: T1[],
  second: T2[],
  extractor1: (item: T1) => IUserIdentifiers,
  extractor2: (item: T2) => IUserIdentifiers
) : T1[]
{
  const alreadySeenEmails = new Set<string>(second.map(s => extractor2(s).email?.toLowerCase() ?? ''));
  const alreadySeenFNs = new Set<string>(second.map(s => extractor2(s).firstName.toLowerCase()));
  const alreadySeenLNs = new Set<string>(second.map(s => extractor2(s).lastName.toLowerCase()));
  let dupes: T1[] = [];
  first.forEach(f => {
    const email = extractor1(f).email?.toLowerCase();
    const fn = extractor1(f).firstName.toLowerCase();
    const ln = extractor1(f).lastName.toLowerCase();
    if (email) {
      if (alreadySeenEmails.has(email)) {
        dupes = [ ...dupes, f ];
      }
    } else if (alreadySeenFNs.has(fn) && alreadySeenLNs.has(ln)) {
      dupes = [ ...dupes, f ];
    }
  })
  return dupes;
}

export function mergeUserDataByEmailOrNames<T>(
  primary: T[],
  secondary: T[],
  extractor: (item: T) => IUserIdentifiers,
) : T[]
{
  // Merge based on matching email or matching FN + LN
  const newOnes = findUsersSetDifference<T, T>(secondary, primary, extractor, extractor);
  const output = primary.concat(newOnes);
  return output;
}

export const convertUserToUserData = (userService: IUserService, user: User) : UserData => {
  const ud: UserData = {
    email: user.email,
    firstName: user.firstName,
    lastName: user.lastName,
    fullName: userService.getFullName(user),
    profileImageKey: user.profileImageKey,
    username: user.username,
    identityId: user.identityID,
    id: user.id,
  };
  return ud;
}

export const convertGoogleContactToUserData = (contact: GoogleContact) : UserData => {
  const ud: UserData = {
    email: contact.email,
    firstName: contact.firstName ?? '',
    lastName: contact.lastName ?? '',
    fullName: contact.fullName ?? '',
    id: contact.id,
  };
  return ud;
}

export const convertRelatedUserToRelatedUserData = (userService: IUserService, users: RelatedUser[]) : RelatedUserData[] => {
  const r: RelatedUserData[] = [];
  users.forEach(u => {
    const ru: RelatedUserData = {
      user: convertUserToUserData(userService, u.user),
      commonUsers: u.commonUsers
    };
    r.push(ru);
  })
  return r;
}

export const convertGoogleContactsToRelatedUserData = (contacts: GoogleContact[]) : RelatedUserData[] => {
  const r: RelatedUserData[] = [];
  contacts.forEach(u => {
    const ru: RelatedUserData = {
      user: convertGoogleContactToUserData(u),
      commonUsers: [],
    };
    r.push(ru);
  })
  return r;
}

export function subscribeToUserUpdates(userId: string, callback: (user: User) => void) : any {
  const subscription = (API.graphql(
    graphqlOperation(s.onUpdateUser, {id: userId})
  ) as any).subscribe({
    next: (thing: any) => {
      const u: User = thing.value.data.onUpdateUser;
      log.info(JSON.stringify(thing.value.data, null, 2));
      callback(u);
    },
    error: (error: any) => {
      log.info(`subscribeToUserUpdates`);
      log.warn(error);
    }
  });

  return subscription;
}

export function subscribeToCommunityMembership(communityId: string, query: string, queryName: string, callback: (communityUser: CommunityUser) => void) : any {
  const subscription = (API.graphql(
    graphqlOperation(query, {communityID: communityId})
  ) as any).subscribe({
    next: (thing: any) => {
      const cu: CommunityUser = thing.value.data[queryName];
      callback(cu);
    },
    error: (error: any) => {
      log.info(`subscribeToCommunityMembership`);
      log.warn(error);
    }
  });

  return subscription;
}

export function subscribeToAll<T>(query: string, queryName: string, callback: (entity: T) => void) : any {
  const subscription = (API.graphql(
    graphqlOperation(query)
  ) as any).subscribe({
    next: (thing: any) => {
      const item: T = thing.value.data[queryName];
      callback(item);
    },
    error: (error: any) => {
      log.info(`subscribeToAll Error! queryName = ${queryName}`);
      log.warn(error);
    }
  });

  return subscription;
}

export function subscribeToCommunityMembershipByUserId(userId: string, query: string, queryName: string, callback: (communityUser: CommunityUser) => void) : any {
  const subscription = (API.graphql(
    graphqlOperation(query, {userID: userId})
  ) as any).subscribe({
    next: (thing: any) => {
      const cu: CommunityUser = thing.value.data[queryName];
      callback(cu);
    },
    error: (error: any) => {
      log.info(`subscribeToCommunityMembershipByUserId`);
      log.warn(error);
    }
  });

  return subscription;
}

export function subscribeTo<ModelType>(idValue: string, idName: string, queryValue: string, queryName: string, callback: (item: ModelType) => void) : any {
  const variables : { [key: string]: string } = {};
  variables[idName] = idValue;
  const subscription = (API.graphql(
    graphqlOperation(queryValue, variables)
  ) as any).subscribe({
    next: (thing: any) => {
      const x: ModelType = thing.value.data[queryName];
      callback(x);
    },
    error: (error: any) => {
      log.info(`subscribeTo`);
      log.warn(error);
    }
  });
  return subscription;
}

// export function subscribeToCommunityUserUpdates(communityUserId: string, callback: (communityUser: CommunityUser) => void) : any {
//   const subscription = (API.graphql(
//     graphqlOperation(s.onUpdateCommunityUser, {id: communityUserId})
//   ) as any).subscribe({
//     next: (thing: any) => {
//       const cu: CommunityUser = thing.value.data.onUpdateCommunityUser;
//       callback(cu);
//     },
//     error: (error: any) => log.error(error)
//   });

//   return subscription;
// }

export function subscribeToXUpdates<T>(xId: string, query: string, queryName: string, callback: (item: T) => void) : any {
  const subscription = (API.graphql(
    graphqlOperation(query, {id: xId})
  ) as any).subscribe({
    next: (thing: any) => {
      const x: T = thing.value.data[queryName];
      callback(x);
    },
    error: (error: any) => {
      log.info(`subscribeToXUpdates`);
      log.warn(error);
    }
  });

  return subscription;
}

export function subscribeToCommunityUpdates(communityId: string, callback: (community: Community) => void) : any {
  const subscription = (API.graphql(
    graphqlOperation(s.onUpdateCommunity, {id: communityId})
  ) as any).subscribe({
    next: (thing: any) => {
      const c: Community = thing.value.data.onUpdateCommunity;
      callback(c);
    },
    error: (error: any) => {
      log.info(`subscribeToCommunityUpdates`);
      log.warn(error);
    }
  });

  return subscription;
}

export function extractNextStepsFromUser(user: User, communityToMembersMap: Dictionary<CommunityUser[]>) : Dictionary<NextStepType[]> {
  // log.warn(`next steps from user`);
  // log.info(JSON.stringify(user.nextSteps, null, 2));
  const dict: Dictionary<NextStepType[]> = {};
  if (user.nextSteps) {      
    const nextSteps = user.nextSteps;
    // log.info(`communityToMembersMap: ${JSON.stringify(communityToMembersMap, null, 2)}`);
    Object.entries(communityToMembersMap).forEach(e => {
      const communityID = e[0];
      // log.info(`communityID: ${JSON.stringify(communityID, null, 2)}`);
      const nextStep = nextSteps.find(ns => ns.communityID == communityID);
      if (nextStep) {
        // log.info(`nextStep: ${JSON.stringify(nextStep, null, 2)}`);
        const communityUsers = e[1];
        const memberSelf = communityUsers.find(cu => cu.user.id == user.id);
        // log.info(`memberSelf: ${JSON.stringify(memberSelf, null, 2)}`);
        if (memberSelf) {
          const role = memberSelf.status;
          // log.info(`role: ${JSON.stringify(role, null, 2)}`);
          if (nextStep.nextStepsByRole) {
            // log.info(`nextStep.nextStepsByRole: ${JSON.stringify(nextStep.nextStepsByRole, null, 2)}`);
            const steps: NextStepType[] | undefined = nextStep.nextStepsByRole[role] as NextStepType[] | undefined;
            if (steps) {
              // log.info(`steps: ${JSON.stringify(steps, null, 2)}`);
              dict[communityID] = steps;
            }
          }
        }
      }
    });
    // log.info(JSON.stringify(dict, null, 2));
  }
  return dict;
}

export function tabForNextStep(nextStep: NextStepType): string {
  switch (nextStep) {
    case NextStepType.INVITE_ORGANIZERS:
      return "Members";
    case NextStepType.INVITE_MEMBERS:
      return "Members";
    case NextStepType.ASK_QUESTIONS:
      return "Asked";
    case NextStepType.LIKE_QUESTIONS:
      return "Asked";
    case NextStepType.REQUEST_INTERVIEW:
      return "Interview";
    default:
      return "";
  }
}

export async function refreshAuthToken(callback?: () => void) : Promise<void> {
  const luser = await Auth.currentAuthenticatedUser();
  const currentSession = luser.signInUserSession;
  luser.refreshSession(currentSession.refreshToken, () => {
    // do something with the new session
    log.info(`refreshed logged-in user's auth token`);
    if (callback) {
      callback();
    }
  });
}