import { useSafeAreaInsets as official }  from "react-native-safe-area-context";

export const TABLET_BREAK = 769;
export const PHONE_BREAK = 420;

export enum DISPLAY {
  WEB,
  TABLET,
  PHONE
}

export const MAX_WIDTH = 1000;//windowWidth;

export function useSafeAreaInsets() {

  return official();
  //return { top: 80 };
}