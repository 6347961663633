
// https://developers.google.com/identity/protocols/oauth2/web-server#exchange-authorization-code
// https://developers.google.com/identity/protocols/oauth2#4.-send-the-access-token-to-an-api.
// https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Authorization
// https://developers.google.com/people/v1/other-contacts

import { API } from 'aws-amplify';
import { getRedirects } from '../config/amplifyConfig';
import log from '../logging/logger';
import { Linking, Platform } from 'react-native';
import awsConfig from '../../aws-exports';
import { GoogleContact } from '../user/userHelper';
import { readThroughCache } from '../cache/cacheHelper';
API.configure(awsConfig);


const CLIENT_ID = `1045003564449-q1csc1q7cfbuaugmkf96u7j21tghgeg3.apps.googleusercontent.com`;
const API_NAME = `novellaREST`;
const { callback } = getRedirects(); // callback is a proxy for "URL base"
const REDIRECT_URI = `${callback}redirect`;
export const INCREMENTAL_STATE_KEY = `incremental@`;

export enum SCOPE {
  CONTACTS_OTHER = `https%3A//www.googleapis.com/auth/contacts.other.readonly`,
  CONTACTS = `https%3A//www.googleapis.com/auth/contacts.readonly`
}

export async function authorizeGoogleContacts(email: string, returnPath: string, scopes: SCOPE[], needsConsent?: boolean) : Promise<void> {
  if (scopes.length === 0) {
    throw `Must include scopes when requesting Google Authorization!`;
  }

  // build scope space-delimited list for inclusion in URL
  let scope = scopes[0].toString();
  for (let i = 1; i < scopes.length; i++) {
    scope = `${scope}%20${scopes[i].toString()}`;
  }

  const loginHint = email;
  const state = encodeURI(`${INCREMENTAL_STATE_KEY}${callback}${returnPath}`);
  const consent = needsConsent ? `&prompt=consent` : ``;

  const authURL = `https://accounts.google.com/o/oauth2/v2/auth?scope=${scope}&include_granted_scopes=true&response_type=code&redirect_uri=${REDIRECT_URI}&client_id=${CLIENT_ID}&login_hint=${loginHint}&state=${state}&access_type=offline${consent}`;
  console.log(`auth url: ${authURL}`);
  if (Platform.OS == 'web') {
    window.open(authURL , "_self");
  } else {
    await Linking.openURL(authURL);
  }
}

export type GoogleTokens = {
  accessToken: string,
  expiresInSeconds: number,
  refreshToken: string,
  scopes: string,
}

export async function exchangeCodeForTokens(code: string) : Promise<GoogleTokens | undefined> {
  const apiName = API_NAME;
  const tokenPath = `/googleAccessToken`;
  const myAccessTokenInit = {
    queryStringParameters: {
      code: code,
      redirectUri: REDIRECT_URI
    }
  }
  
  try {
    const r = await API.get(apiName, tokenPath, myAccessTokenInit);

    log.info(`GOT TOKEN RESPONSE:`);
    log.info(r);
    return r;
  } catch (ex) {
    log.error(`ERROR TRYING TO EXCHANGE CODE FOR TOKENS:`)
    log.error(ex);
  }
}

export async function refreshAccessToken(refreshToken: string) : Promise<GoogleTokens>
{
  const apiName = API_NAME;
  const tokenPath = `/refreshGoogleAccessToken`;
  const myRefreshAccessTokenInit = {
    queryStringParameters: {
      refreshToken: refreshToken,
    }
  }
  let r;
  try {
    r = await API.get(apiName, tokenPath, myRefreshAccessTokenInit);
    log.info(`GOT REFRESH TOKEN RESPONSE:`);
    log.info(r);
    return {
      accessToken: r.accessToken,
      refreshToken: refreshToken,
      expiresInSeconds: r.expiresInSeconds,
      scopes: r.scopes,
    }
  } catch (ex) {
    log.info(`GOT REFRESH TOKEN EXCEPTION:`);
    log.info(JSON.stringify(ex, null, 2));
    throw ex;
  }
}

export async function getGoogleContacts(accessToken: string) : Promise<GoogleContact[]> {
  log.info(`LOOKING IN CACHE FOR GOOGLE CONTACTS`)
  return await readThroughCache({
    key: `googleContacts-${accessToken}`,
    ifNotFound: async () => {
      const apiName = API_NAME;
      const contactsPath = `/googleContacts`;
      const myContactsInit = {
        queryStringParameters: {
          accessToken: accessToken,
        }
      }
      log.info(`NO CONTACTS CACHED -- REQUESTING CONTACTS WITH ACCESS TOKEN:`);
      log.info(JSON.stringify(myContactsInit, null, 2));
      const r = await API.get(apiName, contactsPath, myContactsInit);
      log.info(`GOT CONTACTS RESPONSE:`);
      log.info(r);
      return r;
    }
  });
}

export function isGoogleCallback(state: string) : boolean {
  return state.startsWith(INCREMENTAL_STATE_KEY);
}

export function getReturnUrl(state: string) : string {
  return state.replace(INCREMENTAL_STATE_KEY, '');
}