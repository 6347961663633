/* eslint-disable react/display-name */
// Frameworks
import React, { ForwardedRef, forwardRef, useContext, useEffect, useState } from 'react';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { Platform, TextInput } from 'react-native';
import { Input, InputProps } from 'react-native-elements';
import log from '../../business/logging/logger';
import { Palette } from '../../Theme';

const PADDING = 8;

const NTextInput = forwardRef((props: { maxHeight?: number, hideMaxLength?: boolean } & InputProps, ref: ForwardedRef<TextInput>) => {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const [characterCountText, setCharacterCountText] = useState(props.maxLength ? `(0 / ${props.maxLength} chars)` : '');
  const [height, setHeight] = useState(0);
  const useDynamicHeight = (props.multiline && !props.numberOfLines);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (!props.value) {
      updateText(''); // we need this to reset the char count when the control is dismissed...
    } else {
      updateText(props.value);
    }
}, [props.value])

  const updateCharacterCount = (len: number, max: number) => {
    setCharacterCountText(`(${len} / ${max} chars)`);
  }

  const updateText = (text: string) => {
    //log.info('0');
    if (props.onChangeText) {
      //log.info('1');
      props.onChangeText(text);
      //log.info('2');
      if (props.maxLength) {
        //log.info('3');
        updateCharacterCount(text.length, props.maxLength);
      }
    }
  }

  const hasError = props.errorMessage != undefined && props.errorMessage.length > 0;

  return (
    <Input
      {...props}
      ref={ref}
      returnKeyType={props.multiline ? 'default' : 'done'}
      selectionColor={themeFromContext.colors.primary}
      onBlur={() => setIsFocused(false)}
      onFocus={() => setIsFocused(true)}
      onContentSizeChange={(event) => {
        if (useDynamicHeight) {
          //console.warn(`dynamic height: ${event.nativeEvent.contentSize.height}`);
          const h = Math.min(props.maxHeight ?? 1000, event.nativeEvent.contentSize.height);// + (2 * PADDING);
          if (Math.floor(height) != Math.floor(h)) {
            //console.log(`height: ${Math.floor(height)} -> ${Math.floor(h)}`)
            setHeight(h);
          }
        }
        if (props.onContentSizeChange) {
          props.onContentSizeChange(event);
        }
      }}
      style={[
        useDynamicHeight ? { height: Math.max(30, height) } : {},
        {
          display: 'flex',
          flex: 1,
        },
        Platform.OS == 'web' && { outlineColor: themeFromContext.colors.primary },
        props.style,
      ]}
      containerStyle={[
        {
            display: 'flex',
            //flex: 1,
        },
        props.containerStyle,
        { paddingHorizontal: 0,}
      ]}
      inputStyle={[
        {
          display: 'flex',
          flex: 1,
          textAlignVertical: 'top',
          borderWidth: 1,
          borderColor: hasError ? themeFromContext.colors.danger : `#DDEAEB`,
          borderRadius: 10,
          width: '100%',
          padding: PADDING,
          paddingTop: 10,
          backgroundColor: Palette.white,
          overflow: 'hidden',
          ...themeFromContext.textVariants.body,
        },
        props.inputStyle,
      ]}
      placeholderTextColor={Palette.grey}
      onChangeText={updateText}
      autoCorrect
      inputContainerStyle={[
        // {
        //   display: 'flex',
        //   //flex: 1,
        //   padding: PADDING,
        // },
        props.inputContainerStyle,
        { borderWidth: 1, borderRadius: 10, borderColor: Palette.transparent, overflow: 'hidden' },
        Platform.OS != 'web' && isFocused && { borderColor: themeFromContext.colors.primary }
      ]}
      errorMessage={hasError ? props.errorMessage : characterCountText}
      errorStyle={[
        hasError ? {
          textAlign: 'left',
          color: themeFromContext.colors.danger,
          //marginBottom: themeFromContext.textVariants.detail.fontSize,
          ...themeFromContext.textVariants.detail
        } :
        {
          textAlign: 'right',
          color: themeFromContext.colors.secondary,
          //marginBottom: themeFromContext.textVariants.detail.fontSize,
          ...themeFromContext.textVariants.detail
        },
        !hasError && props.hideMaxLength ? { display: 'none' } : {},
        props.errorStyle,
      ]}
    />
  );
});
export default NTextInput;