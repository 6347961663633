// Frameworks
import React, { useContext } from 'react';
import { View } from 'react-native';

// Theme
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { StepProps } from '../../../types/Steps';
import RelatedUserPicker from '../../components/RelatedUserPicker';
import NButton from '../../components/NButton';
import { Text } from 'react-native-elements';
import { SubjectList } from '../../../business/user/subjectList';
import { RelatedUserData } from '../../../business/user/userHelper';

type NewCommunityChooseSubjectsProps = StepProps<SubjectList> & {
  relatedUsers: RelatedUserData[] | undefined
  max: number
};


function NewCommunityChooseSubjects(props: NewCommunityChooseSubjectsProps): React.ReactElement {
  const myTurn = props.step === props.currentStep;
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  //const [chosenUsers, setChosenUsers] = useState<RelatedUserData[]>([]);

  // useEffect(() => {
  //   if (props.inputPayload) {
  //     setChosenUsers(props.inputPayload);
  //   } else {
  //     setChosenUsers([]);
  //   }
  // }, [props.inputPayload])

  const finish = () => {
    props.onFinish();
  }

  const selectionChanged = (selectedUsers: RelatedUserData[]) => {
    // setChosenUsers(selectedUsers);
    // log.info("chosen users");
    // log.info(chosenUsers);
    props.onPayloadChange(new SubjectList(selectedUsers));
  }

  return (
    <>
      {
        myTurn &&
        <View
        // style={{
        //   height: '100%',
        // }}
        >
          {
            !props.inputPayload &&
            <Text
              style={{
                //paddingHorizontal: 60,
                textAlign: 'center',
                marginBottom: 10,
                ...themeFromContext.textVariants.wizardBody
              }}
            >
              {`You can choose up to ${props.max} spotlight member(s)`}
            </Text>
          }
          {
            props.inputPayload &&
            props.inputPayload.subjects.length < props.max &&
            <Text
              style={{
                //paddingHorizontal: 60,
                textAlign: 'center',
                marginBottom: 8,
                ...themeFromContext.textVariants.wizardBody
              }}
            >
              {`You can choose up to ${props.max - props.inputPayload.subjects.length} more spotlight member(s)`}
            </Text>
          }
          {
            props.inputPayload && props.inputPayload.subjects.length > props.max &&
            <View>
              <Text
                style={{
                  ...themeFromContext.textVariants.detail,
                  color: themeFromContext.colors.failure
                }}
              >
                {`Please limit your selection to ${props.max} spotlight members`}
              </Text>
            </View>
          }
          {/* <View
            style={{
              flex: 1,
              height: '100%',
              width: '100%',
              marginBottom: 100,
            }}
          > */}
          <RelatedUserPicker fullList={props.relatedUsers} alreadyPicked={props.inputPayload?.subjects} onSelectionChanged={selectionChanged} />
          {/* </View> */}
          {/* <View
            style={{
              flex: 1,
            }}
          > */}
          <NButton
            t="primary"
            titleStyle={{
              fontSize: 14,
              fontWeight: '700',
            }}
            buttonStyle={{
              marginVertical: 10,
            }}
            style={{
              alignSelf: 'center',
            }}
            title={`Next step: Include others`}
            disabled={!props.inputPayload || props.inputPayload.subjects.length === 0 || props.inputPayload.subjects.length > props.max}
            onPress={finish}
            testID='create-community-choose-spotlight-next-button'
          />
        </View>
        // </View>
      }
    </>
  );
}
export default NewCommunityChooseSubjects;