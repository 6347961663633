// Frameworks
import React, { useContext } from 'react';
import { View } from 'react-native';
import { Icon } from 'react-native-elements';
import { Palette } from '../../../Theme';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import NButton from '../NButton';
import { TOP_HEADER_BASE_HEIGHT } from '../../../constants/header';
import log from '../../../business/logging/logger';

// If we have a button title, then we ignore the icon; if we don't have a button title, we ignore the padding and border...
function HeaderMenuButton(props: { menuButtonTitle?: string, materialCommunityIconName?: string, action?: () => void, position: 'left' | 'right' }): React.ReactElement {
  const { height } = useContext<CommonContextType>(CommonContext).dimensions;
  return (
    <View
      style={{ height: TOP_HEADER_BASE_HEIGHT, paddingHorizontal: 10, alignItems: 'flex-end', justifyContent: 'center' }}
    >
      <NButton
        hitSlop={{top: 50, left: 50, bottom: 50, right: 50}}
        t={props.menuButtonTitle ? 'secondary' : 'brightLink'}
        icon={
          (props.menuButtonTitle == undefined || props.materialCommunityIconName != undefined) &&
          <Icon
            //size={24}
            name={ props.materialCommunityIconName ?? 'menu' }
            type='material-community'
            color={ props.action ? Palette.white : Palette.transparent }
            disabled={!(props.action)}
            disabledStyle={{ backgroundColor: Palette.transparent, }}
            style={{
              marginRight: props.menuButtonTitle == undefined ? 0 : 10,
            }}
          />
        }
        title={props.menuButtonTitle}
        titleStyle={{
          color: Palette.white,
        }}
        buttonStyle={[
          {
            //width: 100,
            display: 'flex',
          },
          !props.menuButtonTitle ? {} :
          {
            paddingVertical: 6,
            //paddingHorizontal: 14,
            //width: 100,
            backgroundColor: Palette.transparent,
            borderColor: 'rgba(255, 255, 255, 0.5)',
            borderWidth: 2,
          },
          props.position == 'left' ?
          {
            justifyContent: 'flex-start',
          } :
          {
            justifyContent: 'flex-end',
          }
        ]}
        onPress={() =>  props.action ? props.action() : () => { log.info(`no action hooked up!`) } }
        testID={`header-menu-${props.materialCommunityIconName ?? 'menu'}-button`}
      />
      
      {/* <Icon
        name={
          props.action ? 'menu' : 'arrow-left'
        }
        type='material-community'
        color={
          (props.action || props.goBack) ? Palette.white : Palette.plum
        }
        onPress={() =>
          props.action ? props.action() :
          props.goBack ? props.goBack()  :
          () => { return; }
        }
        disabled={!(props.action || props.goBack)}
        disabledStyle={{
          backgroundColor: Palette.plum,
        }}
        testID={`header-menu-${props.action ? 'menu' : props.goBack ? 'back' : 'placeholder'}-button`}
      /> */}
    </View>
  );
}
export default HeaderMenuButton;