// Frameworks
import React, { useContext, useEffect,  useState } from 'react';
import { Easing, Image, View, Animated } from 'react-native';
import { normalizeHeight } from '../../../business/layout/responseSize';
import { Palette } from '../../../Theme';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';

function LogoAnimation(props: { color?: string }) : React.ReactElement {
  const { height } = useContext<CommonContextType>(CommonContext).dimensions;
  const [v1, setV1] = useState(false);
  const [v2, setV2] = useState(false);
  const [v3, setV3] = useState(false);
  const [v4, setV4] = useState(false);
  const [v5, setV5] = useState(false);
  const [v6, setV6] = useState(false);
  const [v7, setV7] = useState(false);
  const [v8, setV8] = useState(false);
  const [v9, setV9] = useState(true);
  const [v10, setV10] = useState(false);
  const [dim, setDim] = useState(normalizeHeight(50, height));
  const [wormdim, setWormdim] = useState(normalizeHeight(70, height));
  const [anim] = useState(new Animated.Value(0));
  let color = Palette.white;

  if (props.color) {
    color = props.color;
  }

  useEffect(() => {
    anim.addListener((value) => {
      //log.info(`value: ${Math.floor(value.value)}`);
      setV1(Math.floor(value.value) % 10 == 0);
      setV2(Math.floor(value.value) % 10 == 1);
      setV3(Math.floor(value.value) % 10 == 2);
      setV4(Math.floor(value.value) % 10 == 3);
      setV5(Math.floor(value.value) % 10 == 4);
      setV6(Math.floor(value.value) % 10 == 5);
      setV7(Math.floor(value.value) % 10 == 6);
      setV8(Math.floor(value.value) % 10 == 7);
      setV9(Math.floor(value.value) % 10 == 8);
      setV10(Math.floor(value.value) % 10 == 9);
    });

    Animated.loop(
      Animated.timing(
        anim,
        {
          toValue: 1000,
          duration: 250000,
          useNativeDriver: true,
          easing: Easing.linear,
        }
      ), {
        iterations: -1,
        resetBeforeIteration: true,
      }
    ).start();

    return () => {
      anim.removeAllListeners();
    }
  }, []);


  return (
    <View
      style={{
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <Animated.Image
        source={require(`../../../../assets/activity-indicator/worm-white.png`)}
        resizeMode='contain'
        style={
          [
            { width: wormdim, height: wormdim, position: 'absolute', tintColor: color },
            {
              transform: [
                {
                  rotate: anim.interpolate({
                    inputRange: [0, 2],
                    outputRange: ['0deg', '360deg'],
                  }),
                }
              ]
            }
          ]
        }
      />
      <Image
        source={require('../../../../assets/activity-indicator/mark-teal2.png')}
        resizeMode='contain'
        style={{ width: dim, height: dim, display: v9 ? 'flex' : 'none' }}
      />
      <Image
        source={require(`../../../../assets/activity-indicator/mark-green.png`)}
        resizeMode='contain'
        style={{ width: dim, height: dim, display: v1 ? 'flex' : 'none' }}
      />
      <Image
        source={require('../../../../assets/activity-indicator/mark-teal.png')}
        resizeMode='contain'
        style={{ width: dim, height: dim, display: v2 ? 'flex' : 'none' }}
      />
      <Image
        source={require('../../../../assets/activity-indicator/mark-orange.png')}
        resizeMode='contain'
        style={{ width: dim, height: dim, display: v3 ? 'flex' : 'none' }}
      />
      <Image
        source={require('../../../../assets/activity-indicator/mark-pink.png')}
        resizeMode='contain'
        style={{ width: dim, height: dim, display: v4 ? 'flex' : 'none' }}
      />
      <Image
        source={require('../../../../assets/activity-indicator/mark-purple.png')}
        resizeMode='contain'
        style={{ width: dim, height: dim, display: v5 ? 'flex' : 'none' }}
      />
      <Image
        source={require('../../../../assets/activity-indicator/mark-yellow.png')}
        resizeMode='contain'
        style={{ width: dim, height: dim, display: v6 ? 'flex' : 'none' }}
      />
      <Image
        source={require('../../../../assets/activity-indicator/mark-green2.png')}
        resizeMode='contain'
        style={{ width: dim, height: dim, display: v7 ? 'flex' : 'none' }}
      />
      <Image
        source={require('../../../../assets/activity-indicator/mark-orange2.png')}
        resizeMode='contain'
        style={{ width: dim, height: dim, display: v8 ? 'flex' : 'none' }}
      />
      <Image
        source={require('../../../../assets/activity-indicator/mark-yellow2.png')}
        resizeMode='contain'
        style={{ width: dim, height: dim, display: v10 ? 'flex' : 'none' }}
      />
    </View>
  );
}
export default LogoAnimation;