// Frameworks
import React from 'react';
import { Linking, Platform, View } from 'react-native';
import { useSafeAreaInsets } from '../../business/layout/layout';
import HeaderFirst from './Header/HeaderFirst';
import { WEBSITE_HEADER_HEIGHT } from '../../constants/header';
import { useLinkTo } from '@react-navigation/native';
import i18n from 'i18n-js';
import { Palette } from '../../Theme';

function WebHeaderMimic(props: { navigation: any }): React.ReactElement {
  const { top } = useSafeAreaInsets();
  const linkTo = useLinkTo();
  return (
    <>
      <View style={{ position: 'absolute', top: 0, height: top, width: '100%', backgroundColor: Palette.plum, zIndex: 10 }} />
      <HeaderFirst
        height={WEBSITE_HEADER_HEIGHT}
        maxWidth={'100%'}
        logoHeight={50}
        navigation={props.navigation}
        top={top}
        // goBack={() => {
        //   if (Platform.OS == 'web') {
        //     window.open('https://meetnovella.com' , "_self");
        //   } else {
        //     Linking.openURL('https://meetnovella.com')
        //   }
        // }}
        // goBackIconName={'home'}
        menuAction={() => linkTo(`/signin`)}
        menuIconName={'account-circle'}
        menuButtonTitle={i18n.t('Page_MemberPreview_Button_SignIn')}
      />
    </>
  );
}
export default WebHeaderMimic;