import { Community } from "../API";
import { IUserService } from "../contracts/IUserService";
import { ICommunityService } from "../contracts/ICommunityService";
import { Dictionary } from "../types/data/Dictionary";
import { IRelatedUserService, RelatedUser, UserCommunitiesMap } from "../contracts/IRelatedUserService";

export class RelatedUserService implements IRelatedUserService {
  userService: IUserService;
  communityService: ICommunityService;
  constructor(userService: IUserService, communityService: ICommunityService) {
    this.userService = userService;
    this.communityService = communityService;
  }
  async getKnownUsers(excludedCommunityIds?: string[]) : Promise<RelatedUser[]>
  {
    // unfortunately these need to happen serially
    const loggedInUser = await this.userService.getLoggedInUser();
    const communities = await this.communityService.getCommunityDataForLoggedInUser();
    const filteredCommunities = communities.filter(c => !excludedCommunityIds || !excludedCommunityIds.includes(c.id));
    const knownMembers = await this.communityService.getJoinedMembers(filteredCommunities.map(c => c.id));

    // transform the data

    const communityDictionary : Dictionary<Community> = {};
    communities.forEach(c => {
      communityDictionary[c.id] = c;
    });

    // what we want here is a map from each related user to an array of community ids
    const knownUsers: Dictionary<UserCommunitiesMap> = {};
    // add the user himself, if no one else...
    // stop doing this, it's confusing people?
    //knownUsers[loggedInUser.id] = { user: loggedInUser, communities: [] };
    knownMembers.forEach(km => {
      if (!knownUsers[km.user.id] && km.user.id !== loggedInUser.id) {
        knownUsers[km.user.id] = { user: km.user, communities: [] };
      }
      knownUsers[km.user.id].communities.push(km.community.id);
    });

    const relatedUsers: RelatedUser[] = [];
    Object.values(knownUsers).forEach(ku => {
      const user = ku.user;
      const communityIds = ku.communities;
      const related: string[] = [];
      communityIds.forEach(id => {
        if (communityDictionary[id]) {
          related.push(communityDictionary[id].name); // should be SUBJECT
        }
      })
      relatedUsers.push({user: user, commonUsers: related});
    });

    return relatedUsers;
  }
}