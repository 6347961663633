/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPacemakerLastRun = /* GraphQL */ `
  query GetPacemakerLastRun($id: ID!) {
    getPacemakerLastRun(id: $id) {
      id
      entries {
        job
        timestamp
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPacemakerLastRuns = /* GraphQL */ `
  query ListPacemakerLastRuns(
    $filter: ModelPacemakerLastRunFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPacemakerLastRuns(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        entries {
          job
          timestamp
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZAnswerCreatedNotifySig = /* GraphQL */ `
  query GetZAnswerCreatedNotifySig($id: ID!, $createdAt: AWSDateTime!) {
    getZAnswerCreatedNotifySig(id: $id, createdAt: $createdAt) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const listZAnswerCreatedNotifySigs = /* GraphQL */ `
  query ListZAnswerCreatedNotifySigs(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelzAnswerCreatedNotifySigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZAnswerCreatedNotifySigs(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZAnswerCreatedNotifyBacklog = /* GraphQL */ `
  query GetZAnswerCreatedNotifyBacklog($communityQuestionAnswerID: String!) {
    getZAnswerCreatedNotifyBacklog(
      communityQuestionAnswerID: $communityQuestionAnswerID
    ) {
      communityID
      communityQuestionAnswerID
      communityQuestionAnswerCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const listZAnswerCreatedNotifyBacklogs = /* GraphQL */ `
  query ListZAnswerCreatedNotifyBacklogs(
    $communityQuestionAnswerID: String
    $filter: ModelzAnswerCreatedNotifyBacklogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZAnswerCreatedNotifyBacklogs(
      communityQuestionAnswerID: $communityQuestionAnswerID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        communityID
        communityQuestionAnswerID
        communityQuestionAnswerCreatedAt
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZAnswerCreatedNotifyComplete = /* GraphQL */ `
  query GetZAnswerCreatedNotifyComplete($communityQuestionAnswerID: String!) {
    getZAnswerCreatedNotifyComplete(
      communityQuestionAnswerID: $communityQuestionAnswerID
    ) {
      communityID
      communityQuestionAnswerID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const listZAnswerCreatedNotifyCompletes = /* GraphQL */ `
  query ListZAnswerCreatedNotifyCompletes(
    $communityQuestionAnswerID: String
    $filter: ModelzAnswerCreatedNotifyCompleteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZAnswerCreatedNotifyCompletes(
      communityQuestionAnswerID: $communityQuestionAnswerID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        communityID
        communityQuestionAnswerID
        userID
        createdAt
        completedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZCommentMadeNotifySig = /* GraphQL */ `
  query GetZCommentMadeNotifySig($id: ID!, $createdAt: AWSDateTime!) {
    getZCommentMadeNotifySig(id: $id, createdAt: $createdAt) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const listZCommentMadeNotifySigs = /* GraphQL */ `
  query ListZCommentMadeNotifySigs(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelzCommentMadeNotifySigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZCommentMadeNotifySigs(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZCommentMadeNotifyBacklog = /* GraphQL */ `
  query GetZCommentMadeNotifyBacklog($communityID: String!) {
    getZCommentMadeNotifyBacklog(communityID: $communityID) {
      communityID
      communityQuestionAnswerID
      communityQuestionAnswerCommentCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const listZCommentMadeNotifyBacklogs = /* GraphQL */ `
  query ListZCommentMadeNotifyBacklogs(
    $communityID: String
    $filter: ModelzCommentMadeNotifyBacklogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZCommentMadeNotifyBacklogs(
      communityID: $communityID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        communityID
        communityQuestionAnswerID
        communityQuestionAnswerCommentCreatedAt
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZCommentMadeNotifyComplete = /* GraphQL */ `
  query GetZCommentMadeNotifyComplete($communityID: String!) {
    getZCommentMadeNotifyComplete(communityID: $communityID) {
      communityID
      communityQuestionAnswerID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const listZCommentMadeNotifyCompletes = /* GraphQL */ `
  query ListZCommentMadeNotifyCompletes(
    $communityID: String
    $filter: ModelzCommentMadeNotifyCompleteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZCommentMadeNotifyCompletes(
      communityID: $communityID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        communityID
        communityQuestionAnswerID
        userID
        createdAt
        completedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZCommunityInvitationReminderNotifySig = /* GraphQL */ `
  query GetZCommunityInvitationReminderNotifySig(
    $id: ID!
    $createdAt: AWSDateTime!
  ) {
    getZCommunityInvitationReminderNotifySig(id: $id, createdAt: $createdAt) {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const listZCommunityInvitationReminderNotifySigs = /* GraphQL */ `
  query ListZCommunityInvitationReminderNotifySigs(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelzCommunityInvitationReminderNotifySigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZCommunityInvitationReminderNotifySigs(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        source
        createdAt
        signal {
          payload
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZCommunityInvitationReminderNotifyBacklog = /* GraphQL */ `
  query GetZCommunityInvitationReminderNotifyBacklog(
    $communityUserID: String!
  ) {
    getZCommunityInvitationReminderNotifyBacklog(
      communityUserID: $communityUserID
    ) {
      communityUserID
      userID
      communityID
      status
      invitedByUserID
      invitedOn
      createdAt
      updatedAt
    }
  }
`;
export const listZCommunityInvitationReminderNotifyBacklogs = /* GraphQL */ `
  query ListZCommunityInvitationReminderNotifyBacklogs(
    $communityUserID: String
    $filter: ModelzCommunityInvitationReminderNotifyBacklogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZCommunityInvitationReminderNotifyBacklogs(
      communityUserID: $communityUserID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        communityUserID
        userID
        communityID
        status
        invitedByUserID
        invitedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZCommunityInvitationReminderNotifyComplete = /* GraphQL */ `
  query GetZCommunityInvitationReminderNotifyComplete(
    $communityUserID: String!
  ) {
    getZCommunityInvitationReminderNotifyComplete(
      communityUserID: $communityUserID
    ) {
      communityUserID
      userID
      communityID
      status
      invitedByUserID
      invitedOn
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const listZCommunityInvitationReminderNotifyCompletes = /* GraphQL */ `
  query ListZCommunityInvitationReminderNotifyCompletes(
    $communityUserID: String
    $filter: ModelzCommunityInvitationReminderNotifyCompleteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZCommunityInvitationReminderNotifyCompletes(
      communityUserID: $communityUserID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        communityUserID
        userID
        communityID
        status
        invitedByUserID
        invitedOn
        createdAt
        completedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZCommunityNextStepCalculateSig = /* GraphQL */ `
  query GetZCommunityNextStepCalculateSig($id: ID!, $createdAt: AWSDateTime!) {
    getZCommunityNextStepCalculateSig(id: $id, createdAt: $createdAt) {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const listZCommunityNextStepCalculateSigs = /* GraphQL */ `
  query ListZCommunityNextStepCalculateSigs(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelzCommunityNextStepCalculateSigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZCommunityNextStepCalculateSigs(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        source
        createdAt
        signal {
          payload
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZCommunityNextStepCalculateBacklog = /* GraphQL */ `
  query GetZCommunityNextStepCalculateBacklog($id: ID!) {
    getZCommunityNextStepCalculateBacklog(id: $id) {
      id
      communityID
      nextStepType
      createdAt
      updatedAt
    }
  }
`;
export const listZCommunityNextStepCalculateBacklogs = /* GraphQL */ `
  query ListZCommunityNextStepCalculateBacklogs(
    $filter: ModelzCommunityNextStepCalculateBacklogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZCommunityNextStepCalculateBacklogs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityID
        nextStepType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZCommunityNextStepCalculateComplete = /* GraphQL */ `
  query GetZCommunityNextStepCalculateComplete($id: ID!) {
    getZCommunityNextStepCalculateComplete(id: $id) {
      id
      communityID
      nextStepType
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const listZCommunityNextStepCalculateCompletes = /* GraphQL */ `
  query ListZCommunityNextStepCalculateCompletes(
    $filter: ModelzCommunityNextStepCalculateCompleteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZCommunityNextStepCalculateCompletes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityID
        nextStepType
        createdAt
        completedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZFirstCommunityQuestionNotifySig = /* GraphQL */ `
  query GetZFirstCommunityQuestionNotifySig(
    $id: ID!
    $createdAt: AWSDateTime!
  ) {
    getZFirstCommunityQuestionNotifySig(id: $id, createdAt: $createdAt) {
      id
      source
      createdAt
      signal {
        payload
      }
      updatedAt
    }
  }
`;
export const listZFirstCommunityQuestionNotifySigs = /* GraphQL */ `
  query ListZFirstCommunityQuestionNotifySigs(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelzFirstCommunityQuestionNotifySigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZFirstCommunityQuestionNotifySigs(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        source
        createdAt
        signal {
          payload
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZFirstCommunityQuestionNotifyBacklog = /* GraphQL */ `
  query GetZFirstCommunityQuestionNotifyBacklog($communityID: String!) {
    getZFirstCommunityQuestionNotifyBacklog(communityID: $communityID) {
      communityID
      communityCreatedAt
      communityQuestionID
      userID
      createdAt
      updatedAt
    }
  }
`;
export const listZFirstCommunityQuestionNotifyBacklogs = /* GraphQL */ `
  query ListZFirstCommunityQuestionNotifyBacklogs(
    $communityID: String
    $filter: ModelzFirstCommunityQuestionNotifyBacklogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZFirstCommunityQuestionNotifyBacklogs(
      communityID: $communityID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        communityID
        communityCreatedAt
        communityQuestionID
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZFirstCommunityQuestionNotifyComplete = /* GraphQL */ `
  query GetZFirstCommunityQuestionNotifyComplete($communityID: String!) {
    getZFirstCommunityQuestionNotifyComplete(communityID: $communityID) {
      communityID
      communityCreatedAt
      communityQuestionID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const listZFirstCommunityQuestionNotifyCompletes = /* GraphQL */ `
  query ListZFirstCommunityQuestionNotifyCompletes(
    $communityID: String
    $filter: ModelzFirstCommunityQuestionNotifyCompleteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZFirstCommunityQuestionNotifyCompletes(
      communityID: $communityID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        communityID
        communityCreatedAt
        communityQuestionID
        userID
        createdAt
        completedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZQuestionAskedNotifySig = /* GraphQL */ `
  query GetZQuestionAskedNotifySig($id: ID!, $createdAt: AWSDateTime!) {
    getZQuestionAskedNotifySig(id: $id, createdAt: $createdAt) {
      id
      createdAt
      updatedAt
    }
  }
`;
export const listZQuestionAskedNotifySigs = /* GraphQL */ `
  query ListZQuestionAskedNotifySigs(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelzQuestionAskedNotifySigFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZQuestionAskedNotifySigs(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZQuestionAskedNotifyBacklog = /* GraphQL */ `
  query GetZQuestionAskedNotifyBacklog($communityID: String!) {
    getZQuestionAskedNotifyBacklog(communityID: $communityID) {
      communityID
      communityQuestionID
      communityQuestionCreatedAt
      userID
      createdAt
      updatedAt
    }
  }
`;
export const listZQuestionAskedNotifyBacklogs = /* GraphQL */ `
  query ListZQuestionAskedNotifyBacklogs(
    $communityID: String
    $filter: ModelzQuestionAskedNotifyBacklogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZQuestionAskedNotifyBacklogs(
      communityID: $communityID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        communityID
        communityQuestionID
        communityQuestionCreatedAt
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getZQuestionAskedNotifyComplete = /* GraphQL */ `
  query GetZQuestionAskedNotifyComplete($communityID: String!) {
    getZQuestionAskedNotifyComplete(communityID: $communityID) {
      communityID
      communityQuestionID
      userID
      createdAt
      completedAt
      updatedAt
    }
  }
`;
export const listZQuestionAskedNotifyCompletes = /* GraphQL */ `
  query ListZQuestionAskedNotifyCompletes(
    $communityID: String
    $filter: ModelzQuestionAskedNotifyCompleteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listZQuestionAskedNotifyCompletes(
      communityID: $communityID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        communityID
        communityQuestionID
        userID
        createdAt
        completedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const nextStepsBacklogByCommunity = /* GraphQL */ `
  query NextStepsBacklogByCommunity(
    $communityID: String
    $sortDirection: ModelSortDirection
    $filter: ModelzCommunityNextStepCalculateBacklogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nextStepsBacklogByCommunity(
      communityID: $communityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityID
        nextStepType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const nextStepsBacklogByCommunityAndNextStepType = /* GraphQL */ `
  query NextStepsBacklogByCommunityAndNextStepType(
    $communityID: String
    $nextStepType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelzCommunityNextStepCalculateBacklogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nextStepsBacklogByCommunityAndNextStepType(
      communityID: $communityID
      nextStepType: $nextStepType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityID
        nextStepType
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const nextStepsCompletedByCommunity = /* GraphQL */ `
  query NextStepsCompletedByCommunity(
    $communityID: String
    $sortDirection: ModelSortDirection
    $filter: ModelzCommunityNextStepCalculateCompleteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nextStepsCompletedByCommunity(
      communityID: $communityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityID
        nextStepType
        createdAt
        completedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const nextStepsCompleteByCommunityAndNextStepType = /* GraphQL */ `
  query NextStepsCompleteByCommunityAndNextStepType(
    $communityID: String
    $nextStepType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelzCommunityNextStepCalculateCompleteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    nextStepsCompleteByCommunityAndNextStepType(
      communityID: $communityID
      nextStepType: $nextStepType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityID
        nextStepType
        createdAt
        completedAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      name
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCategories = /* GraphQL */ `
  query ListCategories(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const categoriesByName = /* GraphQL */ `
  query CategoriesByName(
    $name: String
    $sortDirection: ModelSortDirection
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    categoriesByName(
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCommunityCommand = /* GraphQL */ `
  query GetCommunityCommand($id: ID!) {
    getCommunityCommand(id: $id) {
      id
      command {
        operation
      }
      communityID
      name
      imageKey
      type
      allowPublicPages
      limits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      vimeoVideos
      communityCategories
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCommunityCommands = /* GraphQL */ `
  query ListCommunityCommands(
    $filter: ModelCommunityCommandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityCommands(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        command {
          operation
        }
        communityID
        name
        imageKey
        type
        allowPublicPages
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCommunity = /* GraphQL */ `
  query GetCommunity($id: ID!) {
    getCommunity(id: $id) {
      id
      name
      imageKey
      type
      allowPublicPages
      alias
      publicPreviewHeaderURL
      publicPreviewDescription
      requiresModeration
      allowUnauthenticatedAnswers
      limits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      vimeoVideos
      communityCategories
      interviewTab {
        header
        bodyLine1
        bodyLine2
      }
      createdAt
      updatedAt
      owner
      communityQuestionsByStatusDate {
        items {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionRecommendations {
        items {
          id
          communityID
          categoryID
          questionText
          score
          status
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
        }
        nextToken
      }
      communityUsersByStatusDate {
        items {
          id
          communityID
          userID
          joinedOn
          invitedByUserID
          invitedOn
          invitation {
            invitationMessage
          }
          status
          relationshipToSpotlight
          owner
          group
          createdAt
          updatedAt
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
          invitedByUser {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const listCommunities = /* GraphQL */ `
  query ListCommunities(
    $filter: ModelCommunityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const communityByAlias = /* GraphQL */ `
  query CommunityByAlias(
    $alias: String
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    communityByAlias(
      alias: $alias
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getCommunityInterviewRequestCommand = /* GraphQL */ `
  query GetCommunityInterviewRequestCommand($id: ID!) {
    getCommunityInterviewRequestCommand(id: $id) {
      id
      command {
        operation
      }
      communityID
      userID
      date
      status
      message {
        subject
        body
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCommunityInterviewRequestCommands = /* GraphQL */ `
  query ListCommunityInterviewRequestCommands(
    $filter: ModelCommunityInterviewRequestCommandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityInterviewRequestCommands(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        command {
          operation
        }
        communityID
        userID
        date
        status
        message {
          subject
          body
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCommunityInterviewRequest = /* GraphQL */ `
  query GetCommunityInterviewRequest($id: ID!) {
    getCommunityInterviewRequest(id: $id) {
      id
      communityID
      userID
      date
      status
      message {
        subject
        body
      }
      owner
      createdAt
      updatedAt
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listCommunityInterviewRequests = /* GraphQL */ `
  query ListCommunityInterviewRequests(
    $filter: ModelCommunityInterviewRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityInterviewRequests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityID
        userID
        date
        status
        message {
          subject
          body
        }
        owner
        createdAt
        updatedAt
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const communityInterviewRequestsByCommunity = /* GraphQL */ `
  query CommunityInterviewRequestsByCommunity(
    $communityID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityInterviewRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    communityInterviewRequestsByCommunity(
      communityID: $communityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityID
        userID
        date
        status
        message {
          subject
          body
        }
        owner
        createdAt
        updatedAt
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getCommunityQuestion = /* GraphQL */ `
  query GetCommunityQuestion($id: ID!) {
    getCommunityQuestion(id: $id) {
      id
      communityID
      userID
      categoryID
      communityCategory
      date
      status
      source
      questionText
      imageKeys
      clonedFromCommunityQuestionRecommendationId
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
        owner
      }
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      owner
      communityQuestionAnswersByStatusDate {
        items {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionLikesByStatus {
        items {
          id
          userID
          communityQuestionID
          status
          date
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listCommunityQuestions = /* GraphQL */ `
  query ListCommunityQuestions(
    $filter: ModelCommunityQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityQuestions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const questionsByUser = /* GraphQL */ `
  query QuestionsByUser(
    $userID: ID
    $statusDate: ModelCommunityQuestionByUserByStatusByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByUser(
      userID: $userID
      statusDate: $statusDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const questionsByCommunity = /* GraphQL */ `
  query QuestionsByCommunity(
    $communityID: ID
    $statusDate: ModelCommunityQuestionByCommunityByStatusByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    questionsByCommunity(
      communityID: $communityID
      statusDate: $statusDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getCommunityQuestionAnswerCommand = /* GraphQL */ `
  query GetCommunityQuestionAnswerCommand($id: ID!) {
    getCommunityQuestionAnswerCommand(id: $id) {
      id
      command {
        operation
      }
      communityQuestionAnswerID
      communityQuestionID
      communityID
      userID
      status
      date
      content
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCommunityQuestionAnswerCommands = /* GraphQL */ `
  query ListCommunityQuestionAnswerCommands(
    $filter: ModelCommunityQuestionAnswerCommandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityQuestionAnswerCommands(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        command {
          operation
        }
        communityQuestionAnswerID
        communityQuestionID
        communityID
        userID
        status
        date
        content
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCommunityQuestionAnswer = /* GraphQL */ `
  query GetCommunityQuestionAnswer($id: ID!) {
    getCommunityQuestionAnswer(id: $id) {
      id
      communityQuestionID
      userID
      communityID
      status
      date
      content
      createdAt
      updatedAt
      communityQuestion {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      communityQuestionAnswerCommentsByStatusDate {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          content
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionAnswerLikesByStatus {
        items {
          id
          userID
          communityID
          communityQuestionAnswerID
          status
          date
          createdAt
          updatedAt
          communityQuestionAnswer {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          owner
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listCommunityQuestionAnswers = /* GraphQL */ `
  query ListCommunityQuestionAnswers(
    $filter: ModelCommunityQuestionAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityQuestionAnswers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const answersByQuestion = /* GraphQL */ `
  query AnswersByQuestion(
    $communityQuestionID: ID
    $statusDate: ModelCommunityQuestionAnswerByCommunityQuestionByStatusByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityQuestionAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByQuestion(
      communityQuestionID: $communityQuestionID
      statusDate: $statusDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const answersByUser = /* GraphQL */ `
  query AnswersByUser(
    $userID: ID
    $statusDate: ModelCommunityQuestionAnswerByUserByStatusByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityQuestionAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByUser(
      userID: $userID
      statusDate: $statusDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const answersByCommunity = /* GraphQL */ `
  query AnswersByCommunity(
    $communityID: ID
    $statusDate: ModelCommunityQuestionAnswerByCommunityByStatusByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityQuestionAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    answersByCommunity(
      communityID: $communityID
      statusDate: $statusDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getCommunityQuestionAnswerCommentCommand = /* GraphQL */ `
  query GetCommunityQuestionAnswerCommentCommand($id: ID!) {
    getCommunityQuestionAnswerCommentCommand(id: $id) {
      id
      command {
        operation
      }
      communityQuestionAnswerCommentID
      userID
      communityID
      communityQuestionAnswerID
      content
      status
      date
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCommunityQuestionAnswerCommentCommands = /* GraphQL */ `
  query ListCommunityQuestionAnswerCommentCommands(
    $filter: ModelCommunityQuestionAnswerCommentCommandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityQuestionAnswerCommentCommands(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        command {
          operation
        }
        communityQuestionAnswerCommentID
        userID
        communityID
        communityQuestionAnswerID
        content
        status
        date
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCommunityQuestionAnswerComment = /* GraphQL */ `
  query GetCommunityQuestionAnswerComment($id: ID!) {
    getCommunityQuestionAnswerComment(id: $id) {
      id
      userID
      communityID
      communityQuestionAnswerID
      content
      status
      date
      createdAt
      updatedAt
      communityQuestionAnswer {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listCommunityQuestionAnswerComments = /* GraphQL */ `
  query ListCommunityQuestionAnswerComments(
    $filter: ModelCommunityQuestionAnswerCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityQuestionAnswerComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        communityID
        communityQuestionAnswerID
        content
        status
        date
        createdAt
        updatedAt
        communityQuestionAnswer {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const commentsByAnswer = /* GraphQL */ `
  query CommentsByAnswer(
    $communityQuestionAnswerID: ID
    $statusId: ModelCommunityQuestionAnswerCommentByCommunityQuestionAnswerByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityQuestionAnswerCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByAnswer(
      communityQuestionAnswerID: $communityQuestionAnswerID
      statusId: $statusId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        communityID
        communityQuestionAnswerID
        content
        status
        date
        createdAt
        updatedAt
        communityQuestionAnswer {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const commentsByUser = /* GraphQL */ `
  query CommentsByUser(
    $userID: ID
    $statusDate: ModelCommunityQuestionAnswerCommentByUserByStatusByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityQuestionAnswerCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByUser(
      userID: $userID
      statusDate: $statusDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        communityID
        communityQuestionAnswerID
        content
        status
        date
        createdAt
        updatedAt
        communityQuestionAnswer {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const commentsByCommunity = /* GraphQL */ `
  query CommentsByCommunity(
    $communityID: ID
    $statusDate: ModelCommunityQuestionAnswerCommentByCommunityByStatusByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityQuestionAnswerCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    commentsByCommunity(
      communityID: $communityID
      statusDate: $statusDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        communityID
        communityQuestionAnswerID
        content
        status
        date
        createdAt
        updatedAt
        communityQuestionAnswer {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getCommunityQuestionAnswerLike = /* GraphQL */ `
  query GetCommunityQuestionAnswerLike($id: ID!) {
    getCommunityQuestionAnswerLike(id: $id) {
      id
      userID
      communityID
      communityQuestionAnswerID
      status
      date
      createdAt
      updatedAt
      communityQuestionAnswer {
        id
        communityQuestionID
        userID
        communityID
        status
        date
        content
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        communityQuestionAnswerCommentsByStatusDate {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            content
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswerLikesByStatus {
          items {
            id
            userID
            communityID
            communityQuestionAnswerID
            status
            date
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      owner
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listCommunityQuestionAnswerLikes = /* GraphQL */ `
  query ListCommunityQuestionAnswerLikes(
    $filter: ModelCommunityQuestionAnswerLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityQuestionAnswerLikes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        communityID
        communityQuestionAnswerID
        status
        date
        createdAt
        updatedAt
        communityQuestionAnswer {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const likesByAnswer = /* GraphQL */ `
  query LikesByAnswer(
    $communityQuestionAnswerID: ID
    $statusId: ModelCommunityQuestionAnswerLikeByCommunityQuestionAnswerByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityQuestionAnswerLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByAnswer(
      communityQuestionAnswerID: $communityQuestionAnswerID
      statusId: $statusId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        communityID
        communityQuestionAnswerID
        status
        date
        createdAt
        updatedAt
        communityQuestionAnswer {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getCommunityQuestionLike = /* GraphQL */ `
  query GetCommunityQuestionLike($id: ID!) {
    getCommunityQuestionLike(id: $id) {
      id
      userID
      communityQuestionID
      status
      date
      createdAt
      updatedAt
      communityQuestion {
        id
        communityID
        userID
        categoryID
        communityCategory
        date
        status
        source
        questionText
        imageKeys
        clonedFromCommunityQuestionRecommendationId
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
        communityQuestionAnswersByStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionLikesByStatus {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listCommunityQuestionLikes = /* GraphQL */ `
  query ListCommunityQuestionLikes(
    $filter: ModelCommunityQuestionLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityQuestionLikes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        communityQuestionID
        status
        date
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const likesByQuestion = /* GraphQL */ `
  query LikesByQuestion(
    $communityQuestionID: ID
    $statusId: ModelCommunityQuestionLikeByCommunityQuestionByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityQuestionLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByQuestion(
      communityQuestionID: $communityQuestionID
      statusId: $statusId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        communityQuestionID
        status
        date
        createdAt
        updatedAt
        communityQuestion {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const likesByUser = /* GraphQL */ `
  query LikesByUser(
    $userID: ID
    $statusDate: ModelCommunityQuestionAnswerLikeByUserByStatusByDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityQuestionAnswerLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    likesByUser(
      userID: $userID
      statusDate: $statusDate
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        communityID
        communityQuestionAnswerID
        status
        date
        createdAt
        updatedAt
        communityQuestionAnswer {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        owner
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getCommunityQuestionRecommendation = /* GraphQL */ `
  query GetCommunityQuestionRecommendation($id: ID!) {
    getCommunityQuestionRecommendation(id: $id) {
      id
      communityID
      categoryID
      questionText
      score
      status
      createdAt
      updatedAt
      category {
        id
        name
        createdAt
        updatedAt
        owner
      }
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      owner
    }
  }
`;
export const listCommunityQuestionRecommendations = /* GraphQL */ `
  query ListCommunityQuestionRecommendations(
    $filter: ModelCommunityQuestionRecommendationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityQuestionRecommendations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityID
        categoryID
        questionText
        score
        status
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const recommendationsByCommunity = /* GraphQL */ `
  query RecommendationsByCommunity(
    $communityID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityQuestionRecommendationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    recommendationsByCommunity(
      communityID: $communityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityID
        categoryID
        questionText
        score
        status
        createdAt
        updatedAt
        category {
          id
          name
          createdAt
          updatedAt
          owner
        }
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        owner
      }
      nextToken
    }
  }
`;
export const getCommunityUserCommand = /* GraphQL */ `
  query GetCommunityUserCommand($id: ID!) {
    getCommunityUserCommand(id: $id) {
      id
      command {
        operation
      }
      communityUserId
      communityID
      userID
      joinedOn
      invitedByUserID
      invitedOn
      invitation {
        invitationMessage
      }
      status
      relationshipToSpotlight
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listCommunityUserCommands = /* GraphQL */ `
  query ListCommunityUserCommands(
    $filter: ModelCommunityUserCommandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityUserCommands(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        command {
          operation
        }
        communityUserId
        communityID
        userID
        joinedOn
        invitedByUserID
        invitedOn
        invitation {
          invitationMessage
        }
        status
        relationshipToSpotlight
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getCommunityUser = /* GraphQL */ `
  query GetCommunityUser($id: ID!) {
    getCommunityUser(id: $id) {
      id
      communityID
      userID
      joinedOn
      invitedByUserID
      invitedOn
      invitation {
        invitationMessage
      }
      status
      relationshipToSpotlight
      owner
      group
      createdAt
      updatedAt
      community {
        id
        name
        imageKey
        type
        allowPublicPages
        alias
        publicPreviewHeaderURL
        publicPreviewDescription
        requiresModeration
        allowUnauthenticatedAnswers
        limits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        vimeoVideos
        communityCategories
        interviewTab {
          header
          bodyLine1
          bodyLine2
        }
        createdAt
        updatedAt
        owner
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionRecommendations {
          items {
            id
            communityID
            categoryID
            questionText
            score
            status
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      user {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      invitedByUser {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const listCommunityUsers = /* GraphQL */ `
  query ListCommunityUsers(
    $filter: ModelCommunityUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommunityUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        communityID
        userID
        joinedOn
        invitedByUserID
        invitedOn
        invitation {
          invitationMessage
        }
        status
        relationshipToSpotlight
        owner
        group
        createdAt
        updatedAt
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        invitedByUser {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const communitiesByUser = /* GraphQL */ `
  query CommunitiesByUser(
    $userID: ID
    $statusJoinedOn: ModelCommunityUserByUserByStatusByJoinDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    communitiesByUser(
      userID: $userID
      statusJoinedOn: $statusJoinedOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityID
        userID
        joinedOn
        invitedByUserID
        invitedOn
        invitation {
          invitationMessage
        }
        status
        relationshipToSpotlight
        owner
        group
        createdAt
        updatedAt
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        invitedByUser {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const usersByCommunity = /* GraphQL */ `
  query UsersByCommunity(
    $communityID: ID
    $statusJoinedOn: ModelCommunityUserByCommunityByStatusByJoinDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCommunityUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByCommunity(
      communityID: $communityID
      statusJoinedOn: $statusJoinedOn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        communityID
        userID
        joinedOn
        invitedByUserID
        invitedOn
        invitation {
          invitationMessage
        }
        status
        relationshipToSpotlight
        owner
        group
        createdAt
        updatedAt
        community {
          id
          name
          imageKey
          type
          allowPublicPages
          alias
          publicPreviewHeaderURL
          publicPreviewDescription
          requiresModeration
          allowUnauthenticatedAnswers
          limits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          vimeoVideos
          communityCategories
          interviewTab {
            header
            bodyLine1
            bodyLine2
          }
          createdAt
          updatedAt
          owner
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionRecommendations {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        user {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
        invitedByUser {
          id
          firstName
          lastName
          email
          status
          profileImageKey
          communityLimits {
            maxSubjects
            maxMembers
            maxInvitationLength
            maxVideoDurationSeconds
          }
          welcomesSeen
          helpsSeen
          nextSteps {
            communityID
          }
          referralCode
          referredByUserID
          username
          identityID
          createdAt
          updatedAt
          communityQuestionsByStatusDate {
            nextToken
          }
          communityQuestionAnswersByUserStatusDate {
            nextToken
          }
          communityQuestionlikesByStatusDate {
            nextToken
          }
          communityUsersByStatusDate {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const getInvitation = /* GraphQL */ `
  query GetInvitation($id: ID!) {
    getInvitation(id: $id) {
      id
      email
      toNotify
      status
      createdAt
      updatedAt
    }
  }
`;
export const listInvitations = /* GraphQL */ `
  query ListInvitations(
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        email
        toNotify
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const invitationsByEmailByStatus = /* GraphQL */ `
  query InvitationsByEmailByStatus(
    $email: AWSEmail
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    invitationsByEmailByStatus(
      email: $email
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        toNotify
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUsernameToUserId = /* GraphQL */ `
  query GetUsernameToUserId($username: String!) {
    getUsernameToUserId(username: $username) {
      username
      userID
    }
  }
`;
export const listUsernameToUserIds = /* GraphQL */ `
  query ListUsernameToUserIds(
    $username: String
    $filter: ModelUsernameToUserIdFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsernameToUserIds(
      username: $username
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        username
        userID
      }
      nextToken
    }
  }
`;
export const getUsernameByUserId = /* GraphQL */ `
  query GetUsernameByUserId(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelUsernameToUserIdFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUsernameByUserId(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        username
        userID
      }
      nextToken
    }
  }
`;
export const getUserIdToIdentityId = /* GraphQL */ `
  query GetUserIdToIdentityId($userID: ID!) {
    getUserIdToIdentityId(userID: $userID) {
      userID
      identityID
      createdAt
      updatedAt
    }
  }
`;
export const listUserIdToIdentityIds = /* GraphQL */ `
  query ListUserIdToIdentityIds(
    $userID: ID
    $filter: ModelUserIdToIdentityIdFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserIdToIdentityIds(
      userID: $userID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        userID
        identityID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserCommand = /* GraphQL */ `
  query GetUserCommand($id: ID!) {
    getUserCommand(id: $id) {
      id
      command {
        operation
      }
      userID
      firstName
      lastName
      email
      status
      profileImageKey
      communityLimits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      welcomesSeen
      helpsSeen
      username
      identityID
      referralCode
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUserCommands = /* GraphQL */ `
  query ListUserCommands(
    $filter: ModelUserCommandFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserCommands(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        command {
          operation
        }
        userID
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        username
        identityID
        referralCode
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      status
      profileImageKey
      communityLimits {
        maxSubjects
        maxMembers
        maxInvitationLength
        maxVideoDurationSeconds
      }
      welcomesSeen
      helpsSeen
      nextSteps {
        communityID
        nextStepsByRole {
          INVITED
          MEMBER
          ORGANIZER
          SUBJECT
          REMOVED
          BANNED
          ADMIN
        }
      }
      referralCode
      referredByUserID
      username
      identityID
      createdAt
      updatedAt
      communityQuestionsByStatusDate {
        items {
          id
          communityID
          userID
          categoryID
          communityCategory
          date
          status
          source
          questionText
          imageKeys
          clonedFromCommunityQuestionRecommendationId
          createdAt
          updatedAt
          category {
            id
            name
            createdAt
            updatedAt
            owner
          }
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswersByStatusDate {
            nextToken
          }
          communityQuestionLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionAnswersByUserStatusDate {
        items {
          id
          communityQuestionID
          userID
          communityID
          status
          date
          content
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          owner
          communityQuestionAnswerCommentsByStatusDate {
            nextToken
          }
          communityQuestionAnswerLikesByStatus {
            nextToken
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityQuestionlikesByStatusDate {
        items {
          id
          userID
          communityQuestionID
          status
          date
          createdAt
          updatedAt
          communityQuestion {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      communityUsersByStatusDate {
        items {
          id
          communityID
          userID
          joinedOn
          invitedByUserID
          invitedOn
          invitation {
            invitationMessage
          }
          status
          relationshipToSpotlight
          owner
          group
          createdAt
          updatedAt
          community {
            id
            name
            imageKey
            type
            allowPublicPages
            alias
            publicPreviewHeaderURL
            publicPreviewDescription
            requiresModeration
            allowUnauthenticatedAnswers
            vimeoVideos
            communityCategories
            createdAt
            updatedAt
            owner
          }
          user {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
          invitedByUser {
            id
            firstName
            lastName
            email
            status
            profileImageKey
            welcomesSeen
            helpsSeen
            referralCode
            referredByUserID
            username
            identityID
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const usersByEmail = /* GraphQL */ `
  query UsersByEmail(
    $email: AWSEmail
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const usersByUsername = /* GraphQL */ `
  query UsersByUsername(
    $username: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByUsername(
      username: $username
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const usersByIdentityId = /* GraphQL */ `
  query UsersByIdentityId(
    $identityID: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByIdentityId(
      identityID: $identityID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const usersByReferralCode = /* GraphQL */ `
  query UsersByReferralCode(
    $referralCode: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usersByReferralCode(
      referralCode: $referralCode
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstName
        lastName
        email
        status
        profileImageKey
        communityLimits {
          maxSubjects
          maxMembers
          maxInvitationLength
          maxVideoDurationSeconds
        }
        welcomesSeen
        helpsSeen
        nextSteps {
          communityID
          nextStepsByRole {
            INVITED
            MEMBER
            ORGANIZER
            SUBJECT
            REMOVED
            BANNED
            ADMIN
          }
        }
        referralCode
        referredByUserID
        username
        identityID
        createdAt
        updatedAt
        communityQuestionsByStatusDate {
          items {
            id
            communityID
            userID
            categoryID
            communityCategory
            date
            status
            source
            questionText
            imageKeys
            clonedFromCommunityQuestionRecommendationId
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionAnswersByUserStatusDate {
          items {
            id
            communityQuestionID
            userID
            communityID
            status
            date
            content
            createdAt
            updatedAt
            owner
          }
          nextToken
        }
        communityQuestionlikesByStatusDate {
          items {
            id
            userID
            communityQuestionID
            status
            date
            createdAt
            updatedAt
          }
          nextToken
        }
        communityUsersByStatusDate {
          items {
            id
            communityID
            userID
            joinedOn
            invitedByUserID
            invitedOn
            status
            relationshipToSpotlight
            owner
            group
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getUserGoogleTokens = /* GraphQL */ `
  query GetUserGoogleTokens($owner: ID!) {
    getUserGoogleTokens(owner: $owner) {
      owner
      accessToken
      refreshToken
      scopes
      expiresOn
      createdAt
      updatedAt
    }
  }
`;
export const listUserGoogleTokens = /* GraphQL */ `
  query ListUserGoogleTokens(
    $owner: ID
    $filter: ModelUserGoogleTokensFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserGoogleTokens(
      owner: $owner
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        owner
        accessToken
        refreshToken
        scopes
        expiresOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserReferralCode = /* GraphQL */ `
  query GetUserReferralCode($referralCode: String!) {
    getUserReferralCode(referralCode: $referralCode) {
      referralCode
      firstName
      createdAt
      updatedAt
    }
  }
`;
export const listUserReferralCodes = /* GraphQL */ `
  query ListUserReferralCodes(
    $referralCode: String
    $filter: ModelUserReferralCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUserReferralCodes(
      referralCode: $referralCode
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        referralCode
        firstName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getXAssignEmailToPlaceholder = /* GraphQL */ `
  query GetXAssignEmailToPlaceholder($id: ID!) {
    getXAssignEmailToPlaceholder(id: $id) {
      id
      status
      reason
      communityUserID
      userID
      communityID
      email
      invitation {
        invitationMessage
      }
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listXAssignEmailToPlaceholders = /* GraphQL */ `
  query ListXAssignEmailToPlaceholders(
    $filter: ModelxAssignEmailToPlaceholderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listXAssignEmailToPlaceholders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        reason
        communityUserID
        userID
        communityID
        email
        invitation {
          invitationMessage
        }
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
