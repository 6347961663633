import React, { useContext, useRef, useState } from 'react';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { KeyboardAvoidingView, Platform, Text, TextInput, View } from 'react-native';
import { Palette } from '../../../Theme';
import NButton from '../../components/NButton';
import { CommunityQuestionAnswer, CommunityQuestionAnswerComment } from '../../../API';
import MemberAvatar from '../../components/MemberAvatar';
import NTextInput from '../../components/NTextInput';
import { subscribeTo } from '../../../business/user/userHelper';
import * as s from '../../../graphql/subscriptions';
import { MAX_WIDTH } from '../../../business/layout/layout';
import log from '../../../business/logging/logger';

type CommentNewProps = {
  answer: CommunityQuestionAnswer
  onSave: (commentText: string) => void
}

function CommentNew(props: CommentNewProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { answer, onSave } = props;
  const [proposedText, setProposedText] = useState('');
  const [savingComment, setSavingComment] = useState(false);
  const commentTextRef = useRef<TextInput>(null);
  const [sub, setSub] = useState();
  const [error, setError] = useState('');
  const [showSave, setShowSave] = useState(false);

  const pressSave = async () => {
    onSave(proposedText);
    setSavingComment(true);
    const backup = proposedText;
    setProposedText('');
    const cmd = await commonContext.services.communityService.saveNewComment(answer.id, proposedText);
    if (sub) {
      sub.unsubscribe();
    }
    const u = subscribeTo<CommunityQuestionAnswerComment>(
      answer.id,
      "communityQuestionAnswerID",
      s.onCreateCommunityQuestionAnswerComment,
      "onCreateCommunityQuestionAnswerComment",
      item => {
        // log.info(item.userID);
        // log.info(commonContext.loggedInUser?.id);
        // log.info(item.content.toLowerCase());
        // log.info(backup.toLowerCase());
        if (item.userID == commonContext.loggedInUser?.id && item.content.toLowerCase() == backup.toLowerCase()) {
          // saving complete
          setError('');
          setSavingComment(false);
        }
      });
    setSub(u);
    setTimeout(() => {
      if (savingComment) {
        setSavingComment(false);
        setProposedText(backup);
        const err = `Unable to comment at this time. Please try again later.`;
        setError(err);
        console.error(err);
      }
    }, 10000);
  }

  return (
    <View
      style={{
        display: 'flex',
        width: '100%',
        maxWidth: MAX_WIDTH,
      }}
    >
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <View>
          <MemberAvatar
            size={43}
            userData={commonContext.loggedInUser}
            ignoreUpdate={true}
            containerStyle={{
              marginRight: 8,
              //backgroundColor: themeFromContext.colors.overlay
            }}
            titleStyle={{
              color: themeFromContext.colors.background
            }}
          />
        </View>
        <View
          style={{
            flex: 1,
          }}
        >
          <NTextInput
            ref={commentTextRef}
            placeholder={`Write a comment...`}
            multiline
            value={proposedText}
            onChangeText={setProposedText}
            maxLength={500}
            hideMaxLength
            //disabled={savingComment}
            focusable={!savingComment}
            testID={`create-comment-input`}
            style={{
              minHeight: 0,
              padding: 0,
              paddingTop: 8,
            }}
            inputStyle={{
              textAlignVertical: 'center',
              borderWidth: 0,
              backgroundColor: Palette.faintgrey,
              borderRadius: 25,
              //paddingVertical: 8,
              //paddingTop: Platform.OS == 'ios' ? 10 : 8,
              paddingHorizontal: 16,
              lineHeight: 16,
            }}
          />
        </View>
        <View>
          <NButton
            t='brightLink'
            buttonStyle={{
              marginHorizontal: 6,
            }}
            titleStyle={{
              fontSize: 14,
            }}
            title={`Save`}
            // loading={savingComment}
            // loadingProps={{
            //   color: Palette.teal,
            // }}
            // loadingStyle={{
            //   backgroundColor: Palette.transparent,
            // }}
            disabled={savingComment}
            disabledStyle={{
              backgroundColor: Palette.transparent,
            }}
            hidden={proposedText.length == 0}
            onPress={pressSave}
          >
          </NButton>
        </View>
      </View>
    </View>
  );
}

export default CommentNew;