import React, { useContext, useEffect, useState } from 'react';
import { Image, View } from 'react-native';
import { Icon, Text } from 'react-native-elements';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import { CommunityTabParamList } from '../../../types/Navigation';
import { Community, CommunityInterviewRequest, CommunityUser, CommunityUserStatus } from '../../../API';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import NTextInput from '../../components/NTextInput';
import log from '../../../business/logging/logger';
import { normalizeHeight } from '../../../business/layout/responseSize';
import ScrollableViewScreen from '../../components/Layouts/ScrollableViewScreen';
import NFAB, { FABPosition } from '../../components/NFAB';
import { IInterviewRequestService } from '../../../services/InterviewRequestService';
import MovieCamera from '../../../../assets/svg/illustrations/MovieCamera';
import { Palette } from '../../../Theme';

export type ScheduleScreenProps = BottomTabScreenProps<CommunityTabParamList, 'Interview'>;

const sort = (irs: IInterviewRequestService, requests: CommunityInterviewRequest[], asc: boolean) => {
  return requests.sort((a, b) => {
    return irs.compareCommunityInterviewRequests(a, b, asc);
  });
}

function RequestInterviewScreen({ route, navigation }: ScheduleScreenProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const { userService, communityService, interviewRequestService } = commonContext.services;
  const themeFromContext = commonContext.theme;
  const [subject, setSubject] = useState(``);
  const [message, setMessage] = useState('');
  const { width, height } = commonContext.dimensions;
  const [submitLoading, setSubmitLoading] = useState(false);
  //const [request, setRequest] = useState<CommunityInterviewRequest>();
  const communityId = route.params?.communityId;
  const [loading, setLoading] = useState(true);
  const isFocused = useIsFocused();
  const [communityRole, setCommunityRole] = useState(CommunityUserStatus.REMOVED);
  const [organizers, setOrganizers] = useState<CommunityUser[]>([]);
  //const [hasVideo, setHasVideo] = useState(false);
  const [community, setCommunity] = useState<Community>();
  //const [loggedInUserId, setLoggedInUserId] = useState('');
  const linkTo = useLinkTo();
  //const userId = route.params.userId;

  // Logic for getting existing CommunityInterviewRequests:
  // 1. on page load, look for all open requests for this community and show the most recent
  // 2. create a subscription for any created requests for this community

  useEffect(() => {
    let mounted = true;
    //setRequest(undefined);
    setLoading(true);

    if (isFocused) {
      commonContext.analytics?.viewPage(route.name, route.params);
      // For some reason moving this out of the parallel promise fixed the fact that the goBack() button didn't work? Some race condition...
      // getLoggedInUser()
      //   .then(loggedInUser => {
      //     log.info('set logged in user')
      //     if (mounted && loggedInUser) {
      //       log.info(`set logged in user: ${loggedInUser.id}`);
      //       setLoggedInUserId(loggedInUser.id);
      //     }
      //   });

      Promise.all([
        //getInterviewRequests(communityId),
        communityService.getLoggedInUserCommunityRole(communityId),
        communityService.getMembers([communityId], [CommunityUserStatus.ORGANIZER, CommunityUserStatus.SUBJECT]),
        communityService.getSingleCommunity(communityId),
      ]).then(results => {
        if (mounted) {
          //const requests = results[0];
          //const found = sort(requests, false).find(r => r.status == CommunityInterviewStatus.REQUESTED);
          //setRequest(found);
          const role = results[0];
          setCommunityRole(role);
          const mems = results[1];
          // this puts organizers before subjects
          setOrganizers(mems.sort((a, b) => { return communityService.compareCommunityUsers(a, b, 'status', true); }));
          const c = results[2];
          // if (community && community.vimeoVideos && community.vimeoVideos.length > 0 && community.vimeoVideos[0].length > 0) {
          //   setHasVideo(true);
          // } else {
          //   setHasVideo(false);
          // }
          if (c) {
            setCommunity(c);
          }
          setLoading(false);
        }
      }).catch(ex => {
        log.error(`failed to get prerequisites for Request Interview Screen`);
        log.error(ex);
      });
    }

    return () => {
      mounted = false;
    };
  }, [communityId, isFocused]);

  const doThing = async () => {
    setSubmitLoading(true);
    try {
      const command = await interviewRequestService.createInterviewRequestCommand(communityId, subject, message);
      //setSubmitLoading(false);
    } catch (ex) {
      log.error(ex);
      setSubmitLoading(false);
    }
  }

  const showForm = !loading && (communityRole === CommunityUserStatus.ORGANIZER || communityRole === CommunityUserStatus.SUBJECT || communityRole === CommunityUserStatus.ADMIN);
  const showMemberMessage = !loading && (communityRole === CommunityUserStatus.MEMBER);

  return (
      <ScrollableViewScreen
        navigation={navigation}
        isFocused={isFocused}
        refreshing={loading}
        topTitle={`Interview`}
        bottomTitle={community ? community.name : '...'}
        //goBack={() => linkTo(`/communities/${commonContext.loggedInUser?.id}/`)}
        goBack={navigation.goBack}
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
        contentContainerStyle={{
          //alignItems: 'flex-start',
          justifyContent: 'flex-start',
          flex: 1,
          height: '100%',
          padding: 12,
        }}
        testID={`request-interview-screen-view`}
        siblings={
          showForm &&
          <NFAB
            icon={
              <Icon
                name='send'
                type='material-community'
                color={Palette.plum}
              />
            }
            loading={submitLoading}
            position={FABPosition.CENTER}
            onPress={doThing}
            disabled={message.length == 0 || subject.length == 0 || submitLoading}
            testID={`slide-up-modal-save-button`}
            buttonStyle={{
              backgroundColor: Palette.white,
              borderColor: Palette.plum,
              borderWidth: 1,
            }}
          />
        }
      >
        {
          showForm && // NO request exists for this community
          <View
            testID={`interview-screen-no-video-request-form-view`}
          >
            <View
              style={{
                marginTop: normalizeHeight(20, height),
                marginBottom: normalizeHeight(10, height),
              }}
            >
              <MovieCamera />
              {/* <Image
                source={require('../../assets/svg/illustrations/MovieCamera.svg')}
                defaultSource={require('../../assets/svg/illustrations/MovieCamera.svg')}
                resizeMethod='scale'
                resizeMode='contain'
                style={{
                  width: '100%',//normalizeHeight(343, height),
                  height: normalizeHeight(100, height),
                  //marginTop: normalizeHeight(20, height),
                  marginBottom: normalizeHeight(30, height),
                }}
                testID={`interview-screen-no-video-request-image`}
              /> */}
              <Text
                style={{
                  ...themeFromContext.textVariants.header,
                  textAlign: 'center',
                }}
                testID={`interview-screen-no-video-request-form-heading`}
              >
                {`Schedule Interview`}
              </Text>
            </View>

            <View>
              <Text
                style={{
                  padding: 10,
                  textAlign: 'center',
                  ...themeFromContext.textVariants.body
                }}
                testID={`interview-screen-no-video-request-form-body`}
              >
                {`When you’re ready to discuss a Novella interview for your Spotlight member(s), you can review the `}
                <Text>
                  <a
                    style={{
                      ...themeFromContext.textVariants.body,
                      fontWeight: 'bold',
                      color: themeFromContext.colors.primary,
                      textDecorationLine: 'none'
                    }}
                    href="https://www.meetnovella.com/pricing/"
                    target="_blank" rel="noreferrer"
                  >
                    {`interview options`}
                  </a>
                </Text>
                {` and use the form below to let us know.`}
              </Text>
            </View>


            <View>
              <Text
                style={{
                  ...themeFromContext.textVariants.formLabel,
                  //color: themeFromContext.colors.primary,
                  textAlign: 'left',
                  paddingLeft: 10,
                }}
              >
                {`Subject:`}
              </Text>
            </View>
            <View>
              <NTextInput
                value={subject}
                onChangeText={setSubject}
                maxLength={100}
                testID={`interview-screen-no-video-request-form-subject-input`}
              />
            </View>

            <View>
              <Text
                style={{
                  ...themeFromContext.textVariants.formLabel,
                  //color: themeFromContext.colors.primary,
                  textAlign: 'left',
                  paddingLeft: 10,
                }}
              >
                {`Your message:`}
              </Text>
            </View>
            <View>
              <NTextInput
                multiline={true}
                maxHeight={200}
                maxLength={500}
                value={message}
                onChangeText={setMessage}
                testID={`interview-screen-no-video-request-form-message-input`}
              />
            </View>

          </View>
        }
        {
          showMemberMessage && // NO request exists for this community
          <View
            testID={`interview-screen-no-video-request-message-view`}
          >
            <View
              style={{
                marginVertical: 20,
              }}
            >
              <Image
                source={require('../../../../assets/request-interview/Record_Interview_Warming_75_Opacity.png')}
                defaultSource={require('../../../../assets/request-interview/Record_Interview_Warming_75_Opacity.png')}
                resizeMethod='scale'
                resizeMode='contain'
                style={{
                  width: '100%',//normalizeHeight(343, height),
                  height: normalizeHeight(200, height),
                  //marginTop: normalizeHeight(20, height),
                  //marginBottom: normalizeHeight(30, height),
                }}
              />
            </View>

            <View
              style={{
                //marginTop: normalizeHeight(20, height),
                marginBottom: normalizeHeight(10, height),
              }}
            >
              <Text
                style={{
                  ...themeFromContext.textVariants.header,
                  textAlign: 'center',
                }}
              >
                {(community && community.interviewTab) ? community.interviewTab.header : `Ready?`}
              </Text>
            </View>

            <View
              style={{
                margin: 10
              }}
            >
              <Text
                style={{
                  ...themeFromContext.textVariants.body,
                  textAlign: 'center',
                }}
              >
                {(community && community.interviewTab) ? community.interviewTab.bodyLine1 : `When you're ready to procced with the Novella interview, please let your organizer know.`}
              </Text>
            </View>
            <View
              style={{
                marginTop: 10
              }}
            >
              <Text
                style={{
                  ...themeFromContext.textVariants.body,
                  textAlign: 'center',
                }}
              >
                {(community && community.interviewTab) ? community.interviewTab.bodyLine2 : `They’ll be able to contact Novella and start the interview process.`}
              </Text>
            </View>

            <View
              style={{
                marginVertical: 10
              }}
            >
              {
                organizers[0] &&
                <Text
                  style={{
                    ...themeFromContext.textVariants.body,
                    textAlign: 'center',
                  }}
                >
                  {`Organizer: ${userService.getFullName(organizers[0]?.user)}` + (organizers[0]?.user.email != null ? ` (${organizers[0]?.user.email})` : ``)}
                </Text>
              }
              {
                !organizers[0] &&
                <Text
                  style={{
                    ...themeFromContext.textVariants.body,
                    color: themeFromContext.colors.failure,
                    textAlign: 'center',
                  }}
                >
                  {`This Novella has no organizer. Please let us know at support@meetnovella.com and we'll figure out what went wrong.`}
                </Text>
              }
            </View>

          </View>
        }
        <View
          style={{
            paddingBottom: normalizeHeight(80, height),
          }}
        >
        </View>
      </ScrollableViewScreen>
  );
}

export default RequestInterviewScreen;