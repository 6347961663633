import React, { useContext, useState } from 'react';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { View } from 'react-native';
import { Palette } from '../../Theme';
import { CommunityQuestion, CommunityQuestionAnswer } from '../../API';
import { LikeData } from '../../contracts/ICommunityService';
import { Button, Icon } from 'react-native-elements';

function LikeButton(props: { target: CommunityQuestionAnswer | CommunityQuestion, likes: LikeData, toggleLike: (target: any) => Promise<void> }): React.ReactElement {

  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { target, likes, toggleLike } = props;
  const [likeDisabled, setLikeDisabled] = useState(false);

  return (
    <View
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      <Button
        title={likes ? (likes.totalLikes == 0) ? `Like` : (likes.totalLikes == 1) ? `1 Like` : `${likes?.totalLikes} Likes` : 'Like'}
        titleStyle={{
          color: Palette.plum,
          ...themeFromContext.textVariants.cardBottomButton,
          fontWeight: '700',
          paddingStart: 2,
        }}
        buttonStyle={{
          padding: 0,
          paddingRight: 6,
          borderWidth: 1,
          borderRadius: 100,
          paddingLeft: 3,
          borderColor: Palette.lightpurple.concat('66'),
        }}
        onPress={() => {
          // disable button
          setLikeDisabled(true);
          toggleLike(target)
            .then(() => {
              // enable button
              setLikeDisabled(false);
            });
        }}
        //loading={likeDisabled}
        //loadingStyle={{ alignSelf: 'center' }}
        disabled={likeDisabled}
        disabledTitleStyle={{
          // same as titleStyle
          color: Palette.plum,
          ...themeFromContext.textVariants.cardBottomButton,
          fontWeight: '700',
          paddingStart: 2
        }}
        type='clear'
        icon={
          <Icon
            size={themeFromContext.textVariants.header.fontSize}
            name={likes?.getLiked() ? 'heart' : 'heart-outline'}
            type='ionicon'
            color={likes?.getLiked() ? '#F19D8D' : themeFromContext.colors.text}
          />}
        testID={`like-button-${target.id}`}
      />
      {/* {
        likes && likes.totalLikes > 0 &&
        <View
          style={{
            display: 'flex',
            alignContent: 'center',
            justifyContent: 'center',
            borderRadius: 100,
            overflow: 'hidden',
            backgroundColor: Palette.lightgrey,
            minWidth: themeFromContext.textVariants.header.fontSize,
            height: themeFromContext.textVariants.header.fontSize,
            padding: Platform.OS !== 'web' ? 0 : 8,
          }}
        >
          <Text
            style={{
              textAlign: 'center',
              textAlignVertical: 'center',
              color: themeFromContext.colors.text,
              ...themeFromContext.textVariants.cardBottomButton,
              fontSize: 16,
            }}
            testID={`like-button-count-${target.id}`}
          >
            {likes.totalLikes}
          </Text>
        </View>
      } */}
    </View>
  );
}
export default LikeButton;