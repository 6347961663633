import React, { ReactElement, useContext } from 'react'
import { Image, Pressable, SafeAreaView, View } from 'react-native'
import { Icon } from 'react-native-elements';
import { CommonContextType } from '../../../../types/CommonContextType';
import CommonContext from '../../../../CommonContext';
import { Palette } from '../../../../Theme';
import { DISPLAY, PHONE_BREAK, TABLET_BREAK, useSafeAreaInsets } from '../../../../business/layout/layout';
import { LinearGradient } from 'expo-linear-gradient';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';


function GetStartedPage(props: { navigation: { goBack: () => void}, children?: ReactElement | ReactElement[] }): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const { height, width } = commonContext.dimensions;
  const { top } = useSafeAreaInsets();
  const display = width > TABLET_BREAK ? DISPLAY.WEB : width <= PHONE_BREAK ? DISPLAY.PHONE : DISPLAY.TABLET;

  return (
    <SafeAreaView style={{
      display: 'flex',
      flexDirection: 'row',
      height: '100%',
      flex: 1,
      zIndex: 1,
      justifyContent: 'center',
    }}>
      <View
        style={{
          top: 0,
          left: 0,
          bottom: 0,
          position: 'absolute',
          display: 'flex',
          flex: 1,
          width: width,
          height: height,
          //backgroundColor: Palette.plum,
        }}
      >
        <LinearGradient
          style={[
            {
              display: 'flex',
              flex: 1,
              alignItems: 'center',
              borderColor: Palette.plum,
            },
          ]}
          colors={[ Palette.purple, Palette.plum]}
          start={{x: 0, y: 0.5}}
          end={{x: 1, y: 0.5}}
        ></LinearGradient>
      </View>
      <Pressable
        style={{
          position: 'absolute',
          top: top + 30,
          left: 20,
          zIndex: 2,
          borderRadius: 50,
          overflow: 'hidden',
          backgroundColor: Palette.white.concat('33'),
          height: 50,
          width: 50,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
        onPress={() => {
          console.log('go back');
          props.navigation.goBack();
        }}
      >
        <Icon
          name='arrow-back'
          type='ionicon'
          color={Palette.white}
          size={30}
        />
      </Pressable>
      <Image
        source={require('../../../../../assets/logo-white-teal-alpha.png')}
        resizeMode='contain'
        style={{
          position: 'absolute',
          top: top + 30,
          //right: 20,
          //alignSelf: 'center',
          height: 50,//height / 10,
          width: 153,
          //marginBottom: 20,
          //marginTop: Platform.OS == 'web' ? -50 : -50,
        }}
      />
      <KeyboardAwareScrollView
        style={{
          //alignSelf: 'center',
          flex: 1,
          overflow: 'visible',
        }}
        contentContainerStyle={{
          //flexGrow: 1,
          //justifyContent: 'space-around',
          display: 'flex',
          overflow: 'visible',
          // minHeight: '100%',
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1,
        }}
      >
        <View
          style={{
            // flex: 1,
            flexGrow: display == DISPLAY.PHONE ? 1 : 0,
            width: '100%',
            maxWidth: display == DISPLAY.PHONE ? '100%' : 400,
            alignItems: 'center',
            //backgroundColor: themeFromContext.colors.background,
            borderRadius: 10,
            paddingHorizontal: 30,
            // paddingTop: top,
            // paddingBottom: bottom,
            justifyContent: 'center',
            marginLeft: 0,
            zIndex: 1,
            overflow: 'visible',
          }}
        >
          {props.children}
        </View>
      </KeyboardAwareScrollView>
    </SafeAreaView>
  )
}

export default GetStartedPage;
