import { InteractionManager } from "react-native";
import { ICommunityService, VideoVisibility, VimeoVideoMetadata } from "../../contracts/ICommunityService";
import { IUserService } from "../../contracts/IUserService";
import { Dictionary } from "../../types/data/Dictionary";
import AnalyticsWrapper from "../analytics/analyticsWrapper";
import { getQuestionUnauthenticated } from "../public/unauthenticatedQuestion";
import { AnswerUploadManager } from "./AnswerUploadManager";
import { TUSUpload, UploadStatus } from "./TUSUpload";
import { IUploadListener, UploadListenerLibrary } from "./UploadListenerLibrary";

export enum AnswerStatus {
  PENDING = "PENDING",
  DONE = "DONE",
  STOPPED = "STOPPED",
}

export type AnswerModel = {
  [key: string]: any;
  id: string;
  title: string;
  description: string;
  visibility: VideoVisibility;
  communityQuestionId: string;
  questionText: string;
  userId: string;
  tusUpload: TUSUpload;
  committed: boolean;
  created: string;
  source: 'library' | 'camera';
}

export interface IAnswerModelManager {
  finalize(id: string) : Promise<void>;
}

export class AnswerModelManager implements IAnswerModelManager, IUploadListener<TUSUpload> {
  analytics: AnalyticsWrapper;
  communityService: ICommunityService;
  userService: IUserService;
  name: string;
  constructor(analytics: AnalyticsWrapper, communityService: ICommunityService, userService: IUserService) {
    this.analytics = analytics;
    this.communityService = communityService;
    this.userService = userService;
    this.name = 'AnswerModelFinalizer';
    UploadListenerLibrary.addListener(this);
  }

  async listen(tusUpload: TUSUpload): Promise<void> {
    //console.log(`i'm in the AnswerModelManager!!!!!!!!`);
    // console.log(`Do all the state updates here.`);
    // console.log(`here is the upload status: ${tusUpload.status}`);
    // console.log(`here is the upload id: ${tusUpload.id}`);
    switch (tusUpload.status) {
      case UploadStatus.CANCELED:
        break;
      case UploadStatus.COMPLETED:
        //console.log(`completed upload`);
        try {
          await this.finalize(tusUpload.id);
        } catch (ex) {
          console.log(`crashed in finalizeSave`);
          console.log(ex);
        }
        //console.log(`did I even get here? WTF?`);
        //setUploadStatus(FileUploadStatus.COMPLETED);
        break;
      case UploadStatus.FAILED:
        break;
      case UploadStatus.READY:
        break;
      case UploadStatus.IN_PROGRESS:
        break;
      case UploadStatus.PAUSED:
        break;
      default:
        break;
    }
  }
  async finalize(id: string): Promise<void> {
    //console.log(`finalizing save of answer`);
    const answer = AnswerUploadManager.get(id);
    //console.log(`found answer`);
    if (answer) {
      if (!answer.committed) {
        await this.commit(answer);
      }
      await this.record(answer);

      await AnswerUploadManager.deleteData(answer);
      await AnswerUploadManager.upsert(answer);
    }
  }
  async commit(answer: AnswerModel): Promise<void> {
    //console.log(`Commiting answer to DB: ${answer.id}`);
    const videoMetadata: VimeoVideoMetadata = {
      id: answer.id,
      title: answer.title,
      description: answer.description,
      visibility: answer.visibility,
      //uploadedByUserId: asUser,
    }
    //console.log(`Saving as user: ${answer.userId}`);
    await this.communityService.saveNewAnswer(answer.communityQuestionId, videoMetadata, answer.userId);
    //console.log(`Saved to db: ${answer.id}`);
    this.markComplete(answer);
  }

  async record(answer: AnswerModel): Promise<void> {
    //console.log(`Recording analytics for answer: ${answer.id}`);
    try {
      await this.logAnalytics(answer);
    } catch (ex) {
      console.log(`crashed in logAnalytics`);
      console.log(ex);
    }
  }

  async logAnalytics(answer: AnswerModel): Promise<void> {
    const question = await getQuestionUnauthenticated(answer.communityQuestionId);
    // analytics
    const data = {
      VideoId: answer.id,
      VideoTitle: answer.title,
      VideoDescription: answer.description,
      VideoVisibility: answer.visibility,
      VideoUploadedByUserId: answer.userId,
      //VideoUploadedByUserCommunityRole: communityRole,
      CommunityId: question?.communityID,
      CommunityName: question?.community.name,
      QuestionId: answer.communityQuestionId,
      QuestionText: question?.questionText,
      QuestionCategoryId: question?.categoryID,
      QuestionCategoryName: question?.category?.name,
      QuestionCustomCommunityCategory: question?.communityCategory,
      QuestionAskedByUserId: question?.userID,
      QuestionAskedByUserFullName: this.userService.getFullName(question?.user),
      QuestionAskedByUserEmail: question?.user.email,
    }
    this.analytics.uploadVideo(data);
  }

  markComplete(answer: AnswerModel): void {
    answer.committed = true;
  }
}