// Frameworks
import React, { ReactElement, ReactNode } from 'react';
import { Animated, View } from 'react-native';
import { Palette } from '../../../Theme';
import { useSafeAreaInsets } from '../../../business/layout/layout';
import HeaderFirst from './HeaderFirst';
import HeaderSecond from './HeaderSecond';

function Header(props: {
  navigation: any,
  primaryHeight: number,
  secondaryHeight: number,
  topTitle?: string | ReactElement, // page name
  bottomTitle?: string, // community name
  menuAction: () => void,
  goBack?: () => void,
  filter?: ReactNode,
}): React.ReactElement {

  const { top } = useSafeAreaInsets();

  return (
    <View
      style={{
        display: 'flex',
        width: '100%',
        top: 0,
        position: 'absolute',
        alignItems: 'center',
      }}
    >
      <View style={{ position: 'absolute', top: 0, height: top, width: '100%', backgroundColor: Palette.plum, zIndex: 10 }} />
      <HeaderFirst height={props.primaryHeight} title={props.bottomTitle} top={top} navigation={props.navigation} goBack={props.goBack} menuAction={props.menuAction} />
      <HeaderSecond height={props.secondaryHeight} top={top + props.primaryHeight} topTitle={props.topTitle} filter={props.filter} />
    </View>
  );
}
export default Header;