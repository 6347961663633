import * as React from "react"
import Svg, { Path } from "react-native-svg"

function MovieCamera(props) {
  return (
    <Svg
      data-name="Movie-Camera Illustration"
      xmlns="http://www.w3.org/2000/svg"
      width={100}
      height={100}
      viewBox="0 0 100 100"
      {...props}
    >
      <Path
        data-name="Path 71258"
        d="M100 50A50 50 0 1150 0a50 50 0 0150 50zm0 0"
        fill="#cdebec"
      />
      <Path
        data-name="Path 71259"
        d="M95.391 91.953a1.953 1.953 0 01-1.953 1.953H83.086a1.942 1.942 0 01-1.144-.373c-5.394 1.134-9.4 5.248-9.4 10.139H70c0-6.084 4.839-11.2 11.347-12.606A1.951 1.951 0 0183.086 90h10.352a1.953 1.953 0 011.953 1.953zm0 0"
        transform="translate(-56.328 -72.422)"
        fill="#024c67"
      />
      <Path
        data-name="Path 71260"
        d="M105.938 81.953v3.906a1.953 1.953 0 01-1.953 1.953h-37.11v3.906a3.9 3.9 0 013.778 2.93H82.5a1.953 1.953 0 011.953 1.952v1.953a1.953 1.953 0 01-1.953 1.953H51.25v3.906h4.883a2.929 2.929 0 012.93 2.93v21.484a2.929 2.929 0 01-2.93 2.93H36.6a2.929 2.929 0 01-2.93-2.93v-21.482a2.929 2.929 0 012.93-2.93h4.883v-3.906h-19.53A1.953 1.953 0 0120 98.555V96.6a1.953 1.953 0 011.953-1.953h35.285a3.9 3.9 0 013.778-2.93v-3.904H49.3a1.953 1.953 0 01-1.953-1.953v-3.907A1.953 1.953 0 0149.3 80h54.687a1.953 1.953 0 011.953 1.953zm0 0"
        transform="translate(-16.094 -64.375)"
        fill="#87a0af"
      />
      <Path
        data-name="Path 71261"
        d="M105.938 81.953a1.953 1.953 0 01-1.953 1.953H49.3a1.953 1.953 0 010-3.906h54.688a1.953 1.953 0 011.95 1.953zm-23.5 12.7H27.813v-.977a1.953 1.953 0 00-1.953-1.953h-3.907A1.953 1.953 0 0020 93.672v7.813a1.953 1.953 0 001.953 1.953h3.906a1.953 1.953 0 001.953-1.953v-.977a1.953 1.953 0 011.953-1.953H82.5a1.954 1.954 0 001.949-2.083 2 2 0 00-2.016-1.823zm0 0"
        transform="translate(-16.094 -64.375)"
        fill="#9bb9c3"
      />
      <Path
        data-name="Path 71262"
        d="M95.7 75.859a5.859 5.859 0 01-5.859 5.859H66.406a1.953 1.953 0 01-1.953-1.953v-7.812A1.953 1.953 0 0166.406 70h23.438a5.859 5.859 0 015.856 5.859zm-89.841 5.86H1.953A1.953 1.953 0 000 83.672v11.719a1.953 1.953 0 001.953 1.953h3.906a1.953 1.953 0 001.953-1.953V83.672a1.953 1.953 0 00-1.953-1.953zm0 0"
        transform="translate(0 -56.328)"
        fill="#024c67"
      />
      <Path
        data-name="Path 71263"
        d="M72.266 87.578v3.906a1.953 1.953 0 01-1.953 1.953h-3.907a1.953 1.953 0 01-1.953-1.953v-3.906a1.953 1.953 0 011.953-1.953h3.906a1.953 1.953 0 011.954 1.953zM89.844 70H66.406a1.953 1.953 0 00-1.953 1.953v3.678a1.953 1.953 0 001.953 1.953h23.438a5.86 5.86 0 005.483-3.793A5.86 5.86 0 0089.844 70zM5.859 81.719H1.953A1.953 1.953 0 000 83.672v6.836a1.953 1.953 0 001.953 1.953h3.906a1.953 1.953 0 001.953-1.953v-6.836a1.953 1.953 0 00-1.953-1.953zm0 0"
        transform="translate(0 -56.328)"
        fill="#025f80"
      />
      <Path
        data-name="Path 71264"
        d="M170.781 287.344h-46.875a3.906 3.906 0 01-3.906-3.906v-19.532a3.906 3.906 0 013.906-3.906h46.875a3.906 3.906 0 013.906 3.906v19.531a3.906 3.906 0 01-3.906 3.907zm0 0"
        transform="translate(-96.563 -209.219)"
        fill="#017296"
      />
      <Path
        data-name="Path 71265"
        d="M170.781 283.438h-46.875a3.906 3.906 0 01-3.906-3.907v-15.625a3.906 3.906 0 013.906-3.906h46.875a3.906 3.906 0 013.906 3.906v15.625a3.906 3.906 0 01-3.906 3.907zm0 0"
        transform="translate(-96.563 -209.219)"
        fill="#0680ae"
      />
      <Path
        data-name="Path 71266"
        d="M103.2 322.93v21.484a2.929 2.929 0 01-2.93 2.93H76.122A50.207 50.207 0 0170 341.852V322.93a2.929 2.929 0 012.93-2.93h27.344a2.929 2.929 0 012.93 2.93zm0 0"
        transform="translate(-56.328 -257.5)"
        fill="#017296"
      />
      <Path
        data-name="Path 71267"
        d="M88.594 211.027v35.156a3.906 3.906 0 01-3.906 3.906H39.381A50.186 50.186 0 0130 237.652v-26.625a3.906 3.906 0 013.906-3.906h50.781a3.906 3.906 0 013.906 3.906zm22.878-6.793L92.5 215.617v25.977l18.972 11.383a1.659 1.659 0 002.512-1.422v-45.9a1.659 1.659 0 00-2.512-1.422zm0 0"
        transform="translate(-24.14 -164.152)"
        fill="#3691bb"
      />
      <Path
        data-name="Path 71268"
        d="M108.374 367.2v5.078a3.906 3.906 0 01-3.906 3.906H59.162a50.269 50.269 0 01-4.58-5.078h49.886a3.906 3.906 0 003.906-3.906zm3.906-6.5v6.992l18.972 11.383a1.659 1.659 0 002.512-1.422v-4.063zm0 0"
        transform="translate(-43.921 -290.25)"
        fill="#0680ae"
      />
      <Path
        data-name="Path 71269"
        d="M354.883 278.2h-3.906a.976.976 0 01-.977-.977v-31.25a.976.976 0 01.977-.977h3.906a.976.976 0 01.977.977v31.25a.976.976 0 01-.977.977zm0 0"
        transform="translate(-281.641 -197.148)"
        fill="#3691bb"
      />
      <Path
        data-name="Path 71270"
        d="M354.883 272.216h-3.906a.977.977 0 01-.977-.977v-25.262a.976.976 0 01.977-.977h3.906a.976.976 0 01.977.977v25.263a.977.977 0 01-.977.976zm0 0"
        transform="translate(-281.641 -197.148)"
        fill="#6caac9"
      />
      <Path
        data-name="Path 71271"
        d="M56.875 262.93v21.484a2.929 2.929 0 01-2.93 2.93H16.7A49.764 49.764 0 0110 273.1v-10.17a2.929 2.929 0 012.93-2.93h41.015a2.929 2.929 0 012.93 2.93zM66.64 260h-3.906a1.953 1.953 0 000 3.906h3.906a1.953 1.953 0 000-3.906zm0 5.859h-3.906a1.953 1.953 0 000 3.906h3.906a1.953 1.953 0 000-3.906zm0 17.578h-3.906a1.953 1.953 0 000 3.906h3.906a1.953 1.953 0 000-3.906zm0 0"
        transform="translate(-8.047 -209.219)"
        fill="#017296"
      />
      <Path
        data-name="Path 71272"
        d="M83.2 308.789v1.953a.976.976 0 01-.977.977H50.977a.976.976 0 01-.977-.977v-1.953a.976.976 0 01.977-.977h31.25a.976.976 0 01.973.977zM82.227 300h-31.25a.976.976 0 00-.977.977v1.953a.976.976 0 00.977.977h31.25a.976.976 0 00.977-.977v-1.953a.976.976 0 00-.977-.977zm0 0"
        transform="translate(-40.234 -241.406)"
        fill="#025f80"
      />
      <Path
        data-name="Path 71273"
        d="M271.563 333.125a1.563 1.563 0 111.563-1.562 1.563 1.563 0 01-1.563 1.562zm0 0"
        transform="translate(-217.266 -265.547)"
        fill="#5de0c3"
      />
      <Path
        data-name="Path 71274"
        d="M295.563 333.125a1.563 1.563 0 111.563-1.562 1.563 1.563 0 01-1.563 1.562zm0 0"
        transform="translate(-236.578 -265.547)"
        fill="#ffe899"
      />
      <Path
        data-name="Path 71275"
        d="M273.125 355.563a1.563 1.563 0 11-1.562-1.563 1.563 1.563 0 011.562 1.563zM276.25 354a1.563 1.563 0 101.563 1.563A1.563 1.563 0 00276.25 354zm0 0"
        transform="translate(-217.266 -284.859)"
        fill="#fd7ab5"
      />
    </Svg>
  )
}

export default MovieCamera
