import React, { useContext } from 'react'
import { Linking, View } from 'react-native'
import { Text } from 'react-native-elements';
import CommonContext from '../../../CommonContext';
import { CommonContextType } from '../../../types/CommonContextType';

function PQFooter(): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const ActualYear = React.useMemo(() => new Date().getFullYear(), []);
  return (
    <View
      style={{
        alignItems: 'center',
        marginBottom: 20,
        marginTop: 10,
      }}
    >
      <Text style={{ ...themeFromContext.textVariants.detail, color: themeFromContext.colors.secondary }}>
        {`© ${ActualYear} Novella, Inc.`}
      </Text>
      <Text style={{ ...themeFromContext.textVariants.detail, color: themeFromContext.colors.secondary }}>
        <Text
          style={{
            ...themeFromContext.textVariants.detail,
            fontWeight: 'bold',
            color: themeFromContext.colors.secondary,
            textDecorationLine: 'underline'
          }}
          onPress={() => Linking.openURL("https://www.meetnovella.com/faqs")}
        >
          {`Beta FAQ`}
        </Text>
        {`  |  `}
        <Text
          style={{
            ...themeFromContext.textVariants.detail,
            fontWeight: 'bold',
            color: themeFromContext.colors.secondary,
            textDecorationLine: 'underline'
          }}
          onPress={() => Linking.openURL("https://www.meetnovella.com/terms-of-service/")}
        >
          {`Terms`}
        </Text>
        {`  |  `}
        <Text
          style={{
            ...themeFromContext.textVariants.detail,
            fontWeight: 'bold',
            color: themeFromContext.colors.secondary,
            textDecorationLine: 'underline'
          }}
          onPress={() => Linking.openURL("https://www.meetnovella.com/privacy-policy/")}
        >
          {`Privacy`}
        </Text>
      </Text>
    </View>
  )
}

export default PQFooter
