import React, { useContext, useEffect, useState } from 'react'
import { RootStackParamList } from '../../../../types/Navigation';
import { useIsFocused } from '@react-navigation/native';
import AuthPageInput from '../AuthPageInput';
import { isValidEmail } from '../../../../business/user/userHelper';
import { Text } from 'react-native-elements';
import { CommonContextType } from '../../../../types/CommonContextType';
import CommonContext from '../../../../CommonContext';
import NButton from '../../../components/NButton';
import { Palette } from '../../../../Theme';
import { DrawerScreenProps } from '@react-navigation/drawer';
import GetStartedPage from './GetStartedPage';
import i18n from 'i18n-js';
import { requestHelpGettingStarted } from '../../../../business/supportRequests/gettingStartedHelp';

export type InvitedProps = DrawerScreenProps<RootStackParamList, 'Invited'>;

function Invited(props: InvitedProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [emailError, setEmailError] = useState('');
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [buttonRefreshing, setButtonRefreshing] = useState(false);
  const { width } = commonContext.dimensions;
  const spotlightType = props.route.params?.type;

  const isFocused = useIsFocused();
  useEffect(() => {
    if (isFocused) {
      // analytics
      commonContext.analytics?.viewPage('Invited as ' + spotlightType == 'self' ? 'Spotlight' : 'Member', {});
      setSuccessMessage('');
    }
  }, [isFocused]);

  const clearErrors = () => {
    setEmailError('');
    setFirstNameError('');
    setLastNameError('');
  }

  const clearFields = () => {
    setEmail('');
    setFirstName('');
    setLastName('');
  }

  const pressSubmit = async () => {
    clearErrors();
    let errored = false;
    if (!isValidEmail(email)) {
      setEmailError(`Please enter a valid email address`);
      errored = true;
    }

    if (firstName.trim().length == 0) {
      setFirstNameError(`Please enter your first name`);
      errored = true;
    }

    if (lastName.trim().length == 0) {
      setLastNameError(`Please enter your last name`);
      errored = true;
    }

    if (errored) {
      return;
    }

    try {
      setButtonRefreshing(true);
      const response = await sendMessage(firstName, lastName, email, spotlightType == 'self' ? 'spotlight' : 'member');
      if (response.status == 200) {
        clearFields();
        setSuccessMessage(i18n.t('Help_SuccessMessage'));
      } else {
        setSuccessMessage(i18n.t('Help_ErrorMessage'));
      }
      setButtonRefreshing(false);
    } catch (err: any) {
      console.warn(err);
      setButtonRefreshing(false);
    }
  }

  async function sendMessage(firstName: string, lastName: string, email: string, type: 'spotlight'|'member'): Promise<any> {
    return await requestHelpGettingStarted(firstName, lastName, email, type);
  }

  return (
    <GetStartedPage navigation={props.navigation}>

        <Text
          style={{
            ...themeFromContext.textVariants.askQuestionHeader,
            color: Palette.white,
            maxWidth: '75%',
            textAlign: 'center',
            marginBottom: 30,
          }}
        >
          {spotlightType == 'self' ? i18n.t('InvitedToRecord_Title') : i18n.t('InvitedToJoin_Title')}
        </Text>

        <Text
          style={{
            color: Palette.white,
            
            marginBottom: 20,
            ...themeFromContext.textVariants.body,
            fontSize: 16,
          }}
        >
          {spotlightType == 'self' ? i18n.t('InvitedToRecord_Instructions') : i18n.t('InvitedToJoin_Instructions')}
        </Text>

        <AuthPageInput
          testID='invited-first-name'
          onChangeText={setFirstName}
          value={firstName}
          placeholder={i18n.t('Invited_FirstName_Label')}
          textContentType='name'
          autoCapitalize='words'
          autoCorrect={false}
          errorMessage={firstNameError}
          disabled={buttonRefreshing}
          inputStyle={{
            borderRadius: 10,
            borderColor: Palette.white,
            borderWidth: 2,
            paddingHorizontal: 16,
            paddingVertical: 8,
            ...themeFromContext.textVariants.body,
            color: Palette.white,
            backgroundColor: Palette.white.concat('33'),
          }}
          placeholderTextColor={Palette.white.concat('aa')}
        />

        <AuthPageInput
          testID='invited-last-name'
          onChangeText={setLastName}
          value={lastName}
          placeholder={i18n.t('Invited_LastName_Label')}
          textContentType='name'
          autoCapitalize='words'
          autoCorrect={false}
          errorMessage={lastNameError}
          disabled={buttonRefreshing}
          inputStyle={{
            borderRadius: 10,
            borderColor: Palette.white,
            borderWidth: 2,
            paddingHorizontal: 16,
            paddingVertical: 8,
            ...themeFromContext.textVariants.body,
            color: Palette.white,
            backgroundColor: Palette.white.concat('33'),
          }}
          placeholderTextColor={Palette.white.concat('aa')}
        />

        <AuthPageInput
          testID='invited-email'
          placeholder={i18n.t('Invited_Email_Label')}
          value={email}
          onChangeText={setEmail}
          autoCompleteType='email'
          keyboardType='email-address'
          textContentType='emailAddress'
          autoCapitalize='none'
          autoCorrect={false}
          errorMessage={emailError}
          disabled={buttonRefreshing}
          inputStyle={{
            borderRadius: 10,
            borderColor: Palette.white,
            borderWidth: 2,
            paddingHorizontal: 16,
            paddingVertical: 8,
            ...themeFromContext.textVariants.body,
            color: Palette.white,
            backgroundColor: Palette.white.concat('33'),
          }}
          placeholderTextColor={Palette.white.concat('aa')}
        />
        <NButton
          testID='invited-submit'
          buttonStyle={{ marginBottom: 8 }}
          t='primary2'
          onPress={pressSubmit}
          loading={buttonRefreshing}
          loadingProps={{
            color: Palette.plum,
          }}
          title={i18n.t('Invited_Submit_Button')}
        />

        <Text
          style={{
            ...themeFromContext.textVariants.askQuestionHeader,
            color: Palette.lightyellow,
            maxWidth: '75%',
            textAlign: 'center',
            marginVertical: 30,
          }}
        >
          {successMessage}
        </Text>
    </GetStartedPage>
  )
}

export default Invited;
