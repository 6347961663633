/* eslint-disable no-case-declarations */
import { BottomTabScreenProps } from '@react-navigation/bottom-tabs';
import React, { useContext, useEffect, useReducer, useRef, useState } from 'react'
import { Text, View, TextInput, FlatList, ListRenderItem, Platform, NativeSyntheticEvent, NativeScrollEvent, Image, ActivityIndicator } from 'react-native';
import { Palette } from '../../../Theme';
import { QuestionsStackParamList } from '../../../types/Navigation';
import log from '../../../business/logging/logger';
import { CommonContextType } from '../../../types/CommonContextType';
import CommonContext from '../../../CommonContext';
import ScrollableSectionListScreen from '../../components/Layouts/ScrollableSectionListScreen';
import { useIsFocused, useLinkTo } from '@react-navigation/native';
import { Community, CommunityQuestion, CommunityQuestionAnswer, CommunityQuestionAnswerStatus, CommunityUser, CommunityUserStatus } from '../../../API';
import { AnswerSection, extractAnswerSections } from '../../../business/sections/answerSectionHelper';
import { normalizeHeight } from '../../../business/layout/responseSize';
import { Icon } from 'react-native-elements';
import SlideUpView from '../../components/Modals/SlideUpView';
import { subscribeTo } from '../../../business/user/userHelper';
import * as s from '../../../graphql/subscriptions';
import AnswerItemCard from './AnswerItemCard';
import { LikeData, LikeMap, VideoVisibility, VimeoVideoMetadata } from '../../../contracts/ICommunityService';
import { Provider } from 'react-native-paper';
import { arrayReducer } from '../../../business/answers/arrayReducer';
import { QuestionHeaderCard } from '../../components/QuestionHeaderCard';
import NTextInput from '../../components/NTextInput';
import { UploadStatus } from '../../../business/upload/TUSUpload';
import { AnswerUploadManager } from '../../../business/upload/AnswerUploadManager';
import UploadLineItemSmall from '../../components/UploadLineItemSmall';
import I18n from 'i18n-js';
import { isGregHowellCommunity } from '../../../business/gregHowell/gregHowellHelper';
import NFAB2 from '../../components/NFAB2';
import { FABScrollHelper } from '../../../business/scroll/fabScrollHelper';
import { cleanThumbnails, createThumbnail, editThumbnail, getThumbnail, getVimeoVideosForAnswer } from '../../../business/video/vimeo';
import NButton from '../../components/NButton';

export type AnswersProps = BottomTabScreenProps<QuestionsStackParamList, 'Answers'>;

function Answers({ route, navigation }: AnswersProps): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const { height } = commonContext.dimensions;
  const linkTo = useLinkTo();
  const communityQuestionId = route.params.communityQuestionId;
  //const communityId = route.params.communityId;
  const isFocused = useIsFocused();
  const [refreshing, setRefreshing] = useState(false);
  const [community, setCommunity] = useState<Community>();
  const [question, setQuestion] = useState<CommunityQuestion>();
  //const [answers, setAnswers] = useState<CommunityQuestionAnswer[]>([]);
  const [answers, dispatchAnswerUpdate] = useReducer(arrayReducer, []);
  const [answerSections, setAnswerSections] = useState<AnswerSection[]>([]);
  const [communityQuestionAnswerId, setCommunityQuestionAnswerId] = useState('');
  const [subscriptions, setSubscriptions] = useState<any[]>([]);
  const [refresh, setRefresh] = useState(false);
  const [members, setMembers] = useState<CommunityUser[]>([]);
  const [communityRole, setCommunityRole] = useState<CommunityUserStatus>();

  // edit overlay state
  const titleTextRef = useRef<TextInput>(null);
  const [editOverlayVisible, setEditOverlayVisible] = useState(false);
  const [editOverlayErrorMessage, setEditOverlayErrorMessage] = useState('');
  const [editOverlayTitle, setEditOverlayTitle] = useState('');
  const [editOverlayDescription, setEditOverlayDescription] = useState('');
  const [editOverlayVideoId, setEditOverlayVideoId] = useState<string | undefined>();
  const [editOverlayThumbnailId, setEditOverlayThumbnailId] = useState<string>('');
  const [editOverlayThumbnailUrl, setEditOverlayThumbnailUrl] = useState<string | undefined>();
  const [editOverlayDurationSeconds, setEditOverlayDurationSeconds] = useState(0);
  const [thumbnailResetButtonLoading, setThumbnailResetButtonLoading] = useState(false);
  const [thumbnailShuffleButtonLoading, setThumbnailShuffleButtonLoading] = useState(false);
  const [resetButtonEnabled, setResetButtonEnabled] = useState(false);
  const [editOverlayVideoVisibility, setEditOverlayVideoVisibility] = useState(VideoVisibility.SELF);
  const [savingEdit, setSavingEdit] = useState(false);
  const [editAnswer, setEditAnswer] = useState<CommunityQuestionAnswer | undefined>();

  // delete overlay state
  const [deleteConfirmationVisible, setDeleteConfirmationVisible] = useState(false);
  const [deleteAnswer, setDeleteAnswer] = useState<CommunityQuestionAnswer | undefined>();
  const [deleteOverlayErrorMessage, setDeleteOverlayErrorMessage] = useState('');
  const [deleteButtonRefreshing, setDeleteButtonRefreshing] = useState(false);

  // likes
  const [likeMap, setLikeMap] = useState<LikeMap>({});

  // in-progress uploads
  const [pendingUploads, setPendingUploads] = useState<string[]>([]);
  const [inProgressUploads, setInProgressUploads] = useState<string[]>([]);

  // hide "new answer" button until we know it's ok to show
  const [showNewAnswerButton, setShowNewAnswerButton] = useState(false);
  const thumbnailHeight = Platform.OS == "web" ? (height / 3) : (height / 4);

  useEffect(() => {
    if (community) {
      setShowNewAnswerButton((!isGregHowellCommunity(community) || Platform.OS == 'ios'));
    }
  }, [community]);

  useEffect(() => {
    //console.log(`video id: ${editOverlayVideoId}\tthumbnail id: [${editOverlayThumbnailId}]`);
    if (editOverlayVideoId && editOverlayThumbnailId != undefined) {
      console.log(`video id: ${editOverlayVideoId}\tthumbnail id: [${editOverlayThumbnailId}]`);
      getThumbnail(editOverlayVideoId, editOverlayThumbnailId ?? '')
        .then((thumbnail) => {
          if (thumbnail.status == 200) {
            if (thumbnail.data.data) {
              console.log(`THUMBNAILS FROM VIMEO:`);
              console.log(thumbnail);
              const current = thumbnail.data.data.find(t => t.active);
              if (current) {
                const customLink = resizeThumbnail(current);
                setEditOverlayThumbnailUrl(customLink);
                setThumbnailResetButtonLoading(false);
                setThumbnailShuffleButtonLoading(false);
              }
            }
          }
        })
        .catch((ex) => {
          console.log(`couldn't get thumbnail for video ${editOverlayVideoId} with id ${editOverlayThumbnailId}`);
          console.log(ex);
          setThumbnailResetButtonLoading(false);
          setThumbnailShuffleButtonLoading(false);
        });
    } else {
      setEditOverlayThumbnailUrl(undefined);
      setThumbnailResetButtonLoading(false);
      setThumbnailShuffleButtonLoading(false);
    }
  }, [editOverlayVideoId, editOverlayThumbnailId]);


  useEffect(() => {
    if (editAnswer && editOverlayVideoId) {
      getVimeoVideosForAnswer(editAnswer.id)
        .then((videos) => {
          const video = videos.find(x => x.uri.replace('/videos/', '') == editOverlayVideoId);
          // console.log(`VIDEO:`);
          // console.log(JSON.stringify(video, null, 2));
          setEditOverlayDurationSeconds(video.duration)
        });
    }
  }, [editAnswer, editOverlayVideoId]);


  //log.info(`GOT HERE`);
  useEffect(() => {
    if (isFocused) {
      //console.log(`looking for uploads for question with id: ${communityQuestionId}`);
      Promise.all([
        commonContext.services.communityService.getQuestion(communityQuestionId),
      ])
        .then(results => {
          if (results[0]) {
            const q = results[0];
            setQuestion(q);
            handleQuestion(q);
          }
        })
        .catch(ex => {
          log.error(`Error getting prerequisites:`);
          log.error(ex);
        });

      const answerModels = AnswerUploadManager.getMatching("communityQuestionId", communityQuestionId);
      if (answerModels) {
        //console.log(`got some results from getMatching for id: ${communityQuestionId}`);
        setPendingUploads(answerModels.map(a => a.id));
        setInProgressUploads(answerModels.filter(u => u.tusUpload.status != UploadStatus.CANCELED && u.tusUpload.status != UploadStatus.COMPLETED).map(a => a.id));
        //console.log(`got ${answerModels.length} results from getMatching for id: ${communityQuestionId}`);
      } else {
        console.log(`now matching results in Answers page?? is == communityQuestionId`);
      }
    }
    return () => {
      return;
    }
  }, [communityQuestionId, isFocused]);

  // const updateUploadStatusInner = async (upload: TUSUpload, index: number) : Promise<void> => {
  //   if (pendingUploads && pendingUploads.length > 0 && pendingUploads.length >= index + 1) {
  //     pendingUploads[index].tusUpload = upload;
  //     setPendingUploads(pendingUploads);
  //   }
  // }

  useEffect(() => {
    let mounted = isFocused;
    let subCreate: any = null;
    let subUpdate: any = null;
    let subDelete: any = null;
    if (mounted) {
      // subscribe to CommunityQuestion updates
      //log.info(`UNSUBSCRIBING FROM COMMUNITYQUESTION UPDATES`);
      subscriptions.forEach(s => s.unsubscribe());
      subCreate = subscribeTo<CommunityQuestionAnswer>(communityQuestionId, "communityQuestionID", s.onCreateCommunityQuestionAnswerByQuestionId, "onCreateCommunityQuestionAnswerByQuestionId", (a) => {
        log.info(JSON.stringify(a, null, 2));
        if (a != null) {
          dispatchAnswerUpdate({ type: "add", payload: a });
        }
      });
      subUpdate = subscribeTo<CommunityQuestionAnswer>(communityQuestionId, "communityQuestionID", s.onUpdateCommunityQuestionAnswerByQuestionId, "onUpdateCommunityQuestionAnswerByQuestionId", (a) => {
        if (a != null) {
          if (a.status == CommunityQuestionAnswerStatus.DELETED) {
            log.warn(`soft deleted:`);
            log.info(a);
            dispatchAnswerUpdate({ type: "remove", payload: a });
          } else {
            dispatchAnswerUpdate({ type: "update", payload: a });
          }
        }
      });
      subDelete = subscribeTo<CommunityQuestionAnswer>(communityQuestionId, "communityQuestionID", s.onDeleteCommunityQuestionAnswerByQuestionId, "onDeleteCommunityQuestionAnswerByQuestionId", (a) => {
        if (a != null) {
          log.warn(`deleted:`);
          log.info(a);
          dispatchAnswerUpdate({ type: "remove", payload: a });
        }
      });
      setSubscriptions([subCreate, subUpdate, subDelete]);
    }

    return () => {
      //log.info(`UNSUBSCRIBING FROM COMMUNITYQUESTION UPDATES UNMOUNT`);
      subscriptions.forEach(s => s.unsubscribe());
      mounted = false;
    };
  }, [communityQuestionId, isFocused]);

  useEffect(() => {
    if (answers.length > 0) {
      const s = extractAnswerSections(commonContext.services.communityService, answers, "date", false)
      setAnswerSections(s);
    } else {
      setAnswerSections([]);
    }
    setRefresh(!refresh);
  }, [answers]);

  useEffect(() => {
    if (isFocused) {
      let r;
      if (commonContext.loggedInUser && members) {
        const u = members.find(m => m.userID == commonContext.loggedInUser?.id);
        r = u?.status;
        setCommunityRole(r);
      }

      // analytics
      // we're putting this here so that we can get the community role, if possible
      // this placement assumes that members are ONLY set once after the question is retrieved from the backend
      // note: we want to record this analytics event whenever this screen enters focus
      if (question) {
        const pageName = route.name;
        const data = {
          ...route.params,
          CommunityId: question.communityID,
          CommunityName: question.community.name,
          CommunityRole: r,
          QuestionId: question.id,
          QuestionText: question.questionText,
          QuestionCategoryId: question.categoryID,
          QuestionCategoryName: question.category?.name,
          QuestionCustomCommunityCategory: question.communityCategory,
          QuestionAskedByUserId: question.userID,
          QuestionAskedByUserFullName: commonContext.services.userService.getFullName(question.user),
          QuestionAskedByUserEmail: question.user.email,
        }
        commonContext.analytics?.viewPage(pageName, data);
      }
    }
  }, [members, isFocused]);

  const handleQuestion = async (q: CommunityQuestion) => {
    const results = await Promise.all([
      commonContext.services.communityService.getAnswers(q.id),
      commonContext.services.communityService.getSingleCommunity(q.communityID),
      commonContext.services.communityService.getJoinedMembers([q.communityID]),
    ])
    let a: CommunityQuestionAnswer[] = [];
    if (results[0]) {
      a = results[0];
      dispatchAnswerUpdate({ type: "set", payload: a });
      const map = await commonContext.services.communityService.getLikeMapForAnswers(a);
      setLikeMap(map);
      //setRefresh(!refresh);
    } else {
      log.error(`Error getting prerequisites`);
    }
    if (results[1]) {
      const c = results[1];
      setCommunity(c);
    }
    if (results[2]) {
      const m = results[2];
      setMembers(m);
    }
  }

  const toggleLike = async (answer: CommunityQuestionAnswer): Promise<void> => {
    try {
      if (!likeMap[answer.id]) {
        likeMap[answer.id] = new LikeData(true, 1);
      } else {
        likeMap[answer.id].like(!likeMap[answer.id].getLiked());
      }
      setRefresh(!refresh);
      await commonContext.services.communityService.toggleCommunityQuestionAnswerLike(answer);
      const map = await commonContext.services.communityService.getLikeMapForAnswers(answers);
      setLikeMap(map);
    } catch (ex) {
      log.error(ex);
    }
  }

  const toggleDelete = (answer?: CommunityQuestionAnswer) => {
    setDeleteOverlayErrorMessage('');
    setDeleteAnswer(answer);
    setDeleteConfirmationVisible(!deleteConfirmationVisible);
  };

  const pressDelete = async () => {
    if (deleteAnswer) {
      log.info(`deleting answer!`);
      setDeleteButtonRefreshing(true);
      deleteAnswer.status = CommunityQuestionAnswerStatus.DELETED;
      await commonContext.services.communityService.saveExistingAnswer(deleteAnswer);
      setDeleteButtonRefreshing(false);
      toggleDelete();
    }
  }

  const toggleEdit = (answer: CommunityQuestionAnswer): void => {
    toggleEditOverlay(answer);
  }

  const toggleEditOverlay = (communityQuestionAnswer?: CommunityQuestionAnswer) => {
    //setRefreshCategories(!refreshCategories);
    const makeVisible = !editOverlayVisible;
    if (communityQuestionAnswer) {

      // if (makeVisible) {
      //   setTimeout(() => { // this is a bizarre hack I found here:  https://stackoverflow.com/questions/35522220/react-ref-with-focus-doesnt-work-without-settimeout-my-example
      //     titleTextRef.current?.focus();
      //   }, 1100);
      //   //log.info(questionTextRef.current?.isFocused());
      // }

      const arr: VimeoVideoMetadata[] = JSON.parse(communityQuestionAnswer.content);
      const item = arr[0];

      console.log(`got content from answer:`);
      console.log(item);

      setEditOverlayErrorMessage('');
      setEditOverlayVideoId(item.id);
      setEditOverlayTitle(item.title);
      setEditOverlayThumbnailId(item.thumbnailId ?? '');
      setEditOverlayDescription(item.description);
      setEditOverlayVideoVisibility(item.visibility);
      setResetButtonEnabled(false);
      setEditAnswer(communityQuestionAnswer);
    } else {
      setEditOverlayErrorMessage('');
      setEditOverlayTitle('');
      setEditOverlayDescription('');
      setEditOverlayVideoVisibility(VideoVisibility.SELF);
      setEditOverlayVideoId(undefined);
      setEditOverlayThumbnailId(undefined);
      setResetButtonEnabled(false);
      setEditAnswer(undefined);
    }

    setEditOverlayVisible(makeVisible);
  };

  const pressSaveEdit = async () => {
    setSavingEdit(true);
    if (editAnswer) {
      log.info(`Save edited item: ${editAnswer.id}, ${editOverlayTitle}, ${editOverlayDescription}, ${editOverlayVideoVisibility}, ${editOverlayThumbnailId}`);
      const x = editAnswer.content;
      const arr: VimeoVideoMetadata[] = JSON.parse(x);
      //pretty hacky, assuming we always use the first....
      const first = arr[0];
      if (editOverlayVideoId) {
        let needsRefresh = false;
        const videoMetadata: VimeoVideoMetadata = {
          id: editOverlayVideoId,
          title: editOverlayTitle,
          description: editOverlayDescription,
          visibility: editOverlayVideoVisibility,
        }
        if (first.uploadedByUserId) {
          videoMetadata["uploadedByUserId"] = first.uploadedByUserId;
        }
        if (first.thumbnailId) {
          videoMetadata["thumbnailId"] = first.thumbnailId;
        }
        if (editOverlayThumbnailId && editOverlayThumbnailId.length != 0) {
          videoMetadata["thumbnailId"] = editOverlayThumbnailId;
          await editThumbnail(editOverlayVideoId, editOverlayThumbnailId, true);
          await cleanThumbnails(editOverlayVideoId);
          needsRefresh = true;
        }
        arr[0] = videoMetadata;
        editAnswer.content = JSON.stringify(arr);
        await commonContext.services.communityService.saveExistingAnswer(editAnswer);

        // hacky way to force thumbnail to refresh
        if (needsRefresh) {
          const orig = [...answers];
          dispatchAnswerUpdate({ type: "set", payload: answers.filter(a => a.id != editAnswer.id) });
          dispatchAnswerUpdate({ type: "set", payload: orig });
        }
      }
    }
    toggleEditOverlay();
    setSavingEdit(false);
  }

  const removePendingUpload = (id: string): void => {
    setPendingUploads(pendingUploads.filter(u => u != id));
    setInProgressUploads(inProgressUploads.filter(u => u != id));
    setRefresh(!refresh);
  }

  const renderUploadLineItem: ListRenderItem<string> = ({ item }) => (
    <UploadLineItemSmall key={item} id={item} onRemove={removePendingUpload} isFocus={isFocused} />
  );

  const goBack = () => {
    console.log(`communityId = ${community?.id}`)
    linkTo(`/community/${community?.id}/questions/asked/${community?.id}/`);
  }

  const [showButton, setShowButton] = useState(true);
  const [fabScroller] = useState(new FABScrollHelper(setShowButton));
  function handleScroll(event: NativeSyntheticEvent<NativeScrollEvent>): void {
    fabScroller.handleScroll(event);
  }

  async function randomThumbnail() {
    if (editOverlayDurationSeconds > 0 && editOverlayVideoId) {
      setThumbnailShuffleButtonLoading(true);
      const timestamp = Math.floor(editOverlayDurationSeconds * Math.random());
      createThumbnail(editOverlayVideoId, timestamp)
        .then((tn) => {

          console.log(`random thumbnail:`);
          console.log(JSON.stringify(tn, null, 2));

          if (tn) {
            if (tn.status == 201) {
              const uri: string = tn.data.uri;
              const id = uri.substring(uri.lastIndexOf('/') + 1);
              setEditOverlayThumbnailId(id);

              const customLink = resizeThumbnail(tn.data);
              setEditOverlayThumbnailUrl(customLink);
              setResetButtonEnabled(true);
            }
          }

          setThumbnailShuffleButtonLoading(false);
        })
    }
    //const thigng = await createThumbnail(editOverlayVideoId, )
  }

  function resizeThumbnail(tn: any): string {
    const len = tn.sizes.length;
    const originalLink = tn.sizes[len - 1].link;
    const originalHeight = tn.sizes[len - 1].height;
    const originalWidth = tn.sizes[len - 1].width;
    const originalSizeString = `${originalWidth}x${originalHeight}`;

    const customWidth = Math.floor(thumbnailHeight);
    const customHeight = customWidth;
    const customSizeString = `${customWidth}x${customHeight}`;

    const customLink = originalLink.replace(originalSizeString, customSizeString).replace('r=pad', '');

    console.log(`original thumbnail link: ${originalLink}`);
    console.log(`custom thumbnail link: ${customLink}`);

    return customLink;
  }

  function resetThumbnail() {
    if (editAnswer) {
      const x = editAnswer.content;
      const arr: VimeoVideoMetadata[] = JSON.parse(x);
      const originalThumbnailId = arr[0].thumbnailId;
      setResetButtonEnabled(false);
      setEditOverlayThumbnailId(originalThumbnailId ?? '');
      setThumbnailResetButtonLoading(true);
    }
  }

  const renderFooter = () => (
    <View style={{
      minHeight: answerSections.length > 0 ? normalizeHeight(260, height) : 0,
      paddingVertical: themeFromContext.spacing.m
    }} />
  );

  const renderQuestionHeaderCard = React.useMemo(() => {
    return (
      question &&
      <QuestionHeaderCard question={question} />
    );
  }, [question?.questionText, question?.date, question?.userID, question?.imageKeys]);

  const renderHeader = React.useMemo(() => (
    <View
      style={{
        display: 'flex',
        //flex: 1,
        width: '100%',
      }}
    >
      {
        renderQuestionHeaderCard
      }
      {
        pendingUploads.length > 0 &&
        <View
          style={{
            display: 'flex',
            flex: 1,
            width: '100%',
            alignItems: 'center',
            marginBottom: 8,
          }}
        >
          <Text
            style={{
              //width: '100%',
              textAlign: 'center',
              //fontStyle: 'italic',
              //fontWeight: '400',
              fontSize: 14,
              color: Palette.white,
              //backgroundColor: Palette.lightyellow.concat('ff'),
              paddingHorizontal: 20,
              paddingVertical: 6,
              marginBottom: 6,
              // borderColor: Palette.yellow,
              // borderWidth: 1,
              // borderRadius: 4,
              // overflow: 'hidden',
            }}
          >
            {I18n.t(`AnswersPage_PendingUploads_Title`)}
          </Text>
          {
            Platform.OS == 'web' && inProgressUploads.length > 0 &&
            <>
              <Text
                style={{
                  //width: '100%',
                  textAlign: 'center',
                  //fontStyle: 'italic',
                  //fontWeight: '400',
                  fontSize: 14,
                  color: Palette.white,
                  fontStyle: 'italic',
                  backgroundColor: Palette.red.concat('99'),
                  paddingHorizontal: 20,
                  paddingVertical: 6,
                  marginBottom: 6,
                  borderColor: Palette.red,
                  borderWidth: 1,
                  borderRadius: 4,
                  // overflow: 'hidden',
                }}
              >
                {I18n.t(`AnswersPage_PendingUploads_RefreshWarning`)}
              </Text>
              <Text
                style={{
                  //width: '100%',
                  textAlign: 'center',
                  //fontStyle: 'italic',
                  //fontWeight: '400',
                  fontSize: 14,
                  color: Palette.white,
                  fontStyle: 'italic',
                  backgroundColor: Palette.yellow.concat('99'),
                  paddingHorizontal: 20,
                  paddingVertical: 6,
                  marginBottom: 6,
                  borderColor: Palette.yellow,
                  borderWidth: 1,
                  borderRadius: 4,
                  // overflow: 'hidden',
                }}
              >
                {I18n.t(`AnswersPage_PendingUploads_RefreshWarning2`)}
              </Text>
            </>
          }
          <FlatList
            style={{
              display: 'flex',
              width: '100%',
            }}
            data={pendingUploads}
            renderItem={renderUploadLineItem}
            keyExtractor={(item) => { return `puli-${item}` }}
          />
        </View>
      }
    </View>
  ), [pendingUploads, question?.questionText, question?.date, question?.userID, question?.imageKeys]);

  const renderItem = ({ item, index, }) => (
    <AnswerItemCard
      answer={item}
      moderated={community?.requiresModeration ?? false}
      role={communityRole ?? CommunityUserStatus.REMOVED}
      index={index}
      comments={item.communityQuestionAnswerCommentsByStatusDate.items}
      likes={likeMap[item.id]}
      toggleDelete={toggleDelete}
      toggleEditText={toggleEdit}
      toggleLike={toggleLike}
    />
  );

  const renderSectionHeader = () => (
    <></>
  )

  const renderEmpty = () => (
    <View
      style={{
        alignItems: 'center',
        flex: 1,
        //justifyContent: 'center',
        paddingTop: 30,
        paddingBottom: 130,
      }}
      testID={'question-answer-list-empty-view'}
    >
      <Text
        style={{
          ...themeFromContext.textVariants.body,
          color: Palette.white,
          textAlign: 'center'
        }}
      >
        {`This question hasn't been answered yet! Click the "+" below to answer it now.`}
      </Text>
    </View>
  );

  const pressNewAnswer = () => {
    log.info(`/answer/${communityQuestionId}/`);
    linkTo(`/answer/${communityQuestionId}/`);
  };

  const cancelEdit = () => { toggleEditOverlay(); }

  return (
    <Provider>
      <ScrollableSectionListScreen
        navigation={navigation}
        goBack={goBack}
        topTitle={`Answers`}
        isFocused={isFocused}
        refreshing={refreshing}
        bottomTitle={community?.name ?? '...'}
        sections={answerSections}
        extraData={refresh}
        initialNumToRender={2}
        windowSize={9}
        onScroll={handleScroll}
        renderItem={renderItem}
        renderSectionHeader={renderSectionHeader}
        ListFooterComponent={renderFooter}
        ListHeaderComponent={renderHeader}
        ListEmptyComponent={renderEmpty}
      >
        <NFAB2
          style={{
            display: showNewAnswerButton ? 'flex' : 'none',
          }}
          isVisible={showButton}
          hasBottomBar={true}
          left={
            <Icon
              name='add'
              type='ionicon'
              color={Palette.plum}
              size={32}
            />
          }
          right={
            <Text
              style={{
                fontSize: 20,
                color: Palette.plum,
              }}
            >
              {`New answer`}
            </Text>
          }
          onPress={pressNewAnswer}
          testID={`question-answer-add-button`}
        />
        {/* <NFAB
          icon={
            <Icon
              name='plus'
              type='font-awesome-5'
              color={Palette.plum}
            />
          }
          position={FABPosition.CENTER}
          onPress={pressNewAnswer}
          testID={`question-answer-add-button`}
          style={{
            display: showNewAnswerButton ? 'flex' : 'none',
          }}
          buttonStyle={{
            backgroundColor: Palette.white,
            borderColor: Palette.plum,
            borderWidth: 1,
          }}
        /> */}
        <SlideUpView
          onCancel={toggleDelete}
          onCommit={pressDelete}
          show={deleteConfirmationVisible}
          buttonColors={[themeFromContext.colors.danger, themeFromContext.colors.danger]}
          icon={
            <Icon
              name='trash-alt'
              type='font-awesome-5'
              solid
              color={themeFromContext.colors.background}
            />
          }
        >
          <Text
            style={{
              ...themeFromContext.textVariants.askQuestionHeader,
              color: themeFromContext.colors.primary,
              textAlign: 'left',
              paddingLeft: 10,
            }}
          >
            {`Delete this answer?`}
          </Text>
          {
            deleteAnswer != undefined && deleteAnswer.content != undefined && JSON.parse(deleteAnswer.content)[0].title != undefined && JSON.parse(deleteAnswer.content)[0].title.length > 0 &&
            <Text
              style={{
                ...themeFromContext.textVariants.header,
                color: themeFromContext.colors.foreground,
                padding: themeFromContext.spacing.m,
                textAlign: 'center',
                flex: 1,
              }}
            >
              {`"${JSON.parse(deleteAnswer.content)[0].title}"`}
            </Text>
          }
          <Text
            style={{
              margin: themeFromContext.spacing.s,
              color: themeFromContext.colors.failure,
              fontFamily: themeFromContext.textVariants.body.fontFamily,
              fontSize: themeFromContext.textVariants.body.fontSize,
              textAlign: 'center'
            }}
          >
            {deleteOverlayErrorMessage}
          </Text>
        </SlideUpView>
        <SlideUpView
          onCancel={cancelEdit}
          onCommit={pressSaveEdit}
          buttonTitle={`Save`}
          show={editOverlayVisible}
          working={savingEdit}
        >
          <View>
            <Text
              style={{
                ...themeFromContext.textVariants.askQuestionHeader,
                color: themeFromContext.colors.primary,
                textAlign: 'left',
                paddingBottom: 10,
              }}
            >
              {`Edit title or description:`}
            </Text>
          </View>
          <View
            style={{
              flex: 1,
              display: 'flex',
            }}
          >
            <NTextInput
              ref={titleTextRef}
              placeholder={`(Optional)`}
              value={editOverlayTitle}
              onChangeText={setEditOverlayTitle}
              maxLength={128}
              label={`Title:`}
              labelStyle={{
                //padding: 10,
                ...themeFromContext.textVariants.inputLabel,
                color: themeFromContext.colors.text,
              }}
              disabled={savingEdit}
              focusable={!savingEdit}
              testID={`edit-answer-modal-title-input`}
            />
            <NTextInput
              placeholder={`(Optional)`}
              multiline={true}
              numberOfLines={4}
              value={editOverlayDescription}
              onChangeText={setEditOverlayDescription}
              maxLength={500}
              label={`Description:`}
              labelStyle={{
                //padding: 10,
                ...themeFromContext.textVariants.inputLabel,
                color: themeFromContext.colors.text,
              }}
              inputStyle={{
                textAlignVertical: 'center',
                paddingTop: 8,
                lineHeight: 18
              }}
              disabled={savingEdit}
              focusable={!savingEdit}
              testID={`edit-answer-modal-description-input`}
            />

            <Text
              style={{
                ...themeFromContext.textVariants.inputLabel,
                color: themeFromContext.colors.text,
              }}
            >
              {`Thumbnail:`}
            </Text>
            <View
              style={{
                display: 'flex',
              }}
            >
              {
                editOverlayThumbnailUrl != undefined &&
                <Image
                  source={{ uri: editOverlayThumbnailUrl }}
                  defaultSource={{ uri: editOverlayThumbnailUrl }}
                  resizeMethod='auto'
                  resizeMode='cover'
                  style={{
                    width: thumbnailHeight,
                    height: thumbnailHeight,
                    marginBottom: 4,
                    alignSelf: 'center',
                    borderWidth: 1,
                    borderColor: `#DDEAEB`,
                  }}
                />
              }
              {
                editOverlayThumbnailUrl == undefined &&
                <View
                  style={{
                    display: 'flex',
                    height: thumbnailHeight,
                    width: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <ActivityIndicator />
                </View>
              }
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <NButton
                  t={"mild"}
                  title={`Reset`}
                  onPress={resetThumbnail}
                  loading={thumbnailResetButtonLoading}
                  loadingProps={{
                    color: Palette.teal,
                  }}
                  icon={
                    <Icon
                      iconStyle={{
                        marginEnd: 4
                      }}
                      size={20}
                      name={'refresh'}
                      type='ionicon'
                      color={resetButtonEnabled ? Palette.teal : Palette.grey}
                    />
                  }
                  disabled={!resetButtonEnabled}
                />
                <NButton
                  t={"mild"}
                  title={`Random`}
                  onPress={randomThumbnail}
                  loading={thumbnailShuffleButtonLoading}
                  loadingProps={{
                    color: Palette.teal,
                  }}
                  icon={
                    <Icon
                      iconStyle={{
                        marginEnd: 4
                      }}
                      size={20}
                      name={'shuffle'}
                      type='ionicon'
                      color={Palette.teal}
                    />
                  }
                  iconPosition={'left'}
                />
              </View>
            </View>


            {
              editOverlayErrorMessage.length > 0 &&
              <View>
                <Text
                  style={{
                    margin: themeFromContext.spacing.l,
                    color: themeFromContext.colors.failure,
                    ...themeFromContext.textVariants.body,
                    textAlign: 'center'
                  }}
                >
                  {editOverlayErrorMessage}
                </Text>
              </View>
            }
          </View>
        </SlideUpView>
      </ScrollableSectionListScreen>
    </Provider>
  );
}

export default Answers;