import log from '../logging/logger';
import { API } from 'aws-amplify';
import awsConfig from '../../aws-exports';
import { Community, CommunityQuestion, CommunityUser } from '../../API';
API.configure(awsConfig);

const API_NAME = `novellaREST`;

export type CreateCommunityUnauthenticatedResponse = {
  community: Community;
  question: CommunityQuestion;
  members: CommunityUser[];
}

export async function createCommunityUnauthenticated(stuff: any): Promise<CreateCommunityUnauthenticatedResponse|undefined>
{
  const apiName = API_NAME;
  const path = `/unauthenticatedCommunity`;
  const init = {
    body: stuff
  }

  try {
    const response = await API.post(apiName, path, init);

    log.info(`GOT UNAUTHENTICATED COMMUNITY CREATION RESPONSE:`);
    log.info(response);
    return response;
  } catch (ex) {
    //log.error(`ERROR TRYING TO GET UNAUTHENTICATED QUESTION:`)
    log.error(ex);
  }
}