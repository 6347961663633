// Frameworks
import React, { useContext, useEffect, useState } from 'react';
import { CommonContextType } from '../../types/CommonContextType';
import CommonContext from '../../CommonContext';
import { Button, Icon } from 'react-native-elements';
import { Menu } from 'react-native-paper';
import { Palette } from '../../Theme';
import { useSafeAreaInsets } from '../../business/layout/layout';

type ButtonMenuProps = {
  vertical?: boolean,
  open?: boolean,
  setOpen?: (open: boolean) => void,
  testID?: string,
  loading?: boolean,
  cancelButtonName?: string,
  children: Menu.Item | Menu.Item[],
}

function ButtonMenuInner(props: ButtonMenuProps) : React.ReactElement {
  
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const {top} = useSafeAreaInsets();

  const [menuAnchor, setMenuAnchor] = useState({x: 0, y: 0});
  const [menuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    //console.log(`button menu open prop changed: ${props.open}`);
    if (props.open != undefined) {
      setMenuVisible(props.open);
    }
  }, [props.open]);

  const buttonPress = (event) => {
    setMenuAnchor({x: event.nativeEvent.pageX + 40, y: event.nativeEvent.pageY - 32 + top});
    if (props.setOpen) {
      props.setOpen(true);
    } else {
      setMenuVisible(true);
    }
  };

  const dismiss = () => {
    if (props.setOpen) {
      props.setOpen(false);
    } else {
      setMenuVisible(false);
    }
  };

  return (
    <>
      <Button
        style={{
          display: 'flex',
        }}
        buttonStyle={{
          padding: 10,
        }}
        //disabled={!visible}//!isAdmin && question.user.id !== loggedInUser?.id}
        onPress={buttonPress}
        loading={props.loading}
        type='clear'
        icon={
          <Icon
            size={themeFromContext.textVariants.body.fontSize}
            name={props.vertical ? 'ellipsis-vertical' : 'ellipsis-horizontal'}
            type='ionicon'
            solid
            color={Palette.textgrey}
          />
        }
        hitSlop={{top: 30, right: 30, bottom: 30, left: 30}}
        testID={props.testID}
      />
        <Menu
          style={{
            borderRadius: 25,
            //position: 'relative',
          }}
          contentStyle={{
            backgroundColor: Palette.faintgrey,
            paddingVertical: 0,
            borderRadius: 25,
          }}
          visible={menuVisible}
          anchor={{
            x: menuAnchor.x, y: menuAnchor.y
          }}
          onDismiss={dismiss}
        >
          {props.children}
          <Menu.Item
            icon={() => (
              <Icon
                size={themeFromContext.textVariants.header.fontSize}
                name='times-circle'
                type='font-awesome-5'
                solid
                color={themeFromContext.colors.background}
                style={{
                  alignItems: 'flex-start',
                }}
              />)}
            title={props.cancelButtonName != undefined ? props.cancelButtonName : 'Cancel'}
            titleStyle={{
              fontFamily: themeFromContext.textVariants.body.fontFamily,
              fontSize: themeFromContext.textVariants.body.fontSize,
              color: themeFromContext.colors.background
            }}
            style={{
              backgroundColor: Palette.grey,
              borderBottomLeftRadius: 25,
              borderBottomRightRadius: 25,
              paddingHorizontal: 20,
            }}
            onPress={dismiss}
            testID={`${props.testID}-cancel`}
          />
        </Menu>
    </>
  );
}

export const ButtonMenu = ButtonMenuInner;