import React, { useContext } from 'react'
import { View } from 'react-native'
import { Text } from 'react-native-elements';
import CommonContext from '../../../CommonContext';
import { CommonContextType } from '../../../types/CommonContextType';

function AuthPageFooter(): React.ReactElement {
  const commonContext = useContext<CommonContextType>(CommonContext);
  const themeFromContext = commonContext.theme;
  const ActualYear = React.useMemo(() => new Date().getFullYear(), []);
  return (
    <View
      style={{
        alignItems: 'center',
        marginVertical: 20
      }}
    >
      <Text style={{ ...themeFromContext.textVariants.detail, color: themeFromContext.colors.secondary }}>
        {`© ${ActualYear} Novella, Inc.`}
      </Text>
    </View>
  )
}

export default AuthPageFooter
